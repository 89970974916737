import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Box,} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import { getIntlTranslation } from "../../../../../utils/helper";
import { useIntl } from "react-intl";

function AddHeader({ headerList, updateHeaders, categories }) {
  const intl = useIntl();
  const inspection = useSelector((state) => state.tddInspection);
  const [toggle, setToggle] = useState(false);
  const [headerExist, setHeaderExist] = useState(false);
  const [open, setOpen] = useState(true);
  const [currentCategorieHeaders, setCurrentCategorieHeaders] = useState([]);
  const [possition, setPossition] = useState("");
  const [newData, setNewData] = useState({
    name: "",
  });

  useEffect(() => {
    if (!!headerList) {
      const headers = headerList.filter((e) =>
        e.categoryList.find((f) => f.category.ident === inspection.tab.ident)
      );
      setCurrentCategorieHeaders(headers);
    }
  }, [inspection.tab.ident, headerList]);

  const handleAdd = (params) => {
    if (!!newData.name) {
      const newArr = currentCategorieHeaders.filter((obj) => {
        return obj.name === newData.name;
      });
      if (newArr.length > 0) {
        setHeaderExist(true);
        setToggle(false);
      } else {
        const category = {
          type: "inspectionHeadersCategory",
          category: {
            type: "valueAndIdentDto",
            value: inspection.tab.value,
            ident: inspection.tab.ident,
          },
          headerOrder: currentCategorieHeaders.length + 1,
        };
        const header = {
          type: "inspectionHeadersDto",
          name: newData.name,
          order: currentCategorieHeaders.length + 1,
          key: newData.name,
          custom: true,
          categoryList: [category],
          editor: "textarea",
          editable: true,
        };
        setToggle(true);
        setHeaderExist(false);
        const newHeaderList = [...currentCategorieHeaders, header];
        updateHeaders(newHeaderList);
        setPossition(currentCategorieHeaders.length + 1);
        setNewData({
          name: "",
        });
      }
    }
  };

  const handleCancle = (params) => {
    setNewData({
      name: "",
    });
    setPossition("");
    setToggle(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box style={{ width: "50%" }}>
      <Box
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="body1" gutterBottom style={{ margin:5}}>
          {getIntlTranslation("_add-headers")} <strong>{inspection.tab.value}</strong>
        </Typography>
        <div style={{ display: "flex", width: "100%", padding:5 }}>
          <TextField
            label={getIntlTranslation("_Header Name")}
            value={newData.name}
            style={{ width: "90%" }}
            placeholder={intl.formatMessage({ id:"_add-header button"})}
            onChange={(e) => {
              setNewData({ ...newData, name: e.target.value });
            }}
          />
          {!!newData.name && (
            <Box className={"d-flex flex-column"}>
              <CustomIconButton
                id="custom_header_add_1"
                style={{ padding:2}}
                handleClick={handleAdd}
                icon={<AddBoxIcon style={{ color: "#254a9a",width:25, height:25}} />}
                tooltip={{
                  title: "add a custom header",
                }}
              />
              <CustomIconButton
                id="custom_header_cancel_1"
                style={{ padding:2}}
                handleClick={handleCancle}
                icon={<CancelIcon style={{ color: "#ef4444",width:25, height:25 }} />}
                tooltip={{
                  title: "Click to cancel",
                }}
              />
            </Box>
          )}
        </div>
        <Box style={{ marginTop: 20 }}>
          {toggle && (
            <p style={{ color: "#298714" }}>
              {getIntlTranslation("_header added")} {possition}
            </p>
          )}
          {headerExist && (
            <p style={{ color: "#cf0202" }}>{getIntlTranslation("_header exists")}</p>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AddHeader;
