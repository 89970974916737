import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import IntlMessages from "../../../../../utils/IntlMessages";
import {
  getLabel,
  getIntlTranslation,
  getDialogBoxHeader,
} from "../../../../../utils/helper";
import CloseIcon from "@mui/icons-material/Close";
import SaveButton from "../../../../../components/buttons/SaveButton";

const validationSchema = Yup.object().shape({
  reportVersion: Yup.string().required(<IntlMessages id="required_field" />),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const CreateReportVersion = (props) => {
  const formRef = useRef();
  const intialFormData = {
    reportVersion: "",
    isCurrent:false,
  };

  const onSave = (values) => {

    const temp = {
      type:"reportVersion",
      versionName:values.reportVersion,
      assetId:props.assetIdent,
      current:values.isCurrent,
    }
    props.handleSave(temp);

  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={(e) => props.onClose()}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("report_version")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_asset_close"
            onClick={(e) => props.onClose()}
            style={{ marginLeft: "auto" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
            <Formik
              enableReinitialize
              initialValues={intialFormData}
              validationSchema={validationSchema}
              onSubmit={(values) =>onSave(values)}
              innerRef={formRef}
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                handleChange,
                isSubmitting,
              }) => (
                <Form>
                  <Grid item md={8} xs={12}>
                    <Field
                      disabled={props.editDialog}
                      name="reportVersion"
                      label={getLabel("report_version")}
                      as={TextField}
                      size="small"
                      dense={"true"}
                      className="grid-fields"
                      error={touched.reportVersion && errors.reportVersion}
                      helperText={touched.reportVersion && errors.reportVersion}
                    />
                  </Grid>
                  {props.option !== "update" && (
                  <Grid item md={8} xs={12}>
                  <Typography variant="body1" className="toolbar-heading">
                    <Checkbox
                      fontSize="small"
                      onChange={(e) => {
                        setFieldValue("isCurrent",e.target.checked)
                      }
                    }
                    />
                    {getIntlTranslation("mark-report-verison-current")}
                  </Typography>
                  </Grid>

                  )}
                </Form>
              )}
            </Formik>
      </DialogContent>
      <DialogActions className="justify-content-between"  style={{ marginLeft: "auto" }}>
        <SaveButton
          id="create-report-version-button"
          buttonText={props.buttonText}
          variant="button"
          tooltip={{
            title: "tooltip.create_report_version",
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

CreateReportVersion.propTypes = {
  open: PropTypes.bool,
  assetIdent:PropTypes.string,
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
};

export default CreateReportVersion;
