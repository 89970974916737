import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  b64toBlob,
  getIntlTranslation,
  getStylingForSelectedComment,
  getTranslationVariantText,
} from "../../../../../../utils/helper";
import { ImageUploaderContainer } from "./ImageUploaderContainer";
import ImageIcon from "@mui/icons-material/Image";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsPopup from "../../comments/CommentsPopup";
import { useSelector } from "react-redux";
import GeneralPhotos from "../../../../../../components/imageUploader/GeneralPhotos";
import { CloseOutlined } from "@mui/icons-material";
import DefectImages from "../../../../../../components/imageUploader/DefectImages";
import ReplayIcon from "@mui/icons-material/Replay";
import useChapterHook from "../../hooks/useChapterHook";
import useCommentSaveHook from "../../../../../component-bundle/hooks/useCommentSaveHook";
import useCommentFetchHook from "../../comments/useCommentFetchHook";
import { useSnackbar } from "notistack";
import { mergeStateWithFilterModel } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";

export const TypeImageContainerDataRender = ({ item, reportType }) => {
  let data = item.node;
  const imageRef = useRef(null);
  return (
    <>
      {data.reportImage ? (
        <Box className="d-flex justify-content-start w-100">
          <ImageUploaderContainer
            images={data.reportImages}
            ref={imageRef}
            reportType={reportType}
          />
        </Box>
      ) : (
        <Box className="d-flex align-items-center w-100">
          <RenderPreviewImageCaptions data={data} reportType={reportType} />
        </Box>
      )}{" "}
    </>
  );
};

export const TypeImagesPreviewRender = ({ item, reportType }) => {
  let data = item.node;
  return (
    <Box className="d-flex align-items-center w-100">
      {data.reportImage ? (
        <GetImageStructure data={data} reportType={reportType} />
      ) : (
        <RenderPreviewImageCaptions
          data={data}
          reportType={reportType}
          inReview={true}
        />
      )}
    </Box>
  );
};

const RenderImage = ({ meta, index, onCommentPopUp }) => {
  return (
    <>
    <Card  style={{ width: 315 }} className="m-1">
      <CardContent
      className="d-flex flex-column m-1 p-0 "
      style={{ width: 315 }}
      key={`${index}-${meta.ident}`}>
      <img
          src={b64toBlob(meta.file)}
          alt={meta.caption || ""}
          key={`photo-${meta.ident}-${meta.edited}`}
          style={{
            width: 300,
            height: 220,
            padding: 0,
            margin: 0,
          }}
        />
        <Divider
              style={{
                margin: "2px 0px",
                backgroundColor: "#d08f8e",
                height: 4,
              }}
            />
        <Box className="d-flex justify-content-start w-100">
          <Box className="w-75 d-flex p-1 flex-column"  key={`${index}-${meta.ident}`}>
        <Typography variant="caption" className="w-100 text-wrap">{`${meta.caption}`}</Typography>
        {!!meta.source && (
          <Typography variant="caption" className="w-100 text-wrap text-center">{`(${meta.source})`}</Typography>
        )}
          </Box>
          <Box className="w-25 d-flex p-1 justify-content-end">
          <Tooltip title={getIntlTranslation("tooltip.add-comments")}>
            <IconButton
              id={`comment_${meta.ident}`}
              classname="ml-auto"
              aria-describedby={`pop-comment-${meta.ident}`}
              onClick={(event) => onCommentPopUp(event, meta)}
            >
              <CommentIcon style={{ fontSize: 18, color: "#d08f8e" }} />
            </IconButton>
          </Tooltip>
          </Box>
        </Box>
      </CardContent>
      </Card>
      </>
   
  );
};

const RenderCaptions = ({ meta, index, inReview, onCommentPopUp }) => {
  return (
      <Box className="w-100 d-flex">
        <Chip
          label={index + 1}
          variant="outlined"
          color="primary"
          size="small"
          className="my-1"
        />

        <ImageIcon style={{ color: "#bbb8b8" }} className="mx-1" />
        <TextField
          id={`outlined-caption-${index}`}
          key={`outlined-caption-${index}`}
          defaultValue={meta.caption}
          placeholder={
            !!meta.captionPlaceholder ? meta.captionPlaceholder : "Caption"
          }
          variant="standard"
          multiline
          disabled={"true"}
          className="w-75"
          maxRows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {!!meta.capexID ? meta.capexID : ""}
              </InputAdornment>
            ),
          }}
        />
        {inReview && (
          <Box className="d-flex align-items-center">
            <Tooltip title={getIntlTranslation("tooltip.add-comments")}>
              <IconButton
                id={`comment_${meta.ident}`}
                aria-describedby={`pop-comment-${meta.ident}`}
                onClick={(event) => onCommentPopUp(event, meta)}
              >
                <CommentIcon style={{ fontSize: 18, color: "#d08f8e" }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
  );
};

const RenderImagePreview = ({ image, index, reportType, inReview }) => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const { enqueueSnackbar } = useSnackbar();
  const [commentPopup, setCommentPopup] = useState({
    open: false,
    node: null,
    id: null,
    uniqueIdentifier: null,
  });
  const apiParam = image?.nodeType;
  const [anchorEl, setAnchorEl] = useState(null);
  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );
  const [onCommentsSave] = useCommentSaveHook();
  const dataRef = useRef({
    ...image.imageMetaDto,
    path: image.path,
    ident: image.imageMetaDto.imageIdent,
    nodeType: image?.nodeType,
  });
  const meta = {
    ...image.imageMetaDto,
    ident: image.imageMetaDto.imageIdent,
    captionPlaceholder: image.captionPlaceholder,
    nodeType: image?.nodeType,
  };

  useEffect(() => {
    if (
      isCommentSaved.saved &&
      dataRef.current.uniqueIdentifier === commentPopup.uniqueIdentifier
    ) {
      dataRef.current.comment = {
        ...dataRef.current.comment,
        ident: isCommentSaved.commentsIdent,
      };

      isCommentSaved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setCommentPopup({
        open: false,
        node: null,
        id: null,
        uniqueIdentifier: null,
      });
      resetState();
    }
  }, [isCommentSaved]);

  const processSaveComments = (value) => {
    if (!!dataRef.current.comment) {
      let tempCommentsWrapper = {
        ...dataRef.current.comment,
        apiParam: dataRef.current.nodeType,
        scrollParent: "scrollParent",
        reportType: reportType,
        section: `${dataRef.current.nodeType}-${image.imageMetaDto.imageIdent}`,
      };
      const comments = [...tempCommentsWrapper.commentValues];
      comments.push({
        ...value,
        reportType: reportType,
        parentPath: dataRef.current.path,
        order: commentPopup?.node?.order,
        assetIdent: openAsset.ident,
      });
      onCommentsSave({
        commentsApi: tempCommentsWrapper.apiParam,
        data: { ...tempCommentsWrapper, commentValues: comments },
        apiIdent: dataRef.current.ident,
      });
    }
  };

  return (
    <>
      <Box
        className=""
        id={`scroll_item_${meta.imageIdent}`}
        key={meta.imageIdent}
        style={{
          ...getStylingForSelectedComment(
            isCommentSelected()?.propertyIdent,
            meta.imageIdent
          ),
          width:350,
          display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
            gap: 10,
            alignItems: "start",
        }}
      >
        {meta.nodeType === "report-image" ? (
          <RenderImage
            meta={meta}
            index={index}
            onCommentPopUp={(event, node) => {
              setCommentPopup({
                open: true,
                node: node,
                id: `pop-comment-${node.ident}`,
                uniqueIdentifier: `${apiParam}-${node.ident}`,
              });
              setAnchorEl(event.currentTarget);
              dataRef.current.uniqueIdentifier = `${apiParam}-${node.ident}`;
            }}
          />
        ) : (
          <RenderCaptions
            meta={meta}
            index={index}
            inReview={inReview}
            onCommentPopUp={(event, node) => {
              setCommentPopup({
                open: true,
                node: node,
                id: `pop-comment-${node.ident}`,
                uniqueIdentifier: `${apiParam}-${node.ident}`,
              });
              setAnchorEl(event.currentTarget);
              dataRef.current.uniqueIdentifier = `${apiParam}-${node.ident}`;
            }}
          />
        )}
      </Box>
      {commentPopup.open && !!commentPopup.node && (
        <CommentsPopup
          id={commentPopup.id}
          anchorEl={anchorEl}
          onClose={() => {
            setCommentPopup({
              open: false,
              node: null,
              id: null,
            });
            setAnchorEl(null);
          }}
          node={{
            ...commentPopup.node,
            nodeIdent: commentPopup.node.ident,
            nodeKey: "text",
          }}
          onSave={(value) => {
            processSaveComments(value);
          }}
        />
      )}
    </>
  );
};

const GetImageStructure = ({ data, reportType }) => {
  return (
    <Box className="d-flex flex-wrap align-items-center m-1 p-1 w-100">
      {data.reportImages?.map((image, index) => {
        const imageData = { ...image.imageMetaDto, src: "" };
        if (!!imageData.file) {
          return (
            <RenderImagePreview
              image={{...image,path:data.path}}
              index={index}
              reportType={reportType}
              inReview={true}
            />
          );
        } else {
          return <></>;
        }
      })}
    </Box>
  );
};

const RenderPreviewImageCaptions = ({ data, reportType, inReview = false }) => {
  const [sectionData, setSectionData] = useState(data);
  const { onLoadSectionData } = useChapterHook();
  const scope = useSelector((state) => state.container.scope);
  const [isEditing, setEditing] = useState({
    id: null,
    editing: false,
  });

  const totalImages = useMemo(
    () => sectionData.reportImages?.filter((i) => !!i.content),
    [data]
  );
  const parent = totalImages.length > 0 ? totalImages[0] : null;

  const getChapterAndSectionIdent = () => {
    const split = data.path.split("/");
    return {
      chapterIdent: split[0],
      ident: split[1],
    };
  };

  const renderImageEditDrawer = () => {
    if (!!parent) {
      const node = {
        type: "sub-asset",
        ident: parent?.imageMetaDto.genericIdent,
        projectIdent: scope.projectIdent,
        caption: "",
      };

      if ("general-image" === parent.nodeType) {
        return <GeneralPhotos node={node} />;
      } else if ("defect-image" === parent.nodeType) {
        return <DefectImages node={node} />;
      } else {
        <></>;
      }
    } else {
      <></>;
    }
  };
  return (
    <Box className="w-100">
      <Box className="d-flex flex-column m-1 p-1 w-100">
        <Chip
          label={
            <Typography
              variant="caption"
              className={`w-75 ${
                totalImages.length <= 0 ? "text-danger" : "text-primary"
              }`}
              style={{ fontSize: 14 }}
            >
              {getIntlTranslation("_total-images-uploaded")} :{" "}
              {`${totalImages.length}`}
            </Typography>
          }
          color="primary"
          variant="outlined"
          className="w-100 h-auto rounded-3 d-flex align-items-center justify-content-between"
        />
        <Box className="d-flex flex-wrap align-items-center m-1 p-1 w-100">
          {totalImages?.map((image, index) => {
            return (
              <RenderImagePreview
                image={{ ...image, path: sectionData.path }}
                index={index}
                inReview={inReview}
                reportType={reportType}
              />
            );
          })}
        </Box>
      </Box>
      {isEditing.editing && (
        <Drawer
          anchor={"right"}
          sx={{
            width: "40%",
            zIndex: 1800,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: "40%",
              boxSizing: "border-box",
            },
          }}
          className="h-100"
          open={isEditing.editing}
        >
          <AppBar className={"appBar w-100"}>
            <Toolbar className={"dialog-toolbar w-100"}>
              <IconButton
                edge="start"
                color="inherit"
                id="edit_images_close"
                onClick={(e) => {
                  setEditing({
                    id: null,
                    editing: false,
                  });
                }}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseOutlined />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box className="w-100 d-flex" style={{ height: "calc(100% - 50px)" }}>
            {renderImageEditDrawer()}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

const RenderImageWithCaption = ({ image, index, inReview, reportType }) => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const { enqueueSnackbar } = useSnackbar();
  const [commentPopup, setCommentPopup] = useState({
    open: false,
    node: null,
    id: null,
    uniqueIdentifier: null,
  });
  const apiParam = image?.nodeType;
  const [anchorEl, setAnchorEl] = useState(null);
  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );
  const [onCommentsSave] = useCommentSaveHook();
  const dataRef = useRef({
    ...image.imageMetaDto,
    path: image.path,
    ident: image.imageMetaDto.imageIdent,
    nodeType: image?.nodeType,
  });
  const meta = { ...image.imageMetaDto, ident: image.imageMetaDto.imageIdent };

  useEffect(() => {
    if (
      isCommentSaved.saved &&
      dataRef.current.uniqueIdentifier === commentPopup.uniqueIdentifier
    ) {
      dataRef.current.comment = {
        ...dataRef.current.comment,
        ident: isCommentSaved.commentsIdent,
      };

      isCommentSaved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setCommentPopup({
        open: false,
        node: null,
        id: null,
        uniqueIdentifier: null,
      });
      resetState();
    }
  }, [isCommentSaved]);

  const processSaveComments = (value) => {
    if (!!dataRef.current.comment) {
      let tempCommentsWrapper = {
        ...dataRef.current.comment,
        apiParam: dataRef.current.nodeType,
        scrollParent: "scrollParent",
        reportType: reportType,
        section: `${dataRef.current.nodeType}-${image.imageMetaDto.imageIdent}`,
      };
      const comments = [...tempCommentsWrapper.commentValues];
      comments.push({
        ...value,
        reportType: reportType,
        parentPath: dataRef.current.path,
        order: commentPopup?.node?.order,
        assetIdent: openAsset.ident,
      });
      onCommentsSave({
        commentsApi: tempCommentsWrapper.apiParam,
        data: { ...tempCommentsWrapper, commentValues: comments },
        apiIdent: dataRef.current.ident,
      });
    }
  };

  return (
    <>
      <Box
        className="d-flex flex-wrap align-items-center w-50 p-1"
        id={`scroll_item_${meta.imageIdent}`}
        key={meta.imageIdent}
        style={{
          ...getStylingForSelectedComment(
            isCommentSelected()?.propertyIdent,
            meta.imageIdent
          ),
          marginTop: 20,
        }}
      >
        <>
          <Chip
            label={index + 1}
            variant="outlined"
            color="primary"
            size="small"
            className="my-1"
          />

          <ImageIcon style={{ color: "#bbb8b8" }} className="mx-1" />
          <TextField
            id={`outlined-caption-${index}`}
            key={`outlined-caption-${index}`}
            defaultValue={meta.caption}
            placeholder={
              !!image.captionPlaceholder ? image.captionPlaceholder : "Caption"
            }
            variant="standard"
            multiline
            disabled={"true"}
            className="w-75"
            maxRows={4}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {!!meta.capexID ? meta.capexID : ""}
                </InputAdornment>
              ),
            }}
          />
          {inReview && (
            <Box className="d-flex align-items-center">
              <Tooltip title={getIntlTranslation("tooltip.add-comments")}>
                <IconButton
                  id={`comment_${meta.ident}`}
                  aria-describedby={`pop-comment-${meta.ident}`}
                  onClick={(event) => {
                    setCommentPopup({
                      open: true,
                      node: meta,
                      id: `pop-comment-${meta.ident}`,
                      uniqueIdentifier: `${apiParam}-${meta.ident}`,
                    });
                    setAnchorEl(event.currentTarget);
                    dataRef.current.uniqueIdentifier = `${apiParam}-${meta.ident}`;
                  }}
                >
                  <CommentIcon style={{ fontSize: 18, color: "#d08f8e" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </>
      </Box>
      {commentPopup.open && !!commentPopup.node && (
        <CommentsPopup
          id={commentPopup.id}
          anchorEl={anchorEl}
          onClose={() => {
            setCommentPopup({
              open: false,
              node: null,
              id: null,
            });
            setAnchorEl(null);
          }}
          node={{
            ...commentPopup.node,
            nodeIdent: commentPopup.node.ident,
            nodeKey: "text",
          }}
          onSave={(value) => {
            processSaveComments(value);
          }}
        />
      )}
    </>
  );
};

/*
onDelete={(event) => {
            event?.stopPropagation();
            setEditing({
              id: "",
              editing: true,
            });
          }}
          deleteIcon={
            <Box className="d-flex justify-content-end my-1 mr-1 g-btn-primary rounded-3">
              <Tooltip title={getIntlTranslation("tooltip.open")}>
                <Button
                  style={{ color: "#ffffff", height: 30 }}
                  className="p-1 mx-1"
                >
                  {getTranslationVariantText("_open-images", "caption")}
                </Button>
              </Tooltip>
              {inReview && (
                <>
                  <Divider
                    orientation="vertical"
                    style={{
                      width: 4,
                      margin: "10px 0px",
                      backgroundColor: "#ffffff",
                    }}
                    flexItem
                  />
                  <IconButton
                    className="p-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!!sectionData.path) {
                        onLoadSectionData(
                          getChapterAndSectionIdent(),
                          reportType
                        );
                      }
                    }}
                  >
                    <Tooltip title={getIntlTranslation("tooltip.reload")}>
                      <ReplayIcon style={{ fontSize: 20, color: "#ffffff" }} />
                    </Tooltip>
                  </IconButton>
                </>
              )}
            </Box>
            }
*/
