import React from 'react';
import ProjectDescriptionHome from "./ProjectDescriptionHome"
import { pmRoot } from  "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const ProjectDescriptionRouteConfig = {
  routes: [
		{
    path: `${pmRoot}/project/project-description`,
    exact: true,
    name: <IntlMessages id={"menu.project-description"}/>,
    element: <ProjectDescriptionHome/>,
    auth: [],
  },
	]
};
export default ProjectDescriptionRouteConfig;