import React from "react";
import { globalRoot } from "../../utils/defaultValues";
import EsgModuleHome from "./EsgModuleHome"
import IntlMessages from "../../utils/IntlMessages";
const EsgModuleRouteConfig = {
  routes: [
    {
      path: `${globalRoot}/esg/*`,
      exact: true,
      name: <IntlMessages id={`menu.esg-management`}/>,
      element: <EsgModuleHome/>,
      auth: [],
      children: [],
    },
  ],
};
export default EsgModuleRouteConfig;
