import React from 'react'
import PhotoDocumentationContainer from "./structure/PhotoDocumentationContainer"

function PhotoDocumentationHome(props) {
  return (
    <div
    style={{height:"100%", backgroundColor: '#ffffff'}}
  >
  <PhotoDocumentationContainer/>
  </div>
  )
}

export default PhotoDocumentationHome
