import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import CustomButton from "../../../../../components/buttons/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  getIntlTranslation,
  getLabel,
  getVariantText,
  getOverlineText,
  getDialogBoxHeader,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StopIcon from "@mui/icons-material/Stop";
import LinearProgress from "@mui/material/LinearProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const AddCustomPropertiesContainer = ({open, onClose,selectedParent,data,onAddOptional}) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.keyFindings);
  const [customProperties, setCustomProperties] = useState([]);
  const [customCategory, setCustomCategory] = useState([]);
  const [categories,setCategories] = useState([]);

  useEffect(() => {
    if(data.length > 0){
      setCategories(data);
      setCustomCategory([]);
      setCustomProperties([]);
    }
  }, [data])
  
  const addCustomCategory = (item) => {
    setCustomCategory((prev) => {
      const temp = [...prev];
      temp.push({
        custom: true,
        nodeIdent: "",
        labelTranslation: [],
        nodeName: "",
        propertyType: "text",
        required: false,
        type: "keyFindingsCategory",
        id: Date.now(),
        properties: [],
        update: true,
        isNew: true,
      });
      return temp;
    });
  };

  const addCustomProperties = (item) => {
    setCustomProperties((prev) => {
      const temp = [...prev];
      temp.push({
        categoryIdent: item?.nodeIdent,
        custom: true,
        nodeIdent: "",
        labelTranslation: [],
        mapProperties: [],
        nodeName: "",
        propertyType: "text",
        required: false,
        type: "keyFindingsProperty",
        id: Date.now(),
        update: true,
        isNew: true,
        pId: item.id,
      });
      return temp;
    });
  };
  const getCustomCategoryText = (value, item, name) => {
    setCustomCategory((prev) => {
      let temp = prev.map((m) => {
        if (m.id === item.id) {
          m[`${name}`] = value;
        }
        return m;
      });
      return temp;
    });
  };

  const removeCustomCategory = (obj) => {
    setCustomCategory((prev) => {
      let temp = prev.filter((f) => f.id !== obj.id);
      return temp;
    });
  };

  const getCustomPropertiesText = (value, item, name) => {
    setCustomProperties((prev) => {
      let temp = prev.map((m) => {
        if (m.id === item.id) {
          m[`${name}`] = value;
        }
        return m;
      });
      return temp;
    });
  };

  const removeCustomProperties = (obj) => {
    setCustomProperties((prev) => {
      let temp = prev.filter((f) => f.id !== obj.id);
      return temp;
    });
  };

  const handleSave = () => {
    let customCategoryArr = [];
    let val = categories.map((item) => {
      var propArr = customProperties
        .filter((f) => f.categoryIdent === item.nodeIdent && f.custom)
        .map((m) => ({
          categoryIdent: m.categoryIdent,
          custom: true,
           nodeIdent: m.nodeIdent,
          labelTranslation: m.labelTranslation,
          mapProperties: m.mapProperties,
          nodeName: m.nodeName,
          propertyType: "text",
          required: m.required,
          type: "keyFindingsProperty",
          updated: m.updated,
          isNew: m.isNew,
        }));

      let properties = [...item.properties, ...propArr].map((m, index) => ({
        ...m,
        nodeOrder: index + 1,
      }));
      return { ...item, properties: properties, mapProperties: [] };
    });

    if (customCategory.length > 0) {
      customCategoryArr = customCategory
        .filter((f) => !!f.nodeName)
        .map((m) => {
          let prop = customProperties
            .filter((f) => f.pId === m.id && f.custom)
            .map((m) => ({
              categoryIdent: m.categoryIdent,
              custom: true,
              nodeIden: m.nodeIdent,
              labelTranslation: m.labelTranslation,
              mapProperties: m.mapProperties,
              nodeName: m.nodeName,
              propertyType: "text",
              required: m.required,
              type: "keyFindingsProperty",
              updated: m.updated,
              isNew: m.isNew,
            }));

          let properties = [...m.properties, ...prop].map((m, index) => ({
            ...m,
            nodeOrder: index + 1,
          }));
          return { ...m, properties: properties, mapProperties: [] };
        });
    }

    let combined = [...val, ...customCategoryArr].map((m, index) => ({
      ...m,
      nodeOrder: index + 1,
    }));
    onAddOptional(combined);
  };

  const getName = (m, style) => {
    const inputObj = m?.labelTranslation.find(
      (e) => e.language === scope.projectLanguageCode
    );
    return getVariantText(inputObj.title || m.nodeName, "body2", style);
  };


  const getCustomProperties = useCallback(
    (item) => customProperties?.filter(
        (f) => f.categoryIdent === item.nodeIdent
      ),
    [customProperties],
  )
  

  const createCategoryCard = (item) => {
    return (
      <>
        <ListItem 
        className={"w-100 d-flex flex-column align-items-start"}
        key={`key-${item.nodeName}-${item.nodeIdent}`}
        >
          <ListItemText
            className={"w-100"}
            primary={
              <div className="w-100 d-flex">
                {getName(item, {
                  fontWeight: "bold",
                  color: "#254a9a",
                  margin: 5,
                  width: "25%",
                  textTransform: "uppercase",
                })}
                <Card style={{ width: "75%" }}>
                  <CardContent
                    className={"d-flex flex-wrap"}
                    style={{ padding: 5 }}
                  >
                    <div
                      style={{ width: "90%" }}
                      className={"d-flex flex-wrap"}
                    >
                      {item.properties.map((m, index) => {
                        return (
                          <>
                            <div
                              style={{ margin: 5 }}
                              className={
                                "d-flex align-items-center justify-content-start"
                              }
                              key={`key-${item.nodeName}-${item.nodeIdent}-${index}`}
                            >
                              <StopIcon
                                style={{
                                  color: "#d08f8e",
                                  fontSize: 15,
                                  marginLeft: 5,
                                }}
                              />
                              {getName(m, {
                                margin: "5px 0px",
                                width: "100%",
                                textTransform: "capitalize",
                              })}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </CardContent>
                  <Box>
                    {getOverlineText("Add Custom Properties")}
                    <IconButton
                      autoFocus
                      style={{ margin: 5 }}
                      onClick={() => addCustomProperties(item)}
                    >
                      <AddIcon style={{ color: "#254a9a" }} />
                    </IconButton>
                    <Box>
                      {getCustomProperties(item).length > 0 ? (
                       getCustomProperties(item)
                          .map((item, index) => {
                            return (
                              <div
                                className="d-flex justify-content-start"
                                style={{
                                  margin: 10,
                                  background: "#ff2a5130",
                                  borderRadius: 5,
                                }}
                                key={`key-${item.id}-${index}`}
                              >
                                <TextField
                                  multiline
                                  maxRows={4}
                                  id={`${item.id}_${index}`}
                                  style={{ margin: 10, width: 300 }}
                                 
                                  onBlur={(e) =>
                                    getCustomPropertiesText(
                                      e.target.value,
                                      item,
                                      "nodeName"
                                    )
                                  }
                                  label={getLabel("_item_name")}
                                  variant="standard"
                                />
                                <IconButton
                                  autoFocus
                                  style={{ margin: 15 }}
                                  onClick={() => removeCustomProperties(item)}
                                >
                                  <DeleteForeverIcon
                                    style={{ color: "#f50057" }}
                                  />
                                </IconButton>
                              </div>
                            );
                          })
                      ) : (
                        <>
                          {getTranslationVariantText(
                            "add_custom_properties_placeholder",
                            "caption",
                            {
                              textTransform: "uppercase",
                              marginLeft: 20,
                              color: "#d08f8e",
                              height: 30,
                              display: "block",
                            }
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                </Card>
              </div>
            }
          />
        </ListItem>
        <Divider
          variant="middle"
          className="g-divider-vertical"
          style={{ margin: "10px 10px" }}
        />
      </>
    );
  };

  const createCustomCategoryCard = (item, index) => {
    return (
      <>
        <ListItem
          className="d-flex justify-content-start flex-column align-items-start"
          style={{ background: "#ff2a5130" }}
          key={`key-custom-category-${index}`}
        >
          <ListItemText
            className={"w-100"}
            primary={
              <div className={"w-100 d-flex flex-wrap align-items-center"}>
                <Box className={"d-flex"} style={{ width: "25%" }}>
                  <TextField
                    multiline
                    maxRows={4}
                    id={`${item.id}_${index}`}
                    style={{ width: "90%", margin:5 }}
                    onBlur={(e) =>
                      getCustomCategoryText(e.target.value, item, "nodeName")
                    }
                    label="Category Name"
                    variant="standard"
                  />
                  <IconButton
                    autoFocus
                    onClick={() => removeCustomCategory(item)}
                    style={{ marginLeft: "auto" }}
                  >
                    <DeleteForeverIcon style={{ color: "#f50057" }} />
                  </IconButton>
                </Box>
                <Card style={{ width: "75%",background: "inherit"}}>
                  <CardContent className="w-100 p-0">
                    {getOverlineText("Add Custom Properties")}
                    <IconButton
                      autoFocus
                      style={{ margin: 5 }}
                      onClick={() => addCustomProperties(item)}
                    >
                      <AddIcon style={{ color: "#254a9a" }} />
                    </IconButton>
                    <Box>
                      {customProperties?.filter((f) => f.pId === item.id)
                        .length > 0 ? (
                        customProperties
                          ?.filter((f) => f.pId === item.id)
                          .map((item, index) => {
                            return (
                              <div
                                className="d-flex justify-content-start"
                                style={{ margin: 10}}
                                key={`key-custom-${item.id}-${index}`}
                              >
                                <TextField
                                  multiline
                                  maxRows={4}
                                  id={`${index}`}
                                  style={{ margin: 10, width: 300 }}
                                  
                                  onBlur={(e) =>
                                    getCustomPropertiesText(
                                      e.target.value,
                                      item,
                                      "nodeName"
                                    )
                                  }
                                  label="Property Name"
                                  variant="standard"
                                />
                                <IconButton
                                  autoFocus
                                  style={{ margin: 15 }}
                                  onClick={() => removeCustomProperties(item)}
                                >
                                  <DeleteForeverIcon
                                    style={{ color: "#f50057" }}
                                  />
                                </IconButton>
                              </div>
                            );
                          })
                      ) : (
                        <>
                          {getTranslationVariantText(
                            "add_custom_properties_placeholder",
                            "caption",
                            {
                              textTransform: "uppercase",
                              marginLeft: 20,
                              color: "#d08f8e",
                              height: 30,
                              display: "block",
                            }
                          )}
                        </>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </div>
            }
          />
        </ListItem>
        <Divider
          variant="middle"
          className="g-divider-vertical"
          style={{ margin: "10px 10px" }}
        />
      </>
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("categories")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => onClose()}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {categories.length > 0 ? (
      <DialogContent dividers className="custom-scrollBar">
        <div>
          
            <List className={"w-100"}>
              {categories.map((item) => createCategoryCard(item))}
              {customCategory?.map((item, index) =>
                createCustomCategoryCard(item, index)
              )}
            </List>
          
        </div>
      </DialogContent>)
      :(
        <>
        <Box>
        <LinearProgress/>
        </Box>
        </>
        )
      }
      <DialogActions>
        <CustomButton
          id={`add-category-custom-button`}
          buttonText={"_create-category"}
          variant="button"
          style={{ marginLeft: 5 }}
          className="g-btn-secondary"
          tooltip={{
            title: getIntlTranslation("tooltip.add-custom"),
          }}
          handleClick={(e) => addCustomCategory()}
        />
        <CustomButton
          id={`add-options-custom-button`}
          buttonText={"add"}
          variant="button"
          style={{ marginLeft: "auto" }}
          tooltip={{
            title: getIntlTranslation("tooltip.add-custom"),
          }}
          handleClick={(e) => handleSave()}
        />
      </DialogActions>
    </Dialog>
  );
};



AddCustomPropertiesContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  categories: PropTypes.array,
  onAddOptional: PropTypes.func,
};

export default React.memo(AddCustomPropertiesContainer);
