import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { riskTypeOptions, statusTypeOptions } from "../../../../../common/dropdowns";
import { usePropertiesData } from "./PropertiesDataContext";

const SelectedPropertyContext = createContext();

export const useSelectedProperty = () => useContext(SelectedPropertyContext);

export const SelectedPropertyProvider = ({ children }) => {
  const scope = useSelector((state) => state.container.scope);
  const { structuredProperties } = usePropertiesData();
  const [selectedProperty, setSelectedProperty] = useState(null);

  const getRisk = useCallback(
    (risk) =>
      !!risk ?
      riskTypeOptions.find(
        (f) =>
          f.language === scope.projectLanguageCode &&
          f.value.toLowerCase() === risk.toLowerCase()
      ): { title: "", value: "" },
    [scope.projectLanguageCode]
  );

  const getStatus = useCallback(
    (status) =>
      !!status ?
      statusTypeOptions.find(
        (f) =>
          f.language === scope.projectLanguageCode &&
          f.value.toLowerCase() === status.toLowerCase()
      ): { title: "", value: "" },
    [scope.projectLanguageCode]
  );



  useEffect(() => {
    if(!selectedProperty){
      const propertyKeys = Object.keys(structuredProperties.nodes);
      if (propertyKeys.length > 0) {
        setSelectedProperty(structuredProperties.nodes[propertyKeys[0]]);
      }
    }
    if(!!selectedProperty){
      const propertyKeys = Object.keys(structuredProperties.nodes);
      if (propertyKeys.length > 0 && !structuredProperties.nodes[selectedProperty.nodeIdent]) {
        setSelectedProperty(structuredProperties.nodes[propertyKeys[0]]);
      }
    }
  }, [structuredProperties,selectedProperty]);

  return (
    <SelectedPropertyContext.Provider value={{ selectedProperty, setSelectedProperty, getRisk, getStatus }}>
      {children}
    </SelectedPropertyContext.Provider>
  );
};
