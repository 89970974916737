import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectUsers } from "../../../../../api/userManagementApi";
import { addMentions, resetState as resetUserState } from '../../../../component-bundle/users/usersSlice';


function useProjectUsersHook (props) {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const usersState = useSelector((state) => state.users);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if(props.isFetching){
      dispatch(
        getProjectUsers({
          ident: scope.projectIdent,
        })
      );
    }
   
  }, [dispatch, scope.projectIdent, props.isFetching]);

  useEffect(() => {
    if (
      usersState.projectUsers.type === "FETCHED" &&
      usersState.projectUsers.data.length > 0
    ) {
      let temp = usersState.projectUsers.data.map((user) => {
        return {
          title: `${user.firstName} ${user.lastName}`,
          ...user,
          id: user.userId,
          display:`@${user.firstName} ${user.lastName}`,
        };
      });
      setUsers(temp);
      dispatch(addMentions(temp))
    }
  }, [dispatch, usersState.projectUsers]);

  return {users};
}

export default useProjectUsersHook;
