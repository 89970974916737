import React, { useState, useEffect } from "react";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import Grid  from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import PublicIcon from "@mui/icons-material/Public";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import jsonp from "jsonp";
import { useIntl } from "react-intl";

const { REACT_APP_HMAP_API } = process.env;

const PlacesAutocomplete = ({ setLat, setLng, setGetAddress, fullAddress }) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");
  const [apiData, setApiData] = useState([]);
  const [apiStatus, setApiStatus] = useState(0);

  const handleSearch = async (e) => {
    await jsonp(
      `https://autocomplete.search.hereapi.com/v1/autocomplete?apiKey=${REACT_APP_HMAP_API}&q=${e}`,
      null,
      (err, data) => {
        if (err) {
          console.error(err.message);
        } else if(!!data && data.items.length > 0) {
          setApiData(data.items);
          setApiStatus(200);
        }else{
          setApiData(data.items);
          setApiStatus(0);
        }
      }
    );
  };
  const handleInput = (e) => {
    setInputValue(e.target.value);
    if (inputValue.length > 6) {
      handleSearch(inputValue);
    }
  };
  const handleClick = () => {
    if (inputValue.length > 0) {
      handleSearch(inputValue);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (inputValue.length > 0) {
        handleSearch(inputValue);
      }
    }
  };
  useEffect(() => {
    if (!!fullAddress && inputValue.length <= 0) {
        setInputValue(fullAddress);
        handleSearch(fullAddress);
    }
  }, [fullAddress]);

  const handleSelect = (event, description, address) => {
    event.preventDefault();
    const street =
      address.street && address.houseNumber
        ? address.street + " " + address.houseNumber
        : address.street;
    setGetAddress({
      city: address.city ? address.city : "",
      country: address.countryName ? address.countryName : "",
      postalCode: address.postalCode ? address.postalCode : "",
      street: street ? street : "",
      state: address.state ? address.state : "",
      label: description,
    });

    setInputValue(description, false);
    setApiStatus(0);
    getGeocode({ address: description })
      .then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setLat(lat);
        setLng(lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    apiData.map((suggestion) => {
      return (
        <ListItem
          key={suggestion.id}
          onClick={(e) =>
            handleSelect(e, suggestion?.address?.label, suggestion.address)
          }
          style={{ margin: 5, cursor: "pointer" }}
        >
          <ListItemText>{suggestion?.address?.label}</ListItemText>
        </ListItem>
      );
    });
  const shadow =
    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)";

  return (
    <>
      <Grid>
        <Paper style={{ padding: "2px 4px", display: "flex", alignItems: "center" , backgroundColor:"#f0f8ff"}}>
          <IconButton style={{ padding: "10px",color:"#254a9a"}} aria-label="menu" disabled>
            <PublicIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            multiline
            value={!inputValue ?  null : inputValue }
            placeholder={intl.formatMessage({ id:"search google maps" })}
            inputProps={{ "aria-label": "search google maps" }}
            onChange={(e) => handleInput(e)}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <IconButton
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClick}
          >
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
        {apiStatus === 200 && (
          <List dense={"true"} style={{ boxShadow: shadow }}>
            {renderSuggestions()}
          </List>
        )}
      </Grid>
    </>
  );
};

export default PlacesAutocomplete;
