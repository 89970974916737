import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  defectList: [],
  measureList: [],
  data:{},
  error: {},
};
// A slice for auth with our success or failure reducers
const costElementSlice = createSlice({
  name: "costElementSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    setDefectAndMeasureList: (state, { payload }) => ({
      defectList: payload.defectList,
      measureList: payload.measureList,
      type: "",
      messages: "",
      data:null,
    }),
    resetSliceState: (state) => ({
      ...initialState
    }),
     getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetSliceState,
  getSuccess,
  setDefectAndMeasureList,
} = costElementSlice.actions;

// A selector
export const elementSelector = (state) => state.tddCostElement;

// The reducer
export default costElementSlice.reducer;
