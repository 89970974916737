import React from 'react';
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import StatutoryComplianceHome from "./StatutoryComplianceHome";

const StatutoryComplianceRouteConfig = {
  routes: [
		{
    path: `${tddRoot}/project/statutory-compliance`,
    exact: true,
    name: <IntlMessages id={"menu.statutoryCompliance"}/>,
    element: <StatutoryComplianceHome/>,
    auth: [],
  },
	]
};

export default StatutoryComplianceRouteConfig;
