import { IconButton, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import EditIconButton from "../../../../../../components/buttons/icons-buttons/EditIconButton";
import { getIntlTranslation, getStylingForSelectedComment, shortenString } from "../../../../../../utils/helper";
import { useSelector } from "react-redux";
import EditDefect from "../../../../feature-bundle/defects/edit/EditDefect";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsPopup from "../../comments/CommentsPopup";
import useCommentFetchHook from "../../comments/useCommentFetchHook";
import useCommentSaveHook from "../../../../../component-bundle/hooks/useCommentSaveHook";
import { useSnackbar } from "notistack";
import useFetchContentFireBase from "../../hooks/firebase/useFetchContentHook";
import useSaveContentFireBase from "../../hooks/firebase/useSaveContentHook";
import { EditingIndicator } from "../EditingIndicator";
import useChapterHook from "../../hooks/useChapterHook";
import { FoundationRounded } from "@mui/icons-material";

const CreateRow = ({dRow, index, reportType}) => {
  const [dataRow,setDatRow] = useState(dRow)
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const { enqueueSnackbar } = useSnackbar();
  const { onLoadSectionData } = useChapterHook();
  const [commentPopup, setCommentPopup] = useState({
    open: false,
    node: null,
    id: null,
    uniqueIdentifier: null,
  });
  const [isEditing, setEditing] = useState({
    id: null,
    editing: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const dataRef = useRef({...dRow});
  const apiParam = "defect";
  const [onCommentsSave] = useCommentSaveHook();

  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );
  const { onEdit } = useSaveContentFireBase({
    apiParam: apiParam,
    data:{
      ident:dRow.ident,
      path:dRow.path,
      properties:[dRow],
    },
    reportType:  reportType
  }
  );
  const { updatedNode, setUpdatedNode, locked, users } = useFetchContentFireBase({
    nodePath: `${apiParam}-${dRow.ident}`,
    reportType,
  });

  useEffect(() => {
    dataRef.current = {...dataRow};
  }, [dataRow]);

  useEffect(() => {
    if (
      !isEditing.editing &&
      !!updatedNode &&
      `${apiParam}-${dataRef.current.ident}` === updatedNode?.ident
      && scope.projectIdent === updatedNode?.projectIdent
      && openAsset.ident === updatedNode?.assetIdent
      && reportType === updatedNode?.reportType
    ) {
      let tempNode =Object.values(updatedNode.node)[0];
      if(tempNode.node==="defect_node"){
        setDatRow((prev) => ({...prev,row:tempNode.row}))
        dataRef.current = {...tempNode};
      }
      setUpdatedNode(null);
    }
  }, [isEditing.editing, updatedNode, scope, openAsset]);

  useEffect(() => {
    if (
      isCommentSaved.saved &&
      dataRef.current.uniqueIdentifier === commentPopup.uniqueIdentifier
    ) {
      dataRef.current.comment = {
        ...dataRef.current.comment,
        ident: isCommentSaved.commentsIdent,
      };

      isCommentSaved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setCommentPopup({
        open: false,
        node: null,
        id: null,
        uniqueIdentifier: null,
      });
      resetState();
    }
  }, [isCommentSaved]);

  const processSaveComments = (value) => {
    if (!!dataRef.current.comment) {
     
      let tempCommentsWrapper = {
        ...dataRef.current.comment,
        apiParam: apiParam,
        scrollParent: "scrollParent",
        reportType: reportType,
        section: `defect-${commentPopup.node?.nodeIdent}`,
        subAssetIdent: commentPopup.node.subAssetIdent,
      };
      const comments = [];
      comments.push({ ...value, 
        reportType: reportType,
        subAssetIdent: commentPopup.node.subAssetIdent,
        parentPath: dataRef.current.path,
        order:commentPopup?.node?.order,
        assetIdent: openAsset.ident,
      });
      onCommentsSave({
        commentsApi: tempCommentsWrapper.apiParam,
        data: { ...tempCommentsWrapper, commentValues: comments },
        apiIdent: commentPopup.node.nodeIdent,
      });
    }
  }

  const getChapterAndSectionIdent = () => {
      const split = dataRef.current.path.split('/');
      return ({
        chapterIdent:split[0],
        ident:split[1],
      })
  }

  const onDefectUpdate = () => {
     if(!!dataRef.current.path){
      onLoadSectionData(getChapterAndSectionIdent(), reportType)
    }
    onEdit(apiParam,  dRow.ident, reportType, false);
    setEditing({
      id: null,
      editing: false,
    });
  }

  return (
    <>
    <tr
      id={`scroll_item_${dRow.ident}`}
      className={`w-100 ${(!isEditing.editing && locked?.isLocked) ? "locked-indicator" : ""}`}
      style = {{...getStylingForSelectedComment(isCommentSelected()?.propertyIdent,dRow.ident),margin: "10px 5px", height: "auto" }}
     
    >
      {dataRow.row.map((cell, cellIndex) => {
        if (dataRow.node === "defect_node") {
          dataRef.current.comment =  {...dataRow.comment}
        }
        return (
          <>
            {cell.node === "ranking-node" ? (
              <>
                <td
                  style={{
                    ...createStyling(cell, cellIndex),
                    width: "15%",
                  }}
                  key={`${cellIndex}-cell-${dataRow.ident}`}
                >
                  <Box className="d-flex flex-column h-100">
                    <Box>{parseNode(cell.value)}</Box>
                  </Box>
                </td>
              </>
            ) : (
              <>
                <td
                  colSpan={cell.colSpan != null ? cell.colSpan : null}
                  style={createStyling(cell, cellIndex)}
                >
                  <Box className="d-flex align-item-center m-1">
                    {cell.bulletPoint &&
                      cellIndex === 0 &&
                      cell.node === "defect_node" && (
                        <div>
                          <span
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                              display: "inline-block",
                              width: "5px",
                              height: "5px",
                              backgroundColor: "#254a9a",
                              borderRadius: "0",
                              listStyleType: "square",
                            }}
                          ></span>
                        </div>
                      )}
                    {cell.value}
                  </Box>
                </td>
              </>
            )}
          </>
        );
      })}
      {dataRow.editable && dataRow.node === "defect_node" && (
        <td>
          <Box
            className="d-flex g-btn-secondary p-1 flex-column rounded-3"
            id={`action_${dataRow.ident}`}
            style={{width:40}}
          >
            <EditIconButton
              id={`edit_${dataRow.ident}`}
              handleClick={() => {
                setEditing({ id: dataRow.ident, editing: true });
                onEdit(apiParam,  dataRow.ident, reportType, true);
              }
            }
              tooltip={{
                title: getIntlTranslation("tooltip.edit"),
              }}
              iconStyle={{
                fontSize: 18,
              }}
              className="p-1"
              color="#254a9a"
            />

            <Tooltip
              title={getIntlTranslation("tooltip.add-comments")}
            >
              <IconButton
                aria-describedby={`pop-comment-${dataRow.ident}`}
                id={`comment_${dataRow.ident}`}
                onClick={(event) => {
                  setCommentPopup({
                    open: true,
                    node: {...dataRow,nodeKey: "defect", nodeIdent: dataRow.ident,order:index+1},
                    id: `pop-comment-${dataRow.ident}`,
                    uniqueIdentifier: `${apiParam}-${dataRow.ident}`,
                  });
                  setAnchorEl(event.currentTarget);
                  dataRef.current.uniqueIdentifier = `${apiParam}-${dataRow.ident}`;
                }}
              >
                <CommentIcon
                  style={{ fontSize: 18, color: "#d08f8e" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </td>
      )}
    </tr>
    {(!isEditing.editing && locked?.isLocked) && <EditingIndicator user={locked.lockedBy} />}
    {commentPopup.open && !!commentPopup.node && (
      <CommentsPopup
        id={commentPopup.id}
        anchorEl={anchorEl}
        onClose={() =>{
          setCommentPopup({
            open: false,
            node: null,
            id:null,
            uniqueIdentifier:null,
          });
          setAnchorEl(null);
        }}
        node={commentPopup.node}
        onSave={(value) => {
          processSaveComments(value);
        }}
      />
    )}
       {isEditing.editing && (
          <EditDefect
            open={isEditing.editing}
            defectIdent={isEditing.id}
            assetIdent={openAsset.ident}
            onClose={() =>{
              onEdit(apiParam, isEditing.id, reportType, false);
              setEditing({
                id: null,
                editing: false,
              });
            }}
            onUpdate={(val, saved) => onDefectUpdate()}
          />
        )}
    </>
  );
}



const CreateRowEditingMode  = ({dRow, selectedItem, index, reportType}) => {
  const scope = useSelector((state) => state.container.scope);
  const { enqueueSnackbar } = useSnackbar();
  const openAsset = useSelector((state) => state.openAsset);
  const [isEditing, setEditing] = useState({
    id: null,
    editing: false,
  });
  const dataRef = useRef({...dRow});
  const { onLoadSectionData } = useChapterHook();

  return (
    <React.Fragment key={`scroll_item_${dRow.ident}`}>
    <tr
      className='w-100'
      id={`scroll_item_${dRow.ident}`}
      style = {{margin: "10px 5px", height: "auto" }}
    >
      {dRow.row.map((cell, cellIndex) => {
        if (dRow.node === "defect_node") {
          dataRef.current.comment =  {...dRow.comment}
        }
        return (
          <>
            {cell.node === "ranking-node" ? (
              <>
                <td
                  style={{
                    ...createStyling(cell, cellIndex),
                    width: "15%",
                  }}
                  key={`${cellIndex}-cell-${dRow.ident}`}
                >
                  <Box className="d-flex flex-column h-100">
                    <Box>{parseNode(cell.value)}</Box>
                  </Box>
                </td>
              </>
            ) : (
              <>
                <td
                  colSpan={cell.colSpan != null ? cell.colSpan : null}
                  style={createStyling(cell, cellIndex)}
                >
                  <Box className="d-flex align-item-center m-1">
                    {cell.bulletPoint &&
                      cellIndex === 0 &&
                      cell.node === "defect_node" && (
                        <div>
                          <span
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                              display: "inline-block",
                              width: "5px",
                              height: "5px",
                              backgroundColor: "#254a9a",
                              borderRadius: "0",
                              listStyleType: "square",
                            }}
                          ></span>
                        </div>
                      )}
                    {cell.value}
                  </Box>
                </td>
              </>
            )}
          </>
        );
      })}
      {dRow.editable && dRow.node === "defect_node" && (
        <td className="d-flex p-1 align-items-baseline justify-content-center">
          <Box
            className="d-flex g-btn-secondary p-1 flex-column rounded-3"
            id={`action_${dRow.ident}`}
            style={{width:40, marginTop:10}}
          >
            <EditIconButton
              id={`edit_${dRow.ident}`}
              handleClick={() => {
                setEditing({ id: dRow.ident, editing: true });
                
              }
            }
              tooltip={{
                title: getIntlTranslation("tooltip.edit"),
              }}
              iconStyle={{
                fontSize: 18,
              }}
              className="p-1"
              color="#254a9a"
            />
          </Box>
        </td>
      )}
    </tr>


    {isEditing.editing && (
      <EditDefect
        open={isEditing.editing}
        defectIdent={isEditing.id}
        assetIdent={openAsset.ident}
        onClose={() =>{
          setEditing({
            id: null,
            editing: false,
          });
        }}
        onUpdate={(val, saved) => {
          //onSave(apiParam, {...saved.data, ident:isEditing.id}, reportType)
          setEditing({
            id: null,
            editing: false,
          });
          onLoadSectionData(selectedItem, reportType);
        }}
      />
    )}
    </React.Fragment>
  )
}

const CreateTableStructure = ({ data,selectedItem={},reportType,reviewMode=false }) => {
  if (data.rows.length > 0) {
    return (
      <>
        <tbody>
          {reviewMode ? 
          <>
          {data.rows.map((dRow, index) => {
            return (<CreateRow key={index} dRow={{...dRow,path:data.path}} index={index} reportType = {reportType}/>)
           })
           
        }
        </>
        :
        <>
        
          {data.rows.map((dRow, index) => {
           return (<CreateRowEditingMode key={index} selectedItem={selectedItem} dRow={{...dRow,path:data.path}} index={index} reportType = {reportType}/>)
          })}
        
        </>
        }
          
        </tbody>

      </>
    );
  } else {
    return <></>;
  }
};

const traverse = (node) => {
  let result = [];
  if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === "RANKING") {
    const dataAttributes = node.dataset;
    result.push(
      <Box className="d-flex flex-wrap align-items-center m-1">
        <span>{dataAttributes["label"]} : </span> 
        <span style={{ color: dataAttributes["color"] }}>
          {dataAttributes["value"]}
        </span>
      </Box>
    );
  } else if (node.nodeType === Node.TEXT_NODE) {
    result.push(<span className="m-1">{`(${node.textContent.trim()})`}</span>);
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    if (node.childNodes.length > 0) {
      for (let child of node.childNodes) {
        result.push(...traverse(child));
      }
    }
  }
  return result;
};
const parseNode = (node) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(node, "text/html");
  const body = doc.body;
  let rankingNode = traverse(body);
  return rankingNode;
};

const getBorderColor = (borders) => {
  let border = {};
  borders.borderTypes.forEach((borderType) => {
    if (borderType.side === "top") {
      border["borderTop"] = `1px ${
        borderType.typeOfBorder === "single" ? "solid" : borderType.typeOfBorder
      } `;
    } else if (borderType.side === "bottom") {
      border["borderBottom"] = `1px ${
        borderType.typeOfBorder === "single" ? "solid" : borderType.typeOfBorder
      } `;
    } else if (borderType.side === "left") {
      border["borderLeft"] = `1px ${
        borderType.typeOfBorder === "single" ? "solid" : borderType.typeOfBorder
      } `;
    } else if (borderType.side === "right") {
      border["borderRight"] = `1px ${
        borderType.typeOfBorder === "single" ? "solid" : borderType.typeOfBorder
      } `;
    }
  });
  return border;
};

const createStyling = (cell, index) => {
  let borders = getBorderColor(cell.borders);
  return {
    backgroundColor: !!cell.bgColor ? `#${cell.bgColor}` : "",
    fontWeight: cell.bold ? "bold" : null,
    color: !!cell.textColor ? `#${cell.textColor}` : null,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    minHeight: "40px",
    height: "40px",
    width:"80%",
    textTransform: cell.node === "element_node" ? "capitalize" : "normal",
    ...borders,
  };
};
export const TypeDefectTablePreviewRender = ({ item, reportType }) => {
  const apiParam ="defect-table"
  const [data,setData] = useState(item.node);
  const {currentChapterDatas,setCurrentChapterDatas} = useChapterHook();
  const dataRef = useRef({ ident: `properties` });
  const { onSave } = useSaveContentFireBase({});

  useEffect(() => {
    if (!!currentChapterDatas && 'reportDataNode' in currentChapterDatas) {
      const found = currentChapterDatas.reportDataNode.children.map((m) => m.children)?.flat(1).find((f) => f.nodeType === "data-defect-table");
      if(!!found) {
        found.node.rows.filter(f => f.node === "defect_node").forEach((dRow) => {
          onSave(
            "defect",
            { ident: dRow.ident, 
            path: item.node.path,
            properties:[dRow],
          },
         reportType
        )
      });
      }
      //setData({...found.node, path:item.path})
      setCurrentChapterDatas(null);
    }
  }, [currentChapterDatas]);
  return (
    <table style={{ margin: 10, width:"100%" }}>
      <CreateTableStructure data={data} reportType={reportType} reviewMode={true}/>
    </table>
  );
};

export const TypeDefectTableDataRender = ({ item, selectedItem, reportType }) => {
  const data = item.node;
  return (
    <table style={{ margin: 10 ,width:"100%"}}>
      <CreateTableStructure data={data} reportType={reportType} selectedItem={selectedItem}/>
    </table>
  );
};
