import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Typography,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getTranslationVariantText,
  getIntlTranslation,
  getDialogBoxHeader
} from "../../../../../utils/helper";
import { setFullMode } from "../statutoryComplianceSlice";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import BreadcrumbProjectContainer from "../../../../../layout/app-container/BreadcrumbProjectContainer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = (props) => {
  const inspection = useSelector((state) => state.tddInspection);
  const readOnlystate = useSelector((state) => state.readOnly);
  const dispatch = useDispatch();
  const [blockChange, setBlockChange] = useState({
    block: false,
    action: "",
    value: null,
  });

  const closeFullModeCondition = () => {
    if (inspection.updatedRows.length > 0) {
      setBlockChange({
        block: true,
        action: "fullMode",
        value: false,
      });
    } else {
      dispatch(setFullMode(false));
    }
  };

  const getWarningText = () => {
    const element = inspection.updatedRows.reduce((group, row, index) => {
      const { trade } = row;
      group[trade] = group[trade] ?? [];
      group[trade].push(row);
      return group;
    }, {});

    return (
      <>
        <Typography variant="body1">
          {getIntlTranslation("_unsaved changes")}
        </Typography>
        <List>
          {Object.keys(element).map((el, index) => {
            return (
              <ListItem key={index} style={{ padding: 0 }}>
                <ListItemText
                  id={`${index}_${el}`}
                  primary={`${index + 1}. ${el}`}
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        fullScreen
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            closeFullModeCondition();
          }
        }}
        disableBackdropClick
        disableEscapeKeyDown
        style={{ overflow: "hidden" }}
      >
        <AppBar className="header w-100 rounded-0"
        >
          <Toolbar className={"dialog-toolbar"}>
          <div className="d-flex align-items-center justify-content-between w-25">
          {getDialogBoxHeader(props.headerLabel)}
          </div>
          <div className="d-flex align-items-center justify-content-between w-75">
          <BreadcrumbProjectContainer 
             showAsset={true}
             showSubAsset={true}/>
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={() => closeFullModeCondition()}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <DialogContent
          dividers
          className={"w-100 p-2"}
          style={{ height: "calc(100% - 50px)", overflow: "hidden" }}
        >
          {props.children}
        </DialogContent>
      </Dialog>
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={blockChange.block}
          stayText="_back"
          saveText="Save And Leave"
          leaveText="_continue"
          onLeave={() => {
            dispatch(setFullMode(blockChange.value));
            setBlockChange({
              block: false,
              value: null,
              action: "",
            });
          }}
          onStay={() =>
            setBlockChange({
              block: false,
              value: null,
              action: "",
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText()}
        />
      )}
    </>
  );
};

FullScreenDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  headerLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
};

export default FullScreenDialog;
