import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  getDialogBoxHeader,
  getIntlTranslation,
  getStylingForSelectedComment,
  shortenString,
} from "../../../../../../utils/helper";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsPopup from "../../comments/CommentsPopup";
import { CloseOutlined } from "@mui/icons-material";
import useCommentSaveHook from "../../../../../component-bundle/hooks/useCommentSaveHook";
import useCommentFetchHook from "../../comments/useCommentFetchHook";
import { useSnackbar } from "notistack";
import useChapterHook from "../../hooks/useChapterHook";
import useSaveContentFireBase from "../../hooks/firebase/useSaveContentHook";
import useFetchContentFireBase from "../../hooks/firebase/useFetchContentHook";
import { EditingIndicator } from "../EditingIndicator";
import BuildingProfileTable from "./BuildingProfileTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getSelectedCommentStyle = (isTrue) => {
  if (isTrue) {
    return {
      backgroundColor: "#244a9a",
      color: "#ffffff",
      borderRadius: 4,
    };
  }
  return {};
};

const getSortedValue = (inputValues) => {
  return inputValues.toSorted((a, b) => {
    const nameA = a.subAsset.name.trim().toUpperCase();
    const nameB = b.subAsset.name.trim().toUpperCase();
    return nameA.localeCompare(nameB);
  });
};

const CreateRows = ({ node, reportType, path }) => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const { enqueueSnackbar } = useSnackbar();
  const [commentPopup, setCommentPopup] = useState({
    open: false,
    node: null,
    id: null,
    uniqueIdentifier: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const apiParam = "asset-properties";
  const dataRef = useRef({ ...node, comments: new Map() });
  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );
  const [onCommentsSave] = useCommentSaveHook();
  const { updatedNode, setUpdatedNode, getUser } = useFetchContentFireBase({
    nodePath: `${apiParam}-${node.nodeIdent}`,
    reportType,
  });

  useEffect(() => {
    if (
      isCommentSaved.saved &&
      dataRef.current.uniqueIdentifier === commentPopup.uniqueIdentifier
    ) {
      let currValue = dataRef.current.comments.get(
        `${commentPopup.node?.nodeName}-${commentPopup.node?.subAssetIdent}`
      );
      dataRef.current.comments.set(
        `${commentPopup.node?.nodeName}-${commentPopup.node?.subAssetIdent}`,
        { ...currValue, ident: isCommentSaved.commentsIdent }
      );
      isCommentSaved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setCommentPopup({
        open: false,
        node: null,
        id: null,
        uniqueIdentifier: null,
      });
      resetState();
    }
  }, [isCommentSaved]);

  const processSaveComments = (value) => {
    if (!!dataRef.current.comments) {
      const shortValue = shortenString(
        `${commentPopup.node?.nodeName}-${commentPopup.node?.subAssetIdent}`
      );
      let tempCommentsWrapper = {
        ...dataRef.current.comments.get(shortValue),
        apiParam: apiParam,
        scrollParent: "scrollParent",
        reportType: reportType,
        section: `asset-properties-${shortValue}`,
        subAssetIdent: commentPopup.node.subAssetIdent,
        assetIdent: openAsset.ident,
      };
      const comments = [...tempCommentsWrapper.commentValues];
      comments.push({
        ...value,
        reportType: reportType,
        subAssetIdent: commentPopup.node.subAssetIdent,
        parentPath: path,
        order: commentPopup?.node?.order,
        assetIdent: openAsset.ident,
      });
      onCommentsSave({
        commentsApi: tempCommentsWrapper.apiParam,
        data: { ...tempCommentsWrapper, commentValues: comments },
        apiIdent: openAsset.ident,
      });
    }
  };

  return (
    <>
      <tr
        key={node.nodeIdent}
        id={`scroll_item_${node.nodeIdent}`}
        style={{
          ...getStylingForSelectedComment(
            isCommentSelected()?.propertyIdent,
            node.nodeIdent
          ),
        }}
      >
        <td
          style={{
            wordWrap: "break-word",
            width: "50%",
            fontSize: "14px",
          }}
        >
          <Typography variant="body">
            {node.labelTranslation
              ?.find((e) => e.language === scope.projectLanguageCode)
              .title?.toUpperCase()}
          </Typography>
        </td>
        <td
          style={{
            wordWrap: "break-word",
            width: "50%",
            fontSize: "14px",
          }}
        >
          {getSortedValue(node.nodeValue)?.map((subNode) => {
            let name = subNode.subAsset.name;
            let quelle = subNode.quelle;
            let value = subNode.value;
            let displayString = "";
            if (!!value) {
              const shortValue = shortenString(
                `${node.nodeName}-${subNode.subAsset.subAssetIdent}`
              );
              displayString = displayString
                .concat(name)
                .concat(" : ")
                .concat(value);
              if (!!quelle) {
                displayString = displayString.concat(` (${quelle})`);
              }
              if (!dataRef.current.comments.has(shortValue)) {
                dataRef.current.comments.set(shortValue, subNode.commentDto);
              }
              return (
                <Box
                  className="d-flex flex-row align-items-center justify-content-between"
                  style={getSelectedCommentStyle(
                    `${isCommentSelected()?.propertyIdent}-${
                      isCommentSelected()?.subAssetIdent
                    }` === `${node.nodeIdent}-${subNode.subAsset.subAssetIdent}`
                  )}
                >
                  <Typography
                    variant="body"
                    style={{ margin: 2, width: "90%" }}
                    dangerouslySetInnerHTML={{
                      __html: displayString.trim(),
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexGrow: "1",
                      borderBottom: "1px dashed #cbcbcb",
                    }}
                    className="g-comment"
                  >
                    <Tooltip title={getIntlTranslation("tooltip.add-comments")}>
                      <IconButton
                        aria-describedby={`pop-comment-${node.nodeIdent}`}
                        id={`comment_${node.nodeIdent}`}
                        onClick={(event) => {
                          setCommentPopup({
                            open: true,
                            node: {
                              ...node,
                              subAssetIdent: subNode.subAsset.subAssetIdent,
                            },
                            id: `pop-comment_${node.nodeIdent}`,
                            uniqueIdentifier: `${apiParam}-comment`,
                          });
                          dataRef.current.uniqueIdentifier = `${apiParam}-comment`;
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <CommentIcon
                          style={{ fontSize: 18, color: "#d08f8e" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>
              );
            } else {
              return null;
            }
          })}
        </td>
      </tr>
      {commentPopup.open && !!commentPopup.node && (
        <CommentsPopup
          id={commentPopup.id}
          anchorEl={anchorEl}
          onClose={() => {
            setCommentPopup({
              open: false,
              node: null,
              id: null,
              uniqueIdentifier: null,
            });
            setAnchorEl(null);
          }}
          node={{ ...commentPopup.node, nodeKey: commentPopup.node.nodeName }}
          onSave={(value) => {
            processSaveComments(value);
          }}
        />
      )}
    </>
  );
};

export const TypeBuildingProfilePreviewRender = ({ item, reportType }) => {
  const [currentData, setCurrentData] = useState(null);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const [isEditing, setEditing] = useState({
    id: null,
    editing: false,
  });

  const apiParam = "asset-properties";

  const { onSave, onEdit } = useSaveContentFireBase({
    apiParam: apiParam,
    data: {
      ident: "building-profile",
      path: item.node.path,
      properties: item.node.properties,
    },
    reportType: reportType,
  });
  const { locked, updatedNode, setUpdatedNode, getUser } =
    useFetchContentFireBase({
      nodePath: `${apiParam}-building-profile`,
      reportType,
    });

  useEffect(() => {
    setCurrentData(item.node);
  }, [item.node]);

  useEffect(() => {
    if (
      !isEditing.editing &&
      !!updatedNode &&
      `${apiParam}-${item.nodeIdent}` === updatedNode?.ident &&
      scope.projectIdent === updatedNode?.projectIdent &&
      openAsset.ident === updatedNode?.assetIdent &&
      reportType === updatedNode?.reportType
    ) {
      setCurrentData((prev) => {
        let temp = { ...prev };
        return { ...temp, properties: Object.values(updatedNode.node) };
      });
      setUpdatedNode(null);
    }
  }, [isEditing.editing, updatedNode, scope, openAsset]);

  const lockCheck = (locked) => {
    return (
      !!locked?.lockedId &&
      locked.isLocked &&
      locked.lockedId === `${apiParam}-building-profile` &&
      scope.projectIdent === locked?.projectIdent &&
      openAsset.ident === locked?.assetIdent &&
      reportType === locked?.reportType &&
      locked?.lockedBy.id !== getUser().id
    );
  };

  return (
    <>
      {!!currentData && currentData?.properties.length > 0 && (
        <Box className={`d-flex align-items-baseline m-1 w-100 `}>
          {isEditing.editing ? (
            <Box
              className={`d-flex align-items-center flex-column p-1 w-100 box ${
                isEditing.editing ? "elevated p-1 rounded-2" : ""
              }`}
              style={{ marginTop: 10 }}
            >
              <Dialog
                open={isEditing.editing}
                onClose={(e) =>
                  setEditing({
                    id: null,
                    editing: false,
                  })
                }
                fullWidth={true}
                maxWidth={"lg"}
                TransitionComponent={Transition}
              >
                <AppBar className={"appBar w-100"}>
                  <Toolbar className={"dialog-toolbar w-100"}>
                    {getDialogBoxHeader("_edit")}
                    <IconButton
                      edge="start"
                      color="inherit"
                      id="create_building_close"
                      onClick={(e) => {
                        onEdit(apiParam, "building-profile", reportType, false);
                        setEditing({
                          id: null,
                          editing: false,
                        });
                      }}
                      aria-label="close"
                      style={{ marginLeft: "auto" }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContent className="p-1 w-100 custom-scrollBar">
                  <BuildingProfileTable
                    data={currentData}
                    reportType={reportType}
                    onSaveSuccess={(data) => {
                      if (data.length > 0) {
                        setCurrentData((prev) => ({
                          ...prev,
                          properties: data,
                        }));
                        onSave(
                          apiParam,
                          {
                            ident: "building-profile",
                            path: item.node.path,
                            properties: data,
                          },
                          reportType
                        );
                      }
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          ) : (
            <Box
              className={`d-flex align-items-baseline w-100 flex-wrap p-1 box 
                ${!isEditing.editing && lockCheck(locked) ? "locked-indicator" : ""}
              `}
            >
              <Box className={`d-flex w-100`}>
                <Box style={{ width: "calc(100% - 40px)" }}>
                  <table className="custom-table w-100 m-1">
                    <tbody>
                      {currentData.properties
                        ?.toSorted((a, b) => a.order - b.order)
                        .map((item, index) => (
                          <CreateRows
                            key={`${item.nodeIdent}-${index}`}
                            node={item}
                            reportType={reportType}
                            path={currentData.path}
                          />
                        ))}
                    </tbody>
                  </table>
                </Box>
                {!lockCheck(locked) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexGrow: "1",
                      width: 40,
                    }}
                    className="g-btn-secondary rounded-3 m-1"
                  >
                    <Tooltip title={getIntlTranslation("tooltip.edit")}>
                      <IconButton
                        id={`edit_building_profile`}
                        className="p-0 w-100 rounded"
                        onClick={(event) => {
                          event?.stopPropagation();
                          setEditing({
                            editing: true,
                            id: "building_profile",
                          });
                          onEdit(apiParam, "building-profile", reportType, true);
                        }}
                      >
                        <ModeEditIcon
                          style={{ fontSize: 20, color: "#244a9a" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </Box>
              {!isEditing.editing && lockCheck(locked) && (
                <EditingIndicator user={locked.lockedBy} />
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export const TypeBuildingProfileDataRender = ({
  item,
  selectedItem,
  reportType,
}) => {
  const { onLoadSectionData } = useChapterHook({
    reportType,
  });
  let data = item.node;
  const apiParam = "asset-properties";
  const { onSave } = useSaveContentFireBase({});
  return (
    <Box className="d-flex align-items-center m-1 w-100">
      <BuildingProfileTable
        data={data}
        reportType={reportType}
        onSaveSuccess={(data) => {
          onLoadSectionData(selectedItem, reportType);
          onSave(
            apiParam,
            {
              ident: "building-profile",
              path: item.node.path,
              properties: data,
            },
            reportType
          );
        }}
      />
    </Box>
  );
};
