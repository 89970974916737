import { useEffect, useState } from "react";
import { database } from "../../../../../../index";
import { ref, onValue, off } from "firebase/database";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../../../authentication/authTokenService";

function useFetchContentFireBase({ nodePath, reportType }) {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const [updatedNode, setUpdatedNode] = useState(null);
  const [locked, setLocked] = useState({
    isLocked: false,
    lockedAt: null,
    lockedBy: null,
    lockedId: null,
  });

  useEffect(() => {
    if (!scope.projectIdent || !openAsset.ident) return;

    const path = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/data-nodes/${nodePath}`;
    const nodeRef = ref(database, `${path}`);

    const handleValueChange = (snapshot) => {
      if (snapshot.exists() && !!snapshot.val()) {
        const val = snapshot.val();
        setUpdatedNode({
          node: val,
          ident: nodePath,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          reportType: reportType,
        });
      }
    };

    onValue(nodeRef, handleValueChange);

    const lockedPath = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/locked-nodes/${nodePath}`;
    const editingRef = ref(database, lockedPath);

    const handleLockedChange = (snapshot) => {
      if (snapshot.exists() && !!snapshot.val()) {
        const val = snapshot.val();
        setLocked({
          isLocked: val.isLocked,
          lockedAt: val.lockedAt,
          lockedBy: val.lockedBy,
          lockedId: val.lockedId,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          reportType: reportType,
        });
      }
    };

    onValue(editingRef, handleLockedChange);

    return () => {
      off(nodeRef);
      off(editingRef);
    };
  }, [scope.projectIdent, openAsset.ident, reportType, nodePath]);

  const getUser = () => {
    const userData = getCurrentUser();
    return {
      id: userData.data.uid,
    };
  };

  return { updatedNode, setUpdatedNode, locked, getUser };
}

export default useFetchContentFireBase;
