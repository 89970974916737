import React, { useState, useEffect } from "react";
import SortableTree, {
  toggleExpandedForAll,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  addNodeUnderParent,
  
} from "@nosferatu500/react-sortable-tree";
// import data from "./data";
import SaveIcon from "@mui/icons-material/Save";
import { sortBy } from "lodash";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppBarContent from "./AppBar";
import CustomNodeRenderer from "./CustomNodeRenderer";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { getUnits } from "../../../common/dropdowns";
import MuiAlert from "@mui/material/Alert";
import InternalPrompt from "../../../components/prompt/InternalPrompt";
import { EditDialogBox } from "./EditDialogBox";
import { AddNodeSiblingDialogBox } from "./AddNodeSiblingDialogBox";
import { AddNodeDialogBox } from "./AddNodeDialogBox";
import { costElementMenu } from "./costElementMenu";
import { parentMenu } from "./parentMenu";
import { addNodeSiblingCheck } from "./addNodeSiblingCheck";
import { addNodeChildCheck } from "./addNodeChildCheck";
import CostDataFilter from "./CostDataFilter";
import {
  getCostData,
  creatNewCostElement,
  creatNewSiblingCostElement,
  updateCostElement,
  deletingCostElement,
  updateSingleInflationRate,
  getAllCostelementData
} from "../../../api/costDataApi";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { resetState, resetCreatedCostElement } from "../costDataSlice";
import InflationRateDialogBox from "./InflationRateDialogBox";
import { getIntlTranslation } from "../../../utils/helper";

const CustomizedTreeView = ({ switchTree, setSwitchTree }) => {

  const [searchString, setSearchString] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [currentNode, setCurrentNode] = useState({});
  const [node, setNode] = useState(currentNode.node);
  const [addCostElementNode, setAddCostElementNode] = useState({
    unit: "",
    von: "",
    name: "",
    nodeType: "costElement",
    code: "",
    bis: "",
    mittel: "",
  });
  const [path, setPath] = useState(currentNode.path);
  const [treeData, setTreeData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openCreatedDialog, setOpenCreatedDialog] = React.useState(false);
  const [openAddNodeDialog, setOpenAddNodeDialog] = React.useState(false);
  const [units, setUnits] = useState(getUnits());
  const [openWarning, setOpenWarning] = React.useState(false);
  const [currentParentNode, setCurrentParentNode] = React.useState({});
  const readOnlystate = useSelector((state) => state.readOnly);

  const costDataList = useSelector((state) => state.costData);
  const [treeDataCopy, setTreeDataCopy] = useState(costDataList.costData?.data);
  const inflactionData = useSelector((state) => state.costData.inflactionData);
  const deleteCostElement = useSelector(
    (state) => state.costData.deleteCostElement
  );
  const singleInflactionData = useSelector(
    (state) => state.costData.singleInflactionData
  );
  const creatCostElement = useSelector(
    (state) => state.costData.creatCostElement
  );
  const creatSiblingCostElement = useSelector(
    (state) => state.costData.creatSiblingCostElement
  );
  const updateCost = useSelector((state) => state.costData.updateCostElement);
  const exportCost = useSelector((state) => state.costData.exportCost);
  const [openBox, setBoxOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setNode(currentNode.node);
    setPath(currentNode.path);
  }, [currentNode]);

  useEffect(() => {
    dispatch(getCostData());
  }, [dispatch]);
  useEffect(() => {
    if (exportCost.type === "FETCHED") {
      exportCost?.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCostData());
      dispatch(getAllCostelementData());
      dispatch(resetState());
    }
  }, [dispatch, exportCost]);

  useEffect(() => {
    if (costDataList.costData.type === "FETCHED") {
      setTreeData(costDataList.costData.data);
      setTreeDataCopy(costDataList.costData.data);
      dispatch(resetState());
    }
  }, [costDataList, costDataList.costData, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (updateCost?.type === "UPDATED") {
      updateCost?.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      const updateData = {
        ...updateCost.data,
        nodeType: "costElement",
        updated: false,
      };
      const { path, node } = currentNode;
      setTreeData(
        changeNodeAtPath({
          treeData: treeData,
          path,
          getNodeKey,
          newNode: { ...updateData },
        })
      );
      onCloseDialogBox();
      dispatch(resetState());
    }
  }, [dispatch, enqueueSnackbar, updateCost]);

  useEffect(() => {
    if (singleInflactionData?.type === "UPDATED") {
      singleInflactionData?.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      const updateData = singleInflactionData.data;
      const { path, node } = currentNode;
      setTreeData(
        changeNodeAtPath({
          treeData: treeData,
          path,
          getNodeKey,
          newNode: { ...updateData,nodeType:"costElement" },
        })
      );
      setBoxOpen(false);
      handleClose();
      dispatch(resetState());
    }
  }, [dispatch, enqueueSnackbar, singleInflactionData]);
  useEffect(() => {
    if (deleteCostElement?.type === "SUCCESS") {
      deleteCostElement.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setTreeData(
        removeNodeAtPath({
          treeData,
          path,
          getNodeKey,
        })
      );
      dispatch(resetState());
    }
  }, [dispatch, enqueueSnackbar, deleteCostElement]);
  useEffect(() => {
    if (inflactionData?.type === "UPDATED") {
      inflactionData.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      dispatch(getCostData());
    }
  }, [dispatch, enqueueSnackbar, inflactionData]);
  useEffect(() => {
    if (creatSiblingCostElement?.type === "SAVED") {
      creatSiblingCostElement.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      let newTree = addNodeUnderParent({
        treeData: treeData,
        parentKey: path[path.length - 2],
        expandParent: true,
        getNodeKey,
        newNode: {
          ...creatSiblingCostElement.data,
          nodeType:"costElement"
        },
      });
      setTreeData(newTree.treeData);
      setAddCostElementNode({
        unit: "",
        von: "",
        name: "",
        id: "",
        nodeType: "costElement",
        code: "",
        bis: "",
        mittel: "",
      });
      dispatch(resetCreatedCostElement());
      onCloseDialogBox();
    }
  }, [dispatch, enqueueSnackbar, creatSiblingCostElement]);
  useEffect(() => {
    if (creatCostElement?.type === "SAVED") {
      creatCostElement.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      let newTree = addNodeUnderParent({
        treeData: treeData,
        parentKey: path[path.length - 1],
        expandParent: true,
        getNodeKey,
        newNode: {
          ...creatCostElement.data,
          nodeType:"costElement"
        },
      });
      setTreeData(newTree.treeData);
      setAddCostElementNode({
        unit: "",
        von: "",
        name: "",
        id: "",
        nodeType: "costElement",
        code: "",
        bis: "",
        mittel: "",
      });
      dispatch(resetCreatedCostElement());
      onCloseAddNodeDialogBox();
    }
  }, [dispatch, enqueueSnackbar, creatCostElement]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    handleClose();
  };
  const handleClickOpenAddNodeDialog = () => {
    setOpenAddNodeDialog(true);
    handleClose();
  };

  const handleCloseDialogBox = () => {
    setBoxOpen(false);
  };
  const handleOpenDialogBox = () => {
    setBoxOpen(true);
    handleClose();
  };
  const handleInflationSave = (value) => {
    const res = value.value;
    const convert = parseFloat(res, 10);
    const data = { rate: convert };
    const ident = currentNode.node.id;
    dispatch(updateSingleInflationRate(data, ident));
  };

  const expandAndCollapse = (expanded) => {
    setTreeData(
      toggleExpandedForAll({
        treeData: treeData,
        expanded,
      })
    );
  };

  const removeNode = () => {
    const { node } = currentNode;
    const data = {
      measureId: currentParentNode.id,
      ident: node.id,
    };
    dispatch(deletingCostElement(data));
    handleClose();
  };
  const selectThis = (node, path) => {
    setCurrentNode({ node, path: path });
  };

  const onCloseDialogBox = () => {
    setOpenCreatedDialog(false);
    setOpenDialog(false);
    handleClose();
  };
  const onCloseAddSiblingDialogBox = () => {
    setOpenCreatedDialog(false);
    setOpenDialog(false);
    handleClose();
  };
  const onCloseAddNodeDialogBox = () => {
    setOpenAddNodeDialog(false);
    // handleClose();
  };

  function updateTableNode(node,path) {
    let newTree = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode:{
        ...node
    }
    });
    setTreeData(newTree);
  }

  function updateCostElementNode() {
    let parent = currentParentNode;
    const data = {
      oldMeasureId: parent.id,
      newMeasureId: parent.id,
      ...node,
      type: "updateCostElementDto",
    };
    dispatch(updateCostElement(data));
  }
  function addNodeSibling() {
    let parent = currentParentNode;
    const data = {
      measureId: parent.id,
      ...addCostElementNode,
      type: "createCostElementDto",
    };
    dispatch(creatNewSiblingCostElement(data));
  }
  function addNodeToMeasure() {
    const data = {
      type: "createCostElementDto",
      measureId: node.id,
      ...addCostElementNode,
      code: currentParentNode.costCode + addCostElementNode.code,
    };
    dispatch(creatNewCostElement(data));
  }
  function addCostElementDialogBox() {
    setOpenCreatedDialog(true);
    handleClose();
  }

  const addNodeChild = () => {
    const { path, node } = currentNode;
    addNodeChildCheck(node, treeData, path, getNodeKey, setTreeData);
    handleClose();
  };

  const handelSaveUpdatedNode = (node) => {
    let parent = currentParentNode;
    const data = {
      oldMeasureId: parent.id,
      newMeasureId: parent.id,
      ...node,
      type: "updateCostElementDto",
    };
    dispatch(updateCostElement(data));
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  // Case insensitive search of `node.title`
  const customSearchMethod = ({ node, searchQuery }) => {
    if (searchQuery.length > 0) {
      if (!!node.title) {
        const value = node?.title.toString();
        return value.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
      } else if (node.nodeType === "costElement") {
        const code = node?.code.toString();
        const name = node?.name?.toString();
        return code?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1 || name?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1 
      }else {
        return "";
      }
    }
  };

  const handlePoperClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getWarningText = () => {
    return (
      <>
        <Typography variant="body1">
          {getIntlTranslation("_sorry-request")}
        </Typography>
        <Typography>
          You are trying to delete a {node?.nodeType} that contains sub
          information.
        </Typography>
        <Typography>
          Please make sure the {node?.nodeType} does not contain sub information
          before you can delete it.
        </Typography>
      </>
    );
  };
  const canDrag = ({ node }) => {
    const typeIcon= node;
    if (!!typeIcon && typeIcon?.nodeType=== "costElement") {
            return false;
        } else {
            return false;
        }
    };
    const dropMessage =(object) => {
      if ( object.nextParent && object.nextParent.nodeType === "measure") {
        return true;    
      }
      return false;
    }
  function updateKeyTitleToNameRecursive(data) {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        updateKeyTitleToNameRecursive(data[i]);
      }
    } else if (typeof data === 'object' && data !== null) {
      if (data.title) {
        data.name = data.title;
        delete data.title;
      }
      if (data.children && Array.isArray(data.children)) {
        updateKeyTitleToNameRecursive(data.children);
      }
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          updateKeyTitleToNameRecursive(data[key]);
        }
      }
    }
    return data;

  }
  var tempProps = JSON.parse(JSON.stringify(treeData));
  const newdata = {
    "costCode": "ACDB",
    "children":[...tempProps],
    "id": "56347b23-bebb-4f22-a872-abb29bc251f2",
    "nodeType": "Cost data",
    "updated": false,
    "name": "Außentüren und -tore"
  };


  return (
    <div style={{ height: "100%", marginTop: "20px",overflow:"hidden" , overflowY:"auto"  }}>
      <AppBarContent
        searchString={searchString}
        setSearchString={setSearchString}
        expandAndCollapse={expandAndCollapse}
        treeData={treeData}
          setTreeData={setTreeData}
          treeDataCopy={treeDataCopy}
          toggleExpandedForAll={toggleExpandedForAll}
      />
      <Box style={{ display: "flex", width: "100%", height: "100%", margin:50, }}>
        {treeData?.length > 0 && (
          <Grid style={{ width: "100%", height: "100%" }}>
            
            <SortableTree
              searchMethod={customSearchMethod}
              searchQuery={searchString}
              searchFocusOffset={searchFocusIndex}
              isVirtualized={false}
              treeData={treeData}
              maxDepth={3}
              rowDirection="ltr"
              onlyExpandSearchedNodes={true}
              canDrop={object => dropMessage( object )}
              canDrag={canDrag}
              shouldCopyOnOutsideDrop={true}
              onChange={(treeData) => setTreeData(treeData)}
              nodeContentRenderer={CustomNodeRenderer}
              generateNodeProps={({ node, path, parentNode }) => ({
                handlePoperClick,
                selectThis,
                updateTableNode,
                setCurrentParentNode,
                title: (
                  <form
                    style={{
                      fontSize: "0.88rem",
                      width: "100%",
                      height: "20px",
                      minWidth: 350,
                      display: "flex",
                      justifyItems: "center",
                      justifyContent: "space-between",
                      padding: 0,
                      margin: 0,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      selectThis(node, path);
                    }}
                    key={path}
                  >
                    <input
                    disabled
                      style={{
                        width: "100%",
                        height: "20px",
                      }}
                      value={node.title}
                      onChange={(event) => {
                        const title = event.target.value;
                        const change = true;
                        setTreeData(
                          changeNodeAtPath({
                            treeData: treeData,
                            path,
                            change,
                            getNodeKey,
                            newNode: { ...node, title, updated: change },
                          })
                        );
                      }}
                    />
                  </form>
                ),
                buttons: [
                  node?.updated && (
                    <Tooltip title="Save">
                      <SaveIcon
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setCurrentParentNode(parentNode);
                          handelSaveUpdatedNode(node);
                          selectThis(node, path);
                        }}
                        style={{
                          color: "#a82005",
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  ),
                  <Grid>
                    {(node?.nodeType === "costElement" ||
                      node?.type === "costElementDto" || node?.nodeType  === "measure")  && (
                      <Tooltip title="More" placement="right">
                        <MoreVertIcon
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handlePoperClick(e);
                            selectThis(node, path);
                          }}
                          style={{
                            color:
                              node.nodeType === "costElement"
                                ? "white"
                                : "#254a9a",
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                            padding: 0,
                          }}
                        />
                      </Tooltip>
                    )}
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      style={{ boxShadow: "none" }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          boxShadow: "none",
                        }}
                      >
                        {currentNode.node?.nodeType === "costElement" &&
                          costElementMenu(
                            addCostElementDialogBox,
                            removeNode,
                            handleClickOpenDialog,
                            handleOpenDialogBox,
                            path,
                          )}
                           {currentNode.node?.nodeType  === "measure" && parentMenu(
                            handleClickOpenAddNodeDialog,
                            path,
                          )}
                      </Box>
                    </Popover>
                  </Grid>,
                ],
              })}
            />
          </Grid>
        )}
      </Box>
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={openWarning}
          onLeave={() => setOpenWarning(false)}
          leaveText={getIntlTranslation("_cancle")}
          message={getWarningText()}
        />
      )}
        <InflationRateDialogBox
          open={openBox}
          handleSave={handleInflationSave}
          close={handleCloseDialogBox}
        />

      { openDialog && EditDialogBox(
        onCloseDialogBox,
        updateCostElementNode,
        openDialog,
        node,
        setNode,
        units,
      )}
      {openCreatedDialog && 
        AddNodeSiblingDialogBox(
          onCloseAddSiblingDialogBox,
          addNodeSibling,
          openCreatedDialog,
          addCostElementNode,
          setAddCostElementNode,
          units,
          currentParentNode,
          node
        )
      }
      {openAddNodeDialog && AddNodeDialogBox(
        onCloseAddNodeDialogBox,
        addNodeToMeasure,
        openAddNodeDialog,
        addCostElementNode,
        setAddCostElementNode,
        units,
        currentParentNode
      )}
    </div>
  );
};

export default CustomizedTreeView;
