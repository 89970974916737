import React, { useState, useEffect, useRef } from "react";
// @mui/material components
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import IntlMessages from "../utils/IntlMessages";
import {
  getUserProfile,
  updateUserData,
  getTAEuropeOffices,
} from "../api/userProfileApi";
import { getLanguageList } from "../common/dropdowns";
import { sendPasswordResetLink } from "../api/authApi";
import { reset } from "../authentication/authSlice";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import { updateLanguage } from "../authentication/authSlice";
import { useSnackbar } from "notistack";
import { setSystemLanguage } from "../layout/app-container/systemSlice";
import {
  Grid,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  IconButton,
  Link,
  Toolbar,
  Typography,
  Dialog,
  Grow,
  AppBar,
  Box,
} from "@mui/material";
import { resetState } from "./userProfileSlice";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { getDialogBoxHeader } from "../utils/helper";
import SaveButton from "../components/buttons/SaveButton";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from 'react-phone-input-2'
import "../scss/countryFlag.css"
import "./style.css"

const Input = (props) => {
  const { inputRef, onChange, ...other } = props;

    return (
        <Box>
            <PhoneInput
            specialLabel={''}
            country={'de'}
            // onlyCountries={['de', 'es']}
            // localization={{'Germany': 'Deutschland', 'Spain': 'España'}}
            inputStyle={{
              borderColor: (props.touched && props.error) && "red"
            }}
            onChange={(phone) => {
              props.onChange(phone);
            }}

            {...props}
            />
            {(props.touched && props.error) && <p style={{color:'red'}} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">{props.error}</p> }
        </Box>
    );
  };

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+## (#) ###########"
      mask="_"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
  userName: Yup.string().required(<IntlMessages id="user.usernameRequired" />),
  firstName: Yup.string().required(
    <IntlMessages id="user.firstnameRequired" />
  ),
  lastName: Yup.string().required(<IntlMessages id="user.lastnameRequired" />),
  language: Yup.object()
    .shape({
      label: Yup.string()
        .min(1, <IntlMessages id="required_field" />)
        .required(<IntlMessages id="required_field" />),
      value: Yup.string()
        .nullable()
        .required(<IntlMessages id="required_field" />)
        .required(<IntlMessages id="required_field" />),
    })
    .required(<IntlMessages id="required_field" />),
});

const UserProfileView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.userProfile);
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [officesOptions, setOfficesOptions] = useState([]);
  const [user, setUser] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    position: "",
    emailAddress: "",
    mobile: "",
    image: "",
    language: { label: "", value: "" },
    officeName: "",
    taOfficeDto: [],
  });
  const formRef = useRef();
  const [openOfficesOption, setOpenOfficesOption] = useState(false);
  const loading = openOfficesOption && officesOptions.length === 0;

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setOfficesOptions(profile.offices.data);
  }, [profile.offices]);

  useEffect(() => {
    if (profile.fetch.type === "FETCHED") {
      const currentUser = profile.fetch.data;
      const languageOption = getLanguageOption(currentUser.language);
      setUser({ ...currentUser, language: languageOption });
      dispatch(setSystemLanguage(currentUser.language));
      dispatch(updateLanguage(currentUser.language));
      dispatch(resetState());
    } else if (profile.save.type === "SAVED") {
      profile.save.message.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      dispatch(getUserProfile());
      setLoader(false);
    }
  }, [profile.fetch, profile.save, enqueueSnackbar, dispatch]);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active && profile.offices.data.length > 0) {
        setOfficesOptions(profile.offices.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, profile.offices.data]);

  useEffect(() => {
    if (openOfficesOption) {
      dispatch(getTAEuropeOffices());
    } else if (!openOfficesOption) {
      setOfficesOptions([]);
    }
  }, [dispatch, openOfficesOption]);

  const getLanguageOption = (language) => {
    let userlanguage = getLanguageList().find(
      (f) => f.value.toLowerCase() === language?.toLowerCase()
    );
    if (!!userlanguage) {
      return userlanguage;
    } else {
      return { value: "de", label: "Deutsch" };
    }
  };

  const resetPassword = () => {
    dispatch(sendPasswordResetLink(user.emailAddress));
    dispatch(reset());
    navigate("/login");
  };

  const changeUserLanguge = (event, newValue) => {
    setUser({ ...user, language: newValue });
  };
  const changeUserCity = (event, newValue) => {
    setUser({ ...user, taOfficeDto: newValue });
  };

  const getOptionLabel = (option) => {
    return option.label;
  };
  function trimSpace(value) {
    return value.replace(/\s+/g, " ");
  }
  const submitData = (values) => {
    const firstName = trimSpace(values.firstName);
    const lastName = trimSpace(values.lastName);
    const userName = trimSpace(values.userName);
    const emailAddress = trimSpace(values.emailAddress);
    const position = trimSpace(values.position);
    const users = {
      userName: userName,
      firstName: firstName,
      lastName: lastName,
      mobile: values.mobile,
      image: values.image,
      language: values.language,
      emailAddress: emailAddress,
      position: position,
      taOfficeDto: values.taOfficeDto,
    };
    dispatch(updateUserData(users));
    setLoader(true);
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"md"}
      onClose={(e) => props.onClose()}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar 
      style={{
          height: 40,
          backgroundColor: "#d08f8e",
          position: "sticky",
          zIndex: 5,
        }}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("user.account")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => props.onClose()}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers>
        <div className="container">
          <Formik
            enableReinitialize
            initialValues={{ ...user }}
            validationSchema={validationSchema}
            onSubmit={(values) => submitData(values)}
            innerRef={formRef}
          >
            {({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => (
              <Form>
                <Box>
                  <Grid
                    style={{ width: "90%" }}
                    container
                    className="custom-grid-container"
                    justify="flex-start"
                    spacing={1}
                  >
                    <Grid item md={10} xs={12}>
                      <Typography
                        variant="overline"
                        className="custom-section-heading"
                      >
                        <IntlMessages id="_generalInformation" />
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="userName"
                        label={<IntlMessages id="user.username" />}
                        as={TextField}
                        size="small"
                        className="grid-fields"
                        error={touched.userName && errors.userName}
                        helperText={touched.userName && errors.userName}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <div
                        className="align-password-link"
                        style={{ height: "100%", display: "flex" }}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          style={{ alignSelf: "end", marginLeft: "auto" }}
                          onClick={resetPassword}
                        >
                          <IntlMessages id="user.resetPassword" />
                        </Link>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="firstName"
                        label={<IntlMessages id="user.firstName" />}
                        as={TextField}
                        size="small"
                        className="grid-fields"
                        error={touched.firstName && errors.firstName}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Field
                        name="lastName"
                        label={<IntlMessages id="user.lastName" />}
                        as={TextField}
                        size="small"
                        className="grid-fields"
                        error={touched.lastName && errors.lastName}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ width: "90%" }}
                    container
                    className="custom-grid-container"
                    justify="flex-start"
                    spacing={1}
                  >
                    <Grid item md={10} xs={12}>
                      <Typography
                        variant="overline"
                        className="custom-section-heading"
                      >
                        <IntlMessages id="user.contact" />
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        disabled
                        as={TextField}
                        size="small"
                        name="emailAddress"
                        label={<IntlMessages id="user.emailAddress" />}
                        className="grid-fields"
                        type="email"
                      />
                    </Grid>
                    {/* <Grid item md={5} xs={12}>
                      <TextField
                        style={{ width: "100%" }}
                        name="mobile"
                        size="small"
                        id="mobile-input"
                        label={<IntlMessages id="user.contactNumber" />}
                        className="grid-fields"
                        value={values.mobile}
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </Grid> */}
                    <Grid item md={5}  xs={12}>
                      <Field 
                        component={Input} 
                        style={{ width: "100%" }}
                        name="mobile"
                        size="small"
                        id="mobile-input"
                        label={<IntlMessages id="user.contactNumber" />}
                        className="grid-fields"
                        value={values.mobile}
                        onChange={(num)=> setFieldValue("mobile",num)}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ width: "90%" }}
                    container
                    className="custom-grid-container"
                    justify="flex-start"
                    spacing={1}
                  >
                    <Grid item md={10} xs={12}>
                      <Typography
                        variant="overline"
                        className="custom-section-heading"
                      >
                        <IntlMessages id="user.work" />
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="position"
                        label={<IntlMessages id="user.title" />}
                        as={TextField}
                        size="small"
                        id="user_title"
                        dense={"true"}
                        className="grid-fields"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Autocomplete
                        style={{ maxWidth: "100%" }}
                        id="clear-on-escape"
                        size="small"
                        name="taOfficeDto"
                        className="grid-fields"
                        value={user.taOfficeDto}
                        onChange={(event, newValue) => {
                          changeUserCity(event, newValue);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.city === value.city
                        }
                        getOptionLabel={(option) => option.city}
                        options={officesOptions}
                        open={openOfficesOption}
                        onOpen={() => {
                          setOpenOfficesOption(true);
                        }}
                        onClose={() => {
                          setOpenOfficesOption(false);
                        }}
                        loading={loading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<IntlMessages id="user.office" />}
                            variant="outlined"
                            name="taOfficeDto"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                        error={touched.taOfficeDto && errors.taOfficeDto}
                        helperText={touched.taOfficeDto && errors.taOfficeDto}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ width: "90%" }}
                    container
                    className="custom-grid-container"
                    justify="flex-start"
                    spacing={1}
                  >
                    <Grid item md={10} xs={12}>
                      <Typography
                        variant="overline"
                        className="custom-section-heading"
                      >
                        <IntlMessages id="user.systemLanguage" />
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        className="grid-fields"
                        id="combo-box-language"
                        size="small"
                        style={{ width: "100%", maxWidth: "100%" }}
                        value={user.language}
                        onChange={(event, newValue) =>
                          changeUserLanguge(event, newValue)
                        }
                        options={getLanguageList()}
                        getOptionLabel={(option) => getOptionLabel(option)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<IntlMessages id="user.language" />}
                            name="language"
                            variant="outlined"
                            error={
                              touched.language?.value && errors.language?.value
                            }
                            helperText={
                              touched.language?.value && errors.language?.value
                            }
                            id="help"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
      <DialogActions>
        <SaveButton
          id="create-project-button"
          buttonText="_save"
          variant="button"
          style={{ margin: 5 }}
          tooltip={{
            title: "click to create a project",
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

UserProfileView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
export default UserProfileView;
