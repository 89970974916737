import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
  CardActions,
  TextField,
  CardHeader,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getVariantText,
  getTranslationVariantText,
  minimalEditorConfig,
  getIntlTranslation,
} from "../../../../../utils/helper";
import CustomButton from "../../../../../components/buttons/CustomButton";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import {
  riskTypeOptions,
} from "../../../../../common/dropdowns";
import GuruEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AddIcon from "@mui/icons-material/Add";
import debounce from "lodash.debounce";
import { resetPromptState } from "../../../projectmonitoringModuleSlice";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const splitArray = (array, callback) => {
  const matches = [];
  const nonMatches = [];
  array.forEach((element) =>
    (callback(element) ? matches : nonMatches).push(element)
  );
  return [matches, nonMatches];
};

const KeyFindingsPropertyEditDialog = ({ open,subAssetList,onClose,selectedProperty,onAdd}) => {
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  const riskOption = useMemo(
    () =>
      riskTypeOptions.filter((f) => f.language === scope.projectLanguageCode),
    [scope.projectLanguageCode]
  );
  const [selectedSubAsset, setSelectedSubAsset] = useState(null);
  const state = useSelector((state) => state.keyFindings);
  const [dataRows, setDataRows] = useState([]);
  console.log("Selected property", selectedProperty)

  useEffect(() => {
    if (!!selectedProperty && !!selectedProperty.mapProperties.length > 0) {
      let temp = selectedProperty.mapProperties.map((m,index) => {
        let risk = m.risk
        ? riskOption.find(
            (f) => f.value.toLowerCase() === m.risk.toLowerCase()
          )
        : { title: "", value: "" };
        return ({
          nodeIdent: m.nodeIdent,
        subAsset: m.subAsset,
        description: m.description,
        risk: risk,
        quelle:m.quelle,
        id: `${Date.now()}_${m.nodeIdent}_${index+1}`,
        order: index+1,
        isNew: m.new,
        isUpdated: m.updated,
        isDeleted: m.deleted,
        property: {...m.property,mapProperties:[]},
        versionIdent:m.versionIdent,
      })
    });
    setDataRows(temp);
    } else {
      setDataRows([]);
    }
  }, [selectedProperty, riskOption,state.copyData]);


  const handleRowChange = useCallback(
    (value, name, data) => {
      const [selectedPropertyArray, rest] = splitArray(
        dataRows,
        (f) =>
          f?.subAsset.subAssetIdent === data.subAsset.subAssetIdent && f.id === data.id
      );

      let found = selectedPropertyArray.find((f) => f.id === data.id);
      if (found) {
        let tempData = { ...found, [name]: value, isUpdated: true };
        setDataRows([...rest, { ...tempData }]);
      }
    },
    [dataRows]
  );

  const debouncedHandleChange = useMemo(
    (value, name, data) => debounce(handleRowChange, 300),
    [handleRowChange]
  );

  const checkIfDataExist = (value, name) => {
    setSelectedSubAsset(value);
    let found = dataRows?.find(
      (f) => f?.subAsset.subAssetIdent === value?.subAssetIdent
    );
    if (!found) {
      let data = {
        nodeIdent: "",
        quelle:"",
        subAsset: value,
        description: "",
        risk: { title: "", value: "" },
        id: `${Date.now()}_${value.subAssetIdent}`,
        order: 1,
        isNew: true,
        isUpdated: true,
        isDeleted: false,
        property: selectedProperty,
      };
      setDataRows((prev) => [...prev, { ...data }]);
    }
  };

  const getLength = () => {
    return dataRows.filter(
      (f) => f.subAsset.subAssetIdent === selectedSubAsset.subAssetIdent
    ).length;
  };

  const onDeleteRow = (row) => {
    if(row.isNew){
      let temp = dataRows.filter((f) => f.id !== row.id);
      setDataRows(temp);
    }
  };


  const onAddRowOnIndex = () => {
    let data = {
      nodeIdent: "",
      quelle:"",
      subAsset: selectedSubAsset,
      description: "",
      risk: { title: "", value: "" },
      id: `${Date.now()}_${selectedSubAsset.subAssetIdent}`,
      order: getLength() + 1,
      isNew: true,
      isUpdated: true,
      isDeleted: false,
      property: selectedProperty,
    };
    setDataRows((prev) => [...prev, { ...data }]);
  };

  const createDataCard = () => {
    return dataRows
      .filter(
        (f) => f?.subAsset?.subAssetIdent === selectedSubAsset?.subAssetIdent && !f.isDeleted
      ).sort((a, b) => a.order - b.order)
      .map((f, index) => {
        return (
          <ListItem className="d-flex flex-column w-100 m-1 p-1" disablePadding>
            <Card className={"w-100"}>
              <CardContent className={"w-100 p-1"}>
                <Box
                  style={{ margin: 2 }}
                  className="w-100 p-1 d-flex align-items-center"
                >
                  <Avatar
                    style={{
                      backgroundColor: "#C1D0F0",
                      width: 18,
                      height: 18,
                    }}
                    aria-label="order"
                    variant="rounded"
                  >
                    <Typography
                      variant="caption"
                      style={{ color: "#254a9a", fontWeight: "bold" }}
                    >
                      {index + 1}
                    </Typography>
                  </Avatar>
                  {getTranslationVariantText("_description", "body1", {
                    width: "90%",
                    color: "#254a9a",
                    fontWeight: "bold",
                    marginLeft: 5,
                  })}

                  {f.isNew && 
                  <Box>
                    <CustomIconButton
                      id={`delete_row_button_${f.id}`}
                      icon={
                        <HighlightOffIcon
                          style={{ color: "#f50057", fontSize: 20 }}
                        />
                      }
                      permission={null}
                      handleClick={(e) => {
                        onDeleteRow(f);
                      }}
                      tooltip={{
                        title: getIntlTranslation("tooltip.remove"),
                      }}
                      style={{
                        borderRadius: 0,
                        padding: 2,
                        width: "50%",
                      }}
                      aria-describedby={`delete_row_button_${f.id}`}
                    />
                  </Box>
                  }
                </Box>
                <Box
                  id={`box_comments`}
                  className="d-flex g-text-editor align-items-center justify-content-center p-1"
                  style={{ minHeight: "150px", width: "100%" }}
                  tabIndex={-1}
                >
                  <CKEditor
                    id={`${f.id}_description`}
                    key={`${f.id}_description`}
                    editor={GuruEditor.ClassicEditor}
                    config={minimalEditorConfig}
                    data={f?.description}
                    onChange={(event, editor) => {
                      const text = editor.getData();
                      debouncedHandleChange(text, "description", f);
                    }}
                  />
                </Box>
                <Box className="w-100 p-1">
                  <TextField
                    style={{ width: "100%", marginTop: 5 }}
                    id={`{quelle}_${f.id}`}
                    label={getTranslationVariantText(
                      "_quelle",
                      "body1",
                      {
                        width: "100%",
                        color: "#254a9a",
                        fontWeight: "bold",
                      }
                    )}
                    defaultValue={f.quelle}
                    key={`{quelle}_${f.id}`}
                    onChange={(e) =>
                      debouncedHandleChange(e.target.value, "quelle", f)
                    }
                    InputProps={{
                      "aria-label": "quelle",
                    }}
                    multiline
                    maxRows={2}
                    variant="outlined"
                    size="small"
                    dense="true"
                  />
                </Box>
                <Box className="w-100 p-1">
                  <Autocomplete
                    fullWidth
                    disableClearable={true}
                    value={f?.risk || ""}
                    options={riskOption}
                    getOptionLabel={(option) => option.title || ""}
                    onChange={(event, value) => {
                      if (!!value) {
                        handleRowChange(value, "risk", f);
                      }
                    }}
                    style={{ width: "100%" }}
                    dense={"true"}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant={"outlined"}
                        label={getTranslationVariantText(
                          "_risk-Type",
                          "body1",
                          {
                            width: "100%",
                            color: "#254a9a",
                            fontWeight: "bold",
                          }
                        )}
                      />
                    )}
                  />
                </Box>
              </CardContent>
            </Card>
          </ListItem>
        );
      });
  };

  return (
    <Card className={"h-100 d-flex flex-column"}>
      <AppBar
      style={{backgroundColor:"#d08f8e",
      position: "sticky",
      zIndex: 5 
    }}
      >
        <Toolbar className={"dialog-toolbar"}>
          <div>
            {getVariantText(selectedProperty.nodeTitle, "body2", {
              textTransform: "uppercase",
            })}
          </div>
          <div style={{ marginLeft: "auto" }}>
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => {
              dispatch(resetPromptState([]));
              onClose();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
            </div>
        </Toolbar>
      </AppBar>
      <CardContent
        dividers="true"
        style={{ height: "calc(100%-50px)", overflowY: "auto" }}
        className="custom-scrollBar"
      >
        {!selectedSubAsset && (
          <Box className={"p-1"}>
            {getTranslationVariantText(
              "_select sub-asset-to-start",
              "caption",
              { textTransform: "uppercase"},
              "w-100 p-1 rounded-1 g-btn-secondary"
            )}
          </Box>
        )}
        <Box className={"p-1"}>
          <Autocomplete
            fullWidth
            disableClearable={true}
            value={selectedSubAsset}
            options={subAssetList}
            getOptionLabel={(option) => option.name || ""}
            onChange={(event, value) => {
              if (!!value) {
                checkIfDataExist(value, "subAsset");
              }
            }}
            style={{ width: "100%" }}
            dense={"true"}
            size={"small"}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                label={getTranslationVariantText("_Subasset", "body1", {
                  width: "100%",
                  color: "#254a9a",
                  fontWeight: "bold",
                })}
              />
            )}
          />
        </Box>
        <List className={"d-flex flex-column align-items-center"}>
          {createDataCard()}
          {!!selectedSubAsset && (
            <Box className={"p-1 w-100 d-flex m-1"}>
              <CustomButton
                id={`add_row_button`}
                endIcon={<AddIcon style={{ color: "#d08f8e", fontSize: 20 }} />}
                buttonText={"_add-row"}
                className="btn g-btn-secondary"
                permission={null}
                handleClick={(e) => {
                  onAddRowOnIndex();
                }}
                tooltip={{
                  title: getIntlTranslation("tooltip.add-row"),
                }}
                style={{
                  borderRadius: 4,
                  padding: 2,
                  width: "50%",
                  marginLeft: "auto",
                }}
                aria-describedby={`add_row_button_`}
              />
            </Box>
          )}
        </List>
      </CardContent>
      <CardActions style={{ marginTop: "auto" }}>
      {!!selectedSubAsset && ( <CustomButton
          id={`add-options-custom-button`}
          buttonText={"add"}
          variant="button"
          style={{ margin: 5, width: "100%" }}
          tooltip={{
            title: "click to add",
          }}
          handleClick={(e) => onAdd(dataRows)}
        />)}
      </CardActions>
    </Card>
  );
};
KeyFindingsPropertyEditDialog.propTypes = {
  open: PropTypes.bool,
  selectedProperty: PropTypes.object,
  subAssetList: PropTypes.array,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  onCopyEdit:PropTypes.bool,
};

export default KeyFindingsPropertyEditDialog;
