import React from 'react';
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import RedFlagReportHome from './RedFlagReportHome';

const RedFlagReportRouteConfig = {
	routes: [
		{
    path: `${tddRoot}/project/red-flag`,
    exact: true,
    name: <IntlMessages id={"menu.redFlag"}/>,
    element: <RedFlagReportHome/>,
    auth: [],
  },
	]
};

export default RedFlagReportRouteConfig;
