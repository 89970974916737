import React, {useEffect} from 'react';
import { useDispatch} from 'react-redux';
import { Box } from '@mui/material';
import { resetPromptState } from '../../projectmonitoringModuleSlice';
import ProgressWrapper from './structure/ProgressWrapper';
import { setCompleteReset } from './projectProgressSlice';

const ProjectProgressHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCompleteReset());
    dispatch(resetPromptState());
  }, [dispatch]);

  return (
    <Box
      style={{ height: "100%", overflow: "hidden", backgroundColor: "#ebedef" }}
    >
      <ProgressWrapper moduleApi="project-progress" />
    </Box>
  );
};
export default ProjectProgressHome;
