import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";

export const initialState = {
  hasErrors: false,
  saveSubAssetImage:{ ...response},
  updateImage:{ ...response},
  deleteImage:{ ...response},
  getImages:{ ...response},
  updatedRows:{},
  success:{ ...response},
  failure:{ ...response},
  currentVersion:{},
};


const photoDocumentationSlice = createSlice({
  name: "monitoringPhotoDocumentation",
  initialState,
  reducers: {
    getSubAssetImages: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      getImages:{
        data: payload.guruDtoList,
        type: payload.type,
        messages: payload.messages,
      }
    }),
    getSaveSubAssetImage: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      saveSubAssetImage:{
        data: payload.guruDto,
        type: payload.type,
        messages: payload.messages,
      }
    }),
    getUpdateImage: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      updateImage:{
        data: payload,
        type: payload.type,
        messages: payload.messages,
      }
    }),
    getDeleteImage: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      deleteImage:{
        data: payload,
        type: payload.type,
        messages: payload.messages,
      }
    }),
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      failure:{
        type: payload.type,
        messages: payload.messages,
        data: payload,
      }
    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      success:{
        data: payload,
        type: payload.type,
        messages: payload.messages,
      }
    }),
    resetState: (state) => ({
      ...initialState,
      currentVersion:state.currentVersion,
    }),
    setCurrentVersion: (state, { payload }) =>({ ...state, hasErrors: false, currentVersion:payload}),
    setCompleteReset: (state) => ({
      ...initialState
    }),
    setUpdatedRows: (state, { payload }) => ({
      ...state,
      updatedRows: payload,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetState,
  getSubAssetImages,
  getSaveSubAssetImage,
  getUpdateImage,
  getDeleteImage,
  getSuccess,
  setCurrentVersion,
  setCompleteReset,
  setUpdatedRows
} = photoDocumentationSlice.actions;

// A selector
export const photoDocumentationSelector = (state) => state.photoDocumentationS;

// The reducer
export default photoDocumentationSlice.reducer;
