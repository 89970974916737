import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { response } from "../../../../utils/defaultValues";
import { onSelectReportVersion } from "../slice/CurrentVersionSlice";
import { getFetchCompareSuccess } from "../slice/VersionDataSlice";
import { normalizeProperties } from "../versionUtil";
import { useDataAggregatorContextHook } from "./DataAggregatorContext";

const ComparisonModeContext = createContext();

export const useComparisonMode = () => useContext(ComparisonModeContext);

export const ComparisonModeProvider = ({ children }) => {
  const scope = useSelector((state) => state.container.scope);
  const {
    onFetchCompareProperties,
    subAssetList,
  } = useDataAggregatorContextHook();
  const state = useSelector((state) => state.currentVersion);
  const monitoringState = useSelector((state) => state.projectMonitoring);
  const dataState = useSelector((state) => state.versionData);
  const [versionSelected, setVersionSelected] = useState(null);
  const [compareProperties, setCompareProperties] = useState([]);
  const [structuredCompareProperties, setStructuredCompareProperties] = useState({})
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      monitoringState.isCompareMode &&
      state.versions?.length > 0
      && !!state.currentVersion.ident
      && !state.selectedComparingVersion
      
    ) {
      let temp =  state.versions
        .filter((f) => f.ident !== state.currentVersion.ident)
        .sort((a, b) => a.reportOrder - b.reportOrder);
      dispatch(onSelectReportVersion(temp[0]));
      setLoading(true);
    }
  }, [
    monitoringState.isCompareMode,
    state.currentVersion,
    dispatch,
    state.selectedComparingVersion,
    state.versions
  ]);

  useEffect(() => {
    if (!!state.selectedComparingVersion && !!state.selectedComparingVersion.ident) {
      setVersionSelected(state.selectedComparingVersion);
      onFetchCompareProperties(state.selectedComparingVersion.ident);
    }
  }, [onFetchCompareProperties, state.selectedComparingVersion]);

  useEffect(() => {
    if (
      dataState.fetchCompare.type === "FETCHED" &&
      dataState.fetchCompare.data.length > 0
    ) {
      setCompareProperties(dataState.fetchCompare.data);
      setStructuredCompareProperties(normalizeProperties(
        dataState.fetchCompare.data,
        subAssetList,
        scope.projectLanguageCode
      ));
      dispatch(getFetchCompareSuccess({ ...response, guruDtoList: [] }));
      setLoading(false);
    }
  }, [dataState.fetchCompare, dispatch, scope.projectLanguageCode, subAssetList]);


  return (
    <ComparisonModeContext.Provider
      value={{
        versionSelected,
        loading,
        compareProperties,
        structuredCompareProperties,
        setStructuredCompareProperties
      }}
    >
      {children}
    </ComparisonModeContext.Provider>
  );
};
