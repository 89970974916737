import {
  AppBar,
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
} from "../../../utils/helper";
import CustomButton from "../../../components/buttons/CustomButton";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import { useDocumentContextHook } from "../structure/DocumentBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlacesAutocomplete from "../../../components/locations/PlacesAutocomplete";

const settingOptions = [
  {
    label: "_Name",
    type: "string",
    onChangeField: "title",
  },
  {
    label: "_translation",
    type: "header",
  },
  {
    label: "_German-Translation",
    type: "string",
    onChangeField: "deTranslation",
  },
  {
    label: "_English-Translation",
    type: "string",
    onChangeField: "enTranslation",
  },
];

export const TypeAddressTreeRender = ({ item }) => {
  const { selectedItem } = useDocumentContextHook();
  return (
    <Box
      id={`box_${item.id}`}
      className="w-100 h-100 d-flex justify-content-start align-items-center"
    >
      <Typography
        variant="subtitle2"
        style={{
          color: selectedItem?.id === item.id ? "#ffffff" : "#254a9a",
          fontWeight: selectedItem?.id === item.id ? "bold" : "normal",
        }}
        className="p-1"
      >
        {item?.settings?.title}
      </Typography>
      <FontAwesomeIcon
        icon="fa-solid fa-location-dot"
        style={{ color: "#faaf00", marginLeft: 5 }}
      />
    </Box>
  );
};

export const TypeAddressDropRender = ({ item }) => {
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [getAddress, setGetAddress] = useState({});
  const [fullAddress, setFullAddress] = useState("");
  const [open, setOpen] = useState(false);
  const itemRef = useRef();

  useEffect(() => {
    if (!!item) {
      itemRef.current = item;
    }
  }, [item]);

  const handleCollapseIn = () => {
    if (itemRef.current?.id === item.id) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Box
      id={`box_${item.id}`}
      className="w-100 h-100 flex-column justify-content-start align-items-start"
    >
     <Box className="d-flex align-items-center w-100">
      <Typography
        variant="subtitle2"
        style={{
          color: "#254a9a",
          fontWeight: "bold",
          width: "80%",
        }}
        className="p-1"
      >
        {item?.settings?.title}
      </Typography>
      <IconButton
          style={{
            marginLeft: "auto",
            transform: open ? "rotate(0deg)" : "rotate(180deg)",
          }}
          className="p-1"
          onClick={(e) => {
            e.stopPropagation();
            handleCollapseIn()}
          }
        >
          <FontAwesomeIcon
            icon="fa-solid  fa-square-caret-down"
            style={{
              width: 22,
              height: 22,
              color:  open ? "#254a9a" : "",
            }}
          />
        </IconButton>
        </Box>
        <Collapse
        className="w-100 m-1 p-1"
        in={open}
        timeout="auto"
        unmountOnExit
      >
      <Box className="d-flex  flex-column w-100 align-items-start">
        <Box className="w-100 m-1">
          <PlacesAutocomplete
            setLat={setLat}
            setLng={setLng}
            setGetAddress={setGetAddress}
            fullAddress={fullAddress.trim()}
          />
        </Box>
        <Box className="w-100 d-flex flex-wrap align-items-center justify-content-between">
          <TextField
            id={`address-${item.id}`}
            variant="outlined"
            name="address"
            label={getLabel("_Address")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />

          <TextField
            id={`city-${item.id}`}
            variant="outlined"
            name="city"
            label={getLabel("_City")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />

          <TextField
            id={`postalCode-${item.id}`}
            variant="outlined"
            name="postalCode"
            label={getLabel("_zip-code")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />
          <TextField
            id={`state-${item.id}`}
            variant="outlined"
            name="state"
            label={getLabel("_State")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />

          <TextField
            id={`country-${item.id}`}
            variant="outlined"
            name="country"
            label={getLabel("_Country")}
            size="small"
            disabled={true}
            style={{ width: "100%" }}
            className="p-1"
          />
        </Box>
      </Box>
      </Collapse>
    </Box>
  );
};

export const TypeAddressRenderSettings = ({ item }) => {
  const intl = useIntl();
  const { editItem, setEditItem, saveSettings } = useDocumentContextHook();
  const [settings, setSettings] = useState({
    title: "",
    enTranslation: "",
    deTranslation: "",
  });

  useEffect(() => {
    if (!!editItem && !!editItem.id) {
      setSettings({
        title: editItem.settings.title,
        enTranslation: editItem.settings.translation?.en,
        deTranslation: editItem.settings.translation?.de,
      });
    }
  }, [editItem?.id]);

  const handleSave = () => {
    saveSettings(editItem, {
      ...editItem.settings,
      title: settings.title,
      translation: {
        en: settings.enTranslation,
        de: settings.deTranslation,
      },
    });
    setEditItem(null);
  };

  const renderInputField = (input) => {
    if (input.type === "number") {
      return (
        <TextField
          key={`name_${item.id}-${input.label}`}
          className="w-100 p-1"
          type={input.type}
          defaultValue={
            !!editItem
              ? settings[input.onChangeField]
              : item.settings.defaultTitle
          }
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    } else if (input.type === "checkbox") {
      return (
        <Typography variant="body1">
          <Checkbox
            key={`name_${item.id}-${input.label}`}
            checked={settings[input.onChangeField]}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                [input.onChangeField]: e.target.checked,
              }))
            }
          />
        </Typography>
      );
    } else {
      return (
        <TextField
          key={`name_${item.id}-${input.label}`}
          className="w-100 p-1"
          multiline={true}
          maxRows={5}
          type={input.type || "text"}
          defaultValue={settings[input.onChangeField]}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    }
  };

  return (
    <>
      <Collapse
        className="w-100 m-1 p-1"
        in={editItem?.id === item.id}
        timeout="auto"
        unmountOnExit
      >
        <Card className="w-100 p-1" style={{ backgroundColor: "#e6e8ed" }}>
          <AppBar
            style={{
              backgroundColor: "#d08f8e",
              position: "sticky",
              width: "98%",
              borderRadius: 4,
              marginLeft: 2,
              height: 40,
            }}
          >
            <Toolbar className={"dialog-toolbar"}>
              <Typography variant="body1">{editItem?.label}</Typography>
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={() => setEditItem(null)}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <CardContent
            className="custom-scrollBar m-1"
            style={{
              width: "98%",
              overflowY: "auto",
              overflowX: "hidden",
              padding: 2,
              height: "100%",
            }}
          >
            <List className="w-100 p-0">
              {settingOptions.map((o, index) => {
                if (o.type === "header") {
                  return (
                    <ListItem
                      className="w-100 p-0"
                      key={`option-${o.label}-${index}}`}
                    >
                      {getTranslationVariantText(o.label, "caption", {
                        fontWeight: "bold",
                        color: "#244a9a",
                        textAlign: "center",
                      })}
                    </ListItem>
                  );
                } else {
                  return (
                    <>
                      <ListItem
                        className="w-100 p-0 d-flex flex-row align-items-baseline m-1 flex-wrap"
                        key={`option-${o.label}-${index}}`}
                      >
                        <Box
                          style={{ textTransform: "capitalize", width: "80%" }}
                        >
                          {getLabel(o.label)} {o.optional && `(Optional)`}
                        </Box>
                        {renderInputField(o)}
                      </ListItem>
                    </>
                  );
                }
              })}
            </List>
          </CardContent>
          <CardActions style={{ marginTop: "auto" }}>
            <CustomButton
              id={`save_${item.id}`}
              buttonText={"_save"}
              variant="button"
              textVariant="body1"
              buttonColor={"inherit"}
              style={{
                margin: 5,
                height: 40,
                marginLeft: "auto",
                width: "100%",
                padding: 5,
              }}
              className="g-btn-primary"
              tooltip={{
                title: getIntlTranslation("tooltip.Save"),
              }}
              size="small"
              handleClick={(e) => handleSave()}
            />
          </CardActions>
        </Card>
      </Collapse>
    </>
  );
};
export const TypeAddressPreviewRender = ({item}) => {
  return (
    <Box
      id={`box_${item.id}`}
      className="w-100 h-100 flex-column justify-content-start align-items-start"
    >
      <Typography
        variant="subtitle2"
        style={{
          color: "#254a9a",
          width: "80%",
        }}
        className="p-1"
      >
        {item?.settings?.title}
      </Typography>
    <Box className="d-flex  flex-column w-100 align-items-start">
        <Box className="w-100 d-flex flex-wrap align-items-center justify-content-between">
          <TextField
            id={`address-${item.id}`}
            variant="outlined"
            name="address"
            label={getLabel("_Address")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />

          <TextField
            id={`city-${item.id}`}
            variant="outlined"
            name="city"
            label={getLabel("_City")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />

          <TextField
            id={`postalCode-${item.id}`}
            variant="outlined"
            name="postalCode"
            label={getLabel("_zip-code")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />
          <TextField
            id={`state-${item.id}`}
            variant="outlined"
            name="state"
            label={getLabel("_State")}
            size="small"
            disabled={true}
            style={{ width: "48%" }}
            className="p-1"
          />

          <TextField
            id={`country-${item.id}`}
            variant="outlined"
            name="country"
            label={getLabel("_Country")}
            size="small"
            disabled={true}
            style={{ width: "100%" }}
            className="p-1"
          />
        </Box>
      </Box>
      </Box>

  )
}