import React from 'react';
import { globalRoot } from "../../utils/defaultValues";
import TamModuleHome from "./TamModuleHome"
import IntlMessages from "../../utils/IntlMessages";

const TamModuleRouteConfig = {
	routes: [
		{
    path: `${globalRoot}/tam/*`,
    exact: true,
    name: <IntlMessages id={`menu.tam-management`}/>,
    element:<TamModuleHome/> ,
    auth: [],
    children :[]
  },
	]
};
export default TamModuleRouteConfig;