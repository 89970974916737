import React from 'react';
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import ReviewContainerDialog from "./ReviewContainerDialog";

const ReportReviewRouteConfig = {
  routes: [
		{
    path: `${tddRoot}/project/review/:type`,
    exact: true,
    name: <IntlMessages id={"menu.review"}/>,
    element: <ReviewContainerDialog/>,
    auth: [],
  },
	]
};

export default ReportReviewRouteConfig;
