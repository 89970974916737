import keyFindings from "./feature-bundle/key-findings/keyFindingsSlice";
import photoDocumentation from "./feature-bundle/photo-documentation/photoDocumentationSlice";
import projectDescription from "./feature-bundle/project-description/projectDescriptionSlice";
import projectProgress from "./feature-bundle/project-progress/projectProgressSlice";
import projectmonitoringModule from "./projectmonitoringModuleSlice";
import monitoringReportSlice from "./feature-bundle/report-generation/monitoringReportSlice";
import projectInformationSlice from "./feature-bundle/project-information/projectInformationSlice";
import versionData from "./version-comparison/slice/VersionDataSlice"

export const monitoringReducers = {
  keyFindings: keyFindings,
  projectDescription: projectDescription,
  projectProgress: projectProgress,
  monitoringPhotoDocumentation: photoDocumentation,
  projectMonitoring: projectmonitoringModule,
  monitoringReport:monitoringReportSlice,
  projectInformation:projectInformationSlice,
  versionData:versionData,
};

export const monitoringBlackList = [
"keyFindings",
  "projectDescription",
  "projectProgress",
  "monitoringPhotoDocumentation",
  "monitoringReport",
  "projectInformation",
  "projectMonitoring",
  "versionData"
];
