import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectCellFormatter, SelectColumn } from "react-data-grid";
import PropTypes from "prop-types";
import RowOptionsMenu from "./RowOptionsMenu";

import Typography from "@mui/material/Typography";
import {
  getTextSize,
  getTextHeight,
  isEmpty,
  getIntlTranslation,
  getTranslationVariantText,
} from "../../../../../utils/helper";

import Box from "@mui/material/Box";
import {
  riskTypeOptions,
  defectTypeOptions,
  priorityTypeOptions,
} from "../../../../../common/dropdowns";
import { resetState, setUpdatedRows } from "../keyFindingSlice";
import EditDefect from "../../defects/edit/EditDefect";
import Avatar from "@mui/material/Avatar";
import DefectDataGrid from "./DefectDataGrid";
import CategoriesTab from "../structure/CategoriesTab";
import SubCategoriesPanel from "../structure/SubCategoriesPanel";
import {
  cellStyle,
  getBorderColor,
  costColumnFormatter,
  defectAndMeasureColumnFormatter,
  createSelectRowFormatter,
  getCapexRefFormatter,
} from "./gridFormatters";

const headerStyle = {
  height: "100%",
  width: "100%",
  whiteSpace: "pre-wrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  lineHeight: "normal",
};

// const deleteRow = {
//   backgroundColor: "rgba(0,0,0, 0.4)",
//   color: "#ffffff",
//   fontWeight: "bold",
//   border: "3px solid #ff0000",
//   position: "absolute",
//   zIndex: 2,
//   width: "90%",
//   padding: 20,
//   textAlign: "center",
//   top: "25%",
// };

const isSectionRow = (params) => {
  let bool = false;
  if (params.type === "ROW") {
    bool = params.row.type === "trade_row";
  }
  return bool;
};

const renderHeader = (name) => {
  return (
    <div style={headerStyle}>
      <Typography variant="Heading" style={{ fontWeight: "bold", margin: 5 }}>
        {name}
      </Typography>
    </div>
  );
};

const getWidth = (h) => {
  switch (h.editor) {
    case "date":
      return 170;
    case "date-year":
      return 130;
    case "checkbox":
      return 120;
    case "text":
      return 120;
    case "textarea":
      return 320;
    case "number":
    case "currency":
      return 100;
    case "multi-dropdown":
      return 150;
    case "dropdown":
      return 150;
    default:
      return 200;
  }
};

function DefectGridContainer(props) {
  const dataGridRef = useRef(null);
  const state = useSelector((state) => state.tddKeyFinding);
  const scope = useSelector((state) => state.container.scope);
  const [groupTrade, setGroupTrade] = useState([]);
  const dispatch = useDispatch();
  const [dataRows, setDataRows] = useState([]);
  const [editRow, setEditRow] = useState({
    isOpen: false,
    selectedRow: null,
  });

  useEffect(() => {
    if (state.grid.type === "FETCHED" && !!state.grid.data) {
      let temp = [];
      let defectCount = [];
      state.grid.data?.dataDtoList.forEach((el, tradeIndex) => {
        var groupTrade = {
          id: `row${Math.random() * 30}-${tradeIndex}-${el.trade.ident}`,
          trade: el.trade,
          type: "trade_row",
          height: 40,
          count: el.dataDtoList.length,
          rowOrder: tradeIndex,
        };
        temp.push(groupTrade);
        defectCount.push(groupTrade);

        let defectList = el.dataDtoList.map((m, defectIndex) => {
          const riskType = riskTypeOptions.find(
            (f) =>
              f.value === m.riskType && f.language === scope.projectLanguageCode
          );
          const defectType = defectTypeOptions.find(
            (f) =>
              f.value === m.defectType &&
              f.language === scope.projectLanguageCode
          );

          const priority = priorityTypeOptions.find(
            (f) =>
              f.value === m.priority && f.language === scope.projectLanguageCode
          );

          let data = {
            id: `row${Math.random() * 30}-${tradeIndex}-${defectIndex}`,
            type: "defect_row",
            measureIdent: m.measureIdent,
            defectIdent: m.defectIdent,
            deleted: false,
            updated: false,
            trade: m.trade,
            element: m.element,
            defect: m.defect,
            measure: m.measure,
            comments: m.comments,
            inRedFlag: m.inRedFlag,
            imageCount: m.defectImagesCount,
            priority: {
              name: priority.title,
              options: priorityTypeOptions.filter(
                (f) => f.language === scope.projectLanguageCode
              ),
              multiple: false,
            },
            capexRef: m.capexRef,
            shortTermCost: m.shortTermCost,
            mediumTermCost: m.mediumTermCost,
            longTermCost: m.longTermCost,
            totalCost: m.totalCost,
            createdBy: m.createdBy,
            pricePerUnit: m.pricePerUnit,
            unit: m.unit,
            quantity: m.quantity,
            elementAffected: m.elementAffected,
            costDefect: m.costDefect,
            extraCost: m.extraCost,
            extraCostComment: m.extraCostComment,
            defectType: {
              name: defectType.title,
              options: defectTypeOptions.filter(
                (f) => f.language === scope.projectLanguageCode
              ),
              multiple: false,
            },
            riskType: {
              name: riskType.title,
              options: riskTypeOptions.filter(
                (f) => f.language === scope.projectLanguageCode
              ),
              multiple: false,
              color: riskType.color,
              displayOrder: riskType.displayOrder,
            },
            percentageShortTermPriority: m.percentageShortTermPriority,
            percentageMidTermPriority: m.percentageMidTermPriority,
            percentageLongTermPriority: m.percentageLongTermPriority,
            dataRoomIndex: m.dataRoomIndex,
            quelle: m.quelle,
            questionAndAnswer: m.questionAndAnswer,
          };

          let tsize = getTextSize(m.defect.concat(m.measure), "35px");
          let height = getTextHeight(tsize[`width`]);
          data = {
            ...data,
            height: height < 140 ? 140 : height,
            original: data,
            changed: {},
            rowOrder: tradeIndex + (defectIndex + 1) / 1000,
          };
          return data;
        });

        // Sort defects within this trade
        defectList.sort((a, b) => {
          if (a.riskType.displayOrder !== b.riskType.displayOrder) {
            return (
              Number(a.riskType.displayOrder) - Number(b.riskType.displayOrder)
            );
          }
          return Number(a.capexRef || 0) - Number(b.capexRef || 0);
        });

        // Add sorted defects for this trade to temp array
        temp.push(...defectList);
      });

      // Sort the entire temp array based on rowOrder
      temp.sort((a, b) => a.rowOrder - b.rowOrder);

      setDataRows(temp);
      setGroupTrade(defectCount);
      dispatch(resetState());
    } else if (state.grid.type === "NO_DATA") {
      setDataRows([]);
      dispatch(resetState());
    }
  }, [dispatch, scope.projectLanguageCode, state.grid]);

  // const getdefectAndMeasureColumnEditor = useCallback((p) => {
  //   return (
  //     <DefectMeasureTextEditor
  //       open={true}
  //       defectLabel={getIntlTranslation("_Defect-Description")}
  //       measureLabel={getIntlTranslation("_Measure-Description")}
  //       grid={p}
  //       onSave={(val) => {
  //         let checkDefectUpdated = checkIfUpdated(
  //           val.defect,
  //           p.row.original.defect,
  //           p.row.updated
  //         );
  //         let checkMeasureUpdated = checkIfUpdated(
  //           val.measure,
  //           p.row.original.measure,
  //           p.row.updated
  //         );
  //         if (checkDefectUpdated || checkMeasureUpdated) {
  //           dataGridRef.current.onSingleRowSave({
  //             ...p.row,
  //             ...val,
  //           });
  //         }
  //       }}
  //       onCancel={() => p.onClose(false)}
  //     />
  //   );
  // }, []);

  const dispatchUpdatedRows = (row) => {
    let temp = [...state.updatedRows];

    if (!state.updatedRows.find((f) => f.id === row.id)) {
      temp.push(row);
    } else {
      temp = state.updatedRows.filter((f) => f.id !== row.id);
      temp.push(row);
    }
    dispatch(setUpdatedRows(temp));
  };
  const openEditDialogEditor = useCallback((p) => {
    return (
      <EditDefect
        open={true}
        defectIdent={p.row.defectIdent}
        onClose={() => {
          setEditRow({
            isOpen: false,
            selectedRow: null,
          });
          p.onClose(false);
        }}
        tab={props}
        tabSelected={props.tabSelected}
        onUpdate={(val) => {
          setEditRow({
            isOpen: false,
            selectedRow: null,
          });
          props.onUpdate(val);
        }}
      />
    );
  }, []);

  const selectRow = {
    ...SelectColumn,
    name: "Action",
    headerCellClass: "header-cell",
    required: true,
    checked: true,
    order: 0,
    frozen: true,
    width: 90,
    maxWidth: 90,
    headerRenderer: (col) => renderHeader(col.column.name),
    formatter(p, x) {
      return createSelectRowFormatter(p, x, dataGridRef);
    },
    onDefectEdit: (p) => {
      setEditRow({
        isOpen: true,
        selectedRow: p.row,
      });
    },
  };

  const getColumns = useMemo(() => {
    return [
      selectRow,
      {
        name: "Capex Ref",
        key: "capexRef",
        frozen: true,
        editorType: "text",
        width: 80,
        order: 1,
        editable: true,
        headerCellClass: "header-cell",
        colSpan: (params) => {
          if (isSectionRow(params)) {
            return 3;
          } else {
            return 1;
          }
        },
        cellClass(args) {
          if (args.type === "trade_row") {
            return "g-btn-secondary fw-bold";
          } else {
            return undefined;
          }
        },
        headerRenderer: (col) => renderHeader(col.column.name),
        editor: (p) => {
          if (!p.row.deleted && p.row.type === "defect_row") {
            return openEditDialogEditor(p, props);
          } else {
            return null;
          }
        },
        editorOptions: {
          renderFormatter: true,
        },
        formatter: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return (
              <>
                <div
                  style={{ filter: "blur(4px)", height: "100%", width: "100%" }}
                >
                  {getCapexRefFormatter(p)}
                </div>
              </>
            );
          } else if (p.row.type === "defect_row") {
            return getCapexRefFormatter(p);
          } else {
            return (
              <>
                <div style={cellStyle}>
                  <Typography variant="body1" className="fw-bold">
                    {p.row.trade?.value}
                  </Typography>
                  <Avatar
                    style={{
                      backgroundColor: "#d08f8e",
                      width: 20,
                      height: 20,
                      margin: 10,
                    }}
                    variant="rounded"
                  >
                    <Typography
                      variant="caption"
                      style={{
                        alignItems: "center",
                        paddingLeft: 1,
                        display: "contents",
                      }}
                    >
                      {p.row.count}
                    </Typography>
                  </Avatar>
                </div>
              </>
            );
          }
        },
      },
      {
        name: getIntlTranslation("_risk"),
        key: "risk",
        headerCellClass: "header-cell",
        editorType: "text",
        frozen: true,
        editable: true,
        width: 70,
        headerRenderer: (col) => renderHeader(col.column.name),
        formatter: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return (
              <>
                <div
                  style={{
                    ...cellStyle,
                    filter: "blur(4px)",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: p.row.riskType.color,
                      textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                    {p.row.riskType?.name}
                  </Typography>
                </div>
              </>
            );
          } else if (p.row.type === "defect_row") {
            return (
              <div style={{ ...cellStyle, cursor: "pointer" }}>
                <Typography
                  variant="body2"
                  style={{
                    color: p.row.riskType.color,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                >
                  {p.row.riskType?.name}
                </Typography>
              </div>
            );
          } else {
            return <></>;
          }
        },
        editor: (p) => {
          if (!p.row.deleted && p.row.type === "defect_row") {
            return openEditDialogEditor(p, props);
          } else {
            return null;
          }
        },
        editorOptions: {
          renderFormatter: true,
        },
      },
      {
        name: getIntlTranslation("_element-type"),
        key: "element",
        headerCellClass: "header-cell",
        editorType: "text",
        frozen: true,
        editable: false,
        width: 150,
        headerRenderer: (col) => renderHeader(col.column.name),
        formatter: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return (
              <>
                <div className={"w-100 h-100"} style={{ ...cellStyle }}>
                  {getTranslationVariantText("_mark_row_delete", "caption", {
                    color: "#ff0000",
                    fontWeight: "bold",
                  })}
                </div>
              </>
            );
          } else if (p.row.type === "defect_row") {
            return (
              <div style={{ ...cellStyle, cursor: "pointer" }}>
                <Typography variant="body2">{p.row.element.value}</Typography>
              </div>
            );
          } else {
            return <></>;
          }
        },
      },
      {
        name: getIntlTranslation("_defect-and-measure"),
        key: "defect",
        resizable: true,
        frozen: true,
        headerCellClass: "header-cell",
        editorType: "textarea",
        editable: true,
        width: 380,
        headerRenderer: (col) => renderHeader(col.column.name),
        cellClass(args) {
          if (args.type === "trade_row") {
            return "g-btn-secondary";
          } else {
            return undefined;
          }
        },
        formatter: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return (
              <>
                <div>{defectAndMeasureColumnFormatter(p)}</div>
              </>
            );
          } else if (p.row.type === "defect_row") {
            return defectAndMeasureColumnFormatter(p);
          } else {
            return <></>;
          }
        },
        editor: (p) => {
          if (!p.row.deleted && p.row.type === "defect_row") {
            return openEditDialogEditor(p, props);
          } else {
            return null;
          }
        },
        editorOptions: {
          renderFormatter: true,
        },
      },
      {
        name: getIntlTranslation("_Cost-Data"),
        key: "cost",
        headerCellClass: "header-cell",
        cellClass(args) {
          if (args.type === "trade_row") {
            return "g-btn-secondary";
          } else {
            return undefined;
          }
        },
        flex: 1,
        minWidth: 460,
        editor: (p) => {
          if (p.row.deleted) {
            return null;
          } else if (p.row.type === "defect_row" && !p.row.deleted) {
            return openEditDialogEditor(p, props);
          } else {
            return null;
          }
        },
        editorOptions: {
          renderFormatter: true,
        },
        headerRenderer: (col) => renderHeader(col.column.name),
        formatter: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return (
              <>
                <div
                  style={{ filter: "blur(4px)", height: "100%", width: "100%" }}
                >
                  {costColumnFormatter(p)}
                </div>
              </>
            );
          } else if (p.row.type === "defect_row") {
            return costColumnFormatter(p);
          } else {
            return <></>;
          }
        },
      },
      {
        name: getIntlTranslation("_Comments"),
        key: "comments",
        headerCellClass: "header-cell",
        editorType: "textarea",
        editable: true,
        width: getWidth({ editor: "textarea" }),
        headerRenderer: (col) => renderHeader(col.column.name),
        cellClass(args) {
          if (args.type === "trade_row") {
            return "g-btn-secondary";
          } else {
            return undefined;
          }
        },
        formatter: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return (
              <>
                <div
                  style={{ filter: "blur(4px)", height: "100%", width: "100%" }}
                >
                  <div style={cellStyle}>
                    <Typography variant="body2">{p.row.comments}</Typography>
                  </div>
                </div>
              </>
            );
          } else if (p.row.type === "defect_row") {
            return (
              <div style={{ ...cellStyle, cursor: "pointer" }}>
                <Typography variant="body2">{p.row.comments}</Typography>
              </div>
            );
          } else {
            return <></>;
          }
        },
        editor: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return null;
          } else if (p.row.type === "defect_row") {
            return openEditDialogEditor(p, props);
          } else {
            return null;
          }
        },
        editorOptions: {
          renderFormatter: false,
        },
      },
      {
        name: getIntlTranslation("menu.redFlag"),
        key: "inRedFlag",
        editorType: "checkbox",
        editable: true,
        width: getWidth({ editor: "checkbox" }),
        headerCellClass: "header-cell",
        cellClass(args) {
          if (args.type === "trade_row") {
            return "g-btn-secondary";
          } else {
            return undefined;
          }
        },
        headerRenderer: (col) => renderHeader(col.column.name),
        formatter: (p) => {
          if (p.row.type === "defect_row" && p.row.deleted) {
            return (
              <>
                <div
                  style={{ filter: "blur(4px)", height: "100%", width: "100%" }}
                ></div>
              </>
            );
          } else if (p.row.type === "defect_row") {
            return (
              <SelectCellFormatter
                tabIndex={-1}
                value={p.row.inRedFlag}
                onChange={(e) => {
                  updateDataRow({
                    newVal: e,
                    type: "inRedFlag",
                    data: p,
                  });
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },

      {
        name: getIntlTranslation("_created-by"),
        key: "createdBy",
        headerCellClass: "header-cell",
        editorType: "text",
        editable: false,
        width: getWidth({ editor: "text" }),
        headerRenderer: (col) => renderHeader(col.column.name),
        cellClass(args) {
          if (args.type === "trade_row") {
            return "g-btn-secondary";
          } else {
            return undefined;
          }
        },
        formatter: (p) => {
          if (p.row.type === "defect_row") {
            return (
              <div style={cellStyle}>
                <Typography variant="body2" style={{ color: "#808080" }}>
                  {p.row.createdBy}
                </Typography>
              </div>
            );
          } else {
            return <></>;
          }
        },
      },
    ];
  }, []);

  const updateDataRow = useCallback(({ newVal, type, data }) => {
    const isUpdated = checkIfUpdated(
      newVal,
      data.row.original[type],
      data.row.updated
    );
    data.onRowChange(
      {
        ...data.row,
        [type]: newVal,
        updated: isUpdated,
      },
      true
    );
    dispatchUpdatedRows({
      ...data.row,
      [type]: newVal,
      updated: isUpdated,
    });
  }, []);

  const checkIfUpdated = (newVal, oldValue, isUpdated) => {
    let bool = false;
    if (isEmpty(newVal) && !!oldValue) {
      bool = true;
    } else if (!isEmpty(newVal) && newVal !== oldValue) {
      bool = true;
    }

    return bool || isUpdated;
  };

  const getSubCategories = useCallback(() => {
    let selectedCategory = props.navLinks.find(
      (f) => f.ident === props.tabSelected.ident
    );
    let children = selectedCategory?.children;
    if (groupTrade.length > 0 && children.length > 0) {
      let temp = children.map((item) => {
        let tempObj = { ...item };
        let found = groupTrade.find((g) => g?.trade?.ident === item.ident);
        if (!!found) {
          tempObj.count = found.count;
        }
        return tempObj;
      });
      selectedCategory.children = temp;
    }
    return selectedCategory;
  }, [props.navLinks, props.tabSelected.ident, groupTrade, dataRows]);

  return (
    <>
      <Box
        style={{
          width: "100%",
          height: 40,
          marginBottom: 10,
        }}
      >
        {props.tabs.length > 0 && (
          <CategoriesTab
            onSave={(e) => {
              dataGridRef.current.onSave();
            }}
            categories={{ options: props.tabs }}
            onTabSelect={(val) => props.onTabSelect(val)}
            needGridRefresh={() => props.onUpdate(props.tabSelected)}
          />
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "calc(100% - 50px)",
        }}
      >
        <SubCategoriesPanel
          category={props.navLinks.find(
            (f) => f.ident === props.tabSelected.ident
          )}
          onUpdate={() => {
            props.onUpdate(props.tabSelected);
          }}
        />
        {dataRows.length > 0 && (
          <>
            <DefectDataGrid
              rows={dataRows}
              columns={getColumns}
              ref={dataGridRef}
              needGridRefresh={() => props.onUpdate(props.tabSelected)}
            />
          </>
        )}
      </Box>

      {editRow.isOpen && (
        <EditDefect
          open={editRow.isOpen}
          defectIdent={editRow.selectedRow.defectIdent}
          onClose={() =>
            setEditRow({
              isOpen: false,
              selectedRow: null,
            })
          }
          tab={props.tabSelected}
          tabSelected={props.tabSelected}
          onUpdate={(val) => {
            setEditRow({
              isOpen: false,
              selectedRow: null,
            });
            props.onUpdate(val);
          }}
        />
      )}
    </>
  );
}

DefectGridContainer.propTypes = {
  tabs: PropTypes.array,
  navLinks: PropTypes.array,
  tabSelected: PropTypes.object,
  onTabSelect: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default DefectGridContainer;
