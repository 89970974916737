import React, { useRef } from "react";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Grid,
  IconButton,
  TextField,
  Button,
  Box,
  Divider,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  getIntlTranslation,
  getVariantText,
  getTranslationVariantText,
  getDialogBoxHeader,
  getLabel,
} from "../../../../../utils/helper";
import CustomButton from "../../../../../components/buttons/CustomButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChapterFnc = ({
  type,
  handleSubmit,
  handleClose,
  handleClickOpen,
  openDialog,
}) => {
  const formRef = useRef();
  const intialFormData = {
    english: "",
    german: "",
    option: [],
  };


  const validationSchema = yup.object({
    english: yup.string().required("English translation Name is required"),
    german: yup.string().required("German translation Name is required"),
    option: yup.array().of(
      yup.object().shape({
        optionEnglish: yup
          .string()
          .required("English translation Name is required"),
        optionGerman: yup
          .string()
          .required("German translation Name is required"),
      })
    ),
  });

  const optionType = type === "Chapter" ? "Section" : "Subsection";
  const text = ` You can optionally add a ${optionType} to the ${type}, or you can also do it after creating your ${type}`;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "left",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxHeight: 60,
        margin: 1,
        height: "100%",
      }}
    >
      <Button
        style={{
          display: "flex",
          alignItems: "center",
          height: 40,
          padding:1
        }}
        onClick={() => {
          formRef.current.resetForm();
          handleClickOpen()
        }}
        variant="contained"
        className="g-btn-primary"
      >
        <IconButton>
          <AddIcon fontSize="medium" style={{ color: "#ffffff" }} />
        </IconButton>
        <Box className="d-flex align-items-center">{getTranslationVariantText("add","subtitle2",{
          color:"#ffffff"
        })} {getVariantText(`(${type})`,"subtitle2",{
          color:"#ffffff",marginLeft:5
        })}</Box>
      </Button>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        aria-describedby="alert-dialog-slide-description"
        style={{overflow: "hidden"}}
        className="custom-scrollBar"
      >
        <AppBar className="appBar" style={{ height:40}}>
          <Toolbar
            className={"dialog-toolbar p-1"}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box className="d-flex align-items-center" style={{marginLeft:5}}>
           {getDialogBoxHeader("add")} {getVariantText(`( ${type} )`, "body2",{textTransform: "uppercase",marginLeft:5 })}
            </Box>        
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={(e) => handleClose()}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Formik
          enableReinitialize
          initialValues={intialFormData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}

        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <DialogContent
              className="d-flex align-items-center w-100 custom-scrollBar"
                dividers
            style={{height:"calc(100% - 80)", overflowY: "auto" , overflowX:"hidden"}}
              >
                <DialogContentText
                  sx={{ width: "100%" }}
                  id="alert-dialog-slide-description"
                >
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <IconButton>
                    <InfoIcon style={{width:18, height:18}}/>
                    </IconButton>
                    <ListItemText
                      primary={<Typography variant="body1" style={{ fontWeight:"bold",color:"#254a9a"}}>Please enter the name of the {type} in English and German</Typography>}
                    />
                  </Grid>
                  <Grid style={{ width: "100%"}}>
                    <Grid style={{ display: "flex", marginTop: 3 }}>
                      <Box sx={{ marginRight: 2 }}>
                        <Field
                          fullWidth={true}
                          label={getLabel("_English-Translation")}
                          id="fullWidth"
                          size="small"
                          as={TextField}
                          name={`english`}
                          value={values.english}
                          sx={{ minWidth: 300, margin:2}}
                          error={touched.english && errors.english}
                          helperText={touched.english && errors.english}
                        />
                      </Box>
                      <Box>
                        <Field
                          fullWidth={true}
                          label={getLabel("_German-Translation")}
                          id="fullWidth"
                          size="small"
                          as={TextField}
                          name={`german`}
                          value={values.german}
                          sx={{ minWidth: 300, margin:2 }}
                          error={touched.german && errors.german}
                          helperText={touched.german && errors.german}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {type === "Chapter" && (
                    <>
                      <Divider textAlign="center">
                        <Typography variant="body1" style={{fontWeight:"bold", color:"#254a9a"}}>Section</Typography>
                        </Divider>
                      <Grid
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginTop: 3,
                        }}
                      >
                        <IconButton>
                          <InfoIcon style={{width:18, height:18}}/>
                        </IconButton>
                        <ListItemText primary={<Typography variant="body1" style={{ fontWeight:"bold",color:"#254a9a"}}>{text}</Typography>} />
                      </Grid>
                      <FieldArray
                        name="option"
                        render={(arrayHelpers) => {
                          const options = values.option;
                          return (
                            <Grid>
                              {options && options.length > 0
                                ? options.map((el, index) => (
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        marginTop: 3,
                                      }}
                                      key={index}
                                    >
                                      <Box sx={{ marginRight: 2 }}>
                                        <Field
                                          fullWidth={true}
                                          label={getLabel("_English-Translation")}
                                          id="fullWidth"
                                          size="small"
                                          as={TextField}
                                          value={options[index].optionEnglish}
                                          name={`option[${index}].optionEnglish`}
                                          sx={{ minWidth: 300, margin:2 }}
                                          error={Boolean(
                                            getIn(
                                              touched,
                                              `option[${index}].optionEnglish`
                                            ) &&
                                              getIn(
                                                errors,
                                                `option[${index}].optionEnglish`
                                              )
                                          )}
                                          helperText={
                                            getIn(
                                              touched,
                                              `option[${index}].optionEnglish`
                                            ) &&
                                            getIn(
                                              errors,
                                              `option[${index}].optionEnglish`
                                            )
                                          }
                                        />
                                      </Box>
                                      <Box>
                                        <Field
                                          fullWidth={true}
                                          label={getLabel("_German-Translation")}
                                          id="fullWidth"
                                          size="small"
                                          as={TextField}
                                          value={options[index].optionGerman}
                                          name={`option[${index}].optionGerman`}
                                          sx={{ minWidth: 300, margin:2 }}
                                          error={Boolean(
                                            getIn(
                                              touched,
                                              `option[${index}].optionGerman`
                                            ) &&
                                              getIn(
                                                errors,
                                                `option[${index}].optionGerman`
                                              )
                                          )}
                                          helperText={
                                            getIn(
                                              touched,
                                              `option[${index}].optionGerman`
                                            ) &&
                                            getIn(
                                              errors,
                                              `option[${index}].optionGerman`
                                            )
                                          }
                                        />
                                      </Box>
                                      <Box>
                                        <Button
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          sx={{ width: "30px", height: "30px", margin:2 }}
                                        >
                                          <IconButton>
                                            <DeleteOutlineIcon fontSize="medium"  style={{color:"#f50057"}}/>
                                          </IconButton>
                                        </Button>
                                      </Box>
                                    </Grid>
                                  ))
                                : null}
                              <Grid
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  marginTop: 1,
                                }}
                              >
                                <Button
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 0,
                                    paddingRight: 1,
                                  }}
                                  onClick={() => {
                                    arrayHelpers.push({
                                      optionEnglish: "",
                                      optionGerman: "",
                                    });
                                  }}
                                  variant="outlined"
                                >
                                  <IconButton>
                                    <AddIcon fontSize="medium" />
                                  </IconButton>
                                  <Typography variant="button" style={{color:"#d08f8e", fontWeight:"bold",}}>{getIntlTranslation("add")} {optionType}</Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    </>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <CustomButton
                  id={`save-dialog`}
                  buttonText={"_save"}
                  variant="button"
                  style={{
                    margin: 5,
                    backgroundColor: "#254a9a",
                    color: "#ffffff",
                  }}
                  tooltip={{
                    title: getIntlTranslation("tooltip.save"),
                  }}
                  handleClick={()=>formRef.current.handleSubmit()}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

export default AddChapterFnc;
