import React, { useEffect, useState } from "react";
import { useReportStructureContextHook } from "./ReportStructureContext";
import {
  CardContent,
  List,
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Slide,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderNode } from "./reportItemRender";
import { scroller } from "react-scroll";

const scrollTo = function (id) {
  scroller.scrollTo(`${id}`, {
    containerId: "data-container",
    horizontal: false,
    isDynamic: true,
    offset: -3,
    smooth: true,
    spy: true,
  });
};

const TreeNode = ({ node, index, chapterIdent }) => {
  const { selectedItem, onSelectedItem } = useReportStructureContextHook();
  const [collapsed, setCollapsed] = useState(false);
  let defaultSkipped = false;

  useEffect(() => {
    if(selectedItem.chapterIdent === node.ident){
      setCollapsed(true);
    }else {
      setCollapsed(false); 
    }
  }, [selectedItem, node.ident]); 

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onItemSelection = (item, index) => {
    let selectedIndex = `${index}`;
    if (item.type.includes("header-chapter")) {
      let firstChild = item.children[0];
      selectedIndex = firstChild.default ? `${index}`: `${index}.1.`;
      item = {
        ...firstChild,
        parentId: item.parentId,
        chapterIdent:item.ident,
        id: firstChild.default ? item.ident : firstChild.ident,
      };
    }
    if (item.depth >= 3) {
      scrollTo(node.id);
    }else if(selectedItem.ident !== item.ident || selectedItem.item !== item.parentId) {
      toggleCollapse();
      onSelectedItem({...item,selectedIndex:selectedIndex});
    }
   
    
  };

  const checkDataNodes = () => node.children?.filter((f) => !f.type.includes("data-") && !f.default).length > 0;
  return (
    <div
      key={node.id}
      className={`w-100 ${
        selectedItem.parentId === node.id ? "g-selected-parent-list p-1" : ""
      }`}
      style={{ borderRadius: 4 }}
    >
      <ListItem
        className={`p-1 list-item m-0 w-100 ${
          selectedItem.id === node.id ? "g-selected-list-item" : ""}
        `}
        key={node.id}
      >
        <Box className="d-flex flex-column align-items-center w-100">
          <Box className="d-flex align-items-center w-100">
            <ListItemButton
              className={`d-flex align-items-center p-0 justify-content-start`}
              onClick={(e) => {
                onItemSelection({ ...node}, index);
              }}
              key={`lb-${node.id}`}
            >
              <Box
                className={"d-flex w-100 align-items-center ml-1"}
                style={{ width: "80%" }}
              >
                <ListItemText primary={renderNode(node, selectedItem, index)} />
              </Box>
              {checkDataNodes() && (
              <Box style={{ width: "20%" }}>
                <IconButton
                  className="p-0 mt-1"
                  style={{ height: 40, width: 40}}
                  onClick={(e) => {
                    e.stopPropagation();
                     toggleCollapse();
                  }}
                >
                  {collapsed &&  (
                    <FontAwesomeIcon
                      icon="fa-solid fa-angle-down"
                      style={{
                        color:
                          selectedItem?.id === node.id ? "#ffffff" : "#254a9a",
                        width: 15,
                        height: 15,
                      }}
                    />
                  )}
                  {!collapsed && (
                    <FontAwesomeIcon
                      icon="fa-solid fa-angle-right"
                      style={{
                        color:
                          selectedItem?.id === node.id ? "#ffffff" : "#254a9a",
                        width: 15,
                        height: 15,
                      }}
                    />
                  )}
                </IconButton>
              </Box>
              )}
            </ListItemButton>
          </Box>
        </Box>
      </ListItem>
      {node.children?.length > 0 && (
        <Collapse
        direction="up"
          in={collapsed || selectedItem.id === node.id}
          mountOnEnter
          unmountOnExit
          timeout={{enter: 500, exit: 300}}
        >
          <List className="p-0" dense>
            {node.children.map((child, i) => {
              if (child.children?.length > 0) {
                child = {
                  ...child,
                  children: child.children.map((f) => {
                    return { ...f, header: f.default ? false : true };
                  }),
                };
              }
              if (child.type.includes("data-") || child.default) {
                if(child.default){
                  defaultSkipped = true
                }
                return <></>;
              }
              index = `${index}`.replace(/\.$/, "");
              return (
                <TreeNode
                  index={`${index}.${defaultSkipped ? i : i + 1}.`}
                  node={{ ...child, parentId: node.id, id: child.ident, chapterIdent:chapterIdent }} 
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </div>
  );
};

const DocumentMap = () => {
  const { documentTree, selectedItem, onSelectedItem } = useReportStructureContextHook();
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    if(documentTree.length > 0){
      setLoading(false);
    }
  },[documentTree]
)
useEffect(() => {
if(documentTree.length > 0 && !selectedItem.ident) {
  let item = documentTree[0];
  if (item.type.includes("header-chapter")) {
    let firstChild = item.children[0];
    item = {
      ...firstChild,
      parentId: item.ident,
      chapterIdent:item.ident,
      id: firstChild.default ? item.ident : firstChild.ident,
      selectedIndex: firstChild.default ? '1' : '1.1.',
    };
  }
  onSelectedItem(item);
}
}, [documentTree])

  return (
    <>
      <CardContent
        className="custom-scrollBar p-0"
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          height: "100%",
        }}
      >
        {loading ? (
        <List>
          {[1, 2, 3, 4, 5].map(index => (
            <ListItem key={index}>
              <ListItemText>
                <Skeleton variant="text" />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ): (
        <>
         {!!documentTree && documentTree.length > 0 && (
          <List className="p-0 w-100" dense>
            {documentTree?.map((item, index) => {
              item = {
                ...item,
                children: item.children.map((f) => {
                  return { ...f, header: f.default ? false : true };
                }),
              };
              if (item.type.includes("data-") || item.default) {
                return <></>;
              }
              return (
                <TreeNode
                  key={`${item.id}-${index}`}
                  node={{...item, parentId:item.id,chapterIdent:item.id}}
                  index={item.index}
                  chapterIdent={item.id}
                />
              );
            })}
          </List>
        )}
        </>
      )}
      </CardContent>
    </>
  );
};

DocumentMap.propTypes = {};

export default DocumentMap;
