import { Card, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useSortable } from '@dnd-kit/sortable';
import { useDocumentContextHook } from './DocumentBuilderContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StarHalfIcon from "@mui/icons-material/StarHalf";

const iconStyle = {
  color: "#d08f8e",
  width: 18,
  height: 18,
};

const renderNodeIcon = (node) => {
  switch (node.nodeType) {
    case "document-root":
      return <FontAwesomeIcon icon="fa-solid fa-file-word" style={iconStyle} />;
    case "header-chapter":
      return <FontAwesomeIcon icon="fa-solid fa-heading" style={iconStyle} />;
    case "sub-header":
      return (
        <FontAwesomeIcon icon="fa-solid fa-angle-right" style={iconStyle} />
      );
    case "data-ratings":
      return <StarHalfIcon style={iconStyle} />;
    case "data-contact":
      return (
        <FontAwesomeIcon icon="fa-solid fa-address-card" style={iconStyle} />
      );
    case "data-address":
      return (
        <FontAwesomeIcon icon="fa-solid fa-location-dot" style={iconStyle} />
      );
    case "data-paragraph":
      return <FontAwesomeIcon icon="fa-solid fa-paragraph" style={iconStyle}/>
    case "data-images":
      return <FontAwesomeIcon icon="fa-solid fa-images" style={iconStyle} />;
    case "data-label-text-edtior":
      return (
        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" style={iconStyle} />
      );
      case "data-two-column":
        return (
          <FontAwesomeIcon icon="fa-solid fa-table-columns" style={iconStyle} />
        );
    default:
      return node.settings.title;
  }
};

const DragableItems = (props) => {
  const {item} = props;
  const { handleDrop } = useDocumentContextHook();
  const { attributes, listeners, setNodeRef} = useSortable({ id: item.key });
  return (
    <Card
    className='d-flex align-items-center m-1'
      style={{
        width:"98%",
        height:40,
        borderRadius:4,
        cursor: 'grab',
        backgroundColor: "rgb(244, 245, 247)",
      }}
      ref={setNodeRef}
      key={item.key}
      raised={true}
      {...attributes}
      {...listeners}
      id={item.key}
    >
      <IconButton>
      {renderNodeIcon(item)}
      </IconButton>
      <Typography
        variant="caption"
        color="text.secondary"
      >
        {item.label}
      </Typography>
    </Card>
  )
}

export default DragableItems