import documentBuilderSlice from "./documentBuilderSlice";


export const documentStoreReducers = {
    documentBuilder:documentBuilderSlice,
};

export const documentStoreBlackList = [
    "documentBuilder"
 
];