import React from "react";
import { Typography, Box, Grid } from "../../common/commonImport";

const NoData = ({ preMassage, secMassage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "white",
        height: "100vh",
        width: "100%",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid style={{ width: "80%" }}>
        <Grid>
          {preMassage && (
            <Typography variant="h4" component="div">
              {preMassage}
            </Typography>
          )}
          {secMassage && (
            <Typography variant="subtitle1" component="div">
              {secMassage}
            </Typography>
          )}
          <Typography variant="subtitle1" component="div">
            {("It support")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoData;
