import { Box } from '../../../../common/commonImport';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataSectionHome from '../../common/report-structure/chapter-structure/DataSectionHome';
import { resetPromptState } from '../../common/slice/dataSlice';
import ReportContainer from '../../common/report-component/ReportContainer';
import { ReportStructureContextProvider } from '../../common/report-component/ReportStructureContext';

const RedFlagReportHome = () => {
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(resetPromptState());
    }, [])

  return (
   
    <ReportStructureContextProvider  report={'redflag'}>
    <Box className="h-100">
            <ReportContainer/>
    </Box>
    </ReportStructureContextProvider>
  );
};

export default RedFlagReportHome;
