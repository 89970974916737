
import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/index.scss";
import "./scss/theme/guru.theme.scss";
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import CustomLoader from "./components/CustomLoader";
import { SnackbarProvider } from 'notistack';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirebaseConfig } from "./utils/helper";

const root = ReactDOM.createRoot(document.getElementById("root"));
const firebaseConfig = getFirebaseConfig();
const firebaseApp = initializeApp(firebaseConfig);
export const database = getDatabase(firebaseApp);

root.render(
  <>
    <CustomLoader promiseTracker={usePromiseTracker} />
    <Provider store={store}>

        <BrowserRouter>
        <SnackbarProvider maxSnack={3} hideIconVariant dense
  anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }} >
          <App />
          </SnackbarProvider>  
        </BrowserRouter>
    
    </Provider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
