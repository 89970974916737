import React from "react";
import { makeStyles } from 'tss-react/mui';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";


const headerCells = {
  backgroundColor: "#254a9a",
  color: "#FFFFFF",
  align: "center",
}



const GetTableStructure = ({data}) => {
  const createTableStructure = (data) => {
    let temp = [];
    if (data.headers.length > 0) {
      temp.push(
        <TableHead>
          {data.headers.map((dRow,index) => createTableHeader(dRow.row,index))}
        </TableHead>
      );
    }
    if (data.rows.length > 0) {
      temp.push(
        <TableBody>
          {data.rows.map((dRow,index) => createTableRow(dRow.row,index))}
        </TableBody>
      );
    }
    return temp;
  };

  const createTableHeader = (headers,index) => {
    return (
      <TableRow key={`${index}-headers`}>
        {headers.map((h) => {
          return (
            <TableCell style={headerCells}>
              <Typography variant="body1" gutterBottom>
                {h.value}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const createTableRow = (row,index) => {
    return (
   <TableRow key={`${index}-row`}>
        {row.map((r) => {
          const styling = {
            backgroundColor: r.bgColor!==null ? `#${r.bgColor}` : null,
            color:r.textColor!==null ? r.textColor : "#000000"
          }
       
          return (
            <TableCell
              colSpan={r.colSpan != null ? r.colSpan : null}
              style={{ whiteSpace: "pre-wrap" ,backgroundColor:styling.backgroundColor,color: styling.color }}
            >
              <Typography variant="body2" gutterBottom>
                <div style = {{listStyleType: "square"}} dangerouslySetInnerHTML={{__html: `${r.value}`}} />
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };
  return (
    <>
    {createTableStructure(data)}
    </>
  )
}
export const TypeCostSummaryPreviewRender = ({item,reportType}) => {
  const data = item.node;
  return (
    <TableContainer component={Paper}>
      <Table style={{minWidth: 700}} aria-label="spanning table">
        <GetTableStructure data={data} />
      </Table>
    </TableContainer>
  );
}

export const TypeCostSummaryDataRender = ({item, reportType}) => {
  const data = item.node;
  return (
    <TableContainer component={Paper}>
      <Table style={{minWidth: 700}} aria-label="spanning table">
        <GetTableStructure data={data} />
      </Table>
    </TableContainer>
  );
}
