import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditAssetDialog from "./EditAssetDialog";
import SubAssetEditContainer from "./SubAssetEditContainer";
import CreateAssetDialog from "./create-asset/CreateAssetDialog";
import { deleteAsset } from "../../../../../api/assetOverviewApi";
import { deleteSubAsset } from "../../../api/subAssetApi";
import CreateMultipleAssets from './create-asset/CreateMultipleAssets'
import AlertDialogSlide from "../../../../../common/alertDialogConfirmation";
import AssetUserManagementDialog from "./create-asset/AssetUserManagementDialog";
import AssetCreateContainer from "./create-asset/AssetCreateContainer";
// import SubAssetCreateContainer from "./create-asset/SubAssetCreateContainer";
import CreateSubAssetContainer from "../../sub-asset-management/sub-asset/SubAssetCreateContainer"

const AssetDialogSelection = (props) => {
  const { actionScope, onClose } = props;
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  const [openCreateSubAsset, setOpenCreateSubAsset] = useState({
    asset: null,
    open: false,
  });
  const resetScopeState = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const confirmationReceived = (confirmationAction, node) => {
    switch (confirmationAction) {
      case "Ok":
        if (node.type === "asset") {
          dispatch(
            deleteAsset({
              api: scope.api,
              projectIdent: scope.projectIdent,
              assetIdent: node.ident,
            })
          );
        } else if (node.type === "sub-asset") {
          dispatch(
            deleteSubAsset({
              api: scope.api,
              subAssetIdent: node.ident,
              projectIdent: scope.projectIdent,
              name: node.title,
            })
          );
        }
        break;
      case "Cancel":
        break;
      default:
        break;
    }
  };

  const renderForm = () => {
    if (actionScope.openEditAssetDialog) {
      return (
        <EditAssetDialog
          open={actionScope.openEditAssetDialog}
          asset={actionScope.node.asset}
          projectInfo={actionScope.node.project}
          onClose={() => resetScopeState()}
          mode={actionScope.node.viewMode}
        />
      );
    } 
    if (actionScope.openEditSubAssetDialog) {
      return (
        <SubAssetEditContainer
          open={actionScope.openEditSubAssetDialog}
          subAsset={actionScope.node.subAsset}
          projectInfo={actionScope.node.project}
          onClose={() => resetScopeState()}
          mode={actionScope.node.viewMode}
        />
      );
    } 
    else if (actionScope.openAddAssetDialog) {
      return (
        <AssetCreateContainer
          open={actionScope.openAddAssetDialog}
          projectInfo={actionScope.node.project}
          onClose={() => resetScopeState()}
        />
      );
    }

     else if (actionScope.openUserManagement) {
      return (
        <AssetUserManagementDialog
          open={actionScope.openUserManagement}
          projectInfo={actionScope.node.project}
          asset={actionScope.node.asset}
          onClose={() => resetScopeState()}
        />
      );
    } else if (actionScope.openPortFolioAddAssetDialog) {
      return (
        <CreateMultipleAssets
          open={actionScope.openPortFolioAddAssetDialog}
          projectInfo={actionScope.node.project}
          data={actionScope.node.data}
          onClose={() => resetScopeState()}
        />
      );
    } else if (actionScope.openAddSubAssetDialog) {
      return (
        <>
          <CreateSubAssetContainer
          open={actionScope.openAddSubAssetDialog}
          onClose={() => resetScopeState()}
          node={{
                projectIdent: actionScope.node.projectIdent,
                assetIdent: actionScope.node.assetIdent,
          }}
          />
        </>
      );
    } else if (actionScope.openDeleteAlert) {
      return (
        <AlertDialogSlide
          open={actionScope.openDeleteAlert}
          node={actionScope.node}
          buttonText="Delete"
          info={`Are you sure you want to delete ${actionScope.node.title}`}
          additionalInfo="Once Deleted can not be undone."
          severity="warning"
          onConfirmation={confirmationReceived}
          onClose={() => resetScopeState()}
        />
      );
    }
  };

  return <React.Fragment key="1">{renderForm()}</React.Fragment>;
};

export default AssetDialogSelection;
