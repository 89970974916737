import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { getLabel } from '../../../utils/helper';
import { setOpenAsset } from './OpenAssetSlice';
import useAssetListHook from '../hooks/useAssetListHook';

const AssetSwitcher = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [selectedAsset, setselectedAsset] = useState({});
  const [assetsDropdown, setAssetDropdown] = useState([]);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const {assetList, fetchList} = useAssetListHook();

  useEffect(() => {
   if(assetList.isFetchSuccess && assetList.assets.length > 0){
    const list = assetList.assets.map((a,index) => {
      return ({
              index: index,
              name: a.name,
              ident: a.assetIdent,
              subAssetList: a?.subAssetListDto?.map((s) => {
                return {
                  value: s.name,
                  id: s.subAssetIdent,
                };
              }),
              users: a?.userDtos?.map((u) => {
                return {
                  label: `${u.firstName} ${u.lastName}`,
                  value: u.emailAddress,
                };
              }),
              projectIdent: scope.projectIdent,
            })
    })
    setAssetDropdown(list);
   }else if(assetList.isFetchSuccess && assetList.assets.length <= 0) {
    setAssetDropdown([]);
   }
    else{
    fetchList();
   }
  }, [assetList,scope.projectIdent])



  useEffect(() => {
    if(assetsDropdown.length > 0){
    let found = assetsDropdown.find((f) => f.ident === openAsset?.ident);
    if (!!found) {
      setselectedAsset(found);
      dispatch(
        setOpenAsset({
          assetName: found.name,
          ident: found.ident,
          subAssetList: found.subAssetList,
          userDtos: found.users,
          projectIdent: found.projectIdent,
        })
      );
    }else {
      let val = assetsDropdown[0];
      setselectedAsset(val);
      dispatch(
        setOpenAsset({
          assetName: val.name,
          ident: val.ident,
          subAssetList: val.subAssetList,
          userDtos: val.users,
          projectIdent: val.projectIdent,
        })
      );
    }
  }
  }, [assetsDropdown, openAsset.ident])
  
  const getSelectedAsset = (val) => {
    if (props.dirtyState) {
      props.setBlockState({
        assetName: val.name,
        ident: val.ident,
        subAssetList: val.subAssetList,
        userDtos: val.users,
        projectIdent: val.projectIdent,
      });
    } else {
      setselectedAsset(val);
      dispatch(
        setOpenAsset({
          assetName: val.name,
          ident: val.ident,
          subAssetList: val.subAssetList,
          userDtos: val.users,
          projectIdent: val.projectIdent,
        })
      );
    }
  };
  return (
    <>
      {assetsDropdown.length > 0 && (
        <Autocomplete
          id="assetsOptions"
          size="small"
          autoHighlight={true}
          style={{ padding: 5 }}
          value={!!selectedAsset ? selectedAsset : ''}
          options={assetsDropdown}
          onChange={(event, newValue) => {
            if (!!newValue && !!newValue.ident) {
              getSelectedAsset(newValue);
            }
          }}
          getOptionLabel={(option) => option.name || ''}
          getOptionSelected={(option, value) => value.value === option.value}
          renderInput={(params) => (
            <TextField
              {...params}
              minRows={4}
              variant="outlined"
              size="small"
              label={getLabel('_select asset')}
            />
          )}
        />
      )}
    </>
  );
};

AssetSwitcher.propTypes = {
  dirtyState: PropTypes.bool,
  setBlockState: PropTypes.func,
  filterAssetList: PropTypes.array,
};

export default AssetSwitcher;
