import { createSlice } from "@reduxjs/toolkit";
import {response} from "../../../utils/defaultValues"
export const initialState = {
  hasErrors: false,
  error:{...response
 },
 project:{
...response
 }
};

const projectDashboardSlice = createSlice({
  name: "projectDashboard",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }
    }),
    getProjectSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      project:{
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
      },
      error: {},
    }),
    resetState: (state) => ({
      ...initialState,
    }),
    resetOpenProjectState: (state) => ({
      ...initialState,
    }),
  },
});

export const { getProjectSuccess,resetState,getFailure,resetOpenProjectState } = projectDashboardSlice.actions;

// A selector
export const projectDashboardSelector = (state) => state.projectDashboard;

// The reducer
export default projectDashboardSlice.reducer;