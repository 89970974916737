import { createSlice } from '@reduxjs/toolkit';
import { response } from '../../../../../../utils/defaultValues';

export const initialState = {
  hasErrors: false,
  error: { ...response },
  fetch: { ...response },
  save: { ...response },
  updatedRows: [],
  propertyAction: {
    action: null,
    status: null,
    data: null,
  },
};

const buildingProfileTableSlice = createSlice({
  name: 'buildingProfileTable',
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetch: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),

    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    setUpdatedRows: (state, { payload }) => {
      const temp = [];
      state.updatedRows?.forEach((f) =>  {
        if(f.nodeIdent !== payload.nodeIdent){
          temp.push(payload);
        }
        else if(f.nodeIdent === payload.nodeIdent && f.subAssetIdent !== payload.subAssetIdent){
            temp.push(payload);
        }
        temp.push(f);
      });

      if(!state.updatedRows.length){
        temp.push(payload);
      }

      return {
        ...state,
        updatedRows: [...temp],
      };
    },
    resetState: (state) => ({
      ...initialState,
    }),
    setPropertyAction: (state, { payload }) => ({
      ...state,
      propertyAction: {
        action: payload.action,
        status: payload.status,
        data: payload.data,
      },
    }),
    resetPropertyAction: (state) => ({
      ...state,
      propertyAction: {
        action: null,
        status: null,
        data: null,
      },
    }),
  },
});

export const {
  resetState,
  getFailure,
  getFetchSuccess,
  getSaveSuccess,
  setUpdatedRows,
  setPropertyAction,
  resetPropertyAction,
} = buildingProfileTableSlice.actions;

// A selector
export const buildingProfileTableSelector = (state) =>
  state.buildingProfileTable;

// The reducer
export default buildingProfileTableSlice.reducer;
