import React, { useState } from "react";
import { Box, IconButton, Divider, Tooltip } from "@mui/material";
import InputSearch from "../../../../../components/InputSearch";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import {
  getIntlTranslation,
  getTranslationVariantText,
  getWarningText,
} from "../../../../../utils/helper";
import { setFullMode } from "../statutoryComplianceSlice";
import { useDispatch, useSelector } from "react-redux";
import ReportGenerationDialog from "./ReportGenerationDialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CustomButton from "../../../../../components/buttons/CustomButton";

const SearchBar = (props) => {
  const inspection = useSelector((state) => state.tddInspection);
  const [generateReport, setGenerateReport] = useState(false);
  const readOnlystate = useSelector((state) => state.readOnly);
  const dispatch = useDispatch();
  const [blockChange, setBlockChange] = useState({
    block: false,
    action: "",
    value: null,
  });

  const openFullModeCondition = () => {
    if (inspection.updatedRows.length > 0) {
      setBlockChange({
        block: true,
        action: "open-fullMode",
        value: true,
      });
    } else {
      dispatch(setFullMode(true));
    }
  };

  return (
    <>
      <Box
        style={{ width: "75%", height: 50, flexGrow: 1, marginBottom: 5  }}
        className="d-flex align-items-center justify-content-around rounded-3"
      >
        <Box style={{  width: "60%"}}>
          <InputSearch onFilterInput={(input) => props.onFilter(input)} />
        </Box>
        <Box
        className="d-flex align-items-center justify-content-around rounded-3"
        style={{
          width: "40%",
          backgroundColor: "#d08f8e",
          height: 40,
          margin: "0px 4px",
        }}
        aria-label="button group for defect grid"
      >
        <CustomButton
          id="save-data-room-rows"
          buttonText="_save"
          className="g-btn-elevated m-1"
          variant="button"
          style={{
            height: 30,
            width: "30%",
            backgroundColor: "#244a9a",
            color: "#ffffff",
          }}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={() => props.onSave()}
        />
         <Divider
                orientation="vertical"
                style={{
                  width: 4,
                  margin: "10px 0px",
                  backgroundColor: "#ffffff",
                }}
                flexItem
              />
          <div className="m-1" style={{ width: "30%" }}>
            <Tooltip title={getIntlTranslation("tooltip.open_reports_dialog")}>
              <IconButton
                size="small"
                aria-label="generate report"
                style={{ height: 22 }}
                className={`d-flex g-btn-elevated align-items-center w-100 m-1 justify-content-around p-0 rounded-3 h-100 text-light p-1 `}
                onClick={() => setGenerateReport(true)}
                id="inspection-generate-report-2"
              >
                <p
                  style={{
                    fontSize: 11,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                  className="m-0"
                >
                  {getIntlTranslation("_generate-report")}
                </p>
                <AssignmentIcon
                  style={{
                    color: "#ffffff",
                    fontSize: 20,
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
          {!inspection.fullMode && (
            <>
              <Divider
                orientation="vertical"
                style={{
                  width: 4,
                  margin: "10px 0px",
                  backgroundColor: "#ffffff",
                }}
                flexItem
              />
              <div className="g-btn-elevated" style={{ margin: 2 }}>
                <CustomIconButton
                  id="open-full-mode"
                  handleClick={() => openFullModeCondition()}
                  style={{
                    borderRadius: 4,
                    margin: 5,
                    height: 30,
                  }}
                  icon={
                    <FullscreenIcon
                      style={{ width: 22, height: 22, color: "#ffffff" }}
                    />
                  }
                  tooltip={{
                    title: getIntlTranslation("tooltip.fullscreen"),
                  }}
                />
              </div>
            </>
          )}
        </Box>
      </Box>
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={blockChange.block}
          stayText="_back"
          saveText="Save And Leave"
          leaveText="_continue"
          onLeave={() => {
            dispatch(setFullMode(blockChange.value));
            setBlockChange({
              block: false,
              value: null,
              action: "",
            });
          }}
          onStay={() =>
            setBlockChange({
              block: false,
              value: null,
              action: "",
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={props.warningText}
        />
      )}
      {generateReport && (
        <ReportGenerationDialog
          open={generateReport}
          onClose={() => setGenerateReport(false)}
          headers={props.headers}
          categories={props.categories.options}
        />
      )}
    </>
  );
};
export default SearchBar;
