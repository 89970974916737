import React from 'react';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import usePopoverState from './usePopoverState';

const PopoverInfo = ({ label, message, image }) => {
  const [anchorEl,
    handleClick,
    handlePopOverClose,
    isOpen] = usePopoverState();

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: '0px',
        width: 'max-width',
        flexGrow: '1',
      }}
    >
      <Typography variant="body2">{label}</Typography>
      <IconButton onClick={(e) => handleClick(e)} style={{ padding: 0, marginLeft:5 }}>
        <InfoIcon fontSize="small" style={{color:"#254a9a"}}/>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={(e) => handlePopOverClose()}
      >
        <Typography style={{ padding: 10, maxWidth: 450 }} variant="body2">
          {message}
        </Typography>
        {
          !!image &&  <img src={image} alt={label} style={{ maxWidth: 450, padding: 10 }} />
        }
       
      </Popover>
    </Box>
  );
};
export default PopoverInfo;
