import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  data:[],
  error: {},
};
// A slice for auth with our success or failure reducers
const subAssetListSlice = createSlice({
  name: "SubAssetList",
  initialState,
  reducers: {
    getFetchListFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    resetSubAssetListState: (state) => ({
      ...state,
      hasErrors: false,
      type: "",
      messages: "",
      assets: [],
      error: {},
      data:[]
    }),
    getFetchListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto.subAssetDtoList,
    }),
     getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFetchListFailure,
  resetSubAssetListState,
  getFetchListSuccess,
  getFetchSuccess
} = subAssetListSlice.actions;

// A selector
export const subAssetListSelector = (state) => state.subAssetList;

// The reducer
export default subAssetListSlice.reducer;
