import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AssetProjectInformation from "./AssetProjectInformation";
import useChapterHook from "../../hooks/useChapterHook";
import {
  getDialogBoxHeader,
  getIntlTranslation,
  getStylingForSelectedComment,
} from "../../../../../../utils/helper";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsPopup from "../../comments/CommentsPopup";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { CloseOutlined } from "@mui/icons-material";
import useCommentSaveHook from "../../../../../component-bundle/hooks/useCommentSaveHook";
import useCommentFetchHook from "../../comments/useCommentFetchHook";
import { useSnackbar } from "notistack";
import useFetchContentFireBase from "../../hooks/firebase/useFetchContentHook";
import useSaveContentFireBase from "../../hooks/firebase/useSaveContentHook";
import { EditingIndicator } from "../EditingIndicator";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const TypeProjectInformationDataRender = ({ item, 
  selectedItem,
  reportType }) => {
  const { onLoadSectionData, onProjectInfoSave } = useChapterHook({
    reportType,
  });
  let data = item.node;
  const apiParam = "project-information";
  const { onSave } = useSaveContentFireBase({});
  return (
    <Box className="d-flex align-items-center p-1 w-100">
      <AssetProjectInformation
        properties={data.properties}
        onSave={(data) => onProjectInfoSave(data, reportType)}
        onSaveSuccess={(data) => {
          onLoadSectionData(selectedItem, reportType);
          onSave(
            apiParam,
            { ident: "properties", 
            path: item.node.path,
            properties: data 
          },
            reportType
          );
        }}
        reportType={reportType}
      />
    </Box>
  );
};

export const TypeProjectInformationPreviewRender = ({ item, reportType }) => {
  const [currentData, setCurrentData] = useState(null);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const apiParam = "project-information";
  const [isEditing, setEditing] = useState({
    id: null,
    editing: false,
  });
  const { onProjectInfoSave } = useChapterHook({
    reportType,
  });
  const { onSave, onEdit } = useSaveContentFireBase({
    apiParam, 
    data:{
      ident:"properties",
      path:item.node.path,
      properties:item.node.properties,
    },
    reportType
  });

  const { updatedNode, setUpdatedNode, locked, getUser } =
    useFetchContentFireBase({
      nodePath: `${apiParam}-properties`,
      reportType,
    });

    const dataRef = useRef({ ident: `properties` });

  useEffect(() => {
    setCurrentData(item.node);
  }, [item.node.path]);

  useEffect(() => {
    if (
      !isEditing.editing &&
      !!updatedNode &&
      `${apiParam}-${dataRef.current.ident}` === updatedNode?.ident &&
      scope.projectIdent === updatedNode?.projectIdent &&
      openAsset.ident === updatedNode?.assetIdent &&
      reportType === updatedNode?.reportType
    ) {
      setCurrentData((prev) => {
       return  ({...prev, properties: Object.values(updatedNode.node)})
      });
      setUpdatedNode(null);
    }
  }, [isEditing.editing,updatedNode?.ident, scope, openAsset]);

  const lockCheck = (locked) => {
    return (
      !!locked?.lockedId &&
      locked.isLocked &&
      locked.lockedId === `${apiParam}-properties` &&
      scope.projectIdent === locked?.projectIdent &&
      openAsset.ident === locked?.assetIdent &&
      reportType === locked?.reportType &&
      locked?.lockedBy.id !== getUser().id
    );
  };

  return (
    <>
      {!!currentData && currentData?.properties.length > 0 && (
        <Box className={`d-flex align-items-baseline m-1 w-100 `}>
          {isEditing.editing ? (
            <Box
              className={`d-flex align-items-center flex-column p-1 w-100 box ${
                isEditing.editing ? "elevated p-1 rounded-2" : ""
              }`}
              style={{ marginTop: 10 }}
            >
              <Dialog
                open={isEditing.editing}
                onClose={(e) =>
                  setEditing({
                    id: null,
                    editing: false,
                  })
                }
                fullWidth={true}
                maxWidth={"lg"}
                TransitionComponent={Transition}
              >
                <AppBar className={"appBar w-100"}>
                  <Toolbar className={"dialog-toolbar w-100"}>
                    {getDialogBoxHeader("_edit")}
                    <IconButton
                      edge="start"
                      color="inherit"
                      id="create_project_close"
                      onClick={(e) => {
                        setEditing({
                          id: null,
                          editing: false,
                        });
                        onEdit(apiParam, "properties", reportType, false);
                      }}
                      aria-label="close"
                      style={{ marginLeft: "auto" }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContent className="p-1 w-100 custom-scrollBar">
                  <AssetProjectInformation
                    properties={currentData.properties}
                    onSave={(data) => onProjectInfoSave(data, reportType)}
                    onSaveSuccess={(value) => {
                      onSave(
                        apiParam,
                        { ident: "properties", 
                        path: item.node.path,
                        properties: value 
                      },
                        reportType
                      );
                    }}
                    reportType={reportType}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          ) : (
            <Box
              className={`d-flex align-items-baseline w-100 flex-wrap p-1 box 
        ${!isEditing.editing && lockCheck(locked) ? "locked-indicator" : ""}
      `}
            >
              <Box className={`d-flex w-100`}>
                <Box style={{ width: "calc(100% - 40px)" }}>
                  <GetTableStructure
                    data={currentData}
                    reportType={reportType}
                  />
                </Box>
                {!lockCheck(locked) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexGrow: "1",
                      width: 40,
                    }}
                    className="g-btn-secondary rounded-3"
                  >
                    <Tooltip title={getIntlTranslation("tooltip.edit")}>
                      <IconButton
                        id={`edit_project_information`}
                        className="p-0 w-100 rounded"
                        onClick={(event) => {
                          event?.stopPropagation();
                          setEditing({
                            editing: true,
                            id: "project_information",
                          });
                          onEdit(apiParam, "properties", reportType, true);
                        }}
                      >
                        <ModeEditIcon
                          style={{ fontSize: 20, color: "#244a9a" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </Box>
              {!isEditing.editing && lockCheck(locked) && (
                <EditingIndicator user={locked.lockedBy} />
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

function GetTableStructure({ data, reportType }) {
  const apiParam = "project-information";
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const [commentPopup, setCommentPopup] = useState({
    open: false,
    node: null,
    id: null,
    uniqueIdentifier: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [onCommentsSave] = useCommentSaveHook();
  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );

  const dataRef = useRef({
    comment: data.properties.find((node) => node.type === "commentNode"),
  });

  useEffect(() => {
    if (
      isCommentSaved.saved &&
      dataRef.current.uniqueIdentifier === commentPopup.uniqueIdentifier
    ) {
      dataRef.current.comment = {
        ...dataRef.current.comment,
        ident: isCommentSaved.commentsIdent,
      };
      isCommentSaved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setCommentPopup({
        open: false,
        node: null,
        id: null,
        uniqueIdentifier: null,
      });
      resetState();
    }
  }, [isCommentSaved]);

  const processSaveComments = (value) => {
    if (!!dataRef.current.comment) {
      let tempCommentsWrapper = {
        ...dataRef.current.comment,
        apiParam: apiParam,
        scrollParent: "scrollParent",
        reportType: reportType,
        section: "project-information",
      };
      const comments = [];
      comments.push({
        ...value,
        reportType: reportType,
        parentPath: data.path,
        order: commentPopup?.node?.order,
        assetIdent: openAsset.ident,
      });

      onCommentsSave({
        commentsApi: tempCommentsWrapper.apiParam,
        data: { ...tempCommentsWrapper, commentValues: comments },
        apiIdent: openAsset.ident,
      });
    }
  };

  return (
    <>
      <table className="custom-table w-100">
        <tbody>
          {data.properties
            ?.filter((item) => item.type === "property_node")
            .sort((a, b) => a.order - b.order)
            .map((node, index) => (
              <tr
                key={node.nodeIdent}
                style={{
                  ...getStylingForSelectedComment(
                    isCommentSelected()?.propertyIdent,
                    node.nodeIdent
                  ),
                }}
              >
                <td
                  style={{
                    wordWrap: "break-word",
                    width: "50%",
                    fontSize: "14px",
                  }}
                  id={`scroll_item_${node.nodeIdent}`}
                >
                  <Typography variant="body">
                    {node.labelTranslation
                      ?.find((e) => e.language === scope.projectLanguageCode)
                      .title?.toUpperCase()}
                  </Typography>
                </td>
                <td
                  style={{
                    wordWrap: "break-word",
                    width: "50%",
                    fontSize: "14px",
                  }}
                >
                  <Typography
                    variant="body"
                    dangerouslySetInnerHTML={{ __html: node.nodeValue }}
                  />
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexGrow: "1",
                    }}
                    className="g-comment"
                  >
                    <Tooltip title={getIntlTranslation("tooltip.add-comments")}>
                      <IconButton
                        id={`comment_${node.nodeIdent}`}
                        aria-describedby={`pop-comment-${node.nodeIdent}`}
                        onClick={(event) => {
                          setCommentPopup({
                            open: true,
                            node: node,
                            id: `pop-comment-${node.nodeIdent}`,
                            uniqueIdentifier: `${apiParam}-uniqueIdentifier`,
                          });
                          dataRef.current.uniqueIdentifier = `${apiParam}-uniqueIdentifier`;
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <CommentIcon
                          style={{ fontSize: 18, color: "#d08f8e" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {commentPopup.open && !!commentPopup.node && (
        <CommentsPopup
          id={commentPopup.id}
          anchorEl={anchorEl}
          onClose={() => {
            setCommentPopup({
              open: false,
              node: null,
              id: null,
            });
            setAnchorEl(null);
          }}
          node={commentPopup.node}
          onSave={(value) => {
            processSaveComments(value);
          }}
        />
      )}
    </>
  );
}
