import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import {
  b64toBlob,
  getIntlTranslation,
  getLabel,
} from "../../../../../utils/helper";
import CustomButton from "../../../../../components/buttons/CustomButton";


import {
  Divider,
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Backdrop,
} from "@mui/material";

import {
  getImages,
  saveSubAssetImage,
  deleteImage,
  updateImage,
} from "../../../api/monitoringSubAssetApi";
import { resetState } from "../photoDocumentationSlice";
import imageCompression from "browser-image-compression";
import { getImageFileFromUrl } from "../../../../../components/imageUploader/imageProcessing";
import { ImageProvider } from "../../../../component-bundle/image-gallery/ImageContext";
import SortablePhotoAlbum from "../../../../component-bundle/image-gallery/SortablePhotoAlbum";
import { usePhotoDocumentationContextHook } from "../PhotoDocumentationContext";

const useStyles = styled((theme) => ({
  appBar: {
    position: "relative",
    minHeight: "40px",
    backgroundColor: "#ffffff",
    margin: 5,
    borderRadius: "5px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1500,
    color: "#fff",
  },
  large: {
    width: 300,
    height: 200,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  flex: {
    display: "flex",
  },
}));

const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};

export const isTouchDevice = () => {
  if ("ontouchstart" in window) {
    return true;
  }
  return false;
};

const options = {
  maxSizeMB: 6,
  useWebWorker: true,
};

const onSave = (file, dispatch, node) => {
  const saveImages = async(file, node, projectIdent) => {
    if (file.status === "new" || file.status === "edited") {
      try {
        let originalFile = await getImageFileFromUrl(
          file.src,
          file.fileName
        );
        const compressedFile = await imageCompression(originalFile, options);
        return saveCompressedFile({
          ...file,
          imageFile: compressedFile,
          parentIdent: node.ident,
          primary: file.order === 1 ? true : false,
          dispatch: dispatch,
          projectIdent: projectIdent,
          nodeType: node.type,
          reportType: node.reportType
        })
      } catch (error) {
        console.log(error.message);
      }
    } else {
      saveCompressedFile({
        ...file,
        parentIdent: node.ident,
        primary: file.order === 1 ? true : false,
        dispatch: dispatch,
        projectIdent: projectIdent,
        nodeType: node.type,
        reportType: node.reportType
      });
    }
  };

  const saveCompressedFile = async(file) => {
    const data = new FormData();
    data.append("type", "ImageMetaDto");
    data.append("imageIdent", file.id);
    data.append("parentIdent", file.parentIdent);
    data.append("primary", file?.primary);
    data.append("deleted", false);
    data.append("caption", file.caption || " ");
    data.append("fileName", file.fileName);
    data.append("order", file.order);
    data.append("assetIdent", file.assetIdent);
    data.append("edited", file.isEdited);
    data.append("source", file?.source);

    if (file.status === "new" || file.status === "edited") {
      data.append("multipartFile", file.imageFile);
      if (file.isEdited) {
        let editedFile = await getImageFileFromUrl(
          file.editedFile,
          file.imageFile.name
        );
        const compressedEditedFile = await imageCompression(editedFile, options);
        data.append("editedMultipartFile", compressedEditedFile);
        data.append("markerState", JSON.stringify(file.markerState));
      }

      dispatch(
        saveSubAssetImage({
          formData: data,
          projectIdent: node.projectIdent,
          versionIdent: node.currentversion,
        })
      );
    }
    if (file.status === "saved") {
      dispatch(
        updateImage({
          formData: data,
          projectIdent: node.projectIdent,
          versionIdent: node.currentversion,
        })
      );
    }
  };
  saveImages(file, node, node.projectIdent);
}

function onDelete(file, dispatch, node) {
  const data = new FormData();
  data.append("type", "imageMetaDto");
  data.append("imageIdent", file.id);
  data.append("parentIdent", node.ident);
  data.append("deleted", true);
  data.append("fileName", file.fileName);
  data.append("assetIdent", file.assetIdent);
  dispatch(
    deleteImage({
      formData: data,
      projectIdent: node.projectIdent,
    })
  );
}

export default function ImageBox() {
  const dispatch = useDispatch();
  const { imageSubAssetNode, onFileUpdate} = usePhotoDocumentationContextHook();
  const [files, setFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const photodocState = useSelector(
    (state) => state.monitoringPhotoDocumentation
  );
  const { enqueueSnackbar } = useSnackbar();
  const openAsset = useSelector((state) => state.openAsset);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({                                                                                                                                                                                                                                                                                                                                                             
    accept: "image/jpg, image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      const tempFiles = [
        ...files,
        ...acceptedFiles.map((file) =>{
          let generatedId = uuidv4();
          return(
            Object.assign(file,{
              id: generatedId,
              key: generatedId,
              status: "new",
              caption: imageSubAssetNode.caption,
              order: null,
              parentIdent: imageSubAssetNode.ident,
              primary: "",
              fileName: file.name,
              assetIdent: openAsset.ident,
              src: URL.createObjectURL(file),
              width: 300,
              height: 200,
              isEdited:false,
              markerState:{},
              editedFile:{},
            }))
        }
        ),
      ]
      setFiles(tempFiles);
      onFileUpdate(tempFiles.map((m) => ({id: m.id, status: m.status,src: m.src,fileName: m.fileName})));
    },
  });
  useEffect(() => {
    if (!!imageSubAssetNode) {
      dispatch(
        getImages({
          projectIdent: imageSubAssetNode.projectIdent,
          subAssetIdent: imageSubAssetNode.ident,
          versionIdent: imageSubAssetNode.currentversion,
        })
      );
      setFiles([]);
    }
  }, [dispatch, imageSubAssetNode]);

  useEffect(() => {
    if (photodocState.getImages.type === "FETCHED") {
      if (
        !!photodocState.getImages.data &&
        photodocState.getImages.data.length > 0
      ) {
        let tempFile = [];
        let sortedArray = [...photodocState.getImages.data];
        sortedArray.sort((a, b) => {
          return a.order - b.order;
        });
        sortedArray.forEach((image) => {
          tempFile.push({
            id: image.imageIdent,
            status: "saved",
            caption: image.caption,
            order: image.order,
            fileName: image.fileName,
            parentIdent: image.parentIdent,
            checked: false,
            primary: image.primary,
            assetIdent: openAsset.ident,
            src: b64toBlob(image.file),
            key: image.imageIdent,
            width: 300,
            height: 200,
            markerState: !!image.markerState
                  ? JSON.parse(image.markerState)
                  : {},
            editedFile: !!image.editedFile ? b64toBlob(image.editedFile) : {},
            isEdited: image.edited,
          });
        });
        const combined = [...files, ...tempFile]
        setFiles(combined);
        onFileUpdate(combined.map((m) => ({id: m.id, status: m.status,src: m.src, fileName: m.fileName})));
        dispatch(resetState());
      }
    }
    if (photodocState.saveSubAssetImage.type === "SUCCESS") {
      photodocState.saveSubAssetImage.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      let savedFile = [];
      files.forEach((file) => {
        if (file.id === photodocState.saveSubAssetImage.data.imageIdent) {
          file.status = "saved";
        }
        savedFile.push(file);
      });
      setFiles(savedFile);
      onFileUpdate(savedFile.map((m) => ({id: m.id, status: m.status,src: m.src,fileName: m.fileName})));
      dispatch(resetState());
    }

    if (photodocState.updateImage.type === "SUCCESS") {
      photodocState.updateImage.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
    }

    if (photodocState.deleteImage.type === "SUCCESS") {
      photodocState.deleteImage.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      
    }
    return () => {
      setLoader(false);
    }
  }, [dispatch, enqueueSnackbar, files, onFileUpdate, openAsset.ident, photodocState]);
  

  const onCaptionChange = (key, value) => {
    let tempFile = [];
    files.forEach((element) => {
      if (key === element.key) {
        element.caption = value;
      }
      tempFile.push(element);
    });
    setFiles(tempFile);
  };

  const onRemove = (file) => {
    let nonDeletedfiles = [];
    files.forEach((f) => {
      if (f.key === file.key && f.fileName === file.fileName) {
        if (f.status !== "new") {
          onDelete(f,dispatch, imageSubAssetNode);
        }
      } else {
        nonDeletedfiles.push(f);
      }
    });
    setFiles(nonDeletedfiles);
  };

  const onImageSave = (file) => {
    const index = files.findIndex(
      (f) => f.key === file.key && f.fileName === file.fileName
    );
    files[index].order = index + 1;
    onSave(files[index], dispatch, imageSubAssetNode);
  };

  const handleSubmit = () => {
    files.forEach((file, index) => {
      file.order = index + 1;
      onSave(file, dispatch, imageSubAssetNode);
    });
    setLoader(true);

  };

  return (
    <>
      <Card style={{ height: "100%", width: "100%"}}>
        <CardContent
          className={"d-flex w-100 p-1"}
          style={{
            height: "100%",
            overflowY: "hidden",
            overflowX: "hidden",
          }}
        >
          <Box
            onDragOver={(e) => e.stopPropagation()}
            style={{
              width: "20%",
              backgroundColor: "#ffffff",
              height: "calc(100% - 10px)",
              margin: 5,
            }}
          >
            <div
              className={getClassName("image-drop", isDragActive)}
              {...getRootProps()}
              style={{
                height: "100%",
                border: "4px double #d08f8e",
                borderRadius: 4,
                padding: 4,
                cursor: "pointer",
              }}
            >
              <input
                className="dropzone-input"
                {...getInputProps()}
              />
              <div style={{ textAlign: "center" }} className="p-1">
                {isDragActive ? (
                  <Typography className="dropzone-content">
                    {getLabel("_drop-the-files")}
                  </Typography>
                ) : (
                  <>
                    <Typography className="dropzone-content">
                      {getLabel("_Drag-Images")}
                    </Typography>
                    <em>{getLabel("_Image-Allowed")}</em>
                  </>
                )}
              </div>
            </div>
            <Divider />
          </Box>
          <Card
        className={"p-1"}
          style={{
            width: "80%",
            height: "calc(100% - 10px)",
            marginTop: 5
          }}
        >
            <CardContent
            className={"d-flex flex-column custom-scrollBar w-100 p-1"}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100% - 50px)",
            }}
          >
             {files?.filter((f) => !!f.id).length > 0 && (
              <ImageProvider
                onRemove={(file) => onRemove(file)}
                onImageSave={(file) => onImageSave(file)}
                onCaptionChange={(key, value) =>onCaptionChange(key, value)}
                onImageEdit={(photo, editedImage, markerState) => {
                  let temp = files.map((file) => {
                    if (file.key === photo.key && photo.fileName === file.fileName) {
                      if(!!markerState) {
                        file.markerState = markerState;
                        file.status = file.status === "saved" ? "edited" : file.status;
                        file.isEdited = true;
                        file.editedFile = editedImage;
                      }
                      
                    }
                    return file;
                  });
                  setFiles(temp);
                }}
                isDragable={true}
                onDragEnd={(photos) => setFiles(photos)}
              >
                <SortablePhotoAlbum photoSet={files} />
              </ImageProvider>
            )}
          </CardContent>
            {files.length > 0 && (
              <CardActions
              style={{
             marginTop: 'auto',
             height:50
              }}
            >
            <CustomButton
              id="save-all-images"
              tooltip={{
                title: getIntlTranslation("tooltip.Save-All"),
              }}
              buttonText={"_Save-All-Images"}
              handleClick={handleSubmit}
              style={{ width: "98%", height: 40 }}
              size="small"
              variant="contained"
              className="g-btn-primary"
              disabled={files.length <= 0}
            />
            </CardActions>
        )}
        </Card>
        </CardContent>
        </Card>
      {loader && (
        <Backdrop className={useStyles.backdrop} open={loader}>
          <div className="preloader">
            <div></div>
          </div>
        </Backdrop>
      )}
    </>
  );
}
