import { Box } from '@mui/material';
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux';
import useAssetListHook from '../../../../component-bundle/hooks/useAssetListHook';
import NoAssets from '../../../../component-bundle/NoAssets';
import TempVersionAlert from '../../../version-comparison/TempVersionAlert';
import { ProjectInformationContextProvider } from '../context/ProjectInformationContext';
import HeaderContainer from "./HeaderContainer"
import RenderPropertyContainer from './RenderPropertyContainer';

const ProjectInformationWrapper = ({moduleApi}) => {
  const projectMonitoring = useSelector((state) => state.projectMonitoring);
  const tempVersion = projectMonitoring.temp;
  const openAsset = useSelector((state) => state.openAsset);
  const { assetList } = useAssetListHook();

  const getHeight = useCallback(() => {
    if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
      return { height: "calc(100% - 100px)" };
    } else {
      return { height: "calc(100% - 50px)" };
    }
  }, [openAsset.ident, tempVersion.assetIdent, tempVersion.isTemp]);
  return (
    <>
      <Box className="w-100 h-100 d-flex flex-column align-items-baseline">
          {tempVersion.isTemp && <TempVersionAlert />}
          <ProjectInformationContextProvider moduleApi={moduleApi}>
            <Box className="w-100" style={{ height: 50 }}>
              <HeaderContainer />
            </Box>
            {assetList.assets.length <= 0 ? (
              <NoAssets />
            ) : (
              <Box className="w-100 d-flex flex-row" style={getHeight()}>
                <RenderPropertyContainer/>
              </Box>
            )}
            </ProjectInformationContextProvider>
      </Box>
    </>
  )
}

ProjectInformationWrapper.propTypes = {}

export default ProjectInformationWrapper