import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { List } from "@mui/material";
import { getProjectUsers } from "../../../api/userManagementApi";
import { useSelector, useDispatch } from "react-redux";
import { addMentions,resetState } from "../users/usersSlice";
import RecursiveCommentList from "./RecursiveCommentList";
import useCommentSaveHook from "../hooks/useCommentSaveHook";
import {setSelectedCommentNode} from "./commentSlice";
import {getAllEmailRegex} from "../../../utils/helper"

const newComment = "new-comment";
const updateComments = "update-comments";
const deleteComments = "delete-comments";

function CommentsContainer(props) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.users);
  const commentsState = useSelector((state) => state.comments);
  const [onCommentsSave] = useCommentSaveHook();
  const [inputValue, setInputValue] = useState({
    val: "",
    ident: "",
  });
  const scope = useSelector((state) => state.container.scope);
  const [commentList, setCommentList] = useState([]);
  const [users, setUsers] = useState("");
  useEffect(() => {
    dispatch(
      getProjectUsers({
        ident: scope.projectIdent,
      })
    );
  }, [dispatch, scope.projectIdent]);

  useEffect(() => {
    if (
      userState.projectUsers.type === "FETCHED" &&
      !!userState.projectUsers.data
    ) {
      let temp = userState.projectUsers.data.map((user) => {
        return {
          title: `${user.firstName} ${user.lastName}`,
          ...user,
          id: user.userId,
          display: `${user.firstName} ${user.lastName}`,
        };
      });
      setUsers(temp);
      dispatch(addMentions(temp));
      dispatch(resetState());
    }
  }, [dispatch, userState.projectUsers]);

  useEffect(() => {
    if (commentsState.commentList.length > 0) {
      let newArray = [...commentsState.commentList];
      const temp = newArray.sort((commentA,commentB) =>{
        return new Date(commentA.creationDate) - new Date(commentB.creationDate);
      })
      setCommentList(temp);
    }else{
      setCommentList([]);
    }
  }, [commentsState.commentList]);

  const handleSave = (data) => {
    onCommentsSave({
      commentsApi: data.apiParam,
      ident: data.apiIdent,
      data: data,
    });
  };

  const getAllMentionUser = (value) => {
    let mentionedUsers = [];
    const matches = getAllEmailRegex(value);
    for (let email of matches) {
      let found = userState.mentions.find((f) => f.emailAddress === email[0]);
      if(!mentionedUsers.includes(found.userId)) {
      mentionedUsers.push(found.userId)
      }
    }
    return mentionedUsers;
  }

  const handleDelete = (parent, ident) => {
    let arrayData = JSON.parse(JSON.stringify(parent?.commentValues));
    const updateValue = updateObjects(arrayData, ident, "","delete-comments");
    const newData = {
      ...parent,
      type: "commentNode",
      commentValues: updateValue,
    };
    handleSave(newData);
  };

  const updateObjects = (parent, ident, newValue, action) => {
    // Base case: If the array is empty, return an empty array
    let tempParent = [...parent];
    if (tempParent.length === 0) {
      return [];
    }
    // Iterate over the array
    let temp = tempParent.map((obj) => {
      let tempObj = { ...obj, replies: [...obj.replies] };
      if (tempObj.ident === ident) {
        if (newComment === action) {
          // Found a match, add comments
          tempObj.replies.push(newValue);
        } else if (updateComments === action) {
          // Found a match, update the object
          let mentioned = getAllMentionUser(newValue);
          tempObj.value = newValue;
          tempObj.modified = true;
          tempObj.mentionedInComments=mentioned;
        } else if (deleteComments === action) {
           // Found a match, mark the object deleted
          tempObj.deleted = true;
        }
      } else if (tempObj.replies && tempObj.replies.length > 0) {
        tempObj.replies = updateObjects(
          tempObj.replies,
          ident,
          newValue,
          action
        );
      }
      return tempObj;
    });
    return temp;
  };

  const handleEditSave = (parent, data, newValue) => {
    
    let id = data.ident;
    const updateValue = updateObjects(
      parent?.commentValues,
      id,
      newValue,
      "update-comments"
    );
    const updatedData = {
      ...parent,
      type: "commentNode",
      commentValues: updateValue,
    };
    handleSave(updatedData);
  };

  const handleReply = (parent, item, text) => {
    let mentioned = getAllMentionUser(text);
    let newComment = {
      type: "commentValue",
      value: text,
      propertyIdent: item.propertyIdent,
      propertyKey: item.propertyKey,
      replies: [],
      deleted: false,
      new: true,
      modified: false,
      ident: "",
      createdBy: "",
      creationDate: "",
      updatedAt: "",
      updatedBy: "",
      reportType: parent.reportType,
      mentionedInComments:mentioned
    };
    const ident = item.ident;
    let updateValue = updateObjects(
      parent?.commentValues,
      ident,
      newComment,
      "new-comment"
    );
    const updatedData = {
      ...parent,
      type: "commentNode",
      commentValues: updateValue,
    };
    handleSave(updatedData);
  };

  return (
    <div>
      {commentList?.map((item, i) => {
        return item?.commentValues
        ?.filter((f) => f.reportType === item.reportType)
        ?.map((comment,index) => (
          <List
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              flex: 1,
              flexGrow: 1,
            }}
            key={`item_${index}`}
          >
            <RecursiveCommentList
              key={comment.propertyIdent}
              scrollId={`${item.scrollParent}_${comment.propertyIdent}`}
              data={comment}
              parentCommentValue={item}
              setInputValue={setInputValue}
              inputValue={inputValue}
              users={users}
              handleDelete={handleDelete}
              handleEditSave={handleEditSave}
              handleReply={handleReply}
              scrollTo={(ident) => {
                props.scrollTo(ident,props.scrollContainer)
                dispatch(setSelectedCommentNode({
                  anchorIdent: ident,
                  apiParam: item.apiParam
                }))
              }
              }
            />
          </List>
        ));
      })}
    </div>
  );
}

CommentsContainer.propTypes = {
  scrollContainer: PropTypes.string,
  scrollTo: PropTypes.func,
};

export default CommentsContainer;
