
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { response } from "../../../../../utils/defaultValues";
import { onSelectReportVersion, getFetchCompareSuccess } from "../projectProgressSlice";
import { useProjectProgressContext } from "./ProjectProgressContext";
import { usePropertiesData } from "./PropertiesDataContext";

const ComparisonModeContext = createContext();

export const useComparisonMode = () => useContext(ComparisonModeContext);

export const ComparisonModeProvider = ({ children }) => {
  const scope = useSelector((state) => state.container.scope);
  const { normalizeProperties } = usePropertiesData();
  const {
    onFetchCompareProperties,
  } = useProjectProgressContext();
  const state = useSelector((state) => state.projectProgress);
  const [versionSelected, setVersionSelected] = useState(null);
  const [compareProperties, setCompareProperties] = useState([]);
  const [structuredCompareProperties, setStructuredCompareProperties] = useState({})
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      state.isCompareMode &&
      state.versions?.length > 0
      && !!state.currentVersion.ident
      && !state.selectedComparingVersion
      
    ) {
      let temp =  state.versions
        .filter((f) => f.ident !== state.currentVersion.ident)
        .sort((a, b) => a.reportOrder - b.reportOrder);
      dispatch(onSelectReportVersion(temp[0]));
      setLoading(true);
    }
  }, [
    state.isCompareMode,
    state.currentVersion,
    dispatch,
    state.selectedComparingVersion,
    state.versions
  ]);

  useEffect(() => {
    if (!!state.selectedComparingVersion && !!state.selectedComparingVersion.ident) {
      setVersionSelected(state.selectedComparingVersion);
      onFetchCompareProperties(state.selectedComparingVersion.ident);
    }
  }, [onFetchCompareProperties, state.selectedComparingVersion]);

  useEffect(() => {
    if (
      state.fetchCompare.type === "FETCHED" &&
      state.fetchCompare.data.length > 0
    ) {
      setCompareProperties(state.fetchCompare.data);
      setStructuredCompareProperties(normalizeProperties(
        state.fetchCompare.data,
        scope.projectLanguageCode
      ));
      dispatch(getFetchCompareSuccess({ ...response, guruDtoList: [] }));
      setLoading(false);
    }
  }, [dispatch, normalizeProperties, scope.projectLanguageCode, state.fetchCompare.data, state.fetchCompare.type]);


  return (
    <ComparisonModeContext.Provider
      value={{
        versionSelected,
        loading,
        compareProperties,
        structuredCompareProperties,
        setStructuredCompareProperties
      }}
    >
      {children}
    </ComparisonModeContext.Provider>
  );
};
