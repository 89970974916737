import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { response } from "../../../../utils/defaultValues";
import useReorder from "../../../component-bundle/hooks/useReorderHook";
import { getFetchSuccess } from "../slice/VersionDataSlice";
import { normalizeProperties } from "../versionUtil";
import { ComparisonModeProvider } from "./ComparisonModeContext";
import { useDataAggregatorContextHook } from "./DataAggregatorContext";
import { SelectedPropertyProvider } from "./SelectedPropertyContext";
import { UpdateStateProvider } from "./UpdateStateContext";

const PropertiesDataContext = createContext();

export const usePropertiesData = () => useContext(PropertiesDataContext);

export const PropertiesDataProvider = ({ children, moduleApi }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.versionData);
  const [reorderResponse, onReorder, onResetResponse] = useReorder();
  const { subAssetList } = useDataAggregatorContextHook();
  const [properties, setProperties] = useState(null);

  const [structuredProperties, setStructuredProperties] = useState({
    nodes: {},
  });
  useEffect(() => {
    if (state.fetch.type === "FETCHED" && state.fetch.data.length > 0) {
      setStructuredProperties(
        normalizeProperties(
          state.fetch.data,
          subAssetList,
          scope.projectLanguageCode
        )
      );
      setProperties(state.fetch.data);
      dispatch(getFetchSuccess({ ...response }));
    }
  }, [state.fetch, scope.projectLanguageCode, dispatch, subAssetList]);

  useEffect(() => {
    if (reorderResponse.type === "SAVED") {
      setProperties(reorderResponse.data);
      setStructuredProperties(
        normalizeProperties(
          reorderResponse.data,
          subAssetList,
          scope.projectLanguageCode
        )
      );
      onResetResponse();
    }
  }, [
    onResetResponse,
    reorderResponse,
    scope.projectLanguageCode,
    subAssetList,
  ]);

  const handleReorder = (items) => {
    const reorder = items.map((item, index) => ({
      ...item.original,
      nodeOrder: index + 1,
    }));
    onReorder({
      reorderApi: moduleApi,
      data: reorder,
    });
  };

  return (
    <PropertiesDataContext.Provider
      value={{
        properties,
        structuredProperties,
        setStructuredProperties,
        handleReorder,
      }}
    >
      <SelectedPropertyProvider>
        <UpdateStateProvider>
          <ComparisonModeProvider>{children}</ComparisonModeProvider>
        </UpdateStateProvider>
      </SelectedPropertyProvider>
    </PropertiesDataContext.Provider>
  );
};
