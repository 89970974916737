import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { useDrag } from "react-dnd";

const Style = {
  height: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  fontSize: "12px",
  backgroundColor: "white",
  padding: "none",
  marginRight: 1,
  marginTop: 1,
  minWidth: "100px",
  maxWidth: "250px",
};

function IconContainer({ id, name, option, data, setDragging, current }) {
  const [structureEditable, setStructureEditable] = useState(null);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "card",
    item: { option: option },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    setDragging(isDragging);
    return () => {};
  }, [isDragging]);

  useEffect(() => {
    if (!!current) {
      if (current?.structureEditable === false) {
        setStructureEditable(false);
      } else {
        setStructureEditable(true);
      }
    }
  }, [current]);
  return structureEditable ? (
    <Card
      sx={Style}
      id={id}
      raised={true}
      ref={drag}
      style={{
        cursor: "pointer",
      }}
    >
      <IconButton>
        <data.icon />
      </IconButton>
      <Typography
        variant="caption"
        color="text.secondary"
        style={{ textAlign: "center", width: "90%" }}
      >
        {name}
      </Typography>
    </Card>
  ) : (
    <Card
      sx={Style}
      id={id}
      raised={true}
      style={{
        cursor: "pointer",
      }}
    >
      <IconButton>
        <data.icon />
      </IconButton>
      <Typography
        variant="caption"
        color="text.secondary"
        style={{ textAlign: "center", width: "90%" }}
      >
        {name}
      </Typography>
    </Card>
  );
}

export default IconContainer;
