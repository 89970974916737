import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  error:{
    type: "",
   messages: [],
   data: {},
 },
};

const dataAnalysisSlice = createSlice({
  name: "dataAnalysis",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }

    }),
    resetState: (state) => ({
      ...initialState,
      tab: state.tab,
    }),
  },
});

export const { resetState,getFailure } = dataAnalysisSlice.actions;

// A selector
export const dataAnalysisSelector = (state) => state.dataAnalysis;

// The reducer
export default dataAnalysisSlice.reducer;