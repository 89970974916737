import React from 'react'
import EsgModuleDataWrapper from "./structure/EsgModuleDataWrapper"
import Grid from '@mui/material/Grid';

const EsgModuleHome = () => {
  return (
    <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
  <Grid item xs={12}>
  <EsgModuleDataWrapper/>
  </Grid>
  </Grid>
  );
};
export default EsgModuleHome;