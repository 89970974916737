/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IntlMessages from "../../../../../utils/IntlMessages";
import {
  getLabel,
  getOverlineText,
  getIntlTranslation,
  createAddress,
} from "../../../../../utils/helper";
import {
  Box,
  Grid,
  Autocomplete,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import PlacesAutocomplete from "../../../../../components/locations/PlacesAutocomplete";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, <IntlMessages id="required_field" />)
    .required(<IntlMessages id="required_field" />),
  address: Yup.string().required(<IntlMessages id="required_field" />),
  city: Yup.string().required(<IntlMessages id="required_field" />),
  postalCode: Yup.string().required(<IntlMessages id="required_field" />),
  country: Yup.string().required(<IntlMessages id="required_field" />),
  reportVersion: Yup.string().required(<IntlMessages id="required_field" />),
  subAssets: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .min(1, <IntlMessages id="required_field" />)
        .required(<IntlMessages id="required_field" />),
      addressLabel: Yup.string()
        .min(1, <IntlMessages id="required_field" />)
        .required(<IntlMessages id="required_field" />),
      typeOfUse: Yup.object().shape({
        title: Yup.string()
          .min(1, <IntlMessages id="required_field" />)
          .required(<IntlMessages id="required_field" />),
        value: Yup.string()
          .nullable()
          .required(<IntlMessages id="required_field" />)
          .required(<IntlMessages id="required_field" />),
      }),
    })
  ),
});

const checkAssetAddress = (data) => {
  return data.postalCode && !!data.address && !!data.city && !!data.country;
};

const AssetCreateForm = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [fullAddress, setFullAddress] = useState("");
  const [buildingProfileOptions, setBuildingProfileOption] = useState([]);

  useEffect(() => {
    if (props.buildingType?.length > 0) {
      setBuildingProfileOption(props.buildingType);
    }
  }, [props.buildingType]);

  const createSubAssets = (formProps) => {
    let rows = [];
    formProps.form.values.subAssets.forEach((m, i) => {
      rows.push(
        <>
          <Box
            className="d-flex flex-row align-items-center justify-content-start w-100"
            style={{ marginLeft: 15, marginTop: 10 }}
          >
            <div style={{ width: "20%" }}>
              {getOverlineText("_building")} {i + 1}
            </div>
            <div className="d-flex flex-column" style={{ width: "80%" }}>
              <div className="d-flex flex-row" style={{ width: "100%" }}>
                <div style={{ marginTop: 5, width: "50%" }}>
                  <Field
                    name={`subAssets[${i}].name`}
                    id={`subasset_name_${i + 1}`}
                    disabled={props.readOnly}
                    label={getLabel("Name")}
                    as={TextField}
                    multiline
                    size="small"
                    dense="true"
                    className="grid-fields"
                    error={
                      getIn(formProps.form.touched, `subAssets[${i}].name`) &&
                      getIn(formProps.form.errors, `subAssets[${i}].name`)
                    }
                    helperText={
                      getIn(formProps.form.touched, `subAssets[${i}].name`) &&
                      getIn(formProps.form.errors, `subAssets[${i}].name`)
                    }
                  />
                </div>
                <div style={{ marginTop: 5, width: "50%" }}>
                  <Autocomplete
                    freeSolo
                    id={`subAssets[${i}].building_type`}
                    size="small"
                    value={
                      !!formProps.form.values.subAssets[i].typeOfUse
                        ? formProps.form.values.subAssets[i].typeOfUse
                        : ""
                    }
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    selectOnFocus
                    clearOnBlur
                    options={buildingProfileOptions?.filter(
                      (f) => f.language === scope.projectLanguageCode
                    )}
                    getOptionSelected={(option, value) =>
                      value.value === option.value
                    }
                    inputValue={
                      formProps.form.values.subAssets[i].typeOfUse.title
                    }
                    onInputChange={(event, newInputValue) => {
                      if (typeof newInputValue === "string") {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          {
                            title: newInputValue,
                            value: newInputValue,
                          }
                        );
                      }
                    }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          {
                            title: newValue,
                            value: newValue,
                          }
                        );
                      } else if (newValue !== null) {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          newValue
                        );
                      } else {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          {
                            title: "",
                            value: null,
                          }
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label={getLabel("_building type")}
                          name={`subAssets[${i}].typeOfUse`}
                          className="grid-fields"
                          size="small"
                          dense="true"
                          error={
                            getIn(
                              formProps.form.touched,
                              `subAssets[${i}].typeOfUse.value`
                            ) &&
                            getIn(
                              formProps.form.errors,
                              `subAssets[${i}].typeOfUse.value`
                            )
                          }
                          helperText={
                            getIn(
                              formProps.form.touched,
                              `subAssets[${i}].typeOfUse.value`
                            ) &&
                            getIn(
                              formProps.form.errors,
                              `subAssets[${i}].typeOfUse.value`
                            )
                          }
                        />
                      </>
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.title, inputValue);
                      const parts = parse(option.title, matches);

                      return (
                        <>
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    color: part.highlight ? "red" : "black",
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div style={{ marginTop: 5, width: "50%" }}>
                  {!props.readOnly && (
                    <>
                      <div style={{ marginLeft: 20, width: "89%" }}>
                        <PlacesAutocomplete
                          setGetAddress={(address) => {
                            formProps.form.setFieldValue(
                              `subAssets[${i}].addressLabel`,
                              address.label
                            );
                          }}
                          fullAddress={fullAddress}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div style={{ marginTop: 5, width: "50%" }}>
                  <Field
                    disabled={props.readOnly}
                    name={`subAssets[${i}].addressLabel`}
                    label={getLabel("_Address")}
                    as={TextField}
                    size="small"
                    className="grid-fields"
                    dense={"true"}
                    multiline
                  />
                  {checkAssetAddress(formProps.form.values) && (
                    <FormControlLabel
                      className="grid-fields"
                      label={
                        <div className={"d-flex"}>
                          {getLabel("_same-as-asset")}
                        </div>
                      }
                      labelPlacement="start"
                      name="isGeneral"
                      control={
                        <Checkbox
                          checked={
                            formProps.form.values.subAssets[i]
                              .addressSameAsAsset
                          }
                          onChange={(e) => {
                            formProps.form.setFieldValue(
                              `subAssets[${i}].addressSameAsAsset`,
                              e.target.checked
                            );

                            formProps.form.setFieldValue(
                              `subAssets[${i}].addressLabel`,
                              createAddress({
                                street: formProps.form.values.address,
                                city: formProps.form.values.city,
                                postalCode: formProps.form.values.postalCode,
                                state: formProps.form.values.state,
                                country: formProps.form.values.country,
                              })
                            );
                          }}
                        />
                      }
                    />
                  )}
                </div> 
              </div>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: "5px 5px", backgroundColor: "#254a9a" }}
            />
            {!props.editDialog && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  width: "10%",
                  height: "100%",
                }}
              >
                <CustomIconButton
                  id={`add_row_button_${i}`}
                  icon={<AddIcon style={{ color: "#d08f8e", fontSize: 25 }} />}
                  permission={null}
                  handleClick={(e) => {
                    formProps.push({
                      id: Date.now(),
                      name: "",
                      typeOfUse: { title: "", value: null },
                      addressLabel: "",
                      isGeneral: false,
                      order: i + 1 + 1,
                      addressSameAsAsset: false,
                    });
                  }}
                  tooltip={{
                    title: getIntlTranslation("tooltip.add-row"),
                  }}
                  style={{
                    borderRadius: 0,
                  }}
                  aria-describedby={`add_row_button_${i}`}
                />
                {formProps.form.values.subAssets.length > 1 && (
                  <CustomIconButton
                    id={`delete_row_button_${i}`}
                    icon={
                      <DeleteForeverIcon
                        style={{ color: "#f50057", fontSize: 25 }}
                      />
                    }
                    permission={null}
                    handleClick={(e) => {
                      formProps.remove(i);
                    }}
                    tooltip={{
                      title: getIntlTranslation("tooltip.delete"),
                    }}
                    style={{
                      borderRadius: 0,
                    }}
                    aria-describedby={`delete_row_button_${i}`}
                  />
                )}
              </div>
            )}
          </Box>

          {i < formProps.form.values.subAssets.length - 1 && (
            <Divider component="div" style={{ padding: 5, width: "98%" }} />
          )}
        </>
      );
    });
    return rows;
  };

  return (
    <Box
      style={{ overflowY: "auto", marginTop: 5 }}
      className={"custom-scrollBar"}
    >
      <Card>
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={props.intialFormData}
            validationSchema={validationSchema}
            onSubmit={(values) => props.handleSave(values)}
            innerRef={props.formRef}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              isSubmitting,
            }) => (
              <>
                <Form>
                  <Grid container justify="center" spacing={1}>
                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      {getOverlineText("_Asset Information")}
                      <Field
                        name="name"
                        disabled={props.readOnly}
                        label={getLabel("Name")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      {getOverlineText("report_version")}
                      <Field
                        disabled={props.editDialog}
                        name="reportVersion"
                        label={getLabel("report_version")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.reportVersion && errors.reportVersion}
                        helperText={
                          touched.reportVersion && errors.reportVersion
                        }
                      />
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
                      {getOverlineText("Address Detail")}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {!props.readOnly && (
                        <>
                          <div style={{ marginLeft: 20, width: "95%" }}>
                            <PlacesAutocomplete
                              setGetAddress={(address) => {
                                setFieldValue("addressLabel", address.label);
                                setFieldValue("address", address.street);
                                setFieldValue("city", address.city);
                                setFieldValue("postalCode", address.postalCode);
                                setFieldValue("state", address.state);
                                setFieldValue("country", address.country);
                              }}
                              fullAddress={fullAddress}
                            />
                          </div>
                        </>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="address"
                        label={getLabel("_Address")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        multiline
                        className="grid-fields"
                        error={touched.address && errors.address}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="city"
                        label={getLabel("_City")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.city && errors.city}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="postalCode"
                        label={getLabel("_zip-code")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.postalCode && errors.postalCode}
                        helperText={touched.postalCode && errors.postalCode}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="state"
                        label={getLabel("_State")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.state && errors.state}
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnlys}
                        name="country"
                        label={getLabel("_Country")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.country && errors.country}
                        helperText={touched.country && errors.country}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {getOverlineText("_Sub-Asset Information")}
                    </Grid>
                    <FieldArray name="subAssets">
                      {(fieldArrayProps) => {
                        return createSubAssets(fieldArrayProps);
                      }}
                    </FieldArray>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

AssetCreateForm.propTypes = {
  intialFormData: PropTypes.object,
  handleSave: PropTypes.func,
  formRef: PropTypes.object,
  buildingType: PropTypes.array,
  editDialog: PropTypes.bool,
};

export default AssetCreateForm;
