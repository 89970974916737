import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import GeneralPhotos from '../../../../../components/imageUploader/GeneralPhotos';
import DefectImages from '../../../../../components/imageUploader/DefectImages';
import PhotoDocumentationTabs from './PhotoDocumentationTabs';
import SubAssetSwitcher from '../../../../component-bundle/assetSwitcher/SubAssetSwitcher';
import NoAssets from '../../../../component-bundle/NoAssets';

const PhotoDocumentationContainer = () => {
  const state = useSelector((state) => state.photoDocumentation);
  const openAsset = useSelector((state) => state.openAsset);
  const scope = useSelector((state) => state.container.scope);
  const [node, setNode] = useState(null);
  const [imageCategory, setImageCategory] = useState(null);

  useEffect(() => {
    if (!!openAsset && !!openAsset.subAssetIdent) {
      setNode({
        type: 'sub-asset',
        ident: openAsset.subAssetIdent,
        projectIdent: scope.projectIdent,
        caption: '',
      });
    }
  }, [openAsset, scope.projectIdent]);

  useEffect(() => {
    if (!!state.tab && state.tab?.key) {
      setImageCategory(state.tab);
    }
  }, [state.tab]);

  const loadImages = (val) => {
    if (!!imageCategory) {
      if ('general' === imageCategory.key) {
        return <GeneralPhotos isCloseAllowed={false} node={node} />;
      }
      if ('defect' === imageCategory.key) {
        return <DefectImages node={node} />;
      }
    }
  };

  return (
    <>
      <Box
      className="w-100 d-flex"
        style={{
          backgroundColor: '#ffffff',
          height: 50,
        }}
      >
        <>
          <div style={{ width: '20%', margin: 5, height: 40 }}>
            <SubAssetSwitcher />
          </div>

          {!!openAsset && !!openAsset.subAssetIdent &&
            <Box style={{ width: '80%', height: 40, margin: 5 }}>
              <PhotoDocumentationTabs />
            </Box>
          }
        </>
      </Box>
      {!!openAsset && !!openAsset.subAssetIdent ? (<Box
        className="w-100"
        style={{
          margin: 2,
          height: 'calc(100% - 50px)',
        }}
      >
        {loadImages()}
      </Box>): 
      (<Box className="w-100 m-1" style={{height: 'calc(100% - 50px)',}}>
            <NoAssets/>
      </Box>)
}
</>
  );
};

export default PhotoDocumentationContainer;
