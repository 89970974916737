import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { isAuthTokenValid } from "./authTokenService";
import { decrypt, isEmpty } from "../utils/helper";
import { AuthProvider } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  const currentUser = useSelector((state) => state.authentication);
  let location = useLocation();
  
  

  const handleAuthentication = () => {
    const decrypted = decrypt(currentUser.user);
    if (!isEmpty(decrypted)) {
      const user = JSON.parse(decrypted);
 
      if (!user.data?.token) {
        console.log("onAutoLogout", "access_token expired");
        return false;
      } else if (isAuthTokenValid(user.data?.token.accessToken) && user.loggedIn) {
        return true;
      } else {
        console.log("onAutoLogout", "access_token expired");
        return false;
      }
    } else{
      return false;
    }
  }

  if (handleAuthentication()) {
    return (
      <AuthProvider>
        {children}
      </AuthProvider>
    )
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
          state: { from: location },
        }}
        replace
      />
    );
  }
};

export default ProtectedRoute;
