import axios from "../../../api/customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {
    getFailure, getFetchCompareSuccess, getFetchSuccess, getSaveSuccess,
} from "../feature-bundle/project-description/projectDescriptionSlice";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";

export const saveProjectDescription = ({value,request}) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `${request.api}/${request.moduleApi}/${request.projectIdent}/create/${request.assetIdent}/${request.versionIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: value,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSaveSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const fetchProjectDescription = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `${request.api}/${request.moduleApi}/fetch-data/${request.projectIdent}/${request.assetIdent}/${request.versionIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getFetchSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const fetchComparisonProjectDescription = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `${request.api}/${request.moduleApi}/fetch-data/${request.projectIdent}/${request.assetIdent}/${request.versionIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getFetchCompareSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const updateProjectDescription = ({value,request}) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "put",
        url: `${request.api}/${request.moduleApi}/${request.projectIdent}/update/${request.ident}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: value,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSaveSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

 