import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import UserManagementTable from './structure/UserManagementTable'


const  UserManagementHome = () => {
  return (
    <UserManagementTable/>
  )
}

UserManagementHome.propTypes = {}

export default UserManagementHome
