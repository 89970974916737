import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import clsx from "clsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import { getVariantText, getIntlTranslation } from "../../../../../utils/helper";
import EditPropertyLabel from "../../../version-comparison/EditPropertyLabel";
import { useCategoriesData } from "../context/CategoriesDataContext";
import { useSelectedProperty } from "../context/SelectedPropertyContext";
import { useKeyFindingsContext } from "../context/KeyFindingsContext";
import { useKeyFindingsPropertyContext } from "../context/KeyFindingsPropertyContext";
import KeyFindingsPropertyEditDialog from "./KeyFindingsPropertyEditDialog";
import CustomButton from "../../../../../components/buttons/CustomButton";
import RenderPropertyDataCard from "./RenderPropertyDataCard";

export const getBorderColor = (p) => {
  if (p) {
    return {
      borderLeft: "4px solid rgb(211, 0, 56)",
      backgroundColor: "rgba(255, 42, 81, 0.19)",
    };
  } else {
    return {};
  }
};

const DraggableListItem = ({ id, node, activeIndex, overlay = false,onEdit }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
    index,
  } = useSortable({ id });
  const {selectedCategory} = useSelectedProperty();
  const { onDeleteProperty, onEditProperty,onDeleteRecord } = useKeyFindingsPropertyContext();
  const state = useSelector((state) => state.keyFindings);
  const [openPopOver, setOpenPopOver] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  };

  const getInserPosition =
    activeIndex !== undefined && over?.id === node.nodeIdent && !isDragging
      ? index > activeIndex
        ? "after"
        : "before"
      : undefined;

  return (
    <ListItem
      key={id}
      divider
      ref={setNodeRef}
      className={clsx("drag-item p-1 d-flex flex-column", {
        overlay: overlay,
        active: isDragging,
        insertBefore: getInserPosition === "before",
        insertAfter: getInserPosition === "after",
      })}
      style={getBorderColor(!!state.updatedRows[node.nodeIdent])}
    >
      <Box className={`d-flex w-100 align-items-center justify-content-around`}>
        <div style={{ ...style }} {...attributes} {...listeners}>
          <DragIndicatorIcon style={{ color: "#d08f8e", fontSize: 20 }} />
        </div>
        <ListItemText
          className="w-75"
          primary={
            <div
              className="d-flex flex-wrap align-items-center justify-content-start p-1"
              style={{
                width: "100%",
                cursor: "pointer",
              }}
            >
              {selectedCategory?.nodeIdent === node.nodeIdent && (
                <div className="ml-1 mr-1">
                  <DoubleArrowIcon style={{ color: "#5f9ea0", fontSize: 20 }} />
                </div>
              )}
              {getVariantText(node.nodeTitle, "caption", {
                fontWeight: "bold",
                color: "#254a9a",
                marginLeft: 2,
                textTransform: "uppercase",
              })}
              {node.custom && (
                <>
                  <CustomIconButton
                    id={`menu_button_${node.nodeName}`}
                    icon={
                      <ArrowDropDownIcon
                        style={{ color: "#d08f8e", fontSize: 20 }}
                      />
                    }
                    permission={null}
                    handleClick={(e) => {
                      e.stopPropagation();
                      setOpenPopOver(node.nodeIdent);
                      setAnchorEl(e.currentTarget);
                    }}
                    tooltip={{
                      title: getIntlTranslation("tooltip.edit"),
                    }}
                    style={{
                      borderRadius: 0,
                    }}
                    aria-describedby={`prop_menu_${node.nodeName}`}
                  />
                  <Popover
                    id={`prop_menu_${node.nodeName}`}
                    open={openPopOver === node.nodeIdent}
                    anchorEl={anchorEl}
                    onClose={(e) => {
                      setAnchorEl(null);
                      setOpenPopOver(null);
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <EditPropertyLabel
                      property={node.original}
                      onClose={(e) => {
                        setAnchorEl(null);
                        setOpenPopOver(null);
                      }}
                      handleSave={(val) => onEditProperty([{ ...val, updated: true}])}
                      onDelete={(val) => {
                        onDeleteProperty(val);
                      }}
                    />
                  </Popover>
                </>
              )}
            </div>
          }
        />
        {Object.keys(node.mapProperties).length > 0 &&
        <CustomButton
        id={`edit-${node.nodeIdent}`}
        buttonText={"_edit"}
        style={{
          color: "#254a9a",
          height: 30,
        }}
        className="btn g-btn-secondary mx-1 g-btn-elevated"
        tooltip={{
          title: getIntlTranslation("tooltip.edit"),
        }}
        handleClick={(e) => onEdit({
          open: true,
          selected: {...node.original,nodeTitle:node.nodeTitle}
        })}
      />
      }
      </Box>
      <Box className="w-100 p-1">
      {Object.keys(node.mapProperties).length <=0  && 
        <Box  className="w-100">
        <CustomButton
          id={`create_data_properties_01`}
          buttonText={"no_data_click_start"}
          buttonColor={"inherit"}
          textVariant={"caption"}
          style={{
            margin: 5,
            padding: 10,
            width: "100%",
          }}
          tooltip={{
            title:
              getIntlTranslation("tooltip.add-custom"),
          }}
          size="small"
          className="btn"
          handleClick={(e) => onEdit({
              open: true,
              selected: {...node.original,nodeTitle:node.nodeTitle}
            })}
        />
      </Box>
      }
      <RenderPropertyDataCard data={node.original} isCompare={false} onDelete={(val) => onDeleteRecord(val,node.original)}/>
      </Box>
    </ListItem>
  );
};

const DraggableListContainer = ({ items, onDragEnd, onEdit }) => {
  const [activeId, setActiveId] = React.useState();
  const activeIndex = activeId
    ? items.findIndex((item) => item.nodeIdent === activeId)
    : undefined;

  const handleDragStart = React.useCallback(
    ({ active }) => setActiveId(active.id),
    []
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!!active && !!over && active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.nodeIdent === active.id);
      const newIndex = items.findIndex((item) => item.nodeIdent === over.id);
      onDragEnd(arrayMove(items, oldIndex, newIndex));
      setActiveId(undefined);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <List className="p-1">
          {items.map((item) => (
            <DraggableListItem
              key={item.nodeIdent}
              id={item.nodeIdent}
              node={item}
              activeIndex={activeIndex}
              onEdit={onEdit}
            />
          ))}
        </List>
      </SortableContext>
      <DragOverlay>
        {!!activeIndex && (
          <DraggableListItem
            overlay={true}
            key={items[activeIndex].nodeIdent}
            id={items[activeIndex].nodeIdent}
            node={items[activeIndex]}
            onEdit={onEdit}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};
export const KeyFindingsCurrentVersionContainer = () => {
  const { structuredCategories, handlePropertiesReorder, reorder, setReorder } =
  useCategoriesData();
  const monitoringState = useSelector((state) => state.projectMonitoring);
  const { subAssetList } = useKeyFindingsContext();
  const { onSave,isSaveSuccessFull, setIsSaveSuccessFull } = useKeyFindingsPropertyContext();
  const { selectedCategory } = useSelectedProperty();
  const [structuredProperties, setStructuredProperties] = useState({});
  const [edit, setEdit] = useState({
    open: false,
    selected: null,
  });

  useEffect(() => {
    if (!!selectedCategory && Object.keys(structuredCategories.nodes).length) {
      const tempCat = structuredCategories.nodes[selectedCategory.nodeIdent]
      if(!!tempCat){
        setStructuredProperties({...tempCat.properties});
      }else{
        setStructuredProperties({})
      }
      
    }
  }, [selectedCategory, structuredCategories.nodes]);

  useEffect(() => {
    if(reorder.type==="property" && reorder.isReorder && !!selectedCategory && Object.keys(structuredCategories.nodes).length){
      const tempCat = structuredCategories.nodes[selectedCategory.nodeIdent]
      if(!!tempCat){
        setStructuredProperties({...tempCat.properties});
      }else{
        setStructuredProperties({})
      }
      setReorder({
        type:"",
        isReorder:false,
      })
    }
  }, [reorder, selectedCategory, setReorder, structuredCategories.nodes])
  

  useEffect(() => {
    if(isSaveSuccessFull){
      setEdit({
        open: false,
        selected: null,
      })
      setIsSaveSuccessFull(false);
    }
  }, [isSaveSuccessFull, setIsSaveSuccessFull])
 
  const items = useMemo(
    () => Object.keys(structuredProperties).map((key) => structuredProperties[key]).sort((a, b) => a.nodeOrder - b.nodeOrder),
    [structuredProperties]
  );

  return (
    <Box style={{ width: monitoringState.isCompareMode ? "50%" : "100%", height: "100%" }}>
      <Card className="w-100 h-100">
        <CardContent
          className="w-100 h-100 overflow-y-auto overflow-x-hidden custom-scrollBar p-0"
        >
          <DraggableListContainer
            items={items}
            onDragEnd={(items) => handlePropertiesReorder({ parentIdent: selectedCategory.nodeIdent, items:items},"property")}
            onEdit={(item) =>   setEdit(item)}
          />
        </CardContent>
      </Card>
      {edit.open && (
        <Drawer
          anchor={"right"}
          open={edit.open}
          style={{ borderRadius: 4 }}
          className="w-100"
        >
          <Box style={{ width: 700 }} className="h-100" role="presentation">
            <KeyFindingsPropertyEditDialog
              open={edit.open}
              subAssetList={subAssetList}
              onClose={() => {
                setEdit({
                  open: false,
                  selected: null,
                });
              }}
              selectedProperty={edit.selected}
              onAdd={(rows) => onSave(rows, edit.selected)}
            />
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

