import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListItem, Box, Divider, Card, CardContent, Typography } from "@mui/material";
import {
  getIntlTranslation,
  getTranslationVariantText,
  getVariantText,
} from "../../../utils/helper";
import NumberInput from "./input-types/NumberInput";
import AreaInput from "./input-types/AreaInput";
import DateInput, { YearDateInput } from "./input-types/DateInput";
import TextEditorInput from "./input-types/TextEditorInput";
import TextInput from "./input-types/TextInput";
import QuelleInput from "./input-types/QuelleInput";
import AddressInput from "./input-types/AddressInput";
import CustomButton from "../../../components/buttons/CustomButton";
import { useSelectedProperty } from "./context/SelectedPropertyContext";
import { useUpdateState } from "./context/UpdateStateContext";
import { useComparisonMode } from "./context/ComparisonModeContext";

const RenderCompareNodeValues = () => {
  const { structuredCompareProperties } = useComparisonMode();
  const { selectedProperty } = useSelectedProperty();
  const { onCopyData } = useUpdateState();
  const [inputValue, setInputValue] = useState(null);
  const [propertyExist,setPropertyExist] = useState(true);

  useEffect(() => {
    if (
      !!selectedProperty &&
      !!structuredCompareProperties.nodes &&
      Object.keys(structuredCompareProperties.nodes).length > 0
    ) {
      let temp = {};
      if(!structuredCompareProperties.nodes[selectedProperty.nodeIdent]){
        setPropertyExist(false);
      }else{
        const subAssets = structuredCompareProperties.nodes[selectedProperty.nodeIdent]?.subAssets;
        temp[selectedProperty.nodeIdent] = Object.keys(subAssets).map(
          (key) => subAssets[key]
        );
        setInputValue(temp);
        setPropertyExist(true);
      }
    }
  }, [selectedProperty, structuredCompareProperties]);

  const handleCopy = useCallback(
    (item, subAsset) => {
      onCopyData(item, { ...subAsset });
    },
    [onCopyData]
  );

  const getInputFields = (item, node, subAsset) => {
    const id = `id-${item.nodeName}-${subAsset.subAssetIdent}`;
    const disabled = true;
    if(item.disabled) {
      return (<Typography
        style={{
          color:"#1d1b1b8c"
        }}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: node.value }}
        className="label-text m-1 p-1 w-100"
      ></Typography>)
    }

    if (item.nodeType.includes("-number")) {
      return (
        <Box className="d-flex flex-wrap w-100 p-1">
          <Box style={{ width: "50%" }} className="m-1">
            <NumberInput
              id={id}
              disabled={disabled}
              node={node}
              handleChange={(value, node, isUpdated) => {}}
            />
          </Box>
          <Box style={{ width: "50%" }} className="m-1">
            <QuelleInput
              id={id}
              disabled={disabled}
              node={node}
              handleQuelleChange={(value, node, isUpdated) => {}}
            />
          </Box>
        </Box>
      );
    }
    if (item.nodeType.includes("-area")) {
      return (
        <Box className="d-flex w-100 p-1">
          <Box style={{ width: "50%" }} className="m-1">
            <AreaInput
              id={id}
              disabled={disabled}
              node={node}
              handleChange={(value, node, isUpdated) => {}}
            />
          </Box>
          <Box style={{ width: "50%" }} className="m-1">
            <QuelleInput
              id={id}
              disabled={disabled}
              node={node}
              handleQuelleChange={(value, node, isUpdated) => {}}
            />
          </Box>
        </Box>
      );
    }

    switch (item.nodeType) {
      case "textarea":
        return (
          <Box className="d-flex flex-column w-100">
            <Box style={{ width: "100%" }}>
              <TextEditorInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) => {}}
              />
            </Box>
            <Box style={{ width: "100%" }} className="my-2">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) => {}}
              />
            </Box>
          </Box>
        );

      case "number":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <NumberInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) => {}}
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) => {}}
              />
            </Box>
          </Box>
        );

      case "area":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <AreaInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) => {}}
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) => {}}
              />
            </Box>
          </Box>
        );

      case "date":
      case "date-future":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <DateInput
                id={id}
                disabled={disabled}
                node={node}
                disableFuture={item.type === "date"}
                handleChange={(value, node, isUpdated) => {}}
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) => {}}
              />
            </Box>
          </Box>
        );

      case "date-year":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <YearDateInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) => {}}
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) => {}}
              />
            </Box>
          </Box>
        );

      case "address":
        return (
          <Box className="d-flex flex-column w-100">
            <Box style={{ width: "100%" }} className="m-1">
              <AddressInput
                id={id}
                disabled={disabled}
                node={node}
                mapConfig={{
                  nodeIdent: item.nodeIdent,
                  ident: subAsset.subAssetIdent,
                  value: node.value,
                }}
                handleChange={(value, node, isUpdated) => {}}
              />
            </Box>
            <Box style={{ width: "100%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) => {}}
              />
            </Box>
          </Box>
        );

      default:
        return (
          <Box className="d-flex w-100">
            <Box style={{ width: "50%" }} className="m-1">
              <TextInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) => {}}
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) => {}}
              />
            </Box>
          </Box>
        );
    }
  };

  const renderNodeValues = useCallback(
    (item, subAsset, index) => {
      let node = { ...subAsset.node, isCopy: true };
      return (
        <ListItem
          className={"d-flex flex-column justify-contents-start w-100 p-0"}
          id={`id-${item.nodeIdent}-${subAsset.subAssetIdent}-compare`}
          key={`key-${item.nodeIdent}-${subAsset.subAssetIdent}-compare`}
        >
          <Card className="p-1 m-1 w-100">
            <div style={{ height: 30 }} className="d-flex my-1 w-100">
              {getVariantText(
                subAsset.name,
                "subtitle",
                {
                  color: "#254a9a",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                },
                "p-1"
              )}

              {subAsset.general &&
                getTranslationVariantText(
                  "_marked-general",
                  "subtitile2",
                  {
                    fontWeight: "bold",
                    color: "#d08f8e",
                    marginLeft: 5,
                  },
                  "p-1"
                )}

              <CustomButton
                id="button-copy"
                buttonText={"_copy"}
                className={"g-btn-primary g-btn-elevated"}
                style={{
                  marginLeft: "auto",
                  height: 30,
                }}
                variant="button"
                tooltip={{
                  title: getIntlTranslation("tooltip.copy"),
                }}
                handleClick={(e) => handleCopy(item, subAsset)}
              />
            </div>
            <Divider
              variant="middle"
              className="g-divider-vertical w-100 m-1"
            />
            <CardContent className="p-0">
              {getInputFields(item, node, subAsset)}
            </CardContent>
          </Card>
        </ListItem>
      );
    },
    [handleCopy]
  );

  return (
    <>
    {!propertyExist ? 
    <Box className="w-100 d-flex align-items-center justify-content-around h-100 g-btn-secondary rounded-3">
      {getTranslationVariantText("_property not found","subtitle 2",{fontSize:14},"p-1  mx-auto g-btn-secondary rounded-3")}
    </Box>
    :<>
    {!!inputValue && !!selectedProperty &&
      inputValue[selectedProperty.nodeIdent]?.map((sub, index) => {
        return renderNodeValues(selectedProperty, sub, index);
      })}
      </>
      }
     
    </>
  );
};

export default RenderCompareNodeValues;