import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import { getFailure, getListSuccess, getDataListSuccess,getDataSuccess} from "../common/slice/chaptersSlice";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from "../../../api/authApi";
import {getFailure as failure, getSuccess} from "../common/report-component/items/text-editors/textEditorSlice"
import { getSaveSuccess } from "../common/report-component/items/project-information/projectInformationTDDSlice";
import {getSaveSuccess as getContactSaveSuccess } from "../common/report-component/items/contact-cards/contactCardsSlice";



export const loadAllChapterData = (chapter) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/${chapter.api}/fetch-content/${chapter.projectIdent}/${chapter.assetIdent}/${chapter.language}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataListSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const loadChapters = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/${request.api}/fetch-chapter-list/${request.ident}/${request.assetIdent}/${request.language}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getListSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const loadChapterData = (chapter) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/${chapter.api}/fetch-chapter-content/${chapter.projectIdent}/${chapter.assetIdent}/${chapter.ident}/${chapter.language}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const loadSectionData = (chapter) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/${chapter.api}/fetch-section-content/${chapter.projectIdent}/${chapter.assetIdent}/${chapter.chapterIdent}/${chapter.sectionIdent}/${chapter.language}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const saveProjectInfo = (projectInfo,projectIdent,api) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/${api}/save-project-information/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: {...projectInfo},
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSaveSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const saveReportText = (reportText,projectIdent,api) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/${api}/save-report-text/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: reportText
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(failure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const getMacroCity = (reportText,projectIdent,api) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/macro-location/fetch-cities`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        }
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(failure(response.data));
    } else {
      //dispatch(getCitySuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const getMacroCityDataInfo = (zipCode) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/macro-location/fetch/${zipCode}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        }
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(failure(response.data));
    } else {
      //dispatch(getCityDataSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const autoSaveReportText = (reportText,projectIdent,api) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/${api}/autoSave/save-report-text/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: reportText
      })
    );
    return response;
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const saveContactUser = (data,projectIdent,api) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/${api}/save-contact-information/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: data,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getContactSaveSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
