import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from '../../../api/authApi'
import { getFailure, getSuccess } from "../feature-bundle/defects/costElementSlice";

 export const fetchCostElementList = (obj) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/element/fetch-suggestion-tree/${obj.projectIdent}/${obj.ident}/${obj.defectType}?name=${obj.name}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};