import React, { useState } from "react";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { AppBar, Box, Button, IconButton, Toolbar, Typography  } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { getIntlTranslation } from "../../../../../utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialog = ({
  handleClose,
  openDialog,
  data,
  info,
  severity,
  handleDelete
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        maxHeight: 60,
        margin: 1,
        height: "100%",
      }}
    >
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar className="appBar" style={{ height:40}}>
          <Toolbar
            className={"dialog-toolbar p-1"}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box className="d-flex align-items-center" style={{marginLeft:5}}>
            <Typography variant="body2" style={{ textTransform: 'uppercase',marginLeft:5 }}>
      {data.name}
    </Typography>
            </Box>        
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={(e) => handleClose()}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          dividers
        >
            <Alert severity={severity}>
              <AlertTitle>{severity.toUpperCase()}</AlertTitle>
              <br />
              <strong>{info}</strong>
            </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} type="submit" className="g-btn-primary" variant="contained">
          {getIntlTranslation("_delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DeleteDialog;
