import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from "../../../api/authApi";

import { getFailure, getMarkSuccess, getEmailSuccess} from "../common/report-review/reportReviewSlice";

export const markReviewChapterComplete = (chapter) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `${chapter.api}/assets/${chapter.projectIdent}/${chapter.assetIdent}/mark-report/${chapter.value}/for/${chapter.reportType}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getMarkSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const sendReviewEmail = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `review/send-email`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          ...request,
          type: "emailSender",
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getEmailSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

