import React from 'react';
import AssetOverviewHome from "./AssetOverviewHome"
import { pmRoot } from  "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const AssetOverviewRouteConfig = {
	routes: [
		{
    path: `${pmRoot}/project/assets-overview`,
    exact: true,
    name: <IntlMessages id={"menu.asset-overview"}/>,
    element: <AssetOverviewHome/>,
    auth: [],
  },
	]
};
export default AssetOverviewRouteConfig;