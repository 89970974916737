import { Card, CardContent, CardActions, Box } from '@mui/material'
import React from 'react'
import { getIntlTranslation, getTranslationVariantText } from '../../../utils/helper'
import RenderNodeValues from './RenderNodeValues'
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import SaveButton from '../../../components/buttons/SaveButton'
import { useSelectedProperty } from './context/SelectedPropertyContext'
import { useUpdateState } from './context/UpdateStateContext'
import { useSelector } from 'react-redux';


const CurrentVersionContainer = () => {
  const state = useSelector((state) => state.currentVersion);
  const { selectedProperty } = useSelectedProperty();
  const {onPropertiesSave} = useUpdateState();
  const monitoringState = useSelector((state) => state.projectMonitoring);
  return (
    <Box style={{ width: monitoringState.isCompareMode ? "50%" : "100%", height: "100%" }}>
     <Card className="w-100 h-100 d-flex flex-column">
        {!!selectedProperty && 
        <>
    <CardContent className="w-100 overflow-x-hidden overflow-y-auto custom-scrollBar p-1" style={{height:"calc(100% - 60px"}}>
          <RenderNodeValues/>
    </CardContent>
    <CardActions
            style={{ height: 50, marginTop: "auto" }}
            className="g-btn-secondary p-1"
          >
           {monitoringState.isCompareMode && (
              <Box
                className={
                  "d-flex align-items-center justify-content-start m-1 h-100"
                }
              >
                <DoubleArrowIcon style={{ color: "#5f9ea0", fontSize: 20 }} />
                {getTranslationVariantText("current version", "body1", {
                  fontWeight: "bold",
                  color: "#254a9a",
                  marginLeft: 5,
                })}
              </Box>
            )}
             <SaveButton
              id={"save_current_version"}
              buttonText="_save"
              variant="button"
              style={{ marginLeft: state.isCompareMode ? "auto" : 0, width:state.isCompareMode ? "25%" : "100%" }}
              tooltip={{
                title: getIntlTranslation("tooltip.save"),
              }}
              handleClick={(e) => onPropertiesSave()}
            />
        </CardActions>
        </>
}
    </Card>
    </Box>
  )
}

export default CurrentVersionContainer