import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  hasErrors: false,
  type: '',
  messages: '',
  chapterList: [],
  chapterData: {},
  chapterReviewData: [],
  error: {},
  addChapter: {},
  addSection: {},
  addSubSection: {},
  customType: 'FULL REPORT',
  isDelete: {},
  isUpdate: false,
  sectionData: [],
  chapterStructure: [],
  sectionStructure: [],
  savingStatus: {},
  data: null,
  updateOrderStatus: {},
  deteleStructure: {},
  subSectionStructure: {},
  currentSelection: {
    chapterIdent: '',
    sectionIdent: '',
    api: '',
    selectionType: '',
  },
  fullMode: false,
};

const chapterSlice = createSlice({
  name: 'chapter',
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
      chapterList: [],
      chapterReviewData: [],
      chapterData: {},
    }),
    getListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      chapterList: payload.guruDtoList,
      chapterData: {},
      chapterReviewData: [],
      response: '',
      addChapter: {},
      addSection: {},
      isDelete: {},
      isUpdate: false,
      savingStatus: {},
      updateOrderStatus: {},
      deteleStructure: {},
    }),
    getDataListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      chapterList: [],
      chapterData: {},
      chapterReviewData: payload.guruDtoList,
    }),
    getDataSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      chapterList: [],
      chapterReviewData: [],
      chapterData: payload.guruDto,
    }),
    resetState: (state) => ({
      ...initialState,
      type: '',
      currentSelection: state.currentSelection,
      customType: state.customType,
      fullMode: state.fullMode,
    }),
    getAddChapter: (state, { payload }) => ({
      ...state,
      addChapter: payload,
      data: payload.guruDto,
    }),
    getAddSection: (state, { payload }) => ({
      ...state,
      addSection: payload,
      data: payload.guruDto,
    }),
    getAddSubSection: (state, { payload }) => ({
      ...state,
      addSubSection: payload,
    }),
    getCustomType: (state, { payload }) => ({
      ...state,
      customType: payload.type,
    }),
    getDelete: (state, { payload }) => ({
      ...state,
      isDelete: payload,
      data: payload.guruDto,
    }),
    getUpdate: (state, { payload }) => ({
      ...state,
      isUpdate: true,
      data: payload.guruDto,
    }),
    getChapterStructure: (state, { payload }) => ({
      ...state,
      chapterStructure: payload,
    }),
    getSectionStructure: (state, { payload }) => ({
      ...state,
      sectionStructure: payload,
    }),
    getSubSectionStructure: (state, { payload }) => ({
      ...state,
      subSectionStructure: payload,
    }),
    saveDataInput: (state, { payload }) => ({
      ...state,
      savingStatus: payload,
    }),
    updateOrder: (state, { payload }) => ({
      ...state,
      updateOrderStatus: payload,
    }),
    deleteSecTructure: (state, { payload }) => ({
      ...state,
      deteleStructure: payload,
    }),

    setCurrentSelection: (state, { payload }) => ({
      ...state,
      currentSelection: payload,
    }),
    setFullMode: (state, { payload }) => ({
      ...state,
      fullMode: payload,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  getListSuccess,
  getDataListSuccess,
  getDataSuccess,
  resetState,
  getAddChapter,
  getAddSection,
  getCustomType,
  getAddSubSection,
  getDelete,
  getUpdate,
  getChapterStructure,
  getSectionStructure,
  getSubSectionStructure,
  saveDataInput,
  updateOrder,
  deleteSecTructure,
  setCurrentSelection,
  setFullMode,
} = chapterSlice.actions;

// A selector
export const chapterSelector = (state) => state.tddChapters;

// The reducer
export default chapterSlice.reducer;
