import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

 function CostTableDialog({data, open, handleClose, onSave}) {
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          maxHeight:"680px"
        }}
      >
        <DialogTitle>{"Imported cost element"}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left"> Measure code</TableCell>
                  <TableCell align="left"> Element code</TableCell>
                  <TableCell align="left"> Element Name</TableCell>
                  <TableCell align="left"> kosten element code</TableCell>
                  <TableCell align="left">
                    kosten element beschreibung
                  </TableCell>
                  <TableCell align="left">Unit</TableCell>
                  <TableCell align="left">Von</TableCell>
                  <TableCell align="left">Mittel</TableCell>
                  <TableCell align="left">Bis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((element) => (
                  <TableRow
                    key={element.code}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {element.measureCode}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {element.elementCode}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {element.elementName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {element.code}
                    </TableCell>
                    <TableCell align="left">{element.name}</TableCell>
                    <TableCell align="left">{element.unit}</TableCell>
                    <TableCell align="left">{element.von}</TableCell>
                    <TableCell align="left">{element.mittel}</TableCell>
                    <TableCell align="left">{element.bis}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CostTableDialog;