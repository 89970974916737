import React, { createContext, useContext, useState, useEffect } from "react";
import { usePropertiesData } from "./PropertiesDataContext";

const SelectedPropertyContext = createContext();

export const useSelectedProperty = () => useContext(SelectedPropertyContext);

export const SelectedPropertyProvider = ({ children }) => {
  const { structuredProperties } = usePropertiesData();
  const [selectedProperty, setSelectedProperty] = useState(null);


  useEffect(() => {
    if(!selectedProperty){
      const propertyKeys = Object.keys(structuredProperties.nodes);
      if (propertyKeys.length > 0) {
        setSelectedProperty(structuredProperties.nodes[propertyKeys[0]]);
      }
    }
    if(!!selectedProperty){
      const propertyKeys = Object.keys(structuredProperties.nodes);
      if (propertyKeys.length > 0 && !structuredProperties.nodes[selectedProperty.nodeIdent]) {
        setSelectedProperty(structuredProperties.nodes[propertyKeys[0]]);
      }
    }
  }, [structuredProperties,selectedProperty]);

  return (
    <SelectedPropertyContext.Provider value={{ selectedProperty, setSelectedProperty }}>
      {children}
    </SelectedPropertyContext.Provider>
  );
};
