import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDataGrid, { SelectCellFormatter } from "react-data-grid";
import {
  riskOptions,
  defectTypes,
  getLanguageBasedOption,
} from "../../../../common/dropdowns";
import {
  covertInCurrencyFormat,
  getIntlTranslation,
  getDialogBoxHeader,
} from "../../../../utils/helper";
import {
  Box,
  Divider,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Toolbar,
  Dialog,
  AppBar,
} from "@mui/material";
import { groupBy as rowGrouper } from "lodash";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getCapexDetail,
  updateDefectAndMeasure,
} from "../../api/capexOverviewApi";
import { resetState } from "./capexOverviewSlice";
import { useSnackbar } from "notistack";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { NoData } from "../../../../common/commonImport";
import CustomButton from "../../../../components/buttons/CustomButton";
import TextEditorDialog from "./TextEditorDialog";
import EditDefect from "../defects/edit/EditDefect";
import CapexSummary from "./capex-summary/CapexSummary";
import BreadcrumbProjectContainer from "../../../../layout/app-container/BreadcrumbProjectContainer";

const useStyles = styled((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1550,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CapexOverview = () => {
  const COLUMN_WIDTH = 160;
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(true);
  const options = ["assetName", "category", "trade", "risk", "element", "typ"];
  const { enqueueSnackbar } = useSnackbar();
  const [capexOverview, setcapexOverview] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [expandedGroupIds, setExpandedGroupIds] = useState([]);
  const scope = useSelector((state) => state.container.scope);
  const capexOverviewObj = useSelector((state) => state.capexOverview);
  const [openCapexSummary, setOpenCapexSummary] = useState(false);
  const [editRow, setEditRow] = useState({
    isOpen: false,
    ident: null,
    assetIdent: null,
  });
  const navigate = useNavigate();
  const [originalVal, setOriginalVal] = useState({
    risk: [],
    typ: [],
    redFlag: [],
    defect: [],
    measure: [],
  });
  const dispatch = useDispatch();
  const optionAll = {
    value: "All",
    title: {
      en: "All",
      de: "Alle",
    },
  };
  const [filters, setFilters] = useState({
    assetId: " ",
    risk: {
      value: "All",
      title: {
        en: "All",
        de: "Alle",
      },
    },
    typ: {
      value: "All",
      title: {
        en: "All",
        de: "Alle",
      },
    },
  });

  const handleClose = () => {
    setOpen(false);
    setLoader(false);
    navigate("/guru/tdd/project/assets-overview/");
  };

  useEffect(() => {
    if (!!scope.projectIdent) {
      dispatch(getCapexDetail(scope.projectIdent));
    }
  }, [dispatch, scope.projectIdent]);

  useEffect(() => {
    if (!!capexOverviewObj && capexOverviewObj.type === "SUCCESS") {
      if (capexOverviewObj.data.length > 0) {
        setcapexOverview(
          capexOverviewObj.data.map((data, index) => {
            return {
              id: index + 1,
              assetId: data.assetId,
              assetName: data.assetName,
              assetIdent: data.assetIdent,
              area: data.area,
              subAssetAddress: data.subAssetAddress,
              category: data.category,
              trade: data.trade,
              risk: data.risk,
              riskObj: riskOptions.find(
                (option) =>
                  option.title[scope.projectLanguageCode].toLowerCase() ===
                  data.risk.toLowerCase()
              ),
              element: data.element,
              defect: data.defect,
              measure: data.measure,
              comments: data.comments,
              typ: data.typ,
              typObj: defectTypes.find(
                (option) =>
                  option.title[scope.projectLanguageCode].toLowerCase() ===
                  data.typ.toLowerCase()
              ),
              shortTermCost: data.shortTermCost,
              shortTermCostDisplay: covertInCurrencyFormat(data.shortTermCost),
              mediumTermCost: data.mediumTermCost,
              mediumTermCostDisplay: covertInCurrencyFormat(
                data.mediumTermCost
              ),
              longTermCost: data.longTermCost,
              longTermCostDisplay: covertInCurrencyFormat(data.longTermCost),
              totalCost: data.totalCost,
              totalCostDisplay: covertInCurrencyFormat(data.totalCost),
              quantity: data.quantity,
              unit: data.unit,
              pricePerUnit: covertInCurrencyFormat(data.pricePerUnit),
              percElementsAffected: data.percElementsAffected,
              percProbOfOccurrence: data.percProbOfOccurrence,
              constructionAuthor: data.constructionAuthor,
              mAndEAuthor: data.mAndEAuthor,
              defectIdent: data.defectIdent,
              measureIdent: data.measureIdent,
              redFlag: data.inRedFlag,
              categoryOrder: data.categoryOrder,
              tradeOrder: data.tradeOrder,
            };
          })
        );
        setOriginalVal({
          risk: [],
          typ: [],
          redFlag: [],
          defect: [],
          measure: [],
        });
        dispatch(resetState());
      }
      setLoader(false);
    }

    if (!!capexOverviewObj && capexOverviewObj.type === "UPDATED") {
      capexOverviewObj.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setLoader(false);
      dispatch(getCapexDetail(scope.projectIdent));
    }
  }, [capexOverviewObj, dispatch, scope.projectIdent]);

  function toggleOption(option: string, enabled: boolean) {
    const index = selectedOptions.indexOf(option);
    if (enabled) {
      if (index === -1) {
        setSelectedOptions((options) => [...options, option]);
      }
    } else if (index !== -1) {
      setSelectedOptions((options) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        return newOptions;
      });
    }
    setExpandedGroupIds(new Set());
  }
  const filteredRows = useMemo(() => {
    return capexOverview.filter((r) => {
      return (
        (filters.risk?.value !== "All"
          ? r.riskObj.value === filters.risk.value
          : true) &&
        (filters.typ?.value !== "All"
          ? r.typObj.value === filters.typ.value
          : true)
      );
    });
  }, [capexOverview, filters]);

  const riskDropdownEditor = (p) => {
    return (
      <Autocomplete
        id="riskOptions"
        value={p.row.riskObj}
        options={riskOptions}
        onChange={(event, newValue) => {
          let originalRisk = getLanguageBasedOption(
            p.row.riskObj,
            scope.projectLanguageCode
          );
          if (
            originalVal.risk.length === 0 ||
            !originalVal.risk.find((v) => v.id === p.row.id)
          ) {
            originalVal.risk.push({
              id: p.row.id,
              val: originalRisk,
            });
          }

          if (!!newValue) {
            p.row.riskObj = newValue;
            p.row.risk = getLanguageBasedOption(
              newValue,
              scope.projectLanguageCode
            );
            setcapexOverview(
              capexOverview.map((val) => {
                return val.id === p.row.id
                  ? {
                      ...val,
                      riskObj: newValue,
                      risk: getLanguageBasedOption(
                        newValue,
                        scope.projectLanguageCode
                      ),
                      updated:
                        p.row.risk !==
                        originalVal.risk.find((v) => v.id === p.row.id).val,
                    }
                  : val;
              })
            );
          }
        }}
        getOptionLabel={(option) =>
          getLanguageBasedOption(option, scope.projectLanguageCode)
        }
        getOptionSelected={(option, value) => value.value === option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            style={{ paddingRight: 0 }}
          />
        )}
      />
    );
  };

  const defectDropdownEditor = (p) => {
    return (
      <Autocomplete
        id="defectOptions"
        value={p.row.typObj}
        options={defectTypes}
        onChange={(event, newValue) => {
          const originalTyp = getLanguageBasedOption(
            p.row.typObj,
            scope.projectLanguageCode
          );
          if (
            originalVal.typ.length === 0 ||
            !originalVal.typ.find((v) => v.id === p.row.id)
          ) {
            originalVal.typ.push({
              id: p.row.id,
              val: originalTyp,
            });
          }
          if (!!newValue) {
            p.row.typObj = newValue;
            p.row.typ = getLanguageBasedOption(
              newValue,
              scope.projectLanguageCode
            );
            setcapexOverview(
              capexOverview.map((val) => {
                return val.id === p.row.id
                  ? {
                      ...val,
                      typObj: newValue,
                      typ: getLanguageBasedOption(
                        newValue,
                        scope.projectLanguageCode
                      ),
                      updated:
                        p.row.typ !==
                        originalVal.typ.find((v) => v.id === p.row.id).val,
                    }
                  : val;
              })
            );
          }
        }}
        getOptionLabel={(option) =>
          getLanguageBasedOption(option, scope.projectLanguageCode)
        }
        getOptionSelected={(option, value) => value.value === option.value}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    );
  };

  const defectDescriptionEditor = (p) => {
    const originalDefect = p.row.defect;
    if (
      originalVal.defect.length === 0 ||
      !originalVal.defect.find((v) => v.id === p.row.id)
    ) {
      originalVal.defect.push({
        id: p.row.id,
        val: originalDefect,
      });
    }
    return (
      <TextEditorDialog
        open={true}
        label={"Technical Issue"}
        text={p.row.defect}
        data={p}
        onChange={(event) =>
          p.onRowChange({ ...p.row, defect: event.target.value })
        }
        onSave={() => {
          setcapexOverview(
            capexOverview.map((val) => {
              return val.id === p.row.id
                ? {
                    ...val,
                    defect: p.row.defect,
                    updated:
                      p.row.defect !==
                      originalVal.defect.find((v) => v.id === p.row.id).val,
                  }
                : val;
            })
          );
          p.onClose(true);
        }}
        onCancel={() => {
          p.onClose(true);
        }}
      />
    );
  };

  const measureDescriptionEditor = (p) => {
    const originalMeasure = p.row.measure;
    if (
      originalVal.measure.length === 0 ||
      !originalVal.measure.find((v) => v.id === p.row.id)
    ) {
      originalVal.measure.push({
        id: p.row.id,
        val: originalMeasure,
      });
    }
    return (
      <TextEditorDialog
        open={true}
        label={"Recommendation"}
        text={p.row.measure}
        data={p}
        onChange={(event) =>
          p.onRowChange({ ...p.row, measure: event.target.value })
        }
        onSave={() => {
          setcapexOverview(
            capexOverview.map((val) => {
              return val.id === p.row.id
                ? {
                    ...val,
                    measure: p.row.measure,
                    updated:
                      p.row.measure !==
                      originalVal.measure.find((v) => v.id === p.row.id).val,
                  }
                : val;
            })
          );
          p.onClose(true);
        }}
        onCancel={() => {
          p.onClose(true);
        }}
      />
    );
  };

  const columns = () => {
    return [
      {
        key: "edit",
        name: "Edit",
        width: 60,
        frozen: true,
        resizable: false,
        formatter: (p) => {
          return (
            <IconButton
              size="small"
              aria-label="edit"
              onClick={(e) => {
                setEditRow({
                  isOpen: true,
                  ident: p.row.defectIdent,
                  assetIdent: p.row.assetIdent,
                });
              }}
            >
              <EditIcon color="primary" />
            </IconButton>
          );
        },
      },
      {
        key: "id",
        name: "ID",
        width: 60,
        frozen: true,
        resizable: false,
        summaryFormatter() {
          return <strong>Total</strong>;
        },
      },
      {
        name: "Asset Name",
        key: "assetName",
        width: COLUMN_WIDTH,
        frozen: true,
        summaryFormatter({ row }) {
          return <>{row.totalCount} records</>;
        },
      },
      {
        name: "Category",
        key: "category",
        width: COLUMN_WIDTH,
        frozen: true,
      },
      {
        name: "Trade",
        key: "trade",
        width: COLUMN_WIDTH,
        frozen: true,
      },
      {
        name: "Risk",
        key: "risk",
        width: 120,
        formatter: (p) => {
          return getLanguageBasedOption(
            p.row.riskObj,
            scope.projectLanguageCode
          );
        },
        editor: (p) => riskDropdownEditor(p),
        editorOptions: {
          editOnClick: true,
        },
        headerCellClass: "filter-cell",
        headerRenderer: (p) => (
          <>
            <div style={{ height: "50%" }}>{p.column.name}</div>
            <div style={{ height: "50%" }}>
              <Autocomplete
                id="riskOptions-H"
                className="filter"
                options={[...[optionAll], ...riskOptions]}
                value={filters.risk}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    newValue = optionAll;
                  }
                  setFilters({
                    ...filters,
                    risk: newValue,
                  });
                }}
                getOptionLabel={(option) =>
                  getLanguageBasedOption(option, scope.projectLanguageCode)
                }
                getOptionSelected={(option, value) =>
                  value.value === option.value
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </>
        ),
      },
      { name: "Element", key: "element", width: COLUMN_WIDTH, frozen: true },
      {
        name: "Type",
        key: "typ",
        width: COLUMN_WIDTH,
        formatter: (p) => {
          return getLanguageBasedOption(
            p.row.typObj,
            scope.projectLanguageCode
          );
        },
        editor: (p) => defectDropdownEditor(p),
        editorOptions: {
          editOnClick: true,
        },
        headerCellClass: "filter-cell",
        headerRenderer: (p) => (
          <>
            <div style={{ height: "50%" }}>{p.column.name}</div>
            <div style={{ height: "50%" }}>
              <Autocomplete
                id="defectOptions-H"
                className="filter"
                options={[...[optionAll], ...defectTypes]}
                value={filters.typ}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    newValue = optionAll;
                  }
                  setFilters({
                    ...filters,
                    typ: newValue,
                  });
                }}
                getOptionLabel={(option) =>
                  getLanguageBasedOption(option, scope.projectLanguageCode)
                }
                getOptionSelected={(option, value) =>
                  value.value === option.value
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </>
        ),
      },
      {
        name: "Technical Issue",
        key: "defect",
        width: 200,
        resizable: true,
        editor: (p) =>   {
          return openEditDialogEditor(p);
      },
        editorOptions: {
          renderFormatter: false,
        },
      },
      {
        name: "Recommendation",
        key: "measure",
        width: COLUMN_WIDTH,
        editor: (p) =>   {
          return openEditDialogEditor(p);
      },
        editorOptions: {
          renderFormatter: false,
        },
      },
      {
        name: "Short Term (Year 1)",
        key: "shortTermCostDisplay",
        width: COLUMN_WIDTH,
        summaryFormatter({ row }) {
          const val = filteredRows.reduce(
            (prev, { shortTermCost }) => prev + shortTermCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
        groupFormatter({ childRows }) {
          const val = childRows.reduce(
            (prev, { shortTermCost }) => prev + shortTermCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
      },
      {
        name: "Medium Term (Years 2 - 5)",
        key: "mediumTermCostDisplay",
        width: COLUMN_WIDTH,
        summaryFormatter({ row }) {
          const val = filteredRows.reduce(
            (prev, { mediumTermCost }) => prev + mediumTermCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
        groupFormatter({ childRows }) {
          const val = childRows.reduce(
            (prev, { mediumTermCost }) => prev + mediumTermCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
      },
      {
        name: "Long Term (Years 6 - 10)",
        key: "longTermCostDisplay",
        width: COLUMN_WIDTH,
        summaryFormatter({ row }) {
          const val = filteredRows.reduce(
            (prev, { longTermCost }) => prev + longTermCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
        groupFormatter({ childRows }) {
          const val = childRows.reduce(
            (prev, { longTermCost }) => prev + longTermCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
      },
      {
        name: "Total Cost Estimate",
        key: "totalCostDisplay",
        width: COLUMN_WIDTH,
        summaryFormatter({ row }) {
          const val = filteredRows.reduce(
            (prev, { totalCost }) => prev + totalCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
        groupFormatter({ childRows }) {
          const val = childRows.reduce(
            (prev, { totalCost }) => prev + totalCost,
            0
          );
          return <>{covertInCurrencyFormat(val)}</>;
        },
      },
      {
        name: "Quantity",
        key: "quantity",
        type: "numeric",
        width: COLUMN_WIDTH,
      },
      {
        name: "Unit",
        key: "unit",
        width: COLUMN_WIDTH,
      },
      {
        name: "Price per unit",
        key: "pricePerUnit",
        width: COLUMN_WIDTH,
      },
      {
        name: "% elements affected",
        key: "percElementsAffected",
        width: COLUMN_WIDTH,
      },
      {
        name: "% probability of occurrence",
        key: "percProbOfOccurrence",
        width: COLUMN_WIDTH,
      },
      {
        name: "Comments",
        key: "comments",
        width: COLUMN_WIDTH,
      },
      {
        name: "Construction",
        key: "constructionAuthor",
        width: COLUMN_WIDTH,
      },
      {
        name: "M&E",
        key: "mAndEAuthor",
        width: COLUMN_WIDTH,
      },
      {
        name: "RedFlag",
        key: "redFlag",
        width: 50,
        formatter({ row, onRowChange, isCellSelected }) {
          return (
            <SelectCellFormatter
              tabIndex={-1}
              value={row.redFlag}
              onChange={(e) => {
                const original = row.redFlag;
                if (
                  originalVal.redFlag.length === 0 ||
                  !originalVal.redFlag.find((v) => v.id === row.id)
                ) {
                  originalVal.redFlag.push({
                    id: row.id,
                    val: original,
                  });
                }
                onRowChange({ ...row, redFlag: !row.redFlag });
                setcapexOverview(
                  capexOverview.map((val) => {
                    return val.id === row.id
                      ? {
                          ...val,
                          redFlag: e,
                          updated:
                            !row.redFlag !==
                            originalVal.redFlag.find((v) => v.id === row.id)
                              .val,
                        }
                      : val;
                  })
                );
              }}
              onClick={(e) => e.stopPropagation()}
              isCellSelected={isCellSelected}
            />
          );
        },
      },
      {
        name: "Addresses",
        key: "subAssetAddress",
        width: COLUMN_WIDTH,
        frozen: false,
      },
    ];
  };

  const rowKeyGetter = (row) => {
    return row.id;
  };

  const summaryRows = useMemo(() => {
    const summaryRow = {
      id: "total_0",
      totalCount: filteredRows.length,
      yesCount: filteredRows.filter((r) => r.updated).length,
    };
    return [summaryRow];
  }, [filteredRows]);

  const handleSubmit = () => {
    const updatedRows = capexOverview.filter((val) => val.updated);
    const saveRows = updatedRows.map((r) => {
      const measureDto = {
        type: "measureDto",
        measureDescription: r.measure,
        measureIdent: r.measureIdent,
        active: true,
      };
      const defectDto = {
        type: "defectDto",
        risk: r.riskObj.value,
        defect: r.defect,
        inRedFlag: r.redFlag,
        defectType: r.typObj.value,
        defectIdent: r.defectIdent,
      };
      return { ...defectDto, measureDtoList: [measureDto] };
    });
    dispatch(
      updateDefectAndMeasure({
        projectIdent: scope.projectIdent,
        defectList: saveRows,
      })
    );
    setLoader(true);
  };


  const openEditDialogEditor = useCallback((p) => {
    return (
      <EditDefect
      open={true}
      defectIdent={p.row.defectIdent}
      assetIdent={p.row.assetIdent}
      onClose={() => {
        setEditRow({
          isOpen: false,
          ident: null,
          assetIdent: null,
        })
        p.onClose(false);
      }}
      onUpdate={() => {
        setEditRow({
          isOpen: false,
          ident: null,
          assetIdent: null,
        });
        dispatch(getCapexDetail(scope.projectIdent));
      }}
    />
    );
  }, []);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: "100%", overflow: "hidden" }}
      >
        <AppBar className="header w-100 rounded-0" style={{ zIndex: "1350", height:40 }}>
          <Toolbar
            style={{ height: 40 }}
            className="dialog-toolbar d-flex align-items-center p-1"
          >
            <Box style={{ flex: 1, marginLeft: 5 }} className="w-25">
              {getDialogBoxHeader("_capex-overview")}
            </Box>
            <div className="d-flex align-items-center justify-content-between w-75">
             <BreadcrumbProjectContainer 
             showAsset={false}
             showSubAsset={false}/>
            <CustomButton
              buttonText={"_save"}
              tooltip={{
                title: getIntlTranslation("tooltip.Save"),
              }}
              color="#ffff"
              style={{ height: 30, width:"10%" }}
              variant="button"
              handleClick={handleSubmit}
            />
            <Divider
              orientation="vertical"
              style={{
                width: 4,
                margin: "10px 10px",
                backgroundColor: "#ffffff",
              }}
              flexItem
            />
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            </div>
          </Toolbar>
        </AppBar>
        {!!capexOverview && capexOverview.length > 0 ? (
          <>
            {!openCapexSummary ? (
              <>
                <Box className="d-flex p-1 align-items-center w-100" style={{height:40}}>
                  <Typography variant="subtitile" style={{ padding: 5 }}>
                    <b>Group by columns:</b>
                  </Typography>

                  <FormGroup
                    className="d-flex align-items-center"
                    style={{ width: "75%", flexDirection: "row" }}
                  >
                    {options.map((option) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedOptions.includes(option)}
                            onChange={(event) =>
                              toggleOption(option, event.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="body1"
                            style={{ textTransform: "uppercase" }}
                          >
                            {option}
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                  <CustomButton
                    buttonText={"_capex-summary"}
                    tooltip={{
                      title: getIntlTranslation("tooltip.open"),
                    }}
                    variant="text"
                    style={{ height: 30, marginLeft: "auto" }}
                    className={"g-btn-secondary"}
                    handleClick={() => setOpenCapexSummary(true)}
                  />
                </Box>
                <div className="rdg-root custom-scrollBar" style={{ height:"calc(100% - 90px)"}}>
                  <ReactDataGrid
                    className=" fill-grid rdg-light"
                    headerRowHeight={100}
                    columns={columns()}
                    rows={filteredRows}
                    rowKeyGetter={rowKeyGetter}
                    groupBy={selectedOptions}
                    rowGrouper={rowGrouper}
                    expandedGroupIds={expandedGroupIds}
                    onExpandedGroupIdsChange={setExpandedGroupIds}
                    defaultColumnOptions={{ resizable: true }}
                    summaryRows={summaryRows}
                    rowClass={(row) =>
                      row.updated ? "highlightClassname" : undefined
                    }
                  />
                </div>
              </>
            ) : (
              <CapexSummary
                data={capexOverview}
                onClose={() => setOpenCapexSummary(false)}
                open={openCapexSummary}
              />
            )}
          </>
        ) : (
          <NoData
            preMassage={getIntlTranslation("_requested-service")}
            secMassage={getIntlTranslation("_no-capex-data")}
          />
        )}

        {editRow.isOpen && (
          <EditDefect
            open={editRow.isOpen}
            defectIdent={editRow.ident}
            assetIdent={editRow.assetIdent}
            onClose={() =>
              setEditRow({
                isOpen: false,
                ident: null,
                assetIdent: null,
              })
            }
            onUpdate={() => {
              setEditRow({
                isOpen: false,
                ident: null,
                assetIdent: null,
              });
              dispatch(getCapexDetail(scope.projectIdent));
            }}
          />
        )}
        {loader && (
          <Backdrop className={useStyles.backdrop} open={loader}>
            <div className="preloader">
              <div></div>
            </div>
          </Backdrop>
        )}
      </Dialog>
    </>
  );
};

export default CapexOverview;
