import React, { useEffect, useState } from 'react'
import { Autocomplete, Checkbox, TextField} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const UserInput = ({id,node,userList,disabled = false,handleChange}) => {
    const [userSelected, setUserSelected] = useState([])
    useEffect(() => {
     if(!!node.users){
        setUserSelected(node.users)
     }
    }, [node.users])
    
    return (
        <Autocomplete
          multiple
          disabled={disabled}
          disableCloseOnSelect
          id={id}
          value={userSelected}
          options={userList}
          getOptionLabel={(option) => option.name || null}
          onChange={(event, newValue) => {
            setUserSelected(newValue);
            handleChange(newValue);
          }}
          isOptionEqualToValue={(option, value) =>
            option.userId === value.userId
          }
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            );
          }}
    
          size="small"
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={node.nodeTitle}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      );
}

UserInput.propTypes = {}

export default UserInput