import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  Autocomplete,
  TextField,
  Checkbox,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import {
  covertInCurrencyFormat,
  getIntlTranslation,
  getLabel,
  getVariantText,
} from "../../../../../utils/helper";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomButton from "../../../../../components/buttons/CustomButton";
import CostCharts from "./CostCharts";
import DefectTypeCharts from "./DefectTypeCharts";
import CategoriesAssetChart from "./CategoriesAssetChart";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CapexCharts = (props) => {
  const [chartData, setChartData] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    if (!!props.data) {
      setChartData(props.data);
      if (props.selected) {
        let tempObj = props.data.find(
          (f) => f.assetIdent === props.selected.ident
        );
        setSelectedData((prev) => ({
          ...prev,
          assetName: [
            {
              title: tempObj.assetName,
              ident: tempObj.assetIdent,
              value: tempObj.assetName,
            },
          ],
        }));
      }
    }
  }, [props.data]);

  const handleSelectionChanges = (value, key) => {
    setSelectedData((prev) => ({ ...prev, [key]: value }));
  };

  const getDropDownOption = useCallback(
    (key) => {
      let temp = chartData
        .filter((f, i, arr) => arr.findIndex((a) => a[key] === f[key]) === i)
        .map((m) => {
          return {
            title: m[key],
            value: m[key],
          };
        });
      return temp;
    },
    [chartData]
  );

  const renderFilterOptions = useCallback(
    (options, filter) => {
      return (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={filter.key}
          value={
            !!selectedData[filter.key]?.length > 0
              ? selectedData[filter.key]
              : []
          }
          options={options}
          getOptionLabel={(option) => option.title || []}
          onChange={(event, newValue) => {
            handleSelectionChanges(newValue, filter.key);
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          size="small"
          style={{ width: "100%" }}
          className="custom-scrollBar"
          renderOption={(props, option, objectState, ownerState) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={objectState.selected}
              />
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} multiline maxRows={4} />
          )}
        />
      );
    },
    [selectedData]
  );
  const columns = [
    {
      name: "Asset Name",
      key: "assetName",
      isFilter: true,
      isDropdown: true,
      isMultiple: true,
      render: (obj) => {
        let temp = obj.getDropDownOption(obj.key);
        return renderFilterOptions(temp, obj);
      },
      getDropDownOption: () => {
        let temp = chartData
          .filter(
            (f, i, arr) =>
              arr.findIndex((a) => a["assetName"] === f["assetName"]) === i
          )
          .map((m) => {
            return {
              title: m["assetName"],
              value: m["assetName"],
              ident: m["assetIdent"],
            };
          });
        return temp;
      },
    },
  ];
  return (
    <List className="d-flex  flex-column flex-wrap w-100 -top p-1">
      {columns
        .filter((f) => f.isFilter)
        .map((item) => (
          <>
            <ListItem
              key={item.key}
              style={{ width: "100%", overflow: "hidden" }}
              className="p-1 align-items-start custom-scrollBar"
            >
              <Card className="w-100">
              <CardContent
                        style={{ height: '100%', width: '100%', overflowY:"auto", overflowX:"auto"}}
                        className={
                "p-1 m-1 custom-scrollBar"
                        }
            >
                  <Box style={{ width: "50%" }} className={"m-2"}>
                    {getVariantText(item.name, "subtitle2", {
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      margin: 5,
                    })}
                    {item.render(item)}
                  </Box>
                  <Box className="align-items-center" style={{width:"max-content", display:"inline-flex"}}>
                    <CostCharts
                      item={item}
                      selectedData={selectedData}
                      data={chartData}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        margin: "0 5px",
                        backgroundColor: "#d08f8e",
                      }}
                    />
                    <DefectTypeCharts
                      item={item}
                      selectedData={selectedData}
                      data={chartData}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        margin: "0 5px",
                        backgroundColor: "#d08f8e",
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </ListItem>
          </>
        ))}
    </List>
  );
};

CapexCharts.propTypes = {};

export default CapexCharts;
