import React from 'react'
import { useSelector } from "react-redux";
import { tddServiceLine} from "../../utils/defaultValues";
import { Navigate } from "react-router-dom";

function TddProjectScopeWrapper({children}) {
      const scope = useSelector((state) => state.container.scope);
    
      const validateProjectScope = () => {
        return (scope.isProjectScope && scope.serviceLine.toLowerCase() === tddServiceLine.name.toLowerCase() && !!scope.projectIdent)
    
      }
      return validateProjectScope() ? (
        children
      ) : (
        <Navigate to={`${tddServiceLine.root}/project-list`} replace />
      );
    };

export default TddProjectScopeWrapper