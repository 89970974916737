import React from 'react';
import TddProjectListHome from "./TddProjectListHome"
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";


const ProjectListRouteConfig = {
	routes: [
		{
    path: `${tddRoot}/project-list`,
    exact: true,
    name: <IntlMessages id={"menu.project-list"}/>,
    element: <TddProjectListHome/>,
    auth: [],
  }
	]
};
export default ProjectListRouteConfig;