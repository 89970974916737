import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import {
  Autocomplete,
  Checkbox,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { resetState } from "../../../../../component-bundle/asset-overview/assetOverviewSlice";
import DialogContent from "@mui/material/DialogContent";
import * as Yup from "yup";
import IntlMessages from "../../../../../../utils/IntlMessages";
import { AssetPermissions } from "../../../../../../common/dropdowns";
import { Box } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { resetErrorState } from "../../../../../../error/errorHandlingSlice";
import { updateAsset, getAsset } from "../../../../../../api/assetOverviewApi";
import SaveButton from "../../../../../../components/buttons/SaveButton";
import {
  getDialogBoxHeader,
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
  getVariantText,
} from "../../../../../../utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AssetUserManagementDialog = (props) => {
  const dispatch = useDispatch();
  const { asset, onClose, open, projectInfo, mode } = props;
  const [project, setProject] = useState(projectInfo);
  const scope = useSelector((state) => state.container.scope);
  const [openDialog, setOpenDialog] = useState(open);
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const assetState = useSelector((state) => state.assetOverview.create);
  const formRef = useRef();
  const validationSchema = Yup.object().shape({
    clientName: Yup.string().required(<IntlMessages id="required_field" />),
  });

  const errorState = useSelector((state) => state.errorhandling);
  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
      setLoader(false);
      dispatch(resetErrorState());
    }
  }, [dispatch, errorState]);

  useEffect(() => {
    if (assetState?.type === "UPDATED") {
      dispatch(resetState());
      if (typeof onClose === "function") {
        onClose();
      }
    }
  }, [assetState?.asset, assetState?.isFetch, assetState?.type, dispatch]);

  useEffect(() => {
    if (!!project && project.projectUserDtos.length > 0) {
      let user = [];

      project.projectUserDtos.map((element) => {
        user.push({
          name: `${element.firstName} ${element.lastName}`,
          firstName: element.firstName,
          lastName: element.lastName,
          emailAddress: element.emailAddress,
          permission: getUserPermission(element.emailAddress),
          author: checkForAuthor(element.emailAddress),
        });
      });
      const filterUser = user.filter(
        (e) => e.emailAddress !== "it.support@taeurope.com"
      );
      setUsers(filterUser);
    }
  }, [asset, project]);

  const checkForAuthor = (emailAddress) => {
    let user = asset.assetUserPermissionsDtos.find(
      (user) => user.emailAddress === emailAddress
    );
    if (!!user && user.author) {
      return user.author;
    } else {
      return false;
    }
  };

  const getUserPermission = (emailAddress) => {
    let user = asset.assetUserPermissionsDtos.find(
      (user) => user.emailAddress === emailAddress
    );
    if (!!user) {
      return user.permission;
    } else {
      return "NONE";
    }
  };

  const intialFormData = {
    users: {},
  };

  const handleSubmit = (values) => {
    const userPermissions = [];
    users.forEach((element) => {
      userPermissions.push({
        type: "assetUserPermissionsDto",
        emailAddress: element.emailAddress,
        permission: element.permission,
        author: element.author,
      });
    });

    const editAsset = {
      api: scope.api,
      data: {
        type: "tddAssetDto",
        projectIdent: project.projectIdent,
        name: asset.name,
        assetIdent: asset.assetIdent,
        numberofBuildings: asset.numberofBuildings,
        assetUserPermissionsDtos: userPermissions,
      },
    };
    dispatch(updateAsset(editAsset));
    setLoader(true);
  };

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    setOpenDialog(false);
    setLoader(false);
  };

  function updateUserPermissions(originalArray, newValue, updateValue) {
    const updatedArray = originalArray.map((user) => {
      const matchingUser = newValue.find(
        (newuser) => newuser.emailAddress === user.emailAddress
      );
      if (matchingUser) {
        if (
          matchingUser.permission === user.permission &&
          matchingUser.permission !== updateValue
        ) {
          return { ...user, permission: updateValue };
        } else if (
          matchingUser.permission === user.permission &&
          matchingUser.permission === updateValue
        ) {
          return { ...user, permission: updateValue };
        }
      } else {
        if (user.permission !== updateValue) {
          return { ...user };
        } else {
          return { ...user, permission: "NONE" };
        }
      }
    });
    return updatedArray;
  }
  function updateUserAuthorPermissions(originalArray, newValue) {
    const updatedArray = originalArray.map((user) => {
      const matchingUser = newValue.find(
        (newuser) => newuser.emailAddress === user.emailAddress
      );
      if (matchingUser) {
        if (matchingUser.author === user.author) {
          return { ...user, author: true };
        }
      } else {
        return { ...user, author: false };
      }
    });
    return updatedArray;
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <Formik
          enableReinitialize
          initialValues={intialFormData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <>
              <AppBar className="appBar">
                <Toolbar className={"dialog-toolbar"}>
                  {getDialogBoxHeader("_Asset-User-Management")}
                  <IconButton
                    edge="start"
                    color="inherit"
                    id="create_project_close"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ marginLeft: "auto" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent dividers className="custom-scrollBar">
                <Box>
                  <List>
                    {AssetPermissions.map((m) => {
                      const labelId = `checkbox-list-label-${m}`;
                      return (
                        <Box
                          className="d-flex flex-column"
                          style={{ minHeight: 60, marginLeft: 20 }}
                        >
                          <ListItem
                            key={m}
                            style={{ marginTop: 20 }}
                            disablePadding
                            className="w-100"
                          >
                            <Box className={"w-100 d-flex flex-wrap"}>
                              <ListItemText
                                id={labelId}
                                primary={getVariantText(m.value, "body1", {
                                  fontWeight: "bold",
                                  color: "#254a9a",
                                })}
                                style={{ width: "30%" }}
                              />
                              <Box>
                                <Autocomplete
                                  multiple
                                  size="small"
                                  id="checkboxes-users"
                                  options={users}
                                  value={users.filter(
                                    (u) => u.permission === m.value
                                  )}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, newValue) => {
                                    setUsers(
                                      updateUserPermissions(
                                        users,
                                        newValue,
                                        m.value
                                      )
                                    );
                                  }}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.name}
                                    </li>
                                  )}
                                  style={{ width: 500 }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={getLabel("Team Members")}
                                      placeholder="Users"
                                      size="small"
                                      multiline
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                          </ListItem>
                        </Box>
                      );
                    })}
                    <Box
                      className="d-flex flex-column"
                      style={{ minHeight: 60, marginLeft: 20 }}
                    >
                      <ListItem
                        key={"author"}
                        style={{ marginTop: 20 }}
                        disablePadding
                        className="w-100"
                      >
                        <Box className={"w-100 d-flex flex-wrap"}>
                          <ListItemText
                            primary={getTranslationVariantText("_Author", "body1", {
                              fontWeight: "bold",
                              color: "#254a9a",
                            })}
                            style={{ width: "30%" }}
                          />
                          <Box>
                            <Autocomplete
                              multiple
                              size="small"
                              id="checkboxes-users"
                              options={users}
                              value={users.filter((u) => u.author)}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                setUsers(
                                  updateUserAuthorPermissions(users, newValue)
                                );
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </li>
                              )}
                              style={{ width: 500 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={getLabel("_Author")}
                                  placeholder="Users"
                                  size="small"
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </ListItem>
                    </Box>
                  </List>
                </Box>
              </DialogContent>
              <DialogActions>
                <SaveButton
                  id="save-asset-01"
                  buttonText={"_save"}
                  style={{
                    margin: 5,
                    backgroundColor: "#d9534f",
                    color: "#ffffff",
                    height: 40,
                  }}
                  variant="button"
                  tooltip={{
                    title: getIntlTranslation("_save"),
                  }}
                  handleClick={(e) => handleSubmit(asset)}
                />
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AssetUserManagementDialog;
