import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  TextField,
  Divider,
  Card,
  CardContent,
  DialogContent,
  Autocomplete,
} from '@mui/material';
import CustomButton from '../../../components/buttons/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  getOverlineText,
  getLabel,
  getDialogBoxHeader,
  getIntlTranslation,
  getTranslationVariantText,
} from "../../../utils/helper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import textInputImage from "../../../assets/images/textInputImage.jpeg";
import textEditorImage from "../../../assets/images/textEditor.jpeg";
import numberInputImage from "../../../assets/images/numberInputImage.jpeg";
import locationInputImage from "../../../assets/images/locationInputImage.jpeg";
import dateInputImage from "../../../assets/images/dateInputImage.jpeg";
import dataYearInputImage from "../../../assets/images/dataYearInputImage.jpeg";
import dateFuturInputImage from "../../../assets/images/dateFuturInputImage.jpeg";
import PopoverInfo from '../../../components/popOver/PopoverInfo';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const propertyType = [
  {
    label: {
      en: "Text",
      de: "Text",
    },
    value: "text",
    message:
      "The Text type allows users to enter simple text input, such as their name, description, or a short message. It's a common input type used in forms, surveys, and other data collection applications.",
    image: textInputImage,
  },
  {
    label: {
      en: "Text-Editor",
      de: "Text-Editor",
    },
    value: "textarea",
    message:
      "The Text Editor type allows users to input, modify, and format text in various ways, such as bold, italic, underline, and font size. It's a more advanced input type than simple text and is commonly used in applications that require users to create and edit documents, articles, or blog posts.",
    image: textEditorImage,
  },
  {
    label: {
      en: "Number",
      de: "Nummer",
    },
    value: "number",
    message:
      "The Number type is used for capturing numerical input from the user. Users can enter whole numbers or decimal values.",
    image: numberInputImage,
  },
  {
    label: {
      en: "Location",
      de: "Adresse",
    },
    value: "address",
    message:
      "The Location type allows users to input a location by entering an address or by searching for it using Google Maps.",
    image: locationInputImage,
  },
  {
    label: { en: "Date", de: "Datum" },
    value: "date",
    message:
      "The Date type allows users to pick a specific date from a calendar, including the day, month, and year.",
    image: dateInputImage,
  },
  {
    label: { en: "Date (Year Only)", de: "Datum (nur Jahr)" },
    value: "date-year",
    message:
      "The Date (Year Only) type allows users to pick a specific year from a calendar",
    image: dataYearInputImage,
  },
  {
    label: { en: "Date (Future)", de: "Datum (Zukunft)" },
    value: "date-future",
    message:
      "The Date (Future) type allows users to pick a future date from a calendar, including the day, month, and year.",
    image: dateFuturInputImage,
  },
];

const AddOptionCustomPropertiesContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [customProperties, setCustomProperties] = useState([]);
  const [selectedOptionalProperties, setSelectedOptionalProperties] = useState(
    []
  );
  const currentVersionState = useSelector((state) => state.currentVersion);

  const getPropertyType = (property) => {
    if (property.nodeType === "textarea") {
      return "Text-Editor";
    } else if (property.nodeType === "text") {
      return "Text";
    } else if (property.nodeType === "address") {
      return "Location";
    } else if (property.nodeType === "date") {
      return "Date";
    } else if (property.nodeType === "area") {
      return "Area";
    } else if (property.nodeType === "number") {
      return "Number";
    } else if (property.nodeType === "boolean") {
      return "Check";
    } else if (property.nodeType === "date-year") {
      return "Date (Year Only)";
    } else if (property.nodeType === "date-future") {
      return "Date (Future)";
    }
    else if (property.nodeType === "parking-number"){
      return "Parking"
    }
    else if (property.nodeType === "floor-number"){
      return "Floor"
    }
  };


  useEffect(() => {
    setCustomProperties([{ name: '', type: 'text', id: Date.now() }]);
  }, []);

  const addCustomProperties = () => {
    setCustomProperties((prev) => {
      const temp = [...prev];
      temp.push({
        name: '',
        type: 'text',
        id: Date.now(),
      });
      return temp;
    });
  };

  const removeCustomProperties = (obj) => {
    setCustomProperties((prev) => {
      let temp = prev.filter((f) => f.id !== obj.id);
      return temp;
    });
  };

  const getCustomPropertiesText = (value, item, label) => {
    setCustomProperties((prev) => {
      let temp = prev.map((m) => {
        if (m.id === item.id) {
          m[`${label}`] = value;
        }
        return m;
      });
      return temp;
    });
  };

  const colllectOptionalProperties = (value, e) => {
    setSelectedOptionalProperties((prev) => {
      let temp = [];
      if (e.target.checked) {
        temp = prev.filter((f) => f.nodeName !== value.nodeName);
        temp.push({ ...value, required: true });
      } else {
        temp = prev.filter((f) => f.nodeName !== value.nodeName);
      }
      return temp;
    });
  };

  const collectOptionalAndCustomProperties = () => {
    let temp = [];
    const startingOrder = props.requiredProperties.length + props.data.length;
    if (customProperties.length > 0) {
      temp = customProperties
        .filter((m) => !!m.name)
        .map((m, index) => {
          return {
            type: 'properties',
            nodeIdent: '',
            nodeName: m.name.trim(),
            nodeType: m.type,
            nodeValue: [],
            nodeOrder: index + 1 + startingOrder,
            custom: true,
            required: false,
            currentVersion: currentVersionState.currentVersion?.ident,
            labelTranslation: [
              {
                type: 'title',
                title: m.name,
                language: 'de',
                value: m.name,
              },
              {
                type: 'title',
                title: m.name,
                language: 'en',
                value: m.name,
              },
            ],
          };
        });
    }

    if (selectedOptionalProperties.length > 0) {
      let arr = selectedOptionalProperties.map((m,index) => {
        return {
          type: 'properties',
          nodeIdent: m.nodeIdent,
          nodeName: m.nodeName,
          nodeType: m.nodeType,
          nodeValue: [],
          nodeOrder: temp.length + index + 1 + props.requiredProperties.length,
          custom: false,
          required: true,
          currentVersion: currentVersionState.currentVersion?.ident,
        };
      });

      temp = [...temp, ...arr];
    }
    props.onAddOptional(temp);
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'md'}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: 'hidden' }}
    >
      <AppBar className={'appBar'}>
        <Toolbar className={'dialog-toolbar'}>
          {getDialogBoxHeader('_properties')}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => props.onClose()}
            aria-label="close"
            style={{ marginLeft: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers className={'custom-scrollBar'}>
        <Card className={'m-1'}>
          <CardContent>
            <Box style={{ display: 'flex' }}>
              <Box
                style={{ width: '100%' }}
                className={'d-flex align-items-center'}
              >
                <>
                  <List className='w-100'>
                    <Box>{getOverlineText('Add Custom Properties')}</Box>
                    {customProperties.length === 0 && (
                      <Box
                        className={
                          'w-100 d-flex align-items-center justify-content-center'
                        }
                      >
                        <CustomButton
                          id={`add-optional-custom-button`}
                          buttonText={'_add-more'}
                          endIcon={
                            <AddIcon
                              style={{
                                color: '#d08f8e',
                                width: 20,
                                height: 20,
                              }}
                            />
                          }
                          variant="button"
                          className={'g-btn-secondary'}
                          style={{ margin: 5, height: 30 }}
                          tooltip={{
                            title: getIntlTranslation(
                              'add_custom_properties_placeholder'
                            ),
                          }}
                          handleClick={(e) => addCustomProperties()}
                        />
                      </Box>
                    )}

                    {customProperties?.map((item, index) => {
                      return (
                        <>
                          <ListItem className="d-flex justify-content-between p-1 m-1 w-100 align-items-center">
                            <TextField
                              multiline
                              maxRows={4}
                              id={`${item.name}_${index}`}
                              style={{ margin: 10, width: 300 }}
                              value={item.name}
                              onChange={(e) =>
                                getCustomPropertiesText(
                                  e.target.value,
                                  item,
                                  'name'
                                )
                              }
                              onBlur={(e) =>
                                getCustomPropertiesText(
                                  e.target.value,
                                  item,
                                  'name'
                                )
                              }
                              label={getLabel('_item_name')}
                              variant="standard"
                            />
                            {props.isPropertyTypeRequired && (
                              <Autocomplete
                                id="custom properties type"
                                options={propertyType}
                                value={
                                  !!item.type
                                    ? propertyType.find(
                                        (f) =>
                                          f.value === item.type &&
                                          !!f.label[scope.projectLanguageCode]
                                      )
                                    : null
                                }
                                getOptionLabel={(option) =>
                                  option.label[scope.projectLanguageCode]
                                }
                                sx={{ width: 300 }}
                                onChange={(event, newValue) => {
                                  if (!!newValue) {
                                    getCustomPropertiesText(
                                      newValue.value,
                                      item,
                                      'type'
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label={getLabel('_item_type')}
                                    style={{ margin: 10 }}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            )}
                            {customProperties.length > 0 ? (
                              <CustomIconButton
                                id={`${index}_item_delete`}
                                icon={
                                  <DeleteForeverIcon
                                    style={{ color: '#f50057' }}
                                  />
                                }
                                permission={null}
                                handleClick={(e) => {
                                  removeCustomProperties(item);
                                }}
                                tooltip={{
                                  title: getIntlTranslation('tooltip.delete'),
                                }}
                                style={{ margin: 15 }}
                                aria-describedby={`${index}_item_delete`}
                              />
                            ) : (
                              <div style={{ margin: 15 }}></div>
                            )}
                          </ListItem>
                          {index === customProperties.length - 1 && (
                            <Box
                              className={
                                'w-100 d-flex align-items-center justify-content-center'
                              }
                            >
                              <CustomButton
                                id={`add-optional-custom-button`}
                                buttonText={'_add-more'}
                                endIcon={
                                  <AddIcon
                                    style={{
                                      color: '#d08f8e',
                                      width: 20,
                                      height: 20,
                                    }}
                                  />
                                }
                                variant="button"
                                className={'g-btn-secondary'}
                                style={{ margin: 5, height: 30 }}
                                tooltip={{
                                  title: getIntlTranslation(
                                    'add_custom_properties_placeholder'
                                  ),
                                }}
                                handleClick={(e) => addCustomProperties()}
                              />
                            </Box>
                          )}
                        </>
                      );
                    })}
                  </List>
                </>
              </Box>
            </Box>
          </CardContent>
        </Card>
        {props.data.length > 0 && (
          <Card className={'m-1'}>
            <CardContent>
              <Box style={{ marginTop: 10 }}>
                {getOverlineText('Optional Properties')}
                <List
                  className="d-flex flex-wrap"
                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                >
                  {props.data?.map((value) => {
                    const labelId = `checkbox-list-label-${value.nodeName}`;
                    return (
                      <ListItem key={value.nodeName} style={{ width: '25%' }}>
                        <ListItemIcon style={{ minWidth: 'auto' }}>
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            checked={selectedOptionalProperties.some(
                              (v) =>
                                v.nodeName === value.nodeName &&
                                v.required === true
                            )}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onChange={(e) =>
                              colllectOptionalProperties(value, e)
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography variant="subtitle2">
                              {
                                value?.labelTranslation?.find(
                                  (e) =>
                                    e.language === scope.projectLanguageCode
                                ).title?.toUpperCase()
                              }{" "}
                              ({getPropertyType(value)})
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions className={"w-100 d-flex align-items-center justify-content-between"}>
          <List
            style={{
              width: "90%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "left",
              backgroundColor: "#C1D0F0",
            }}
          >
            {propertyType.map((element, index) => (
              <ListItem
                style={{
                  width: "fit-content",
                }}
              >
                <PopoverInfo
                  key={element.label[scope.projectLanguageCode]}
                  label={element.label[scope.projectLanguageCode]}
                  message={element.message}
                  image={element.image}
                />
              </ListItem>
            ))}
          </List>
        <CustomButton
          id={`add-options-custom-button`}
          buttonText={'save'}
          variant="button"
          style={{ margin: 5}}
          tooltip={{
            title: getIntlTranslation('tooltip.Save'),
          }}
          handleClick={(e) => collectOptionalAndCustomProperties()}
        />
      </DialogActions>
    </Dialog>
  );
};
AddOptionCustomPropertiesContainer.defaultProps = {
  isPropertyTypeRequired: true,
};

AddOptionCustomPropertiesContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.array,
  onAddOptional: PropTypes.func,
  requiredProperties:PropTypes.array,
  isPropertyTypeRequired: PropTypes.bool,
};

export default React.memo(AddOptionCustomPropertiesContainer);
