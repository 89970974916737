import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText } from "@mui/material";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import {
  getVariantText,
  getTranslationVariantText
} from "../../../utils/helper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterAltIcon from '@mui/icons-material/FilterAlt';




const SortingMenu = (props) => {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={props.menuAnchor?.anchor}
        id="sorting-filtering-menu"
        open={Boolean(props.menuAnchor?.anchor)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={(e) => {
          props.sortAsc(props.menuAnchor.column)
          props.onClose();
        }}>
          <ListItemIcon className="p-1">
            <ArrowUpwardIcon style={{ fontSize: "20", color: "#0000008a" }} />
          </ListItemIcon>
          <ListItemText primary={getTranslationVariantText("_sort-by-asc")} />
        </MenuItem>
        <MenuItem onClick={(e) => {
          props.sortDesc(props.menuAnchor.column)
          props.onClose();
        }}>
          <ListItemIcon className="p-1">
            <ArrowDownwardIcon style={{ fontSize: "20", color: "#0000008a" }} />
          </ListItemIcon>
          <ListItemText primary={getTranslationVariantText("_sort-by-desc")} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => {
          props.onFilterSelect(props.menuAnchor);
          props.onClose();
        }}>
        <ListItemIcon className="p-1">
            <FilterAltIcon style={{ fontSize: "20", color: "#0000008a" }} />
          </ListItemIcon>
          <ListItemText primary={getVariantText("Filter")} />
          </MenuItem>
      </Menu>
      </React.Fragment>
  );
};

SortingMenu.propTypes = {
  menuAnchor: PropTypes.shape({
    anchor: PropTypes.object,
      column: PropTypes.shape({
        columnName: PropTypes.string,
        type: PropTypes.string,
      }),
      dataList:PropTypes.array,
  }),
  onClick: PropTypes.func,
  sortAsc: PropTypes.func,
  sortDesc: PropTypes.func,
  onFilterSelect: PropTypes.func,
};

export default SortingMenu;
