import Box from '@mui/material/Box';
import React from 'react';
import UserProjects from './structure/UserProjects';
import { UserDashBoardContextProvider } from './structure/UserDashBoardContext';

const UserDashBoardHome = () => {
  return (
    <>
      <div
        style={{ display: 'flex', height: '100%', justifyContent: 'center' }}
      >
        <Box
          style={{ width: '100%', height: '100%' }}
          className={'d-flex align-items-center justify-content-center'}
        >
          <UserDashBoardContextProvider>
          <UserProjects />
          </UserDashBoardContextProvider>
        </Box>
      </div>
    </>
  );
};

export default UserDashBoardHome;
