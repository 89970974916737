import { Buffer } from "buffer";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  Chip,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import botAvatar from "../../assets/images/botAvatar.png"; 
import { authHeader, getCurrentUser } from "../../authentication/authTokenService"; 
import { DotLoader } from "react-spinners";
import ReactMarkdown from "react-markdown";
import { getTranslationVariantText } from "../../utils/helper"; 
import { NON_PARSABLE_FILES } from "../../utils/constants"; 
import { v4 as uuidv4 } from 'uuid'; 


// Set the worker globally
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// Define approximate token counting function
const getTokenCount = (text) => Math.ceil(text.length / 4);

const ChatWithPdf = () => {
  const { pdfLink } = useParams();
  const decodedPdfLink = Buffer.from(pdfLink, "base64").toString("utf-8");

  // State variables
  const [chatInput, setChatInput] = useState("");
  const [chatMessages, setChatMessages] = useState([
    { id: uuidv4(), user: false, message: "How can I help you today?" },
  ]);
  const [pdfText, setPdfText] = useState("");
  const [relevantPages, setRelevantPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPdfTooLarge, setIsPdfTooLarge] = useState(false);
  const [isPdfNotSearchable, setIsPdfNotSearchable] = useState(false);
  const [processing, setProcessing] = useState(true); 

  const user = getCurrentUser();

  const chatContainerRef = useRef(null);
  const zoomPluginInstance = zoomPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  /**
   * Function to transform toolbar slots.
   * This maintains existing configurations and avoids introducing errors.
   */
  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  // Define maximum token limit based on your model
  const MAX_TOKEN_LIMIT = 100000; // Adjust based on your model's capability

  /**
   * Function to extract text from the PDF.
   * It also checks if the PDF is too large or not searchable.
   */
  const extractTextFromPdf = async (url) => {
    try {
      const loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;
      let textContent = "";

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const text = await page.getTextContent();
        const pageText = text.items.map((item) => item.str).join(" ");
        textContent += `\n\n--- Page ${i} ---\n\n` + pageText;

        const currentTokenCount = getTokenCount(textContent);
        if (currentTokenCount > MAX_TOKEN_LIMIT) {
          setIsPdfTooLarge(true);
          break;
        }
      }

      setPdfText(textContent);

      const tokenCount = getTokenCount(textContent);
      console.log(`PDF Token Count: ${tokenCount}`);

      if (tokenCount > MAX_TOKEN_LIMIT) {
        setIsPdfTooLarge(true);
      }

      const pdfFileName = decodedPdfLink.split("/").pop();

      if (NON_PARSABLE_FILES.includes(pdfFileName)) {
        setIsPdfNotSearchable(true);
        return;
      }

      const wordCount = textContent
        .split(/\s+/)
        .filter((word) => word.length > 3).length;

      if (wordCount < 50) {
        setIsPdfNotSearchable(true);
      }
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
      setChatMessages((prev) => [
        ...prev,
        {
          id: uuidv4(),
          user: false,
          message:
            "Failed to extract text from the PDF. Please ensure the PDF is searchable.",
        },
      ]);
      setIsPdfNotSearchable(true);
    } finally {
      setProcessing(false);
    }
  };

  // Extract text when the PDF link changes
  useEffect(() => {
    if (decodedPdfLink) {
      extractTextFromPdf(decodedPdfLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedPdfLink]);

  // Scroll to the bottom of the chat when messages update
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  /**
   * Function to handle sending messages to OpenAI.
   * It sends the extracted PDF text along with the user's question.
   */
  const handleSendMessage = async () => {
    if (chatInput.trim() === "") return;
  
    // Prevent sending a new message while already loading
    if (loading) return;
  
    const updatedMessages = [
      ...chatMessages,
      { id: uuidv4(), user: true, message: chatInput },
    ];
    setChatMessages(updatedMessages);
    setChatInput("");
    setLoading(true);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/openai/chat`,  
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader(getCurrentUser().token)
          },
          body: JSON.stringify({
            message: chatInput, 
            pdfContent: pdfText, 
        }),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response from the backend:", errorData);
  
        // Handle specific error codes
        if (errorData.code === "rate_limit_exceeded") {
          setChatMessages((prevMessages) => [
            ...prevMessages,
            {
              id: uuidv4(),
              user: false,
              message:
                "Your request exceeds the token per minute limit. Please wait a moment before sending another message.",
            },
          ]);
        } else if (errorData.code === "model_not_found") {
          setChatMessages((prevMessages) => [
            ...prevMessages,
            {
              id: uuidv4(),
              user: false,
              message:
                "The specified AI model is not available. Please contact support or try a different model.",
            },
          ]);
        } else {
          setChatMessages((prevMessages) => [
            ...prevMessages,
            {
              id: uuidv4(),
              user: false,
              message:
                "There was an error processing your request. Please try again.",
            },
          ]);
        }
  
        throw new Error("Backend API error");
      }
  
      const data = await response.json();
      const reply = data.choices[0].message.content;
  
      // Extract page numbers from the reply
      const pageMatches = [...reply.matchAll(/page (\d+)/gi)];
      const pages = pageMatches.map((match) => match[1]);
  
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { id: uuidv4(), user: false, message: reply },
      ]);
  
      setRelevantPages(pages);
    } catch (error) {
      console.error("Error with the backend API:", error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          id: uuidv4(),
          user: false,
          message: "There was an error processing your request. Please try again.",
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function to handle Enter key press in the chat input.
   * Sends the message when Enter is pressed without Shift.
   */
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  /**
   * Function to render individual chat messages with avatars.
   */
  const renderMessage = (msg) => {
    return (
      <Box
        key={msg.id}
        display="flex"
        flexDirection="row"
        justifyContent={msg.user ? "flex-end" : "flex-start"}
        mb={2}
      >
        <Box display="flex" alignItems="flex-start" sx={{ maxWidth: "80%" }}>
          {msg.user ? (
            <Box display="flex" flexDirection="row">
              <Card
                sx={{
                  padding: "10px",
                  borderRadius: "15px",
                  backgroundColor: "#E0F2FF",
                  boxShadow: "0px 4px 10px rgba(0, 123, 255, 0.2)",
                  marginRight: "20px", // Increased right margin
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#3f51b5",
                  }}
                >
                  {msg.message}
                </Typography>
              </Card>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#3f51b5",
                  color: "#fff",
                  marginRight: "10px",
                }}
              >
                {user.data.name.charAt(0).toUpperCase()}
              </Avatar>
            </Box>
          ) : (
            <Box display="flex" flexDirection="row">
              <Avatar
                alt="Bot"
                src={botAvatar}
                sx={{ marginRight: "10px", width: 40, height: 40 }}
              />
              <Card
                sx={{
                  padding: "10px",
                  borderRadius: "15px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: "15px",
                    color: "#333",
                  }}
                >
                  <ReactMarkdown>{msg.message}</ReactMarkdown>
                </Box>
              </Card>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  /**
   * Function to render a typing/loading indicator.
   */
  const renderTypingIndicator = () => (
    <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
      <Avatar
        alt="Bot"
        src={botAvatar}
        sx={{ marginRight: "10px", width: 40, height: 40 }}
      />
      <DotLoader size={30} color="#3f51b5" />
    </Box>
  );

  /**
   * Function to render the warning message when the PDF is too large.
   */
  const renderLargePdfWarning = () => (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#fff3cd",
        border: "1px solid #ffeeba",
        borderRadius: "5px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {getTranslationVariantText("chat-with-pdf.warning-large-token", "label")}
      </Typography>
    </Box>
  );

  /**
   * Function to render the warning message when the PDF is not searchable.
   */
  const renderNonSearchablePdfWarning = () => (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#f8d7da",
        border: "1px solid #f5c6cb",
        borderRadius: "5px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {getTranslationVariantText("chat-with-pdf.warning-not-searchable-by-text", "label")}
      </Typography>
    </Box>
  );

  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      flexDirection="row"
      gap={2}
      p={2}
    >
      {/* Chat Interface (left side) */}
      <Box
        width="35%"
        display="flex"
        flexDirection="column"
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          padding: "16px",
          backgroundColor: "white",
          height: "calc(100vh - 120px)",
          boxSizing: "border-box",
          position: "relative", 
        }}
        className="custom-scrollBar"
      >
        <Typography variant="h6" sx={{ marginBottom: "16px" }}>
          {getTranslationVariantText("chat-with-pdf.title", "subtitle", {
            color: "#254a9a",
            fontWeight: "bold",
          })}
        </Typography>

        {/* Processing Overlay */}
        {processing && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 10,
              borderRadius: 2,
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress />
              <Typography variant="body1" sx={{ marginTop: "10px" }}>
                Processing PDF, please wait...
              </Typography>
            </Box>
          </Box>
        )}

        {/* Chat Messages */}
        <Box
          ref={chatContainerRef}
          sx={{ flexGrow: 1, overflowY: "auto", marginBottom: "16px" }}
          className="custom-scrollBar"
        >
          {isPdfTooLarge && renderLargePdfWarning()}
          {isPdfNotSearchable && renderNonSearchablePdfWarning()}
          {chatMessages.map((msg) => renderMessage(msg))}
          {loading && renderTypingIndicator()}
        </Box>

        {/* Relevant Pages */}
        {relevantPages.length > 0 && (
          <Box sx={{ marginTop: "8px", marginBottom: "16px" }}>
            <Typography variant="subtitle1">Relevant Pages:</Typography>
            {relevantPages.map((page, index) => (
              <Chip
                key={index}
                label={`Page ${page}`}
                sx={{ marginRight: "4px", marginBottom: "4px" }}
              />
            ))}
          </Box>
        )}

        {/* Chat Input */}
        <TextField
          fullWidth
          label={getTranslationVariantText("chat-with-pdf.question", "label")}
          variant="outlined"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{
            marginBottom: "8px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#808080",
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#3f51b5",
            },
          }}
          disabled={isPdfTooLarge || isPdfNotSearchable || loading} 
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          disabled={isPdfTooLarge || isPdfNotSearchable || loading} 
        >
          {getTranslationVariantText("chat-with-pdf.send", "button")}
        </Button>
      </Box>

      {/* PDF Viewer (right side) */}
      <Box
        width="65%"
        display="flex"
        flexDirection="column"
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          padding: "16px",
          backgroundColor: "white",
          height: "calc(100vh - 120px)",
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        {decodedPdfLink ? (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
            <div
              className="rpv-core__viewer"
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                boxSizing: "border-box",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "#eeeeee",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  padding: "0.25rem",
                  boxSizing: "border-box",
                }}
              >
                <Toolbar>
                  {toolbarPluginInstance.renderDefaultToolbar(transform)}
                </Toolbar>
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: "auto",
                  boxSizing: "border-box",
                }}
                className="custom-scrollBar"
              >
                <Viewer
                  fileUrl={decodedPdfLink}
                  plugins={[toolbarPluginInstance, zoomPluginInstance]}
                />
              </div>
            </div>
          </Worker>
        ) : (
          <Typography align="center" sx={{ marginTop: "20px" }}>
            {getTranslationVariantText("chat-with-pdf.select_file", "label")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ChatWithPdf;
