import { Box, TextField } from '@mui/material'
import React, { useState } from 'react'

export const TextInput = ({ id, key, disabled=false, node, handleChange}) => {
  const [value, setValue] = useState(node.value);
  return (
    <Box className="d-flex">
          <TextField
            fullWidth
            id={id}
            disabled={disabled}
            key={key}
            value={value}
            type={"text"}
            multiline
            minRows={2}
            maxRows={5}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => {
              if(e.target.value !== node.value){
                handleChange(e.target.value, node, true);
              }
            }
          }
            variant="outlined"
            size="small"
            dense="true"
            InputLabelProps={{
                shrink: true,
            }}
          />
        </Box>
  )
}

export default TextInput
