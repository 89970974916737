import React from "react";
import PropTypes from "prop-types";
import { Divider ,Box} from "@mui/material";

import { FastField } from "formik";
import { getLabel, getIntlTranslation } from "../../../../utils/helper";
import TextFieldWrapper from "./TextFieldWrapper"


function SourceDataRoomSection(props) {
  const { values,touched, errors,setFieldValue} = props;
  return (
    <>
    <Divider style={{ margin: "5px 2px", width: "100%" }} className="g-divider-vertical"/>
    <Box  style={{position:"sticky", backgroundColor:"#ffffff" ,bottom:0, zIndex:1500, borderRadius:5 }}>
    <Box className={"d-flex align-items-center justify-content-between"} style={{width:"98%", marginLeft:20}}>
    <FastField name="quelle">
    {() => {
      return (
        <TextFieldWrapper
          name="quelle"
          label={getIntlTranslation("_quelle")}
          style={{width:"33%", margin:5}}
          size="small"
          id="quelle_01"
          value={values.quelle}
          onChange={(e) =>
            setFieldValue("quelle", e.target.value)
          }
          error={touched.quelle && errors.quelle}
          helperText={touched.quelle && errors.quelle}
        /> );
      }}
    </FastField>
    <FastField name="dataRoomIndex">
    {() => {
      return (
        <TextFieldWrapper
        style={{width:"33%", margin:5}}
          name="dataRoomIndex"
          label={getIntlTranslation("_Data-Room-Index")}
          size="small"
          id="dataRoomIndex_01"
          value={values.dataRoomIndex}
          onChange={(e) =>
            setFieldValue("dataRoomIndex", e.target.value)
          }
          error={touched.dataRoomIndex && errors.dataRoomIndex}
          helperText={touched.dataRoomIndex && errors.dataRoomIndex}
        /> );
      }}
      </FastField>
      <FastField name="questionAndAnswer">
    {() => {
      return (
        <TextFieldWrapper
          name="questionAndAnswer"
          label={getIntlTranslation("_Q&A")}
          style={{width:"33%", margin:5}}
          size="small"
          id="questionAndAnswer_01"
          value={values.questionAndAnswer}
          onChange={(e) =>
            setFieldValue("questionAndAnswer", e.target.value)
          }
          error={touched.questionAndAnswer && errors.questionAndAnswer}
          helperText={touched.questionAndAnswer && errors.questionAndAnswer}
        />
        );
      }}
    </FastField>
      </Box>
     <Box className={"d-flex"} style={{width:"98%", marginLeft:20}}>
        <FastField name="comments">
        {() => {
          return (
            <TextFieldWrapper
          name="comments"
          label={getIntlTranslation("_Comments")}
          style={{width:"100%", margin:5}}
          multiline
          maxRows={10}
          value={values.comments}
          id="comments_01"
          onChange={(e) =>
            setFieldValue("comments", e.target.value)
          }
          size="small"
          error={touched.comments && errors.comments}
          helperText={touched.comments && errors.comments}
        />
        );
      }}
    </FastField>
      </Box>
      </Box>
      </>
  );
}

SourceDataRoomSection.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
};

export default SourceDataRoomSection;
