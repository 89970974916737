import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const InjectMessage = (props) => {
  return <FormattedMessage id={props.id} {...props} />;
};

export default injectIntl(InjectMessage, {
  withRef: false,
});
