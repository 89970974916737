import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const dropdown = {
  margin: 5,
  backgroundColor: "#fff",
  color: "#000000",
  borderRadius: 5,
};

const NoPaddingAutocomplete = styled({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"]': {
      padding: "3px !important"
    }
    },
  input: {},
})(Autocomplete);

const FilterMultiDropdownEditor = (props) => {
  return (
    <>
    
    <Autocomplete
          multiple={props.multiple}
          limitTags={2}
          id={`checkboxes-${props.type}-filter`}
          options={props.options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => {
            if (!!newValue) {
                props.applyFilter(newValue);
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <>
              <Typography variant="body1" style={{margin:1, fontWeight:"bold"}}>{option.title}</Typography>
              {index < value.length -1 && <>,</>}
              </>
            ))
          }
          style={{...dropdown, width: !!props.width? props.width : "33%"}}
          renderInput={(params) => (
            <TextField {...params} label={props.placeholder} size="small" dense={"true"}/>
          )}
        />
    </>
     )
};

FilterMultiDropdownEditor.defaultProps = {
multiple:true,
}
FilterMultiDropdownEditor.propTypes = {
  header: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  applyFilter:PropTypes.func,
  width: PropTypes.string
};

export default FilterMultiDropdownEditor;
