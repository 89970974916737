import React from "react";
import {
  Card,
  CardContent,
  List,
  ListItemButton,
  Box,
  Divider,
} from "@mui/material";
import {
  getTranslationVariantText,
  getVariantText,
} from "../../../../../utils/helper";
import { usePhotoDocumentationContextHook } from "../PhotoDocumentationContext";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import { useDispatch, useSelector } from "react-redux";
import { setOpenAsset } from "../../../../component-bundle/assetSwitcher/OpenAssetSlice";
import { setCurrentVersion } from "../photoDocumentationSlice";

const SubAssetContainer = (props) => {
  const dispatch = useDispatch();
  const {
    subAssetList,
    selectedSubAsset,
    prepareSubAssetData,
    getWarningText,
    setBlockAssetChange,
    blockAssetChange,
  } = usePhotoDocumentationContextHook();
  const readOnlystate = useSelector((state) => state.readOnly);
  return (
    <>
      <Card className="w-100 h-100 p-1">
        <CardContent className="custom-scrollBar w-100 p-1 overflow-x-hidden overflow-y-auto">
          <List>
            {subAssetList?.map((m) => {
              return (
                <>
                  <ListItemButton
                    selected={
                      selectedSubAsset?.subAssetIdent === m.subAssetIdent
                    }
                    className="w-100 p-1 justify-content-center align-items-center"
                    onClick={(e) => prepareSubAssetData({ ...m })}
                  >
                    {selectedSubAsset?.subAssetIdent === m.subAssetIdent && (
                      <div style={{ minWidth: 25 }}>
                        <DoubleArrowIcon
                          style={{ color: "#5f9ea0", fontSize: 20 }}
                        />
                      </div>
                    )}

                    <Box
                      className="d-flex flex-wrap align-items-center justify-content-start"
                      style={{
                        margin: "4px 0px",
                        width: "100%",
                        marginLeft: "auto",
                      }}
                    >
                      {getVariantText(m.name, "caption", {
                        fontWeight: "bold",
                        color: "#254a9a",
                        margin: 5,
                        textTransform: "uppercase",
                      })}
                    </Box>
                  </ListItemButton>
                  <Divider component="li" />
                </>
              );
            })}
          </List>
        </CardContent>
      </Card>
      {!readOnlystate.isReadOnly && blockAssetChange.block && (
        <InternalPrompt
          when={blockAssetChange.block}
          stayText={"_back"}
          saveText="Save And Leave"
          leaveText={"_continue"}
          onLeave={() => {
            dispatch(setCurrentVersion({}));
            dispatch(setOpenAsset(blockAssetChange.newAsset));
            setBlockAssetChange({
              block: false,
              newAsset: {},
            });
          }}
          onStay={() =>
            setBlockAssetChange({
              block: false,
              newAsset: {},
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText()}
        />
      )}
    </>
  );
};

SubAssetContainer.propTypes = {};

export default SubAssetContainer;
