import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../api/customAxios";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import { ERROR, FETCHED, SUCCESS } from "../../../common/apiStatus";
import { getFailure, getSuccess } from "./costSuggestionSlice";
const interval = 1000;

function useCostSuggestionHook() {
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  
  const onDataFetch = useCallback(async (request) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "get",
          url: `/costs/fetch-cost-list`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
        })
      );
      if (response.data.type === SUCCESS) {
        dispatch(getSuccess({ type: FETCHED, list: response.data.guruDtoList }))
      } else {
        console.log("Error: ", response.data);
        dispatch(getFailure({ type: ERROR, data: response.data }))
      }
    } catch (error) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  },[dispatch]);

  return [onDataFetch];
}

export default useCostSuggestionHook;
