import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { setTab } from "../photoDocumentationSlice";

const options = [
  {
    name: "General Photos",
    key: "general",
  },
  {
    name: "Defect Photos",
    key: "defect",
  },
];

const PhotoDocumentationTabs = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const state = useSelector((state) => state.photoDocumentation);
  const [blockTabChange, setBlockTabChange] = useState({
    block: false,
    newTab: {},
  });

  useEffect(() => {
    if (!value) {
      setValue(options[0]);
      dispatch(setTab(options[0]));
    } else {
      setValue(options.find((f) => f.key === state.tab?.key));
    }
  }, [dispatch, state.tab?.key]);

  const handleChange = (event, newValue) => {
    dispatch(setTab(newValue));
  };
  return (
    <Box style={{ flexGrow: 1, marginBottom: 5 }}>
      <AppBar
        className="appBar"
        style={{ borderRadius:4}}
      >
        <Toolbar style={{ minHeight: 35, padding: 0 }}>
          <Tabs
           className="g-btn-secondary d-flex align-items-center"
           style={{ height: 40, width: "100%", minHeight: 40 }}
           value={value}
           onChange={handleChange}
           indicatorColor="secondary"
           textColor={"#d9534f"}
           TabIndicatorProps={{
             style: {
               backgroundColor: "#d08f8e",
               height: 12,
               borderRadius: 40,
             },
           }}
           variant="scrollable"
           scrollButtons="auto"
           aria-label="scrollable categories"
         >
            {options?.map((m, index) => {
              return (
                <Tab
                style={{
                  color: "#254a9a",
                  height: 40,
                  maxWidth: "fit-content",
                  marginTop: 5,
                }}
                label={
                  <Typography variant="button" display="block" gutterBottom>
                    {m.name}
                  </Typography>
                }
                key={m.key}
                value={m}
              />
)})}
          </Tabs>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

PhotoDocumentationTabs.propTypes = {};

export default PhotoDocumentationTabs;
