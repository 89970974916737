import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  status:null,
  messages: "",
  error: {},
  responseError:false,
};

const errorHandlingSlice = createSlice({
  name: "errorHandlingSlice",
  initialState,
  reducers: {
    getErrorFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      status:payload.status,
      type: payload.type,
      messages: payload.messages,
      error: payload.error,
      responseError:payload.responseError,
    }),
    resetErrorState: (state) => ({
      ...state,
      hasErrors: false,
      status:null,
      type: "",
      messages: "",
      error: {},
      responseError:false,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getErrorFailure,
  resetErrorState,
} = errorHandlingSlice.actions;

// A selector
export const errorHandlingSelector = (state) => state.errorHandling;

// The reducer
export default errorHandlingSlice.reducer;
