import { useMemo } from "react";
import PropTypes from "prop-types";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  elementName: yup.object().shape({
    title: yup.string().required("This is a Required field"),
    language: yup.string().required("This is a Required field"),
    value: yup.string().required("This is a Required field"),
    type:yup.string()
  }),
  riskType: yup.string().required("This is a Required field"),
  defectType: yup.string().required("This is a Required field"),
  priority: yup.string().required("This is a Required field"),
  defectDescription: yup.string().required("This is a Required field"),
  measureDescription: yup.string().required("This is a Required field"),
  pricePerUnit: yup.number().when(["costDefect"], {
    is: true,
    then:() => yup
      .number()
      .required("This is a Required field")
      .min(1)
      .typeError("This should be a valid Cost"),
    otherwise:()=> yup.number().nullable(),
  }),
  quantity:yup.number().when(["costDefect"], {
    is: true,
    then:() => yup
      .number()
      .required("This is a Required field")
      .min(1)
      .typeError("This should be a valid Quantity"),
    otherwise:()=> yup.number().nullable(),
  }),
  extraCostComment: yup.string().when(["extraCost", "costDefect"], {
    is: (extraCost, costDefect) =>
      extraCost != null && Number(extraCost) > 0 && costDefect,
    then:() => yup
      .string()
      .required("This is a Required field")
      .typeError("This is a Required field"),
    otherwise:()=> yup.string().nullable(),
  }),
  unit: yup.string().when(["costDefect"], {
    is: true,
    then:() => yup
      .string()
      .required("This is a Required field")
      .typeError("This should be a valid Quantity"),
    otherwise:() => yup.string().nullable(),
  }),
  customElement: yup.mixed().when(["isElementOther"], {
    is: true,
    then:() => yup.object().shape({
      value: yup.string().required("This is required"),
      title: yup.string().required("This is required"),
    }),
    otherwise:() => yup.mixed().notRequired(),
  }),
});

export const insertSuggestionValues = (values) => {
  const ifExists = !!values.costSuggestion;
  const defectFormData = {
    elementName: values.element.isSonstiges
      ? { value: "Sonstiges", label: "Sonstiges/Others" }
      : values.element,
    customElementName: values.element.isSonstiges ? values.element.value : "",
    customElementNameEn: "",
    constructionYear: values.constructionYear,
    defectDescription: values.defectDescription,
    defectType: values.defectType,
    riskType: "",
    defectIdent: "",
    inRedFlag: false,
    measureDescription: values.measureDescription,
    costElement: values.costElementDescription,
    pricePerUnit:
      ifExists && "Mittel" in values.costSuggestion
        ? values.costSuggestion.Mittel
        : "",
    costSuggestion: values.costSuggestion,
    unit: values.unit,
    quantity: "1",
    priority: "",
    percentageShortTermPriority: 30,
    percentageMidTermPriority: 40,
    percentageLongTermPriority: 30,
    customFactorText: "",
    customFactor: "",
    projectFactor: "1",
    comments: "",
    extraCost: "",
    extraCostComment: "",
  };
  const costCode = {
    defect: values.defectCode,
    measure: values.measureCode,
    costElement: values.costElementCode,
  };
  const selectedUnit = {
    value: values.unit,
    title: values.unit,
  };
  return { defectFormData, costCode, selectedUnit };
};

export const NumericFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        if (floatValue == null) {
          return formattedValue === "";
        } else {
          return floatValue > 0;
        }
      }}
    />
  );
};

NumericFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const NumberFormatForPercentage = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat 
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        if (floatValue == null) {
          return formattedValue === "";
        } else {
          return floatValue <= 100 && floatValue > 0;
        }
      }}
    />
  );
};

NumberFormatForPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export const calulateTotal = (values, includeMultiple) => {
  let total = 0;
  if (values.pricePerUnit > 0 && values.quantity > 0) {
    total = values.pricePerUnit * values.quantity;
    if (values.elementAffected > 0) {
      total = total * (values.elementAffected / 100);
    }
    if (values.extraCost > 0) {
      total = total + Number(values.extraCost);
    }
    if (values.isMultiple && includeMultiple) {
      total =
        Math.round((total * values.multipleCost?.short) / 1000) * 1000 +
        Math.round((total * values.multipleCost?.medium) / 1000) * 1000 +
        Math.round((total * values.multipleCost?.long) / 1000) * 1000;
    }
    return total;
  }
  return total;
};

export const convertToDecimal = (value,isMultiple) => {
  return parseFloat(calulateTotal(value,isMultiple)).toFixed(2)
}

export const createElementDto = (values) => {
  const el = {
    type: "elementDto",
    trade: values.isElementOther ? values.customElement.value : values.elementName.value, 
    name: values.isElementOther ? values.customElement.value : values.elementName.value,
    elementName: values.isElementOther
        ? [
            {
              type: "title",
              title: values.customElement.value,
              language: "de",
              value: values.customElement.value,
            },
            {
              type: "title",
              title: values.customElement.value,
              language: "en",
              value: values.customElement.value,
            },
          ]
        : null,
    constructionYear: !!values.constructionYear ? values.constructionYear : null,
    subCategorySecondaryIdent: values.isElementOther ? values.customElement.parentIdent : values.elementName.parentIdent,
  };
  return el;
};

export const createMeasureDto = (
  values
) => {
  return {
    type: "measureDto",
    measureDescription: values.measureDescription,
    pricePerUnit: values.pricePerUnit,
    quantity: values.quantity,
    priority: values.priority,
    percentageShortTermPriority: values.percentageShortTermPriority,
    percentageMidTermPriority: values.percentageMidTermPriority,
    percentageLongTermPriority: values.percentageLongTermPriority,
    extraCostComment: values.extraCostComment,
    extraCost: values.extraCost,
    elementAffected: values.elementAffected,
    probabilityOfOccurrence: values.probabilityOfOccurrence,
    comments: values.comments,
    unit: values.unit,
    active: true,
    totalCost: calulateTotal(values,false),
    code: values.costParameters?.code,
    costElement: values.costParameters?.name,
  };
};

export const createDefectDto = (
  values,
) => {
  return {
    type: "defectDto",
    risk: values.riskType,
    inRedFlag: values.inRedFlag,
    defect: values.defectDescription,
    defectType: values.defectType,
    costDefect: values.costDefect,
    dataRoomIndex: values.dataRoomIndex,
    quelle: values.quelle,
    questionAndAnswer: values.questionAndAnswer,
    code: null,
  };
};

export const getLanguageBasedOption = (option, langCode) => {
  if (option !== null && option !== "" && option.title !== undefined) {
    const tOption = option.title[langCode];
    if (tOption !== null) {
      return tOption;
    } else {
      return option.title["de"];
    }
  } else {
    return "";
  }
};

export const getDefaultSelectionRecommendations = (defectType) =>
{
  if(defectType ==="Lebenszyklus"){
    return {
      defectType : "Lebenszyklus",
      risk:[
        {
          type:"niedrig",
          info:"Life cycle Y2-10 (end of life span in the time of consideration)"
        },
        {
          type:"mittel",
          info:"Life cycle Y0-1 (end of life span already exceeded)"
        },
      ]
      
    }
  }

}

export const setDefaultValues = (defectType) =>{

  if(defectType ==="Lebenszyklus"){
    return {
      defectType : "Lebenszyklus",
      risk:"niedrig",
      priority:"langfristig",
      isRedFlag:false
    }
  } else if(defectType ==="Konformität"){
    return {
      defectType : "Konformität",
      risk:"hoch",
      priority:"kurzfristig",
      isRedFlag:true
    }
  } else if(defectType ==="Prävention"){
    return {
      defectType : "Prävention",
      risk:"niedrig",
      priority:"kurzfristig",
      isRedFlag:false
    }
  }else{
    return {
      defectType : "Defekt",
      risk:"mittel",
      priority:"kurzfristig",
      isRedFlag:false
    }
  }

}
export const setDefaultRiskTypeValues = (riskType) =>{

  if(riskType ==="hoch"){
    return {
      isRedFlag:true
    }
  } else{
    return {
      isRedFlag:false
    }
  }
}
export const formData = {
  elementName: {
    title: "",
    language: "",
    value: "",
    parentIdent:"",
  },
  isElementOther: false,
  customElement: {
    value: "",
    title: {
      de: "",
      en: "",
    },
    parentIdent:"",
  },
  constructionYear: null,
  defectDescription: "",
  defectType: "Defekt",
  riskType: "mittel",
  inRedFlag: false,
  defectIdent: "",
  costDefect: true,
  dataRoomIndex: "",
  quelle: "",
  questionAndAnswer: "",
  measureDescription: "",
  pricePerUnit: null,
  unit: "",
  quantity: 1,
  priority: "kurzfristig",
  isMultiple: false,
  multipleCost: {
    short: Number(30) / 100,
    medium: Number(40) / 100,
    long: Number(30) / 100,
  },
  percentageShortTermPriority: 30,
  percentageMidTermPriority: 40,
  percentageLongTermPriority: 30,
  projectFactor: "",
  elementAffected: "",
  probabilityOfOccurrence: "",
  extraCost:"",
  extraCostComment:"",
  measureIdent: "",
  comments: "",
  totalCost: "",
  costSuggestions:[],
  costParameters: null,
};
