import { createContext, useContext, useEffect, useState } from "react";
import {
  loadUserProjects,
  markProjectFavorite,
} from "../../api/userProjectsApi";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../userProjectSlice";
import { setProjectOpen } from "../../layout/app-container/containerSlice";
import { serviceLines } from "../../utils/defaultValues";
import { useNavigate } from "react-router-dom";
import useFetchCommentsHook from "../../guru-suite/component-bundle/firebase/useFetchCommentsHook";

export const UserDashBoardContext = createContext(undefined);
export const useUserDashBoardContextHook = () => {
  const context = useContext(UserDashBoardContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

export const UserDashBoardContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.userprojects);
  const [projectList, setprojectList] = useState([]);
  const { onFetch } = useFetchCommentsHook();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(loadUserProjects());
  }, [dispatch]);

  useEffect(() => {
    if (!!state && state.list.length > 0) {
      const tempProject = [];
      state.list.forEach(async (project) => {
        try {
          let data = await onFetch(project.projectIdent);
          if (!!data) {
            setprojectList((prev) => {
              return [...prev].map((p) => {
                if (p.projectIdent === project.projectIdent) {
                  p = { ...p, comments: [...data?.comments] };
                }
                return p;
              });
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      });
      setprojectList(state.list);
      dispatch(resetState());
    }
  }, [dispatch, state]);

  const markProjectFav = (p) => {
    dispatch(
      markProjectFavorite({ ...p, isFavoriteProject: !p.isFavoriteProject })
    );
  };

  const openProject = (data) => {
    const found = serviceLines.find(
      (f) => f.name.toLowerCase() === data.serviceLine.toLowerCase()
    );
    if (!!found) {
      dispatch(
        setProjectOpen({
          serviceLine: data.serviceLine.toLowerCase(),
          api: found.api,
          isProjectScope: true,
          projectIdent: data.projectIdent,
          isReadOnly: data.isReadOnly,
          projectName: data.projectName,
          currentProjectRole: "Default",
          projectLanguageCode: !!data.languageCode ? data.languageCode : "de",
        })
      );
      navigate(`${found.root}/project/project-dashboard`);
    }
  };
  return (
    <UserDashBoardContext.Provider
      value={{
        projectList,
        loading,
        setLoading,
        openProject,
        markProjectFav,
      }}
    >
      {children}
    </UserDashBoardContext.Provider>
  );
};
