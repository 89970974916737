import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  hasErrors: false,
  type: '',
  messages: '',
  data: [],
  error: {},
};
const dataCollectionSlice = createSlice({
  name: 'dataCollection',
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: { type: payload.type, messages: payload.messages, data: payload },
    }),
    getDataCollectionSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDtoList,
    }),
    resetSliceState: (state) => ({ ...initialState }),
  },
});

export const { resetState, getFailure, getDataCollectionSuccess } =
  dataCollectionSlice.actions; // A selector
export const esgModuleSelector = (state) => state.esgModule;
// The reducer
export default dataCollectionSlice.reducer;
