import React from 'react';
import BuildingProfileHome from "./BuildingProfileHome"
import { pmRoot} from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const BuildingProfileRouteConfig = {
  routes: [
		{
    path: `${pmRoot}/project/building-profile`,
    exact: true,
    name: <IntlMessages id={"menu.building-profile"}/>,
    element: <BuildingProfileHome/>,
    auth: [],
  },
	]
};
export default BuildingProfileRouteConfig;