import React, { useEffect, useState } from "react";
import { Box, AppBar, Toolbar, IconButton } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AddHeader from "./AddHeader";
import ReorderHeaders from "./ReorderHeaders";
import { useDispatch, useSelector } from "react-redux";
import { createCustomHeader } from "../../../api/statutoryComplianceApi";
import { fetchRows } from "../../../api/statutoryComplianceApi";
import { resetState } from "../statutoryComplianceSlice";
import { useSnackbar } from "notistack";
import SaveButton from "../../../../../components/buttons/SaveButton";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { getDialogBoxHeader,getIntlTranslation } from "../../../../../utils/helper";
import CloseIcon from "@mui/icons-material//Close";

export function ReorderAndHeaderDialog({
  open,
  headerList,
  categories,
  onClose,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const saveHeader = useSelector((state) => state.tddInspection.headers);
  const dispatch = useDispatch();
  const [headers, setHeaders] = useState(headerList);

  const updateHeaders = (items) => {
    setHeaders(items);
  };
  const handleSave = () => {
    const updatedList = headers.filter((el) => {
      return el.updated === true || el.headerIdent === undefined;
    });
    dispatch(
      createCustomHeader({
        projectIdent: scope.projectIdent,
        subAssetIdent: openAsset.subAssetIdent,
        headers: updatedList,
      })
    );
  };
  useEffect(() => {
    if (saveHeader.type === "SAVED") {
      saveHeader.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(
        fetchRows({
          projectIdent: scope.projectIdent,
          subAssetIdent: openAsset.subAssetIdent,
        })
      );
      dispatch(resetState());
      onClose();
    }
  }, [saveHeader]);

  return (
    <>
      <Dialog
        onClose={(e) => onClose()}
        open={open}
        fullWidth={"true"}
        maxWidth={"lg"}
      >
          <AppBar className={"appBar"}>
            <Toolbar className={"dialog-toolbar"}>
               {getDialogBoxHeader("_Rearrange")}
              <IconButton
                edge="start"
                color="inherit"
                id="header_dialog_close"
                onClick={(e) =>onClose()}
                aria-label="close"
                style={{marginLeft:"auto"}}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        <DialogContent
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "row",
          }}
          className="custom-scrollBar"
          dividers
        >
          <AddHeader
            headerList={headers}
            categories={categories}
            updateHeaders={updateHeaders}
          />
          <ReorderHeaders headerList={headers} updateHeaders={updateHeaders} />
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex" }}>
          <CustomButton
          id="cancel_01"
          buttonText="_cancel"
          className={"btn"}
          variant="text"
          tooltip={{
            title: getIntlTranslation("tooltip.cancel"),
          }}
          style={{ margin: 5 }}
          handleClick={() =>onClose()}
        />
            <SaveButton
              id="edit_header__save_01"
              buttonText="_save"
              variant="button"
              style={{ margin: 5 }}
              tooltip={{
                title: getIntlTranslation("tooltip.Save"),
              }}
              handleClick={handleSave}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ReorderAndHeaderDialog;
