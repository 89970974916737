import React from "react";
import DataCollectionWrapper from "./structure/DataCollectionWrapper";
import Grid from "@mui/material/Grid";

const DataCollectionHome = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%", overflow: "auto" }}
    >
      <Grid item xs={12}>
        <DataCollectionWrapper />
      </Grid>
    </Grid>
  );
};
export default DataCollectionHome;
