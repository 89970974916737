import React from 'react'
import Box from '@mui/material/Box';
import ProjectDashboardHome from "../../../component-bundle/project-dashboard/ProjectDashboardHome"
import {pmServiceLine, pmRoot} from '../../../../utils/defaultValues'

const PMProjectDashboardHome = () => {
  return (
    <Box style={{ height: "100%", overflowY: "auto" }} className="custom-scrollBar">
  <ProjectDashboardHome serviceLine={pmServiceLine} routePrefix={pmRoot}/>
  </Box>
  );
};
export default PMProjectDashboardHome;