import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  AlertTitle,
  DialogTitle,
  Grow,
  Button,
} from "@mui/material";
import CustomButton from "../buttons/CustomButton";
import {
  getIntlTranslation,
  getTranslationVariantText,
} from "../../utils/helper";

import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const DeleteConfirmationAlert = (props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (props.open) {
      setOpenDeleteDialog(true);
    }
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        maxWidth={"sm"}
        fullWidth={true}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            props.onClose();
          }
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
         <DialogTitle className={"d-flex flex-align-items p-2"} >
         <Alert severity="warning" className={"d-flex align-items-center justify-items-center w-100"}>
            <AlertTitle style={{ fontWeight: "bold" }} className="w-100">
            {getIntlTranslation("_warning","caption",{width:"100%"})}
            </AlertTitle>
            {getTranslationVariantText("_delete-alert-text", "body1", {
              fontWeight: "bold",
              textTransform: "capitalize",
              color:"#663c00",
              margin:"auto"
            })}
            </Alert>
          </DialogTitle>
        <DialogContent dividers>
          <div className={"d-flex align-items-center w-100"}>
            {props.warningMessage}
          </div>
        </DialogContent>
        <DialogActions className={"d-flex align-items-center"}>
        <CustomButton
            id={`cancel-delete-action`}
            buttonText={"_cancel"}
            variant="text"
            style={{ marginLeft: 5, height: 30 }}
            className="g-btn-secondary"
            tooltip={{
              title: getIntlTranslation("tooltip.cancel"),
            }}
            handleClick={(e) => props.onClose()}
          />
          <CustomButton
            id={`delete-custom-button`}
            buttonText={"_delete"}
            variant="button"
            style={{ marginLeft: "auto", height: 30 }}
            className="g-btn-primary"
            tooltip={{
              title: getIntlTranslation("tooltip.delete"),
            }}
            handleClick={(e) => props.onConfirmation()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteConfirmationAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirmation: PropTypes.func,
  alertText: PropTypes.string,
  warningMessage:PropTypes.object,
};

export default DeleteConfirmationAlert;
