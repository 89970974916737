import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { getIntlTranslation, getTranslationVariantText } from "../../utils/helper";

function Footer(props) {
  return (
    <>
      <Box className="g-footer">
        <div className="d-flex align-items-center w-100 justify-content-end">
        <Tooltip title={getIntlTranslation("tooltip.help-us-improve")}>
          <Button
            variant="text"
            size="small"
            className="feedback"
            data-replace="Feedback"
            target="_blank" 
            rel="noopener noreferrer"
            href="https://taeuropecloud.sharepoint.com/:x:/r/sites/group/_layouts/15/Doc.aspx?sourcedoc=%7B0503A23C-8AD0-42C2-8C2F-9232BCD459DC%7D&file=2024_Guru%20issues%20and%20ideas1.xlsx&action=default&mobileredirect=true"
          >
            {getTranslationVariantText("_feedback","caption")}
          </Button>
          </Tooltip>
        </div>
      </Box>
    </>
  );
}

Footer.propTypes = {};

export default Footer;
