import {
  AppBar,
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
} from "../../../utils/helper";
import CustomButton from "../../../components/buttons/CustomButton";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import { useDocumentContextHook } from "../structure/DocumentBuilderContext";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { propertyType } from "../../../utils/defaultValues";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconStyle = {
  width: 22,
  height: 22,
};

const settingOptions = [
  {
    label: "_Name",
    type: "string",
    onChangeField: "title",
  },
  {
    label: "_translation",
    type: "header",
  },
  {
    label: "_German-Translation",
    type: "string",
    onChangeField: "deTranslation",
  },
  {
    label: "_English-Translation",
    type: "string",
    onChangeField: "enTranslation",
  },
];

export const TypeTwoColumnTreeRender = ({ item }) => {
  const { selectedItem } = useDocumentContextHook();

  return (
    <Box
      id={`box_${item.id}`}
      className="w-100 h-100 d-flex justify-content-start align-items-center"
    >
      <Typography
        variant="subtitle2"
        style={{
          color: selectedItem?.id === item.id ? "#ffffff" : "#254a9a",
          fontWeight: selectedItem?.id === item.id ? "bold" : "normal",
        }}
        className="p-1"
      >
        {item?.settings?.title}
      </Typography>
    </Box>
  );
};

export const TypeTwoColumnDropRender = ({ item }) => {
  const { saveContent } = useDocumentContextHook();
  const itemRef = useRef();
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: "",
      enTranslation: "",
      deTranslation: "",
      columnType: "",
      ident: uuidv4(),
      nodeOrder: "",
      custom: true,
    },
  ]);
  const columnStructure = {
    title: "",
    enTranslation: "",
    deTranslation: "",
    columnType: "",
    ident: "",
    nodeOrder: "",
    custom: true,
  };

  useEffect(() => {
    if (!!item && item.content.columns.length) {
      setColumns(item.content.columns);
    }
  }, [item?.id]);

  useEffect(() => {
    if (!!item) {
      itemRef.current = item;
    }
  }, [item]);

  const handleSave = () => {
    saveContent(item, {
      ...item.content,
      columns: columns,
    });
  };

  const updateCustomProperty = (value, column, key) => {
    setColumns((prev) => {
      return prev.map((property, index) => {
        if (property.ident === column.ident) {
          return { ...property, [key]: value };
        }
        return property;
      });
    });
  };

  const removeCustomProperty = (col) => {
    setColumns((prev) => prev.filter((prop) => prop.ident !== col.ident));
  };

  const addCustomProperty = () => {
    setColumns((prev) => [...prev, { ...columnStructure, ident: uuidv4() }]);
  };

  const handleCollapseIn = () => {
    if (itemRef.current?.id === item.id) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Box
      id={`box_${item.id}`}
      className="w-100 h-100 d-flex flex-column justify-content-start align-items-start"
    >
      <Box className="d-flex align-item-center w-100">
        <Typography
          variant="subtitle2"
          style={{
            color: "#254a9a",
            fontWeight: "bold",
            width: "80%",
          }}
          className="p-1"
        >
          {item?.settings?.title}
        </Typography>
        <IconButton
          style={{
            marginLeft: "auto",
            transform: open ? "rotate(0deg)" : "rotate(180deg)",
          }}
          className="p-1"
          onClick={() => handleCollapseIn()}
        >
          <FontAwesomeIcon
            icon="fa-solid  fa-square-caret-down"
            style={{
              width: 22,
              height: 22,
              color:  open ? "#254a9a" : "",
            }}
          />
        </IconButton>
      </Box>

      <Collapse
        className="w-100 m-1 p-1"
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <CardContent style={{ width: "98%", padding: 0 }}>
          <List className="p-0 w-100">
            {columns.map((col, index) => {
              return (
                <ListItem
                  key={col.ident}
                  className="m-2 w-100 d-flex flex-wrap align-items-center justify-content-start"
                  style={{ backgroundColor: "#ffffff", borderRadius: 4 }}
                >
                  <TextField
                    id={col.ident}
                    style={{ width: "40%", margin: 5 }}
                    label={getLabel("_item_name")}
                    variant="outlined"
                    value={col.title}
                    multiline={true}
                    maxRows={5}
                    onChange={(e) =>
                      updateCustomProperty(e.target.value, col, "title")
                    }
                    size="small"
                  />
                  <Autocomplete
                    id={`auto-${col.ident}`}
                    options={propertyType}
                    value={
                      !!col.columnType
                        ? propertyType?.find(
                            (f) => f.value === col.columnType && !!f.label["de"]
                          )
                        : null
                    }
                    getOptionLabel={(option) => option.label["de"]}
                    style={{ width: "40%", margin: 5 }}
                    onChange={(event, newValue) => {
                      if (!!newValue) {
                        updateCustomProperty(newValue.value, col, "columnType");
                      }
                    }}
                    renderOption={(props, option, { inputValue }) => {
                      return (
                        <ListItem {...props}>
                          <Typography variant="body">
                            {option.label["de"]}
                          </Typography>
                        </ListItem>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={getLabel("_item_type")}
                        multiline={true}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  <IconButton onClick={() => removeCustomProperty(col)}>
                    <DeleteForeverIcon style={{ color: "#f50057" }} />
                  </IconButton>
                  <TextField
                    id={`de-${col.ident}`}
                    style={{ width: "40%", margin: 5 }}
                    label={getLabel("_German-Translation")}
                    variant="outlined"
                    value={col.deTranslation}
                    multiline={true}
                    maxRows={5}
                    onChange={(e) =>
                      updateCustomProperty(e.target.value, col, "deTranslation")
                    }
                    size="small"
                  />
                  <TextField
                    id={`en-${col.ident}`}
                    style={{ width: "40%", margin: 5 }}
                    label={getLabel("_English-Translation")}
                    variant="outlined"
                    value={col.enTranslation}
                    multiline={true}
                    maxRows={5}
                    onChange={(e) =>
                      updateCustomProperty(e.target.value, col, "enTranslation")
                    }
                    size="small"
                  />
                </ListItem>
              );
            })}
            <ListItem>
              <CustomButton
                id={`add-optional-custom-button`}
                buttonText={"_add-more"}
                endIcon={
                  <AddIcon
                    style={{
                      color: "#d08f8e",
                      width: 20,
                      height: 20,
                    }}
                  />
                }
                variant="button"
                className={"g-btn-secondary"}
                style={{ margin: 5, height: 30 }}
                tooltip={{
                  title: getIntlTranslation(
                    "add_custom_properties_placeholder"
                  ),
                }}
                handleClick={(e) => addCustomProperty()}
              />
            </ListItem>
          </List>
        </CardContent>
        <CardActions style={{ marginTop: "auto", width: "100%" }}>
          <CustomButton
            id={`save_${item.id}`}
            buttonText={"_save"}
            variant="button"
            textVariant="body1"
            buttonColor={"inherit"}
            style={{
              margin: 5,
              height: 40,
              marginLeft: "auto",
              width: "100%",
              padding: 5,
            }}
            className="g-btn-primary"
            tooltip={{
              title: getIntlTranslation("tooltip.Save"),
            }}
            size="small"
            handleClick={(e) => handleSave()}
          />
        </CardActions>
      </Collapse>
    </Box>
  );
};

export const TypeTwoColumnSettings = ({ item }) => {
  const intl = useIntl();
  const { editItem, setEditItem, saveSettings } = useDocumentContextHook();
  const [settings, setSettings] = useState({
    title: "",
    enTranslation: "",
    deTranslation: "",
  });

  useEffect(() => {
    if (!!editItem && !!editItem.id) {
      setSettings({
        title: editItem.settings.title,
        enTranslation: editItem.settings.translation?.en,
        deTranslation: editItem.settings.translation?.de,
      });
    }
  }, [editItem?.id]);

  const handleSave = () => {
    saveSettings(editItem, {
      ...editItem.settings,
      title: settings.title,
      translation: {
        en: settings.enTranslation,
        de: settings.deTranslation,
      },
    });
    setEditItem(null);
  };

  const renderInputField = (input) => {
    if (input.type === "number") {
      return (
        <TextField
          key={`name_${item.id}-${input.label}`}
          className="w-100 p-1"
          type={input.type}
          defaultValue={
            !!editItem
              ? settings[input.onChangeField]
              : item.settings.defaultTitle
          }
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    } else if (input.type === "checkbox") {
      return (
        <Typography variant="body1">
          <Checkbox
            key={`name_${item.id}-${input.label}`}
            checked={settings[input.onChangeField]}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                [input.onChangeField]: e.target.checked,
              }))
            }
          />
        </Typography>
      );
    } else {
      return (
        <TextField
          key={`name_${editItem?.id}-${input.label}`}
          className="w-100 p-1"
          multiline={true}
          maxRows={5}
          type={input.type || "text"}
          defaultValue={settings[input.onChangeField]}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    }
  };

  return (
    <>
      <Collapse
        className="w-100 m-1 p-1"
        in={editItem?.id === item.id}
        timeout="auto"
        unmountOnExit
      >
        <Card className="w-100 p-1" style={{ backgroundColor: "#e6e8ed" }}>
          <AppBar
            style={{
              backgroundColor: "#d08f8e",
              position: "sticky",
              width: "98%",
              borderRadius: 4,
              marginLeft: 2,
              height: 40,
            }}
          >
            <Toolbar className={"dialog-toolbar"}>
              <Typography variant="body1">{editItem?.label}</Typography>
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={() => setEditItem(null)}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <CardContent
            className="custom-scrollBar m-1"
            style={{
              width: "98%",
              overflowY: "auto",
              overflowX: "hidden",
              padding: 2,
              height: "100%",
            }}
          >
            <List className="w-100 p-0">
              {settingOptions.map((o, index) => {
                if (o.type === "header") {
                  return (
                    <ListItem
                      className="w-100 p-0"
                      key={`option-${o.label}-${index}}`}
                    >
                      {getTranslationVariantText(o.label, "caption", {
                        fontWeight: "bold",
                        color: "#244a9a",
                        textAlign: "center",
                      })}
                    </ListItem>
                  );
                } else {
                  return (
                    <>
                      <ListItem
                        className="w-100 p-0 d-flex flex-row align-items-baseline m-1 flex-wrap"
                        key={`option-${o.label}-${index}}`}
                      >
                        <Box
                          style={{ textTransform: "capitalize", width: "80%" }}
                        >
                          {getLabel(o.label)} {o.optional && `(Optional)`}
                        </Box>
                        {renderInputField(o)}
                      </ListItem>
                    </>
                  );
                }
              })}
            </List>
          </CardContent>
          <CardActions style={{ marginTop: "auto" }}>
            <CustomButton
              id={`save_${item.id}`}
              buttonText={"_save"}
              variant="button"
              textVariant="body1"
              buttonColor={"inherit"}
              style={{
                margin: 5,
                height: 40,
                marginLeft: "auto",
                width: "100%",
                padding: 5,
              }}
              className="g-btn-primary"
              tooltip={{
                title: getIntlTranslation("tooltip.Save"),
              }}
              size="small"
              handleClick={(e) => handleSave()}
            />
          </CardActions>
        </Card>
      </Collapse>
    </>
  );
};

function getTableStructure(data) {
  return (
    <table className="custom-table">
      <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            <td style={{
              wordWrap:"break-word",
              width:"50%"
            }}>{item.title}</td>
            <td  style={{
               wordWrap:"break-word",
              width:"50%"
            }}>{item.columnType}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
export const TypeTwoColumnPreviewRender = ({item}) => {
  return (
  <Box className="d-flex align-items-center m-1">
    {getTableStructure(item.content.columns)}
  </Box>
  )
};