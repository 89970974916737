import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import {
  getUserListSuccess,
  getFailure,
  getRoles,
  getCreateSuccess,
  editUserSuccess,
  editUserFailure,
  getOffices,
} from "../guru-suite/user-management/userManagementSlice";
import {
  getUserListSuccess as getUserList,
  getProjectUserListSuccess,
} from "../guru-suite/component-bundle/users/usersSlice";
import { renewAccessTokenIfGoingExpire } from "./authApi";
import { authHeader, getCurrentUser } from "../authentication/authTokenService";
import { getErrorFailure } from "../error/errorHandlingSlice";

export const getUsers = () => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.get(`/user/users`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(getCurrentUser().token),
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getUserListSuccess(response.data));
      dispatch(getUserList(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        status: !!error.response ? error.response.status : null,
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const getProjectUsers = (request) => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.get(`/tdd/user/users/${request.ident}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(getCurrentUser().token),
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getProjectUserListSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        status: !!error.response ? error.response.status : null,
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact it.support@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getRolesList = () => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios.get(`/roles/roles-list`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getRoles(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const createUsers = (user) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/user/create`,
        data: {
          type: "userSignUpDetails",
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          roles: user.roles,
          userName: user.userName,
          taOfficeDto: user.taOfficeDto,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getCreateSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const updateUser = (user) => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/user/update`,
        data: {
          type: "userDetails",
          businessLine: user.businessLine,
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          roles: user.roles,
          taOfficeDto: user.taOfficeDto,
          status: user.status,
          userName: user.userName,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(getCurrentUser().token),
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(editUserFailure(response.data));
    } else {
      dispatch(editUserSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getTAEuropeOffices = () => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.get(`/user/offices`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(),
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getOffices(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getUsersDetails = (request) => async (dispatch) => {
  try {
    const query = encodeURIComponent(JSON.stringify(request.users));
    const response = await trackPromise(
      axios.get(`user/fetch/users?emails=${query}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(),
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getUserList(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};


