import React from 'react';
import PMProjectDashboardHome from "./PMProjectDashboardHome"
import { pmRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const ProjectDashboardRouteConfig = {
	routes: [
		{
    path: `${pmRoot}/project/project-dashboard`,
    exact: true,
    name: <IntlMessages id={"menu.project-dashboard"}/>,
    element:  <PMProjectDashboardHome/>,
    id: "pm-project-information",
    auth: [],
  },
  {
    path: `${pmRoot}`,
    exact: true,
    name: <IntlMessages id={"menu.project-dashboard"}/>,
    element: <PMProjectDashboardHome/>,
    id: "pm-project-information",
    auth: [],
  },
  {
    path: `${pmRoot}/project`,
    exact: true,
    name: <IntlMessages id={"menu.project-dashboard"}/>,
    element: <PMProjectDashboardHome/>,
    id: "pm-project-information",
    auth: [],
  },
	]
};
export default ProjectDashboardRouteConfig;