import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from '../../../api/authApi'
import {
  getSubCategoryFetchListSuccess,
  getCategoryFetchListSuccess,
  getSubAssetTypeListSuccess,
  getElementTypeListSuccess,
  getReportsPropertyLabel,
  getFailure } from "../../component-bundle/suggestion-values/suggestionSlice";

export const fetchSubCategoryList = (projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/category/fetch-subcategory-list/${projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSubCategoryFetchListSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
     error: error?.response?.data,
      responseError: "true",
      };
      dispatch(getErrorFailure(payload));
  }
};


export const fetchCategoryList = (projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/category/fetch-category-list/${projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getCategoryFetchListSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
      payload = {
        type: "ERROR",
        messages:"OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
     error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
  }
};
export const fetchElementList = (projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/category/fetch-element-list/${projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getElementTypeListSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
      payload = {
        type: "ERROR",
        messages:"OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
     error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
  }
};


export const fetchSubAssetTypeList = (projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/subassets/fetch-subasset-type/${projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSubAssetTypeListSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
      payload = {
        type: "ERROR",
        messages:"OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
     error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
  }
};

export const fetchPropertyLabelList = (projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/suggestions/fetch-property-label/${projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getReportsPropertyLabel(response.data));
    }
  } catch (error) {
    let payload = {};
      payload = {
        type: "ERROR",
        messages:"OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
     error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
  }
};