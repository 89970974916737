import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { riskTypeOptions, statusTypeOptions } from "../../../../../common/dropdowns";
import { useCategoriesData } from "./CategoriesDataContext";


const SelectedPropertyContext = createContext();

export const useSelectedProperty = () => useContext(SelectedPropertyContext);

export const SelectedPropertyProvider = ({ children }) => {
  const scope = useSelector((state) => state.container.scope);
  const { structuredCategories } = useCategoriesData();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const getRisk = useCallback(
    (risk) =>
      !!risk ?
      riskTypeOptions.find(
        (f) =>
          f.language === scope.projectLanguageCode &&
          f.value.toLowerCase() === risk.toLowerCase()
      ): { title: "", value: "" },
    [scope.projectLanguageCode]
  );

  const getStatus = useCallback(
    (status) =>
      !!status ?
      statusTypeOptions.find(
        (f) =>
          f.language === scope.projectLanguageCode &&
          f.value.toLowerCase() === status.toLowerCase()
      ): { title: "", value: "" },
    [scope.projectLanguageCode]
  );



  useEffect(() => {
    if(!selectedCategory){
      const propertyKeys = Object.keys(structuredCategories.nodes);
      if (propertyKeys.length > 0) {
        setSelectedCategory(structuredCategories.nodes[propertyKeys[0]]);
      }
    }
    if(!!selectedCategory){
      const propertyKeys = Object.keys(structuredCategories.nodes);
      if (propertyKeys.length > 0 && !structuredCategories.nodes[selectedCategory.nodeIdent]) {
        setSelectedCategory(structuredCategories.nodes[propertyKeys[0]]);
      }
    }
  }, [selectedCategory, structuredCategories.nodes]);

  return (
    <SelectedPropertyContext.Provider value={{ selectedProperty, setSelectedProperty, selectedCategory, setSelectedCategory,getRisk, getStatus }}>
      {children}
    </SelectedPropertyContext.Provider>
  );
};
