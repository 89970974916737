import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemButton,
  CardActions,
  Box,
  Card,
  CardContent,
  Toolbar,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  getVariantText,
  createAddress,
  getTranslationVariantText,
  getIntlTranslation,
  sortColumn,
} from "../../../utils/helper";
import InputSearch from "../../../components/InputSearch";
import FolderIcon from "@mui/icons-material/Folder";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SortingMenu from "./SortingMenu";
import FilteringMenu from "./FilteringMenu";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import Groups3Icon from "@mui/icons-material/Groups3";
import { useIntl } from "react-intl";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { setProjectOpen } from "../../../layout/app-container/containerSlice";
import { serviceLines } from "../../../utils/defaultValues";
import TopicIcon from "@mui/icons-material/Topic";

const ChapterListStyle = {
  width: "100%",
  border: "2px double #C1D0F0",
  height: "100%",
  overflow: "auto",
  marginRight: 4,
  borderRadius: 8,
};

const cellStyle = {
  marginLeft: 5,
  borderRight: "1px solid #0000001f",
  height: "100%",
  padding: 5,
};

const ProjectListView = (props) => {
  const intl = useIntl();
  const system = useSelector((state) => state.system);
  const [projectList, setProjectList] = useState([]);
  const [filterList, setfilterList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState({
    anchor: null,
  });
  const [menuAnchor, setMenuAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const projectColumnList = useMemo(
    () => [
      {
        columnName: "projectName",
        displayName: intl.formatMessage({ id: "_Name" }),
        type: "string",
      },
      {
        columnName: "projectNumber",
        displayName: intl.formatMessage({ id: "_project-number" }),
        type: "string",
      },
      {
        columnName: "serviceLine",
        displayName: intl.formatMessage({ id: "Service Line" }),
        type: "string",
      },
      {
        columnName: "date",
        displayName: intl.formatMessage({ id: "_start-date" }),
        type: "date",
      },
      {
        columnName: "clientName",
        displayName: intl.formatMessage({ id: "Client Name" }),
        type: "string",
      },
      {
        columnName: "clientAddress",
        displayName: intl.formatMessage({ id: "_Address" }),
        type: "string",
      },
    ],
    []
  );

  useEffect(() => {
    if (!!props.data) {
      let temp = [];

      props.data.forEach((client) => {
        let projects = client.projects.map((m) => {
          let tempDate = new Date(m.creationDate);
          return {
            ...m,
            date: m.creationDate,
            displayDate: `${tempDate.toLocaleDateString(system.language, {
              month: "short",
            })},${tempDate.getFullYear()}`,
            clientIdent: client.clientIdent,
            clientName: client.clientName,
          };
        });

        temp = [...temp, ...projects];
      });

      setProjectList(temp);
      setfilterList(temp);
    }
  }, [props.data, system.language]);

  const filterData = (value) => {
    if (!!value && value.length) {
      setfilterList(
        projectList.filter((e) => e.projectName.toUpperCase().includes(value))
      );
    } else {
      setfilterList(projectList);
    }
  };

  const handleMenuOpen = (event, columnName) => {
    setMenuAnchorEl({
      anchor: event.currentTarget,
      column: columnName,
      dataList: projectList,
    });
  };

  const sortAsc = (col) => {
    let temp = sortColumn(col.columnName, filterList);
    setfilterList(temp);
  };

  const sortDesc = (col) => {
    let temp = sortColumn(col.columnName, filterList);
    setfilterList(temp.reverse());
  };

  const applyFilter = useCallback((dataList) => {
    setfilterList(dataList);
  }, []);

  const resetFilters = useCallback(() => {
    setfilterList(projectList);
  }, [projectList]);

  const openProject = (data) => {
    const found = serviceLines.find(
      (f) => f.name.toLowerCase() === data.serviceLine.toLowerCase()
    );
    if (!!found) {
      dispatch(
        setProjectOpen({
          serviceLine: data.serviceLine.toLowerCase(),
          api: found.api,
          isProjectScope: true,
          projectIdent: data.projectIdent,
          projectName: data.projectName,
          isReadOnly: data.isReadOnly || true,
          currentProjectRole: "Default",
          projectLanguageCode: !!data.languageCode ? data.languageCode : "de",
        })
      );
      navigate(`${found.root}/project/project-dashboard`);
    }
  };

  const createClientAddress = (value) => {
    let address = createAddress({ ...value, street: value?.streetName });
    return (
      <Box className="d-flex align-items-center">
        <LocationOnIcon
          style={{ fontSize: "20", color: "#018075 ", marginRight: 5 }}
        />

        {getVariantText(address, "caption", { padding: 5 })}
      </Box>
    );
  };

  const createProjectListStruct = () => {
    return (
      <>
        <Card style={ChapterListStyle} className="custom-scrollBar">
          <Toolbar
            style={{
              height: 50,
              position: "sticky",
              zIndex: 1500,
              top: 0,
              backgroundColor: "#ffffff",
            }}
            className="w-100 d-flex align-items-center justify-content-start mb-1"
          >
            <Box className="d-flex align-items-center justify-content-start">
              <IconButton
                edge="start"
                color="inherit"
                id="switch to client view"
                onClick={() =>
                  props.switchView({
                    isClientListView: true,
                    isProjectListView: false,
                  })
                }
                aria-label="close"
              >
                <Tooltip
                  title={getIntlTranslation("tooltip.switch-to-client-list")}
                >
                  <Groups3Icon
                    style={{ width: 25, height: 25, color: "#d08f8e" }}
                  />
                </Tooltip>
              </IconButton>
            </Box>
            <Divider orientation="vertical" flexItem style={{ margin: "15px 5px", backgroundColor: "#254a9a" }} />
            <Box
              style={{ width: "40%" }}
              className="d-flex align-items-center justify-content-start"
            >
              {getTranslationVariantText(`menu.project-list`, "caption", {
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#254a9a",
                margin: "0px 5px",
              })}
            </Box>
            <Box style={{ width: "50%", marginLeft: "auto" }}>
              <InputSearch onFilterInput={filterData} />
            </Box>
            <Box className="d-flex align-items-center justify-content-start">
              <CustomIconButton
                id={`reset-filter-options`}
                icon={
                  <RestartAltIcon
                    style={{ fontSize: "25", color: "#d08f8e" }}
                  />
                }
                edge="end"
                style={{ margin: 5, borderRadius: 4, width: 30, height: 30 }}
                handleClick={(e) => resetFilters()}
                tooltip={{
                  title: getIntlTranslation("_reset"),
                }}
                label={""}
                aria-label="all filter"
              />
            </Box>
          </Toolbar>
          <CardContent className={"w-100 d-flex flex-column p-0 custom-scrollBar"} 
          style={{ height:"calc(100% - 120px)", overflowY:"auto"}}>
            <List className={"p-1"}>
              <ListItem
                divider
                style={{
                  position: "sticky",
                  top: 0,
                  height: 40,
                  zIndex: 1500,
                  borderRadius: 4,
                }}
                className="p-0 g-btn-primary"
                key="headers"
              >
                <ListItemButton className="p-0">
                  <ListItemIcon className="p-1">
                    <TopicIcon style={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <>
                    <Box
                      className="d-flex align-items-center w-100"
                      style={{ height: 40 }}
                    >
                      <div
                        style={{
                          ...cellStyle,
                          width: "20%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("_Name", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_project_name`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#ffffff" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "projectName",
                              type: "string",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by project name"
                        />
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "15%",
                          minHeight: 40,
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText(
                          "_project-number",
                          "caption",
                          {
                            fontWeight: "Bold",
                            textTransform: "uppercase",
                          }
                        )}
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "15%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("Service Line", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_project_service_line`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#ffffff" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "serviceLine",
                              type: "string",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by service Line"
                        />
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "10%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("_start-date", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_projects`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#ffffff" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "date",
                              type: "date",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by projects count"
                        />
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "20%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("Client Name", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_client_name`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#ffffff" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "clientName",
                              type: "string",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by client name"
                        />
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "20%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("_Address", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                      </div>
                    </Box>
                  </>
                </ListItemButton>
              </ListItem>
              {filterList.map((m, index) => {
                return (
                  <>
                    <ListItem
                      key={index}
                      divider
                      className="p-0"
                      style={{
                        borderLeft: "1px solid #0000001f",
                        borderRight: "1px solid #0000001f",
                      }}
                    >
                      <ListItemButton
                        className="p-0"
                        onClick={(e) => {
                          openProject(m);
                          setSelectedProject({
                            anchor: m.ident,
                          });
                        }}
                      >
                        <ListItemIcon className="p-1">
                          <FolderIcon style={{ color: "#F8D775" }} />
                        </ListItemIcon>
                        <>
                          <Box className="d-flex align-items-center h-100 w-100">
                            <div
                              style={{
                                ...cellStyle,
                                width: "20%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.projectName, "subtitle2", {
                                padding: 5,
                              })}
                            </div>
                            <div
                              style={{
                                ...cellStyle,
                                width: "15%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.projectNumber, "caption", {
                                padding: 5,
                              })}
                            </div>
                            <div
                              style={{
                                ...cellStyle,
                                width: "15%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.serviceLine, "caption", {
                                padding: 5,
                              })}
                            </div>
                            <div
                              style={{
                                ...cellStyle,
                                width: "10%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.displayDate, "caption", {
                                padding: 5,
                              })}
                            </div>
                            <div
                              style={{
                                ...cellStyle,
                                width: "20%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.clientName, "subtitle2", {
                                padding: 5,
                              })}
                            </div>
                            <div style={{ ...cellStyle, width: "20%" }}>
                              {createClientAddress(m.address)}
                            </div>
                          </Box>
                        </>
                      </ListItemButton>
                    </ListItem>
                  </>
                );
              })}
            </List>
          </CardContent>
          <CardActions
            style={{ height: 50, marginTop: "auto" }}
            className="d-flex flex-column p-0"
          >
            <List className={"w-100 h-100 p-2"}>
              <ListItem
                divider
                style={{
                  position: "sticky",
                  top: 0,
                  height: 40,
                  borderRadius: 4,
                  zIndex: 1500,
                }}
                className="p-0 g-btn-secondary"
                key="headers-1"
              >
                <Box className={"w-100 d-flex align-items-center h-100"}>
                  <div
                    style={{
                      ...cellStyle,
                      width: "5%",
                      border: "none",
                    }}
                  >
                    {getTranslationVariantText("_Total", "body1", {
                      padding: 5,
                      fontWeight: "bold",
                    })}
                  </div>
                  <div
                    style={{
                      ...cellStyle,
                      width: "5%",
                      border: "none",
                    }}
                  >
                  {getVariantText(filterList.length, "body1", {
                        padding: 5,
                        fontWeight: "bold",
                      })}
                      </div>
                  
                </Box>
              </ListItem>
            </List>
          </CardActions>
        </Card>
        <SortingMenu
          menuAnchor={menuAnchor}
          onClose={() => setMenuAnchorEl(null)}
          sortAsc={(col) => sortAsc(col)}
          sortDesc={(col) => sortDesc(col)}
          onFilterSelect={(anchor) =>
            setFilterAnchorEl({
              ...anchor,
              openFilter: true,
              columnList: projectColumnList,
              filterList: filterList,
            })
          }
        />
      </>
    );
  };
  return (
    <div className="d-flex h-100 w-100">
      {createProjectListStruct()}
      {filterAnchorEl?.openFilter && (
        <Box style={{ width: "30%" }} className="h-100">
          <FilteringMenu
            menuAnchor={filterAnchorEl}
            onClose={() => setFilterAnchorEl(null)}
            columnList={filterAnchorEl.columnList}
            dataList={filterList}
            onSortSelect={(anchor) =>
              setMenuAnchorEl({ ...anchor, openSort: true })
            }
            applyFilter={(data) => applyFilter(data)}
            resetFilters={() => resetFilters()}
          />
        </Box>
      )}
    </div>
  );
};

ProjectListView.propTypes = {
  data: PropTypes.object,
  onFilterSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default ProjectListView;
