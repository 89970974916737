import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import { Box} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { setTab } from "../statutoryComplianceSlice";

function CategoriesTab(props) {
  const {  categories } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const inspection = useSelector((state) => state.tddInspection);
 

  useEffect(() => {
    if (!value) {
      setValue(categories?.options[0]);
      dispatch(setTab(categories?.options[0]));
    } else {
      setValue(
        categories?.options.find((f) => f.ident === inspection.tab?.ident)
      );
    }
  }, [dispatch, inspection.tab, categories?.options]);

  const handleChange = (event, newValue) => {
    dispatch(setTab(newValue));
  };

  return (
    <Box
      style={{flexGrow: 1, marginBottom: 5  }}
      className="d-flex align-items-center justify-content-start w-100"
    >
      <AppBar
        position="static"
        style={{
          width: "100%",
          height: 40,
          borderRadius: 5,
        }}
      >
        <Toolbar
          style={{ minHeight: 35, padding: 0, height: 40 }}
          className="d-flex align-items-center w-100"
        >
          <Tabs
            className="g-btn-secondary d-flex align-items-center"
            style={{ height: 40, width: "100%", minHeight: 40 }}
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#d08f8e",
                height: 12,
                borderRadius: 40,
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable categories"
          >
            {categories.options?.map((m) => (
              <Tab
                style={{
                  color: "#254a9a",
                  height: 40,
                  maxWidth: "fit-content",
                  marginTop: 5,
                }}
                label={
                  <Typography variant="button" display="block" gutterBottom>
                    {m.value}
                  </Typography>
                }
                key={m.value}
                value={m}
              />
            ))}
          </Tabs>
        </Toolbar>
      </AppBar>
      </Box>
      
  );
}

CategoriesTab.propTypes = {
  onSave: PropTypes.func.isRequired,
  categories: PropTypes.oneOfType([PropTypes.object]).isRequired,
  headers: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default CategoriesTab;
