import React, {useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  ListItem,
  Typography,
} from "@mui/material";
import {
  getIntlTranslation,
} from "../../../../../utils/helper";
import CustomButton from "../../../../../components/buttons/CustomButton";
import RenderSubAssetsCard from "./RenderSubAssetsCard";

const RenderItemProgress = ({ data, selectedProperty,onEdit, onDelete }) => {
  const [node, setNode] = useState(data);

  useEffect(() => {
    if (!!selectedProperty && !!data) {
      setNode(data);
    }
  }, [selectedProperty, data]);


  return (
    <>
      <ListItem
        className="w-100 p-0 d-flex flex-column my-1"
        key={`key-${node.ident}`}
      >
        <Box className="w-100 d-flex justify-content-between my-1">
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
            }}
            className="w-75 mx-1"
          >
            {node?.subAsset?.name}
          </Typography>
          <CustomButton
              id={`delete-${node.ident}`}
              buttonText={"_delete"}
              style={{
                color: "#254a9a",
                height: 30,
              }}
              className="btn g-btn-danger mx-1 g-btn-elevated"
              tooltip={{
                title: getIntlTranslation("tooltip.delete"),
              }}
              handleClick={(e) => onDelete(node)}
            />
        </Box>
        <Card className="w-100 rounded-3">
          <CardContent className="w-100 p-1">
          <RenderSubAssetsCard node={node} key={"current-mode"}/> 
          </CardContent>

          <CardActions className="w-100 d-flex align-items-center justify-content-end">
          <CustomButton
            id={`edit-${node.ident}`}
            buttonText={"_edit"}
            style={{
              color: "#254a9a",
              height: 30,
            }}
            className="btn g-btn-secondary mx-1 g-btn-elevated"
            tooltip={{
              title: getIntlTranslation("tooltip.edit"),
            }}
            handleClick={(e) => onEdit(node.subAsset)}
          />
          </CardActions>
        </Card>
      </ListItem>
      <Divider
        variant="middle"
        className="g-divider-vertical"
        style={{ margin: "10px 10px", width: "99%" }}
      />
    </>
  );
};

RenderItemProgress.propTypes = {};

export default RenderItemProgress;
