import React from 'react'
import DocumentDataWrapper from "./structure/DocumentDataWrapper"
import {Box} from '@mui/material';

const DocumentBuilderHome = () => {
  return (
    <Box className={"h-100 w-100"}>
  <DocumentDataWrapper/>
  </Box>
  );
};
export default DocumentBuilderHome;