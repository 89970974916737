import React from "react";
import AppRouter from "./routes/appRouter";
import Locale from "./lang/Locale";
import { IntlProvider } from "react-intl"
// import the library
import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fab, fas, far)
const App = () => {
  let currentLocale = Locale["de"];
  return (
    <IntlProvider
    locale={currentLocale.locale}
    messages={currentLocale.messages}
  >
      <div className={"h-100 app"}>
        <AppRouter />
      </div>
      </IntlProvider>
  );
};

export default App;
