import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCommentSaveHook from "../../../../component-bundle/hooks/useCommentSaveHook";
import useCommentFetchHook from "./useCommentFetchHook";

const useCommentActionHook = (comment) => {
    const { apiParam, reportType } = comment.queryDetails;
    const dispatch = useDispatch();
    const [onCommentsSave] = useCommentSaveHook();
  const { isCommentSaved, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );

  useEffect(() => {
    if (isCommentSaved.saved) {
      resetState();
    }
  }, [isCommentSaved]);


  const onDelete = (isReply) => {
    if(isReply) {
        let pComment = comment.parent;
        const replies = pComment.replies.filter(reply => reply.ident !== comment.ident);
        const { queryDetails, ...rest } = pComment;
      onCommentsSave({
        commentsApi: queryDetails.apiParam,
        data: {
          ...queryDetails,
          commentValues: [
            {
              ...rest,
              replies:replies,
            },
          ],
        },
        apiIdent: queryDetails.apiIdent,
      });
    }
    if (!!comment.queryDetails && !isReply) {
      const { queryDetails, ...rest } = comment;
      onCommentsSave({
        commentsApi: queryDetails.apiParam,
        data: {
          ...queryDetails,
          commentValues: [
            {
              ...rest,
             deleted:true,
            },
          ],
        },
        apiIdent: queryDetails.apiIdent,
      });
    }
  };

  const onResolve = (isReply) => {
    let rComment = comment;
    if(isReply) {
      rComment = comment.parent;
    }
    const { queryDetails, ...rest } = rComment;
    onCommentsSave({
      commentsApi: queryDetails.apiParam,
      data: {
        ...queryDetails,
        commentValues: [
          {
            ...rest,
           resolved:true,
          },
        ],
      },
      apiIdent: queryDetails.apiIdent,
    });
  }

  const onReopen = () => {
    const { queryDetails, ...rest } =comment;
    onCommentsSave({
      commentsApi: queryDetails.apiParam,
      data: {
        ...queryDetails,
        commentValues: [
          {
            ...rest,
           resolved:false,
          },
        ],
      },
      apiIdent: queryDetails.apiIdent,
    });
  }

  return {onDelete, onResolve, onReopen};
}

export default useCommentActionHook;
