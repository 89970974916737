import axios from "../../../api/customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {
  getFailure,
  getFetchSuccess,
  getCustomSuccess,
  getCustomUpdate,
  getSaveSuccess,
  getFetchCompareSuccess,
  getCustomDelete,
} from "../version-comparison/slice/VersionDataSlice";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";

export const fetchProperties = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `${request.api}/${request.moduleApi}/${request.projectIdent}/${request.assetIdent}/fetch/${request.version}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getFetchSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const saveProperties = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `${request.api}/${request.moduleApi}/save-update/${request.projectIdent}/${request.assetIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: request.data,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSaveSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const addProperties = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `${request.api}/${request.moduleApi}/add-optional-custom-label/${request.projectIdent}/${request.assetIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: request.data,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getCustomSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const fetchPropertiesToCompare = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `${request.api}/${request.moduleApi}/${request.projectIdent}/${request.assetIdent}/fetch/${request.version}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getFetchCompareSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const editProperty = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "put",
        url: `${request.api}/${request.moduleApi}/update-custom-label/${request.projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: request.data,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getCustomUpdate(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const deleteProperty = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "delete",
        url: `${request.api}/${request.moduleApi}/${request.projectIdent}/delete/${request.propertyIdent}/${request.version}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getCustomDelete(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};
