import React, { useState } from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PropTypes from "prop-types";

const FullScreenWidget = (props) => {
  const [inFullScreen, setinFullScreen] = useState(false);

  const isInFullScreenFn = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  const toggleFullScreen = () => {
    const isFS = isInFullScreenFn();

    const docElm = document.documentElement;
    if (!isFS) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setinFullScreen(!isFS);
  };

  return (
    <div  className="g-btn-elevated m-1 p-0">
    <button
      className="fullScreen"
      type="button"
      id="fullScreen"
      onClick={toggleFullScreen}
    >
      {inFullScreen ? <FullscreenExitIcon style={{fontSize:20,color:"#ffffff"}}/> : <FullscreenIcon style={{fontSize:20,color:"#ffffff"}}/>}
    </button>
    </div>
  );
};

FullScreenWidget.propTypes = {};

export default FullScreenWidget;
