import {
  Box,
  Card,
} from "@mui/material";
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { getIntlTranslation } from "../../../utils/helper";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import { renderNodeDropRender } from "../../../utils/customReportHelper";

const SortableDroppedItems = ({ item }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id: item.id });

  return (
    <Card
      className="d-flex flex-row align-items-center m-1"
      style={{
        borderRadius: 4,
        width: "95%",
        minHeight: 40,
        backgroundColor: "rgb(244, 245, 247)",
      }}
      key={item.id}
      ref={setNodeRef}
      raised={true}
      id={item.id}
    >
      <div
        style={{
          display: "flex",
          width: 20,
          marginRight: 5,
          cursor: "grabbing",
        }}
        className="g-drag"
      >
        <CustomIconButton
          id={`drag-${item.id}`}
          icon={
            <DragIndicatorIcon
              style={{
                fontSize: 18,
              }}
            />
          }
          tooltip={{
            title: getIntlTranslation("tooltip.reorder"),
          }}
          className={"p-1"}
          aria-label="drag handler"
          {...attributes}
          {...listeners}
        />
      </div>
      <Box className="w-100 p-1">
        {renderNodeDropRender(item)}
      </Box>
    </Card>
  );
};

export default SortableDroppedItems;
