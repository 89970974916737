import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material/";
import Autocomplete from "@mui/material/Autocomplete";
import { setOpenAsset } from "../../tdd-module/feature-bundle/asset-management/asset/OpenAssetSlice";
import PropTypes from "prop-types";
import useAssetListHook from "../hooks/useAssetListHook";

const SubAssetSwitcher = (props) => {
  const [selectedAsset, setSelectedAsset] = useState({});
  const [assets, setAssets] = useState([]);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const { assetList, fetchList } = useAssetListHook();

  useEffect(() => {
    if (assetList.isFetchSuccess && assetList.assets.length > 0) {
      const tempList = assetList.assets;
      const flattenedAssets = tempList.flatMap((a, i) => {
        return a.subAssetListDto
          .filter((sub) => !!sub.subAssetIdent)
          .map((s, index) => {
            return {
              index: i,
              asset: a.name,
              ident: a.assetIdent,
              label: s.name,
              value: s.name,
              sIdent: s.subAssetIdent,
              sType: s.subAssetType,
            };
          });
      });
      setAssets(flattenedAssets);

      if (!!openAsset && !!openAsset.subAssetIdent) {
        var subAssetList = tempList.map((m) => m.subAssetListDto).flat(1);
        var temp = subAssetList.find(
          (a) => a.subAssetIdent === openAsset.subAssetIdent
        );
        if (!!temp) {
          setSelectedAsset({
            asset: temp.assetName,
            ident: temp.ident,
            label: temp.name,
            value: temp.name,
            sIdent: temp.subAssetIdent,
          });
        }
      } else if (!!tempList[0].subAssetListDto[0]) {
        let firstSub = tempList[0].subAssetListDto[0];
        dispatch(
          setOpenAsset({
            assetName: tempList[0].name,
            ident: tempList[0].assetIdent,
            subAssetName: firstSub.name,
            subAssetIdent: firstSub.subAssetIdent,
          })
        );
        setSelectedAsset({
          index: 0,
          asset: tempList[0].name,
          ident: tempList[0].assetIdent,
          label: firstSub.name,
          value: firstSub.name,
          sIdent: firstSub.subAssetIdent,
          sType: firstSub.subAssetType,
        });
      }
    } else if (assetList.isFetchSuccess && assetList.assets.length <= 0) {
      setAssets([]);
    } else {
      fetchList();
    }
  }, [assetList, dispatch, openAsset, fetchList]);

  useEffect(() => {
    if (!!openAsset && !!openAsset.subAssetIdent && assets.length > 0) {
      const found = assets.find((f) => f.sIdent === openAsset.subAssetIdent);
      if (found) {
        setSelectedAsset(found);
      } else {
      }
    }
  }, [assets, openAsset]);

  const getSubAssetCategories = (val) => {
    if (props.dirtyState) {
      props.setBlockState({
        assetName: val.asset,
        subAssetName: val.label,
        ident: val.ident,
        subAssetIdent: val.sIdent,
      });
    } else {
      setSelectedAsset(val);
      dispatch(
        setOpenAsset({
          assetName: val.asset,
          subAssetName: val.label,
          ident: val.ident,
          subAssetIdent: val.sIdent,
        })
      );

      if (props.needGridRefresh) {
        props.needGridRefresh();
      } else {
      }
    }
  };

  return (
    <>
      {assets.length > 0 && (
        <Autocomplete
          id="assetsOptions"
          size="small"
          autoHighlight={true}
          style={{ ...props.style, margin: 5 }}
          value={selectedAsset}
          options={assets}
          groupBy={(option) => option.asset}
          onChange={(event, newValue) => {
            if (!!newValue && !!newValue.sIdent) {
              getSubAssetCategories(newValue);
            }
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) =>
            option.sIdent === value.sIdent
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant={props.variant}
              label="Sub-Asset Switcher"
            />
          )}
        />
      )}
    </>
  );
};

export default SubAssetSwitcher;

SubAssetSwitcher.defaultProps = {
  variant: "outlined",
  dirtyState: false,
};

SubAssetSwitcher.propTypes = {
  variant: PropTypes.string,
  style: PropTypes.object,
  dirtyState: PropTypes.bool,
  setBlockState: PropTypes.func,
  needGridRefresh: PropTypes.func,
};
