import React, { useCallback } from "react";
import PropTypes from "prop-types";
import TempVersionAlert from "../../../version-comparison/TempVersionAlert";
import HeaderContainer from "./HeaderContainer";
import ComparisonVersionContainer from "./ComparisonVersionContainer";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { ProjectDescriptionContextProvider } from "../context/ProjectDescriptionContext";
import CurrentVersionContainer from "./CurrentVersionContainer";
import NoAssets from "../../../../component-bundle/NoAssets";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";

const ProjectDescriptionWrapper = ({ moduleApi }) => {
  const monitoringState = useSelector((state) => state.projectMonitoring);
  const tempVersion = monitoringState.temp;
  const openAsset = useSelector((state) => state.openAsset);
  const { assetList } = useAssetListHook();

  const getHeight = useCallback(() => {
    if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
      return { height: "calc(100% - 100px)" };
    } else {
      return { height: "calc(100% - 50px)" };
    }
  }, [openAsset.ident, tempVersion.assetIdent, tempVersion.isTemp]);

  return (
    <>
      <Box className="w-100 h-100 d-flex flex-column align-items-baseline">
        <ProjectDescriptionContextProvider moduleApi={moduleApi}>
          {tempVersion.isTemp && <TempVersionAlert />}
          <Box className="w-100" style={{ height: 50 }}>
            <HeaderContainer />
          </Box>
          {assetList.assets.length <= 0 ? (
            <NoAssets />
          ) : (
            <Box className="w-100 d-flex flex-row" style={getHeight()}>
              <Box className="w-100 d-flex flex-row overflow-hidden h-100 m-1">
                <CurrentVersionContainer />
                {monitoringState.isCompareMode && <ComparisonVersionContainer />}
              </Box>
            </Box>
          )}
        </ProjectDescriptionContextProvider>
      </Box>
    </>
  );
};

ProjectDescriptionWrapper.propTypes = {
  moduleApi: PropTypes.string.isRequired,
};

export default React.memo(ProjectDescriptionWrapper, (prevProps, nextProps) => {
  return prevProps.moduleApi === nextProps.moduleApi;
});
