export const getUnits = () => {
  return [
    { value: "cm", title: "cm" },
    { value: "m", title: "m" },
    { value: "m²", title: "m²" },
    { value: "m³", title: "m³" },
    { value: "psch", title: "psch" },
    { value: "St", title: "St" },
    { value: "l", title: "l" },
    { value: "t", title: "t" },
    { value: "kg", title: "kg" },
    { value: "W", title: "W" },
    { value: "kW", title: "kW" },
    { value: "MW", title: "MW" },
    { value: "kVA", title: "kVA" },
  ];
};

export const AssetPermissions = [
  { value: "READ", label: "READ" },
  { value: "WRITE", label: "WRITE" },
  { value: "NONE", label: "NONE" },
];

export const riskOptions = [
  {
    value: "niedrig",
    title: {
      en: "Low",
      de: "niedrig",
    },
  },
  {
    value: "mittel",
    title: {
      en: "Medium",
      de: "mittel",
    },
  },
  {
    value: "hoch",
    title: {
      en: "High",
      de: "hoch",
    },
  },
];

export const riskTypeOptions = [
  {
    title: "Low",
    language: "en",
    value: "niedrig",
    color:"#70ad47",
    monitoringColor:"#70ad47",
    displayOrder:3,
  },
  {
    title: "niedrig",
    language: "de",
    value: "niedrig",
    color:"#70ad47",
    monitoringColor:"#70ad47",
    displayOrder:3,
  },
  {
    title: "Medium",
    language: "en",
    value: "mittel",
    color:"#f7a63e",
    monitoringColor:"#f7a63e",
    displayOrder:2,
  },
  {
    title: "mittel",
    language: "de",
    value: "mittel",
    color:"#f7a63e",
    monitoringColor:"#f7a63e",
    displayOrder:2,
  },
  {
    title: "High",
    language: "en",
    value: "hoch",
    color:"#aa3939",
    monitoringColor:"#aa3939",
    displayOrder:1,
  },
  {
    title: "hoch",
    language: "de",
    value: "hoch",
    color:"#aa3939",
    monitoringColor:"#aa3939",
    displayOrder:1,
  },
];

export const statusTypeOptions = [
  {
    title: "Nicht angefangen",
    language: "de",
    value: "nicht angefangen",
   
  },{
    title: "Not Started",
    language: "en",
    value: "nicht angefangen",
    
  },{
    title: "Laufend",
    language: "de",
    value: "laufend",
   
  },{
    title: "On Going",
    language: "en",
    value: "laufend",
   
  },{
    title: "Vollendet",
    language: "de",
    value: "vollendet",
    
  },{
    title: "Completed",
    language: "en",
    value: "vollendet",
    
  },
]

export const priorityOptions = [
  {
    value: "kurzfristig",
    nonCostDefect: true,
    title: {
      en: "Short Term",
      de: "kurzfristig",
    },
  },
  {
    value: "mittelfristig",
    nonCostDefect: true,
    title: {
      en: "Medium Term",
      de: "mittelfristig",
    },
  },
  {
    value: "langfristig",
    nonCostDefect: true,
    title: {
      en: "Long Term",
      de: "langfristig",
    },
  },
  {
    value: "Multiple",
    nonCostDefect: false,
    title: {
      en: "Multiple",
      de: "Multiple",
    },
  },
];

export const priorityTypeOptions = [
  {
    title: "Short Term",
    language: "en",
    value: "kurzfristig",
  },
  {
    title: "kurzfristig",
    language: "de",
    value: "kurzfristig",
  },
  {
    title: "Medium Term",
    language: "en",
    value: "mittelfristig",
  },
  {
    title: "mittelfristig",
    language: "de",
    value: "mittelfristig",
  },
  {
    title: "Long Term",
    language: "en",
    value: "langfristig",
  },
  {
    title: "langfristig",
    language: "de",
    value: "langfristig",
  },
  {
    title: "Multiple",
    language: "en",
    value: "Multiple",
  },
  {
    title: "Multiple",
    language: "de",
    value: "Multiple",
  },
];

export const defectTypes = [
  {
    value: "Defekt",
    title: {
      en: "Defect",
      de: "Defekt",
    },
  },
  {
    value: "Lebenszyklus",
    title: {
      en: "Life cycle",
      de: "Lebenszyklus",
    },
  },
  {
    value: "Konformität",
    title: {
      en: "Conformity",
      de: "Konformität",
    },
  },
  {
    value: "Prävention",
    title: {
      en: "Prevention",
      de: "Prävention",
    },
  },
];

export const defectTypeOptions = [
  {
    title: "Defect",
    language: "en",
    value: "Defekt",
  },
  {
    title: "Defekt",
    language: "de",
    value: "Defekt",
  },
  {
    value: "Lebenszyklus",
    title: "Life cycle",
    language: "en",
  },
  {
    value: "Lebenszyklus",
    title: "Lebenszyklus",
    language: "de",
  },
  {
    value: "Konformität",
    title: "Conformity",
    language: "en",
  },
  {
    value: "Konformität",
    title: "Konformität",
    language: "de",
  },

  {
    value: "Prävention",
    title: "Prevention",
    language: "en",
  },
  {
    value: "Prävention",
    title: "Prävention",
    language: "de",
  },
];

export const getLanguageList = () =>{
  return ([
    { value: "de", label: "Deutsch" },
    { value: "en", label: "English" },
  ]);
} 
 
export const optionAll = {
  value: "All",
  title: {
    en: "All",
    de: "Alle",
  },
};

export const getLanguageBasedOption = (option, langCode) => {
  if (option !== null && option !== "" && option?.title !== undefined) {
    const tOption = option.title[langCode];
    if (tOption !== null) {
      return tOption;
    } else {
      return option.title["de"];
    }
  } else {
    return "";
  }
};

export const extractLanguageBasedTranslation = (
  from,
  tobeTranslated,
  langCode
) => {
  const objectfound = from.find((f) => f.value === tobeTranslated);
  return getLanguageBasedOption(objectfound, langCode);
};

export const costTypeOptions = [
  {
    title: "Cost Defect",
    language: "en",
    value: "kosten",
    costDefect:true,
  },
  {
    title: "kostenpflichtigen Mangel",
    language: "de",
    value: "kosten",
    costDefect:true,
  }, {
    title: "Non Cost Defect",
    language: "en",
    value: "Nicht kostenpflichtigen Mangel",
    costDefect:false,
  },
  {
    title: "Nicht kostenpflichtigen Mangel",
    language: "de",
    value: "Nicht kostenpflichtigen Mangel",
    costDefect:false,
  },
]


export const documentStatusOptions = [
  {
    value: "Missing",
    title: {
      en: "Missing",
      de: "Missing",
    },
  },
  {
    value: "Defect Free",
    title: {
      en: "Defect Free",
      de: "Defect Free",
    },
  },
];

export const statusOptions = [
  {
    value: "Kosten",
    title: {
      en: "Costs",
      de: "Kosten",
    },
  },
  {
    value: "erledigt",
    title: {
      en: "Completed",
      de: "erledigt",
    },
  },
  {
    value: "offen",
    title: {
      en: "Open",
      de: "offen",
    },
  },
  {
    value: "nicht verfügbar",
    title: {
      en: "Not Available",
      de: "nicht verfügbar",
    },
  },
];

export const inspectionCategories = [
  {
    value: "Baurecht",
    title: {
      en: "Construction law",
      de: "Baurecht",
    },
  },
  {
    value: "Baudokumentation",
    title: {
      en: "Building documentation",
      de: "Baudokumentation",
    },
  },
  {
    value: "Technische Unterlagen",
    title: {
      en: "Building documentation",
      de: "Technische Unterlagen",
    },
  },
  {
    value: "Umwelt",
    title: {
      en: "Environment",
      de: "Umwelt",
    },
  },
  {
    value: "Objektverwaltung",
    title: {
      en: "Property management",
      de: "Objektverwaltung",
    },
  },
  {
    value: "Sonstiges",
    title: {
      en: "Others",
      de: "Sonstiges",
    },
  },
];

export const others = [
  {
    title: "Others",
    language: "en",
    value: "Sonstiges",
  },
  {
    title: "Sonstiges",
    language: "de",
    value: "Sonstiges",
  },
];


export const reportOptions = {
  lang: [
    { label: "English", value: "en" },
    { label: "Deutsch", value: "de" },
  ],
  version: [
    {
      value: "Draft",
      label: "Draft",
    },
    {
      value: "Final",
      label: "Final",
    },
  ],
};

export const roles = 
["PROJECT LEADER", "PROJECT ASSISTANT", "USER (INTERNAL)", "QM PROCESS", "USER (EXTERNAL)"]