import React,{useState,useEffect} from "react";
import { Field } from "formik";
import { Grid, TextField, Typography } from "@mui/material";
import PlacesAutocomplete from "../../../../../../components/locations/PlacesAutocomplete";
import { getLabel,getOverlineText } from "../../../../../../utils/helper";


const AddressForm = (formikProps) => {
  const { values,errors, touched} = formikProps;
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [getAddress, setGetAddress] = useState({});
  const [fullAddress, setFullAddress] = useState("");

  useEffect(() => {
    if (!!getAddress.hasOwnProperty("street")) {
      values.address = getAddress?.street;
      values.city = getAddress?.city;
      values.country = getAddress?.country;
      values.state = getAddress?.state;
      values.postalCode = getAddress?.postalCode;
    }
    setFullAddress(
      `${getAddress?.street} ${getAddress?.city} ${getAddress?.postalCode} ${getAddress?.state} ${getAddress?.country}`
    );
  }, [getAddress]);

  useEffect(() => {
    setGetAddress({
      street: values.address,
      city: values.city,
      country: values.country,
      state: values.state,
      postalCode: values.postalCode,
    });
    setFullAddress(
      `${values.address} ${values.city} ${values.postalCode} ${values.state} ${values.country}`
    );
  }, [values]);
  return (
    <>
       <Grid
        container
        className="custom-grid-container"
        justify="start"
      >
        <Grid item md={12} xs={12}>
          <Typography>
            {getOverlineText("_Address Details")}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} style={{margin:10}} className="grid-fields">
          <PlacesAutocomplete
            setLat={setLat}
            setLng={setLng}
            setGetAddress={setGetAddress}
            fullAddress={fullAddress.trim()}
          />
        </Grid>
        <Grid item md={5} xs={11} className="m-1">
          <Field
            name="address"
            id="address_address"
            label={getLabel("_Address")}
            as={TextField}
            size="small"
            dense="true"
            className="grid-fields"
            error={touched.address && errors.address}
            helperText={touched.address && errors.address}
          />
        </Grid>
        <Grid item md={5} xs={11} className="m-1">
          <Field
            name="city"
            label={getLabel("_City")}
            id="city"
            as={TextField}
            size="small"
            dense="true"
            className="grid-fields"
            error={touched.city && errors.city}
            helperText={touched.city && errors.city}
          />
        </Grid>
        <Grid item md={5} xs={11} className="m-1">
          <Field
            name="postalCode"
            label={getLabel("_zip-code")}
            id="postalCode"
            as={TextField}
            size="small"
            dense="true"
            className="grid-fields"
            error={touched.postalCode && errors.postalCode}
            helperText={touched.postalCode && errors.postalCode}
          />
        </Grid>
        <Grid item md={5} xs={11} className="m-1">
          <Field
            name="state"
            label={getLabel("_State")}
            as={TextField}
            id="state"
            size="small"
            dense="true"
            className="grid-fields"
            error={touched.state && errors.state}
            helperText={touched.state && errors.state}
          />
        </Grid>
        <Grid item md={5} xs={11} className="m-1">
          <Field
            name="country"
            label={getLabel("_Country")}
            id="country"
            as={TextField}
            size="small"
            dense="true"
            className="grid-fields"
            error={touched.country && errors.country}
            helperText={touched.country && errors.country}
          />
        </Grid>
        </Grid>
    </>
  );
};

export default AddressForm;
