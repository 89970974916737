import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Tooltip } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Popover from "@mui/material/Popover";
import { Divider, Grid, Typography } from "@mui/material";
import InflationRateDialogBox from "./InflationRateDialogBox";
import { useDispatch, useSelector } from "react-redux";
import {
  updateInflationRate,
  getAllCostelementData,
  exportExcleSheetCost,
} from "../../../api/costDataApi";
import { useSnackbar } from "notistack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import CostTableDialog from "./CostTableDialog";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CostDataFilter from "./CostDataFilter";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { getIntlTranslation } from "../../../utils/helper";
import ListIcon from "@mui/icons-material/List";
import { useIntl } from "react-intl";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const AppBarContent = ({
  searchString,
  setSearchString,
  expandAndCollapse,
  treeData,
  treeDataCopy,
  setTreeData,
  toggleExpandedForAll,
}) => {
  const [open, setOpen] = useState(false);
  const [openImportSheetDialog, setOpenImportSheetDialog] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [importData, setImportData] = useState([]);
  const dispatch = useDispatch();
  const costDataList = useSelector((state) => state.costData);
  const inflactionData = useSelector((state) => state.costData.inflactionData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isExpand, setIsExpand] = useState(false);
  const [value, setValue] = useState([]);
  const [seletedCategoryList, setSelectedCategoryList] = useState([]);
  const intl = useIntl();

  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    if (isExpand) {
      expandAndCollapse(true);
    } else {
      expandAndCollapse(false);
    }
  }, [isExpand]);

  useEffect(() => {
    // get all category from the copy lists
    if (treeDataCopy?.length > 0) {
      const data = treeDataCopy.map((category) => {
        const res = {
          id: category.id,
          name: category.title,
        };
        return res;
      });
      setCategoryList(data);
    } else {
      setCategoryList([]);
    }
  }, [treeDataCopy]);

  const filterDataWithCategoryList = (element) => {
    if (!!element.length) {
      var filteredArray = treeDataCopy.filter(function (array_el) {
        const filterSome = element.some(function (anotherOne_el) {
          return anotherOne_el.id === array_el.id;
        });
        return filterSome;
      });
      setTreeData(filteredArray);
    } else {
      setTreeData(treeDataCopy);
    }
  };

  useEffect(() => {
    // filter data bass on chosed category list
    filterDataWithCategoryList(seletedCategoryList);
  }, [seletedCategoryList]);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const openPoper = Boolean(anchorEl);
  const id = openPoper ? "simple-popover" : undefined;

  const allCostElements = useSelector(
    (state) => state.costData.allCostElements
  );
  useEffect(() => {
    if (allCostElements.type === "FETCHED") {
      setExportData(allCostElements.data);
    }
  }, [allCostElements]);
  const { enqueueSnackbar } = useSnackbar();

  const handleToggleOpen = () => {
    setOpen(!open);
  };
  const handleSave = (rate) => {
    const res = rate.value;
    const convert = parseFloat(res, 10);
    const data = { rate: convert };
    dispatch(updateInflationRate(data));
  };

  useEffect(() => {
    if (inflactionData.type === "UPDATED") {
      setOpen(false);
    }
  }, [dispatch, enqueueSnackbar, inflactionData.type]);

  useEffect(() => {
    if (exportData.length <= 0) {
      dispatch(getAllCostelementData());
    }
  }, [dispatch, exportData]);

  const handleOnExport = () => {
    const data = [];
    exportData.forEach((element) => {
      let val = {
        "Measure Code": element.measureCode,
        "Element code": element.elementCode,
        "Element name": element.elementName,
        "kosten element code": element.code,
        "kosten element beschreibung": element.name,
        Unit: element.unit,
        Von: element.von,
        Mittel: element.mittel,
        Bis: element.bis,
      };
      data.push(val);
    });

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* calculate column width */
    ws["!cols"] = [
      { wch: 14 },
      { wch: 15 },
      { wch: 40 },
      { wch: 17 },
      { wch: 80 },
      { wch: 10 },
      { wch: 14 },
      { wch: 14 },
      { wch: 14 },
    ];
    var wsrows = [{ hpt: 30 }];

    ws["!rows"] = wsrows;
    XLSX.writeFile(wb, "costElement.xlsx");
  };
  const readFileContents = (file) => {
    file.stopPropagation();
    file.preventDefault();

    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });
        const firstSheet = workbook.SheetNames[0];
        const excelRows = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[firstSheet]
        );
        const data = [];
        excelRows.forEach((element) => {
          let val = {
            measureCode: element["Measure Code"],
            elementCode: element["Element code"],
            elementName: element["Element name"],
            code: element["kosten element code"],
            name: element["kosten element beschreibung"],
            unit: element.Unit,
            von: element.Von,
            mittel: element.Mittel,
            bis: element.Bis,
          };
          data.push(val);
        });
        setImportData(data);

        if (!!data.length) {
          setOpenImportSheetDialog(true);
        }
      } catch (e) {
        return;
      }
    };
    // Open the file in binary mode
    fileReader.readAsBinaryString(files[0]);
  };
  const handleImportSheetClose = () => {
    setOpenImportSheetDialog(false);
  };
  const onSaveDataSheet = () => {
    const newData = [];
    importData.forEach((element) => {
      let newObject = {
        ...element,
        type: "costElementExcelDto",
      };
      newData.push(newObject);
    });
    dispatch(exportExcleSheetCost(newData));
    setOpenImportSheetDialog(false);
  };

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 100 }}>
      <Paper>
        <AppBar
          position="static"
          style={{
            backgroundColor: "#7facd5",
            color: "#ffffff",
            borderRadius: 5,
          }}
        >
          <Toolbar style={{ minHeight: 55, padding: 0, marginLeft: 0 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexGrow: 1,
              }}
            >
              <Paper style={{ height: 30, marginLeft: 5 }}>
                <Button
                  variant="contained"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                  }}
                  onClick={(e) => {
                    setTreeData(treeDataCopy);
                    setSelectedCategoryList([]);
                    setValue({});
                  }}
                >
                  {getIntlTranslation("_view-all")}
                </Button>
              </Paper>
              {!isExpand ? (
                <Paper style={{ height: 30, marginLeft: 5 }}>
                  <Button
                  variant="contained"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                  }}
                    onClick={() => {
                      setIsExpand(true);
                    }}
                  >
                    {getIntlTranslation("_Expand")}
                  </Button>

                </Paper>
              ) : (
                <Paper style={{ height: 30, marginLeft: 5 }}>
                  <Button
                  variant="contained"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                  }}
                    onClick={() => {
                      setIsExpand(false);
                    }}
                  >
                    {getIntlTranslation("_Collapse")}
                  </Button>
                </Paper>
              )}
              <Divider
                orientation="vertical"
                style={{
                  width: 1,
                  margin: "5px 5px",
                  backgroundColor: "#244a9a",
                }}
                flexItem
              />
              <CostDataFilter
                treeData={treeData}
                setTreeData={setTreeData}
                treeDataCopy={treeDataCopy}
                toggleExpandedForAll={toggleExpandedForAll}
              />
              <Divider
                orientation="vertical"
                style={{
                  width: 1,
                  margin: "5px 5px",
                  backgroundColor: "#244a9a",
                }}
                flexItem
              />
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                  height: 40,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={intl.formatMessage({ id: "_Search Costs" })}
                  inputProps={{ "aria-label": "search Cost" }}
                  value={searchString}
                  onChange={(event) => setSearchString(event.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Divider
                orientation="vertical"
                style={{
                  width: 1,
                  margin: "5px 5px",
                  backgroundColor: "#244a9a",
                }}
                flexItem
              />
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  height: 40,
                  marginRight: 1,
                }}
              >
                <Tooltip
                  title={getIntlTranslation("_Download Cost element")}
                  placement="left"
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="Download excel sheet"
                    component="label"
                    onClick={() => handleOnExport()}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  style={{
                    width: 1,
                    margin: "5px 5px",
                    backgroundColor: "#244a9a",
                  }}
                  flexItem
                />
                <Tooltip
                  title={getIntlTranslation("_Upload cost elment")}
                  placement="left"
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="Upload excel sheet"
                    component="label"
                  >
                    <input
                      hidden
                      accept=".xlsx"
                      onChange={(e) => readFileContents(e)}
                      type="file"
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <UploadFileIcon />
                  </IconButton>
                </Tooltip>
              </Paper>
            </div>
          </Toolbar>
        </AppBar>
      </Paper>
      <AppBar
        position="static"
        style={{
          backgroundColor: "rgba(36,74,154,1)",
          color: "#ffffff",
          height: 45,
          borderRadius: 5,
        }}
      >
        <Toolbar style={{ minHeight: 35, padding: 0 }}>
          <>
            <Tabs
              style={{ height: 30, padding: 0, width: "98%" }}
              value={value}
              onChange={(event, newValue) => {
                setSelectedCategoryList([newValue]);
                setValue(newValue);
              }}
              textColor="#d9534f"
              indicatorColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#d9534f",
                  height: 4,
                  borderRadius: 40,
                },
              }}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable categories"
            >
              {categoryList.map((m, index) => {
                return (
                  <Tab
                    style={{
                      color: "#ffffff",
                      borderBottom: value.id === m.id && "solid red 3px",
                      padding: 10,
                      margin: 5,
                      height: "100%",
                      maxWidth: "fit-content",
                    }}
                    label={
                      <Typography variant="button" display="block" gutterBottom>
                        {m.name}
                      </Typography>
                    }
                    key={m.value}
                    value={m}
                  />
                );
              })}
            </Tabs>
          </>
        </Toolbar>
      </AppBar>
      {openImportSheetDialog && !!importData.length && (
        <CostTableDialog
          data={importData}
          open={openImportSheetDialog}
          handleClose={handleImportSheetClose}
          onSave={onSaveDataSheet}
        />
      )}
      <InflationRateDialogBox
        open={open}
        handleSave={handleSave}
        close={handleToggleOpen}
      />
    </Box>
  );
};

export default AppBarContent;
