import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Box,
  Divider,
  Chip,
  Checkbox,
  Card,
  CardContent,
  List,
  Typography,
  CardMedia,
  Collapse,
  CardActions,
  Tooltip,
  Button,
} from "@mui/material";
import CustomIconButton from "../../../../components/buttons/icons-buttons/CustomIconButton";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import { useIntl } from "react-intl";
import {
  getIntlTranslation,
  getTranslationVariantText,
} from "../../../../utils/helper";
import CostSuggestionDialog from "./CostSuggestionDialog";

const CostSuggestion = (props) => {
  const intl = useIntl();
  const { values, setFieldValue } = props;
  const [costSuggestions, setCostSuggestions] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState([]);
  const [viewCostSuggestion, setViewCostSuggestion] = useState(false);
  useEffect(() => {
    if (!!values.costSuggestions && values.costSuggestions.length > 0) {
  
      //let test = values.costSuggestions.map((m,index) => ({...m,code:`${m.code}_${index}`}));
      setCostSuggestions([...values.costSuggestions]);
    } else {
      setCostSuggestions([]);
    }
  }, [values.costSuggestions]);

  const renderCostParameters = (option) => {
    return ""
      .concat(`${intl.formatMessage({ id: "Price per Unit" })}: `)
      .concat(`${intl.formatMessage({ id: "_from" })} €${option?.cost?.von}, `)
      .concat(
        `${intl.formatMessage({ id: "_middle" })} €${option?.cost?.mittel}, `
      )
      .concat(`${intl.formatMessage({ id: "_upto" })} €${option?.cost?.bis}, `)
      .concat(`${intl.formatMessage({ id: "unit" })}: ${option?.unit}`);
  };

  const renderCostSuggestions = (option) => {
    return (
      <ListItem
        className={"d-flex align-items-center w-100"}
        key={`option-${option.code}`}
        secondaryAction={
          option.children?.length > 0 && (
            <>
              {option.code === selectedDetail?.code ? (
                <CustomIconButton
                  id="close_details"
                  icon={
                    <UnfoldLessIcon
                      fontSize="20"
                      style={{ color: "#d08f8e" }}
                    />
                  }
                  permission={null}
                  handleClick={(e) => {
                    setSelectedDetail(null);
                  }}
                  tooltip={{
                    title: getIntlTranslation("tooltip.Click-to-close"),
                  }}
                  style={{ marginLeft: "auto" }}
                  aria-describedby={`close cost details`}
                />
              ) : (
                <CustomIconButton
                  id="open_details"
                  icon={
                    <UnfoldMoreIcon
                      fontSize="20"
                      style={{ color: "#d08f8e" }}
                    />
                  }
                  permission={null}
                  handleClick={(e) => {
                    setSelectedDetail(option);
                  }}
                  tooltip={{
                    title: getIntlTranslation("tooltip.show-more-details"),
                  }}
                  style={{ marginLeft: "auto" }}
                  aria-describedby={`open cost details`}
                />
              )}
            </>
          )
        }
        disablePadding
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            tabIndex={-1}
            checked={option.code === values.costParameters?.code}
            disableRipple
            onChange={(e) => {
              if (e.target.checked) {
                setFieldValue("costParameters", option);
                setFieldValue("pricePerUnit", option?.cost?.mittel);
                setFieldValue("unit", option?.unit);
              } else {
                setFieldValue("costParameters", null);
                setFieldValue("pricePerUnit", null);
                setFieldValue("unit", null);
              }
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="subtitile"
              style={{
                color: option.default ? "#008000" : "inherit",
                fontWeight: "bold",
              }}
            >
              {option?.name}
            </Typography>
          }
          secondary={
            <>
              <Typography variant="body2" style={{ marginLeft: 5 }}>
                {renderCostParameters(option)}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  return (
    <>
      {values.costDefect && (
        <>
          {!!values.measureDescription ? (
            <>
              {costSuggestions.length > 0 ? (
                <Box style={{ width: "49%" }}>
                  <Card className="d-flex flex-column align-items-center grid-fields p-0 m-1 w-100">
                    <Divider style={{ margin: "5px 0px", width: "100%" }}>
                      <Chip
                        className="g-btn-secondary"
                        label={getIntlTranslation("_cost-suggestion")}
                      />
                    </Divider>
                    <CardContent
                      dividers
                      className="p-2 w-100 custom-scrollBar"
                      style={{
                        height: 350,
                        maxHeight: 350,
                        overflowY: "auto",
                        overFlowX: "hidden",
                      }}
                    >
                      <List className="w-100">
                        {costSuggestions.map((option) => (
                          <>
                            {renderCostSuggestions(option)}
                            {option.code === selectedDetail?.code &&
                              selectedDetail.children.length > 0 && (
                                <Box style={{ marginLeft: 20 }}>
                                  <Collapse
                                    in={
                                      option.code === selectedDetail?.code &&
                                      selectedDetail.children.length > 0
                                    }
                                    style={{ transformOrigin: "0 0 0" }}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <List>
                                      {selectedDetail.children.map(
                                        (child, index) =>
                                          renderCostSuggestions(
                                            child,
                                            index,
                                            true
                                          )
                                      )}
                                    </List>
                                  </Collapse>
                                </Box>
                              )}
                          </>
                        ))}
                      </List>
                    </CardContent>
                    <CardActions
                      style={{ marginBottom: "auto", marginLeft: "auto" }}
                      className="w-100"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-end">
                        <Tooltip title={getIntlTranslation("tooltip.show-all")}>
                          <Button
                            variant="contained"
                            className="g-btn-secondary"
                            size="small"
                            onClick={(e) => setViewCostSuggestion(true)}
                          >
                            {getTranslationVariantText("_view-all", "caption")}
                          </Button>
                        </Tooltip>
                      </div>
                    </CardActions>
                  </Card>
                </Box>
              ) : (
                <>
                  <Box style={{ width: "49%" }}>
                    <Card className="d-flex flex-column align-items-center grid-fields p-0 m-1 w-100">
                      <Divider style={{ margin: "5px 0px", width: "100%" }}>
                        <Chip
                          className="g-btn-secondary"
                          label={getIntlTranslation("_cost-suggestion")}
                        />
                      </Divider>

                      <CardContent
                        dividers
                        className="p-2 w-100 custom-scrollBar d-flex flex-column align-items-center"
                        style={{
                          height: 350,
                          maxHeight: 350,
                          overflowY: "auto",
                          overFlowX: "hidden",
                        }}
                      >
                        <CardMedia
                          component="img"
                          style={{
                            width: "60%",
                            height: "65%",
                            margin: 5,
                            padding: 10,
                          }}
                          image="/assets/images/under_construction.svg"
                          alt="under construction"
                        />
                        {getTranslationVariantText(
                          "_cost-suggestion-not-available",
                          "subtitle",
                          {
                            padding: 10,
                            fontWeight: "bold",
                          }
                        )}
                      </CardContent>
                      <CardActions
                        style={{ marginBottom: "auto", marginLeft: "auto" }}
                        className="w-100"
                      >
                        <div className="d-flex align-items-center w-100 justify-content-end">
                          <Tooltip
                            title={getIntlTranslation("tooltip.show-all")}
                          >
                            <Button
                              variant="contained"
                              className="g-btn-secondary"
                              size="small"
                              onClick={(e) => setViewCostSuggestion(true)}
                            >
                              {getTranslationVariantText(
                                "_view-all",
                                "caption"
                              )}
                            </Button>
                          </Tooltip>
                        </div>
                      </CardActions>
                    </Card>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <Box style={{ width: "49%" }}>
                <Card className="d-flex flex-column align-items-center grid-fields p-0 m-1 w-100">
                  <Divider style={{ margin: "5px 0px", width: "100%" }}>
                    <Chip
                      className="g-btn-secondary"
                      label={getIntlTranslation("_cost-suggestion")}
                    />
                  </Divider>

                  <CardContent
                    dividers
                    className="p-2 w-100 custom-scrollBar d-flex flex-column align-items-center"
                    style={{
                      height: 350,
                      maxHeight: 350,
                      overflowY: "auto",
                      overFlowX: "hidden",
                    }}
                  >
                    <CardMedia
                      component="img"
                      style={{
                        width: "60%",
                        height: "65%",
                        margin: 5,
                        padding: 10,
                      }}
                      image="/assets/images/selection.svg"
                      alt="no selected option"
                    />
                    {getTranslationVariantText(
                      "_cost-suggestion-select-defect-measure",
                      "subtitle",
                      {
                        padding: 10,
                        fontWeight: "bold",
                      }
                    )}
                  </CardContent>
                  <CardActions
                    style={{ marginBottom: "auto", marginLeft: "auto" }}
                    className="w-100"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-end">
                      <Tooltip title={getIntlTranslation("tooltip.show-all")}>
                        <Button
                          variant="contained"
                          className="g-btn-secondary"
                          size="small"
                          onClick={(e) => setViewCostSuggestion(true)}
                        >
                          {getTranslationVariantText("_view-all", "caption")}
                        </Button>
                      </Tooltip>
                    </div>
                  </CardActions>
                </Card>
              </Box>
            </>
          )}
        </>
      )}
      {viewCostSuggestion && (
                <CostSuggestionDialog
                  open={viewCostSuggestion}
                  onClose={() => setViewCostSuggestion(false)}
                  onCopy={(selectedItem) => {
                    if(!!selectedItem){
                      let item = {
                        code:selectedItem.costCode,
                        cost:selectedItem.cost,
                        name:selectedItem.description,
                        nodeType: "costelement",
                        unit:selectedItem.unit,
                        hasChildren: false,
                        default:true,
                        children:[],
                      }
                      setFieldValue("costParameters", item);
                      setFieldValue("pricePerUnit", item?.cost?.mittel);
                      setFieldValue("unit", item?.unit);
                      setCostSuggestions((prev) => {
                        let rest = prev.filter((prevItem) => prevItem.code !== item.code);
                        return [...rest, ...[item]];
                      })
                      setViewCostSuggestion(false);
                    }else{
                      setFieldValue("costParameters", null);
                      setFieldValue("pricePerUnit", null);
                      setFieldValue("unit", null);
                    }
                  }}
                />
              )}
    </>
  );
};

CostSuggestion.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
};
export default CostSuggestion;
