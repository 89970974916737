import React from 'react';
import TDDProjectDashboardHome from "./TDDProjectDashboardHome"
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const ProjectDashboardRouteConfig = {
	routes: [
		{
    path: `${tddRoot}/project/project-dashboard`,
    exact: true,
    name: <IntlMessages id={"menu.menu.project-dashboard"}/>,
    element: <TDDProjectDashboardHome/>,
    auth: [],
  },
  {
    path: `${tddRoot}/`,
    exact: true,
    name: <IntlMessages id={"menu.project-dashboard"}/>,
    element: <TDDProjectDashboardHome/>,
    auth: [],
  },
  {
    path: `${tddRoot}/project/`,
    exact: true,
    name: <IntlMessages id={"menu.project-dashboard"}/>,
    element: <TDDProjectDashboardHome/>,
    auth: [],
  },
	]
};
export default ProjectDashboardRouteConfig;