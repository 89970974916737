import React, { createContext, useContext, useEffect, useState } from "react";
import { database } from "../../../../../index";
import { ref, set, onValue, off, get } from "firebase/database";
import { useSelector } from "react-redux";

export const DocumentTreeContext = createContext(null);

export const  useDocumentTreeContext = () => {
  const context = useContext(DocumentTreeContext);

  if(context === undefined){
    throw new Error("context should be used")
  }
return context;
}

export const DocumentTreeContextProvider = ({ children, reportType }) => {
    const scope = useSelector((state) => state.container.scope);
    const openAsset = useSelector((state) => state.openAsset);
    const [documentTree, setDocumentTree] = useState([]);
    
  useEffect(() => {
    const treePath = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/document-tree`;
    const treeRef = ref(database, treePath);
    onValue(treeRef, (snapshot) => {
      const val = snapshot.val();
      setDocumentTree(val);
    });
    return () => off(treeRef);
  }, []);

    return (
        <DocumentTreeContext.Provider value={{documentTree}}>
            {children}
        </DocumentTreeContext.Provider>
    );
};


