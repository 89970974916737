import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import DefectGridContainer from "../defect-grid/DefectGridContainer";
import { fetchDefectListForCategory } from "../../../api/keyFindingApi";
import { resetSliceState } from "../../defects/defectSlice";
import { useSnackbar } from "notistack";

function KeyFindingsContainer(props) {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.tddKeyFinding);
  const [tabSelected, setTabSelected] = useState({});
  const [navLinks, setNavLinks] = useState([]);
  const tddDefectSection = useSelector((state) => state.tddDefectSection);
  const { enqueueSnackbar } = useSnackbar();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevSubAssetIdent = usePrevious(openAsset.subAssetIdent);

  useEffect(() => {
    if (props.navLinks.length > 0) {
      setNavLinks(props.navLinks);
    }
  }, [props.navLinks]);
  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (
      !isObjEmpty(state.tab) &&
      (prevSubAssetIdent === openAsset.subAssetIdent || !prevSubAssetIdent)
    ) {
      setTabSelected(state.tab);
      if (!!openAsset.subAssetIdent && !!state.tab.ident) {
        dispatch(
          fetchDefectListForCategory({
            projectIdent: scope.projectIdent,
            subAssetIdent: openAsset.subAssetIdent,
            categoryIdent: state.tab.ident,
          })
        );
      }
    }

    if (
      prevSubAssetIdent !== openAsset.subAssetIdent &&
      !!props.tabs &&
      !!prevSubAssetIdent
    ) {
      setTabSelected(props?.tabs[0]);
      if (!!openAsset.subAssetIdent && !!props?.tabs[0]?.ident) {
        dispatch(
          fetchDefectListForCategory({
            projectIdent: scope.projectIdent,
            subAssetIdent: openAsset.subAssetIdent,
            categoryIdent: props.tabs[0].ident,
          })
        );
      }
    }
  }, [dispatch, openAsset.subAssetIdent, scope.projectIdent, state.tab]);

  useEffect(() => {
    if (
      tddDefectSection.multiUpdate?.type === "SAVED" &&
      !!tddDefectSection.multiUpdate.data
    ) {
      tddDefectSection.multiUpdate.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetSliceState());
      if (!!openAsset.subAssetIdent) {
        dispatch(
          fetchDefectListForCategory({
            projectIdent: scope.projectIdent,
            subAssetIdent: openAsset.subAssetIdent,
            categoryIdent: state.tab.ident,
          })
        );
      }
      // dispatch(resetSliceState());
    }
  }, [
    tddDefectSection.multiUpdate,
    dispatch,
    enqueueSnackbar,
    openAsset.subAssetIdent,
    scope.projectIdent,
    state.tab.ident,
    openAsset,
  ]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {props.tabs.length > 0 && (
          <>
            <DefectGridContainer
              navLinks={navLinks}
              tabs={props.tabs}
              tabSelected={tabSelected}
              onTabSelect={(val) => setTabSelected(val)}
              onUpdate={(tab) => {
                dispatch(
                  fetchDefectListForCategory({
                    projectIdent: scope.projectIdent,
                    subAssetIdent: openAsset.subAssetIdent,
                    categoryIdent: tab.ident,
                  })
                );
              }}
            />
          </>
        )}
      </Box>
    </>
  );
}

KeyFindingsContainer.propTypes = {
  tabs: PropTypes.array,
  navLinks: PropTypes.array,
};

export default KeyFindingsContainer;
