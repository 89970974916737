import React from "react";

const PhotoAlbum = (props) => {
  const createRenderProps = (photo,index) => {
    let maxPhotosPerRow = props.rowConstraints?.maxPhotos || 2;
    return {
      photo: {
        ...photo,
        order:index+1,
        style: {
          aspectRatio: photo.width / photo.height,
          boxSizing: "content-box",
          display: "block",
          padding: props.padding || "20px",
          height: "auto",
          width: photo.width,
          margin: props.spacing
        },
      },
      wrapperStyle: {
        aspectRatio: photo.width / photo.height,
        boxSizing: "content-box",
        display: "block",
        padding: props.padding || "20px",
        width: `calc((100% - 110px) /${maxPhotosPerRow} )`,
      },
    };
  };

  return (
    <div
      className="react-photo-album react-photo-album--rows"
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        flexDirection: "row",
      }}
    >
      {props.photos.length > 0 &&
        props.photos.map((photo,index) =>
          props.renderPhoto(createRenderProps(photo,index))
        )}
    </div>
  );
};

export default PhotoAlbum;
