import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import IntlMessages from "../../../utils/IntlMessages";

const TamModuleContainer = props => {

  return (
    <div>TamModule</div>
  )
}

TamModuleContainer.propTypes = {}

export default TamModuleContainer;