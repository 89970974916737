import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCommentListState } from "../../../../component-bundle/comments/commentSlice";
import { autoSaveReportText, loadAllChapterData, loadChapterData, loadChapters, loadSectionData, saveProjectInfo, saveReportText } from "../../../api/chaptersApi";
import { setCurrentSelection, resetState } from "../../slice/chaptersSlice";
import { resetPromptState } from "../../slice/dataSlice";
import { useSnackbar } from 'notistack';


function useChapterHook () {
  const dispatch = useDispatch();
  const chapterState = useSelector((state) => state.tddChapters);
  const openAsset = useSelector((state) => state.openAsset);
  const scope = useSelector((state) => state.container.scope);
  const [currentChapterDatas, setCurrentChapterDatas] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [chapterList, setChapterList] = useState([]);

  useEffect(() => {
    if (
      chapterState.type === 'SUCCESS' &&
      !!chapterState.chapterList &&
      chapterState.chapterList.length > 0
    ) {
      setChapterList(chapterState.chapterList);
      dispatch(resetState());
    }
    if (
      chapterState.type === 'SUCCESS' &&
      !!chapterState.chapterReviewData &&
      chapterState.chapterReviewData.length > 0
    ) {
      setChapterList(chapterState.chapterReviewData);
      dispatch(resetState());
    }
    if (chapterState.type === 'SUCCESS' && !!chapterState.chapterData) {
      setCurrentChapterDatas(chapterState.chapterData);
      dispatch(resetState());
    }
    if (chapterState.type === 'SAVED' && !!chapterState.chapterData) {
      chapterState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      dispatch(resetPromptState());
      dispatch(resetState());
    }
  }, [chapterState, dispatch, enqueueSnackbar]);


const onLoadAllChapters = (reportType) => {
  dispatch(
    loadAllChapterData({
      projectIdent: scope.projectIdent,
      assetIdent: openAsset.ident,
      language: scope.projectLanguageCode,
      api: reportType,
    })
  );
}

const onLoadChapters = (reportType) => {
  dispatch(
    loadChapters({
      ident: scope.projectIdent,
      language: scope.projectLanguageCode,
      assetIdent:  openAsset.ident,
      api: reportType,
    })
  );
}


const onLoadSectionData = (selectedItem, reportType) => {
  dispatch(
    loadSectionData({
      projectIdent: scope.projectIdent,
      assetIdent:  openAsset.ident,
      chapterIdent: selectedItem.chapterIdent,
      sectionIdent: selectedItem.ident,
      language: scope.projectLanguageCode,
      api: reportType,
    })
  );
  dispatch(
    setCurrentSelection({
      chapterIdent: selectedItem.chapterIdent,
      sectionIdent: selectedItem.ident,
      api: reportType,
      selectedItem: selectedItem,
    })
  );
}

const onLoadChapterData = (selectedItem,reportType) => {
  if (!!selectedItem) {
    if (
      selectedItem.type.includes("header-chapter")
    ) {
      dispatch(
        loadChapterData({
          projectIdent: scope.projectIdent,
          assetIdent:  openAsset.ident,
          ident: selectedItem.ident,
          language: scope.projectLanguageCode,
          api: reportType,
        })
      );
    } else if (selectedItem.type.includes("header-section")) {
      dispatch(
        loadSectionData({
          projectIdent: scope.projectIdent,
          assetIdent:  openAsset.ident,
          chapterIdent: selectedItem.chapterIdent,
          sectionIdent: selectedItem.ident,
          language: scope.projectLanguageCode,
          api: reportType,
        })
      );
  }
  dispatch(
    setCurrentSelection({
      chapterIdent: selectedItem.chapterIdent,
      sectionIdent: selectedItem.ident,
      api: reportType,
      selectedItem: selectedItem,
    })
  );
}
}

  const onReportTextSave = (data, reportType) => {
    dispatch(saveReportText({...data,assetIdent:openAsset.ident}, scope.projectIdent, reportType));
  };

  const onAutoSaveReportText = (data,reportType) => {
    dispatch(autoSaveReportText(data, scope.projectIdent, reportType));
  };

  const onProjectInfoSave  = (data,reportType) => {
    dispatch(saveProjectInfo(data, scope.projectIdent, reportType));
  } 
  return {chapterList, 
    currentChapterDatas,
    setCurrentChapterDatas,
    onLoadChapters,
    onLoadChapterData,
    onReportTextSave,
    onAutoSaveReportText,
    onProjectInfoSave,
    onLoadAllChapters,
    onLoadSectionData,
  };
}

export default useChapterHook;
