import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CreateIcon from "@mui/icons-material/Create";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { red, grey } from "@mui/material/colors";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { getIntlTranslation } from "../../../utils/helper";

export function costElementMenu(
  addNodeSibling,
  removeNode,
  handleClickOpenDialog,
  handleOpenDialogBox,
  path,
  
) {
  return (
    <>
      <Tooltip title={getIntlTranslation("_edit")} placement="right">
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClickOpenDialog();
          }}
        >
          <CreateIcon sx={{ color: "#7facd5" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={getIntlTranslation("_Add Sibling")} placement="right">
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addNodeSibling(path);
          }}
        >
          <SubdirectoryArrowRightIcon sx={{ color: grey[700] }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={getIntlTranslation("_delete")} placement="right">
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            removeNode();
          }}
        >
          <DeleteSweepIcon sx={{ color: red[700] }} />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title={t("Single Inflation Rate")} placement="right">
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={()=>{
            handleOpenDialogBox()
          }}
        >
          <TrendingUpIcon />
        </IconButton>
      </Tooltip> */}
    </>
  );
}
