import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush } from "recharts";
import {
  getIntlTranslation,
  getLabel,
  getVariantText,
  covertInCurrencyFormat,
  costStyle,
} from "../../../../../utils/helper";
import {defectTypes} from "../../../../../common/dropdowns"

import { Box, Typography,Divider, Chip } from "@mui/material";

const DefectTypeCharts = (props) => {

  const getAssetList = useMemo(() => {
    let temp = props.data
    .filter(
      (f, i, arr) => arr.findIndex((a) => a.assetIdent === f.assetIdent) === i
    )
    .sort((a, b) => a.assetName.localeCompare(b.assetName))
    .map((m) => {
      return {
        title: m["assetName"],
        value: m["assetName"],
        ident: m["assetIdent"],
      };
    });
    return temp;
  },[props.data])
  const getGroupedCost = (childRows, cost) => {
    let temp = childRows.map((m) => m[cost]);
    const val = temp.reduce((x, y) => {
      return x + y;
    }, 0);
    return val;
  };

  const getGroupedCount = useCallback((childRows, value) => {
    let filterData = childRows.filter((m) => m?.typObj?.value === value);
    const shortTerm = getGroupedCost(filterData, "shortTermCost");
    const mediumTerm = getGroupedCost(filterData, "mediumTermCost");
    const longTerm = getGroupedCost(filterData, "longTermCost");
    const total = shortTerm + mediumTerm + longTerm;
    return {
      count: filterData.length,
      shortTerm: shortTerm,
      mediumTerm: mediumTerm,
      longTerm: longTerm,
      total: total,
    };
  }, []);

  const colors = [
    "#ed4a4a",
    "#454d66",
    "#309975",
    "#dad873",
    "#264e70",
    
  ];
  const getChartData = useMemo(() => {
    let filterData = props.data;
    if (
      !!props.selectedData[props.item.key] &&
      props.selectedData[props.item.key].length > 0
    ) {
      let selected = props.selectedData[props.item.key];
      filterData = props.data.filter((f) =>
        selected.some((t) => t.value === f[props.item.key])
      );
    }

   

    let data = getAssetList.map((x) => {
      let searchArr = filterData.filter((f) => f.assetIdent === x.ident);
      const defectCount = getGroupedCount(searchArr, "Defekt");
      const lifeCycleCount = getGroupedCount(searchArr, "Lebenszyklus");
      const conformityCount = getGroupedCount(searchArr, "Konformität");
      const preventionCount = getGroupedCount(searchArr, "Prävention");
      const total = defectCount.total + lifeCycleCount.total + conformityCount.total + preventionCount.total
      return {
        name: x.title.slice(0, 5),
        value:x.title,
        total: total,
          Defekt: defectCount.total,
          Lebenszyklus:lifeCycleCount.total,
          Konformität:conformityCount.total,
          Prävention: preventionCount.total
      };
    });
    return data;
  }, [getGroupedCount, props.data, props.item.key, props.selectedData]);

 
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const obj = payload[0].payload;
      const keysArr = Object.keys(payload[0].payload).slice(3);
      return (
        <div
          className="custom-tooltip p-2"
          style={{ backgroundColor: "#ffffff" }}
        >
          <Box>
            <Typography
              style={{
                fontWeight: "bold",
                width: 200,
              }}
              variant="subtitle2"
            >
              {obj.value}&nbsp;
            </Typography>
          </Box>
          <Divider/>
          <Box className="desc w-100">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
          {keysArr.map((key,index) => (
        <span style={costStyle}>
        <Typography
          style={{
            width: "50%",
            color: colors[index],
          }}
          variant="caption"
        >
          {key.toUpperCase()}:&nbsp;
        </Typography>
        <Typography
          variant="caption"
          style={{ width: "50%",fontWeight:"bold" }}
        >
          {covertInCurrencyFormat(obj[key])}
        </Typography>
      </span>
      ))}
      <Divider/>
      <span style={costStyle}>
        <Typography
          style={{
            width: "50%",
            color: "#254a9a",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
          variant="caption"
        >
           {getIntlTranslation("_Total")}:&nbsp;
        </Typography>
        <Typography
          variant="caption"
          style={{ width: "50%", fontWeight:"bold" }}
        >
          {covertInCurrencyFormat(obj["total"])}&nbsp;
        </Typography>
      </span>
      </div>
            </Box>
        </div>
      );
    }

    return null;
  };

  const getBar = () => {
    const keysArr = Object.keys(getChartData[0]).slice(3);
    const barArr= [];
    keysArr.forEach((key,index) => {
      barArr.push(<Bar dataKey={key} fill={colors[index]}  barSize={20} />);
  })
return barArr;
}

const getWidth = () => {
  if(getAssetList.length > 1){
    return getAssetList.length * 150
  }else{
    return 300;
  }
}

  return (
    <>
      {props.data.length > 0 && (
        <div
         style={{width:"max-content"}}
          height="100%"
          className="d-flex flex-column align-items-center p-1 m-1 "
        >
          <BarChart
             width={props.layout === "vertical" ? 600 :getWidth()}
             height={props.layout === "vertical" ? 200 :400}
            barSize={30}
            data={getChartData}
            layout={props.layout || "horizontal"}
            margin={{
              top: 20, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3"  horizontal={false} vertical={false}/>
            {props.layout === "vertical" ? (
              <>
                <XAxis type="number"/>
                <YAxis
                  dataKey="name"
                  reversed
                  type="category"
                  tick={{ fontSize: 12, fontWeight: "bold" }}
                  tickLine={false}
                  tickMargin={20}
                  width={100}
                />
              </>
            ) : (
              <>
                <XAxis dataKey="name" 
                tick={{ fontSize: 12, fontWeight: "bold" }}
                  tickLine={false}
                  tickMargin={10}
                  width={50}/>
                <YAxis />
              </>
            )}
             <Tooltip content={<CustomTooltip />} />
             <Legend/>
            {getBar()}
            <Brush dataKey={"name"}>
          <BarChart>
            {getBar()}
          </BarChart>
        </Brush>
          </BarChart>
          <Divider>
              <Chip
                label={getVariantText("Risk Type Distribution", "caption", {
                  fontWeight: "bold",
                  textTransform: "uppercase",
                })}
              />
            </Divider>
           
        </div>
      )}
    </>
  );
};

DefectTypeCharts.propTypes = {
  data: PropTypes.array,
  item: PropTypes.object,
  selectedData: PropTypes.object,
  layout: PropTypes.string,
};

export default DefectTypeCharts;
