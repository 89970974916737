import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import {renewAccessTokenIfGoingExpire} from '../../../api/authApi'
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import { getFailure, getSuccess} from "../common/report-text-history/historyReportTextSlice";


export const getData = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `${request.api}/assets/fetch-rt-history/${request.projectIdent}/${request.reportTextIdent}/${request.assetIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};