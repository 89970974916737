// @ts-nocheck

import { createContext, useContext, useState } from "react";
const ImageContext = createContext();

export const useImageContext = () => {
  return useContext(ImageContext);
};

export const ImageProvider = ({
  children,
  onRemove,
  onImageSave,
  onCaptionChange,
  isDragable,
  onImageEdit,
  isQuelleNeeded = false,
  onQuelleChange,
  onDragEnd,
}) => {
  return (
    <ImageContext.Provider
      value={{
        onRemove,
        onImageSave,
        onCaptionChange,
        isDragable,
        onImageEdit,
        isQuelleNeeded,
        onQuelleChange,
        onDragEnd,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};
