import {
  AppBar,
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
  normalEditorConfig,
} from "../../../utils/helper";
import CustomButton from "../../../components/buttons/CustomButton";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import { useDocumentContextHook } from "../structure/DocumentBuilderContext";
import GuruEditor  from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const settingOptions = [
  // {
  //   label: "_Name",
  //   type: "string",
  //   onChangeField: "title",
  // },
  // {
  //   label: "_translation",
  //   type: "header",
  // },
  // {
  //   label: "_German-Translation",
  //   type: "string",
  //   onChangeField: "deTranslation",
  // },
  // {
  //   label: "_English-Translation",
  //   type: "string",
  //   onChangeField: "enTranslation",
  // },
  {
    label: "User Editable",
    type: "checkbox",
    onChangeField: "userEditable",
  },
];

export const TypeParagraphTreeRender = ({ item }) => {
  const { selectedItem } = useDocumentContextHook();
  return (
    <Box
      id={`box_${item.id}`}
      className="w-100 h-100 d-flex justify-content-start align-items-center"
    >
      <Typography
        variant="subtitle2"
        style={{
          color: selectedItem?.id === item.id ? "#ffffff" : "#254a9a",
          fontWeight: selectedItem?.id === item.id ? "bold" : "normal",
        }}
        className="p-1"
      >
        {item?.settings?.title}
      </Typography>
    </Box>
  );
};

export const TypeParagraphDropRender = ({ item }) => {
  const { saveContent } = useDocumentContextHook();
  const editorRef = useRef();
  const [open, setOpen] = useState(false);
  const itemRef = useRef();

  useEffect(() => {
    if (!!item) {
      itemRef.current = item;
    }
  }, [item]);

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      const handleMouseOut = (event) => {
        if (!editor.editing.view.dom.contains(event.relatedTarget)) {
          handleSave(editor);
          console.log("Autosave on Mouse Out:", editor.getData());
        }
      };

      const handleFocusOut = () => {
        const newData = editor.getData();
        handleSave(editor);
        console.log("Autosave on Focus Out:", newData);
      };
      editor.editing.view.document.on("mouseout", handleMouseOut);
      editor.editing.view.document.on("focusout", handleFocusOut);
      return () => {
        editor.editing.view.document.off("mouseout", handleMouseOut);
        editor.editing.view.document.off("focusout", handleFocusOut);
      };
    }
  }, []);

  const handleSave = (editor) => {
    saveContent(item, {
      ...item.content,
      defaultText: editor.getData(),
    });
  };

  const handleCollapseIn = () => {
    if (itemRef.current?.id === item.id) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Box
      id={`box_${item.id}`}
      className="w-100 h-100 d-flex flex-column justify-content-start align-items-start"
    >
      <Box className="d-flex align-items-center w-100">
      <Typography
        variant="subtitle2"
        style={{
          color: "#254a9a",
          fontWeight: "bold",
          width: "75%",
        }}
        className="p-1"
      >
        {item?.settings?.title}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{
          color: "#254a9a",
          fontWeight: "bold",
        }}
        className="p-1"
      >
      {getLabel("User Editable")}
        </Typography>
        <Checkbox
            key={`name_${item.id}`}
            checked={item.settings.userEditable}
            disabled
          />
          <IconButton
          style={{
            marginLeft: "auto",
            transform: open ? "rotate(0deg)" : "rotate(180deg)",
          }}
          className="p-1"
          onClick={(e) => {
            e.stopPropagation();
            handleCollapseIn()}
          }
        >
          <FontAwesomeIcon
            icon="fa-solid  fa-square-caret-down"
            style={{
              width: 22,
              height: 22,
              color:  open ? "#254a9a" : "",
            }}
          />
        </IconButton>
        </Box>
        <Collapse
        className="w-100 m-1 p-1"
        in={open}
        timeout="auto"
        unmountOnExit
      >
      <Box className="w-100 p-1 d-flex align-items-center">
        <Box
          id={`box_${item.id}`}
          className="g-text-editor custom-scrollBar p-1"
          style={{
            minHeight: "200px",
            padding: 5,
            width: "99%",
            height: "98%",
          }}
          tabIndex={-1}
        >
          <CKEditor
            id={`editor_${item.id}`}
            editor={GuruEditor.ClassicEditor}
            config={normalEditorConfig}
            onReady={(editor) => {
              editorRef.current = editor;
            }}
            disabled={false}
            data={item.content.defaultText}
            onBlur={(event, editor) => handleSave(editor)}
          />
        </Box>
      </Box>
      </Collapse>
    </Box>
  );
};

export const TypeParagraphSettings = ({ item }) => {
  const intl = useIntl();
  const { editItem, setEditItem, saveSettings } = useDocumentContextHook();
  const [settings, setSettings] = useState({
    title: "",
    enTranslation: "",
    deTranslation: "",
    userEditable: true,
  });

  useEffect(() => {
    if (!!editItem && !!editItem.id) {
      setSettings({
        title: editItem.settings.title,
        enTranslation: editItem.settings.translation?.en,
        deTranslation: editItem.settings.translation?.de,
        userEditable: editItem.settings.userEditable,
      });
    }
  }, [editItem?.id]);

  const handleSave = () => {
    saveSettings(editItem, {
      ...editItem.settings,
      title: settings.title,
      translation: {
        en: settings.enTranslation,
        de: settings.deTranslation,
      },
      userEditable: settings.userEditable,
    });
    setEditItem(null);
  };

  const renderInputField = (input) => {
    if (input.type === "number") {
      return (
        <TextField
          key={`name_${item.id}-${input.label}`}
          className="w-100 p-1"
          type={input.type}
          defaultValue={
            !!editItem
              ? settings[input.onChangeField]
              : item.settings.defaultTitle
          }
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    } else if (input.type === "checkbox") {
      return (
        <Typography variant="body1">
          <Checkbox
            key={`name_${item.id}-${input.label}`}
            checked={settings[input.onChangeField]}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                [input.onChangeField]: e.target.checked,
              }))
            }
          />
        </Typography>
      );
    } else {
      return (
        <TextField
          key={`name_${editItem?.id}-${input.label}`}
          className="w-100 p-1"
          multiline={true}
          maxRows={5}
          type={input.type || "text"}
          defaultValue={settings[input.onChangeField]}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    }
  };

  return (
    <>
      <Collapse
        className="w-100 m-1 p-1"
        in={editItem?.id === item.id}
        timeout="auto"
        unmountOnExit
      >
        <Card className="w-100 p-1" style={{ backgroundColor: "#e6e8ed" }}>
          <AppBar
            style={{
              backgroundColor: "#d08f8e",
              position: "sticky",
              width: "98%",
              borderRadius: 4,
              marginLeft: 2,
              height: 40,
            }}
          >
            <Toolbar className={"dialog-toolbar"}>
              <Typography variant="body1">{editItem?.label}</Typography>
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={() => setEditItem(null)}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <CardContent
            className="custom-scrollBar m-1"
            style={{
              width: "98%",
              overflowY: "auto",
              overflowX: "hidden",
              padding: 2,
              height: "100%",
            }}
          >
            <List className="w-100 p-0">
              {settingOptions.map((o, index) => {
                if (o.type === "header") {
                  return (
                    <ListItem
                      className="w-100 p-0"
                      key={`option-${o.label}-${index}}`}
                    >
                      {getTranslationVariantText(o.label, "caption", {
                        fontWeight: "bold",
                        color: "#244a9a",
                        textAlign: "center",
                      })}
                    </ListItem>
                  );
                } else {
                  return (
                    <>
                      <ListItem
                        className="w-100 p-0 d-flex flex-row align-items-baseline m-1 flex-wrap"
                        key={`option-${o.label}-${index}}`}
                      >
                        <Box
                          style={{ textTransform: "capitalize", width: "80%" }}
                        >
                          {getLabel(o.label)} {o.optional && `(Optional)`}
                        </Box>
                        {renderInputField(o)}
                      </ListItem>
                    </>
                  );
                }
              })}
            </List>
          </CardContent>
          <CardActions style={{ marginTop: "auto" }}>
            <CustomButton
              id={`save_${item.id}`}
              buttonText={"_save"}
              variant="button"
              textVariant="body1"
              buttonColor={"inherit"}
              style={{
                margin: 5,
                height: 40,
                marginLeft: "auto",
                width: "100%",
                padding: 5,
              }}
              className="g-btn-primary"
              tooltip={{
                title: getIntlTranslation("tooltip.Save"),
              }}
              size="small"
              handleClick={(e) => handleSave()}
            />
          </CardActions>
        </Card>
      </Collapse>
    </>
  );
};

export const TypeParagraphPreviewRender = ({item}) => {
  return (
  <Box className="d-flex align-items-center flex-wrap m-1">
    <Typography dangerouslySetInnerHTML={{ __html: item.content.defaultText }} className="label-text"/>
  </Box>
  )
};
