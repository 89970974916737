import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  data:{},
  error: {},
  userSelection:{},
};
// A slice for auth with our success or failure reducers
const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    resetState: (state) => ({
      ...state,
      hasErrors: false,
      type: "",
      messages: "",
      error: {},
      data:{}
    }),
     getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
       getReportSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: "REPORT_SUCCESS",
      messages: payload.messages,
      data: payload.guruDto,
    }),
    setUserSelection:(state, { payload }) => ({
      ...state,
      hasErrors: false,
      userSelection: payload,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetState,
  getSuccess,
  getReportSuccess,
  setUserSelection,
} = reportSlice.actions;

// A selector
export const reportSelector = (state) => state.reportSlice;

// The reducer
export default reportSlice.reducer;
