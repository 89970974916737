import React from "react";
import {useSelector} from "react-redux";
import { Outlet } from "react-router-dom";
import {
  Alert,
} from "@mui/material";
import{getTranslationVariantText} from "../../utils/helper"
const TddModuleHome = () => {
  const state = useSelector((state) => state.readOnly);
  return (
    <>
    {state.isReadOnly &&
      <Alert variant="filled" severity="error" className="w-100 p-1">
        {getTranslationVariantText("_readOnly_mode","caption")}
    </Alert>
    }
      <Outlet />
      </>
  );
};
export default TddModuleHome;
