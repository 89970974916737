import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";
export const initialState = {
  hasErrors: false,
 reportSuccess:{...response},
error: {...response},
};
// A slice for auth with our success or failure reducers
const monitoringReportSlice = createSlice({
  name: "monitoringReportSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
        type: payload.type,
        messages: payload.messages,
        error: payload,
      }
      
    }),
    resetState: (state) => ({
      ...initialState
    }),
     getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
       getReportSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      reportSuccess:{
        type: "REPORT_SUCCESS",
        messages: payload.messages,
        data: payload.guruDto,
      }
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetState,
  getSuccess,
  getReportSuccess
} = monitoringReportSlice.actions;

// A selector
export const monitoringReportSelector = (state) => state.monitoringReportSlice;

// The reducer
export default monitoringReportSlice.reducer;
