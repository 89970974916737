import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AppBar, Box, TextField, Toolbar } from "@mui/material";
import { getLabel, getIntlTranslation } from "../../../../utils/helper";
import DeleteIconButton from "../../../../components/buttons/icons-buttons/DeleteIconButton";
import debounce from "lodash.debounce";
import CustomButton from "../../../../components/buttons/CustomButton";
import SaveButton from "../../../../components/buttons/SaveButton";

const EditCustomPropertyLabel = (props) => {
  const [intialFormData, setIntialFormData] = useState({});
  useEffect(() => {
    if (!!props.property) {
      setIntialFormData({
        name: props.property.nodeName,
        type: props.property.nodeType,
      });
    }
  }, [props.property]);

  const save = () => {
    let temp = {
      ...props.property,
      nodeName: intialFormData.name,
      updated: true,
      isTemporary: true,
      deleted: false,
      labelTranslation: props.property.labelTranslation.map((t) => ({
        ...t,
        title: intialFormData.name,
        value: intialFormData.name,
      })),
    };
    props.handleSave(temp);
  };
  const onDelete = () => {
    let temp = {
      ...props.property,
      nodeName: intialFormData.name,
      deleted: true,
    };
    props.onDelete(temp);
  };

  const handleTextChange = useCallback((val) => {
    setIntialFormData((prev) => ({ ...prev, name: val }));
  }, []);

  const debouncedTextChangeHandler = useMemo(
    (val) => debounce(handleTextChange, 200),
    [handleTextChange]
  );

  return (
    <AppBar
      className="w-100 rounded-3"
      style={{ position: "relative", background: "#ffffff" }}
    >
      <Toolbar
        style={{ width: 400 }}
        className="d-flex justify-content-start flex-column p-1 align-items-center"
      >
        <Box className="w-100 d-flex justify-content-start p-1 align-items-center">
          <TextField
            style={{ width: "95%", margin: 5 }}
            id={`{property}`}
            label={getLabel("Property Name")}
            defaultValue={intialFormData.name}
            key={`{property}`}
            onChange={(e) => debouncedTextChangeHandler(e.target.value)}
            InputProps={{
              "aria-label": `Property Name`,
            }}
            multiline
            maxRows={4}
            variant="outlined"
            size="small"
            dense="true"
          />
          <DeleteIconButton
            id={`delete_button_${props.property.nodeName}`}
            permission={null}
            handleClick={(e) => onDelete()}
            tooltip={{
              title: getIntlTranslation("tooltip.delete"),
            }}
            style={{
              borderRadius: 0,
              margin: 5,
              marginLeft: "auto",
            }}
          />
        </Box>
        <Box className="w-100 d-flex align-items-center justify-content-between">
          <CustomButton
            id="cancle_edit"
            buttonText={"_cancel"}
            style={{
              margin: 5,
              color: "#254a9a",
              height: 40,
            }}
            className="btn"
            tooltip={{
              title: getIntlTranslation("tooltip.cancel"),
            }}
            handleClick={(e) => props.onClose()}
          />
          <SaveButton
            id={`save_button_${props.property.nodeName}`}
            buttonText="_save"
            variant="button"
            style={{ marginLeft: "auto" }}
            tooltip={{
              title: getIntlTranslation("tooltip.save"),
            }}
            handleClick={(e) => save()}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

EditCustomPropertyLabel.propTypes = {
  property: PropTypes.object,
  handleSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default EditCustomPropertyLabel;
