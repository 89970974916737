import { Box, TextField } from '@mui/material'
import React, { useState } from 'react'
import { getLabel } from '../../../../utils/helper';

const QuelleInput = ({id,key, node, disabled, handleQuelleChange}) => {
  const [value, setValue] = useState(node.quelle);
  return (
    <Box className="d-flex">
    <TextField
     fullWidth
      id={id}
      label={getLabel("_quelle")}
      disabled={disabled}
      value={value}
      key={id}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        if(e.target.value !== node.quelle){
          handleQuelleChange(e.target.value, node, true);
        }
      }
    }
      multiline
      minRows={2}
      maxRows={5}
      variant="outlined"
      size="small"
      dense="true"
      InputLabelProps={{
        shrink: true,
    }}
    />
  </Box>
  )
}

export default QuelleInput;
