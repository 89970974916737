import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import TackManagementContainer from "./structure/TackManagementContainer";

const  UserManagementHome = () => {
  return (
    <div> <TackManagementContainer/></div>
  )
}

UserManagementHome.propTypes = {}

export default UserManagementHome
