import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import {
  covertInCurrencyFormat,
  getIntlTranslation,
  getLabel,
  getVariantText,
  getTranslationVariantText,
} from "../../../../../utils/helper";

import { Box, Chip, Divider, Typography } from "@mui/material";

const CostCharts = (props) => {
  const getGroupedCost = (childRows, cost) => {
    let temp = childRows.map((m) => m[cost]);
    const val = temp.reduce((x, y) => {
      return x + y;
    }, 0);
    return val;
  };

  const getChartData = useMemo(() => {
    let filterData = props.data;

    if (
      !!props.selectedData[props.item.key] &&
      props.selectedData[props.item.key].length > 0
    ) {
      let selected = props.selectedData[props.item.key];
      filterData = props.data.filter((f) =>
        selected.some((t) => t.value === f[props.item.key])
      );
    }
    const shortTerm = getGroupedCost(filterData, "shortTermCost");
    const mediumTerm = getGroupedCost(filterData, "mediumTermCost");
    const longTerm = getGroupedCost(filterData, "longTermCost");
    const data = [
      { name: "Short Term (Year 1)", value: shortTerm, color: "#e85656" },
      {
        name: "Medium Term (Years 2 - 5)",
        value: mediumTerm,
        color: "#dfb81c",
      },
      { name: "Long Term (Years 6 - 10)", value: longTerm, color: "#209e91" },
    ];

    return data;
  }, [props.data, props.item.key, props.selectedData]);

  const getTotalCost = (data) => {
    const val = data
      .map((s) => s.value)
      .reduce((x, y) => {
        return x + y;
      }, 0);

    return covertInCurrencyFormat(val);
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, fill, payload, textAnchor } = props;
    let cost = covertInCurrencyFormat(payload.value);
    return (
      <text x={x - 3} y={y - 3} fill={fill} textAnchor={textAnchor}>
        {cost}
      </text>
    );
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <>
        <Box className="w-100 d-flex align-items-center justify-content-center">
          {getTranslationVariantText("_Total", "subtitle2", {
            fontWeight: "Bold",
            textTransform: "uppercase",
            color:"#254a9a",
            margin: 5,
          })}

          {getVariantText(getTotalCost(getChartData), "subtitle2", {
            fontWeight: "Bold",
            textTransform: "uppercase",
            color:"#254a9a",
            margin: 5,
          })}
        </Box>
        <ul
          className={
            "d-flex p-1 w-100 align-items-center justify-content-around"
          }
        >
          {payload.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{ color: entry.color,  padding: 2, listStyle:"square" }}
            >
              {entry.value}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <>
      {props.data.length > 0 && (
        <div
          width="100%"
          height="100%"
          className="d-flex flex-column align-items-center"
        >
          <PieChart width={600} height={250}>
            <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={getChartData}
              cx="50%"
              cy="80%"
              labelLine={true}
              label={renderCustomizedLabel}
              paddingAngle={1}
              outerRadius={80}
              fill="#8884d8"
            >
              {getChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend content={renderLegend} />
          </PieChart>
          <Divider>
              <Chip
                label={getVariantText("Cost Distribution", "caption", {
                  fontWeight: "bold",
                  textTransform: "uppercase",
                })}
              />
            </Divider>
        </div>
      )}
    </>
  );
};

CostCharts.propTypes = {
  data: PropTypes.array,
  item: PropTypes.object,
  selectedData: PropTypes.object,
};

export default CostCharts;
