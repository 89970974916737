import { Box, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";

export const TextInput = ({ id, disabled = false, node, handleChange }) => {
  const [value, setValue] = useState(node.value);
  const [firstChangeTriggered, setFirstChangeTriggered] = useState(false);

  useEffect(() => {
    if (node.value !== null) {
      setValue(node.value);
    } else {
      setValue(null);
    }
  }, [node.value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (!firstChangeTriggered) {
      setFirstChangeTriggered(true);
      handleChange(newValue, node, true);
    }
  };

  const handleInputBlur = (e) => {
    if (firstChangeTriggered && e.target.value !== node.value) {
      handleChange(e.target.value, node, true);
    }
  };

  return (
    <Box className="d-flex w-100">
      <TextField
        fullWidth
        id={`text-${id}`}
        disabled={disabled}
        key={`text-${id}`}
        value={value}
        type={"text"}
        multiline
        minRows={2}
        maxRows={5}
        onChange={(e) => handleInputChange(e)}
        onBlur={(e) => handleInputBlur(e)}
        variant="outlined"
        size="small"
        dense="true"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default TextInput;
