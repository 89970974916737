import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  error:{
    type: "",
   messages: [],
   data: {},
 },
 success:{
  type:"",
  messages:[],
  data:{},
  list:[],
 }
};

const costSuggestionSlice = createSlice({
  name: "costSuggestionSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }
    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      success:{
      type: payload.type,
      messages: payload.messages,
      data: payload.data,
      list:payload.list,
     }
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

export const { resetState,getFailure,getSuccess } = costSuggestionSlice.actions;

// A selector
export const costSuggestionSliceSelector = (state) => state.costSuggestionSlice;

// The reducer
export default costSuggestionSlice.reducer;