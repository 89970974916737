import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import DataAnalysisContainer from "./DataAnalysisContainer"

const DataAnalysisDataWrapper = props => {
  return (
    <div><DataAnalysisContainer/></div>
  )
}

DataAnalysisDataWrapper.propTypes = {}

export default DataAnalysisDataWrapper;