import React  from 'react'
import PropTypes from 'prop-types'
import Box from "@mui/material/Box";
import DisplayPriorityRangeSlider from "../../common/range-slider/DisplayPriorityRangeSlider"
import { getLabel, getIntlTranslation } from "../../../../utils/helper";

function MultiplePriority(props) {
    
  return (
    <>
    <Box>
    <div className="grid-container" style={{ width:"100%"}}>
                    <span className="grid-item shortTerm">
                      {getIntlTranslation("_Short-Term")} (%)
                    </span>
                    <span className="grid-item mediumTerm">
                      {getIntlTranslation("_Medium-Term")} (%)
                    </span>
                    <span className="grid-item longTerm">
                      {" "}
                      {getIntlTranslation("_Long-Term")} (%)
                    </span>
                    </div>
    <DisplayPriorityRangeSlider data={props.data} onChange={props.onChange}/>
    </Box>
    </>
  );
}

MultiplePriority.propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func,
}

export default MultiplePriority
