import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssets } from "../../../../api/assetSwitcherApi";
import SectionMap from "./components/SectionMap";
import { NoData, Box } from "../../../../common/commonImport";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const CustomReportHome = () => {
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  useEffect(() => {
    if (scope.projectIdent !== null && scope.projectIdent !== "") {
      const request = {
        api: scope.api,
        projectIdent:scope.projectIdent 
      }
      dispatch(getAssets(request));
    }
  }, [scope]);

  return (
    <DndProvider backend={HTML5Backend}>
          <SectionMap/>
    </DndProvider>
  );
};

export default CustomReportHome
