import { useEffect} from "react";
import { useDispatch } from "react-redux";
import {  setCompleteReset } from "./keyFindingsSlice";
import {Box} from "@mui/material";
import { resetPromptState } from "../../projectmonitoringModuleSlice";
import KeyFindingsWrapper from "./structure/KeyFindingsWrapper";
import React from "react";

const KeyFindingsHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCompleteReset());
    dispatch(resetPromptState());
  }, [dispatch]);

  return (
    <Box
      style={{ height: "100%", overflow: "hidden", backgroundColor: "#ebedef" }}
    >
     <KeyFindingsWrapper moduleApi={"key-findings"}/>
    </Box>
  );
};
export default KeyFindingsHome;
