import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CustomAutoComplete from "./CustomAutoComplete";
import { getIntlTranslation } from "../../../utils/helper";

export function AddNodeDialogBox(
  onClose,
  onSave,
  openDialog,
  node,
  setNode,
  units,
  currentParentNode
) {
  const form = () => {
    return (
      <Grid>
        {!!node && !!node.nodeType && (
          <>
            <Grid>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  disabled
                  autoFocus
                  margin="dense"
                  id="name"
                  label={`element code`}
                  fullWidth
                  variant="standard"
                  value={currentParentNode.costCode}
                  sx={{width: '150px' }}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={`kosten element code`}
                  fullWidth
                  variant="standard"
                  value={node.code}
                  onChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const code = event.target.value;
                    let updateNode = {
                      ...node,
                      code: code,
                    };
                    setNode(updateNode);
                  }}
                />
              </Box>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={`kostene element beschreibung`}
                fullWidth
                variant="standard"
                value={node.name}
                onChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const name = event.target.value;
                  let updateNode = {
                    ...node,
                    name,
                  };
                  setNode(updateNode);
                }}
              />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomAutoComplete
                  id="unit-suggestion"
                  options={units}
                  value={node.unit}
                  onChange={(event) => {
                    const unit = event.value;
                    let updateNode = {
                      ...node,
                      unit,
                    };
                    setNode(updateNode);
                  }}
                  style={{ width: "100%", margin: 5, padding: 4 }}
                  variant="outlined"
                  label={getIntlTranslation("_Unit")}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={`Von`}
                  fullWidth
                  type="number"
                  variant="standard"
                  value={node.von}
                  style={{ padding: 4 }}
                  onChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const res = event.target.value;
                    const von = parseFloat(res, 10);
                    let updateNode = {
                      ...node,
                      von,
                    };
                    setNode(updateNode);
                  }}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={`Mittel`}
                  fullWidth
                  type="number"
                  variant="standard"
                  value={node.mittel}
                  style={{ padding: 4 }}
                  onChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const res = event.target.value;
                    const mittel = parseFloat(res, 10);
                    let updateNode = {
                      ...node,
                      mittel,
                    };
                    setNode(updateNode);
                  }}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={`Bis`}
                  fullWidth
                  type="number"
                  variant="standard"
                  value={node.bis}
                  style={{ padding: 4 }}
                  onChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const res = event.target.value;
                    const bis = parseFloat(res, 10);
                    let updateNode = {
                      ...node,
                      bis,
                    };
                    setNode(updateNode);
                  }}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  };
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      maxWidth="sm"
    >
      <DialogContent
        dividers
        style={{ maxWidth: "700px", minWidth: "500px", minHeight: "200px" }}
      >
        {!!node && !!node.nodeType && form()}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {getIntlTranslation("_cancel")}
        </Button>
        <Button autoFocus onClick={onSave}>
          {getIntlTranslation("_save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
