import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  isDeleted:false,
  isUpdated:false,
  type: "",
  messages: "",
  data:{},
  error: {},
};
// A slice for auth with our success or failure reducers
const subAssetSlice = createSlice({
  name: "SubAsset",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      isDeleted:false,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    getCreateSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      isDeleted:false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
    getUpdatedSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      isUpdated:true,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
    resetSubAssetState: (state) => ({
      ...state,
      hasErrors: false,
      type: "",
      messages: "",
      asset: {},
      error: {},
    }),
    getDeleteFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      isDeleted:false,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    getDeleteSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      isDeleted:true,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
    getNavLinks:(state, { payload }) => ({
      ...state,
      hasErrors: false,
      isDeleted:true,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  getCreateSuccess,
  resetSubAssetState,
  getUpdatedSuccess,
  getFetchSuccess,
  getDeleteSuccess,
  getDeleteFailure,
  getNavLinks
} = subAssetSlice.actions;

// A selector
export const subAssetSelector = (state) => state.subAsset;

// The reducer
export default subAssetSlice.reducer;
