import { createSlice } from "@reduxjs/toolkit";
import { encrypt} from "../../../utils/helper";



export const initialState = {
  hasErrors: false,
  assetName: null,
  subAssetName: null,
  subAssetList:[],
  ident:null,
  subAssetIdent:null,
  users:null,
  projectIdent:null,
};
// A slice for auth with our success or failure reducers
const openAssetSlice = createSlice({
  name: "OpenAsset",
  initialState,
  reducers: {
    setOpenAsset: (state, { payload }) => {
      return {
          ...state,
          hasErrors: false,
          assetName: payload.assetName,
          subAssetName: payload.subAssetName,
          ident:payload.ident,
          subAssetList:payload.subAssetList,
          users:encrypt(payload.userDtos),
          subAssetIdent:payload.subAssetIdent,
          projectIdent:payload.projectIdent,
        }
      
    },
    resetOpenAssetState: (state) => ({...initialState}),
  },
});

// eslint-disable-next-line max-len
export const { setOpenAsset, resetOpenAssetState } = openAssetSlice.actions;

// A selector
export const assetSelector = (state) => state.openAsset;

// The reducer
export default openAssetSlice.reducer;
