import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { fetchSubCategoryElementList } from '../../../api/elementSectionApi';
import { getIntlTranslation } from '../../../../../utils/helper';
import match from 'autosuggest-highlight/match';
import { others } from '../../../../../common/dropdowns';
import parse from 'autosuggest-highlight/parse';
import { resetSliceState as resetCostSlice } from '../costElementSlice';
import { resetSliceState } from './elementSectionSlice';

const customfilter = createFilterOptions({
  matchFrom: 'contain',
  stringify: (option) => option.title,
});

const elementsSuggestionList = (arr, obj, parent) => {
  let temp = arr.reduce((list, curr) => {
    if (
      !list.find((f) => f.language === curr.language && f.value === curr.value)
    ) {
      list.push({
        ...curr,
        parentIdent: obj.subCategorySecondaryIdent,
        parentName: parent.title,
      });
    }
    return list;
  }, []);
  return temp;
};

function ElementSection(props) {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.tddElementSection);
  const [subSecondaryCategoryList, setSubSecondaryCategoryList] = useState([]);
  const [elementList, setElementList] = useState([]);
  const dispatch = useDispatch();

  const processElementsSuggestions = useCallback((obj, index) => {
    const parentName = obj.subCategorySecondaryName.find(
      (f) => f.language === scope.projectLanguageCode
    );
    let checked = false;
    let disabled = true;
    let temp = [];
    obj.elementDtoList?.forEach((item) => {
      if (item.elementName.length) {
        temp = [...temp, ...item.elementName];
      }
    });
    let defaultElementList = [...temp, ...obj.elementsSuggestionList];
    if (defaultElementList.length === 0 && !props.isEdit) {
      props.setFieldValue('isElementOther', true);
      props.setFieldValue('customElement', {
        value: parentName.title,
        title: parentName.title,
        parentIdent: obj.subCategorySecondaryIdent,
      });
      props.setFieldValue('elementName', {
        ...others.find((f) => f.language === scope.projectLanguageCode),
        parentIdent: obj.subCategorySecondaryIdent,
      });
    }
    temp = [...defaultElementList, ...others];

    if (
      !!props.isEdit &&
      props.isEdit.isEdit &&
      obj.subCategorySecondaryIdent === props.isEdit.parentIdent
    ) {
      checked = true;
      disabled = false;
    } else if (!props.isEdit && index === 0) {
      checked = true;
      disabled = false;
    }
    return {
      name: parentName.title,
      ident: obj.subCategorySecondaryIdent,
      parent: props.subCategory,
      elementsSuggestionList: elementsSuggestionList(temp, obj, parentName),
      checked: checked,
      disabled: disabled,
    };
  }, []);

  useEffect(() => {
    if (!!props.subCategory) {
      dispatch(
        fetchSubCategoryElementList({
          projectIdent: scope.projectIdent,
          ident: props.subCategory.ident,
        })
      );
    }
  }, [dispatch, scope, props.subCategory]);

  useEffect(() => {
    if (
      state.suggestionElements.type === 'FETCHED' &&
      !!state.suggestionElements.data
    ) {
      let secondary = [];
      state.suggestionElements.data.subCategorySecondaryDtoList
        ?.filter(
          (f) =>
            !f.subCategorySecondaryName.find((t) => t.value === 'Sonstiges')
        )
        .forEach((m, index) => {
          let tempObj = processElementsSuggestions(m, index);
          secondary.push(tempObj);
        });

      if (secondary.length === 0) {
        let found =
          state.suggestionElements.data.subCategorySecondaryDtoList?.find((f) =>
            f.subCategorySecondaryName.find((t) => t.value === 'Sonstiges')
          );
        if (!!found) {
          let tempObj = processElementsSuggestions(found, 0);
          secondary.push(tempObj);
        }
      }
      setSubSecondaryCategoryList(secondary);

      if (!!props.isEdit && props.isEdit.isEdit) {
        setElementList(
          secondary.find((f) => f.ident === props.isEdit.parentIdent)
            ?.elementsSuggestionList
        );
      } else {
        setElementList(secondary[0]?.elementsSuggestionList);
      }
      dispatch(resetSliceState());
    }
  }, [scope.projectLanguageCode, state]);

  const handleTextChange = useCallback((name, val) => {
    props.setFieldValue(name, val);
  }, []);

  const debouncedTextChangeHandler = useMemo(
    (name, val) => debounce(handleTextChange, 200),
    [handleTextChange]
  );

  const handleToggle = (value, checked) => {
    setSubSecondaryCategoryList((prev) => {
      return prev.map((item) => {
        if (item.ident === value.ident) {
          if (checked) {
            item.checked = true;
            item.disabled = false;
            props.resetForm();
            dispatch(resetCostSlice());
            if (
              item.elementsSuggestionList.length === 2 &&
              item.elementsSuggestionList[0].value === 'Sonstiges'
            ) {
              props.setFieldValue('isElementOther', true);
              props.setFieldValue(
                'elementName',
                others.find((f) => f.language === scope.projectLanguageCode)
              );
              props.setFieldValue('customElement', {
                value: item.elementsSuggestionList[0].parentName,
                title: item.elementsSuggestionList[0].parentName,
                parentIdent: item.elementsSuggestionList[0].parentIdent,
              });
            } else {
              props.setFieldValue('elementName', {
                title: '',
                language: scope.projectLanguageCode,
                value: '',
                parentIdent: item.ident,
              });
              props.setFieldValue('isElementOther', false);
            }
            setElementList(item.elementsSuggestionList);
          } else {
            // do nothing, do not allow user to unselect the current item
          }
        } else {
          item.checked = false;
          item.disabled = false;
        }
        return item;
      });
    });
  };

  return (
    <>
      {subSecondaryCategoryList.length > 0 && (
        <>
          <List
            dense={'true'}
            style={{
              width: '100%',
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'start',
            }}
          >
            {subSecondaryCategoryList.map((value, index) => {
              const labelId = `checkbox-list-secondary-label-${index}`;
              return (
                <ListItem
                  key={`${value.name}_${index}`}
                  disablePadding
                  style={{ width: '33%', padding: 0 }}
                >
                  <ListItemButton
                    onClick={(e) => handleToggle(value, !value.checked)}
                  >
                    <Box style={{ marginRight: 10 }}>
                      <Checkbox
                        edge="end"
                        checked={value.checked}
                        disabled={value.disabled}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </Box>
                    <ListItemText
                      id={labelId}
                      primary={
                        <Typography
                          variant="body2"
                          style={{ width: '90%', whiteSpace: 'pre-wrap' }}
                        >
                          {value.name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Divider
            style={{ margin: '5px 2px', width: '100%' }}
            className="g-divider-vertical"
          />
          {!!elementList && elementList.length > 0 && (
            <Box style={{ width: '40%', padding: 8 }}>
              <Box
                style={{ width: '100%', padding: 8 }}
                className={'d-flex align-center flex-column'}
              >
                <Autocomplete
                  size="small"
                  id={`type-element-1`}
                  style={{ margin: '5px 0px' }}
                  value={
                    !props.values.elementName ? '' : props.values.elementName
                  }
                  filterOptions={(options, params) => {
                    const filtered = customfilter(options, params);
                    return filtered;
                  }}
                  onChange={(event, newValue) => {
                    if (!!newValue) {
                      // props.resetForm();
                      props.setFieldValue('elementName', newValue);
                      if (newValue.value === 'Sonstiges') {
                        props.setFieldValue('isElementOther', true);
                        props.setFieldValue('customElement', {
                          value: newValue.parentName,
                          title: newValue.parentName,
                          parentIdent: newValue?.parentIdent,
                        });
                      } else {
                        props.setFieldValue('isElementOther', false);
                        props.setFieldValue('customElement', {
                          value: '',
                          title: '',
                          parentIdent: newValue?.parentIdent,
                        });
                      }
                    }
                  }}
                  options={elementList.filter(
                    (f) => scope.projectLanguageCode === f.language
                  )}
                  groupBy={(option) => option?.parentName}
                  getOptionLabel={(option) => option.title}
                  isOptionEqualToValue={(option, value) =>
                    value.value === option.value
                  }
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.title, inputValue);
                    const parts = parse(option.title, matches);

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                color: part.highlight ? 'red' : 'black',
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getIntlTranslation('_Choose-Element-Type')}
                      name="elementName"
                      size="small"
                      multiline
                      maxRows={5}
                      dense="true"
                      variant="standard"
                      id={'element-name-1'}
                      error={
                        props.touched.elementName?.value &&
                        props.errors.elementName?.value
                      }
                      helperText={
                        props.touched.elementName?.value &&
                        props.errors.elementName?.value
                      }
                    />
                  )}
                />
                {props.values.isElementOther && (
                  <>
                    <TextField
                      key={'is_element_other'}
                      defaultValue={props.values.customElement?.title}
                      onChange={(e) =>
                        debouncedTextChangeHandler('customElement', {
                          ...props.values.customElement,
                          value: e.target.value,
                          title: e.target.value,
                        })
                      }
                      label={getIntlTranslation('_Element-Name')}
                      style={{ margin: '5px 0px' }}
                      id="defect_element_type_de"
                      dense="true"
                      variant="standard"
                      multiline
                      maxRows={5}
                      error={
                        props.touched.customElement?.value &&
                        props.errors.customElement?.value
                      }
                      helperText={
                        props.touched.customElement?.value &&
                        props.errors.customElement?.value
                      }
                    />
                  </>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    autoOk
                    minDate={dayjs(new Date(1800, 1, 1))}
                    disableFuture={true}
                    name="constructionYear"
                    views={['year']}
                    size="small"
                    dense="true"
                    style={{ marginTop: 5 }}
                    id="defect_element_construction_year"
                    label={getIntlTranslation('_Element-Construction-Year')}
                    value={dayjs(new Date(props.values.constructionYear, 1, 1))}
                    onChange={(date) => {
                      if (!!date) {
                        props.setFieldValue('constructionYear', date.year());
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        size: 'small',
                        style: { width: '100%' },
                      },
                      inputAdornment: {
                        position: 'start',
                      },
                    }}
                    error={null}
                    helperText={null}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

ElementSection.defaultProps = {
  data: {
    name: '',
    custom: {
      isCustom: false,
      nameEN: '',
      nameDE: '',
    },
  },
};

ElementSection.propTypes = {
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  subCategory: PropTypes.object,
  isEdit: PropTypes.object,
  resetForm: PropTypes.func,
};

export default ElementSection;
