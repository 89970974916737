import {
  Card,
  CardHeader,
  Avatar,
  Typography,
  Box,
  CardActions,
  CardContent,
  FormControlLabel,
  Checkbox,
  Divider,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIntlTranslation,
  getTranslationVariantText,
} from "../../../../../../utils/helper";
import { saveContactUser } from "../../../../api/chaptersApi";
import { resetState } from "./contactCardsSlice";
import { useSnackbar } from "notistack";
import useFetchUsersHook from "../../hooks/useFetchUsers ";

export const TypeContactCardsDataRender = ({ item, reportType }) => {
  const data = item.node;
  const [contactUsers, setContactUsers] = useState([]);
  const {users,fetchUsersDetails} = useFetchUsersHook({isFetching: false});
  useEffect(() => {
    if (!!data.users && data.users.length > 0) {
      fetchUsersDetails(data.users.map((m) => m.emailAddress))
    }
  }, [data.users]);

  useEffect(() => {
    if(!!users && users.length > 0) {
      const temp = data.users.map((m) => {
       let found =  users.find((f) => f.emailAddress === m.emailAddress)
       if(!!found) {
          m = {...m, mobile:found.mobile, taOfficeDto:found.taOfficeDto, position:found.position}
       }
       return m;
      }
        
      )
      setContactUsers(temp);
    }
  }, [users, data.users])
  

  return (
    <>
      <RenderContacts
        users={contactUsers}
        onChange={(users) => setContactUsers(users)}
        reportType={reportType}
      ></RenderContacts>
    </>
  );
};

const RenderContacts = ({ users, onChange, reportType }) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.contactCardsState);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(state.success.type === 'SAVED'){
      state.success.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
    }
  }, [state.success])
  

  const handleContactChange = (checked, emailAddress) => {
    const temp = users.map((el) => {
      if (el.emailAddress === emailAddress) {
        el = { ...el, contact: checked };
      }
      return el;
    });
    onChange(temp);

    const data = {
      type: "assetContactDto",
      assetIdent: openAsset.ident,
      users: temp,
    };
    dispatch(saveContactUser(data, scope.projectIdent, reportType));
  };

  const getSortedUsers = (users) => {
    const temp =  [...users].sort((a, b) =>a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()));
    return temp;
  }
  return (
    <>
      {!!users && users.length > 0 && (
        <Box className="d-flex align-items-center flex-wrap">
          {getSortedUsers(users).map((user, index) => {
            return (
              <Card
                key={`${user.firstName}-${index}`}
                className="m-1 p-1 g-contact rounded-1"
                style={{ width: 280 }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      variant="square"
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: "#244a9a",
                        borderRadius: 4,
                      }}
                    >
                      <Typography variant="caption">
                        {`${user.firstName
                          .charAt(0)
                          .toUpperCase()} ${user.lastName
                          .charAt(0)
                          .toUpperCase()}`}
                      </Typography>
                    </Avatar>
                  }
                  title={`${user.firstName?.toUpperCase()} ${user.lastName?.toUpperCase()}`}
                  subheader={
                    <Box style={{ display: "flex" }}>
                      {!!user.position ? (
                        <Typography variant="caption" style={{ marginLeft: 3, fontSize:12 }}>
                          {user.position}
                        </Typography>
                      ) : (
                        <Typography variant="caption" style={{fontSize: 12}}>
                         {getIntlTranslation("_position")}
                          <span
                            style={{ marginLeft: 3 }}
                            className="text-danger"
                          >
                            {getIntlTranslation("_Data is missing")}
                          </span>
                        </Typography>
                      )}
                    </Box>
                  }
                />
                <CardContent>
                  <Box style={{ display: "flex", flexWrap: "nowrap" }}>
                    {!!user.taOfficeDto?.officeName ? (
                      <Typography variant="caption">
                        {user.taOfficeDto?.officeName}
                      </Typography>
                    ) : (
                      <Typography variant="caption" style={{fontSize: 12}}>
                        {getIntlTranslation("_Office Name")} :{" "}
                        <span  style={{ marginLeft: 3 }}
                            className="text-danger">
                          {getIntlTranslation("_Data is missing")}
                        </span>
                      </Typography>
                    )}
                  </Box>
                  <Box style={{ display: "flex" }}>
                    {!!user.emailAddress ? (
                      <Typography variant="caption" style={{fontSize: 12}}>
                        <span className="text-primary fw-bold">{getIntlTranslation("_email")}</span> : {user.emailAddress}
                      </Typography>
                    ) : (
                      <Typography variant="caption" style={{fontSize: 12}}>
                       <span className="text-primary fw-bold">{getIntlTranslation("_email")}</span> :
                        <span  style={{ marginLeft: 3, fontSize: 12 }}
                            className="text-danger">
                          {getIntlTranslation("_Data is missing")}
                        </span>
                      </Typography>
                    )}
                  </Box>
                  <Box style={{ display: "flex" }}>
                    {!!user.mobile ? (
                      <Typography variant="caption" style={{fontSize: 12}}>
                         <span className="text-primary fw-bold">{getIntlTranslation("_Mobile")}</span> : {user.mobile}
                      </Typography>
                    ) : (
                      <Typography variant="caption" style={{fontSize: 12}}>
                       <span className="text-primary fw-bold">{getIntlTranslation("_Mobile")}</span> : 
                        <span  style={{ marginLeft: 3 }}
                            className="text-danger">
                          {getIntlTranslation("_Data is missing")}
                        </span>
                      </Typography>
                    )}
                  </Box>
                </CardContent>
                <Divider orientation="vertical" className="g-divider mb-1" />
                <CardActions className="p-1 d-flex align-items-center justify-content-center g-btn-secondary rounded-1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={user.contact}
                        onChange={(e) =>
                          handleContactChange(
                            e.target.checked,
                            user.emailAddress
                          )
                        }
                        name={user.emailAddress}
                      />
                    }
                    label={
                      user.contact
                        ? getIntlTranslation("_Remove contact")
                        : getIntlTranslation("_Add contact")
                    }
                  />
                </CardActions>
              </Card>
            );
          })}
        </Box>
      )}
    </>
  );
};

export const TypeContactCardsPreviewRender = ({ item, reportType }) => {
  const scope = useSelector((state) => state.container.scope);
  const data = item.node;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!!data.users && data.users.length > 0) {
      setUsers(data.users);
    }
  }, [data.users]);

  const getContacts = () => users.filter((user) => user.contact);

  return (
    <>
      {!!users && users.length > 0 && (
        <Box className="d-flex align-items-center flex-wrap justify-content-around w-100">
          {getContacts().length <= 0 ? (
            <>
              <Box className="d-flex flex-column p-1 m-1 justify-content-start w-100">
                <Box className="m-1 w-100">
                <Chip label={
              <Typography
              style={{
                fontSize: 14,
                width: "95%",
                marginRight: 5,
                textWrap: "wrap",
              }}
              className="text-danger m-1"
              variant="subtitle2"
            >
              {getIntlTranslation("_no-contacts")}
            </Typography>
            } color="error" variant="outlined" style={{width:"95%",height:"auto" ,marginRight:5}}/>
                </Box>
                <RenderContacts
                  users={users}
                  onChange={(users) => setUsers(users)}
                  reportType={reportType}
                />
              </Box>
            </>
          ) : (
            <>
              <RenderContacts
                users={users}
                onChange={(users) => setUsers(users)}
                reportType={reportType}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
};
