import { useState } from 'react';

const usePopoverState = (initialValue = null) => {
  const [anchorEl, setAnchorEl] = useState(initialValue);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return [anchorEl,
    handleClick,
    handlePopOverClose,
    isOpen];
};

export default usePopoverState;
