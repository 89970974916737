import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getDataCollection } from '../api/dataCollectionApi';
import { Alert, Box, Grid } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SelectOption from './SelectOption';
import DataTable from './DataTable';
import { useSelector } from 'react-redux';
import ExampleChart from './ExampleChart';
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

const DataCollectionWrapper = (props) => {
  const dispash = useDispatch();
  const dataCollection = useSelector((state) => state.dataCollection);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispash(getDataCollection());
  }, []);

  useEffect(() => {
    if (dataCollection?.data) {
      setData(dataCollection.data);
    }
  }, [dataCollection?.data]);
  const defaultProps = {
    options: currencies,
    getOptionLabel: (option) => option.label,
  };

  return (
    <div
      style={{
        padding: 5,
        backgroundColor: '#ffff',
      }}
      className="scrol"
    >
      <Box
        style={{
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: '#ffff',
        }}
        className="custom-scrollBar"
        key="1"
      >
        <Grid
          container
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <SelectOption defaultProps={defaultProps} />  */}
          {/* <DataTable data={data}/> */}
          {/* <DataGraph data={data}/> */}
          {/* <ExampleChart data={data}/> */}
        </Grid>
      </Box>
    </div>
  );
};

DataCollectionWrapper.propTypes = {};

export default DataCollectionWrapper;
