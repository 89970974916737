import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomStructure from "./CustomStructure";

const RenderCustomStructure = ({ selectedChapter, info}) => {
  const { current, chapterData, section, subSection, report } = info;
  const [currentChapterDatas, setCurrentChapterDatas] = useState(null);

useEffect(() => {
 if(!!selectedChapter){
   setCurrentChapterDatas(selectedChapter);
 }
}, [selectedChapter])

  return (
    <>
        {!!currentChapterDatas && !!current && (
            <CustomStructure
              currentChapterDatas={currentChapterDatas}
              current={current}
              section={section}
              subSection={subSection}
              report={report}
            />
        )}
    </>
  );
};

export default RenderCustomStructure;
