import React, {  useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersDetails, getUsers } from "../../../../../api/userManagementApi";
import { resetState } from "../../../../component-bundle/users/usersSlice";


function useFetchUsersHook ({isFetching = false}) {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const usersState = useSelector((state) => state.users);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if(isFetching){
      dispatch(getUsers());
    }
   
  }, [dispatch, isFetching]);

  useEffect(() => {
    if (usersState.userList.type === 'FETCHED' && !!usersState.userList.data) {
      let temp = usersState.userList.data.map((user) => {
        return {
          title: `${user.firstName} ${user.lastName}`,
          ...user,
          id: user.userId,
          display:`@${user.firstName} ${user.lastName}`,
        };
      });
      setUsers(temp);
      dispatch(resetState());
    }
  }, [dispatch, usersState.userList]);


  const fetchUsersDetails = (users) => {
    dispatch(getUsersDetails({users}));
  }

  return {users, fetchUsersDetails};
}

export default useFetchUsersHook;
