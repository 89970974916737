import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import DocumentContainer from "./DocumentContainer"
import React from "react";

const DocumentDataWrapper = props => {
  return (
    <DocumentContainer/>
  )
}

DocumentDataWrapper.propTypes = {}

export default DocumentDataWrapper;