import { createSlice } from "@reduxjs/toolkit";
import {response} from "../../../utils/defaultValues"

export const initialState = {
  hasErrors: false,
  error:{
    ...response
 },
 type: "",
 messages: [],
 data: {},
 projectData:{
  ...response
 },
 clientList:{
  ...response
 }
};

const projectListSlice = createSlice({
  name: "projectList",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }

    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDtoList,
    }),
    getProjectData: (state, { payload }) => ({
      ...state,
      projectData:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
       }
    }),
    getClientData: (state, { payload }) => ({
      ...state,
      clientList:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      }
    }),
    resetState: (state) => ({
      ...initialState,
      tab: state.tab,
    }),
  },
});

export const { resetState,getFailure,getSuccess,getProjectData,getClientData } = projectListSlice.actions;

// A selector
export const projectListSelector = (state) => state.projectList;

// The reducer
export default projectListSlice.reducer;