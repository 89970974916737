import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";
export const initialState = {
  hasErrors: false,
  error: { ...response },
  fetch: { ...response },
  fetchCompare: { ...response },
  save: { ...response },
  custom: { ...response },
  customUpdate:{ ...response },
  customDelete: { ...response },
  currentReportVersion: {},
  currentVersion:{},
  comparisonMode:false,
  projectUsers:{ ...response },
  updatedRows:[],
  additionalProperties: { ...response },
  propertyAction: {
    action: null,
    status: null,
    data: null,
  },
};

const projectInformationSlice = createSlice({
  name: "projectInformation",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetch: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getProjectUserSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      projectUsers: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getFetchCompareSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchCompare: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getCustomSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      custom: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomUpdate: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customUpdate: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomDelete: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customDelete: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    setUpdatedRows:(state,{payload}) => ({
      ...state,
      updatedRows:payload,
    }),
    setCurrentReportVersion: (state, { payload }) => ({
      ...state,
      currentReportVersion: payload,
    }),
    setComparisonMode: (state, { payload }) => ({
      ...state,
      comparisonMode: payload,
    }),
    resetState: (state) => ({
      ...initialState,
      currentReportVersion: state.currentReportVersion,
      comparisonMode:state.comparisonMode
    }),
    setPropertyAction: (state, { payload }) => ({
      ...state,
      propertyAction: {
        action: payload.action,
        status: payload.status,
        data: payload.data,
      },
    }),
    resetPropertyAction: (state) => ({
      ...state,
      propertyAction: {
        action: null,
        status: null,
        data: null,
      },
    }),
    setAdditionalProperties: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      additionalProperties: {
        type: payload.type,
        messages: payload.messages,
        data: payload.data,
      },
    }),
    setCurrentVersion: (state, { payload }) =>({ ...state, hasErrors: false, currentVersion:payload}),
    setCompleteReset: (state, { payload }) => ({  ...initialState})
  },
});

export const {
  resetState,
  getFailure,
  getFetchSuccess,
  getCustomSuccess,
  getCustomUpdate,
  getSaveSuccess,
  getFetchCompareSuccess,
  getCustomDelete,
  setCurrentReportVersion,
  setDirtyChanges,
  resetDirtyChanges,
  setComparisonMode,
  getProjectUserSuccess,
  setUpdatedRows,
  setPropertyAction,
  resetPropertyAction,
  setAdditionalProperties,
  setCurrentVersion,
  setCompleteReset,
  

} = projectInformationSlice.actions;

// A selector
export const projectInformationSelector = (state) => state.projectInformation;

// The reducer
export default projectInformationSlice.reducer;
