import { tddRoot } from "../../utils/defaultValues";
import { globalRoot } from "../../utils/defaultValues";
import ListIcon from "@mui/icons-material/List";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GridViewIcon from "@mui/icons-material/GridView";
import FlagIcon from "@mui/icons-material/Flag";
import SettingsIcon from "@mui/icons-material/Settings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import GradingIcon from "@mui/icons-material/Grading";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ConstructionIcon from "@mui/icons-material/Construction";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

export const projectScopeNav = [];

export const nav = [
  {
    id: "tdd-project-list",
    icon: (
      <ListIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    label: "menu.project-list",
    to: `${tddRoot}/project-list`,
    roles: [],
    renderScope: true,
    defaultAccess: true,
    parentId: "tdd-management",
  },
  // {
  //   id: "tdd-data-analysis",
  //   icon: <DonutLargeIcon style={{ width:22, height:22, color: "#637381"}} />,
  //   label: "menu.data-analysis",
  //   to: `${tddRoot}/data-analysis`,
  //   roles: [],
  //   renderScope: true,
  //   defaultAccess: true,
  //   parentId: "tdd-management",
  // },
  {
    id: "tdd-overview-tag",
    label: "tag.overview",
    tag: true,
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-project-dashboard",
    label: "menu.project-dashboard",
    to: `${tddRoot}/project/project-dashboard`,
    icon: (
      <GridViewIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-assets-overview",
    label: "menu.asset-overview",
    to: `${tddRoot}/project/assets-overview`,
    icon: (
      <ApartmentIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-building-profile",
    label: "menu.building-profile",
    to: `${tddRoot}/project/building-profile`,
    icon: (
      <RoomPreferencesIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-project-defects",
    label: "menu.defects",
    to: `${tddRoot}/project/defects`,
    icon: (
      <ConstructionIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-photo-documentation",
    label: "menu.photo-documentation",
    to: `${tddRoot}/project/photo-documentation`,
    icon: (
      <LinkedCameraIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-capex-overview",
    label: "menu.capexOverview",
    to: `${tddRoot}/project/capex-overview`,
    icon: (
      <RequestQuoteIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-red-flag",
    label: "menu.redFlag",
    to: `${tddRoot}/project/red-flag`,
    icon: (
      <FlagIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-full-report",
    label: "menu.fullReport",
    to: `${tddRoot}/project/full-report`,
    icon: (
      <AutoStoriesIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-statutory-compliance",
    label: "menu.statutoryCompliance",
    to: `${tddRoot}/project/statutory-compliance`,
    icon: (
      <GradingIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    defaultAccess: true,
    renderScope: false,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
  {
    id: "tdd-custom-report",
    label: "menu.customReport",
    defaultAccess: false,
    to: `${tddRoot}/project/custom-report`,
    icon: (
      <PostAddIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    roles: [],
    renderScope: false,
    renderScopeCondition: {
      serviceLine: "tdd",
      key: "isProjectScope",
    },
    parentId: "tdd-management",
  },
];
