import { globalRoot } from "../../utils/defaultValues";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FoundationIcon from "@mui/icons-material/Foundation";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import Groups3Icon from "@mui/icons-material/Groups3";
import { nav as tddNav } from "./tddNav";
import { nav as pmNav } from "./projectMonitoringNav";
import { authRoles, projectAccessRoles } from "../../authentication/authRoles";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ListIcon from "@mui/icons-material/List";
import DocumentScannerSharpIcon from '@mui/icons-material/DocumentScannerSharp';

const nav = [
  // {
  //   id: "task-management",
  //   location: "task-management",
  //   icon: (
  //     <Badge color="secondary" badgeContent={3}>
  //       <TaskAltIcon />
  //     </Badge>
  //   ),
  //   label: "menu.task-management",
  //   to: `${globalRoot}/task-management/`,
  //   roles: [],
  //   children: [],
  // },

  {
    id: "user-dashboard",
    location: "user-dashboard",
    icon: <DashboardIcon style={{ width: 22, height: 22, color: "#637381" }} />,
    label: "menu.user-dashboard",
    to: `${globalRoot}/user-dashboard/`,
    roles: authRoles.all,
    children: [],
    mappedRoutes: [
      {
        path: `${globalRoot}/user-dashboard/`,
      },
      {
        path: `${globalRoot}`,
      },
      {
        path: "/",
      },
      {
        path: `${globalRoot}/`,
      },
    ],
  },
  {
    id: "user-management",
    location: "user-management",
    icon: (
      <ManageAccountsIcon style={{ width: 22, height: 22, color: "#637381" }} />
    ),
    label: "menu.user-management",
    to: `${globalRoot}/user-management/`,
    roles: authRoles.admin,
    children: [],
    mappedRoutes: [
      {
        path: `${globalRoot}/user-management/`,
      },
    ],
  },
  // {
  //   id: "data-collection",
  //   location: "data-collection",
  //   icon: <CategoryIcon style={{ width:22, height:22, color: "#637381"}} />,
  //   label: "menu.data-collection",
  //   to: `${globalRoot}/data-collection/`,
  //   roles: authRoles.admin,
  //   children: [],
  //   mappedRoutes:[
  //     {
  //       path:`${globalRoot}/data-collection/`,
  //     },
  //   ],
  // },
  {
    id: "client-management",
    location: "client-management",
    icon: <Groups3Icon style={{ width: 22, height: 22, color: "#637381" }} />,
    label: "menu.client-management",
    to: `${globalRoot}/client-management/`,
    roles: authRoles.admin,
    children: [],
    mappedRoutes: [
      {
        path: `${globalRoot}/client-management/`,
      },
    ],
  },
  // {
  //   id: "cost-dataRoom",
  //   location: "cost-dataRoom",
  //   icon: <RequestQuoteIcon style={{ width:22, height:22, color: "#637381"}} />,
  //   label: "menu.cost-dataRoom",
  //   to: `${globalRoot}/cost-dataRoom`,
  //   roles: authRoles.admin,
  //   children: [],
  // },
  {
    id: "tdd-management",
    location: "tdd",
    icon: (
      <EngineeringIcon style={{ width: 22, height: 22, color: "#637381" }} />
    ),
    label: "menu.tdd-management",
    to: `${globalRoot}/tdd`,
    roles: projectAccessRoles.all,
    children: [...tddNav],
    mappedRoutes: [
      {
        path: `${globalRoot}/tdd`,
      },
      {
        path: `${globalRoot}/tdd/`,
      },
    ],
  },
 {
    id: "pm-management",
    location: "monitoring",
    icon: (
      <FoundationIcon style={{ width: 22, height: 22, color: "#637381" }} />
    ),
    label: "menu.pm-management",
    to: `${globalRoot}/monitoring`,
    roles: projectAccessRoles.all,
    children: [...pmNav],
    mappedRoutes: [
      {
        path: `${globalRoot}/monitoring`,
      },
    ], 
  },
  {
    id: "preview-document",
    location: "monitoring",
    icon: (
      <DocumentScannerSharpIcon style={{ width: 22, height: 22, color: "#637381" }} />
    ),
    label: "menu.preview-document",
    to:`${globalRoot}/preview-document`,
    roles: projectAccessRoles.testAll,
    children: [],
    mappedRoutes: [
      {
        path: `${globalRoot}/preview-document`,
      },
    ],
  },
  // {
  //   id: "customizable-report",
  //   location: "customizable-report",
  //   icon: <SummarizeIcon style={{ width: 22, height: 22, color: "#637381" }} />,
  //   label: "menu.customizable-report",
  //   to: `${globalRoot}/customizable-report`,
  //   roles: projectAccessRoles.all,
  //   children: [],
  //   mappedRoutes: [
  //     {
  //       path: `${globalRoot}/customizable-report`,
  //     },
  //   ],
  // },
  // {
  //   id: "cost-data-center",
  //   location: "cost-data-center",
  //   icon: (
  //     <RequestQuoteIcon style={{ width: 22, height: 22, color: "#637381" }} />
  //   ),
  //   label: "menu.cost-data-center",
  //   to: `${globalRoot}/cost-data-center`,
  //   roles: projectAccessRoles.all,
  //   children: [],
  //   mappedRoutes: [
  //     {
  //       path: `${globalRoot}/cost-data-center`,
  //     },
  //   ],
  // },
  // {
  //   id: "esg-management",
  //   location: "esg",
  //   icon: <NaturePeopleIcon style={{ width:22, height:22, color: "#637381"}} />,
  //   label: "menu.esg-management",
  //   to: `${globalRoot}/esg`,
  //   roles: [],
  //   children: [],
  // },
  // {
  //   id: "tam-management",
  //   location: "tam",
  //   icon: <ManageAccountsIcon style={{ width:22, height:22, color: "#637381"}} />,
  //   label: "menu.tam-management",
  //   to: `${globalRoot}/tam`,
  //   roles: [],
  //   children: [],
  // },
  // {
  //   id: "infra-management",
  //   location: "tam",
  //   icon: <ManageAccountsIcon style={{ width:22, height:22, color: "#637381"}} />,
  //   label: "menu.infra-management",
  //   to: `${globalRoot}/infra-management`,
  //   roles: [],
  //   children: [],
  // },
];

export default nav;
