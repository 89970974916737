import React, { useState, useEffect, useRef } from "react";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Slide from "@mui/material/Slide";
import { IconButton, TextField, Box, AppBar, Toolbar, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import * as yup from "yup";
import { getIntlTranslation, getLabel } from "../../../../../utils/helper";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../../components/buttons/CustomButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditHeaders = ({ handleSubmit, handleClose, openDialog, data, setGetElement }) => {
  const formRef = useRef();
  const [intialFormData, setIntialFormData] = useState({
    english: "",
    german: "",
  });
  useEffect(() => {
    if (!!data.nameTranslation) {
      setIntialFormData({
        english:
          data.nameTranslation[0].language.toLowerCase() === "en"
            ? data.nameTranslation[0].title.toUpperCase()
            : data.nameTranslation[1].title.toUpperCase(),
        german:
          data.nameTranslation[1].language.toLowerCase() === "de"
            ? data.nameTranslation[1].title.toUpperCase()
            : data.nameTranslation[0].title.toUpperCase(),
      });
    }
  }, [data]);

  const validationSchema = yup.object({
    english: yup.string().required("English translation Name is required"),
    german: yup.string().required("German translation Name is required"),
  });

  const saveChanges =(value)=>{
    handleSubmit(value, data)
  }

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        maxHeight: 60,
        margin: 1,
        height: "100%",
      }}
    >
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-describedby="alert-dialog-slide-description"
      >
          <AppBar className="appBar" style={{ height:40}}>
          <Toolbar
            className={"dialog-toolbar p-1"}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box className="d-flex align-items-center" style={{marginLeft:5}}>
            <Typography variant="body2" style={{ textTransform: 'uppercase',marginLeft:5 }}>
      {data.name}
    </Typography>
            </Box>        
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={(e) => handleClose()}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Formik
          enableReinitialize={true}
          initialValues={intialFormData}
          validationSchema={validationSchema}
          onSubmit={saveChanges}
          innerRef={formRef}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <DialogContent
              className="w-100 d-flex flex-column"
                dividers
              >
                  <Box className="p-1 m-1 d-flex">
                    <IconButton>
                      <InfoIcon style={{fontSize:18}}/>
                    </IconButton>
                    <ListItemText
                      primary={`Please enter the name in English and German`}
                    />
                  </Box>
                      <Box className="p-1 m-1">
                        <Field
                          fullWidth={true}
                          label={getLabel("_English-Translation")}
                          id="fullWidth"
                          size="small"
                          as={TextField}
                          name={`english`}
                          value={values.english}
                          sx={{ minWidth: 300 }}
                          error={touched.english && errors.english}
                          helperText={touched.english && errors.english}
                        />
                      </Box>
                      <Box className="p-1 m-1">
                        <Field
                          fullWidth={true}
                          label={getLabel("_German-Translation")}
                          id="fullWidth"
                          size="small"
                          as={TextField}
                          name={`german`}
                          value={values.german}
                          sx={{ minWidth: 300 }}
                          error={touched.german && errors.german}
                          helperText={touched.german && errors.german}
                        />
                      </Box>
              </DialogContent>
             
            </Form>
          )}
        </Formik>
        <DialogActions>
              <CustomButton
                  id={`save-dialog`}
                  buttonText={"_save"}
                  variant="button"
                  style={{
                    margin: 5,
                    backgroundColor: "#254a9a",
                    color: "#ffffff",
                  }}
                  tooltip={{
                    title: getIntlTranslation("tooltip.save"),
                  }}
                  handleClick={()=>formRef.current.handleSubmit()}
                />
              </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EditHeaders;
