// @ts-nocheck
import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import {
  Backdrop,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { saveImages, onDelete } from "./imageProcessing";
import { resetSliceState } from "./imageProcessSlice";
import { useSnackbar } from "notistack";
import { getImageFiles } from "../../guru-suite/tdd-module/api/subAssetImageApi";
import { b64toBlob,getLabel } from "../../utils/helper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomButton from "../buttons/CustomButton";
import Divider from "@mui/material/Divider";
import SortablePhotoAlbum from "../../guru-suite/component-bundle/image-gallery/SortablePhotoAlbum";
import { ImageProvider } from "../../guru-suite/component-bundle/image-gallery/ImageContext";

const useStyles = styled((theme) => ({
  appBar: {
    position: "relative",
    minHeight: "40px",
    backgroundColor: "#ffffff",
    margin: 5,
    borderRadius: "5px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1500,
    color: "#fff",
  },
  large: {
    width: 300,
    height: 200,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  flex: {
    display: "flex",
  },
}));

const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};

export const isTouchDevice = () => {
  if ("ontouchstart" in window) {
    return true;
  }
  return false;
};

export default function GeneralPhotos(props) {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const { node } = props;
  const [loader, setLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const imageState = useSelector((state) => state.imageProcess);
  const readOnlystate = useSelector((state) => state.readOnly);
  const { enqueueSnackbar } = useSnackbar();
  const openAsset = useSelector((state) => state.openAsset);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>{
          let generatedId = uuidv4();
          return (Object.assign(file,{
            id: generatedId,
            key: generatedId,
            status: "new",
            caption: node.caption,
            order: null,
            parentIdent: "",
            primary: "",
            fileName: file.name,
            assetIdent: openAsset.ident,
            src: URL.createObjectURL(file),
            width: 300,
            height: 200,
            isEdited:false,
            markerState:{},
            editedFile:{},
          }))
        }
        ),
      ]);
    },
  });

  useEffect(() => {
    if (!!node) {
      dispatch(
        getImageFiles({
          ident: node.ident,
          projectIdent: node.projectIdent,
        })
      );
    }
    setFiles([]);
  }, [dispatch, node, node?.ident, node?.projectIdent]);

  useEffect(() => {
    if (imageState.type === "SUCCESS") {
      setLoader(true);
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      let savedFile = [];
      files.forEach((file) => {
        if (file.id === imageState.imageData.imageIdent) {
          file.status = "saved";
        }
        savedFile.push(file);
      });
      setFiles(savedFile);
      dispatch(resetSliceState());
    }

    if (imageState.type === "UPDATED") {
      setLoader(true);
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetSliceState());
      setLoader(false);
    }

    if (imageState.type === "DELETED") {
      setLoader(true);
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetSliceState());
      setLoader(false);
    }
    setLoader(false);
  }, [dispatch, enqueueSnackbar, files, imageState]);

  useEffect(() => {
    if (!!imageState.data && imageState.data.length > 0) {
      let tempFile = [];
      let sortedArray = [...imageState.data];
      sortedArray.sort((a, b) => {
        return a.order - b.order;
      });
      sortedArray.forEach((image) => {
        tempFile.push({
          id: image.imageIdent,
          status: "saved",
          caption: image.caption,
          order: image.order,
          fileName: image.fileName,
          parentIdent: image.parentIdent,
          primary: image.primary,
          assetIdent: openAsset.ident,
          src: b64toBlob(image.file),
          key: image.imageIdent,
          width: 300,
          height: 200,
          markerState: !!image.markerState
                ? JSON.parse(image.markerState)
                : {},
          editedFile: !!image.editedFile ? b64toBlob(image.editedFile) : {},
          isEdited: image.edited,
        });
      });
      setFiles([...files, ...tempFile]);
      dispatch(resetSliceState());
      setLoader(false);
    }
  }, [dispatch, files, imageState]);

  const handleSubmit = () => {
    files.forEach((file, index) => {
      file.order = index + 1;
      saveImages(file, node, dispatch, scope.projectIdent);
    });
    setLoader(true);
  };

  const onRemove = (file) => {
    let nonDeletedfiles = [];
    files.forEach((f) => {
      if (f.key === file.key && f.fileName === file.fileName) {
        if (f.status !== "new") {
          onDelete(f, node, dispatch, scope.projectIdent);
        }
      } else {
        nonDeletedfiles.push(f);
      }
    });
    setFiles(nonDeletedfiles);
  };

  const onImageSave = (file) => {
    setLoader(true);
    const index = files.findIndex(
      (f) => f.key === file.key && f.fileName === file.fileName
    );
    files[index].order = index + 1;
    setLoader(true);
    saveImages(files[index], node, dispatch, scope.projectIdent);
    setLoader(false);
  };

  const onCaptionChange = (key, value) => {
    let tempFile = [];
    files.forEach((element) => {
      if (key === element.key) {
        element.caption = value;
      }
      tempFile.push(element);
    });
    setFiles(tempFile);
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Box
          onDragOver={(e) => e.stopPropagation()}
          style={{
            width: "20%",
            backgroundColor: "#ffffff",
            height: "calc(100% - 10px)",
            margin: 5,
          }}
        >
          <div
            className={getClassName("image-drop", isDragActive)}
            {...getRootProps()}
            style={{
              height: "100%",
              border: "4px double #d08f8e",
              borderRadius: 4,
              padding: 4,
              cursor: "pointer",
            }}
          >
            <input className="dropzone-input" {...getInputProps()} {...props} />
            <div style={{ textAlign: "center" }} className="p-1">
              {isDragActive ? (
                <Typography className="dropzone-content">
                  {getLabel("_drop-the-files")}
                </Typography>
              ) : (
                <>
                  <Typography className="dropzone-content">
                    {getLabel("_Drag-Images")}
                  </Typography>
                  <em>{getLabel("_Image-Allowed")}</em>
                </>
              )}
            </div>
          </div>
          <Divider />
        </Box>
        <Card
          className={"p-1"}
          style={{
            width: "80%",
            height: "calc(100% - 10px)",
            marginTop: 5,
          }}
        >
          <CardContent
            className={"d-flex flex-column custom-scrollBar w-100 p-1"}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100% - 50px)",
            }}
          >
            {files?.filter((f) => !!f.id).length > 0 && (
              <ImageProvider
                onRemove={(file) => onRemove(file)}
                onImageSave={(file) => onImageSave(file)}
                onCaptionChange={(key, value) =>onCaptionChange(key, value)}
                onImageEdit={(photo, editedImage, markerState) => {
                  let temp = files.map((file) => {
                    if (file.key === photo.key && photo.fileName === file.fileName) {
                      if(!!markerState) {
                        file.markerState = markerState;
                        file.status = file.status === "saved" ? "edited" : file.status;
                        file.isEdited = true;
                        file.editedFile = editedImage;
                      }
                      
                    }
                    return file;
                  });
                  setFiles(temp);
                }}
                isDragable={true}
                onDragEnd={(photos) => setFiles(photos)}
              >
                <SortablePhotoAlbum photoSet={files} />
              </ImageProvider>
            )}
          </CardContent>
          {files.length > 0 && (
            <CardActions
              style={{
                marginTop: "auto",
                height: 50,
              }}
            >
              <CustomButton
                id="save-all-images"
                tooltip={{
                  title: "tooltip.Save-All-Images",
                }}
                buttonText={"_Save-All-Images"}
                handleClick={handleSubmit}
                style={{
                  backgroundColor: "#d9534f",
                  color: "#ffffff",
                  width: "98%",
                  height: 40,
                }}
                variant="contained"
                disabled={files.length <= 0}
              />
            </CardActions>
          )}
        </Card>
      </Box>
      {loader && (
        <Backdrop className={useStyles.backdrop} open={loader}>
          <div className="preloader">
            <div></div>
          </div>
        </Backdrop>
      )}
    </>
  );
}
