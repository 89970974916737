import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";
export const initialState = {
  hasErrors: false,
  error: { ...response },
  fetchCategories: { ...response },
  fetchProperties: { ...response },
  fetchCompare: { ...response },
  save: { ...response },
  custom: { ...response },
  customDelete: { ...response },
  currentData: {},
  savePropertyData: { ...response },
  saveAddOptional: { ...response },
  customCategoryUpdate: { ...response },
  customCategoryDelete: { ...response },
  customPropertyUpdate: { ...response },
  customPropertyDelete: { ...response },
  currentSelectedCategory: null,
  selectedComparingVersion:{},
  copyData:[],
  updatedRows:[],
  propertyCopyDataSave:{ ...response },
  isCompareMode: false,
  currentVersion:{},
  versions:[],
};

const keyFindingsSlice = createSlice({
  name: "keyFindings",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getFetchCategoriesSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchCategories: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getFetchPropertiesSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchProperties: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getFetchCompareSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchCompare: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getAddOptionalSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      saveAddOptional: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getPropertyDataSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      savePropertyData: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getPropertyCopyDataSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      propertyCopyDataSave: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      custom: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomCategoryUpdate: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customCategoryUpdate: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomCategoryDelete: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customCategoryDelete: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getCustomPropertyUpdate: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customPropertyUpdate: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomPropertyDelete: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customPropertyDelete: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    setCurrentData: (state, { payload }) => ({
      ...state,
      currentData: payload,
    }),
    setCurrentReportVersion: (state, { payload }) => ({
      ...state,
      currentReportVersion: payload,
    }),

    setSelectedCategory: (state, { payload }) => ({
      ...state,
      currentSelectedCategory: payload,
    }),
    setComparisonMode: (state, { payload }) => ({
      ...state,
      comparisonMode: payload,
    }),
    onSelectReportVersion: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      selectedComparingVersion:payload
    }),
    onCopyData: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      copyData:payload
    }),
    

    resetState: (state) => ({
      ...initialState,
      currentVersion:state.currentVersion,
      isCompareMode: state.isCompareMode,
    }),
    setVersions: (state, { payload }) => ({...state, hasErrors: false, versions:payload}),
    setCompareMode: (state, { payload }) => ({ ...state, hasErrors: false, isCompareMode:payload}),
    setCurrentVersion: (state, { payload }) =>({ ...state, hasErrors: false, currentVersion:payload}),
    setCompleteReset: (state, { payload }) => ({  ...initialState})
  },
});

export const {
  resetState,
  getFailure,
  getFetchPropertiesSuccess,
  getCustomSuccess,
  getCustomUpdate,
  getSaveSuccess,
  getFetchCompareSuccess,
  getCustomDelete,
  setCurrentData,
  getFetchCategoriesSuccess,
  getPropertyDataSaveSuccess,
  getAddOptionalSaveSuccess,
  getCustomPropertyDelete,
  getCustomPropertyUpdate,
  getCustomCategoryUpdate,
  getCustomCategoryDelete,
  setCurrentReportVersion,
  setSelectedCategory,
  setComparisonMode,
  onCopyData,
  getPropertyCopyDataSaveSuccess, setCompareMode,
  setCurrentVersion,
  onSelectReportVersion,
  setVersions,
  setCompleteReset,

} = keyFindingsSlice.actions;

// A selector
export const keyFindingsSelector = (state) => state.keyFindings;

// The reducer
export default keyFindingsSlice.reducer;
