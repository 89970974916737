export const taskData = [
    {
       "id":1,
       "title":"Task 1",
       "created_user":{
          "first_name":"John",
          "surname":"Doe",
          "id":1,
          "image":"https://picsum.photos/200/200/?random"
       },
       "assignedTo":[
          {
             "first_name":"Jane",
             "surname":"Smith",
             "id":4,
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"William",
             "surname":"Brown",
             "id":6,
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"Emily",
             "surname":"Johnson",
             "id":10,
             "image":"https://picsum.photos/200/200/?random"
          }
       ],
       "created_date":"2023-02-12T00:00:00Z",
       "status":"In Progress",
       "description":"This is the description for Task 1",
       "tags":[
          "Tag1",
          "Tag2",
          "Tag3"
       ],
       "comments":[
          {
             "first_name":"Jane",
             "surname":"Smith",
             "date":"2023-02-12T00:00:00Z",
             "comment":"This is a comment on Task 1",
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"William",
             "surname":"Brown",
             "date":"2023-02-12T00:00:00Z",
             "comment":"This is another comment on Task 1",
             "image":"https://picsum.photos/200/200/?random"
          }
       ]
    },
    {
       "id":2,
       "title":"Task 2",
       "created_user":{
          "first_name":"Jane",
          "surname":"Smith",
          "id":4,
          "image":"https://picsum.photos/200/200/?random"
       },
       "assignedTo":[
          {
             "first_name":"John",
             "surname":"Doe",
             "id":1,
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"Emily",
             "surname":"Johnson",
             "id":10,
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"William",
             "surname":"Brown",
             "id":6,
             "image":"https://picsum.photos/200/200/?random"
          }
       ],
       "created_date":"2023-02-12T00:00:00Z",
       "status":"In Progress",
       "description":"This is the description for Task 2",
       "tags":[
          "Tag1",
          "Tag2",
          "Tag3"
       ],
       "comments":[
          {
             "first_name":"John",
             "surname":"Doe",
             "date":"2023-02-12T00:00:00Z",
             "comment":"This is a comment on Task 2",
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"Emily",
             "surname":"Johnson",
             "date":"2023-02-12T00:00:00Z",
             "comment":"This is another comment on Task 2",
             "image":"https://picsum.photos/200/200/?random"
          }
       ]
    },
    {
       "id":3,
       "title":"Develop new software feature",
       "created_user":{
          "firstname":"Abby",
          "surname":"Harrison",
          "id":5,
          "image":"https://randomuser.me/api/portraits/women/35.jpg"
       },
       "assignedTo":[
          {
             "firstname":"Ralph",
             "surname":"Turner",
             "id":15,
             "image":"https://randomuser.me/api/portraits/men/51.jpg"
          },
          {
             "firstname":"Evelyn",
             "surname":"Hayes",
             "id":16,
             "image":"https://randomuser.me/api/portraits/women/83.jpg"
          }
       ],
       "created_date":"2022-10-06T09:20:00Z",
       "status":"In Progress",
       "description":"Design and implement a new feature for the software that will increase user engagement.",
       "tags":[
          "Software Development",
          "User Experience"
       ],
       "comments":[
          {
             "firstname":"Evelyn",
             "surname":"Hayes",
             "date":"2022-10-08T12:45:00Z",
             "comment":"I suggest we add a user feedback feature to the software so that we can gather user opinions on the new feature.",
             "image":"https://randomuser.me/api/portraits/women/83.jpg"
          },
          {
             "firstname":"Ralph",
             "surname":"Turner",
             "date":"2022-10-10T15:30:00Z",
             "comment":"I agree, a feedback feature would be a great addition to the software. Let's start working on it.",
             "image":"https://randomuser.me/api/portraits/men/51.jpg"
          }
       ]
    },
    {
       "id":4,
       "title":"Create marketing campaign",
       "created_user":{
          "firstname":"Avery",
          "surname":"Wheeler",
          "id":6,
          "image":"https://randomuser.me/api/portraits/women/74.jpg"
       },
       "assignedTo":[
          {
             "firstname":"Caleb",
             "surname":"Spencer",
             "id":17,
             "image":"https://randomuser.me/api/portraits/men/76.jpg"
          },
          {
             "firstname":"Wanda",
             "surname":"Campbell",
             "id":18,
             "image":"https://randomuser.me/api/portraits/women/24.jpg"
          }
       ],
       "created_date":"2022-10-07T11:30:00Z",
       "status":"In Progress",
       "description":"Develop and execute a marketing campaign to promote the new software feature.",
       "tags":[
          "Marketing",
          "Software Promotion"
       ],
       "comments":[
          {
             "firstname":"Caleb",
             "surname":"Spencer",
             "date":"2022-10-09T13:15:00Z",
             "comment":"I think we should focus on social media advertising to reach a wider audience.",
             "image":"https://randomuser.me/api/portraits/men/76.jpg"
          },
          {
             "firstname":"Wanda",
             "surname":"Campbell",
             "date":"2022-10-11T16:00:00Z",
             "comment":"I agree, social media advertising is a cost-effective way to reach a large audience. Let's start with Facebook and Twitter.",
             "image":"https://randomuser.me/api/portraits/women/24.jpg"
          }
       ]
    },
    {
       "id":5,
       "title":"Improve website design",
       "created_user":{
          "firstname":"Liam",
          "surname":"Price",
          "id":7,
          "image":"https://randomuser.me/api/portraits/men/29.jpg"
       },
       "assignedTo":[
          {
             "firstname":"Martha",
             "surname":"Lynch",
             "id":19,
             "image":"https://randomuser.me/api/portraits/women/75.jpg"
          },
          {
             "firstname":"Frank",
             "surname":"Morrison",
             "id":20,
             "image":"https://randomuser.me/api/portraits/men/87.jpg"
          }
       ],
       "created_date":"2022-10-08T13:45:00Z",
       "status":"In Progress",
       "description":"Improve the overall design of the website to make it more user-friendly and visually appealing.",
       "tags":[
          "Web Design",
          "User Experience"
       ],
       "comments":[
          {
             "firstname":"Martha",
             "surname":"Lynch",
             "date":"2022-10-10T15:30:00Z",
             "comment":"I suggest we use a minimalist design approach to simplify the website and make it more user-friendly.",
             "image":"https://randomuser.me/api/portraits/women/75.jpg"
          },
          {
             "firstname":"Frank",
             "surname":"Morrison",
             "date":"2022-10-12T17:15:00Z",
             "comment":"I agree, a minimalist design approach would definitely make the website more user-friendly. Let's start working on it.",
             "image":"https://randomuser.me/api/portraits/men/87.jpg"
          }
       ]
    },
    {
       "id":3,
       "title":"Design project proposal",
       "created_user":{
          "firstname":"Ashley",
          "surname":"Williamson",
          "id":8,
          "image":"https://i.pravatar.cc/150?img=3"
       },
       "assignedTo":[
          {
             "firstname":"Lori",
             "surname":"Johnson",
             "id":6,
             "image":"https://i.pravatar.cc/150?img=6"
          },
          {
             "firstname":"David",
             "surname":"Harris",
             "id":14,
             "image":"https://i.pravatar.cc/150?img=14"
          }
       ],
       "created_date":"2022-11-24T03:32:19.294Z",
       "status":"In progress",
       "description":"Create a detailed project proposal for the new design project.",
       "tags":[
          "design",
          "proposal"
       ],
       "comments":[
          {
             "firstname":"Ashley",
             "surname":"Williamson",
             "date":"2022-11-24T07:15:20.294Z",
             "comment":"I have started working on the proposal, I will send a draft by end of day.",
             "image":"https://i.pravatar.cc/150?img=3"
          },
          {
             "firstname":"David",
             "surname":"Harris",
             "date":"2022-11-24T10:15:20.294Z",
             "comment":"I am also working on some parts, we can combine our efforts and create a better proposal.",
             "image":"https://i.pravatar.cc/150?img=14"
          }
       ]
    },
    {
       "id":4,
       "title":"Write blog post",
       "created_user":{
          "firstname":"Christine",
          "surname":"Harrison",
          "id":17,
          "image":"https://i.pravatar.cc/150?img=17"
       },
       "assignedTo":[
          {
             "firstname":"Christine",
             "surname":"Harrison",
             "id":17,
             "image":"https://i.pravatar.cc/150?img=17"
          }
       ],
       "created_date":"2022-12-01T03:32:19.294Z",
       "status":"In progress",
       "description":"Write a blog post about the latest advancements in technology.",
       "tags":[
          "technology",
          "blog post"
       ],
       "comments":[
          {
             "firstname":"Christine",
             "surname":"Harrison",
             "date":"2022-12-01T07:15:20.294Z",
             "comment":"I am researching for the blog post",
             "image":"https://picsum.photos/200/200/?random"
          }
       ]
    },
    {
       "id":20,
       "title":"Task 20",
       "created_user":{
          "first_name":"Emily",
          "surname":"Johnson",
          "id":10,
          "image":"https://picsum.photos/200/200/?random"
       },
       "assignedTo":[
          {
             "first_name":"John",
             "surname":"Doe",
             "id":1,
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"Jane",
             "surname":"Smith",
             "id":4,
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"William",
             "surname":"Brown",
             "id":6,
             "image":"https://picsum.photos/200/200/?random"
          }
       ],
       "created_date":"2023-02-12T00:00:00Z",
       "status":"In Progress",
       "description":"This is the description for Task 20",
       "tags":[
          "Tag1",
          "Tag2",
          "Tag3"
       ],
       "comments":[
          {
             "first_name":"Jane",
             "surname":"Smith",
             "date":"2023-02-12T00:00:00Z",
             "comment":"This is a comment on Task 20",
             "image":"https://picsum.photos/200/200/?random"
          },
          {
             "first_name":"William",
             "surname":"Brown",
             "date":"2023-02-12T00:00:00Z",
             "comment":"This is another comment on Task 20",
             "image":"https://picsum.photos/200/200/?random"
          }
       ]
    },
    {
       "id":3,
       "title":"Develop new software feature",
       "created_user":{
          "firstname":"Megan",
          "surname":"Wright",
          "id":3,
          "image":"https://randomuser.me/api/portraits/women/91.jpg"
       },
       "assignedTo":[
          {
             "firstname":"Amelia",
             "surname":"Greene",
             "id":9,
             "image":"https://randomuser.me/api/portraits/women/69.jpg"
          },
          {
             "firstname":"Dylan",
             "surname":"Young",
             "id":11,
             "image":"https://randomuser.me/api/portraits/men/47.jpg"
          }
       ],
       "created_date":"2022-11-22T15:32:20.000Z",
       "status":"In Progress",
       "description":"Develop a new software feature for the product",
       "tags":[
          "Software",
          "Feature",
          "Development"
       ],
       "comments":[
          {
             "firstname":"Megan",
             "surname":"Wright",
             "date":"2022-12-05T08:32:20.000Z",
             "comment":"Starting the development of the new feature",
             "image":"https://randomuser.me/api/portraits/women/91.jpg"
          },
          {
             "firstname":"Amelia",
             "surname":"Greene",
             "date":"2022-12-05T10:32:20.000Z",
             "comment":"Looking forward to the new feature",
             "image":"https://randomuser.me/api/portraits/women/69.jpg"
          }
       ]
    },
    {
       "id":4,
       "title":"Design new product packaging",
       "created_user":{
          "firstname":"Olivia",
          "surname":"Harris",
          "id":4,
          "image":"https://randomuser.me/api/portraits/women/24.jpg"
       },
       "assignedTo":[
          {
             "firstname":"Isabella",
             "surname":"Watson",
             "id":6,
             "image":"https://randomuser.me/api/portraits/women/72.jpg"
          },
          {
             "firstname":"William",
             "surname":"Brown",
             "id":18,
             "image":"https://randomuser.me/api/portraits/men/12.jpg"
          }
       ],
       "created_date":"2022-10-15T05:32:20.000Z",
       "status":"In Progress",
       "description":"Design new packaging for the product",
       "tags":[
          "Packaging",
          "Design"
       ],
       "comments":[
          {
             "firstname":"Olivia",
             "surname":"Young",
             "date":"2022-12-10T00:00:00.000Z",
             "comment":"This is comment 1 for task 20",
             "image":"https://i.picsum.photos/id/39/200/300.jpg"
          }
       ]
    }]