import { useCallback, useContext, useEffect, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext, useNavigate,useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText,  DialogTitle,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import CustomButton from "../buttons/CustomButton";
import {getIntlTranslation} from "../../utils/helper"
import { sidebar } from "../../layout/app-container/systemSlice";
import { useDispatch, useSelector } from "react-redux";

export function RouterPrompt(props) {
  const {
    when,
    onOK,
    onCancel,
    message,
    okText,
    cancelText,
    warningHeader,
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [userLeaving, setUserLeaving] = useState(false);
  const [userStaying, setUserStaying] = useState(false);
  const { navigator } = useContext(NavigationContext);
  const [nextDestination, setNextDestination] = useState(null);
  const [usBlocker, setUsBlocker] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const readOnlystate = useSelector((state) => state.readOnly);
  useEffect(() => {
    let push = navigator.push;
    if (usBlocker && !userLeaving ) {
      navigator.push = (...args) => {
        if (nextDestination !== null) {
          // If user has confirmed exit, don't show prompt again
          setNextDestination(null);
          push(...args);
        }else{
          setNextDestination(args[0]);
          setShowPrompt(true);
        }
      };
    }

    return () => {
      navigator.push = push;
    };
  }, [navigator, userStaying, userLeaving, nextDestination, usBlocker]);

  const handleStay = () => {
    setUserLeaving(false);
    setUserStaying(true);
    setShowPrompt(false);
    setUsBlocker(true);
    setNextDestination(null);
    onCancel()
  };

  const handleLeave = () => {
    setUserLeaving(true);
    setUserStaying(false);
    setShowPrompt(false);
    setUsBlocker(false);
    onOK()
    navigate(nextDestination.pathname);
    dispatch(sidebar(false))
  };

  useEffect(() => {
    if (when && !readOnlystate.isReadOnly) {
      setUsBlocker(true);
      dispatch(sidebar(true))
    } else {
      setUsBlocker(false);
      dispatch(sidebar(false))
    }
  }, [dispatch, readOnlystate.isReadOnly, when]);

  return showPrompt ? (
    <>
    <Dialog
      open={showPrompt}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleStay();
        }
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>
        <Alert severity="warning" className={"d-flex align-items-center justify-items-center"}>
            <AlertTitle style={{ fontWeight: "bold" }}>
              {warningHeader}
            </AlertTitle>
            {getIntlTranslation("_action prompt","caption")}
            </Alert>
        </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          
            {message}
         
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          id="cancel_prompt_01"
          buttonText={cancelText}
          variant="text"
          tooltip={{
            title: "Close dialog",
          }}
          style={{ margin: 5 }}
          handleClick={(e) => handleStay()}
        />
        <CustomButton
          id="ok_prompt_01"
          buttonText={okText}
          style={{ color: "#ffffff", backgroundColor: "#254a9a", margin: 5 }}
          variant="button"
          tooltip={{
            title: "Confirm",
          }}
          handleClick={(e) => handleLeave()}
        />
      </DialogActions>
    </Dialog>
    </>
  ) : null;
}

export default RouterPrompt;
