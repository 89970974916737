import React from 'react'
import Box from '@mui/material/Grid';
import ProjectListHome from "../../../component-bundle/project-list/ProjectListHome"
import {pmServiceLine} from '../../../../utils/defaultValues'


const PMProjectListHome = () => {
  return (
    <Box
    style={{ background:"#ffffff", height: "100%", padding: "10px"}}
  >
  <ProjectListHome serviceLine={pmServiceLine}/>
  </Box>
  );
};
export default PMProjectListHome;