import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';

const CustomLoader = (props) => {
  const { promiseInProgress } = props.promiseTracker ? props.promiseTracker() : false;
  const loaderStyle = {
    background: props.background,
  };
  return (
    props.loading || promiseInProgress
      ? <Backdrop open={promiseInProgress}  style={{ color: '#fff', zIndex: "2001"}}>
  <div className="preloader">
          <div></div>
          </div>
</Backdrop>
 : ''
  );
};

CustomLoader.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
  background: PropTypes.string,
  promiseTracker: PropTypes.any,
};

CustomLoader.defaultProps = {
  color: '#000',
  loading: false,
  background: 'rgba(255,255,255,.5)',
  promiseTracker: false,
};

export default CustomLoader;