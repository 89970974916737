import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Alert,
  AlertTitle,
  Slide,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material//Close";
import { getDialogBoxHeader, getIntlTranslation} from "../utils/helper";
import CustomButton from "../components/buttons/CustomButton";

// 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const {
    onClose,
    node,
    info,
    severity,
    status,
    open,
    onConfirmation,
    buttonText,
  } = props;
  const [openDialog, setOpenDialog] = useState(open);

  const handleConfirmation = () => {
    if (typeof onConfirmation === "function") {
      onConfirmation("Ok", { ...node, status });
    }
    if (typeof onClose === "function") {
      onClose();
    }
    setOpenDialog(false);
  };

  const handleCancel = () => {
    if (typeof onConfirmation === "function") {
      onConfirmation("Cancel", node);
    }
    if (typeof onClose === "function") {
      onClose();
    }
    setOpenDialog(false);
  };

  const handleClose = () => {
    if (typeof onConfirmation === "function") {
      onConfirmation("Cancel", node);
    }
    if (typeof onClose === "function") {
      onClose();
    }
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar className={"appBar"}>
          <Toolbar className={"dialog-toolbar"}>
            {getDialogBoxHeader(`dialog.delete-Asset`)}
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={handleClose}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Alert severity={severity}>
            <AlertTitle>{severity.toUpperCase()}</AlertTitle>
            <br />
            <strong>{info}</strong>
          </Alert>
        </DialogContent>
        <DialogActions>
        <CustomButton
            id="delete-asset-02"
            buttonText={"_cancel"}
            style={{
              margin: 5,
              color: "#254a9a",
              height: 40,
            }}
            className = "btn"
            tooltip={{
              title: getIntlTranslation("tooltip.cancel"),
            }}
            handleClick={(e) => handleCancel()}
          />
          <CustomButton
            id="button-asset-01"
            buttonText={`${buttonText}`}
            className={"g-btn-primary"}
            style={{
              margin: 5,
              backgroundColor: "#d9534f",
              height: 40,
              color: "#ffffff",
            }}
            variant="button"
            tooltip={{
              title: getIntlTranslation("tooltip.save"),
            }}
            handleClick={(e) => handleConfirmation()}
          />
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
