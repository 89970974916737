import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import {
  addNewchapter,
  loadChapters,
  updateChapterOrder,
} from '../../../api/customChaptersApi';
import { getCustomType, resetState } from '../../../common/slice/chaptersSlice';
import { useDispatch, useSelector } from 'react-redux';

import AddChapterFnc from './AddChapterFnc';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Collapse from '@mui/material/Collapse';
import DoubleArrowSharpIcon from '@mui/icons-material/DoubleArrowSharp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import { InsertEmoticonSharp } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RenderSection from './RenderSection';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

export const isOpen = {
  borderLeft: '4px solid #5f9ea0',
  padding: 1,
};

const reorder = {
  borderRadius: 2,
  marginTop: 1,
  boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
  transition: '.8s',
  backgroundColor: 'white',
};

const DocumentMap = ({
  onNodeSelect,
  onChapterSelect,
  onSectionSelect,
  onSubSectionSelect,
  current,
  report,
}) => {
  const [optionMap, setoptionMap] = useState([]);
  const chapter = useSelector((state) => state.tddChapters);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const readOnlystate = useSelector((state) => state.readOnly);
  const [chapterData, setChapterData] = useState(optionMap);
  const [dragStart, setDragStrat] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [displaySaveBtn, setDisplaySaveBtn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [handleActive, setHandleActive] = useState({});
  const [optionClicked, setoptionClicked] = useState({
    chapIdent: '',
    sectIdent: '',
    subSectIdent: '',
    selectedObj: {},
  });
  const [activeChapter, setActiveChapter] = useState({
    index: null,
    value: null,
  });
  const [activeSection, setActiveSection] = useState({
    index: null,
    value: null,
  });
  const [activeSubSection, setActiveSubSection] = useState({
    index: null,
    value: null,
  });
  const dispatch = useDispatch();

  const getChapters = (val) => {
    let payload = {
      type: val,
    };
    dispatch(getCustomType(payload));
  };
  useEffect(() => {
    if (!!chapter.customType) {
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          report: chapter.customType,
        })
      );
      setActiveChapter({ index: null });
    }
  }, [dispatch, scope, chapter.customType]);

  useEffect(() => {
    if (chapter.isDelete.type === 'DELETED') {
      if (!!chapter.isDelete.guruDto) {
        if (current.type === 'sub_section') {
          setHandleActive({
            ident: current.cident,
            parent: current.cident,
            type: current.type,
            selectedObj: '',
          });
        } else {
          setHandleActive({
            ident: current.cident,
            parent: current.cident,
            type: current.type,
            selectedObj: '',
            status: 'delete',
          });
        }
      }
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully Delete', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    if (chapter.isUpdate) {
      setHandleActive({
        ident: current.ident,
        parent: current.cident,
        type: current.type,
        selectedObj: current,
        status: 'update',
      });
      onNodeSelect(current);
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully Updated ', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  }, [chapter, current, dispatch, enqueueSnackbar, openAsset, scope]);

  useEffect(() => {
    if (chapter.addChapter.type === 'SAVED') {
      if (current.type === 'section' || current.type === 'sub_section') {
        setHandleActive({
          ident: current.ident,
          parent: current.cident,
          type: current.type,
          selectedObj: current,
        });
      } else {
        setHandleActive({
          ident: current.ident,
          parent: current.ident,
          type: current.type,
          selectedObj: current,
        });
      }
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully saved', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    return () => {};
  }, [
    chapter.addChapter,
    chapter.customType,
    current,
    dispatch,
    enqueueSnackbar,
    openAsset.ident,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);

  useEffect(() => {
    if (chapter.updateOrderStatus.type === 'UPDATED') {
      setHandleActive({
        ident: chapter.updateOrderStatus.guruDto.ident,
        parent: current.cident,
        type: current.type,
        selectedObj: current,
      });
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully updated', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    return () => {};
  }, [
    chapter.customType,
    chapter.updateOrderStatus,
    current,
    dispatch,
    enqueueSnackbar,
    openAsset.ident,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);

  useEffect(() => {
    if (chapter.addSection.type === 'SAVED') {
      setHandleActive({
        ident: current.ident,
        parent: current.cident,
        type: current.type,
        selectedObj: current,
      });
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully saved', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    return () => {};
  }, [
    chapter.addSection,
    chapter.customType,
    current,
    dispatch,
    enqueueSnackbar,
    openAsset.ident,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);

  useEffect(() => {
    if (chapter.addSubSection.type === 'SAVED') {
      setHandleActive({
        ident: current.ident,
        parent: current.cident,
        type: current.type,
        selectedObj: current,
      });
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully saved', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    return () => {};
  }, [
    chapter.addSubSection,
    chapter.customType,
    current,
    dispatch,
    enqueueSnackbar,
    openAsset.ident,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);

  useEffect(() => {
    if (chapter.deteleStructure.type === 'DELETED') {
      setHandleActive({
        ident: chapter.deteleStructure.guruDto.ident,
        parent: current.cident,
        type: current.type,
        selectedObj: current,
      });
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully deleted', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  }, [
    chapter.customType,
    chapter.deteleStructure,
    current,
    dispatch,
    enqueueSnackbar,
    openAsset.ident,
    scope,
  ]);
  useEffect(() => {
    if (chapter.savingStatus.type === 'SUCCESS') {
      setHandleActive({
        ident: chapter.savingStatus.guruDto.ident,
        parent: current.cident,
        type: current.type,
        selectedObj: current,
      });
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully saved', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  }, [
    chapter.customType,
    chapter.savingStatus,
    current,
    dispatch,
    enqueueSnackbar,
    openAsset.ident,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);

  useEffect(() => {
    if (chapter.updateOrderStatus.type === 'SAVED') {
      setHandleActive({
        ident: current.ident,
        parent: current.cident,
        type: current.type,
        selectedObj: current,
      });
      dispatch(
        loadChapters({
          ident: scope.projectIdent,
          language: scope.projectLanguageCode,
          assetIdent: openAsset.ident,
          report: chapter.customType,
        })
      );
      enqueueSnackbar('successfully reorder', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  }, [
    chapter.customType,
    chapter.updateOrderStatus,
    current,
    dispatch,
    enqueueSnackbar,
    openAsset.ident,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);
  useEffect(() => {
    if (
      chapter.type === 'SUCCESS' &&
      !!chapter.chapterList &&
      chapter.chapterList.length > 0
    ) {
      let cIndex = 0;
      const temp = chapter.chapterList.map((c, index) => {
        if(c.chapter){
          cIndex = cIndex+1;
        }
        return {
          type: c.type,
          nodeId: `${index}`,
          ident: c.ident,
          name: c.node,
          structureEditable: c.structureEditable,
          customizable: c.customizable,
          translation: c.nameTranslation,
          oldOrder: index,
          cIndex: c.chapter ? cIndex : "",
          children: c.children.map((s, sIndex) => {
            return {
              type: s.type,
              nodeId: `${index}_${sIndex}`,
              ident: s.ident,
              isDefault: s.node.startsWith('default_') ? true : false,
              name: s.node,
              cident: c.ident,
              translation: s.nameTranslation,
              customizable: s.customizable,
              children: s.children.map((sub, subIndex) => {
                return {
                  type: sub.type,
                  nodeId: `${index}_${sIndex}_${subIndex}`,
                  ident: sub.ident,
                  isDefault: sub.node.startsWith('default_') ? true : false,
                  name: sub.node,
                  pident: s.ident,
                  cident: c.ident,
                  customizable: sub.customizable,
                  children: [],
                  translation: sub.nameTranslation,
                };
              }),
            };
          }),
        };
      });
      setoptionMap(temp);
      setChapterData(temp);
      if (
        !!temp &&
        temp.length &&
        typeof onNodeSelect === 'function' &&
        !Object.keys(handleActive).length
      ) {
        handleChapterSelection(temp[0]);
      } else if (!!Object.keys(handleActive).length) {
        if (handleActive.type === 'section') {
          if (handleActive.status === 'delete') {
            const data = temp.filter((e) => e.ident === handleActive.parent);
            setActiveChapter({ index: data[0].ident });
            setoptionClicked({
              ...optionClicked,
              chapIdent: data[0].ident,
              selectedObj: data[0],
            });
            setHandleActive({});
            getChapterData(data[0]);
          } else if (handleActive.status === 'update') {
            const data = temp.filter((e) => e.ident === handleActive.parent);
            if (!!data.length) {
              setActiveChapter({ index: data[0].ident });
              setoptionClicked({
                ...optionClicked,
                sectIdent: handleActive.ident,
                selectedObj: handleActive.selectedObj,
              });
              setHandleActive({});
              const sectdata = data[0].children.filter(
                (e) => e.ident === handleActive.ident
              );
              getChapterData(sectdata[0]);
            }
          } else {
            setActiveSection({ index: handleActive.ident });
            setoptionClicked({
              ...optionClicked,
              sectIdent: handleActive.ident,
              selectedObj: handleActive.selectedObj,
            });
            setHandleActive({});
          }
        } else if (handleActive.type === 'sub_section') {
          setoptionClicked({
            ...optionClicked,
            subSectIdent: handleActive.ident,
            selectedObj: handleActive.selectedObj,
          });
          setHandleActive({});
        }
        setHandleActive({});
      }
      dispatch(resetState());
    }
  }, [chapter, dispatch, handleActive, onNodeSelect, optionClicked]);

  const getChapterData = (data) => {
    if (!!data) {
      if (
        !data.children.length ||
        data.type === 'section' ||
        data.type === 'non-chapter'
      ) {
        if (typeof onNodeSelect === 'function') {
          if (data.type === 'chapter' || data.type === 'non-chapter') {
            onNodeSelect(data);
            onChapterSelect(data);
          } else if (data.type === 'section') {
            if (data.children.length > 0) {
              onNodeSelect(data.children[0]);
              onSubSectionSelect(data.children[0]);
              setActiveSubSection({ index: data.children[0].ident });
            } else {
              onSectionSelect(data);
              onNodeSelect(data);
            }
          } else if (data.type === 'sub_section') {
            onNodeSelect(data);
            onSubSectionSelect(data);
          }
        }
      } else if (data.children.length > 0) {
        if (typeof onNodeSelect === 'function') {
          onChapterSelect(data);
          if (data.type === 'chapter') {
            onNodeSelect(data.children[0]);
            const child = data.children[0];
            onSectionSelect(child);
            setActiveSection({ index: child.ident });
            if (
              child.children.length > 0 &&
              child.children[0].type === 'sub_section'
            ) {
              onNodeSelect(child.children[0]);
              setActiveSubSection({ index: child.children[0].ident });
              onSubSectionSelect(child.children[0]);
            }
          }
        }
      }
    }
  };

  const handleChapterSelection = (o) => {
    setoptionClicked({ ...optionClicked, chapIdent: o.ident, selectedObj: o });
    setActiveChapter({
      index: activeChapter.index === o.ident ? null : o.ident,
    });
    getChapterData(o);
  };

  const handleSectionSelection = (o) => {
    setoptionClicked({ ...optionClicked, sectIdent: o.ident, selectedObj: o });

    setActiveSection({
      index: activeSection.index === o.ident ? null : o.ident,
    });
    getChapterData(o);
  };

  const handleSUbSectionSelection = (o) => {
    setoptionClicked({
      ...optionClicked,
      subSectIdent: o.ident,
      selectedObj: o,
    });
    setActiveSubSection({
      index: activeSubSection.index === o.ident ? null : o.ident,
    });
    getChapterData(o);
  };
  useEffect(() => {
    const newData = chapterData.filter((el, index) => el.oldOrder !== index);
    if (newData.length > 0) {
      setDisplaySaveBtn(true);
    } else {
      setDisplaySaveBtn(false);
    }
  }, [chapterData]);

  const handleOnDragEnd = (result) => {
    setDragStrat(false);
    if (!result.destination) return;
    const items = Array.from(chapterData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    handleChapterReorder(items);
  };

  const handleDragStart = (result) => {
    setDragStrat(true);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = (values, { resetForm }) => {
    const order = chapterData.length + 1;
    const projectIdent = scope.projectIdent;
    const data = {
      type: 'custom-chapter',
      sections: values.option.map((el, index) => {
        return {
          type: 'custom-section',
          custom: {
            type: 'custom',
            name: el.optionGerman?.toUpperCase(),
            node: 'section',
            order: index,
            parentIdent: '',
            reportType: chapter.customType, // full or red flag,
            nameTranslation: [
              {
                type: 'title',
                title: el.optionEnglish.toUpperCase(),
                language: 'english', //german,deutsch,english
                value: el.optionGerman.toUpperCase(),
              },
              {
                type: 'title',
                title: el.optionGerman.toUpperCase(),
                language: 'deutsch', //german,deutsch,english
                value: el.optionGerman.toUpperCase(), // always german one
              },
            ],
          },
        };
      }),
      custom: {
        type: 'custom',
        name: values.german.toUpperCase(),
        node: 'chapter',
        order: order,
        reportType: chapter.customType, // FULL REPORT or RED FLAG
        nameTranslation: [
          {
            type: 'title',
            title: values.english.toUpperCase(),
            language: 'english', //german,deutsch,english
            value: values.german.toUpperCase(),
          },
          {
            type: 'title',
            title: values.german.toUpperCase(),
            language: 'deutsch', //german,deutsch,english
            value: values.german.toUpperCase(), // always german one
          },
        ],
      },
    };
    dispatch(addNewchapter(data, projectIdent));
    resetForm();
    handleClose();
  };
  const handleCheckedChange = (event) => {
    setIsChecked(event.target.checked);
    if (!event.target.checked) {
      setChapterData(optionMap);
      setDisplaySaveBtn(false);
    }
  };

  const handleChapterReorder = (items) => {
    const projectIdent = scope.projectIdent;
    const data = items.map((element, index) => {
      const map = {
        type: 'custom',
        node: element?.type,
        name: element.name,
        ident: element.ident,
        order: index,
        reportType: chapter.customType,
      };
      return map;
    });

    dispatch(
      updateChapterOrder(
        {
          type: 'custom-wrapper',
          customs: data,
        },
        projectIdent
      )
    );
  };
  const option = ['FULL REPORT', 'RED FLAG'];
  const constructView = () => {
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          size="small"
          options={option}
          defaultValue="FULL REPORT"
          value={chapter.customType}
          onChange={(event, newValue) => {
            if (!!newValue) {
              getChapters(newValue);
            }
          }}
          style={{ margin: 15 }}
          renderInput={(params) => (
            <TextField {...params} label="Reports Type" />
          )}
        />
        <Box
          sx={{
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <FormControlLabel
            control={<Switch sx={{ m: 1 }} onChange={handleCheckedChange} />}
            label={`${
              !isChecked ? "Reorder is disabled" : "Reorder is enabled"
            }`}
          /> */}
          {displaySaveBtn && (
            <Button
              style={{
                padding: '2px',
                maxHeight: 30,
              }}
              variant="contained"
              onClick={handleChapterReorder}
            >
              <Typography>Save</Typography>
            </Button>
          )}
        </Box>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleDragStart}
        >
          <Droppable droppableId="droppable-id">
            {(provided) => (
              <List
                className="droppable-id"
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  width: '100%',
                  border: dragStart ? '5px solid blue' : '0px',
                  borderStyle: 'dashed',
                }}
              >
                {chapterData.map((o, index) => {
                  let chapterIndex = o.cIndex;
                  const info =
                    o.name === 'PROJECT INFORMATION' ||
                    o.name === 'PROJEKTINFORMATIONEN';
                  return (
                    <Draggable
                      key={o.ident}
                      draggableId={o.ident}
                      index={index}
                      isDragDisabled={readOnlystate.isReadOnly ? true : false}
                    >
                      {(provided) => (
                        <>
                          <ListItemButton
                            selected={activeChapter.index === o.ident}
                            onClick={(e) => handleChapterSelection(o)}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <div
                              style={{ width: 30 }}
                              {...provided.dragHandleProps}
                              key={o.ident}
                            >
                              <DragIndicatorIcon
                                style={{
                                  color: '#d08f8e',
                                  fontSize: 20,
                                }}
                              />
                            </div>
                            {o.type === 'chapter' && (
                              <ListItemIcon>
                                <Box
                                  style={{
                                    color: '#5f9ea0',
                                    marginRight: '2px',
                                  }}
                                >
                                  {current.ident === o.ident && (
                                    <DoubleArrowSharpIcon />
                                  )}
                                </Box>
                                {current.ident !== o.ident && (
                                  <Typography
                                    variant="body1"
                                    style={{
                                      color: '#254a9a',
                                      marginRight: '2px',
                                    }}
                                  >
                                    {chapterIndex}
                                  </Typography>
                                )}
                              </ListItemIcon>
                            )}
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {o.name}
                                </Typography>
                              }
                            />
                            {o.structureEditable &&
                              (activeChapter.index === o.ident ? (
                                <ExpandMore />
                              ) : (
                                <ChevronRightIcon />
                              ))}
                          </ListItemButton>
                          {o.structureEditable && (
                            <Collapse
                              direction="up"
                              in={activeChapter.index === o.ident}
                              mountOnEnter
                              unmountOnExit
                            >
                              <RenderSection
                                o={o}
                                activeSection={activeSection}
                                activeSubSection={activeSubSection}
                                index={chapterIndex}
                                current={current}
                                setActiveSection={setActiveSection}
                                setActiveSubSection={setActiveSubSection}
                                handleSectionSelection={handleSectionSelection}
                                handleSUbSectionSelection={
                                  handleSUbSectionSelection
                                }
                              />
                            </Collapse>
                          )}
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        {chapterData.length > 0 && (
          <AddChapterFnc
            type="Chapter"
            handleSubmit={handleSubmit}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            openDialog={openDialog}
          />
        )}
      </Box>
    );
  };
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
      }}
    >
      {constructView()}
    </Box>
  );
};

export default DocumentMap;
