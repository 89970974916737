import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import SideBar from "./SideBar";
import Footer from "./Footer";
import Content from "./Content";
import Locale from "../../lang/Locale";
import { IntlProvider } from "react-intl";
import ScrollToTop from "../../utils/ScrollToTop";

const Layout = () => {
  const actionState = useSelector((state) => state.containerActions);
  const systemState = useSelector((state) => state.system);
  const [currentLocale, setCurrentLocale] = useState(Locale["de"]);
  useEffect(() => {
    if (!!systemState.language) {
      setCurrentLocale(Locale[systemState.language]);
    }
  }, [systemState.language]);
  return (
    <>
      <IntlProvider
        locale={currentLocale.locale}
        messages={currentLocale.messages}
      >
          <ScrollToTop />
        <div id="app-container" className={actionState.containerClassnames.join(" ")}>
          <Header />
          <div id="g-wrapper">
            <SideBar />
            <div className={actionState.contentClassnames}>
              <Content />
              <Footer />
            </div>
          </div>
        </div>
      </IntlProvider>
    </>
  );
};

export default Layout;
