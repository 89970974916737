import { AES, enc } from "crypto-js";
import sha256 from "crypto-js/sha256";
import { FormattedMessage, injectIntl } from "react-intl";

import IntlMessages from "./IntlMessages";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { scroller } from "react-scroll";
import { store } from "../store";

const { REACT_APP_CRYPTO_KEY } = process.env;

export const createAndDownloadBlobFile = (data, name, extension = "xlsx") => {
  const blob = new Blob([data]);
  const fileName = `${name}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const b64toBlob = (b64Data, sliceSize = 512) => {
  let contentType = "image/jpg";
  if (b64Data.charAt(0) === "/") {
    contentType = "image/jpg";
  } else if (b64Data.charAt(0) === "i") {
    contentType = "image/png";
  }
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return URL.createObjectURL(blob);
};

export const encrypt = (payload) => {
  return AES.encrypt(
    JSON.stringify(payload),
    `${REACT_APP_CRYPTO_KEY}`
  ).toString();
};

export const decrypt = (payload) => {
  const decrypted = AES.decrypt(payload, `${REACT_APP_CRYPTO_KEY}`);
  return decrypted.toString(enc.Utf8);
};

export async function getSuggestion(searchTerm, mentionChar) {
  const state = store.getState();
  const openAsset = state.openAsset;
  const atValues = openAsset.subAssetList.map((t, index) => ({
    ...t,
    short: index,
  }));

  const hashValues = [
    {
      id: 1,
      value:
        "The component is generally in good condition, from the areas that could be inspected.",
    },
    {
      id: 2,
      value:
        "The component is generally in bad condition, from the areas that could be inspected.",
    },
    { id: 3, value: "HANDLUNGS-EMPFEHLUNGEN" },
  ];
  let values;
  if (mentionChar === "@") {
    values = atValues;
  } else {
    values = hashValues;
  }

  return values.filter((p) => p.value.includes(searchTerm));
}

export const removeHTML = (str) => {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

export const getLanguageCode = (str) => {
  if (str.toUpperCase() === "ENGLISH") {
    return "en";
  } else {
    return "de";
  }
};

export function isEmpty(str) {
  return !str || str.length === 0;
}

export function getTextSize(txt, height) {
  const element = document.createElement("canvas");
  const context = element.getContext("2d");
  context.font = "bold 12px arial";
  var tsize = {
    width: context.measureText(txt).width,
    height: parseInt(height),
  };
  return tsize;
}

export function covertInCurrencyFormat(val) {
  return Number(val).toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
}

export const getTextHeight = (t) => {
  if (t >= 250 && t < 450) {
    return 60;
  } else if (t >= 450 && t < 800) {
    return 80;
  } else if (t >= 800 && t < 1400) {
    return 120;
  } else if (t >= 1400 && t < 1800) {
    return 150;
  } else if (t >= 1800 && t < 2400) {
    return 200;
  } else if (t >= 2400 && t < 3000) {
    return 250;
  } else if (t >= 3000 && t < 4000) {
    return 300;
  } else if (t >= 4000) {
    return 350;
  } else {
    return 40;
  }
};

export const getCostBasedOnPriority = (priority, totalCost) => {
  if (priority.value === "langfristig") {
    return {
      shortTermCost: 0,
      mediumTermCost: 0,
      longTermCost: totalCost,
    };
  } else if (priority.value === "mittelfristig") {
    return {
      shortTermCost: 0,
      mediumTermCost: totalCost,
      longTermCost: 0,
    };
  } else if (priority.value === "kurzfristig") {
    return {
      shortTermCost: totalCost,
      mediumTermCost: 0,
      longTermCost: 0,
    };
  } else {
    return {
      shortTermCost: 0,
      mediumTermCost: 0,
      longTermCost: 0,
    };
  }
};

export const getWarningText = (updatedRows, t) => {
  const element = updatedRows.reduce((group, row, index) => {
    const { trade } = row;
    group[trade.value] = group[trade.value] ?? [];
    group[trade.value].push(row);
    return group;
  }, {});
  return (
    <>
      <Typography variant="body1">
        {getIntlTranslation("_unsaved changes")}
      </Typography>
      <List>
        {Object.keys(element).map((el, index) => {
          return (
            <ListItem key={index} style={{ padding: 0 }}>
              <ListItemText
                id={`${index}_${el}`}
                primary={`${index + 1}. ${el}`}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export const getLabel = (value) => {
  return (
    <Typography variant="subtitle2">
      <IntlMessages id={value} />
    </Typography>
  );
};

export const getDialogBoxHeader = (value) => {
  return (
    <Typography variant="body2" style={{ textTransform: "uppercase" }}>
      <IntlMessages id={value} />
    </Typography>
  );
};

export const getButtonLabel = (value) => {
  return (
    <Typography variant="button">
      <IntlMessages id={value} />
    </Typography>
  );
};

export const getOverlineText = (value, style) => {
  return (
    <Typography
      variant="overline"
      className="custom-section-heading"
      style={style}
    >
      <IntlMessages id={value} />
    </Typography>
  );
};

export const getVariantText = (value, variant, style, className) => {
  return (
    <Typography
      variant={!!variant ? variant : "body1"}
      style={style}
      className={className}
    >
      {value}
    </Typography>
  );
};

export const getTranslationVariantText = (value, variant, style, className) => {
  return (
    <Typography
      variant={!!variant ? variant : "body1"}
      style={style}
      className={className}
    >
      <IntlMessages id={value} />
    </Typography>
  );
};

export const getTranslationWithVariable = (
  message,
  variant,
  style,
  className
) => {
  return (
    <Typography
      variant={!!variant ? variant : "body1"}
      style={style}
      className={className}
    >
      <FormattedMessage id={message.id} values={message.values} />
    </Typography>
  );
};

export const getIntlTranslation = (value) => {
  return <IntlMessages id={value} />;
};

export const commentsEditorConfig = {
  toolbar: {
    shouldNotGroupWhenFull: true,
    items: [
      "undo",
      "redo",
      "|",
      "bold",
      "removeFormat",
      "highlight",
      "|",
      "bulletedList",
      "|",
    ],
  },
  extraAllowedContent: "div",
  language: "en",
};

export const minimalEditorConfig = {
  toolbar: {
    shouldNotGroupWhenFull: true,
    items: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "removeFormat",
      "|",
      "todoList",
      "bulletedList",
      "numberedList",
      "alignment",
      "outdent",
      "indent",
      "|",
    ],
  },
  extraAllowedContent: "div",
  language: "en",
};

// removed
// 'highlight','fontSize','blockQuote','insertTable',

export const normalEditorConfig = {
  fontBackgroundColor: {
    colorPicker: false,
  },
  toolbar: {
    shouldNotGroupWhenFull: true,
    items: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "removeFormat",
      "highlight",
      "|",
      "todoList",
      "bulletedList",
      "alignment",
      "outdent",
      "indent",
      "|",
      "superscript",
      "subscript",
      "specialCharacters",
      "|",
      "imageInsert",
      "imageUpload",
      "link",
      "|",
      "findAndReplace",
    ],
  },
  language: "en",
  image: {
    resizeOptions: [
      {
        name: "resizeImage:original",
        value: null,
        icon: "original",
      },
      {
        name: "resizeImage:50",
        value: "50",
        icon: "medium",
      },
      {
        name: "resizeImage:75",
        value: "75",
        icon: "large",
      },
    ],
    toolbar: [
      "imageTextAlternative",
      "toggleImageCaption",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
      "linkImage",
      "resizeImage:50",
      "resizeImage:75",
      "resizeImage:original",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
};

export const splitArray = (array, callback) => {
  const matches = [];
  const nonMatches = [];
  array.forEach((element) =>
    (callback(element) ? matches : nonMatches).push(element)
  );
  return [matches, nonMatches];
};

export const createAddress = (val) => {
  let address = [];
  if (!!val.street) {
    address.push(val.street);
    address.push(", ");
  }
  if (!!val.postalCode) {
    address.push(val.postalCode);
    if (!val.city) {
      address.push(", ");
    }
  }
  if (!!val.city) {
    address.push(" ");
    address.push(val.city);
    address.push(", ");
  }
  if (!!val.state) {
    address.push(val.state);
    address.push(", ");
  }
  if (!!val.country) {
    address.push(val.country);
  }

  return address.join("");
};

const isObject = (val) => typeof val === "object" && val && !Array.isArray(val);

export const compareChanges = (newVal, oldVal) => {
  let changes = {};
  Object.keys(newVal).forEach((key) => {
    if (key in oldVal) {
      if (isObject(oldVal[key]) && isObject(newVal[key])) {
        let temp = compareChanges(newVal[key], oldVal[key]);
        if (Object.keys(temp).length > 0) {
          changes[key] = temp;
        }
      } else {
        if (oldVal[key] !== newVal[key] && !Array.isArray(newVal[key])) {
          changes[key] = newVal[key];
        }
      }
    }
  });
  return changes;
};

export const random_hex_color_code = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
};

export const scrollTo = function (id, containerId) {
  scroller.scrollTo(`${id}`, {
    containerId: containerId,
    horizontal: false,
    isDynamic: true,
    offset: -40,
    smooth: true,
    spy: true,
  });
};

export const emailRegex = /[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+/g;
export const getAllEmailRegex = (text) => {
  let matches = text.matchAll(emailRegex);
  return matches;
};

export const getBackground = (p) => {
  if (p) {
    return "#ff2a5130";
  } else {
    return "#ffffff";
  }
};
export const getBorderColor = (p) => {
  if (p) {
    return "solid 4px #d30038";
  } else {
    return "none";
  }
};

export const sortColumn = (col, filterList) => {
  let temp = filterList;
  if (col.includes("date")) {
    temp = filterList.sort((a, b) => a[col] - b[col]);
  } else {
    temp = filterList.sort((a, b) =>
      String(a[col])?.localeCompare(String(b[col]), "en", { numeric: true })
    );
  }
  return temp;
};

export const randomIdGenerator = (prefix = "random") => {
  return Math.random()
    .toString(36)
    .replace("0.", prefix || "");
};
export const sortEmpytOrNullLast = (temp) => {
  temp.sort(function (a, b) {
    return (a === null) - (b === null) || +(a > b) || -(a < b);
  });

  return temp;
};

export const costStyle = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "space-between",
};
export const getStylingForSelectedComment = (selectedComment, nodeIdent) => {
  return {
    background: selectedComment === nodeIdent ? "#a9a9a924" : "",
    borderRadius: selectedComment === nodeIdent ? "8px" : "",
    border: selectedComment === nodeIdent ? "4px solid #dc3545" : "",
  };
};

export const getMentionFeedItems = (queryText, users) => {
  const userList =
    users.mentionList?.filter(
      (e) => e.emailAddress !== "it.support@taeurope.com"
    ) || [];
  return userList
    ?.filter((item) =>
      item.display.toLowerCase().includes(queryText.toLowerCase())
    )
    .map((item) => ({
      id: `@${item.title}`,
      display: item.display,
      userId: item.userId,
      emailAddress: item.emailAddress,
    }));
};
export const customMentionItemRenderer = (item) => {
  const itemElement = document.createElement("div");
  itemElement.classList.add("custom-item-comments");
  const displayItem = document.createElement("div");
  displayItem.classList.add("custom-item-display");
  displayItem.id = `mention-list-item-id-${item.userId}`;
  displayItem.textContent = `${item.display} `;

  const emailElement = document.createElement("div");
  emailElement.textContent = `${item.emailAddress} `;
  emailElement.classList.add("custom-item-email");
  emailElement.textContent = `(${item.emailAddress})`;
  itemElement.appendChild(displayItem);
  itemElement.appendChild(emailElement);

  return itemElement;
};

export function shortenString(inputString) {
  const hash = sha256(inputString).toString();
  return hash.substring(0, 8);
}

export const getMentions = (editorRef) => {
  const range = editorRef.current.model.createRangeIn(
    editorRef.current.model.document.getRoot()
  );
  const mentions = [];

  //iterate through the whole tree in that range (TreeWalker)
  for (const treeWalker of range.getWalker({ ignoreElementEnd: true })) {
    if (treeWalker.type === "text") {
      //the item property represents TextProxy which is not instance of node
      const node = treeWalker.item.textNode;

      if (node.hasAttribute("mention")) {
        const mention = node.getAttribute("mention");
        if (mention) {
          mentions.push(mention);
        }
      }
    }
  }
  return mentions;
};

export const getFirebaseConfig = () => {
  const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
  const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
  const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
  const appId = process.env.REACT_APP_FIREBASE_APP_ID;
  const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
  return {
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  };
};

export const getNodeTitle = (node, lanuageCode) => {
  const inputObj = node?.labelTranslation.find(
    (e) => e.language === lanuageCode
  );
  return inputObj.title;
};

// Remove duplicates from an array of objects
export const removeDuplicates = (arr) => {
  return Array.from(new Set(arr.map((a) => a.id))).map((id) => {
    return arr.find((a) => a.id === id);
  });
};


