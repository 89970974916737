import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssetOverviewContainer from "./structure/AssetOverviewContainer";
import AssetCreateContainer from "./structure/AssetCreateContainer";
import SubAssetCreateContainer from "./structure/SubAssetCreateContainer";
import SubAssetEditContainer from "./structure/SubAssetEditContainer";
import AssetEditContainer from "./structure/AssetEditContainer";
import { Box} from "@mui/material";
import {
  fetchAssetList,
  deleteAsset,
  getAsset,
  createReportVersion,
  getMonitoringSubAsset,
  deleteMonitoringSubAsset,
} from "../../../../api/assetOverviewApi";
import { resetState } from "../../../component-bundle/asset-overview/assetOverviewSlice";
import { useSnackbar } from "notistack";
import CreateReportVersion from "./structure/CreateReportVersion";
import ReportGenerationDialog from "../report-generation/ReportGenerationDialog"

const AssetOverviewHome = () => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.assetOverview);
  const [openAssetCreate, setOpenAssetCreate] = useState(false);
  const [openCreateSubAsset, setOpenCreateSubAsset] = useState({
    asset: null,
    open: false,
  });
  const [openEditContainer, setOpenEditContainer] = useState({
    open: false,
    asset: null,
  });
  const [openCreateReportVersion, setOpenCreateReportVersion] = useState({
    open: false,
    assetIdent: null,
  });
  const [openSubAssetEditContainer, setOpenSubAssetEditContainer] = useState({
    open: false,
    data: null,
    asset:null,
  });

  const [openReportGenerationDialog,setOpenReportGenerationDialog] = useState({
    open:false,
    data:null,
  })
  const dispatch = useDispatch();
  const [assetList, setAssetList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(
      fetchAssetList({
        api: scope.api,
        projectIdent: scope.projectIdent,
      })
    );
  }, [dispatch, scope.api, scope.projectIdent]);

  useEffect(() => {
    if (state.assetList.type === "FETCHED" && !!state.assetList.data) {
      let assetList = state.assetList.data.map((m) => {
        return {
          ...m,
          address: { ...m.addressDto },
          reportVersion: [...m.reportVersionDtos],
        };
      });
      setAssetList(assetList);
      dispatch(resetState());
    }

    if (state.deleteAsset.type === "DELETED") {
      state.deleteAsset.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      dispatch(
        fetchAssetList({
          api: scope.api,
          projectIdent: scope.projectIdent,
        })
      );
    }
    if (state.deleteSubAsset.type === "DELETED") {
      state.deleteSubAsset.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      dispatch(
        fetchAssetList({
          api: scope.api,
          projectIdent: scope.projectIdent,
        })
      );
    }
    if (state.error.type === "ERROR") {
      state.error.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
    }
    if (state.fetchAsset.type === "FETCHED" && !!state.fetchAsset.data) {
      setOpenEditContainer({
        open: true,
        asset: state.fetchAsset.data,
      });
      dispatch(resetState());
    }
    if (state.fetchSubAsset.type === "FETCHED" && !!state.fetchSubAsset.data) {
      setOpenSubAssetEditContainer({
        open: true,
        data: state.fetchSubAsset.data,
      });
      dispatch(resetState());
    }
    if (state.createReportVersion.type === "SAVED") {
      state.createReportVersion.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      setOpenCreateReportVersion({
        open: false,
        assetIdent: null,
      });
      dispatch(
        fetchAssetList({
          api: scope.api,
          projectIdent: scope.projectIdent,
        })
      );
    }
    if (state.updateReportVersion.type === "UPDATED") {
      state.updateReportVersion.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      dispatch(
        fetchAssetList({
          api: scope.api,
          projectIdent: scope.projectIdent,
        })
      );
    }
  }, [dispatch, 
    state.assetList, 
    state.deleteAsset, 
    enqueueSnackbar, 
    scope.api, 
    scope.projectIdent, 
    state.error, 
    state.fetchAsset, 
    state.createReportVersion,
     state.deleteSubAsset,
     state.fetchSubAsset,state.updateReportVersion]);

  const fetchAssets = () => {
    dispatch(
      fetchAssetList({
        api: scope.api,
        projectIdent: scope.projectIdent,
      })
    );
  };

  return (
    <Box style={{ height: "100%" }}>
      <AssetOverviewContainer
        assetList={assetList}
        openCreateAssetDialog={() => setOpenAssetCreate(true)}
        onEdit={(asset) =>
          dispatch(
            getAsset({
              api: scope.api,
              projectIdent: scope.projectIdent,
              assetIdent: asset.assetIdent,
            })
          )
        }
        onDelete={(asset) =>
          dispatch(
            deleteAsset({
              api: scope.api,
              projectIdent: scope.projectIdent,
              assetIdent: asset.assetIdent,
            })
          )
        }
        openReportVersion={(val) => setOpenCreateReportVersion(val)}
        onCreateSubAsset={(val) =>
          setOpenCreateSubAsset({
            asset: val,
            open: true,
          })
        }
        onSubAssetEdit={(val) => 
           dispatch(
            getMonitoringSubAsset({
              api: scope.api,
              projectIdent: scope.projectIdent,
              ident: val.subAssetIdent,
            })
          )
        }
        onSubAssetDelete={(val) =>  dispatch(
          deleteMonitoringSubAsset({
            api: scope.api,
            projectIdent: scope.projectIdent,
            ident: val.subAssetIdent,
          })
        )}
        onOpenReportGeneration = {(val) => {
          setOpenReportGenerationDialog({
            open:true,
            data:val,
          })
        }} 
      />
      {openAssetCreate && (
        <AssetCreateContainer
          open={openAssetCreate}
          onClose={() => setOpenAssetCreate((prev) => !prev)}
          onCreate={() => fetchAssets()}
        />
      )}
      {openCreateSubAsset.open && (
        <SubAssetCreateContainer
          open={openCreateSubAsset.open}
          asset={openCreateSubAsset.asset}
          onClose={() => setOpenCreateSubAsset({ open: false, asset: null })}
          onCreate={() => fetchAssets()}
        />
      )}
      {openSubAssetEditContainer.open && (
        <SubAssetEditContainer
          open={openSubAssetEditContainer.open}
          data={openSubAssetEditContainer.data}
          asset={openSubAssetEditContainer.asset}
          onClose={() => setOpenSubAssetEditContainer({ open: false, subAsset: null })}
          onUpdate={() => fetchAssets()}
        />
      )}
      {openEditContainer.open && (
        <AssetEditContainer
          open={openEditContainer.open}
          asset={openEditContainer.asset}
          onClose={() => setOpenEditContainer({ open: false, asset: null })}
          onUpdate={() => fetchAssets()}
        />
      )}

      {openCreateReportVersion && (
        <CreateReportVersion
          open={openCreateReportVersion.open}
          buttonText="_create"
          option="create"
          assetIdent={openCreateReportVersion.assetIdent}
          onClose={() =>
            setOpenCreateReportVersion({
              open: false,
              assetIdent: null,
            })
          }
          handleSave={(val) =>
            dispatch(
              createReportVersion({
                api: scope.api,
                projectIdent: scope.projectIdent,
                data: val,
              })
            )
          }
        />
      )}

      {openReportGenerationDialog && (
        <ReportGenerationDialog 
        open={openReportGenerationDialog.open} 
        data = {openReportGenerationDialog.data}  
        onClose={() =>
          setOpenReportGenerationDialog({
            open: false,
            data: null,
          })
        }/>
      )}
    </Box>
  );
};
export default AssetOverviewHome;
