import { useEffect} from "react";
import { database } from "../../../../../../index";
import {
  ref,
  set,
  get,
  remove,
  query,
  orderByChild,
  equalTo,
  update,
  serverTimestamp,
} from "firebase/database";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { getCurrentUser } from "../../../../../../authentication/authTokenService";

const useSaveContentFireBase = (item) => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);

  useEffect(() => {
    if (Object.keys(item).length > 0) {
      const nodePath = `${item.apiParam}-${item.data.ident}/`;
      const path = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${item.reportType}/data-nodes/${nodePath}`;
      const nodeRef = ref(database, path);
      syncData(item.data.properties, path, openAsset.ident);
    }
  }, [item]);

  function updateIfOutdated(storedTimestamp) {
    const now = Date.now();
    return now > storedTimestamp;
  }

  const syncData = (properties, path, assetIdent) => {
    try {
      properties.forEach(async (property) => {
        const canPerformAction = property.userDefined ?? true;
        if (canPerformAction) {
          const ident = property.ident || property.nodeIdent;
          const itemRef = ref(database, `${path}/${ident}`);
          const snapshot = await get(itemRef);
          if (snapshot.exists()) {
            const firebaseData = snapshot.val();
            if (
              !firebaseData.timestamp ||
              firebaseData.timestamp < item.data.timestamp
            ) {
              set(itemRef, {
                ...property,
                timestamp: serverTimestamp(),
                assetIdent: assetIdent,
              });
            }
          } else {
            set(itemRef, {
              ...property,
              timestamp: serverTimestamp(),
              assetIdent: assetIdent,
            });
          }
        }
      });
    } catch (error) {
      console.error("Error updating node: ", error);
    }
  };

  const onEdit = async (type, ident, reportType, isEditing) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const userData = getCurrentUser();
      let loggedIn = {};
      if (userData.data.uid === user.uid) {
        loggedIn = {
          email: userData.data.email,
          avatar: userData.data.avatar,
          displayName: userData.data.name,
          id: user.uid,
        };
      }

      const nodePath = `${type}-${ident}`;
      const path = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/locked-nodes/${nodePath}`;
      const nodeRef = ref(database, path);

      try {
        const snapshot = await get(nodeRef);
        if (snapshot.exists()) {
          console.log("Path exists. Updating data...");
          await update(nodeRef, {
            isLocked: isEditing,
            lockedAt: serverTimestamp(),
            lockedBy: loggedIn,
            lockedId: `${type}-${ident}`,
          });
        } else {
          // Path does not exist, set initial data
          console.log(
            "Path does not exist. Creating new path and setting data..."
          );
          await set(nodeRef, {
            isLocked: isEditing,
            lockedAt: serverTimestamp(),
            lockedBy: loggedIn,
            lockedId: `${type}-${ident}`,
          });
        }
      } catch (error) {
        console.error("Error updating node: ", error);
      }
    } else {
      console.error("Error updating node, Authentication Error");
    }
  };

  const onSave = async (type, node, reportType) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const nodePath = `${type}-${node.ident}/`;
      const path = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/data-nodes/${nodePath}`;
      const nodeRef = ref(database, path);
      try {
        node.properties.forEach((property) => {
          const ident = property.ident || property.nodeIdent;
          const itemRef = ref(database, `${path}/${ident}`);
          get(itemRef).then((snapshot) => {
            set(itemRef, {
              ...property,
              timestamp: serverTimestamp(),
              assetIdent: openAsset.ident,
            });
          });
        });
      } catch (error) {
        console.error("Error updating node: ", error);
      }
    } else {
      console.error("Error updating node, Authentication Error");
    }
  };

  const releaseUserLocks = async (projectIdent, assetIdent, reportType) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const path = `projects/${projectIdent}/assets/${assetIdent}/reports/${reportType}/locked-nodes/`;
      const lockedRef = ref(database, path);
      try {
        const snapshot = await get(lockedRef);
        if (snapshot.exists()) {
          const locks = { ...snapshot.val() };
          for (const key in locks) {
            if (locks[key].lockedBy.id === user.uid) {
              locks[key].isLocked = false;
            }
          }
          await set(lockedRef, locks);
        }
      } catch (error) {
        console.error("Error updating node: ", error);
      }
    } else {
      console.error("Error updating node, Authentication Error");
    }
  };

  return { onSave, onEdit, releaseUserLocks };
};

export default useSaveContentFireBase;
