import React from 'react';
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import BuildingProfileHome from './BuildingProfileHome.js';

const BuildingProfileRouteConfig = {
	routes: [
    {
      path: `${tddRoot}/project/building-profile`,
      exact: true,
      name: <IntlMessages id={"menu.building-profile"}/>,
      element: <BuildingProfileHome/>,
      auth: [],
    },
    ]
};


export default BuildingProfileRouteConfig;
