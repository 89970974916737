import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import { authHeader,getCurrentUser } from "../authentication/authTokenService";
import {
  getUserProfileData,
  getUserUpdateFailure,
  getUserUpdateSuccess,
  getOffices,
  getOfficesFailure
} from "../user-profile/userProfileSlice";

import { getErrorFailure } from "../error/errorHandlingSlice";

export const getUserProfile = () => async (dispatch) => {
  try {
    const user = getCurrentUser();
    const response = await trackPromise(
      axios.get(`/user/${user.data.uid}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(),
        },
      })
    );
    dispatch(getUserProfileData(response.data));
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const updateUserData = (user) => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/user/updateUserProfile`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(getCurrentUser().token),
        },
        data: {
          type: "userDto",
          userImage: "",
          userName: user.userName,
          firstName: user.firstName,
          lastName: user.lastName,
          position:user.position,
          emailAddress: user.emailAddress,
          mobile: user.mobile,
          taOfficeDto:user.taOfficeDto,
          language: user.language.value,
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getUserUpdateFailure(response.data));
    } else {
      dispatch(getUserUpdateSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getTAEuropeOffices = () => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.get(`/user/offices`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authHeader(),
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getOfficesFailure(response.data));
    } else {
      dispatch(getOffices(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
}

