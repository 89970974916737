import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../error/errorHandlingSlice";
import { createAndDownloadBlobFile } from "../utils/helper";
import { renewAccessTokenIfGoingExpire } from "./authApi";

export const getHandbook = (lang) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/user/fetch-user-handbook/${lang}`,
        responseType: 'arraybuffer',
        headers: {
          Authorization: tokenHeader,
          Accept: "application/pdf",
        },
      })
    );
    if (!!response) {
    createAndDownloadBlobFile(response.data,"Handbook","pdf")
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};
