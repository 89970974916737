import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasChildren: false,
  hideSidebar:false,
  containerClassnames: ["menu"],
  contentClassnames: "content",
  menuChildrenHiddenBreakpoint:1440,
  mainMenuHiddenBreakpoint:768,
  menuClickCount: 0,
  userSelection:{
    parentNodeId:null,
    hasChildren: false,
    childNodeId:null,
  }
};

const containerActionSlice = createSlice({
  name: "containerAction",
  initialState,
  reducers: {
    setParentHasChildren: (state, { payload }) => ({
      ...state,
      hasChildren: payload,
    }),
   addNewClassToContainer: (state, { payload }) => ({
    ...state,
    containerClassnames: payload,
  }),
  setHideSidebarFlag: (state, { payload }) => ({
    ...state,
    hideSidebar: payload,
  }),
  setContentClass:(state, { payload }) => ({
    ...state,
    contentClassnames: payload,
  }),
  setUserSelection: (state, { payload }) => ({
    ...state,
    userSelection:payload
  }),
  resetState: (state, { payload }) =>({
    ...initialState
  }),
  },
});

// eslint-disable-next-line max-len
export const {
  resetState,
    setParentHasChildren,setDefaultClassName, setContentClass,setClassNameToContainer, addNewClassToContainer ,setHideSidebarFlag,setUserSelection
} = containerActionSlice.actions;

// A selector
export const containerActionSliceSelector = (state) => state.containerActions;

// The reducer
export default containerActionSlice.reducer;
