import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
  Slide,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material//Close";
import CustomButton from "../../../../components/buttons/CustomButton";
import CostSuggestionContainer from "../../../component-bundle/cost-suggestions/CostSuggestionContainer";
import { getDialogBoxHeader, getIntlTranslation } from "../../../../utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const CostSuggestionDialog = (props) => {

const [selectedItem, setSelectedItem] = useState({});

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.onClose();
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("_cost-suggestion")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => props.onClose()}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent className={"custom-scrollBar p-0"} 
      dividers style={{
        overflowX: "hidden",
        overflowY: "hidden",
        height:"100%",
        padding:5
      }}>
        <CostSuggestionContainer selectedItem={selectedItem} onSelect={(selected) =>
                setSelectedItem((prev) => {
                  if (prev?.ident === selected.ident) {
                    return null;
                  } else {
                    return selected;
                  }
                })
              } />
      </DialogContent>
      <DialogActions style={{height:40}}>
        {!!selectedItem && (
          <CustomButton
            id="save_cost_suggestion"
            buttonText={"_save"}
            className="g-btn-primary"
            style={{
              margin: 5,
              color: "#254a9a",
              height: 30,
            }}
            tooltip={{
              title: getIntlTranslation("tooltip.copy"),
            }}
            handleClick={(e) => props.onCopy(selectedItem)}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};



export default CostSuggestionDialog;
