import { createContext, useContext, useEffect, useState } from "react";
import useAssetListHook from "../../../component-bundle/hooks/useAssetListHook";
import { useDispatch, useSelector } from "react-redux";
import {
  autoSaveReportText,
  loadChapterData,
  loadSectionData,
  saveReportText,
} from "../../api/chaptersApi";
import useChapterHook from "./hooks/useChapterHook";
import { tddRoot } from "../../../../utils/defaultValues";
import { useNavigate } from "react-router-dom";

export const ReportStructureContext = createContext();

export const useReportStructureContextHook = () => {
  const context = useContext(ReportStructureContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};


const appendPathToNodes = (
  node,
  parent = null,
  parentPath = "",
  order = 0
) => {
  let newNode = { ...node };
  let currentPath = "";
  if (!node.nodeType.includes("data-")) {
    currentPath = `${parentPath ? parentPath + "/" : ""}${node.ident}`;
  }

  if (node.nodeType.includes("data-")) {
    newNode = {
      ...newNode,
      node: { ...newNode.node, path: parentPath, order: order , timestamp: Date.now()},
    };
  }

  if (node.children && node.children.length > 0) {
    newNode.children = node.children.map((child, index) =>
      appendPathToNodes(child, node, currentPath, index + 1)
    );
  }

  return newNode;
};
export const ReportStructureContextProvider = ({ report, children }) => {
  const [assets, setAssetList] = useState([]);
  const [currentChapter, setCurrentChapter] = useState({});
  const [selectedIndex, setSelectedIndex] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [reportType, setReportType] = useState(report);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const { assetList, fetchList } = useAssetListHook();
  const dataSlice = useSelector((state) => state.dataSlice);
  const navigate = useNavigate();
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [loading, setLoading] = useState(true);
  const [documentItems, setDocumentItems] = useState({
    node: "document",
    nodeType: "document-root",
    children: [],
  });
  const [documentTree, setDocumentTree] = useState([]);
  const { chapterList, onLoadChapters,currentChapterDatas, onLoadChapterData } = useChapterHook({
    reportType: reportType,
  });

  const getPath = () => reportType ==="redflag" ? "red-flag" :"full-report"

  useEffect(() => {
    if(assetList.isFetchSuccess && assetList.assets.length > 0){
      setAssetList(assetList.assets);
    } else if(assetList.isFetchSuccess && assetList.assets.length <= 0) {
      setAssetList([]);
     }
      else{
      fetchList();
     }
  }, [assetList]);

  useEffect(() => {
    if (!!openAsset && !!openAsset.ident) {
      setLoading(true);
      onLoadChapters(reportType);
      setSelectedItem({});
      setDocumentItems((prev) => ({
        ...prev,
        assetIdent:openAsset.ident,
        children: [],
      }));
    }
  }, [
    openAsset.ident,
    reportType,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);

  useEffect(() => {
    if (chapterList.length > 0) {
      let chapterIndex = 0;
      const headers = chapterList
        .map((m) => m.reportDataNode)
        .map((c) => {
          if (c.header) {
            chapterIndex = chapterIndex + 1;
            c = { ...c, index: chapterIndex };
          } else {
            c = { ...c, index: null };
          }
          return { ...c, id: c.ident };
        });
      setDocumentTree(headers);
      setLoading(false);
    }
  }, [chapterList]);


  useEffect(() => {
    if (!!selectedItem.ident) {
      setLoading(true);
      onLoadChapterData(selectedItem, reportType);
     
    }
  }, [selectedItem, reportType]);

  useEffect(() => {
    if (!!currentChapterDatas && 'reportDataNode' in currentChapterDatas) {
      const header = appendPathToNodes(currentChapterDatas?.reportDataNode);
      setDocumentItems((prev) => ({
        ...prev,
        children: [{...header,selectedIndex:selectedItem.selectedIndex}],
      }));
    }
    setLoading(false);
  }, [currentChapterDatas]);

  const onChapterSelection = (chapter) => {
    setCurrentChapter(chapter);
  };

  const onSelectedIndex = (selectedIndex) => {
    setSelectedIndex((prev) => (prev !== selectedIndex ? selectedIndex : prev));
  };

  const onSelectedItem = (selected) => {
    setSelectedItem(selected);
  };

  const getUpdatedRows = () => dataSlice?.data?.filter((f) => f.updated) || [];

  const openReviewMode = () => {
    navigate(
      {
        pathname: `${tddRoot}/project/review/${getPath()}`,
      },
      {
        state: { api: reportType,fallback: `${tddRoot}/project/${getPath()}` },
      },
    );
  }

  return (
    <ReportStructureContext.Provider
      value={{
        openAsset,
        assets,
        reportType,
        currentChapter,
        selectedIndex,
        selectedItem,
        fullScreenMode,
        showComments,
        documentTree,
        documentItems,
        loading,
        setLoading,
        onSelectedItem,
        onChapterSelection,
        onSelectedIndex,
        getUpdatedRows,
        openReviewMode,
        setFullScreenMode,
        setShowComments,
      }}
    >
      {children}
    </ReportStructureContext.Provider>
  );
};
