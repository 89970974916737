import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import {renewAccessTokenIfGoingExpire} from '../../../api/authApi'
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {getFailure , getSuccess } from "../common/slice/ImageUploadSlice"

export const saveImage = (image) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/${image.api}/upload/${image.projectIdent}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: image.formData,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const updateImage = (image) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/${image.api}/update-image/${image.projectIdent}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: image.formData,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const removeImage = (image) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/${image.api}/delete-image/${image.projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: image.formData,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
