import React from 'react';
import { tddRoot } from '../../../../utils/defaultValues';
import IntlMessages from '../../../../utils/IntlMessages';
import AssetOverviewHome from './AssetOverviewHome';

const AssetOverviewRouteConfig = {
  routes: [
    {
      path: `${tddRoot}/project/assets-overview`,
      exact: true,
      name: <IntlMessages id={'menu.asset-overview'} />,
      element: <AssetOverviewHome />,
      auth: [],
    },
  ],
};

export default AssetOverviewRouteConfig;
