import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  TypeHeaderTreeRender,
  TypeHeadersRenderSettings,
} from "../Items/TypeHeaders";
import {
  TypeRatingsDropRender,
  TypeRatingsSettings,
  TypeRatingsTreeRender,
} from "../Items/TypeRatings";
import {
  TypeImagesTreeRender,
  TypeImagesSettings,
  TypeImagesDropRender,
} from "../Items/TypeImages";
import { syncDocumentState } from "../documentBuilderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  TypeLabelTextEditorDropRender,
  TypeLabelTextEditorSettings,
  TypeLabelTextEditorTreeRender,
} from "../Items/TypeLabelTextEditor";
import {
  TypeContactCardSettings,
  TypeContactCardTreeRender,
  TypeContactCardsDropRender,
} from "../Items/TypeContactCard";
import {
  TypeParagraphTreeRender,
  TypeParagraphSettings,
  TypeParagraphDropRender,
} from "../Items/TypeParagraph";
import {
  TypeAddressDropRender,
  TypeAddressRenderSettings,
  TypeAddressTreeRender,
} from "../Items/TypeAddress";
import { TypeTwoColumnDropRender, TypeTwoColumnSettings, TypeTwoColumnTreeRender } from "../Items/TypeTwoColumn";

const documentRoot = {
  label: "Document",
  key: "document-root",
  nodeType: "document-root",
  depth: 0,
  settings: {
    title: "Document",
    defaultTitle: "Document",
    translation: {
      en: "",
      de: "",
    },
  },
  id: uuidv4(),
  children: [],
  render: (item) => {
    return <TypeHeaderTreeRender item={item} />;
  },
  renderSettings: (item) => {
    return <TypeHeadersRenderSettings item={item} />;
  },
};

const header = {
  label: "Header",
  key: "header-chapter",
  nodeType: "header-chapter",
  depth: 1,
  settings: {
    title: "Header",
    defaultTitle: "Header",
    translation: {
      en: "",
      de: "",
    },
  },
  children: [],
  render: (item) => {
    return <TypeHeaderTreeRender item={item} />;
  },
  renderSettings: (item) => {
    return <TypeHeadersRenderSettings item={item} />;
  },
};

const subHeader = {
  label: "Sub Header",
  key: "sub-header",
  nodeType: "sub-header",
  depth: 2,
  settings: {
    title: "Sub Header",
    defaultTitle: "Sub Header",
    translation: {
      en: "",
      de: "",
    },
  },
  children: [],
  render: (item) => {
    return <TypeHeaderTreeRender item={item} />;
  },
  renderSettings: (item) => {
    return <TypeHeadersRenderSettings item={item} />;
  },
};

const dragIconOptions = [
  {
    label: "Rating",
    nodeType: "data-ratings",
    key: "4_rating",
    order: 4,
    render: (item) => {
      return <TypeRatingsTreeRender item={item} />;
    },
    renderSettings: (item) => {
      return <TypeRatingsSettings item={item} />;
    },
    renderDrop: (item) => {
      return <TypeRatingsDropRender item={item} />;
    },
    settings: {
      title: "Rating",
      defaultTitle: "Rating",
      translation: {
        en: "",
        de: "",
        value: "",
      },
      level: 5,
    },
  },
  {
    label: "Images",
    nodeType: "data-images",
    key: "5_images",
    order: 5,
    render: (item) => {
      return <TypeImagesTreeRender item={item} />;
    },
    renderSettings: (item) => {
      return <TypeImagesSettings item={item} />;
    },
    renderDrop: (item) => {
      return <TypeImagesDropRender item={item} />;
    },
    settings: {
      title: "Images",
      defaultTitle: "Images",
      translation: {
        en: "",
        de: "",
        value: "",
      },
      isSourceRequired: true,
      numberofImages: 2,
    },
  },
  {
    label: "Paragraph",
    nodeType: "data-paragraph",
    key: "6_paragraph",
    order: 6,
    render: (item) => {
      return <TypeParagraphTreeRender item={item} />;
    },
    renderSettings: (item) => {
      return <TypeParagraphSettings item={item} />;
    },
    renderDrop: (item) => {
      return <TypeParagraphDropRender item={item} />;
    },
    settings: {
      title: "Paragraph",
      defaultTitle: "Paragraph",
      userEditable: true,
      sourceRequired: true,
    },
    content:{
      defaultText:"",
    }
  },
  {
    label: "Address",
    nodeType: "data-address",
    key: "7_address",
    order: 7,
    render: (item) => {
      return <TypeAddressTreeRender item={item} />;
    },
    renderSettings: (item) => {
      return <TypeAddressRenderSettings item={item} />;
    },
    renderDrop: (item) => {
      return <TypeAddressDropRender item={item} />;
    },
    settings: {
      title: "Address",
      defaultTitle: "Address",
      translation: {
        en: "",
        de: "",
        value: "",
      },
      userEditable: true,
    },
    content:{
        street: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
      },
  },
  {
    label: "Contact-Cards",
    nodeType: "data-contact",
    key: "8_contact_cards",
    order: 8,
    render: (item) => {
      return <TypeContactCardTreeRender item={item} />;
    },
    renderSettings: (item) => {
      return <TypeContactCardSettings item={item} />;
    },
    renderDrop: (item) => {
      return <TypeContactCardsDropRender item={item} />;
    },
    settings: {
      title: "Contact",
      defaultTitle: "Contact",
      translation: {
        en: "",
        de: "",
        value: "",
      },
      numberOfContacts: 4,
    },
  },
  {
    label: "Label & Text-Editor",
    nodeType: "data-label-text-edtior",
    key: "9_text-editor",
    order: 9,
    render: (item) => {
      return <TypeLabelTextEditorTreeRender item={item} />;
    },
    renderSettings: (item) => {
      return <TypeLabelTextEditorSettings item={item} />;
    },
    renderDrop: (item) => {
      return <TypeLabelTextEditorDropRender item={item} />;
    },
    settings: {
      title: "Label & Text-Editor",
      defaultTitle: "Label & Text-Editor",
      translation: {
        en: "",
        de: "",
        value: "",
      },
      userEditable: true,
    },
    content:{
      defaultText:"",
    }
  },
  {
    label: "Two Column",
    nodeType: "data-two-column",
    key: "10_two-columr",
    order: 10,
    render: (item) => {
      return <TypeTwoColumnTreeRender item={item} />;
    },
    renderSettings: (item) => {
      return <TypeTwoColumnSettings item={item} />;
    },
    renderDrop: (item) => {
      return <TypeTwoColumnDropRender item={item} />;
    },
    settings: {
      title: "Two Column",
      defaultTitle: "Two Column",
      translation: {
        en: "",
        de: "",
        value: "",
      },
      userEditable: true,
    },
    content:{
      columns:[],
    }
  },
];

export const DocumentBuilderContext = createContext();
export const useDocumentContextHook = () => {
  const context = useContext(DocumentBuilderContext);
  if (!context) {
    throw new Error("useFormBuilder must be used within a FormBuilderProvider");
  }
  return context;
};

export const DocumentContextProvider = ({ children }) => {
  const [dragItems, setDragItems] = useState(dragIconOptions);
  const [selectedItem, setSelectedItem] = useState({ ...documentRoot });
  const [editItem, setEditItem] = useState({});
  const [documentItems, setDocumentItems] = useState({ ...documentRoot });
  const documentBuilder = useSelector((state) => state.documentBuilder);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!!documentBuilder.documentItems){
      setDocumentItems({...documentBuilder.documentItems});
    }

  }, [])

  useEffect(() => {
    dispatch(syncDocumentState(documentItems));
  }, [documentItems]);

  const handleDragContainerDrop = (event) => {
    const { active, over } = event;
    const uniqueId = uuidv4();
    if (active) {
      const item = dragItems.find((item) => item.key === active.id);
      let draggedItem = { ...item, id: uniqueId };
      if (!!selectedItem) {
        let parentNode = null;
        if (selectedItem.nodeType === "document-root") {
          parentNode = { ...selectedItem, children: [] }
          setDocumentItems((prev) => ({
            ...prev,
            children: [
              ...prev.children,
              {
                ...draggedItem,
                depth: selectedItem.depth + 1,
                parentId: prev.id,
                parentNode: parentNode,
              },
            ],
          }));
        } else {
          let temp = documentItems.children.map((header) => {
            if (header.id === selectedItem.id) {
              parentNode ={ ...header, children: [] }
              header = {
                ...header,
                children: [
                  ...header.children,
                  {
                    ...draggedItem,
                    depth: header.depth + 1,
                    parentId: header.id,
                    parentNode: parentNode,
                  },
                ],
              };
            } else if (header.id === selectedItem.parentNode.id) {
              header = modifyItem(header, selectedItem.id, draggedItem, "ADD");
              parentNode = { ...header, children: [] };
            }
            return header;
          });
          setDocumentItems((prev) => ({ ...prev, children: temp }));
        }
        setSelectedItem((prev) => ({
          ...prev,
          children: [
            ...prev.children,
            {
              ...draggedItem,
              depth: selectedItem.depth + 1,
              parentId: prev.id,
              parentNode:parentNode,
            },
          ],
        }));
      }
    }
  };

  function deepCopy(obj, parentNode) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    const copiedObject = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (key === "id") {
          copiedObject[key] = uuidv4();
        } else if (key === "title") {
          copiedObject[key] = `${obj.defaultTitle}`;
        } else if(key === "parentNode"){
          copiedObject[key] = {...parentNode,children: []}
        }
         else {
          copiedObject[key] = deepCopy(value, parentNode);
        }
      }
    }

    return copiedObject;
  }

  function modifyItem(header, itemId, modifiedData, action, modifiedArr=[]) {
    const findAndModifyItem = (currentArray) => {
      return currentArray.map((item) => {
        if (item.id === itemId) {
          if (action === "SAVE") {
            let temp = { ...item, settings: modifiedData };
            if (!!selectedItem && selectedItem.id === item.id) {
              setSelectedItem(temp);
            }
            return temp;
          } else if (action === "SAVE-CONTENT"){
            let temp = { ...item, content: modifiedData };
            if (!!selectedItem && selectedItem.id === item.id) {
              setSelectedItem(temp);
            }
            return temp;
          }
           else if (action === "COPY") {
            let temp = {
              ...item,
              children: [
                ...item.children,
                {
                  ...modifiedData,
                  parentNode: { ...header, children: [] },
                },
              ],
            };
            return temp;
          } else if (action === "DELETE") {
            return null;
          } else if (action === "ADD SUBHEADER") {
            const uniqueId = uuidv4();
            let depth = item.depth + 1;
            let tempObj = {
              ...subHeader,
              key: `${subHeader.key}-${depth}`,
              id: uniqueId,
              depth: depth,
              parentId: item.id,
              parentNode: { ...header, children: [] },
            };
            let temp = {
              ...item,
              children: [...item.children, { ...tempObj }],
            };
            return temp;
          } else if (action === "ADD") {
            let temp = {
              ...item,
              children: [
                ...item.children,
                {
                  ...modifiedData,
                  parentId: item.id,
                  parentNode: { ...header, children: [] },
                },
              ],
            };
            return temp;
          }
          else if (action === "REORDER") {
            return ({
              ...item,
              children: [
                ...modifiedArr,
              ]
          })
        }
        } else if (Array.isArray(item.children)) {
          const modifiedChildren = findAndModifyItem(item.children);
          return { ...item, children: modifiedChildren.filter(Boolean) };
        } else {
          return item;
        }
      });
    };

    const modifiedChildren = findAndModifyItem(header.children);
    const modifiedParent = {
      ...header,
      children: modifiedChildren.filter(Boolean),
    };
    return modifiedParent;
  }

  const saveContent = (item, content) => {
    if (item.nodeType === "document-root") {
      if (!!selectedItem && selectedItem.id === item.id) {
        setSelectedItem((prev) => ({ ...prev, content: content }));
      }
      setDocumentItems((prev) => ({ ...prev, content: content }));
    } else {
      let temp = documentItems.children.map((header) => {
        if (header.id === item.id) {
          header = { ...header, content: content };
          if (!!selectedItem && selectedItem.id === item.id) {
            setSelectedItem(header);
          }
        } else if (header.id === item.parentNode.id) {
          header = modifyItem(header, item.id, content, "SAVE-CONTENT");
        }
        return header;
      });
      setDocumentItems((prev) => ({ ...prev, children: temp }));
    }
  };

  const saveSettings = (item, settings) => {
    if (item.nodeType === "document-root") {
      if (!!selectedItem && selectedItem.id === item.id) {
        setSelectedItem((prev) => ({ ...prev, settings: settings }));
      }
      setDocumentItems((prev) => ({ ...prev, settings: settings }));
    } else {
      let temp = documentItems.children.map((header) => {
        if (header.id === item.id) {
          header = { ...header, settings: settings };
          if (!!selectedItem && selectedItem.id === item.id) {
            setSelectedItem(header);
          }
        } else if (header.id === item.parentNode.id) {
          header = modifyItem(header, item.id, settings, "SAVE");
        }
        return header;
      });
      setDocumentItems((prev) => ({ ...prev, children: temp }));
    }
  };

  const addHeader = () => {
    const uniqueId = uuidv4();
    setDocumentItems((prevItem) => {
      return {
        ...prevItem,
        children: [
          ...prevItem.children,
          {
            ...header,
            id: uniqueId,
            parentId: prevItem.id,
            parentNode: { ...prevItem, children: [] },
          },
        ],
      };
    });
  };

  const copyItem = (item) => {
    let found = documentItems.children.find((header) => header.id === item.id);
    if (!!found && item.depth === 1) {
      let header = {...item, id:uuidv4(), children: []};
      let copiedObject = deepCopy(item,header);
      let temp = [...documentItems.children, { ...header, children:copiedObject.children,parentNode:item.parentNode}];
      setDocumentItems((prev) => ({ ...prev, children: temp }));
    } else {
      let parent = documentItems.children.find(
        (header) => header.id === item.parentNode.id
      );
      let copiedObject = deepCopy(item,{...parent});
      if (item.parentId === item.parentNode.id) {
        parent = {
          ...parent,
          children: [...parent.children, { ...copiedObject,parentNode:item.parentNode }],
        };
      } else {
        parent = modifyItem(parent, item.parentId, {...copiedObject,parentNode:item.parentNode}, "COPY");
      }
      let temp = documentItems.children.map((m) => {
        if (m.id === parent.id) {
          m = parent;
        }
        return m;
      });
      setDocumentItems((prev) => ({ ...prev, children: temp }));
    }
  };

  const createSubHeader = (item) => {
    let temp = documentItems.children.map((header) => {
      if (header.id === item.id) {
        const uniqueId = uuidv4();
        let depth = header.depth + 1;
        let tempObj = {
          ...subHeader,
          key: `${subHeader.key}-${depth}`,
          id: uniqueId,
          depth: depth,
          parentId: header.id,
          parentNode: { ...header, children: [] },
        };
        header = { ...header, children: [...header.children, { ...tempObj }] };
      } else if (header.id === item.parentNode.id) {
        header = modifyItem(header, item.id, {}, "ADD SUBHEADER");
      }
      return header;
    });
    setDocumentItems((prev) => ({ ...prev, children: temp }));
  };

  const removeItem = (item) => {
    let temp = [];
    documentItems.children.forEach((header) => {
      if (header.id === item.id) {
        //do nothing
      } else if (header.id === item.parentNode.id) {
        header = modifyItem(header, item.id, {}, "DELETE");
        temp.push(header);
      } else {
        temp.push(header);
      }
    });
    setDocumentItems((prev) => ({ ...prev, children: temp }));
  };

  const reOrderDropItems = (item) => {
    if(item.nodeType === "document-root"){
      setDocumentItems((prev) => ({ ...prev, children:item.children}));
    } else {
      let header = documentItems.children.find((f) => f.id === item.id);
      if (!!header && item.id === header.id) {
      header = { ...header, children: item.children}
    }else {
       header = documentItems.children.find((f) => f.id === item.parentNode.id);
      header = modifyItem(header, item.id, {}, "REORDER", item.children);
    }
    let temp = documentItems.children.map((m) => {
      if (m.id === header.id) {
        m = header;
      }
      return m;
    });
    setDocumentItems((prev) => ({ ...prev, children: temp }));
  }
  }

  return (
    <DocumentBuilderContext.Provider
      value={{
        dragItems,
        setDragItems,
        handleDragContainerDrop,
        setSelectedItem,
        selectedItem,
        saveSettings,
        addHeader,
        documentItems,
        copyItem,
        removeItem,
        setEditItem,
        editItem,
        createSubHeader,
        reOrderDropItems,
        saveContent,
      }}
    >
      {children}
    </DocumentBuilderContext.Provider>
  );
};
