import React, { useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import EditRowFormFields from "./EditRowFormFields";
import TextField from "@mui/material/TextField";
import Grow from "@mui/material/Grow";
import { getIntlTranslation } from "../../../../../utils/helper";

const EditRowForm = ({ setData, data, headerList, tradeIsMissing }) => {
  const scope = useSelector((state) => state.container.scope);
  const [yearOnly, setYearOnly] = useState("");
  const [interval, setInterval] = useState("");
  const [overdue, setOverdue] = useState("");

  const getOrderedHeader = () => {
    return headerList.sort((a, b) => a.editor - b.editor);
  }
  const getTradeName = () => {
    let found = headerList?.find(x => x.key === "trade");
    if(!!found && 'trade' in data){
      return(
        <Box>
        <Grow
          in={true}
          timeout={1000}
          style={{ transformOrigin: "10 0 0" }}
        >
          <Box style={{ width: "100%"}}>
              <TextField
                id={data.id}
               className="w-100 m-1"
                fullWidth={true}
                variant="standard"
                label={found.name}
                disabled={!data.custom}
                maxRows={8}
                multiline
                value={data[found.key]}
                onChange={(e) => {
                  setData({
                    ...data,
                    [found.key]: e.target.value,
                  });
                }}
              />
             
            {tradeIsMissing && <p style={{color: "red"}}>{getIntlTranslation("required_field")}</p>}
            </Box>
        </Grow>
      </Box>
      )
    }
    return null;
  };

  return (
    <>
      <Box className={"w-100 h-100"}>
        {getTradeName()}
        <EditRowFormFields
          headerList={getOrderedHeader()}
          data={data}
          interval={interval}
          overdue={overdue}
          yearOnly={yearOnly}
          setYearOnly={setYearOnly}
          setInterval={setInterval}
          setOverdue={setOverdue}
          setData={setData}
        />
      </Box>
    </>
  );
};

EditRowForm.propTypes = {};

export default EditRowForm;
