import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import IntlMessages from "../../utils/IntlMessages";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";


const SwitchButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const state = useSelector((state) => state.readOnly);

useEffect(() => {
      setReadOnly(state.isReadOnly);
  }, [state.isReadOnly]);
  return (
    <>
      {!readOnly && (
        <Tooltip title={props.tooltip.title}>
          <span>
          <FormControlLabel
            value="start"
            id={props.id}
            className={props.className}
            style={props.style}
            control={
              <Switch
                checked={props.checked}
                onChange={(e) => props.handleClick(e)}
              />
            }
            label={props.label}
            labelPlacement="start"
          />
          </span>
        </Tooltip>
      )}
    </>
  );
};

SwitchButton.defaultProps = {};
SwitchButton.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.element,
  color: PropTypes.string,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool
};

export default SwitchButton;
