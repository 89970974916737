import React from 'react'
import DataAnalysisDataWrapper from "./structure/DataAnalysisDataWrapper"
import {Box} from '@mui/material';

const DataAnalysisHome = () => {
  return (
    <Box className={"h-100 w-100"}>
    <Box className={"h-100 w-100"}>
  <DataAnalysisDataWrapper/>
  </Box>
  </Box>
  );
};
export default DataAnalysisHome;