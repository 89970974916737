import axios from "axios";


// Function to fetch folder structure from S3
export const fetchS3FolderStructure = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/s3/list');
       

        if (response && response.data) {
            return response.data;
        } else {
            console.error("No contents found in the S3 bucket");
            return [];
        }
    } catch (error) {
        console.error("Error fetching S3 folder structure:", error);
        return [];
    }
};


// Function to convert flat S3 object list to a hierarchical structure with unique IDs
export const buildTree = async (s3Objects) => {
    const tree = {};
    if (!s3Objects || s3Objects.length === 0) {
        console.error("S3 Objects array is empty or undefined");
        return [];
    }

    for (const { key } of s3Objects) {
        const parts = key.split('/');
        let current = tree;
        let fullPath = ''; 

        for (let index = 0; index < parts.length; index++) {
            const part = parts[index];
            fullPath += `/${part}`; 

            if (!current[part]) {
                current[part] = {
                    folderName: part,
                    id: fullPath, 
                    children: {}
                };
            }

            if (index === parts.length - 1 && key.endsWith(".pdf")) {
                // Generate a presigned URL for PDF files
                current[part].link = `${process.env.REACT_APP_PREVIEW_DOCUMENT_BUCKET_BASE_URL}/${key}`;
            }

            current = current[part].children;
        }
    }

    const convertToTreeFormat = (obj) => {
        return Object.keys(obj).map(key => ({
            id: obj[key].id, // Ensure unique ID
            folderName: obj[key].folderName,
            link: obj[key].link || null,
            children: convertToTreeFormat(obj[key].children)
        }));
    };
    return convertToTreeFormat(tree);
};

//TO BE DELETED

