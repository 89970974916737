import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  MenuItem,
  Menu,
  ListItemButton,
  Tooltip,
  Typography,
  Button,
  Divider,
} from "@mui/material";

import {
  MoreVert as MoreVertIcon,
  Send as SendIcon,
  DeleteForever as DeleteForeverIcon,
  Reply as ReplyIcon,
  ExpandLess,
  ExpandMore,
  Edit as EditIcon,
} from "@mui/icons-material";
import { getIntlTranslation, getTranslationVariantText } from "../../../../../utils/helper";
import useCommentActionHook from "./useCommentActionHook";


export const RenderActionMenu = ({ anchorEl, data, isReply, onClose, onEdit }) => {
    const open = Boolean(anchorEl);
    const { onDelete, onResolve } = useCommentActionHook(data);
    
    return (
      <>
        <Menu
          id="comments-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "comments-action",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          className="rounded-3"
        >
          <MenuItem
            id="edit_comment"
            onClick={(e) => onEdit(data, isReply)}
          >
           {getTranslationVariantText("_edit-comment", "caption")}
          </MenuItem>
          <MenuItem
            id="delete_comment"
            onClick={(e) => {
              onResolve(isReply)
              onClose();
            }}
          >
            {getTranslationVariantText("_resolve-thread", "caption")}
          </MenuItem>
          <MenuItem
            id="delete_comment"
            onClick={(e) => {
              onDelete(isReply)
              onClose();
            }}
          >
            {getTranslationVariantText("_delete-thread", "caption")}
          </MenuItem>
        </Menu>
      </>
    );
  };

  export const ResolvedCommentActions = ({comment}) => {
    const { onDelete, onReopen } = useCommentActionHook(comment);
    return (
      <Box className="w-100 d-flex align-items-center justify-content-between">
      <Button
      onClick={(e) => {
        e.stopPropagation();
        onReopen()
      }
      }
      style={{width:"49%"}}
      >
        {getTranslationVariantText(
          "_reopen-thread",
          "caption",
          {
            color: "#000000",

          }
        )}
      </Button>
      <Divider
        orientation="vertical"
        style={{
          width: 4,
          backgroundColor: "#ffffff",
        }}
        flexItem
      />
      <Button
      onClick={(e) => {
        e.stopPropagation();
        onDelete()
      }
      }
      style={{width:"49%"}}
      >
        {getTranslationVariantText(
          "_delete",
          "caption",
          {
            color: "#000000",
          }
        )}
      </Button>
    </Box>
    )
  }