import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import DefectImageUploader from "./DefectImageUploader"



const DefectImagesDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = (needGridRefresh) => {
    setOpen(!open);
    props.onClose(needGridRefresh);
  };
  return (
    <>
      <Drawer  anchor={"bottom"} open={props.open} keepMounted style={{zIndex:1500}}>
        <Box style={{ height: "60vh" }} role="presentation">
        <DefectImageUploader row={props.row} onClose={(needGridRefresh) => handleDrawerOpen(needGridRefresh)}/>
        </Box>
      </Drawer>
    </>
  );
};

DefectImagesDrawer.propTypes = {
  open: PropTypes.bool,
  row:PropTypes.object,
  onClose: PropTypes.func,
};

export default DefectImagesDrawer;
