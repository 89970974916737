import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isDescendant } from "./utils/tree-data-utils";
import classnames from "./utils/classnames";
import "./node-renderer-default.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "react-spring";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton } from "@mui/material";
function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const NodeRendererDefault = (props) => {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    didDrop,
    treeId,
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,
    handlePoperClick,
    selectThis,
    updateTableNode,
    setCurrentParentNode,
    ...otherProps
  } = props;

  const nodeTitle = title || node.title;
  const nodeSubtitle = subtitle || node.subtitle;
  const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null;
  const [nodeData, setnodeData] = useState({});

  useEffect(() => {
    setnodeData(node);
    setCurrentParentNode(parentNode);
  }, [node]);

  let handle;
  if (canDrag) {
    if (typeof node.children === "function" && node.expanded) {
      // Show a loading symbol on the handle when the children are expanded
      //  and yet still defined by a function (a callback to fetch the children)
      handle = (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {[...new Array(12)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classnames(
                  "rst__loadingCirclePoint",
                  rowDirectionClass
                )}
              />
            ))}
          </div>
        </div>
      );
    } else {
      // Show the handle used to initiate a drag-and-drop
      handle = connectDragSource(<div className="rst__moveHandle" />, {
        dropEffect: "copy",
      });
    }
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
  if (rowDirection === "rtl") {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
  }

  return (
    <div style={{ height: "100%" }} {...otherProps}>
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === "function") && (
          <div>
            <button
              type="button"
              aria-label={node.expanded ? "Collapse" : "Expand"}
              className={classnames(
                node.expanded ? "rst__collapseButton" : "rst__expandButton",
                rowDirectionClass
              )}
              style={buttonStyle}
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                })
              }
            />

            {node.expanded && !isDragging && (
              <div
                style={{ width: scaffoldBlockPxWidth }}
                className={classnames("rst__lineChildren", rowDirectionClass)}
              />
            )}
          </div>
        )}

      {(node?.nodeType && node.nodeType === "costElement") ||
      (node?.type && node.type === "costElementDto") ? (
        <>
          <div className={classnames("rst__rowWrapper", rowDirectionClass)}>
            {/* Set the row preview to be used during drag and drop */}
            {connectDragPreview(
              <div
                className={classnames(
                  "rst__row",
                  isLandingPadActive && "rst__rowLandingPad",
                  isLandingPadActive && !canDrop && "rst__rowCancelPad",
                  isSearchMatch && "rst__rowSearchMatch",
                  isSearchFocus && "rst__rowSearchFocus",
                  rowDirectionClass,
                  className
                )}
                style={{
                  opacity: isDraggedDescendant ? 0.5 : 1,
                  ...style,
                }}
              >
                {handle}

                <div
                  className={classnames(
                    "rst__rowContents",
                    !canDrag && "rst__rowContentsDragDisabled",
                    rowDirectionClass
                  )}
                  style={{
                    padding: 1,
                  }}
                >
                  <TableContainer component={Paper}>
                    <Grid style={{ display: "flex", padding: 5 }}>
                      <Button
                        style={{
                          // backgroundColor: "#4d4949",
                          backgroundColor: "#969190",
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          width: "72px",
                          padding: 0,
                        }}
                        >
                        {buttons.map((btn, index) => (
                          <div key={index}>{btn}</div>
                          ))}
                      </Button>
                      <Table
                        sx={{ minWidth: 750 }}
                        size="small"
                        aria-label="a dense table"
                        >
                        <TableHead
                          style={{
                            backgroundColor: "#4d4949",
                            // backgroundColor: "#969190",
                            color: "white",
                            height: "10px",
                          }}
                        >
                          <TableRow
                            style={{
                              color: "white",
                            }}
                          >
                            <TableCell
                              style={{ padding: "0px 1px", color: "#ffff",}}
                            >
                              <b>Element code</b>
                            </TableCell>
                            <TableCell
                              style={{ padding: "0px 1px", color: "#ffff" }}
                              align="left"
                            >
                              <b>Beschreibung des Elements</b>
                            </TableCell>
                            <TableCell
                              style={{ padding: "0px 1px", color: "#ffff" }}
                              align="left"
                            >
                              <b>Unit</b>
                            </TableCell>
                            <TableCell
                              style={{ padding: "0px 1px", color: "#ffff" }}
                              align="left"
                            >
                              <b>Von</b>
                            </TableCell>
                            <TableCell
                              style={{ padding: "0px 1px", color: "#ffff" }}
                              align="left"
                            >
                              <b>Mittel</b>
                            </TableCell>
                            <TableCell
                              style={{ padding: "0px 1px", color: "#ffff" }}
                              align="left"
                            >
                              <b>Bis</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            key={nodeData.kostenelementcode}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              maxHeight: "25px",
                              color: "#ffff",
                            }}
                          >
                            <>
                              <TableCell
                                align="center"
                                style={{
                                  padding: "0px",
                                  paddingRight: "15px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  id="outlined-basic"
                                  value={nodeData.code}
                                  size="small"
                                  style={{width:100}}
                                  onChange={(e)=>{
                                    const value = e.target.value
                                     const newData = {
                                      ...nodeData,
                                         code: value,
                                         updated: true
                                     }
                                     updateTableNode(newData, path, treeIndex)
                                   }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{
                                  padding: "0px",
                                  paddingRight: "15px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  id="outlined-basic"
                                  value={nodeData.name}
                                  size="small"
                                  style={{ width: 400 }}
                                  onChange={(e)=>{
                                    const value = e.target.value
                                     const newData = {
                                      ...nodeData,
                                         name: value,
                                         updated: true
                                     }
                                     updateTableNode(newData, path, treeIndex)
                                   }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  padding: "0px",
                                  paddingRight: "15px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  id="outlined-basic"
                                  value={nodeData.unit}
                                  style={{width:30}}
                                  size="small"
                                  onChange={(e)=>{
                                    const value = e.target.value
                                     const newData = {
                                      ...nodeData,
                                         unit: value,
                                         updated: true
                                     }
                                     updateTableNode(newData, path, treeIndex)
                                   }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  padding: "0px",
                                  paddingRight: "15px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  id="outlined-basic"
                                  value={nodeData.von}
                                  size="small"
                                  style={{width:70}}
                                  onChange={(e)=>{
                                    const value = e.target.value
                                     const newData = {
                                      ...nodeData,
                                         von: value,
                                         updated: true
                                     }
                                     updateTableNode(newData, path, treeIndex)
                                   }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  padding: "0px",
                                  paddingRight: "15px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  id="outlined-basic"
                                  value={nodeData.mittel}
                                  size="small"
                                  style={{width:70}}
                                  onChange={(e)=>{
                                    const value = e.target.value
                                     const newData = {
                                      ...nodeData,
                                         mittel: value,
                                         updated: true
                                     }
                                     updateTableNode(newData, path, treeIndex)
                                   }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  padding: "0px",
                                  paddingRight: "15px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  id="outlined-basic"
                                  value={nodeData.bis}
                                  size="small"
                                  style={{width:70}}
                                  onChange={(e)=>{
                                    const value = e.target.value
                                     const newData = {
                                      ...nodeData,
                                         bis: value,
                                         updated: true
                                     }
                                     updateTableNode(newData, path, treeIndex)
                                   }}
                                />
                              </TableCell>
                            </>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </TableContainer>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={classnames("rst__rowWrapper", rowDirectionClass)}>
          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={classnames(
                "rst__row",
                isLandingPadActive && "rst__rowLandingPad",
                isLandingPadActive && !canDrop && "rst__rowCancelPad",
                isSearchMatch && "rst__rowSearchMatch",
                isSearchFocus && "rst__rowSearchFocus",
                rowDirectionClass,
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}
            >
              {handle}

              <div
                className={classnames(
                  "rst__rowContents",
                  !canDrag && "rst__rowContentsDragDisabled",
                  rowDirectionClass
                )}
              >
                <div className={classnames("rst__rowLabel", rowDirectionClass)}>
                  <span
                    className={classnames(
                      "rst__rowTitle",
                      node.subtitle && "rst__rowTitleWithSubtitle"
                    )}
                  >
                    {typeof nodeTitle === "function"
                      ? nodeTitle({
                          node,
                          path,
                          treeIndex,
                        })
                      : nodeTitle}
                  </span>

                  {nodeSubtitle && (
                    <span className="rst__rowSubtitle">
                      {typeof nodeSubtitle === "function"
                        ? nodeSubtitle({
                            node,
                            path,
                            treeIndex,
                          })
                        : nodeSubtitle}
                    </span>
                  )}
                </div>

                <div className="rst__rowToolbar">
                  {buttons.map((btn, index) => (
                    <div
                      key={index} // eslint-disable-line react/no-array-index-key
                      className="rst__toolbarButton"
                    >
                      {btn}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

NodeRendererDefault.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: "ltr",
};

NodeRendererDefault.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
};

export default NodeRendererDefault;
