import React from 'react';
import ClientManagementHome from "./ClientManagementHome"
import { globalRoot } from "../../utils/defaultValues";
import IntlMessages from "../../utils/IntlMessages";
import Groups3Icon from '@mui/icons-material/Groups3';

const ClientManagementRouteConfig = {
	routes: [
		{
    id:"client-management",
    path: `${globalRoot}/client-management/`,
    exact: true,
    showBreadCrumbs: true,
    name: <IntlMessages id={"menu.client-management"}/>,
    element: <ClientManagementHome/>,
    icon: <Groups3Icon style={{ width:22, height:22, color: "#637381"}} />,
    auth: [],
  },
	]
};
export default ClientManagementRouteConfig;