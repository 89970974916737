import React from 'react';
import CostDataCenterHome from "./CostDataCenterHome"
import { globalRoot } from "../../utils/defaultValues";
import IntlMessages from "../../utils/IntlMessages";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

// change path  
const CostDataCenterRouteConfig = {
	routes: [
		{
    path: `${globalRoot}/cost-data-center`,
    exact: true,
    name: <IntlMessages id={"menu.cost-data-center"}/>,
    element: <CostDataCenterHome/>,
    icon: <RequestQuoteIcon style={{ width:22, height:22, color: "#637381"}} />,
    auth: [],
  },
	]
};
export default CostDataCenterRouteConfig;