import React, { useEffect, useState } from "react";
import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { useReviewContextHook } from "./ReviewContext";
import {
  renderPreviewNode,
} from "../report-component/reportItemRender";
import { useSelector } from "react-redux";
import TextSelectorComments from "../report-component/comments/TextSelectorComments";



const TreeNode = ({ node, index }) => {
  const { reportType, selectedItem } = useReviewContextHook();
  return (
    <React.Fragment key={node.id}>
      <ListItem
        className="p-0 d-flex flex-column w-100"
        key={`key_${node.id}_${index}`}
        id={`scroll_item_${node.id}_${index}`}
      > 
        {renderPreviewNode({ ...node, index: index }, reportType)}
      </ListItem>
      {node.children?.length > 0 && (
        <List className="p-0">
          {node?.children?.map((child, i) => {
            index = `${index}`.replace(/\.$/, "");
            return (
              <TreeNode
                index={`${index}.${i + 1}.`}
                node={{ ...child, id:child.ident,parentId: node.ident }}
              />
            );
          })}
        </List>
       
      )}
    </React.Fragment>
  );
};

const ReviewContentContainer = (props) => {
  const { reportType,documentItems,loading } = useReviewContextHook();
  const commentsState = useSelector((state) => state.comments);

  return (
    
    <CardContent
      className="custom-scrollBar p-1"
      style={{
        width: "100%",
        overflowY: "auto",
        overflowX: "auto",
        height: "100%",
      }}
      id={`review-data-container`}
    >
     
      {loading ? (
        <List>
          {[1, 2, 3, 4, 5].map((index) => (
            <ListItem key={index}>
              <ListItemText>
                <Skeleton variant="text" />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <List id={`list-data-container`} className="p-1">
          {documentItems?.children?.map((m, index) => {
            return (
              <TreeNode key={`${m.id}-${index}`} node={{...m, id:m.ident}} index={index + 1} />
            );
          })}
        </List>
      )}
    </CardContent>
    
  );
};

ReviewContentContainer.propTypes = {};

export default ReviewContentContainer;
