import React, { useMemo, useState } from "react";
import DocumentMap from "./DocumentMap";
import {
  AppBar,
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
} from "@mui/material";
import ContentContainer from "./ContentContainer";
import { useReportStructureContextHook } from "./ReportStructureContext";
import AssetSwitcher from "../../../component-bundle/assetSwitcher/AssetSwitcher";
import NoAssets from "../../../component-bundle/NoAssets";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { fullReport, redFlag } from "../../../../utils/defaultValues";
import { useSelector } from "react-redux";
import {
  getDialogBoxHeader,
  getIntlTranslation,
} from "../../../../utils/helper";
import ReportGenerationDialog from "../../../component-bundle/generate-report/ReportGenerationDialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import BreadcrumbProjectContainer from "../../../../layout/app-container/BreadcrumbProjectContainer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const ReportContainer = () => {
  const {
    reportType,
    fullScreenMode,
    setFullScreenMode,
  } = useReportStructureContextHook();

  const getReportOptions = () =>
    reportType?.toUpperCase() === "REDFLAG" ? redFlag : fullReport;

  return (
    <>
    {!fullScreenMode ? (
      <RenderReportContainer/>
    ) : (
      <>
        <Dialog
          open={fullScreenMode}
          TransitionComponent={Transition}
          fullScreen
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setFullScreenMode(false);
            }
          }}
          disableBackdropClick
          disableEscapeKeyDown
          style={{ overflow: "hidden" }}
        >
          <AppBar
            style={{
              position: "sticky",
              height: 40,
            }}
           className="header w-100 rounded-0"
          >
            <Toolbar className={"dialog-toolbar"}>
            <div className="d-flex align-items-center justify-content-between w-25">
              {getDialogBoxHeader(getReportOptions().heading)}
              </div>
              <div className="d-flex align-items-center justify-content-between w-75">
              <BreadcrumbProjectContainer 
             showAsset={true}
             showSubAsset={false}/>
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={() => setFullScreenMode(false)}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
              </div>
            </Toolbar>
          </AppBar>

          <DialogContent
            dividers
            className={"w-100 p-2"}
            style={{ height: "calc(100% - 50px)", overflow: "hidden" }}
          >
             <RenderReportContainer/>
          </DialogContent>
        </Dialog>
      </>
    )}
  </>

  )
}
function RenderReportContainer(props) {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.tddChapters);
  const {
    assets,
    selectedItem,
    reportType,
    openAsset,
    getUpdatedRows,
    openReviewMode,
    fullScreenMode,
    setFullScreenMode,
  } = useReportStructureContextHook();

const getReportOptions = () =>
  reportType?.toUpperCase() === "REDFLAG" ? redFlag : fullReport;

  const getDataList = useMemo(
    () =>
      assets?.map((a) => ({
        name: a.name,
        ident: a.assetIdent,
        projectIdent: scope.projectIdent,
        isParent: true,
        parentIdent: a.assetIdent,
        users: a?.userDtos?.map((u) => {
          return {
            label: `${u.firstName} ${u.lastName}`,
            value: u.emailAddress,
          };
        }),
      })),
    [assets, scope.projectIdent]
  );

  const getSelectedData = useMemo(() => {
    let found = assets?.find((a) => a.assetIdent === openAsset.ident);
    if (!!found) {
      return {
        name: found.name,
        ident: found.assetIdent,
        isParent: true,
        parentIdent: found.assetIdent,
        users: found?.userDtos?.map((u) => {
          return {
            label: `${u.firstName} ${u.lastName}`,
            value: u.emailAddress,
          };
        }),
        projectIdent: scope.projectIdent,
      };
    }
    return null;
  }, [assets, openAsset.ident, scope.projectIdent]);
  const [blockChange, setBlockChange] = useState({
    block: false,
    newAsset: null,
    action: null,
  });


  const render = () => {
    return (
      <Box className="w-100 h-100 d-flex">
        {assets.length > 0 ? (
          <>
            <Box className="w-100 h-100 d-flex flex-column">
              <Box
                className="w-100 d-flex flex-row align-items-center justify-content-between m-1 rounded-1"
                style={{ backgroundColor: "#c1d0f0" }}
              >
                <Box style={{width:"70%"}}>
                  <AssetSwitcher
                    dirtyState={
                      getUpdatedRows()?.data?.filter((f) => f.updated)?.length >
                      0
                    }
                    setBlockState={(val) =>
                      setBlockChange({
                        block: true,
                        newAsset: val,
                        action: "asset-switch",
                      })
                    }
                  />
                </Box>
                <Box
                  className="d-flex p-1 rounded-3
                  report-comments 
                  align-items-center 
                  justify-content-between"
                  style={{
                    height: 40,
                    width:"30%",
                    backgroundColor: "#d08f8e",
                  }}
                >
                  {openAsset.ident && (
                    <div
                    style={{width:"40%"}}
                      className="d-flex flex-grow-1 h-100"
                    >
                      <ReportGenerationDialog
                        reportOptions={[getReportOptions()]}
                        selectedData={getSelectedData}
                        filterDataList={getDataList}
                        id={`report-generation`}
                        className="text-light p-1"
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "30%",
                      flexGrow: "1",
                      height:"100%",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      style={{
                        width: 4,
                        backgroundColor: "#ffffff",
                        height:"100%",
                        margin:"0px 5px",
                      }}
                      flexItem
                    />
                    <Tooltip title={getIntlTranslation("tooltip.review-mode")} className="h-100 p-1">
                      <Box
                        className="g-btn-elevated d-flex align-items-center h-100 p-1 w-100 justify-content-between"
                        style={{ color: "#ffffff", cursor: "pointer" }}
                        onClick={(e) => openReviewMode()}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                          className="m-0"
                        >
                          {getIntlTranslation("_review")}
                        </p>
                        <RateReviewIcon
                          style={{
                            color: "#ffffff",
                            fontSize: 20,
                            marginLeft: 5,
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </div>
                  {!fullScreenMode && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "10%",
                        flexGrow: "1",
                        height:"100%",
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        style={{
                          width: 4,
                          backgroundColor: "#ffffff",
                          height:"100%",
                          margin:"0px 5px",
                        }}
                        flexItem
                      />
                      <Tooltip title={getIntlTranslation("tooltip.fullscreen")} className="h-100">
                        <IconButton
                          size="small"
                          aria-label="Full screen"
                          style={{ width: 22, height: 22, color: "#ffffff" }}
                          onClick={() => setFullScreenMode(true)}
                          id={"full-screen"}
                        >
                          <FullscreenIcon
                            style={{
                              color: "#ffffff",
                              fontSize: 22,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                className="w-100 d-flex flex-row"
                style={{ height: "calc(100% - 50px)" }}
              >
                <Card className="m-1 d-flex" style={{ width: "25%" }}>
                  <DocumentMap />
                </Card>

                <Card className="m-1 d-flex" style={{ width: "75%" }}>
                  {!!selectedItem.ident && <ContentContainer />}
                </Card>
              </Box>
            </Box>
          </>
        ) : (
          <NoAssets />
        )}
      </Box>
    );
  };

  return (
    <>
     {render()}
    </>
  );
}
export default ReportContainer;
