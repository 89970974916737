import React  from 'react';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import StatutoryGridWrapper from './structure/StatutoryGridWrapper';
import FullScreenDialog from "./dialog-full/FullScreenDialog"
function StatutoryComplianceHome() {
  const state = useSelector((state) => state.tddInspection);
  return (
    <>
    {state.fullMode ? 
    <FullScreenDialog
    open={state.fullMode}
    children={ <Box className={"h-100 w-100"}>
      <StatutoryGridWrapper/>
      </Box>}
    headerLabel = {"menu.statutoryCompliance"}
    />
     :
    <>
     <Box className={"h-100"}>
       <StatutoryGridWrapper />
     </Box>
   </>
    }
    </>
  );
}

export default StatutoryComplianceHome;
