import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import useAssetListHook from '../../../../component-bundle/hooks/useAssetListHook';
import NoAssets from '../../../../component-bundle/NoAssets';
import TempVersionAlert from '../../../version-comparison/TempVersionAlert';
import { KeyFindingsProvider } from '../context/KeyFindingsContext';
import HeaderContainer from './HeaderContainer';
import { CategoriesDataProvider } from '../context/CategoriesDataContext';
import {KeyFindingsCurrentVersionContainer} from './KeyFindingsCurrentVersionContainer';
import { KeyFindingsCategoriesContainer } from './KeyFindingsCategoriesContainer';
import { KeyFindingsPropertyProvider } from '../context/KeyFindingsPropertyContext';
import {KeyFindingsComparisionContainer} from "./KeyFindingsComparisionContainer"


const KeyFindingsWrapper = ({moduleApi}) => {
  const monitoringState = useSelector((state) => state.projectMonitoring);
    const tempVersion = monitoringState.temp;
    const openAsset = useSelector((state) => state.openAsset);
    const { assetList } = useAssetListHook();

    const getHeight = useCallback(() => {
        if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
          return { height: "calc(100% - 100px)" };
        } else {
          return { height: "calc(100% - 50px)" };
        }
      }, [openAsset.ident, tempVersion.assetIdent, tempVersion.isTemp]);


  return (
     <>
      <Box className="w-100 h-100 d-flex flex-column align-items-baseline">
        <KeyFindingsProvider moduleApi={moduleApi}>
          {tempVersion.isTemp && <TempVersionAlert />}
          <CategoriesDataProvider moduleApi={moduleApi}>
            <Box className="w-100" style={{ height: 50 }}>
              <HeaderContainer />
            </Box>
            {assetList.assets.length <= 0 ? (
              <NoAssets />
            ) : (
              <Box className="w-100 d-flex flex-row" style={getHeight()}>
                <Box className="w-25 h-100 overflow-hidden m-1">
                  <KeyFindingsCategoriesContainer />
                </Box>
                <Box className="w-75 d-flex flex-row overflow-hidden h-100 m-1">
                  <KeyFindingsPropertyProvider moduleApi={moduleApi}>
                  <KeyFindingsCurrentVersionContainer />
                  {monitoringState.isCompareMode && <KeyFindingsComparisionContainer/>}
                  </KeyFindingsPropertyProvider>
                </Box>
              </Box>
            )}
          </CategoriesDataProvider>
        </KeyFindingsProvider>
      </Box>
    </>
  )
}

KeyFindingsWrapper.propTypes = {}

export default KeyFindingsWrapper