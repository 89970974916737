import React from 'react';
import PMProjectListHome from "./PMProjectListHome"
import { pmRoot} from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";


const ProjectListRouteConfig = {
	routes: [
		{
    path: `${pmRoot}/project-list`,
    exact: true,
    name: <IntlMessages id={"menu.project-list"}/>,
    element: <PMProjectListHome/>,
    auth: [],
  },
	]
};
export default ProjectListRouteConfig;