import React,{useState} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import FilterMultiDropdownEditor from "../../../../component-bundle/grid/filters/FilterMultiDropdownEditor";
import {setFilters} from "../keyFindingSlice"
import {costTypeOptions, defectTypeOptions, priorityTypeOptions, riskTypeOptions} from "../../../../../common/dropdowns"
import {getLabel} from "../../../../../utils/helper";



function FilterOptions(props) {
  const dispatch = useDispatch()
    const scope = useSelector((state) => state.container.scope);
    const [filterSelected, setFilterSelected] = useState({
      costType: {
        selected:[]
      }, 
      defectType:{
        selected: [],
      },
      priorityType:{
        selected: []
      }, 
      riskType:{
        selected: []
      }
    });

    
    return (
        <Box style={{ flexGrow: 1, margin:2, width:"100%"}}>
        <AppBar
          position="static"
          style={{ backgroundColor: "#f1f1f1", color: "#ffffff", borderRadius:5 , width:"100%"}}
        >
          <Toolbar style={{ minHeight: 35, padding: 0, marginLeft: 0, height: 45,width:"100%"}}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexGrow: 1,
              }}
            >
            <FilterMultiDropdownEditor 
              options={costTypeOptions.filter((f) => f.language === scope.projectLanguageCode)}
              type="costType"
              placeholder = {getLabel("Cost Type")}
              width="25%"
              applyFilter ={(option) => {
                const  costType= {
                  ...filterSelected.costType,
                  selected: [...option],
                };
                setFilterSelected({
                  ...filterSelected,
                  costType: costType,
                });
                dispatch(setFilters({
                  ...filterSelected,
                  costType: costType,
                }))
              }}
              />
              <Divider
                orientation="vertical"
                style={{
                  width: 1,
                  margin: "15px 0px",
                  backgroundColor: "#244a9a",
                }}
                flexItem
              />
              <FilterMultiDropdownEditor 
              options={defectTypeOptions.filter((f) => f.language === scope.projectLanguageCode)}
              type="defectType"
              placeholder = {getLabel("_Defect")}
              width="25%"
              applyFilter ={(option) => {
                const  type= {
                  ...filterSelected.defectType,
                  selected: [...option],
                };
                setFilterSelected({
                  ...filterSelected,
                  defectType: type,
                });
                dispatch(setFilters({
                  ...filterSelected,
                  defectType: type,
                }))
              }}
              />
              <Divider
                orientation="vertical"
                style={{
                  width: 1,
                  margin: "15px 0px",
                  backgroundColor: "#244a9a",
                }}
                flexItem
              />
              <FilterMultiDropdownEditor 
              options={priorityTypeOptions.filter((f) => f.language === scope.projectLanguageCode)}
              type="priorityType"
              placeholder = {getLabel("_Priority-Type")}
              width="25%"
              applyFilter ={(option) => {
                const  type= {
                  ...filterSelected.priorityType,
                  selected: [...option],
                };
                setFilterSelected({
                  ...filterSelected,
                  priorityType: type,
                });
                dispatch(setFilters({
                  ...filterSelected,
                  priorityType: type,
                }))
              }}
              />
              <Divider
                orientation="vertical"
                style={{
                  width: 1,
                  margin: "15px 0px",
                  backgroundColor: "#244a9a",
                }}
                flexItem
              />
               <FilterMultiDropdownEditor 
              options={riskTypeOptions.filter((f) => f.language === scope.projectLanguageCode)}
              type="riskType"
              placeholder = {getLabel("_risk-Type")}
              width="25%"
              applyFilter ={(option) => {
                const  type= {
                  ...filterSelected.riskType,
                  selected: [...option],
                };
                setFilterSelected({
                  ...filterSelected,
                  riskType: type,
                });
                dispatch(setFilters({
                  ...filterSelected,
                  riskType: type,
                }))
              }}
              />
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    )
}


export default FilterOptions
