import keyFindingSlice from "./feature-bundle/key-findings/keyFindingSlice"
import defectSlice from "./feature-bundle/defects/defectSlice"
import costElementSlice from "./feature-bundle/defects/costElementSlice"
import elementSectionSlice from "./feature-bundle/defects/element-section/elementSectionSlice"
import tddBuildingProfileSlice from "./feature-bundle/building-profile/tddBuildingProfileSlice"
import buildingprofileSlice from "./feature-bundle/sub-asset-management/sub-asset/buildingprofileSlice"
import subAssetListSlice from "./feature-bundle/sub-asset-management/sub-asset/subAssetListSlice"
import subAssetSlice from "./feature-bundle/sub-asset-management/sub-asset/subAssetSlice"
import OpenAssetSlice from "./feature-bundle/asset-management/asset/OpenAssetSlice"
import capexOverviewSlice from "./feature-bundle/capex-overview/capexOverviewSlice";
import photoDocumentationSlice from "./feature-bundle/photodocumentation/photoDocumentationSlice";
import chaptersSlice from "./common/slice/chaptersSlice";
import historyReportTextSlice from "./common/report-text-history/historyReportTextSlice"
import ImageUploadSlice from "./common/slice/ImageUploadSlice";
import statutoryCompliance from "./feature-bundle/statutory-compliance/statutoryComplianceSlice"
import dataSlice from "./common/slice/dataSlice"
import suggestionSlice from "../component-bundle/suggestion-values/suggestionSlice";
import tddProjectInformation from "./common/report-component/items/project-information/projectInformationTDDSlice";
import buildingProfileTable from "./common/report-component/items/building-profile/buildingProfileTableSlice";
import dataAnalysis from "./feature-bundle/data-analysis/dataAnalysisSlice";
import projectInformationTDD from "./common/report-component/items/project-information/projectInformationTDDSlice";
import textEditorSlice from "./common/report-component/items/text-editors/textEditorSlice";
import reportReviewSlice from "./common/report-review/reportReviewSlice";
import contactCardsSlice from "./common/report-component/items/contact-cards/contactCardsSlice";
export const tddStoreReducers = {
    tddKeyFinding: keyFindingSlice,
    tddDefectSection: defectSlice,
    tddCostElement: costElementSlice,
    tddElementSection : elementSectionSlice,
    capexOverview:capexOverviewSlice,
    photoDocumentation:photoDocumentationSlice,
    tddChapters: chaptersSlice,
    tddReportHistory: historyReportTextSlice,
    tddImageUpload : ImageUploadSlice,
    tddInspection : statutoryCompliance,
    dataSlice : dataSlice,
    SubAsset:subAssetSlice,
    suggestionSlice:suggestionSlice,
    tddProjectInformation:tddProjectInformation,
    buildingProfileTable:buildingProfileTable,
    dataAnalysis:dataAnalysis,
    projectInformationTDD:projectInformationTDD,
    textEditor:textEditorSlice,
    reportReview:reportReviewSlice,
    contactCardsState:contactCardsSlice,
};

export const tddStoreBlackList = [
    "tddKeyFinding",
    "tddDefectSection",
    "tddCostElement",
    "tddElementSection",
    "capexOverview",
    "photoDocumentation",
    "tddChapters",
    "tddReportHistory",
    "tddImageUpload",
    "dataSlice",
    "SubAsset",
    "suggestionSlice",
    "tddProjectInformation",
    "buildingProfileTable",
    "dataAnalysis",
    "textEditor",
    "reportReview",
    "contactCardsState"
];