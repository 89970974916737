import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import TamModuleContainer from "./TamModuleContainer"

const TamModuleDataWrapper = props => {
  return (
    <div><TamModuleContainer/></div>
  )
}

TamModuleDataWrapper.propTypes = {}

export default TamModuleDataWrapper;