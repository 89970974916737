import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import CostSection from '../CostSection';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import { Box, Divider } from '@mui/material';
import DefectSection from '../DefectSection';
import Checkbox from '@mui/material/Checkbox';
import CostSuggestion from '../CostSuggestion';
import { resetSliceState } from '../defectSlice';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DefectFormFooter from '../DefectFormFooter';
import DefectSuggestion from '../DefectSuggestion';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useRef, useState } from 'react';
import SourceDataRoomSection from '../SourceDataRoomSection';
import FormControlLabel from '@mui/material/FormControlLabel';
import { saveElementAndDefect } from '../../../api/defectApi';
import ElementSection from '../element-section/ElementSection';
import { getDialogBoxHeader, getLabel } from '../../../../../utils/helper';
import {
  createDefectDto,
  createElementDto,
  createMeasureDto,
  formData,
  validationSchema,
} from '../defectHelper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateDefect(props) {
  const [createAnother, setcreateAnother] = useState(false);
  const containerRef = React.useRef(null);
  const intialFormData = formData;
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const state = useSelector((state) => state.tddDefectSection);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();

  const [apiCallCondition, setApiCallCondition] = useState({
    projectIdent: '',
    ident: '',
    elementName: '',
    defectType: '',
  });

  useEffect(() => {
    if (state.saveDefect.type === 'SUCCESS') {
      state.saveDefect.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      props.onUpdate();
      if (createAnother) {
        formRef.current.resetForm();
      } else {
        props.onClose();
      }
      dispatch(resetSliceState());
    }
  }, [
    createAnother,
    dispatch,
    enqueueSnackbar,
    props,
    state.saveDefect.messages,
    state.saveDefect.type,
  ]);

  const onSave = (values) => {
    let element = createElementDto(values);
    const measureDto = createMeasureDto(values);

    let defect = createDefectDto(values);

    const assetIdent = openAsset.ident;
    const saveDefect = {
      projectIdent: scope.projectIdent,
      elementDto: { ...element, assetIdent: openAsset.ident },
      defectDto: {
        ...defect,
        measureDtoList: [measureDto],
        assetIdent: assetIdent,
      },
    };
    dispatch(saveElementAndDefect(saveDefect));
  };

  return (
    <>
      {!!props.subCategory && (
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={props.open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.onClose();
            }
          }}
          TransitionComponent={Transition}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <Formik
            enableReinitialize
            initialValues={intialFormData}
            validationSchema={validationSchema}
            onSubmit={(values) => onSave(values)}
            innerRef={formRef}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              isSubmitting,
              resetForm,
            }) => (
              <>
                <AppBar
                  style={{ position: 'relative', height: 40 }}
                  className="appBar"
                >
                  <Toolbar
                    style={{ minheight: 40 }}
                    className="dialog-toolbar d-flex align-items-center p-1"
                  >
                    <Typography variant="h6" style={{ flex: 1, marginLeft: 5 }}>
                      {getDialogBoxHeader('_Defect')}
                    </Typography>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.costDefect}
                            style={{
                              color: values.costDefect ? '#254a9a' : '#ffffff',
                            }}
                            onChange={(e) =>
                              setFieldValue('costDefect', e.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="button"
                            style={{
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              marginLeft: 4,
                            }}
                          >
                            {getLabel('_Cost-Defect')}
                          </Typography>
                        }
                        labelPlacement="end"
                      />
                    </Box>
                    <Divider
                      orientation="vertical"
                      style={{
                        width: 4,
                        margin: '10px 10px',
                        backgroundColor: '#ffffff',
                      }}
                      flexItem
                    />
                    <div style={{ marginLeft: 'auto' }}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Toolbar>
                </AppBar>
                <DialogContent
                  dividers
                  className={'custom-scrollBar'}
                  style={{ padding: '0px 5px' }}
                >
                  <Form>
                    <Box className={'d-flex align-items-start flex-wrap'}>
                      <ElementSection
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        resetForm={(e) => resetForm()}
                        subCategory={props.subCategory}
                      />
                      <DefectSection
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </Box>

                    <>
                      <Box style={{ width: '100%' }}>
                        <DefectSuggestion
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                        />
                      </Box>

                      <Grow
                        direction="up"
                        container={containerRef.current}
                        in={values.costDefect}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(values.costDefect ? { timeout: 1000 } : {})}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Box style={{ marginTop: 10, marginLeft: 0 }}>
                          <Box
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <CostSuggestion
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                            />
                            <CostSection
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                            />
                          </Box>
                        </Box>
                      </Grow>
                      <SourceDataRoomSection
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </>
                  </Form>
                </DialogContent>
              </>
            )}
          </Formik>
          <DialogActions>
            <DefectFormFooter
              createAnother={createAnother}
              onClose={props.onClose}
              onSelect={(val) => setcreateAnother(val)}
              handleSubmit={(e) => formRef.current.handleSubmit()}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

CreateDefect.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  subCategory: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default CreateDefect;
