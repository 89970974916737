import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsset } from "../../../api/assetOverviewApi";
import { resetState } from "../asset-overview/assetOverviewSlice";


function useAssetHooks (props) {
  const dispatch = useDispatch();
  const [asset, setAsset] = useState({});
  const scope = useSelector((state) => state.container.scope);
  const assetState = useSelector((state) => state.assetOverview);
  
  useEffect(() => {
    if (
      assetState.fetchAsset.type === 'SUCCESS' &&
      !!assetState.fetchAsset.data
    ) {
      setAsset(assetState.fetchAsset.data);
      dispatch(resetState());
    }
  }, [assetState]);


  const fetchAsset = (ident) => {
    dispatch(
      getAsset({
        api: scope.api,
        projectIdent: scope.projectIdent,
        assetIdent: ident,
      })
    );
  }

  return {fetchAsset, asset};
}

export default useAssetHooks;
