import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import BreadcrumnContainer from './BreadcrumbContainer';
import FullScreenWidget from './FullScreenWidget';
import DateWidget from './DateWidget';
import { setHideSidebarFlag } from './containerActionSlice';
import { addAndRemoveClasses } from './containerActions';

import { addNewClassToContainer } from './containerActionSlice';
import LanguageSwitcherWidget from './LanguageSwitcherWidget';
import AppGuideBookWidget from './AppGuideBookWidget';
import UserProfileWidget from './UserProfileWidget';
import BreadcrumbProjectContainer from './BreadcrumbProjectContainer';
//import OtherApps from "./OtherApps";

const Header = () => {
  const dispatch = useDispatch();
  const actionState = useSelector((state) => state.containerActions);
  useEffect(() => {
    if (actionState.hideSidebar) {
      let payload = addAndRemoveClasses(
        'menu-hidden',
        actionState.containerClassnames,
        'menu-show'
      );
      dispatch(addNewClassToContainer(payload));
    } else {
      let payload = addAndRemoveClasses(
        'menu-show',
        actionState.containerClassnames,
        'menu-hidden'
      );
      dispatch(addNewClassToContainer(payload));
    }
  }, [actionState.hideSidebar, dispatch]);

  return (
    <div className={'d-flex w-100'}>
      <nav className="header navbar fixed-top w-100 align-items-center justify-content-start">
        {/* <button
        style={{ marginLeft: 20 }}
        className="header-icon btn btn-empty d-sm-inline-block header-btn"
        type="button"
        id="toogle-sidebar"
        onClick={(e) => 
          dispatch(setHideSidebarFlag(!actionState.hideSidebar))
        }
      >
        <MenuIcon />
      </button> */}
        <div
          className={'d-flex align-items-center justify-content-start p-1 h-100'}
          style={{width:"15%"}}
        >
          <NavLink className="header-logo nav" to="/guru/user-dashboard/">
            <div
              className={'d-flex align-items-center'}
              style={{ width: '100%', height: '100%' }}
            >
              <span className="logo d-xs-block" />
              <span className="logo-guru d-xs-none d-md-none d-lg-block" />
            </div>
          </NavLink>
        </div>
        <div className="d-flex align-items-center justify-content-between p-1"  style={{width:"30%"}}>
          <BreadcrumnContainer />
        </div>
        <div
          className={'d-flex align-items-center justify-content-between h-100 p-1'}
          style={{width:"55%"}}
        >
          <div className='d-flex align-items-center justify-content-end  m-1 h-100'>
           <BreadcrumbProjectContainer 
           showAsset={true}
           showSubAsset={true}/>
           </div>
           <div style={{width:"40%"}} className='d-flex align-items-center justify-content-around h-100'>
          <DateWidget />
          <LanguageSwitcherWidget />
          <AppGuideBookWidget />
          <UserProfileWidget />
          <FullScreenWidget />
          </div>
        </div>
      </nav>
    </div>
  );
};

Header.propTypes = {};

export default Header;
