import React from "react";
import {
  Button,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
} from "@mui/material";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { useDispatch } from "react-redux";
import IntlMessages from "../../utils/IntlMessages";
import { getHandbook } from "../../api/containerApi";
import { getTranslationVariantText } from "../../utils/helper";

const languages = [
  {
    id: "en",
    title: "English",
    flag: "us",
  },
  {
    id: "de",
    title: "Deutsch",
    flag: "de",
  },
];

const AppGuideBookWidget = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserHandBook = (lang) => {
    dispatch(getHandbook(lang));
  };

  return (
    <div className="g-btn-elevated m-1 p-1">
      <Tooltip title={<IntlMessages id="header.handbookDownload" />}>
        <IconButton
          id="handbook"
          aria-controls={open ? "handbook-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{
            width: 25,
          }}
        >
          <Avatar
            variant="square"
            style={{
              width: 25,
              height: 25,
              backgroundColor: "#d08f8e",
              borderRadius: 4,
            }}
          >
            <StickyNote2Icon style={{ fontSize: 30, color: "#ffffff" }} />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        id="handbook-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "handbook",
        }}
      >
        {languages.map((l) => (
          <MenuItem
            key={l.id}
            onClick={() => getUserHandBook(l.id)}
            id="switchLanguage"
          >
            <ListItemIcon className="min-w-40" id={`select-${l.title}`}>
              <img
                className="min-w-20"
                src={`/assets/flags/${l.flag}.png`}
                alt={l.title}
              />
            </ListItemIcon>
            <ListItemText primary={l.title} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AppGuideBookWidget;
