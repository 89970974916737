import deLang from './de'
import enLang from './en'


const deLocale = {
    messages: {
      ...deLang,
    },
    locale: 'de-DE',
  };

  const enLocale = {
    messages: {
      ...enLang,
    },
    locale: 'en-US',
  };

const Locale = {
  en: enLocale,
  de: deLocale,
};

export default Locale;