import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Divider } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import List from "@mui/material/List";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextInput from "./TextInput";
import Maps from "./Maps";
import Image from "./Image";
import TextEditorLabel from "./TextEditorLabel";
import RenderCustomStructure from "./RenderCustomStructure";
import { useSelector, useDispatch } from "react-redux";
// Api calling func
import {
  saveSectionStructure,
  deleteSectionStructure,
  chapterDataStructure,
  sectionDataStructure,
  getSubSectionDataStructure,
  saveSubSectionStructure,
  deleteSubSectionStructure,
} from "../../../api/customChaptersApi";
import { resetState } from "../../../common/slice/chaptersSlice";
import Table from "./Table";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import GrassSharpIcon from "@mui/icons-material/GrassSharp";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { getIntlTranslation } from "../../../../../utils/helper";

const CustomStyle = {
  width: "100%",
  height: "100%",
  overflow: "auto",
  borderRight: "2px solid",
  borderColor: "#808080",
};

const CustomBox = ({
  option,
  current,
  chapterData,
  section,
  subSection,
  isdragging,
  remove,
}) => {
  const [dragStart, setDragStrat] = useState(isdragging);
  const [droppedOption, setDroppedOption] = useState(option);
  const [displaySaveBtn, setDisplaySaveBtn] = useState(false);
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  const [currentChapterDatas, setCurrentChapterDatas] = useState(null);
  const chapter = useSelector((state) => state.tddChapters);
  const chapterState = useSelector((state) => state.tddChapters.chapterStructure);
  const sectionState = useSelector((state) => state.tddChapters.sectionStructure);
  const subSectionState = useSelector(
    (state) => state.tddChapters.subSectionStructure
  );
  const [childName, setChildName] = useState(null);
  useEffect(() => {
    if (!!current) {
      if (current.type === "chapter" || current.type === "non-chapter") {
        dispatch(
          chapterDataStructure({
            projectIdent: scope.projectIdent,
            ident: current.ident,
            language: scope.projectLanguageCode,
            report: chapter.customType,
          })
          );
        } else if (current.type === "section" && current.cident === chapterData.ident) {
          dispatch(
          sectionDataStructure({
            projectIdent: scope.projectIdent,
            ident: current.ident,
            language: scope.projectLanguageCode,
            report: chapter.customType,
          })
        );
      } else if (current.type === "sub_section") {
        dispatch(
          getSubSectionDataStructure({
            projectIdent: scope.projectIdent,
            ident: current.ident,
            language: scope.projectLanguageCode,
            report: chapter.customType,
          })
        );
      }
    }
  }, [current, dispatch, scope, chapterData.ident]);

  useEffect(() => {
    if (!!current) {
      if (
        (current.type === "chapter" &&
          chapter.savingStatus.type === "SUCCESS") ||
        (current.type === "non-chapter" &&
          chapter.savingStatus.type === "SUCCESS")
      ) {
        dispatch(
          chapterDataStructure({
            projectIdent: scope.projectIdent,
            ident: current.ident,
            language: scope.projectLanguageCode,
            report: chapter.customType,
          })
        );
      } else if (
        current.type === "section" &&
        chapter.savingStatus.type === "SUCCESS"
      ) {
        dispatch(
          sectionDataStructure({
            projectIdent: scope.projectIdent,
            ident: current.ident,
            language: scope.projectLanguageCode,
            report: chapter.customType,
          })
        );
      } else if (
        current.type === "sub_section" &&
        chapter.savingStatus.type === "SUCCESS"
      ) {
        dispatch(
          getSubSectionDataStructure({
            projectIdent: scope.projectIdent,
            ident: current.ident,
            language: scope.projectLanguageCode,
            report: chapter.customType,
          })
        );
      }
    }
  }, [current, dispatch, scope, chapter.customType, chapter.savingStatus.type]);
  useEffect(() => {
    if (chapterState.type === "SUCCESS" && !!chapterState.guruDto) {
      setCurrentChapterDatas(chapterState.guruDto);
      dispatch(resetState());
    } else if (sectionState.type === "SUCCESS" && !!sectionState.guruDto) {
      setCurrentChapterDatas(sectionState.guruDto);
      dispatch(resetState());
    } else if (
      subSectionState.type === "SUCCESS" &&
      !!subSectionState.guruDto
    ) {
      setCurrentChapterDatas(subSectionState.guruDto);
      dispatch(resetState());
    }
  }, [chapterState, dispatch, sectionState, subSectionState]);

  useEffect(() => {
    if (!!currentChapterDatas && currentChapterDatas.data.node === "CHAPTER") {
      const temp = [];
      if (!!currentChapterDatas.sections[0]) {
        const section = currentChapterDatas.sections[0];
        if (section.subSections.length > 0) {
          section.subSections[0].structureList.forEach((element, index) => {
            if (element.node === "report_images" || element.node === "location_map") {
              let nodeName = element.node === "location_map" ? "map" : "images"
              let obj = temp.find((f) => f.option.key === element.parentBox);
              if (!!obj) {
                obj.option.children.push(element);
              } else {
                temp.push({
                  option: {
                    node: nodeName,
                    key: element.parentBox,
                    order: element.order,
                    ident: element.ident,
                    children: [element],
                    index: index,
                  },
                  data: {},
                  ref: React.createRef(),
                  currentData: {
                    node: nodeName,
                    key: element.parentBox,
                    order: element.order,
                    ident: element.ident,
                    children: [element],
                    index: index,
                  },
                });
              }
            } else {
              temp.push({
                option: {
                  node: element.node,
                  key: uuidv4(),
                  order: element.order,
                  ident: element.ident,
                  index: index,
                },
                ref: React.createRef(),
                data: { ...element },
                currentData: {
                  node: element.node,
                  key: element.parentBox,
                  order: element.order,
                  ident: element.ident,
                  children: [element],
                  index: index,
                  ...element,
                },
              });
            }
          });
          setDroppedOption(
            temp.sort(function (a, b) {
              return a.order - b.order;
            })
          );
          if (section.subSections[0].data.name.startsWith("default_")) {
            setChildName(section.data.name);
          } else {
            setChildName(section.subSections[0].data.name);
          }
        } else {
          section.structureList.forEach((element, index) => {
            if (element.node === "report_images" || element.node === "location_map") {
              let nodeName = element.node === "location_map" ? "map" : "images"
              let obj = temp.find((f) => f.option.key === element.parentBox);
              if (!!obj) {
                obj.option.children.push(element);
              } else {
                temp.push({
                  option: {
                    node: nodeName,
                    key: element.parentBox,
                    order: element.order,
                    ident: element.ident,
                    children: [element],
                    index: index,
                  },
                  data: {},
                  ref: React.createRef(),
                  currentData: {
                    node: nodeName,
                    key: element.parentBox,
                    order: element.order,
                    ident: element.ident,
                    children: [element],
                    index: index,
                  },
                });
              }
            } else {
              temp.push({
                option: {
                  node: element.node,
                  key: uuidv4(),
                  order: element.order,
                  ident: element.ident,
                  index: index,
                },
                ref: React.createRef(),
                data: { ...element },
                currentData: {
                  node: element.node,
                  key: uuidv4(),
                  order: element.order,
                  ident: element.ident,
                  index: index,
                  ...element,
                },
              });
            }
          });
          setDroppedOption(
            temp.sort(function (a, b) {
              return a.order - b.order;
            })
          );
          if (section.data.name.startsWith("default_")) {
            setChildName(currentChapterDatas.data.name);
          } else {
            setChildName(section.data.name);
          }
        }
      } else {
        temp.push({
          option: {
            node: "structureEditable",
            key: uuidv4(),
            order: currentChapterDatas.data.order,
            ident: currentChapterDatas.data.ident,
          },
          ref: React.createRef(),
          data: {},
        });
        setDroppedOption(
          temp.sort(function (a, b) {
            return a.order - b.order;
          })
        );
        setChildName(currentChapterDatas.data.name);
      }
    }
  }, [currentChapterDatas]);

  useEffect(() => {
    setDroppedOption([]);
    setDisplaySaveBtn(false);
  }, [current, chapterData, section, subSection]);

  useEffect(() => {
    if (isdragging) setDragStrat(true);
    if (!isdragging) setDragStrat(false);
    return () => {};
  }, [isdragging]);

  useEffect(() => {
    if (droppedOption.length > 0) {
      setDisplaySaveBtn(true);
    } else {
      setDisplaySaveBtn(false);
    }
    if (option.length) {
      let temp = droppedOption.map((m) => {
        return {
          ...m,
          currentData: m.ref.current.getNode(),
        };
      });
      let newDropped = option.find(
        (f) => !temp.find((d) => d.option.key === f.key)
      );
      if (!!newDropped) {
        var droppedNode = {
          option: { ...newDropped, index: droppedOption.length },
          data: {},
          currentData: { ...newDropped, index: droppedOption.length },
          ref: React.createRef(),
        };
        setDroppedOption([...temp, droppedNode]);
      }
    }
  }, [option, droppedOption]);

  const handleOnDragEnd = (result) => {
    setDragStrat(false);
    if (!result.destination) return;
    const items = Array.from(droppedOption);
    const reorderedItem = items.splice(result.source.index, 1);
    const reorderedObject = reorderedItem[0];
    items.splice(result.destination.index, 0, {
      ...reorderedObject,
      currentData: reorderedObject.ref.current.getNode(),
    });
    setDroppedOption(
      items.map((m) => ({ ...m, currentData: m.ref.current.getNode() }))
    );
  };

  const handleStart = (result) => {
    setDragStrat(true);
  };

  const handleRemoveOption = (node, data) => {
    const newData = droppedOption.filter((el) => el.option.key !== node.key);
    let selectedSection = getSelectedSection();
    let customStructure = {};
    if (newData.length > 0) {
      setDisplaySaveBtn(true);
    } else {
      setDisplaySaveBtn(false);
    }
    if (typeof remove === "function") {
      if ((node.node === "images" || node.node ==="map") && !!node.children.length) {
        customStructure = {
          type: "custom-structure",
          ident: selectedSection.ident,
          parentIdent: selectedSection.cident,
          reportType: chapter.customType,
          structureList: node.children.map((m) => {
            return {
              type: "structure-node",
              ident: m.ident,
            };
          }),
        };
        if (!!Object.keys(customStructure).length) {
          if (selectedSection.type === "sub_section") {
            dispatch(
              deleteSubSectionStructure(customStructure, scope.projectIdent)
            );
          } else {
            dispatch(
              deleteSectionStructure(customStructure, scope.projectIdent)
            );
          }
        }
      } else if ((!!data && !!Object.keys(data).length) || !!node.ident) {
        customStructure = {
          type: "custom-structure",
          ident: selectedSection.ident,
          parentIdent: selectedSection.cident,
          reportType: chapter.customType,
          structureList: [
            {
              type: "structure-node",
              ident: data.ident,
            },
          ],
        };
        if (!!Object.keys(customStructure).length) {
          if (selectedSection.type === "sub_section") {
            dispatch(
              deleteSubSectionStructure(customStructure, scope.projectIdent)
            );
          } else {
            dispatch(
              deleteSectionStructure(customStructure, scope.projectIdent)
            );
          }
        }
      } else {
        remove(node);
      }
      setDroppedOption(newData);
    }
  };

  const getSelectedSection = () => {
    let selectedSection = current;
    if (current.type === "chapter") {
      const defaultSection = current.children.find((f) =>
        f.name.startsWith("default_")
      );
      if (!!defaultSection) {
        selectedSection = {
          ident: defaultSection.ident,
          cident: defaultSection.cident,
        };
      }
    }
    return selectedSection;
  };

  const handleStructureSave = () => {
    let temp = [];
    let inc = 0;
    let isLabel = false;
    let pkey = null;
    let selectedSection = getSelectedSection();
    droppedOption.forEach((m) => {
      let currentIndex = inc + 1;
      let current = m.ref.current.getNode();
      if (current.node === "property-label") {
        isLabel = true;
        pkey = pkey === null ? uuidv4() : pkey;
      } else {
        isLabel = false;
        pkey = null;
      }
      if (current.node === "text") {
        temp.push({
          type: "structure-text",
          userDefined: current.userDefined,
          defaultText: current.defaultText,
          ident: current?.ident ? current?.ident : "",
          editable: "",
          order: currentIndex,
        });
        inc = inc + 1;
      } else if (current.node === "table") {
        temp.push({
          type: "structure-table",
          ident: current?.ident ? current?.ident : "",
          order: currentIndex,
        });
        inc = inc + 1;
      } else if (current.node === "images") {
        if (!!current.children.length) {
          current.children.forEach((element) => {
            temp.push({
              type: "structure-image-box",
              ident: element.ident,
              order: currentIndex,
              parentBox: element.parentBox,
            });
          });
        } else {
          temp.push({
            type: "structure-image-box",
            ident: "",
            order: currentIndex,
            parentBox: current.key,
          });
          temp.push({
            type: "structure-image-box",
            ident: "",
            order: currentIndex,
            parentBox: current.key,
          });
        }
        inc = inc + 1;
      }  else if (current.node === "map") {
        if (!!current.children.length) {
          current.children.forEach((element) => {
            temp.push({
              type: "structure-map",
              ident: element.ident,
              order: currentIndex,
              parentBox: element.parentBox,
            });
          });
        } else {
          temp.push({
            type: "structure-map",
            ident: "",
            order: currentIndex,
            parentBox: current.key,
          });
          temp.push({
            type: "structure-map",
            ident: "",
            order: currentIndex,
            parentBox: current.key,
          });
        }
        inc = inc + 1;
      } else if (current.node === "property-label" && current.nameTranslation.length > 0) {
        temp.push({
          type: "structure-property-label",
          userDefined: current.userDefined,
          defaultText: current.defaultText,
          ident: current?.ident ? current?.ident : "",
          order: currentIndex,
          nameTranslation: current.nameTranslation,
          name: current.nameTranslation[0].value,
          parentBox: isLabel ? pkey : null,
          editable: "",
        });
        inc = inc + 1;
      }
    });
    const customStructure = {
      type: "custom-structure",
      ident: selectedSection.ident,
      parentIdent: selectedSection.cident,
      reportType: chapter.customType,
      structureList: temp,
    };
    if (selectedSection.type === "sub_section") {
      dispatch(saveSubSectionStructure(customStructure, scope.projectIdent));
    } else {
      dispatch(saveSectionStructure(customStructure, scope.projectIdent));
    }
  };

  const renderCustomOptions = () => {
    return (
      <>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: dragStart ? "5px solid blue" : "0px",
            borderStyle: "dashed",
            
          }}
          style={{
            backgroundColor: dragStart ? "#8bb4f7" : "white",
            marginTop: "20px",
            height: "100%",
          }}
        >
          <DragDropContext
            onDragEnd={handleOnDragEnd}
            onDragStart={handleStart}
          >
            <Droppable droppableId="droppable_custom">
              {(provided) => (
                <List
                  className="charaters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{
                    width: "80%",
                  }}
                >
                  {droppedOption.map((element, index) => {
                    const key = element.option?.key + "_" + index;
                    return (
                      <Draggable key={key} draggableId={key} index={index}>
                        {(provided) => {
                          if (element.option.node === "text") {
                            return (
                              <TextInput
                                provided={provided}
                                node={element.option}
                                data={element.data}
                                ref={element.ref}
                                currentData={element.currentData}
                                remove={(node, data) =>
                                  handleRemoveOption(node, data)
                                }
                              />
                            );
                          } else if (element.option.node === "map") {
                            return (
                              <Maps
                                provided={provided}
                                node={element.option}
                                ref={element.ref}
                                data={element.data}
                                remove={(node, data) =>
                                  handleRemoveOption(node, data)
                                }
                              />
                            );
                          } else if (element.option.node === "images") {
                            return (
                              <Image
                                provided={provided}
                                node={element.option}
                                ref={element.ref}
                                data={element.data}
                                remove={(node, data) =>
                                  handleRemoveOption(node, data)
                                }
                              />
                            );
                          } else if (element.option.node === "property-label") {
                            return (
                              <TextEditorLabel
                                provided={provided}
                                node={element.option}
                                data={element.data}
                                ref={element.ref}
                                currentData={element.currentData}
                                remove={(node, data) =>
                                  handleRemoveOption(node, data)
                                }
                              />
                            );
                          } else if (element.option.node === "table") {
                            return (
                              <Table
                                provided={provided}
                                node={element.option}
                                data={element.data}
                                ref={element.ref}
                                remove={(node, data) =>
                                  handleRemoveOption(node, data)
                                }
                              />
                            );
                          } else if (
                            element.option.node === "structureEditable"
                          ) {
                            return (
                              <Box style={{ marginTop: "100px" }}>
                                <Typography variant="h5">
                                  This chapter is not editable you will not be
                                  able to customize it because it is standard.
                                </Typography>
                              </Box>
                            );
                          }
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </>
    );
  };
  const report = chapter.customType
  const info = { current, chapterData, section, subSection,report };
  return (
    <>
      {!!current.name && (
        <Box id="custom_container" sx={CustomStyle} className={"element d-flex flex-column"}>
          <RenderCustomStructure
            info={info}
            selectedChapter={currentChapterDatas}
          />
           <Divider
               flexItem
               style={{
                 margin: "0 5px",
               }}
             >
               <Typography style={{ marginLeft: "5px", color:"#d08f8e", fontWeight:"bold" }}>
                {getIntlTranslation("_existing-structure")}
              </Typography>
             </Divider>
          <Box style={{ height:"80%", overflowY:"auto",overflowX:"hidden" }} className={"custom-scrollBar"}>
          {renderCustomOptions()}
          </Box>
          <Box style={{ marginTop:"auto"}}>
          {displaySaveBtn && current?.structureEditable === undefined && (
              <SaveButton
                style={{ margin:5, width:"100%" }}
                variant="contained"
                id="save-custom-item"
                handleClick={(e) => handleStructureSave()}
                textVariant="_save"
                tooltip={
                  {
                    title:"tooltip.save"
                  }
                }
              />
            )}
            </Box>
        </Box>
      )}
    </>
  );
};

export default CustomBox;
