import { useSnackbar } from "notistack";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchComparisonProjectDescription,
  fetchProjectDescription,
  saveProjectDescription,
  updateProjectDescription,
} from "../../../api/monitoringProjectDescriptionApi";
import { getFetchSuccess, getSaveSuccess, onSelectReportVersion, resetState, setCurrentVersion, setUpdatedRows, setVersions } from "../projectDescriptionSlice";
import {
  resetPromptState,
} from "../../../projectmonitoringModuleSlice";
import { ListItem, ListItemText } from "@mui/material";
import React from "react";
import { List } from "reactstrap";
import { getTranslationVariantText } from "../../../../../utils/helper";
import { response } from "../../../../../utils/defaultValues";
import { ComparisonModeProvider } from "./ComparisonModeContext";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import { setOpenAsset } from "../../../../component-bundle/assetSwitcher/OpenAssetSlice";
import { RouterPrompt } from "../../../../../components/prompt/RouterPrompt";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
export const ProjectDescriptionContext = createContext(undefined);

export const useProjectDescriptionContext = () => {
  const context = useContext(ProjectDescriptionContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

export const ProjectDescriptionContextProvider = ({
  moduleApi,
  children
}) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.projectDescription);
  const openAsset = useSelector((state) => state.openAsset);
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const [loading, setLoading] = useState(true);
  const { assetList } = useAssetListHook();
  const [assets, setAssets] = useState({
    isAssigned: false,
    data: [],
  });
  const [subAssetList, setSubAssetList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [blockAssetChange, setBlockAssetChange] = useState({
    block: false,
    newAsset: {},
  });
  const [descriptionText, setDescriptionText] = useState({});
  const readOnlystate = useSelector((state) => state.readOnly);
  const [isCopy, setIsCopy] = useState({
    copy:false,
    text:"",
  })

  useEffect(() => {
    if (!!openAsset.ident){
      setDescriptionText({})
      setSubAssetList([]);
      dispatch(resetState())
      dispatch(setCurrentVersion({}));
      setAssets({
        isAssigned: false,
        data: [],
      });
    }
  }, [dispatch, openAsset.ident])

  useEffect(() => {
    if (!!openAsset.ident && assetList.isFetchSuccess) {
      const list = assetList.assets.map((m) => {
        return {
          ...m,
          address: { ...m.addressDto },
          reportVersion: [
            ...m.reportVersionDtos.map((rv) => {
              return { ...rv, label: rv.versionName, value: rv.ident };
            }),
          ],
        };
      });

      let found = list.find(
        (f) =>
          f.assetIdent === openAsset.ident &&
          scope?.projectIdent === openAsset?.projectIdent
      );
      let currentversion = found.reportVersion.find((f) => f.current);
      if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
        currentversion = found.reportVersion.find(
          (f) => f.ident === tempVersion.reportVersionIdent
        );
      }
      let temp = [
        ...found?.subAssets.map((m) => m).sort((a, b) => b.general - a.general),
      ];
      setSubAssetList(temp);
      dispatch(setCurrentVersion(currentversion || {}));
      dispatch(onSelectReportVersion(null));
      dispatch(setVersions(found.reportVersion));
      setAssets({
        isAssigned: true,
        data: list,
      });
    }
  }, [assetList.assets, assetList.isFetchSuccess, dispatch, openAsset.ident, openAsset?.projectIdent, scope?.projectIdent, tempVersion.assetIdent, tempVersion.isTemp, tempVersion.reportVersionIdent]);

  const fetchProps = useCallback(() => {
    if(!!state.currentVersion &&  !!state.currentVersion.ident){
      dispatch(
        fetchProjectDescription({
          api: scope.api,
          moduleApi:moduleApi,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          versionIdent: state.currentVersion.ident,
        })
      );
  }
  }, [dispatch, moduleApi, openAsset.ident, scope.api, scope.projectIdent, state.currentVersion]);

  useEffect(() => {
    if (assets.isAssigned && !!state.currentVersion) {
      fetchProps();
    }
  }, [
    assets.isAssigned,
    state.currentVersion,
    fetchProps,
  ]);

  useEffect(() => {
    if (state.fetch.type === "FETCHED") {
      setDescriptionText(state.fetch.data || {});
      dispatch(getFetchSuccess({ ...response,guruDto:{} }));
    }
    if (state.save.type === "SAVED" || state.save.type === "UPDATED") {
      state.save.messages.forEach((element) =>
        enqueueSnackbar(element, { variant: "success", autoHideDuration: 2000 })
      );
      dispatch(setUpdatedRows([]));
      dispatch(getSaveSuccess({ ...response,guruDto:{} }));
      dispatch(resetPromptState());
      fetchProps();
    }
    return () => {
      setLoading(false);
    };
  }, [dispatch, enqueueSnackbar, fetchProps, state.fetch.data, state.fetch.type, state.save.messages, state.save.type]);


  const onFetchCompareProperties = useCallback((versionIdent) => {
    dispatch(
      fetchComparisonProjectDescription({
        api: scope.api,
        moduleApi:moduleApi,
        projectIdent: scope.projectIdent,
        assetIdent: openAsset.ident,
        versionIdent: versionIdent,
      })
    );
  },[dispatch, moduleApi, openAsset.ident, scope.api, scope.projectIdent]);


  const onCopy = (copiedObj) => {
    setIsCopy({
      copy:true,
      text:copiedObj.description
    })
  }

  const onSave = (value, isUpdate) => {
    if (isUpdate) {
      const data = {
        value: value,
        request: {
          moduleApi:moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          ident: value.ident,
        },
      };
      dispatch(updateProjectDescription(data));
      dispatch(resetPromptState());
      setLoading(true);
    } else {
      const data = {
        value: value,
        request: {
          moduleApi:moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          versionIdent: state.currentVersion.ident,
        },
      };
      dispatch(saveProjectDescription(data));
      dispatch(resetPromptState());
      setLoading(true);
    }
  };

  const isDataUpdated = useCallback(
    () => state.updatedRows.filter((f) => f.updated).length > 0,
    [state.updatedRows]
  );


  const markDataUpdated = useCallback((isUpdate) => {
    dispatch(
      setUpdatedRows([
        {
          updated: isUpdate,
        },
      ])
    );
  },[dispatch]);

  const getWarningText = useCallback(() => {
    let updatedRows = state.updatedRows.filter((f) => f.updated);
    return (
      <>
        {updatedRows.length > 0 ? (
          <>
            {getTranslationVariantText("_unsaved changes", "subtitle2")}
            <List>
              {updatedRows?.map((el, index) => {
                return (
                  <ListItem key={index} style={{ padding: 0 }}>
                    <ListItemText
                      id={`${index}`}
                      primary={getTranslationVariantText(
                        "menu.project-description",
                        "subtitle2",
                        { fontWeight: "bold" }
                      )}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : null}
      </>
    );
  }, [state.updatedRows]);


  return (
    <ProjectDescriptionContext.Provider
      value={{
        openAsset,
        loading,
        descriptionText,
        blockAssetChange,
        tempVersion,
        isCopy,
        setIsCopy,
        onFetchCompareProperties,
        markDataUpdated,
        isDataUpdated,
        setBlockAssetChange,
        onSave,
        onCopy,
      }}
    >
      <ComparisonModeProvider>
      {children}
      </ComparisonModeProvider>
      {!readOnlystate.isReadOnly && blockAssetChange.block && (
        <InternalPrompt
          when={blockAssetChange.block}
          stayText={"_back"}
          saveText="Save And Leave"
          leaveText={"_continue"}
          onLeave={() => {
            dispatch(resetState());
            dispatch(setOpenAsset(blockAssetChange.newAsset));
            setBlockAssetChange({
              block: false,
              newAsset: {},
            });
          }}
          onStay={() =>
            setBlockAssetChange({
              block: false,
              newAsset: {},
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText()}
        />
      )}
      {!readOnlystate.isReadOnly && isDataUpdated() && (
        <RouterPrompt
          when={isDataUpdated()}
          cancelText="_back"
          okText="_continue"
          warningHeader={"Save And Leave"}
          onOK={() => dispatch(resetPromptState())}
          onCancel={() => false}
          message={getWarningText()}
        />
      )}
    </ProjectDescriptionContext.Provider>
  );
};
