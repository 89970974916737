import { Box, Checkbox, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getIntlTranslation } from '../../../../utils/helper';

export const TextInputWithCheckBox = ({ id, key, disabled=false, node, handleChange}) => {
  const [value, setValue] = useState(node.value || "Nicht verfügbar / Not available");
  const [checked, setChecked] = useState(node.value?.length);

  const handleCheckboxChange = (event) => {
    if(!event.target.checked){
        setChecked(event.target.checked);
        setValue("Nicht verfügbar / Not available");
    }else{
        setChecked(event.target.checked);
        setValue(" ");
    }
  };

  return (
    <Box className="d-flex">
          <TextField
            fullWidth
            id={id}
            disabled={disabled}
            key={key}
            value={value}
            type={"text"}
            multiline
            minRows={2}
            maxRows={5}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => {
              if(value !== node.value){
                handleChange(value, node, true);
              }
            }
          }
            variant="outlined"
            size="small"
            dense="true"
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      className="p-1"
                    />
                  </InputAdornment>
                ),
                className: "align-items-baseline pt-2",
              }}
          />
        </Box>
  )
}

export default TextInputWithCheckBox
