import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { getFireBaseCustomToken } from '../api/authApi';
import { useDispatch } from 'react-redux';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('fToken'));
    const dispatch = useDispatch();

    useEffect(() => {
        const auth = getAuth();
        if (token) {
            signInWithFirebase(token);
        }
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                dispatch(getFireBaseCustomToken())
            }
        });

        return () => unsubscribe();
    }, [token]);

    const signInWithFirebase = (token) => {
        signInWithCustomToken(getAuth(), token)
            .catch(error => {
                console.error("Error signing in with Firebase custom token:", error);
                setUser(null);
                localStorage.removeItem('fToken'); 
            });
    };

    

    return (
        <AuthContext.Provider value={{ user}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
