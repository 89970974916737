import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import { IconButton, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { createDefectsWithExcel } from "../../../../../api/defectOverviewApi";
import { fetchAssetList } from "../../../../../api/assetOverviewApi";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { DialogActions, DialogContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getDialogBoxHeader } from "../../../../../utils/helper";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { resetSliceState } from "../defectSlice";

const useStyles = styled((theme) => ({
  root: {
    width: "40%",
  },
  proot: {
    width: "80%",
  },
  appBar: {
    position: "sticky",
    zIndex: "0",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  backdrop2: {
    zIndex: 1500,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateMultipleDefects = (props) => {
  const { open, onClose, projectInfo, data } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const createMulti = useSelector((state) => state.tddDefectSection.multiUpdate);
  const scope = useSelector((state) => state.container.scope);
  const [openDialog, setOpenDialog] = useState(open);
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    if (data.length > 0) {
      const extractCols = Object.values(data)[0];
      const temp = Object.keys(extractCols).map((e) => {
        return { field: e, headerName: e, flex: 0.5, minWidth: 90 };
      });
      setColumns(temp);
      const rowsValue = Object.values(data);
      const rowsWithId = rowsValue.map((e, index) => {
        return { ...e, id: index };
      });
      setRows(rowsWithId);
    } else {
      enqueueSnackbar("You can't upload an empty asset file", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [data, enqueueSnackbar]);

  useEffect(() => {
    if (!!createMulti && createMulti.type === "SUCCESS") {
      setOpenDialog(false);
      setLoader(false);
      createMulti.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      if (typeof onClose === "function") {
        onClose();
      }
      props.needGridRefresh()
      dispatch(resetSliceState());
    }
  }, [createMulti, dispatch, enqueueSnackbar, onClose, scope]);

  const handleClose = () => {
    setOpenDialog(false);
    setLoader(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const createDefects = () => {
    setLoader(true);
    const excelRows = rows.map((e) => {
      return { ...e, type: "defectExcelDto" };
    });
    dispatch(
      createDefectsWithExcel({
        excelRows: excelRows,
        projectIdent: projectInfo.projectIdent,
        assetIdent: projectInfo.assetIdent,
        subAssetIdent: projectInfo.subAssetIdent,
      })
    );
  };

  return (
    <>
      {!!rows && (
        <>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            fullWidth={true}
            fullScreen
            TransitionComponent={Transition}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <AppBar className="appBar">
              <Toolbar className={"dialog-toolbar"}>
                {getDialogBoxHeader("_Create Asset")}
                <IconButton
                  edge="start"
                  color="inherit"
                  id="create_mutliple_asset_close"
                  onClick={handleClose}
                  aria-label="close"
                  style={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <DataGrid
                style={{ height: "100%" }}
                rows={rows}
                columns={columns}
                disableColumnResize={false}
              />
            </DialogContent>
            <DialogActions>
              <SaveButton
                id="save-asset-01"
                buttonText={"_save"}
                style={{
                  margin: 5,
                  backgroundColor: "#d9534f",
                  color: "#ffffff",
                  height: 40,
                }}
                variant="button"
                tooltip={{
                  title: "_save",
                }}
                handleClick={createDefects}
              />
            </DialogActions>
          </Dialog>
          {loader && (
            <Backdrop className={classes.backdrop2} open={loader}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </>
      )}
    </>
  );
};

export default CreateMultipleDefects;
