import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  Drawer,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";

import { useSelector } from "react-redux";
import {
  getVariantText,
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import { useSelectedProperty } from "../context/SelectedPropertyContext";
import { useKeyFindingsContext } from "../context/KeyFindingsContext";
import { useKeyFindingsPropertyContext } from "../context/KeyFindingsPropertyContext";
import CustomButton from "../../../../../components/buttons/CustomButton";
import RenderPropertyDataCard from "./RenderPropertyDataCard";
import { useComparisonMode } from "../context/ComparisonModeContext";
import { onSelectReportVersion } from "../keyFindingsSlice";
import { useDispatch } from "react-redux";
import KeyFindingsCopyDialog from "./KeyFindingsCopyDialog";

export const getBorderColor = (p) => {
  if (p) {
    return {
      borderLeft: "4px solid rgb(211, 0, 56)",
      backgroundColor: "rgba(255, 42, 81, 0.19)",
    };
  } else {
    return {};
  }
};

const PropertyListItem = ({ id, node, onCopy }) => {
  return (
    <ListItem key={id} divider className={"drag-item p-1 d-flex flex-column"}>
      <Box className={`d-flex w-100 align-items-center justify-content-around`}>
        <ListItemText
          className="w-75"
          primary={
            <div
              className="d-flex flex-wrap align-items-center justify-content-start p-1"
              style={{
                width: "100%",
                cursor: "pointer",
              }}
            >
              {getVariantText(node.nodeTitle, "caption", {
                fontWeight: "bold",
                color: "#254a9a",
                marginLeft: 2,
                textTransform: "uppercase",
              })}
            </div>
          }s
        />
        {Object.keys(node.mapProperties).length > 0 &&  (
                      <Box className="w-25">
                        <CustomButton
                          id={`copy-${node.nodeIdent}`}
                          buttonText={"_copy"}
                          style={{
                            color: "#254a9a",
                            height: 30,
                          }}
                          className="btn g-btn-secondary mx-1 g-btn-elevated w-100 p-1"
                          tooltip={{
                            title: getIntlTranslation("tooltip.copy"),
                          }}
                          handleClick={(e) =>
                            onCopy({
                              open: true,
                              selected: {...node}
                            })
                          }
                        />
                      </Box>
                    )}
      </Box>
      <Box className="w-100 p-1">
        {Object.keys(node.mapProperties).length <= 0 && (
          <Box className="w-100 d-flex align-items-center justify-content-around h-100 g-btn-secondary rounded-3">
            {getTranslationVariantText(
              "_no-data",
              "subtitle 2",
              { fontSize: 14 },
              "p-1  mx-auto g-btn-secondary rounded-3"
            )}
          </Box>
        )}
        <RenderPropertyDataCard
          data={node.original}
          isCompare={true}
        />
      </Box>
    </ListItem>
  );
};
export const KeyFindingsComparisionContainer = () => {
  const {
    loading,
    versionSelected,
    structuredCompareCategory,
    compareCategory,
  } = useComparisonMode();
  const state = useSelector((state) => state.keyFindings);
  const monitoringState = useSelector((state) => state.projectMonitoring);
  const { subAssetList } = useKeyFindingsContext();
  const { onSave, isSaveSuccessFull, setIsSaveSuccessFull } = useKeyFindingsPropertyContext();
  const { selectedCategory } = useSelectedProperty();
  const dispatch = useDispatch();
  const [comparingProperty, setComparingProperty] = useState({});
  const [edit, setEdit] = useState({
    open: false,
    selected: null,
  });

  useEffect(() => {
    if (
      selectedCategory &&
      !!structuredCompareCategory.nodes &&
      Object.keys(structuredCompareCategory.nodes).length > 0
    ) {
      const tempCat =
        structuredCompareCategory.nodes[selectedCategory.nodeIdent];
      if (!!tempCat) {
        setComparingProperty({ ...tempCat.properties });
      } else {
        setComparingProperty({});
      }
    }
  }, [selectedCategory, structuredCompareCategory]);

  useEffect(() => {
    if(isSaveSuccessFull){
      setEdit({
        open: false,
        selected: null,
      })
      setIsSaveSuccessFull(false);
    }
  }, [isSaveSuccessFull, setIsSaveSuccessFull])
  

  const items = useMemo(
    () => Object.keys(comparingProperty).map((key) => comparingProperty[key]),
    [comparingProperty]
  );

  return (
    <>
    {monitoringState.isCompareMode && (
      <Box
        style={{ width: monitoringState.isCompareMode ? "49%" : "0%", height: "100%" }}
        className="mx-1"
      >
        {loading && (
          <Box className="w-100">
            <LinearProgress />
          </Box>
        )}
      <Card className="w-100 h-100">
        <CardContent
          className="w-100 overflow-y-auto overflow-x-hidden custom-scrollBar p-0"
          style={{ height: "calc(100% - 60px" }}
        >
          <List className="p-1">
            {items.map((item) => (
              <PropertyListItem
                key={item.nodeIdent}
                id={item.nodeIdent}
                node={item}
                onCopy={(val) =>setEdit({...val})}
              />
            ))}
          </List>
        </CardContent>
        {!!versionSelected && state.versions.length > 0 && (
          <CardActions
            className="p-0 w-100 w-100 mt-auto"
            style={{ height: 50, backgroundColor: "#c0c0c0" }}
          >
            {getTranslationVariantText(
              "_compare",
              "body1",
              {
                fontWeight: "bold",
                color: "#254a9a",
              },
              "w-25 p-1"
            )}
            <Autocomplete
              className="p-1 w-75"
              id="rerport_version"
              value={versionSelected.label}
              options={state.versions.filter(
                (f) => f.ident !== state.currentVersion.ident
              )}
              isOptionEqualToValue={(option, value) =>
                option.ident === value.ident
              }
              onChange={(e, newValue) => {
                dispatch(onSelectReportVersion(newValue));
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={getLabel("version")}
                  size="small"
                  style={{ color: "#ffffff", width: "100%" }}
                />
              )}
            />
          </CardActions>
        )}
      </Card>
      {edit.open && (
        <Drawer
          anchor={"right"}
          open={edit.open}
          style={{ borderRadius: 4 }}
          className="w-100"
        >
          <Box style={{ width: 700 }} className="h-100" role="presentation">
            <KeyFindingsCopyDialog
              open={edit.open}
              subAssetList={subAssetList}
              onClose={() => {
                setEdit({
                  open: false,
                  selected: null,
                 
                });
              }}
              selectedProperty={edit.selected}
              onAdd={(rows) => onSave(rows, edit.selected)}
            />
          </Box>
        </Drawer>
      )}
      </Box>
    )}
  </>
);
};
