import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { getIntlTranslation, getLabel } from "../../../utils/helper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CostDataFilter = ({
  treeData,
  setTreeData,
  treeDataCopy,
  toggleExpandedForAll,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [filterCatData, setFilterCatData] = useState([]);
  const [seletedCategoryList, setSelectedCategoryList] = useState([]);

  useEffect(() => {
    // get all category from the copy lists
    if (treeDataCopy?.length > 0) {
      const data = treeDataCopy.map((category) => {
        const res = {
          id: category.id,
          name: category.title,
        };
        return res;
      });
      setCategoryList(data);
    } else {
      setCategoryList([]);
    }
  }, [treeDataCopy]);

  const filterDataWithCategoryList = (seletedCategoryList) => {
    if (!!seletedCategoryList.length) {
      var filteredArray = treeDataCopy.filter(function (array_el) {
        const filterSome = seletedCategoryList.some(function (anotherOne_el) {
          return anotherOne_el.id === array_el.id;
        });
        return filterSome;
      });
      setTreeData(filteredArray);
      setFilterCatData(filteredArray);
    } else {
      setTreeData(treeDataCopy);
      setFilterCatData([]);
    }
  };
  useEffect(() => {
    // filter data bass on chosed category list
    filterDataWithCategoryList(seletedCategoryList);
  }, [seletedCategoryList]);

  return (
    <Paper
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={3}
        sx={{ minWidth: 220, maxWidth: 500}}
        flex="1"
      >
        <Autocomplete
          multiple
          id="tags-outlined"
          size="small"
          options={categoryList}
          getOptionLabel={(option) => option.name}
          disableCloseOnSelect
          limitTags={2}
          value={seletedCategoryList}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={getIntlTranslation("_filter-category")}
              placeholder="category"
              size="small"
            />
          )}
          onChange={(event, newValue) => {
            setSelectedCategoryList(newValue);
          }}
        />
      </Stack>
    </Paper>
  );
};

export default CostDataFilter;
