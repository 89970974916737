import {
  AppBar,
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getDialogBoxHeader,
  getIntlTranslation,
  getLabel,
  getOverlineText,
} from "../../../utils/helper";
import AddIcon from "@mui/icons-material/Add";
import { CloseOutlined } from "@mui/icons-material";
import CustomButton from "../../../components/buttons/CustomButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PopoverInfo from "../../../components/popOver/PopoverInfo";
import PropTypes from "prop-types";
import areaInputImage from "../../../assets/images/areaInputImage.png";
import dataYearInputImage from "../../../assets/images/dataYearInputImage.jpeg";
import dateFuturInputImage from "../../../assets/images/dateFuturInputImage.jpeg";
import dateInputImage from "../../../assets/images/dateInputImage.jpeg";
import locationInputImage from "../../../assets/images/locationInputImage.jpeg";
import numberInputImage from "../../../assets/images/numberInputImage.jpeg";
import textEditorImage from "../../../assets/images/textEditor.jpeg";
import textInputImage from "../../../assets/images/textInputImage.jpeg";
import { useSelector } from "react-redux";

const propertyType = [
  {
    label: {
      en: "Text",
      de: "Text",
    },
    value: "text",
    message:
      "The Text type allows users to enter simple text input, such as their name, description, or a short message. It's a common input type used in forms, surveys, and other data collection applications.",
    image: textInputImage,
  },
  {
    label: {
      en: "Text-Editor",
      de: "Text-Editor",
    },
    value: "textarea",
    message:
      "The Text Editor type allows users to input, modify, and format text in various ways, such as bold, italic, underline, and font size. It's a more advanced input type than simple text and is commonly used in applications that require users to create and edit documents, articles, or blog posts.",
    image: textEditorImage,
  },
  {
    label: {
      en: "Number",
      de: "Nummer",
    },
    value: "number",
    message:
      "The Number type is used for capturing numerical input from the user. Users can enter whole numbers or decimal values.",
    image: numberInputImage,
  },
  {
    label: {
      en: "Location",
      de: "Adresse",
    },
    value: "address",
    message:
      "The Location type allows users to input a location by entering an address or by searching for it using Google Maps.",
    image: locationInputImage,
  },
  {
    label: { en: "Date", de: "Datum" },
    value: "date",
    message:
      "The Date type allows users to pick a specific date from a calendar, including the day, month, and year.",
    image: dateInputImage,
  },
  {
    label: { en: "Date (Year Only)", de: "Datum (nur Jahr)" },
    value: "date-year",
    message:
      "The Date (Year Only) type allows users to pick a specific year from a calendar",
    image: dataYearInputImage,
  },
  {
    label: { en: "Date (Future)", de: "Datum (Zukunft)" },
    value: "date-future",
    message:
      "The Date (Future) type allows users to pick a future date from a calendar, including the day, month, and year.",
    image: dateFuturInputImage,
  },
  {
    label: { en: "Area", de: "Area" },
    value: "area",
    message: "The Area type allows users to specify area in m².",
    image: areaInputImage,
  },
  {
    label: { en: "Parking", de: "Parkplatz" },
    value: "parking-number",
    message: "The Parking type allows users to specify parking fields.",
  },
  {
    label: { en: "Floor", de: "Stockwerk" },
    value: "floor-number",
    message: "The Floor type allows users to specify floor fields.",
  },
];
const AddOptionCustomProperties = ({
  open,
  handleClose,
  data,
  requiredProperties,
  onSave,
  currentReportversion,
}) => {
  const scope = useSelector((state) => state.container.scope);
  const [customProperties, setCustomProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);

  useEffect(() => {
    // Initialize customProperties with one empty object
    setCustomProperties([{ name: "", type: "text", id: Date.now() }]);
  }, []);

  const addCustomProperty = () => {
    setCustomProperties([
      ...customProperties,
      { name: "", type: "text", id: Date.now() },
    ]);
  };

  const removeCustomProperty = (item) => {
    setCustomProperties(customProperties.filter((prop) => prop.id !== item.id));
  };

  const updateCustomProperty = (value, item, label) => {
    setCustomProperties((prev) => {
      return prev.map((property, index) => {
        if (property.id === item.id) {
          return { ...property, [label]: value };
        }
        return property;
      });
    });
  };

  const togglePropertySelection = (value, e) => {
    setSelectedProperties((prev) => {
      if (e.target.checked) {
        return [
          ...prev,
          {
            ...value,
            required: true,
          },
        ];
      }
      return prev.filter((property) => property.nodeName !== value.nodeName);
    });
  };
  // Helper function to create a custom property object
  const createCustomProperty = (property, index) => ({
    type: "tddProperties",
    nodeIdent: "",
    propertyValue: "",
    propertyQuelle: "",
    nodeName: property.name.trim(),
    nodeType: property.type,
    nodeValue: [],
    nodeOrder: index + 1 + requiredProperties.length,
    custom: true,
    required: false,
    currentVersion: currentReportversion?.ident,
    labelTranslation: [
      {
        type: 'title',
        title: property.name,
        language: 'de',
        value: property.name,
      },
      {
        type: 'title',
        title: property.name,
        language: 'en',
        value: property.name,
      },
    ],
  });

  // Helper function to create an optional property object

  const saveAllProperties = () => {
    const customProps = customProperties
      .filter((property) => Boolean(property.name))
      .map(createCustomProperty);
    const createOptionalProperty = (property, index) => ({
      type: "tddProperties",
      nodeIdent: property.nodeIdent,
      nodeName: property.nodeName,
      nodeType: property.nodeType,
      nodeValue: [],
      custom: true,
      required: true,
      nodeOrder: index + 1 + requiredProperties.length + customProps.length,
      currentVersion: currentReportversion?.ident,
      labelTranslation: property.labelTranslation,
    });
    const optionalProps = selectedProperties.map(createOptionalProperty);
    const allProperties = [...customProps, ...optionalProps];
    if (!!customProps.length || !!selectedProperties.length) {
      onSave(allProperties);
    }
  };

  const getPropertyType = (property) => {
    if (property.nodeType === "textarea") {
      return "Text-Editor";
    } else if (property.nodeType === "text") {
      return "Text";
    } else if (property.nodeType === "address") {
      return "Location";
    } else if (property.nodeType === "date") {
      return "Date";
    } else if (property.nodeType === "area") {
      return "Area";
    } else if (property.nodeType === "number") {
      return "Number";
    } else if (property.nodeType === "boolean") {
      return "Check";
    } else if (property.nodeType === "date-year") {
      return "Date (Year Only)";
    } else if (property.nodeType === "date-future") {
      return "Date (Future)";
    }
    else if (property.nodeType === "parking-number"){
      return "Parking"
    }
    else if (property.nodeType === "floor-number"){
      return "Floor"
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"}>
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("_properties")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => handleClose()}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers className={"custom-scrollBar"}>
        <Card elevation={0}>
          <CardContent
            display="flex"
            flexDirection="column"
            style={{
              minWidth: "700px",
            }}
          >
            <Box>{getOverlineText("Add Custom Properties")}</Box>
            <List
              style={{
                padding: "0px",
                width: "100%",
              }}
            >
              {customProperties.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      style={{ minWidth: "250px", marginRight: "5px" }}
                      label={getLabel("_item_name")}
                      variant="standard"
                      value={item.name}
                      onChange={(e) =>
                        updateCustomProperty(e.target.value, item, "name")
                      }
                    />
                    <Autocomplete
                      id="custom properties type"
                      options={propertyType}
                      value={
                        !!item.type
                          ? propertyType?.find(
                              (f) =>
                                f.value === item.type &&
                                !!f.label[scope.projectLanguageCode]
                            )
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.label[scope.projectLanguageCode]
                      }
                      style={{ minWidth: "250px", margin: "5px" }}
                      onChange={(event, newValue) => {
                        if (!!newValue) {
                          updateCustomProperty(newValue.value, item, "type");
                        }
                      }}
                      renderOption={(props, option, { inputValue }) => {
                        return (
                          <ListItem
                            {...props}
                            
                          >
                            <Typography variant="body">
                              {option.label[scope.projectLanguageCode]}
                            </Typography>
                             
                          </ListItem>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={getLabel("_item_type")}
                          style={{ margin: 10 }}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                    <IconButton onClick={() => removeCustomProperty(item)}>
                      <DeleteForeverIcon style={{ color: "#f50057" }} />
                    </IconButton>
                  </ListItem>
                );
              })}
              <ListItem>
                <CustomButton
                  id={`add-optional-custom-button`}
                  buttonText={"_add-more"}
                  endIcon={
                    <AddIcon
                      style={{
                        color: "#d08f8e",
                        width: 20,
                        height: 20,
                      }}
                    />
                  }
                  variant="button"
                  className={"g-btn-secondary"}
                  style={{ margin: 5, height: 30 }}
                  tooltip={{
                    title: getIntlTranslation(
                      "add_custom_properties_placeholder"
                    ),
                  }}
                  handleClick={(e) => addCustomProperty()}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        {data?.length > 0 && (
          <Card className={"m-1"} elevation={1}>
            <CardContent>
              <Box style={{ marginTop: 10 }}>
                {getOverlineText("Optional Properties")}
                <List
                  className="d-flex flex-wrap"
                  sx={{ width: "100%", bgcolor: "background.paper" }}
                >
                  {data?.map((value) => {
                    const labelId = `checkbox-list-label-${value.nodeName}`;
                    return (
                      <ListItem key={value.nodeName} style={{ width: "25%" }}>
                        <ListItemIcon style={{ minWidth: "auto" }}>
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            checked={selectedProperties.some(
                              (v) =>
                                v.nodeName === value.nodeName &&
                                v.required === true
                            )}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(e) => togglePropertySelection(value, e)}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography variant="subtitle2">
                              {
                                value?.labelTranslation?.find(
                                  (e) =>
                                    e.language === scope.projectLanguageCode
                                ).title?.toUpperCase()
                              }{" "}
                              ({getPropertyType(value)})
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions className="w-100 d-flex flex-column align-items-center">
        <Box className="w-100">
          <List
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "left",
              backgroundColor: "#C1D0F0",
            }}
          >
            {propertyType.map((element, index) => (
              <ListItem
                style={{
                  width: "fit-content",
                }}
              >
                <PopoverInfo
                  key={element.label[scope.projectLanguageCode]}
                  label={element.label[scope.projectLanguageCode]}
                  message={element.message}
                  image={element.image}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            padding: "0px",
          }}
        >
          <CustomButton
            id="add-options-custom-button"
            buttonText="_save"
            variant="button"
            style={{ margin: 5 }}
            tooltip={{
              title: getIntlTranslation("tooltip.Save"),
            }}
            handleClick={saveAllProperties}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

AddOptionCustomProperties.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  currentReportversion: PropTypes.object,
};

export default AddOptionCustomProperties;
