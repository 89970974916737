import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../error/errorHandlingSlice";
import { getFailure,getProjectSuccess } from "../guru-suite/component-bundle/project-dashboard/projectDashboardSlice";
import { renewAccessTokenIfGoingExpire } from "./authApi";

export const getProject = (request) => async (dispatch) => {
  try {
     const tokenHeader = await renewAccessTokenIfGoingExpire();
     const response = await trackPromise(
      axios.get(`${request.api}/projects/project-open/${request.projectIdent}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if(response.data.type==='ERROR' || response.data.type==="ACCESS_DENIED" ){
      dispatch(getFailure(response.data));
    }else{
    dispatch(getProjectSuccess(response.data));
    }
    
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};

  export const deleteProject = (request) => async (dispatch) => {
  try {
     const tokenHeader = await renewAccessTokenIfGoingExpire();
     const response = await trackPromise(
      axios.get(`/projects/project-delete/${request.projectIdent}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if(response.data.type==='ERROR' || response.data.type==="ACCESS_DENIED" ){
      dispatch(getFailure(response.data));
    }else{
    dispatch(getProjectSuccess(response.data));
    }
    
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};

    export const markProjectComplete = (projectIdent) => async (dispatch) => {
  try {
     const tokenHeader = await renewAccessTokenIfGoingExpire();
     const response = await trackPromise(
      axios.get(`/projects/project-complete/${projectIdent}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if(response.data.type==='ERROR' || response.data.type==="ACCESS_DENIED" ){
      dispatch(getFailure(response.data));
    }else{
    dispatch(getProjectSuccess(response.data));
    }
    
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};



  export const updateProjectUsers = (request) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "post",
          url: `${request.api}/projects/update-users`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
          data: request.users
        })
      );
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getProjectSuccess(response.data));
      }
    } catch (error) {
     if (error.response || error.request){
       let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
  
     }
    }};