import Divider from "@mui/material/Divider";
import WarningIcon from "@mui/icons-material/Warning";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import { SelectColumn } from "react-data-grid";
import Box from "@mui/material/Box";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import Typography from "@mui/material/Typography";
import { covertInCurrencyFormat } from "../../../../../utils/helper";
import { getIntlTranslation } from "../../../../../utils/helper";
import InfoIcon from "@mui/icons-material/Info";
import RowOptionsMenu from "./RowOptionsMenu";

const costStyle = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  flexWrap: "wrap",
};

export const cellStyle = {
  height: "100%",
  width: "100%",
  whiteSpace: "pre-wrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  lineHeight: "normal",
  padding: 5,
};

export const getBorderColor = (p) => {
  if (p.row.updated && !p.row.deleted) {
    return "solid 5px #ff0000";
  } else if (p.row.deleted) {
    return "solid 5px #ff0000";
  } else {
    return "none";
  }
};

export const costColumnFormatter = (p, t) => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
        {p.row.costDefect && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <span style={costStyle}>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  {getIntlTranslation("_Defect-Type")}:&nbsp;
                </Typography>
                <Typography variant="body2">
                  {p.row.defectType?.name}
                </Typography>
              </span>
              <span style={costStyle}>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  {getIntlTranslation("_Priority-Type")}:&nbsp;
                </Typography>
                <Typography variant="body2">{p.row.priority?.name}</Typography>
              </span>
              <span style={costStyle}>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  {getIntlTranslation("_Price-per-Unit")}:&nbsp;
                </Typography>
                <Typography variant="body2">
                  {covertInCurrencyFormat(p.row.pricePerUnit)}
                </Typography>
              </span>
              <span style={costStyle}>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  {getIntlTranslation("_Unit")}:&nbsp;
                </Typography>
                <Typography variant="body2">{p.row.unit}</Typography>
              </span>
              <span style={costStyle}>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  {getIntlTranslation("_Quantity")}:&nbsp;
                </Typography>
                <Typography variant="body2">{p.row.quantity}</Typography>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <span style={costStyle}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    width: "50%",
                  }}
                  variant="body2"
                >
                  {getIntlTranslation("_Short-Term")}:&nbsp;
                </Typography>
                <Typography variant="body2">
                  {covertInCurrencyFormat(p.row.shortTermCost)}
                </Typography>
              </span>
              <span style={costStyle}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    width: "50%",
                  }}
                  variant="body2"
                >
                  {getIntlTranslation("_Medium-Term")}:&nbsp;
                </Typography>
                <Typography variant="body2">
                  {covertInCurrencyFormat(p.row.mediumTermCost)}
                </Typography>
              </span>
              <span style={costStyle}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    width: "50%",
                  }}
                  variant="body2"
                >
                  {getIntlTranslation("_Long-Term")}:&nbsp;
                </Typography>
                <Typography variant="body2">
                  {covertInCurrencyFormat(p.row.longTermCost)}
                </Typography>
              </span>
              <span style={{ display: "flex" }}>
                <Typography
                  style={{ fontWeight: "bold", width: "50%" }}
                  variant="body2"
                >
                  {getIntlTranslation("_Extra-Cost")}:&nbsp;
                </Typography>
                <Typography variant="body2">
                  {covertInCurrencyFormat(p.row.extraCost)}
                </Typography>
              </span>
              <span style={{ display: "flex" }}>
                <Typography
                  style={{ fontWeight: "bold", width: "50%" }}
                  variant="body2"
                >
                  {getIntlTranslation("_Total")}:&nbsp;
                </Typography>
                <Typography variant="body2">
                  {covertInCurrencyFormat(p.row.totalCost)}
                </Typography>
              </span>
            </div>
          </>
        )}
        {!p.row.costDefect && (
          <div style={{ display: "flex", flexDirection: "column", cursor: "pointer"  }}>
            <span style={costStyle}>
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                {getIntlTranslation("_Defect-Type")}:&nbsp;
              </Typography>
              <Typography variant="body2">{p.row.defectType?.name}</Typography>
            </span>
            <span style={costStyle}>
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                {getIntlTranslation("_Priority-Type")}:&nbsp;
              </Typography>
              <Typography variant="body2">{p.row.priority?.name}</Typography>
            </span>
            <Typography
              style={{ fontWeight: "bold", color: "#0288d1", marginTop: 5 }}
              variant="caption"
            >
              {getIntlTranslation("_Non-Cost-Defect")}
            </Typography>
          </div>
        )}
      </div>
      {p.row.extraCost > 0 && !!p.row.extraCostComment && (
        <span className={"d-flex "}>
          <Typography style={{ fontWeight: "bold" }} variant="body2">
            {getIntlTranslation("_Extra-Cost-Comments")}:&nbsp;
          </Typography>
          <Typography
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              overflow: "hidden",
            }}
            variant="body2"
          >
            {p.row.extraCostComment}
          </Typography>
        </span>
      )}
    </>
  );
};

export const defectAndMeasureColumnFormatter = (p, t) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        cursor: "pointer",
      }}
    >
      <span style={{ ...cellStyle, alignItems: "baseline" }}>
        <Typography variant="body2">
          <strong>{getIntlTranslation("_Defect-Description")}:&nbsp;</strong>
          {p.row.defect}
        </Typography>
      </span>
      <span style={{ ...cellStyle, alignItems: "baseline" }}>
        <Typography variant="body2">
          <strong>{getIntlTranslation("_Measure-Description")}:&nbsp;</strong>
          {p.row.measure}
        </Typography>
      </span>
    </div>
  );
};

export const createSelectRowFormatter = (p, x, dataGridRef) => {
  if (p.row.type === "defect_row" && p.row.deleted) {
    return  <Box
    className="d-flex flex-column justify-content-center"
    style={{
      borderLeft: getBorderColor(p),
      height: "100%",
      width: "100%",
    }}
  >
    <RowOptionsMenu
      grid={p}
      gridRef={dataGridRef}
      onEditRow={(p) => p.column.onDefectEdit(p)}
    />
  </Box>;
  } else if (p.row.type === "defect_row") {
    return (
      <Box
        className="d-flex flex-column justify-content-center"
        style={{
          borderLeft: getBorderColor(p),
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            alignItems: "center",
            height: 25,
            marginTop: 10,
          }}
        >
          {SelectColumn.formatter(p, x)}
        </div>
        <RowOptionsMenu
          grid={p}
          gridRef={dataGridRef}
          onEditRow={(p) => p.column.onDefectEdit(p)}
        />
      </Box>
    );
  } else {
    return (
      <Box
        className="d-flex align-align-items-center justify-content-between p-1"
        style={{
          borderLeft: getBorderColor(p),
          height: 40,
        }}
      >
        {p.row.count > 0 ? (
          <>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{
                width: "50%",
                position: "relative",
                height: 30,
              }}
            >
              {SelectColumn.formatter(p, x)}
            </div>

            <Divider orientation="vertical" flexItem />
            <CustomIconButton
              id="custom_block-paste"
              handleClick={() => dataGridRef.current.openSubAssetCopyDrawer(p)}
              icon={<ContentPasteGoIcon style={{ color: "#254a9a" }} />}
              tooltip={{
                title: getIntlTranslation(
                  "tooltip.Click-to-paste-selected-rows"
                ),
              }}
            />
          </>
        ) : (
          <div style={{ filter: "blur(4px)" }}></div>
        )}
      </Box>
    );
  }
};

export const getCapexRefFormatter = (p, t) => {
  if (p.row.costDefect && p.row.capexRef) {
    return (
      <>
        <div style={{...cellStyle, cursor: "pointer"}}>
          <Typography variant="body2">{p.row.capexRef} </Typography>
        </div>
      </>
    );
  } else if (p.row.costDefect && !p.row.capexRef) {
    return (
      <>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            lineHeight: "normal",
            padding: 5,
            flexDirection: "column",
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            cursor: "pointer",
          }}
        >
          <WarningIcon
            style={{ width: 20, height: 20, color: "#f1902b", margin: 5 }}
          />
          <Typography
            style={{
              fontWeight: "bold",
              color: "#f1902b",
              marginTop: 5,
              width: "90%",
              whiteSpace: "pre-wrap",
              lineHeight: "1.00",
            }}
            variant="caption"
          >
            {getIntlTranslation("_Capex-Needs-To-Be-Generated")}
          </Typography>
        </div>
      </>
    );
  } else if (!p.row.costDefect) {
    return (
      <>
        <div
          style={{
            ...cellStyle,
            flexDirection: "column",
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <InfoIcon
            style={{ width: 20, height: 20, color: "#0288d1", margin: 5 }}
          />
          <Typography
          style={{
            fontWeight: "bold",
            color: "#0288d1",
            marginTop: 5,
            width: "90%",
            whiteSpace: "pre-wrap",
            lineHeight: "1.00",
          }}
            variant="caption"
          >
            {getIntlTranslation("_Non-Cost-Defect")}
          </Typography>
        </div>
      </>
    );
  }
};
