import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const DeleteIconButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const { icon,tooltip,label, color, ...restProps } = props;
  const state = useSelector((state) => state.readOnly);

useEffect(() => {
      setReadOnly(state.isReadOnly);
  }, [state.isReadOnly]);

  const checkPermission = () => {
    if (!!props.permission) {
      return (
        <>
          {props.permission.create && props.permission.update ? (
            <Tooltip title={props.tooltip.title}>
            <IconButton
              id={props.id}
              className={props.className}
              
              edge={props.edge}
              onClick={(e) => props.handleClick(e)}
              {...restProps}
            >
                <DeleteForeverIcon style={{ ...props.iconStyle, color: props.color }} />
            </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      );
    } 
    else {
      return (
        <Tooltip title={props.tooltip.title}>
          <IconButton
            id={props.id}
            className={props.className}
            autoFocus
            edge={props.edge}
            onClick={(e) => props.handleClick(e)}
            {...restProps}
          >
              <DeleteForeverIcon style={{...props.iconStyle, color: props.color }} />
          </IconButton>
          </Tooltip>
      );
    }
  };

  return <>{!readOnly && checkPermission()}</>;
};

DeleteIconButton.defaultProps = {
  color:"#f50057",
};
DeleteIconButton.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  color: PropTypes.string,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  permission: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  edge: PropTypes.string,
  iconStyle:PropTypes.object,
};

export default DeleteIconButton;
