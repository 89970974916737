import { updateSubSection, updateChapter, updateSection, deleteChpater, deleteSection, deleteSubSection } from "../../../api/customChaptersApi";
export function updateSectionData(values, current, chapter, dispatch, projectIdent) {
  const data = {
    type: "custom-section",
    custom: {
      type: "custom",
      name: values.german?.toUpperCase(),
      node: "section",
      ident: current.ident,
      parentIdent: current.cident,
      reportType: chapter.customType,
      nameTranslation: [
        {
          type: "title",
          title: values.english?.toUpperCase(),
          language: "english",
          value: values.german?.toUpperCase(),
        },
        {
          type: "title",
          title: values.german?.toUpperCase(),
          language: "deutsch",
          value: values.german?.toUpperCase(), // always german one
        },
      ],
    },
  };
  dispatch(updateSection(data, projectIdent));
}




export function updateSubSectionData(
  values,
  current,
  chapter,
  dispatch,
  projectIdent) {
  const data = {
    type: "custom",
    name: values.german?.toUpperCase(),
    node: "sub-section",
    ident: current.ident,
    parentIdent: current.cident,
    reportType: chapter.customType,
    nameTranslation: [
      {
        type: "title",
        title: values.english?.toUpperCase(),
        language: "english",
        value: values.german?.toUpperCase(),
      },
      {
        type: "title",
        title: values.german?.toUpperCase(),
        language: "deutsch",
        value: values.german?.toUpperCase(), // always german one
      },
    ],
  };
  dispatch(updateSubSection(data, projectIdent));
}




export function updateChapterData(values, current, chapter, dispatch, projectIdent) {
  const data = {
    type: "custom-chapter",
    custom: {
      type: "custom",
      name: values.german?.toUpperCase(),
      node: "chapter",
      order: current.nodeId,
      ident: current.ident,
      reportType: chapter.customType,
      nameTranslation: [
        {
          type: "title",
          title: values.english?.toUpperCase(),
          language: "english",
          value: values.german?.toUpperCase(),
        },
        {
          type: "title",
          title: values.german?.toUpperCase(),
          language: "deutsch",
          value: values.german?.toUpperCase(), // always german one
        },
      ],
    },
  };
  dispatch(updateChapter(data, projectIdent));
}

export const deleteHeader = (element,projectIdent,report,dispatch) =>{

    if (element.node === "CHAPTER") {
        dispatch(deleteChpater(projectIdent, element.ident, report));
      }
      if (element.node === "SECTION") {
        dispatch(deleteSection(projectIdent, element.ident, report));
      }
      if (element.node === "sub_section") {
        dispatch(deleteSubSection(projectIdent, element.ident, report));
      }
}
