import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  data: {},
  error: {},
  costData: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  allCostElements: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  inflactionData: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  singleInflactionData: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  creatCostElement: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  creatSiblingCostElement: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  deleteCostElement: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  updateCostElement: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
  exportCost: {
    type: "",
    messages: "",
    error: {},
    data: [],
  },
};

const costDataSlice = createSlice({
  name: "costData",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      costData: {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDtoList,
      },
      
    }),
    getAllCostElements: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      allCostElements: {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDtoList,
      },
      
    }),
    getCreatedSiblingCostElement: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      creatSiblingCostElement:  {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDto,
      },
    }),
    getCreatedCostElement: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      creatCostElement:  {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDto,
      },
    }),
    getUpdatedCostElement: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      updateCostElement: {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDto,
      },
    }),
    getExportExcleSheetCost: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      exportCost: {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDtoList,
      },
    }),
    getUpdatedInflation: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      inflactionData: {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDto,
      },
    }),
    getUpdatedSingleInflation: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      singleInflactionData: {
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDto,
      },
    }),
    getDeletedCostElement: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      deleteCostElement:{
        type: payload.type,
        messages: payload.messages,
        error: payload.errorFields,
        data: payload.guruDto,
      }
    }),

    resetCreatedCostElement: (state) => ({
      ...initialState,
      hasErrors: false,
      type: "",
      messages: "",
      error: {},
    }),
    resetState: (state) => ({
      ...initialState,
      hasErrors: false,
      type: "",
      messages: "",
      error: {},
      deleteCostElement: {
        type: "",
        messages: "",
        error: {},
        data: [],
      },
      costData:{
        ...state.costData,
        type: "",
        messages: "",
        error: {},
      },
    }),
  },
});

export const {
  resetState,
  getFailure,
  getSuccess,
  getCreatedCostElement,
  getUpdatedCostElement,
  getDeletedCostElement,
  getUpdatedInflation,
  getCreatedSiblingCostElement,
  getUpdatedSingleInflation,
  resetCreatedCostElement,
  getAllCostElements,
  getExportExcleSheetCost
} = costDataSlice.actions;

// A selector
export const costDataSelector = (state) => state.costData;

// The reducer
export default costDataSlice.reducer;
