import axios from "../../../api/customAxios";
import { trackPromise } from "react-promise-tracker";
import { 
  getFailure, 
  getListSuccess, 
  getDataSuccess, 
  getAddChapter,
  getAddSection,
  getAddSubSection,
  getDelete,
  getUpdate,
  getChapterStructure,
  getSectionStructure,
  getSubSectionStructure,
  saveDataInput,
  updateOrder,
  deleteSecTructure
} from "../common/slice/chaptersSlice";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from '../../../api/authApi'

export const loadChapters = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/custom/fetch-chapter-list/${request.ident}/${request.language}/${request.report}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getListSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const loadChapterData = (chapter) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/fullreport/fetch-chapter-content/${chapter.projectIdent}/${chapter.assetIdent}/${chapter.ident}/${chapter.language}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const loadSectionData = (chapter) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/fullreport/fetch-section-content/${chapter.projectIdent}/${chapter.assetIdent}/${chapter.chapterIdent}/${chapter.sectionIdent}/${chapter.language}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const addNewchapter = (projectInfo,projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/custom/save-chapter/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: {...projectInfo}
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getAddChapter(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const addNewSection = (projectInfo,projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/custom/save-section/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: {...projectInfo}
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getAddSection(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const addNewSubSection = (projectInfo,projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/custom/save-sub-section/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: {...projectInfo}
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getAddSubSection(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const deleteChpater = (projectIdent,ident,report) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/custom/delete-chapter/${projectIdent}/${ident}/${report}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        }
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDelete(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const deleteSection = (projectIdent,ident,report) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/custom/delete-section/${projectIdent}/${ident}/${report}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        }
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDelete(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const deleteSubSection = (projectIdent,ident,report) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/custom/delete-sub-section/${projectIdent}/${ident}/${report}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        }
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDelete(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const updateChapter = (projectInfo,projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/custom/update-chapter/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: {...projectInfo}
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getUpdate(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const updateSection = (projectInfo,projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/custom/update-section/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: {...projectInfo}
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getUpdate(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const updateSubSection = (projectInfo,projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/custom/update-sub-section/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },data: {...projectInfo}
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getUpdate(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const chapterDataStructure = ({projectIdent,ident,language,report}) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/custom/fetch-chapter-structure/${projectIdent}/${ident}/${language}/${report}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        }
      })
      );
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getChapterStructure(response.data));
      }
    } catch (error) {
      if (error.response || error.request) {
        let payload = {};
        payload = {
          type: "ERROR",
          messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
      }
    }
  }
  
  export const sectionDataStructure = ({projectIdent,ident,language,report}) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "get",
          url: `/custom/fetch-section-structure/${projectIdent}/${ident}/${language}/${report}`,
          headers: {
            Accept: "application/json",
            Authorization: tokenHeader,
          }
        })
        );
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(getSectionStructure(response.data));
        }
      } catch (error) {
        if (error.response || error.request) {
          let payload = {};
          payload = {
            type: "ERROR",
            messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
            responseError: "true",
          };
          dispatch(getErrorFailure(payload));
        }
      }
    }
  export const getSubSectionDataStructure = ({projectIdent,ident,language,report}) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "get",
          url: `/custom/fetch-sub-section-structure/${projectIdent}/${ident}/${language}/${report}`,
          headers: {
            Accept: "application/json",
            Authorization: tokenHeader,
          }
        })
        );
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(getSubSectionStructure(response.data));
        }
      } catch (error) {
        if (error.response || error.request) {
          let payload = {};
          payload = {
            type: "ERROR",
            messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
            responseError: "true",
          };
          dispatch(getErrorFailure(payload));
        }
      }
    }
    export const saveSectionStructure = (customStructure,projectIdent) => async (dispatch) => {
      try {
        const tokenHeader = await renewAccessTokenIfGoingExpire();
        const response = await trackPromise(
          axios({
            method: "post",
            url: `/custom/save-section-structure/${projectIdent}`,
            headers: {
              Accept: "application/json",
              Authorization: tokenHeader,
            },data: {...customStructure}
          })
        );
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(saveDataInput(response.data));
        }
      } catch (error) {
        if (error.response || error.request) {
          let payload = {};
          payload = {
            type: "ERROR",
            messages:
              "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
            responseError: "true",
          };
          dispatch(getErrorFailure(payload));
        }
      }
    };
    export const saveSubSectionStructure = (customStructure,projectIdent) => async (dispatch) => {
      try {
        const tokenHeader = await renewAccessTokenIfGoingExpire();
        const response = await trackPromise(
          axios({
            method: "post",
            url: `/custom/save-sub-section-structure/${projectIdent}`,
            headers: {
              Accept: "application/json",
              Authorization: tokenHeader,
            },data: {...customStructure}
          })
        );
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(saveDataInput(response.data));
        }
      } catch (error) {
        if (error.response || error.request) {
          let payload = {};
          payload = {
            type: "ERROR",
            messages:
              "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
            responseError: "true",
          };
          dispatch(getErrorFailure(payload));
        }
      }
    };
    export const updateChapterOrder = (customStructure,projectIdent) => async (dispatch) => {
      try {
        const tokenHeader = await renewAccessTokenIfGoingExpire();
        const response = await trackPromise(
          axios({
            method: "post",
            url: `/custom/save-chapter-order/${projectIdent}`,
            headers: {
              Accept: "application/json",
              Authorization: tokenHeader,
            },data: {...customStructure}
          })
        );
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(updateOrder(response.data));
        }
      } catch (error) {
        if (error.response || error.request) {
          let payload = {};
          payload = {
            type: "ERROR",
            messages:
              "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
            responseError: "true",
          };
          dispatch(getErrorFailure(payload));
        }
      }
    };
    export const deleteSectionStructure = (customStructure,projectIdent) => async (dispatch) => {
      try {
        const tokenHeader = await renewAccessTokenIfGoingExpire();
        const response = await trackPromise(
          axios({
            method: "post",
            url: `/custom/delete-section-structure/${projectIdent}`,
            headers: {
              Accept: "application/json",
              Authorization: tokenHeader,
            },data: {...customStructure}
          })
        );
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(deleteSecTructure(response.data));
        }
      } catch (error) {
        if (error.response || error.request) {
          let payload = {};
          payload = {
            type: "ERROR",
            messages:
              "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
            responseError: "true",
          };
          dispatch(getErrorFailure(payload));
        }
      }
    };
    export const deleteSubSectionStructure = (customStructure,projectIdent) => async (dispatch) => {
      try {
        const tokenHeader = await renewAccessTokenIfGoingExpire();
        const response = await trackPromise(
          axios({
            method: "post",
            url: `/custom/delete-subsection-structure/${projectIdent}`,
            headers: {
              Accept: "application/json",
              Authorization: tokenHeader,
            },data: {...customStructure}
          })
        );
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(deleteSecTructure(response.data));
        }
      } catch (error) {
        if (error.response || error.request) {
          let payload = {};
          payload = {
            type: "ERROR",
            messages:
              "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
            responseError: "true",
          };
          dispatch(getErrorFailure(payload));
        }
      }
    };