import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItemButton,
  Typography,
  Divider,
  Collapse,
  Chip,
} from "@mui/material";
import { scroller } from "react-scroll";

import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { RenderActionMenu, ResolvedCommentActions } from "./CommentsActions";
import { setSelectedCommentNode } from "../../../../component-bundle/comments/commentSlice";
import {
  getIntlTranslation,
  getStylingForSelectedComment,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { getCurrentUser } from "../../../../../authentication/authTokenService";
import EditCommentsContainer from "./EditCommentsContainer";
import ReplyCommentContainer from "./ReplyCommentContainer";
import CancelIcon from '@mui/icons-material/Cancel';


const scrollTo = function (id) {
  scroller.scrollTo(`scroll_item_${id}`, {
    containerId: "review-data-container",
    horizontal: false,
    isDynamic: true,
    offset: -10,
    smooth: true,
    spy: true,
  });
};
export const RenderComments = ({ comments, selectedComment, handleSearch, filterApplied, setFilterApplied }) => {
  const { open, closed, data } = comments;
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const commentsState = useSelector((state) => state.comments);
  const system = useSelector((state) => state.system);
  const usersState = useSelector((state) => state.users);

  const [openReply, setOpenReply] = useState({
    open: false,
    comment: null,
  });
  const [anchorEl, setAnchorEl] = useState({
    data: null,
    anchor: null,
    isReply: false,
  });
  const [editing, setEditing] = useState({
    comment: null,
    edit: false,
    isReply: false,
  });

  const openMenu = Boolean(anchorEl.anchor);


  useEffect(() => {
    if(!!selectedComment && comments?.data.length > 0){
      let found = comments?.data.find((comment) => comment.ident === selectedComment.ident);
      if(!!found){
        dispatch(setSelectedCommentNode(found));
        scrollTo(found.propertyIdent);
      }
    }
  }, [selectedComment,comments?.data])
  

  const handleOptionClick = (event, comment, isReply) => {
    setAnchorEl({
      data: comment,
      anchor: event.currentTarget,
      isReply: isReply,
    });
  };

  const renderHeader = (comment, isReply) => {
    const user = usersState.mentionList.find(
      (user) => comment.updatedBy === user?.id
    );
    const date = getDate(comment);
    return (
      <CardHeader
        className="w-100 m-1"
        avatar={
          <Avatar
            style={{
              backgroundColor: "#d08f8e",
              width: 30,
              height: 30,
              padding: 5,
              marginRight:5,
            }}
          >
            <Typography variant="caption">
              {user?.firstName?.charAt(0).toUpperCase()}
              {user?.lastName?.charAt(0).toUpperCase()}
            </Typography>
          </Avatar>
        }
        action={
          <>
            {currentUser?.data?.uid === comment.createdBy &&
              !comment.resolved && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionClick(e, comment, isReply);
                  }}
                  size="small"
                  id="comments-action"
                  className="m-2"
                >
                  <MoreVertIcon style={{ width: 20, height: 20 }} />
                </IconButton>
              )}
          </>
        }
        title={
          <>
            <div className={"d-flex flex-row flex-wrap align-items-center"}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold", fontSize: "12px"}}
                className={"p-1"}
              >
                {user?.title || "Unknown User"}
              </Typography>
              <Typography
                style={{
                  color: "grey",
                }}
                variant="caption"
                className="mx-1"
              >
                {`${date.toLocaleString(system.language, {
                  weekday: "short",
                })},
          ${date.toLocaleDateString(system.language)} , 
          ${date.toLocaleTimeString(system.language)} ${
                  system.language === "de" ? "Uhr" : ""
                }`}
                {comment.modified &&
                  getTranslationVariantText("_edited", "caption", {
                    textTransform: "uppercase",
                    marginLeft: 5,
                  })}
              </Typography>
            </div>
          </>
        }
        style={{ padding: 0, width: "100%", flex: 1 }}
      />
    );
  };

  const SearchBox = () => {
    return (
      <Box
      style={{
        width: "98%",
        position: "sticky",
        top: 0,
        zIndex: 1500,
        height: 40,
        backgroundColor: "#ffffff",
      }}
      className="d-flex flex-column align-items-center justify-content-start p-1 m-1 rounded-3"
    >
      <Box
        style={{ height: 30, marginTop: "auto" }}
        className="d-flex align-items-center w-100"
      >
        <Chip
          className="g-btn-pink d-flex align-items-center justify-content-start h-100 rounded-3 p-1"
          style={{ width: "45%" }}
          onClick={(event) => {
            setFilterApplied((prev) => ({...prev,type:"open"}))
            handleSearch("open");
          }}
          onDelete={(event) => {
            setFilterApplied((prev) => ({...prev,type:null}))
            handleSearch("reset");
          }}
      deleteIcon={ filterApplied.type==="open" ? <CancelIcon style={{color:"#ffffff", fontSize:18, marginLeft:5}}/> :<></>}
          label={<Box className="d-flex align-items-center">
            <Typography variant="body1" className="m-1 fw-bold">{getIntlTranslation("_open-threads")}  
            </Typography>
            <Typography variant="body1" className="m-1 fw-bold">{comments.open}</Typography>
          </Box>}
        />
        <Chip
          className="g-btn-primary d-flex align-items-center justify-content-start h-100 rounded-3 m-1 p-1"
          style={{ width: "55%" }}
          onClick={(event) => {
            setFilterApplied((prev) => ({...prev,type:"resolved"}))
            handleSearch("resolved");
          }}
          onDelete={(event) => {
            setFilterApplied((prev) => ({...prev,type:null}))
            handleSearch("reset");
          }}
          deleteIcon={ filterApplied.type==="resolved" ? <CancelIcon style={{color:"#ffffff", fontSize:18, marginLeft:5}}/> :<></>}
          label={
            <Box className="d-flex align-items-center">
            <Typography variant="body1" className="m-1 fw-bold">{getIntlTranslation("_resolve-threads")}  
          </Typography>
          <Typography variant="body1" className="m-1 fw-bold">{comments.closed}</Typography>
          </Box>
          }
        />
      </Box>
    </Box>
    )
  }

  const getDate = (comment) => new Date(comment.updatedAt);

  return (
    <Box
      className="w-100 d-flex flex-column align-items-center"
      style={{ backgroundColor: "#a3a3a326", height:"calc(100% - 85px)" }}
    >
      <SearchBox/>
      <List
        className="w-100 custom-scrollBar p-1"
        style={{
          width: "100%",
          height:"calc(100% - 70px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {data.map((comment) => {
          return (
            <ListItemButton
              className="w-100 p-1"
              style={getStylingForSelectedComment(
                commentsState.selectedCommentNode?.ident,
                comment.ident
              )}
              onClick={(e) => {
                dispatch(setSelectedCommentNode(comment));
                scrollTo(comment.propertyIdent);
              }}
            >
              <Card
                className="w-100 m-0"
                style={{ borderLeft: "4px solid #244a9a" }}
              >
                <Box style={{ opacity: comment.resolved ? "0.5" : "1" }}>
                  {renderHeader(comment, false)}
                  <CardContent className="p-0 m-0">
                    {editing.edit &&
                    editing.comment?.ident === comment.ident ? (
                      <EditCommentsContainer
                        comment={editing.comment}
                        isReply={editing.isReply}
                        onEditClose={() =>
                          setEditing({
                            edit: false,
                            comment: null,
                            isReply: false,
                          })
                        }
                      />
                    ) : (
                      <Typography
                        style={{
                          fontSize: 14,
                          width: "95%",
                          textAlign: "left",
                        }}
                        className=" label-text render-comments p-1"
                        dangerouslySetInnerHTML={{ __html: comment.value }}
                      />
                    )}
                  </CardContent>
                  {comment?.replies?.map((r) => {
                    const reply = {
                      ...r,
                      queryDetails: comment.queryDetails,
                      parent: comment,
                      resolved: comment.resolved
                    };
                    return (
                      <>
                        <Divider
                          orientation="vertical"
                          className="w-100 mb-1"
                          style={{ height: 4, backgroundColor: "#c1d0f0b3" }}
                        />
                        <Box className="m-0">
                          {renderHeader({ ...reply, }, true)}
                          <CardContent className="p-0">
                            {editing.edit &&
                            editing.comment?.ident === reply.ident ? (
                              <EditCommentsContainer
                                comment={editing.comment}
                                isReply={editing.isReply}
                                onEditClose={() =>
                                  setEditing({
                                    edit: false,
                                    comment: null,
                                    isReply: false,
                                  })
                                }
                              />
                            ) : (
                              <Typography
                                style={{
                                  fontSize: 14,
                                  width: "95%",
                                  textAlign: "justify",
                                }}
                                className=" label-text render-comments p-1"
                                dangerouslySetInnerHTML={{
                                  __html: reply.value,
                                }}
                              />
                            )}
                          </CardContent>
                        </Box>
                      </>
                    );
                  })}
                  <Divider orientation="vertical" className="g-divider mb-1" />
                  {openReply.open &&
                    openReply.comment?.ident === comment.ident && (
                      <Collapse
                        in={
                          openReply.open &&
                          openReply.comment?.ident === comment.ident
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <ReplyCommentContainer
                          comment={openReply.comment}
                          onClose={() =>
                            setOpenReply({ open: false, comment: {} })
                          }
                        />
                      </Collapse>
                    )}
                </Box>
                {openReply.open === false && (
                  <CardActions
                    className="p-0 m-0 rounded-1"
                    style={{
                      background: comment.resolved ? "#24242421" : "#ffffff",
                    }}
                  >
                    {!comment.resolved ? (
                      <CustomButton
                        id={`reply-${comment.ident}`}
                        buttonText={"_reply"}
                        variant="text"
                        size="small"
                        style={{
                          fontSize: "10px",
                          color: "#282928",
                          fontWeight: 700,
                          padding: 0,
                          marginLeft: "auto",
                        }}
                        className="btn p-1"
                        textVariant="caption"
                        tooltip={{
                          title: getIntlTranslation("tooltip.reply"),
                        }}
                        handleClick={(e) => {
                          e.stopPropagation();
                          setOpenReply({
                            open: true,
                            comment: comment,
                          });
                        }}
                      />
                    ) : (
                      <ResolvedCommentActions comment={comment} />
                    )}
                  </CardActions>
                )}
              </Card>
            </ListItemButton>
          );
        })}
      </List>
      {openMenu && (
        <RenderActionMenu
          anchorEl={anchorEl.anchor}
          data={anchorEl.data}
          isReply={anchorEl.isReply}
          onClose={() => {
            setAnchorEl({
              data: null,
              anchor: null,
              isReply: false,
            });
          }}
          onEdit={(data, isReply) => {
            setEditing({
              edit: true,
              comment: data,
              isReply: isReply,
            });
            setAnchorEl({
              data: null,
              anchor: null,
              isReply: false,
            });
          }}
        />
      )}
    </Box>
  );
};
