import React,{ useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {resetErrorState } from "../../error/errorHandlingSlice";
import { useSnackbar } from "notistack";
import { Outlet } from "react-router-dom";

const Content = () => {
  const errorState = useSelector((state) => state.errorhandling);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
      enqueueSnackbar(errorState.messages, {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      dispatch(resetErrorState());
    }
  })

  return (
    <div className="g-content">
        <Outlet />
      </div>
  );
};

Content.propTypes = {};

export default Content;
