import Box from "@mui/material/Box";
import React from "react";
import ProjectInformationWrapper from "./structure/ProjectInformationWrapper"

const ProjectInfromationHome = () => {

  return (
    <Box
      style={{ height: "100%", overflow: "hidden", backgroundColor: "#ebedef" }}
    >
      <ProjectInformationWrapper moduleApi={"project-information"}/>
    </Box>
  );
};
export default ProjectInfromationHome;
