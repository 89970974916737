import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import EsgModuleContainer from "./EsgModuleContainer"

const EsgModuleDataWrapper = props => {
  return (
    <div><EsgModuleContainer/></div>
  )
}

EsgModuleDataWrapper.propTypes = {}

export default EsgModuleDataWrapper;