import { useState, useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Box,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import {
  getVariantText,
  getTranslationVariantText,
  getIntlTranslation,
  sortColumn,
  covertInCurrencyFormat,
} from "../../../../../utils/helper";
import { useIntl } from "react-intl";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const cellStyle = {
  borderRight: "1px solid #0000001f",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  padding: 5,
  whiteSpace: "pre-wrap",
  height: "100%",
};

const totalCellStyle = {
  borderRight: "1px solid #0000001f",
  minHeight: 40,
  padding: 5,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  height: "100%",
};

const getGroupedCost = (childRows, cost) => {
  let temp = childRows.map((m) => m[cost]);
  const val = temp.reduce((x, y) => {
    return x + y;
  }, 0);
  return val;
};

const getGroupedCategories = (childRows, value) => {
  let filterData = childRows.filter(
    (m) => m.category.toLowerCase().trim() === value.toLowerCase().trim()
  );
  return getGroupedData(filterData);
};
const getGroupedTrade = (childRows, value) => {
  let filterData = childRows.filter(
    (m) => m.trade.toLowerCase().trim() === value.toLowerCase().trim()
  );
  return getGroupedData(filterData);
};

const getGroupedDefect = (childRows, value) => {
  let filterData = childRows.filter(
    (m) => m?.typObj?.value.toLowerCase().trim() === value.toLowerCase().trim()
  );
  return getGroupedData(filterData);
};

const getGroupedData = (filterData) => {
  const shortTerm = getGroupedCost(filterData, "shortTermCost");
  const mediumTerm = getGroupedCost(filterData, "mediumTermCost");
  const longTerm = getGroupedCost(filterData, "longTermCost");
  const total = shortTerm + mediumTerm + longTerm;
  return {
    count: filterData.length,
    shortTerm: shortTerm,
    mediumTerm: mediumTerm,
    longTerm: longTerm,
    total: total,
  };
};

const CapexCostTable = (props) => {
  const [tableData, setTableData] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headers, setHeaders] = useState([]);

  const defaultHeaders = useMemo(
    () => [
      {
        name: "",
        key: "name",
        isEmpty: true,
        order: 1,
        isFirst: true,
        style: {
          backgroundColor: "#ffffff",
          color: "#ffffff",
          width: 300,
          minWidth: 300,
        },
      },
      {
        name: "_Priority",
        key: "priority",
        frozen: true,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 900,
          minWidth: 900,
          position: "sticky",
          zIndex: "1500",
          left: 0,
        },
        order: 2,
        extraColumn: true,
      },
      {
        name: "categories",
        key: "category",
        frozen: true,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 1000,
          minWidth: 1000,
          position: "sticky",
          zIndex: "1500",
          left: 0,
        },
        order: 3,
        extraColumn: true,
      },
      {
        name: "_trade",
        key: "trade",
        frozen: true,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 1000,
          minWidth: 1000,
          position: "sticky",
          zIndex: "1500",
          left: 0,
        },
        order: 4,
        extraColumn: true,
      },
      {
        name: "_Defect-Type",
        key: "defect",
        frozen: true,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 800,
          minWidth: 800,
          position: "sticky",
          zIndex: "1500",
          left: 0,
        },
        order: 5,
        extraColumn: false,
      },
      {
        name: "",
        key: "total",
        order: 6,
        isLast: true,
        isEmpty: true,
        style: {
          backgroundColor: "#ffffff",
          color: "#ffffff",
          width: 200,
          minWidth: 200,
        },
      },
    ],
    []
  );

  const defaultColumns = useMemo(
    () => [
      {
        name: "_Name",
        key: "title",
        headerKey: "name",
        type: {
          dataType: "string",
          valueType: "name",
        },
        frozen: true,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 300,
          minWidth: 300,
          position: "sticky",
          zIndex: "1500",
          left: 0,
          justifyContent: "start",
        },
        order: 1,
        showtotal: false,
        extraColumn: false,
        isFirst: true,
      },
      {
        name: "_short-term",
        key: "shortTerm",
        headerKey: "priority",
        type: {
          dataType: "number",
          valueType: "cost",
          gridColor: "#e85656",
        },
        frozen: false,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 300,
          minWidth: 300,
          justifyContent: "center",
        },
        order: 2,
        showtotal: true,
        extraColumn: false,
      },
      {
        name: "_medium-term",
        key: "mediumTerm",
        headerKey: "priority",
        type: {
          dataType: "number",
          valueType: "cost",
          gridColor: "#dfb81c",
        },
        frozen: false,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 300,
          minWidth: 300,
          justifyContent: "center",
        },
        order: 3,
        showtotal: true,
        extraColumn: false,
      },
      {
        name: "_long-term",
        key: "longTerm",
        headerKey: "priority",
        type: {
          dataType: "number",
          valueType: "cost",
          gridColor: "#209e91",
          justifyContent: "center",
        },
        frozen: false,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 300,
          minWidth: 300,
        },
        order: 4,
        showtotal: true,
        extraColumn: true,
      },
      {
        name: "_Total",
        key: "total",
        headerKey: "total",
        type: {
          dataType: "number",
          valueType: "cost",
          gridColor: "#C1D0F0",
        },
        frozen: true,
        style: {
          position: "sticky",
          zIndex: "1500",
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 200,
          minWidth: 200,
          right: 0,
          justifyContent: "center",
        },
        order: 14,
        extraColumn: false,
        showtotal: true,
        isLast: true,
      },
    ],
    []
  );

  const getUniqueResults = useCallback((key, filterData) => {
    let temp = filterData
      .filter((f, i, arr) => arr.findIndex((a) => a[key] === f[key]) === i)
      .map((m) => {
        return {
          title: m[key],
          value: m[key],
        };
      });
    return temp;
  }, []);

  useEffect(() => {
    if (!!props.data && props.data.length > 0) {
      let temp = props.data.filter(
        (f, i, arr) =>
          arr.findIndex((a) => a["assetName"] === f["assetName"]) === i
      );
      let tempCategory = getUniqueResults("category", props.data);
      let tempTrade = getUniqueResults("trade", props.data);
      let tempDefect = getUniqueResults("typ", props.data);

      let assets = temp.map((m, index) => {
        let filterData = props.data.filter(
          (f) => f.assetIdent === m.assetIdent
        );
        const shortTerm = getGroupedCost(filterData, "shortTermCost");
        const mediumTerm = getGroupedCost(filterData, "mediumTermCost");
        const longTerm = getGroupedCost(filterData, "longTermCost");
        const total = shortTerm + mediumTerm + longTerm;

        let tempObj = {
          category: {},
          trade: {},
          defect: {},
        };

        tempCategory.forEach((c) => {
          tempObj.category[c.value] = getGroupedCategories(filterData, c.value);
        });
        tempTrade.forEach((c) => {
          tempObj.trade[c.value] = getGroupedTrade(filterData, c.value);
        });
        tempDefect.forEach((c) => {
          tempObj.defect[c.value] = getGroupedDefect(filterData, c.value);
        });
        return {
          title: m["assetName"],
          ident: m["assetIdent"],
          shortTerm: shortTerm,
          mediumTerm: mediumTerm,
          longTerm: longTerm,
          area: m.area,
          total: total,
          cost: Number(total) / Number(m.area),
          order: index + 1,
          ...tempObj,
        };
      });
      setAssetsList(assets);
      setTableData(props.data);

      let categoryColumns = tempCategory.map((m, index) => ({
        name: m.title,
        key: m.value,
        headerKey: "category",
        type: {
          dataType: "number",
          valueType: "category",
        },
        frozen: false,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 300,
          minWidth: 300,
          justifyContent: "center",
        },
        extraColumn: index === tempCategory.length - 1 ? true : false,
        showtotal: true,
        order: props.data.find((f) => f.category === m.value).categoryOrder,
      }));

      let tradeColumns = tempTrade.map((m, index) => {
        let found = props.data.find((f) => f.trade === m.value);
        let order = parseFloat(found.categoryOrder + "." + found.tradeOrder);
        return {
          name: m.title,
          key: m.value,
          headerKey: "trade",
          type: {
            dataType: "number",
            valueType: "trade",
          },
          frozen: false,
          style: {
            backgroundColor: "#254a9a",
            color: "#ffffff",
            width: 300,
            minWidth: 300,
            justifyContent: "center",
          },
          extraColumn: index === tempTrade.length - 1 ? true : false,
          showtotal: true,
          order: order,
        };
      });

      let defectColumns = tempDefect.map((m, index) => ({
        name: m.title,
        key: m.value,
        headerKey: "defect",
        type: {
          dataType: "number",
          valueType: "defect",
        },
        frozen: false,
        style: {
          backgroundColor: "#254a9a",
          color: "#ffffff",
          width: 300,
          minWidth: 300,
          justifyContent: "center",
        },
        extraColumn: index === tempDefect.length - 1 ? true : false,
        showtotal: true,
        order: index + 1,
      }));

      const tempHeaders = defaultHeaders.map((m) => {
        if (m.key === "category") {
          m = {
            ...m,
            style: {
              ...m.style,
              width: 300 * categoryColumns.length,
              minWidth: 300 * categoryColumns.length,
            },
          };
        } else if (m.key === "trade") {
          m = {
            ...m,
            style: {
              ...m.style,
              width: 300 * tradeColumns.length,
              minWidth: 300 * tradeColumns.length,
            },
          };
        } else if (m.key === "defect") {
          m = {
            ...m,
            style: {
              ...m.style,
              width: 300 * defectColumns.length,
              minWidth: 300 * defectColumns.length,
            },
          };
        }
        return m;
      });
      setHeaders([...tempHeaders]);

      let combined = [
        ...defaultColumns,
        ...categoryColumns,
        ...tradeColumns,
        ...defectColumns,
      ];

      let finalColumns = [];
      let sIndex = 0;
      tempHeaders.forEach((e, index) => {
        let found = combined.filter((f) => f.headerKey === e.key);
        found
          .sort((a, b) => a.order - b.order)
          .forEach((m, index) =>
            finalColumns.push({ ...m, order: index + sIndex + 1 })
          );
        sIndex = finalColumns.length;
      });

      setColumns(finalColumns);
    }
  }, [defaultColumns, defaultHeaders, getUniqueResults, props.data]);

  const sortAsc = (col) => {
    let temp = assetsList;
    if (col.headerKey === "category") {
      temp = assetsList.sort((a, b) =>
        String(a.category[col.columnName].total)?.localeCompare(
          String(b.category[col.columnName].total),
          "en",
          { numeric: true }
        )
      );
    } else if (col.headerKey === "trade") {
      temp = assetsList.sort((a, b) =>
        String(a.trade[col.columnName].total)?.localeCompare(
          String(b.trade[col.columnName].total),
          "en",
          { numeric: true }
        )
      );
    } else if (col.headerKey === "defect") {
      temp = assetsList.sort((a, b) =>
        String(a.defect[col.columnName].total)?.localeCompare(
          String(b.defect[col.columnName].total),
          "en",
          { numeric: true }
        )
      );
    } else {
      temp = sortColumn(col.columnName, assetsList).map((m, index) => ({
        ...m,
        order: index + 1,
      }));
    }
    setAssetsList([...temp.map((m, index) => ({ ...m, order: index + 1 }))]);
  };

  const sortDesc = (col) => {
    let temp = assetsList;
    if (col.headerKey === "category") {
      temp = assetsList.sort((a, b) =>
        String(b.category[col.columnName].total)?.localeCompare(
          String(a.category[col.columnName].total),
          "en",
          { numeric: true }
        )
      );
    } else if (col.headerKey === "trade") {
      temp = assetsList.sort((a, b) =>
        String(b.trade[col.columnName].total)?.localeCompare(
          String(a.trade[col.columnName].total),
          "en",
          { numeric: true }
        )
      );
    } else if (col.headerKey === "defect") {
      temp = assetsList.sort((a, b) =>
        String(b.defect[col.columnName].total)?.localeCompare(
          String(a.defect[col.columnName].total),
          "en",
          { numeric: true }
        )
      );
    } else {
      temp = sortColumn(col.columnName, assetsList)
        .map((m, index) => ({
          ...m,
          order: index + 1,
        }))
        .reverse();
    }
    setAssetsList([...temp.map((m, index) => ({ ...m, order: index + 1 }))]);
  };

  const filterArrows = (column) => {
    let col = { ...column };
    return (
      <Box className="d-flex" style={{ marginLeft: "auto", width: 30 }}>
        <Tooltip title={getIntlTranslation("_sort-by-asc")}>
          <IconButton className="p-0" onClick={() => sortAsc(col)}>
            <PlayArrowIcon
              style={{
                fontSize: 16,
                color: "#ffffff",
                transform: "rotate(270deg)",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={getIntlTranslation("_sort-by-desc")}>
          <IconButton className="p-0" onClick={() => sortDesc(col)}>
            <PlayArrowIcon
              style={{
                fontSize: 16,
                color: "#ffffff",
                transform: "rotate(90deg)",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const renderFilterOptions = () => {
    let options = headers
      .filter((m) => !m.isEmpty)
      .map((m) => ({
        title: m.name,
        value: m.key,
      }));
    return (
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          style={{ width: "50%" }}
          className="p-1 d-flex align-items-center flex-wrap"
        >
          {options.map((m) => {
            return (
              <FormControlLabel
                style={{
                  margin: 5,
                }}
                control={
                  <Button
                    component="label"
                    variant="contained"
                    href="#file-upload"
                    style={{
                      background: selectedData.includes(m.value)
                        ? "#32908F"
                        : "#254a9a",
                      color: "#fff",
                      height: 25,
                    }}
                  >
                    <Checkbox
                      icon={icon}
                      type="checkbox"
                      id={m.value}
                      checkedIcon={checkedIcon}
                      style={{
                        clip: "rect(0 0 0 0)",
                        clipPath: "inset(50%)",
                        height: "1px",
                        overflow: "hidden",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        whiteSpace: "nowrap",
                        width: "1px",
                      }}
                      checked={selectedData.includes(m.value)}
                      onChange={(e) =>
                        setSelectedData((prev) => {
                          if (e.target.checked) {
                            if (prev.includes(m.value)) {
                              return [...prev];
                            } else {
                              return [...prev, m.value];
                            }
                          } else {
                            return prev.filter((f) => f !== m.value);
                          }
                        })
                      }
                    />
                    {getTranslationVariantText(m.title, "subtitle2", {
                      fontWeight: "bold",
                      color: "#fff",
                    })}
                  </Button>
                }
              />
            );
          })}
        </Box>

        <Box
          style={{ width: "50%" }}
          className="p-1 d-flex align-items-center flex-wrap justify-content-center"
        >
          <Stack
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid
                style={{
                  width: "150px",
                  height: "10px",
                  borderRadius: "25px",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  background:
                    "linear-gradient(90deg, rgba(13,125,6,1) 0%, rgba(9,121,17,1) 48%, rgba(255,233,0,1) 100%)",
                }}
              />
              <Grid>
                {getTranslationVariantText("Niedrige Kosten", "subtitle2", {
                  fontWeight: "bold",
                  color: "black",
                })}
              </Grid>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid
                style={{
                  width: "150px",
                  height: "10px",
                  borderRadius: "25px",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  background:
                    "linear-gradient(90deg, rgba(255,237,0,1) 0%, rgba(240,236,47,1) 45%, rgba(255,160,0,1) 100%)",
                }}
              />
              <Grid
                style={{
                  textAlign: "center",
                }}
              >
                {getTranslationVariantText("Mittlere Kosten", "subtitle2", {
                  fontWeight: "bold",
                  color: "black",
                })}
              </Grid>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                marginRight: "5px",
              }}
            >
              <Grid
                style={{
                  width: "150px",
                  height: "10px",
                  borderRadius: "25px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  background:
                    "linear-gradient(90deg, rgba(255,160,0,1) 10%, rgba(240,77,47,1) 50%, rgba(240,77,47,1) 100%)",
                }}
              />
              <Grid
                style={{
                  textAlign: "right",
                  marginRight: "5px",
                }}
              >
                {getTranslationVariantText("Hohe Kosten", "subtitle2", {
                  fontWeight: "bold",
                  color: "black",
                })}
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Box>
    );
  };

  const createStickColumnsSeperator = (col) => {
    let temp = {
      position: "sticky",
      width: 5,
      backgroundColor: "#d08f8e",
    };
    if (col.isFirst) {
      temp = { ...temp, left: col.style.width, backgroundColor: "#ffffff" };
    } else if (col.isLast) {
      temp = {
        ...temp,
        right: col.style.width,
        backgroundColor: "#ffffff",
        width: 5 + col.style.right,
      };
    } else if (col.extraColumn) {
      temp = { ...temp, left: col.style.width, backgroundColor: "#d08f8e" };
    } else {
      return <></>;
    }
    return (
      <>
        <div
          style={{
            ...temp,
            minHeight: 40,
            whiteSpace: "pre-wrap",
            justifyContent: "center",
            border: "none",
            zIndex: 1500,
          }}
        ></div>
      </>
    );
  };

  const createParentHeaderRows = useMemo(() => {
    let temp = [...headers];
    if (selectedData.length > 0) {
      temp = headers.filter((f) => selectedData.includes(f.key) || f.isEmpty);
    }
    return temp
      .sort((a, b) => a.order - b.order)
      .map((col) => {
        let tempStyle = { ...cellStyle, ...col.style };
        if (col.isEmpty) {
          return (
            <>
              <div
                style={{
                  ...tempStyle,
                  height: 40,
                  justifyContent: "start",
                  border: "none",
                }}
              ></div>
              {(col.isFirst || col.extraColumn) && (
                <div
                  style={{
                    width: 5,
                    minHeight: 40,
                    border: "none",
                    zIndex: 1500,
                  }}
                ></div>
              )}
            </>
          );
        } else {
          return (
            <>
              <div
                style={{ ...tempStyle, height: 40, justifyContent: "center" }}
              >
                {getTranslationVariantText(col.name, "caption", {
                  fontWeight: "Bold",
                  textTransform: "uppercase",
                  width: "calc(100% - 30px)",
                  textAlign: "center",
                })}
              </div>
              {col.extraColumn && (
                <div
                  style={{
                    width: 5,
                    minHeight: 40,
                    whiteSpace: "pre-wrap",
                    backgroundColor: "#ffffff",
                    justifyContent: "center",
                    border: "none",
                    zIndex: 1500,
                  }}
                ></div>
              )}
            </>
          );
        }
      });
  }, [headers, selectedData]);

  const createHeaderRows = useMemo(() => {
    let temp = [...columns];
    if (selectedData.length > 0) {
      temp = columns.filter(
        (f) => selectedData.includes(f.headerKey) || f.isFirst || f.isLast
      );
    }
    return temp
      .sort((a, b) => a.order - b.order)
      .map((col) => {
        let tempStyle = { ...cellStyle, ...col.style };
        return (
          <>
            {col.isLast && col.frozen && createStickColumnsSeperator(col)}
            <div style={{ ...tempStyle, height: 40, justifyContent: "center" }}>
              {getTranslationVariantText(col.name, "caption", {
                fontWeight: "Bold",
                textTransform: "uppercase",
                width: "calc(100% - 30px)",
                textAlign: "center",
              })}
              {filterArrows({
                columnName: col.key,
                type: col.type.dataType,
                headerKey: col.headerKey,
              })}
            </div>
            {col.isFirst && col.frozen && createStickColumnsSeperator(col)}
            {col.extraColumn && createStickColumnsSeperator(col)}
          </>
        );
      });
  }, [columns, selectedData]);

  const createTotalRow = () => {
    let obj = getGroupedData(tableData);
    const totalArea = getGroupedCost(assetsList, "area");
    let temp = [...columns];
    if (selectedData.length > 0) {
      temp = columns.filter(
        (f) => selectedData.includes(f.headerKey) || f.isFirst || f.isLast
      );
    }
    return temp
      .sort((a, b) => a.order - b.order)
      .map((col) => {
        let tempStyle = { ...totalCellStyle, ...col.style };
        let value = "";
        let textColor = "#ffffff";
        if (col.type.valueType === "cost" && col.frozen) {
          textColor = "#254a9a";
        } else if (col.type.valueType === "cost") {
          textColor = "#ffffff";
        } else {
          textColor = "#000000";
        }
        if (col.type.valueType === "cost") {
          value = covertInCurrencyFormat(obj[col.key]);
        } else if (col.type.valueType === "category") {
          let tempObj = getGroupedCategories(tableData, col.key);
          value = covertInCurrencyFormat(tempObj.total);
        } else if (col.type.valueType === "trade") {
          let tempObj = getGroupedTrade(tableData, col.key);
          value = covertInCurrencyFormat(tempObj.total);
        } else if (col.type.valueType === "defect") {
          let tempObj = getGroupedDefect(tableData, col.key);
          value = covertInCurrencyFormat(tempObj.total);
        }

        if (!col.showtotal) {
          return (
            <>
              {col.isLast && col.frozen && createStickColumnsSeperator(col)}
              <div style={{ ...tempStyle, backgroundColor: "#ffffff" }} />
              {col.isFirst && col.frozen && createStickColumnsSeperator(col)}
              {col.extraColumn && createStickColumnsSeperator(col)}
            </>
          );
        } else {
          return (
            <>
              {col.isLast && col.frozen && createStickColumnsSeperator(col)}
              <div
                style={{
                  ...tempStyle,
                  justifyContent: "center",
                  backgroundColor:
                    col.type.valueType === "cost"
                      ? col.type.gridColor
                      : "#C1D0F0",
                }}
              >
                {getVariantText(value, "subtitle2", {
                  fontWeight: "Bold",
                  color: textColor,
                  textAlign: "center",
                })}
              </div>
              {col.isFirst && col.frozen && createStickColumnsSeperator(col)}
              {col.extraColumn && createStickColumnsSeperator(col)}
            </>
          );
        }
      });
  };
  const getBackgroundColor = (m, currentValue) => {
    var colors = [
      { r: 50, g: 168, b: 82 },
      { r: 242, g: 247, b: 96 },
      { r: 224, g: 9, b: 9 },
    ];

    const totalSum = m
      .map((f) => f.total)
      .reduce((x, y) => {
        return x + y;
      }, 0);

    // Step 1: Sort the array
    var sortedArray = [...m].sort((a, b) => a.total - b.total);

    // Step 2: Find the position
    var position = sortedArray.findIndex((x) => x.total === currentValue);
    if (position === -1) {
      position = sortedArray.length;
    } else {
      position = position + 1;
    }

    if (position === sortedArray.length) {
      return "#ed4a4a";
    }

    // Step 3: Determine the color range and blend factor
    var range = sortedArray.length / 2 + 1;
    var color1, color2, factor;

    if (position < range + 1) {
      color1 = colors[0];
      color2 = colors[1];
      factor = position / range;
    } else {
      color1 = colors[1];
      color2 = colors[2];
      factor = (position - range) / range;
    }
    // Step 4 and 5: Blend the colors and return
    return blendColors(color1, color2, factor);
  };

  function blendColors(color1, color2, factor) {
    var r = Math.floor(color1.r * (1 - factor) + color2.r * factor);
    var g = Math.floor(color1.g * (1 - factor) + color2.g * factor);
    var b = Math.floor(color1.b * (1 - factor) + color2.b * factor);
    return `rgb(${r}, ${g}, ${b})`;
  }

  const getTradesCollector = useMemo(() => {
    let tempTrade = getUniqueResults("trade", props.data);
    let temp = new Map();
    tempTrade.forEach((t, index) => {
      let tempArr = [];
      assetsList.forEach((f) => {
        if (!!f.trade[t.value]) {
          tempArr.push({
            assetName: f.title,
            total: f.trade[t.value].total,
          });
        }
      });
      temp.set(t.value, tempArr);
    });
    return temp;
  }, [getUniqueResults, props.data, assetsList]);

  const createDataRows = (m, index) => {
    let tradesCollector = getTradesCollector;
    let temp = [...columns];
    if (selectedData.length > 0) {
      temp = columns.filter(
        (f) => selectedData.includes(f.headerKey) || f.isFirst || f.isLast
      );
    }
    return temp
      .sort((a, b) => a.order - b.order)
      .map((col) => {
        let tempStyle = { ...cellStyle, ...col.style };
        let value = "";
        if (col.type.valueType === "cost") {
          value = covertInCurrencyFormat(m[col.key]);
        } else if (col.type.valueType === "category") {
          value = covertInCurrencyFormat(m.category[col.key]?.total);
        } else if (col.type.valueType === "trade") {
          value = covertInCurrencyFormat(m.trade[col.key]?.total);
        } else if (col.type.valueType === "defect") {
          value = covertInCurrencyFormat(m.defect[col.key]?.total);
        } else {
          value = m[col.key];
        }

        return (
          <>
            {col.isLast && col.frozen && createStickColumnsSeperator(col)}
            <div
              style={{
                ...tempStyle,
                minHeight: 40,
                whiteSpace: "pre-wrap",
                backgroundColor:
                  col.type.valueType === "trade"
                    ? getBackgroundColor(
                        tradesCollector.get(col.key),
                        m.trade[col.key]?.total
                      )
                    : "#ffffff",
              }}
            >
              {getVariantText(value, "subtitle2", {
                fontWeight: col.frozen ? "bold" : "normal",
                textTransform: "capitalize",
                color: col.frozen ? "#254a9a" : "#000000",
              })}
            </div>
            {col.isFirst && col.frozen && createStickColumnsSeperator(col)}
            {col.extraColumn && createStickColumnsSeperator(col)}
          </>
        );
      });
  };

  const getHeight = useMemo(() => {
      if(assetsList.length <= 9){
      return (assetsList.length +1) * 42.5 + 140
    }else{
      return 600
    }
  }, [assetsList]);

  const createStruct = () => {
    return (
      <>
        {assetsList.length > 0 && (
          <>
            <List
              className={"custom-scrollBar p-0"}
              style={{ width: "100%", overflowX: "auto", height: getHeight }}
            >
              <ListItem
                divider
                style={{
                  position: "sticky",
                  top: 0,
                  height: 40,
                  zIndex: 1600,
                  borderRadius: 4,
                  left: 0,
                  width: "100%",
                  margin: 5,
                  backgroundColor: "#ffffff",
                }}
                className="p-0"
                key="headers"
              >
                <>
                  <Box
                    className="d-inline-flex align-items-center h-100"
                    style={{ width: "100%", height: 40 }}
                  >
                    {renderFilterOptions()}
                  </Box>
                </>
              </ListItem>
              <ListItem
                divider
                style={{
                  position: "sticky",
                  top: 40,
                  height: 40,
                  marginBottom: 2,
                  zIndex: 1600,
                  borderRadius: 4,
                  width: "max-content",
                  backgroundColor: "#ffffff",
                }}
                className="p-0"
                key="headers"
              >
                <>
                  <Box
                    className="d-inline-flex align-items-center h-100"
                    style={{
                      width: "max-content",
                      height: 30,
                      marginBottom: 5,
                    }}
                  >
                    {createParentHeaderRows}
                  </Box>
                </>
              </ListItem>
              <ListItem
                divider
                style={{
                  position: "sticky",
                  top: 79,
                  height: 40,
                  borderRadius: 4,
                  width: "max-content",
                  zIndex: 1600,
                  backgroundColor: "#ffffff",
                }}
                className="p-0"
                key="headers"
              >
                <>
                  <Box
                    className="d-inline-flex align-items-center h-100"
                    style={{ width: "max-content", height: 40 }}
                  >
                    {createHeaderRows}
                  </Box>
                </>
              </ListItem>
              <ListItem
                divider
                style={{
                  height: 40,
                  zIndex: 1500,
                  borderRadius: 4,
                  border: "1px solid #0000001f",
                  width: "max-content",
                }}
                className="p-0"
                key="headers-total"
              >
                <>
                  <Box
                    className="d-inline-flex align-items-center h-100"
                    style={{ width: "max-content", minHeight: 40 }}
                  >
                    {createTotalRow()}
                  </Box>
                </>
              </ListItem>
              {assetsList.map((m, index) => {
                return (
                  <ListItem
                    key={index}
                    className="p-0"
                    style={{
                      border: "1px solid #0000001f",
                      width: "max-content",
                    }}
                  >
                    <>
                      <Box
                        className="d-inline-flex align-items-center h-100"
                        style={{ width: "max-content", minHeight: 40 }}
                      >
                        {createDataRows(m, index)}
                      </Box>
                    </>
                  </ListItem>
                );
              })}
              <ListItem
                divider
                style={{
                  position: "sticky",
                  bottom: 0,
                  height: 20,
                  borderRadius: 4,
                  width: "max-content",
                  zIndex: 1600,
                  backgroundColor: "#ffffff",
                }}
                className="p-0"
                key="headers"
              ></ListItem>
            </List>
          </>
        )}
      </>
    );
  };
  return (
    <Box style={{ width: "100%", overflow: "hidden" }}>
      {tableData.length > 0 && assetsList.length > 0 && createStruct()}
    </Box>
  );
};

CapexCostTable.propTypes = {
  data: PropTypes.object,
};

export default CapexCostTable;
