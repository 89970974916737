import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";
export const initialState = {
  hasErrors: false,
  error: { ...response },
  fetch: { ...response },
  fetchCompare: { ...response },
  save: { ...response },
  custom: { ...response },
  customDelete: { ...response },
  currentData: {},
};

const versionDataSlice = createSlice({
  name: "versionData",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetch: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getFetchCompareSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchCompare: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getCustomSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      custom: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomUpdate: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      custom: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomDelete: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customDelete: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    setCurrentData: (state, { payload }) => ({
      ...state,
      currentData: payload,
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

export const {
  resetState,
  getFailure,
  getFetchSuccess,
  getCustomSuccess,
  getCustomUpdate,
  getSaveSuccess,
  getFetchCompareSuccess,
  getCustomDelete,
  setCurrentData,
} = versionDataSlice.actions;

// A selector
export const versionDataSelector = (state) => state.versionData;

// The reducer
export default versionDataSlice.reducer;
