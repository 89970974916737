import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  error:{
    type: "",
   messages: [],
   data: {},
 },
 grid:{
  type: "",
  messages: [],
  data: {},
 },
  tab: {},
  optionalSubCategories: [],
  navLinks: {
    type: "",
    messages: [],
    data: {},
  },
updatedRows:[],
filters: {
  costType:{
    selected:[]
  },
  riskType:{
    selected:[]
  },
  defectType:{
    selected:[]
  },
  priorityType:{
    selected:[]
  },
},
fullMode: false,
}

const keyFindingSlice = createSlice({
  name: "tddKeyFinding",
  initialState,
  reducers: {
    getNavLinkSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      navLinks: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }
    }),
    getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      grid:{
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
     }
    }),
    setTab: (state, { payload }) => ({
      ...state,
      tab: payload,
    }),
    setOptionalSubCategories: (state, { payload }) => ({
      ...state,
      optionalSubCategories: payload,
    }),
    resetState: (state) => {
      return {
        ...initialState,
        tab: state.tab,
        fullMode: state.fullMode,
        optionalSubCategories: state.optionalSubCategories,
      }
    },
    setUpdatedRows: (state, { payload }) => ({
      ...state,
      updatedRows: payload,
    }),
    setFullMode: (state, { payload }) => ({
      ...state,
      fullMode: payload,
    }),
    setFilters: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
  },
});

// eslint-disable-next-line max-len
export const { setTab,setFullMode, resetState,getNavLinkSuccess,getFailure, getFetchSuccess,setOptionalSubCategories,setUpdatedRows,setFilters } = keyFindingSlice.actions;

// A selector
export const keyFindingSelector = (state) => state.tddKeyFinding;

// The reducer
export default keyFindingSlice.reducer;
