import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../error/errorHandlingSlice";
import {
  getFailure,
  getMultipleUpdateSuccess,
} from "../guru-suite/tdd-module/feature-bundle/defects/defectSlice.js";
import { renewAccessTokenIfGoingExpire } from "./authApi";
import { createAndDownloadBlobFile } from "../utils/helper.js";

export const createDefectsWithExcel = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `defect/createOrUpdateDefectFromExcel/${request.projectIdent}/${request.assetIdent}/${request.subAssetIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: request.excelRows,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getMultipleUpdateSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};

export const getDefectTemplate = (asset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/defect/fetch-defect-template`,
        responseType: "arraybuffer",
        headers: {
          Authorization: tokenHeader,
          Accept: "application/xlsx",
        },
      })
    );
    if (!!response) {
      createAndDownloadBlobFile(
        response.data,
        "defect_creation_template",
        "xlsx"
      );
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};
