import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDocumentContextHook } from './DocumentBuilderContext';
import { Box, CardContent, IconButton, List, ListItem, ListItemButton, ListItemText, Slide, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomIconButton from '../../../components/buttons/icons-buttons/CustomIconButton';
import { previewRender, renderNode, renderNodeSettings } from '../../../utils/customReportHelper';
import { getIntlTranslation } from '../../../utils/helper';

const TreeNode = ({ node, index }) => {
    const { editItem, selectedItem, setSelectedItem } = useDocumentContextHook();
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };
  
    return (
      <div key={node.id}>
        <ListItem
          className="p-1 action-item"
          key={node.id}
          id={node.id}
        >
          <Box className="d-flex flex-column align-items-center w-100 list-item">
            <Box className="d-flex align-items-center w-100">
              <ListItemButton
                className="w-100 d-flex align-items-center p-0 justify-content-start"
                onClick={(e) => {
                  if (!node.nodeType.includes("data")) {
                    setSelectedItem({
                      ...node,
                      index: index,
                    });
                  }
                }}
                key={node.id}
              >
                <ListItemText primary={previewRender({...node,index:index})} />
              </ListItemButton>
            </Box>
          </Box>
        </ListItem>
        <Box>
        </Box>
        {node.children?.length > 0 && (
          <Slide direction="left" in={!collapsed} mountOnEnter unmountOnExit
          timeout={{ enter: 1000,exit:500, easing: 'easeInOut' }}
          >
          <List
            className="p-0"
          >
            {node?.children.map((child, i) => (
              <TreeNode
                index={`${index}.${i + 1}`}
                node={{ ...child, parentId: node.id }}
              />
            ))}
          </List>
          </Slide>
        )}
      </div>
    );
  };
  

const PreviewContainer = props => {
    const {documentItems, selectedItem} = useDocumentContextHook();
  return (
    <CardContent
    className="custom-scrollBar p-0"
    style={{
      width: "98%",
      overflowY: "auto",
      overflowX: "auto",
      height: "100%",
    }}
    id={`${documentItems.id}-container`}
  > 
    <List id={`${selectedItem?.id}-container`} className='p-1'>
   {
        documentItems.children.map((m,index) => {
            return ( <TreeNode
                key={`${m.id}-${index}`}
                node={m}
                index={index + 1}
              />)
        })}
        </List>
        </CardContent>
  )
}

PreviewContainer.propTypes = {}

export default PreviewContainer