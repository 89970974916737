import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../error/errorHandlingSlice";
import {
  getFailure,
  getCreateSuccess,
} from "../guru-suite/component-bundle/create-project/createProjectSlice";
import { renewAccessTokenIfGoingExpire } from "./authApi";

export const createProject = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `${request.api}/projects/createOrUpdateProject`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: request.data,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getCreateSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
