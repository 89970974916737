import React, { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import GuruEditor from "ckeditor5-custom-build";
import {
  Box,
  AppBar,
  IconButton,
  Toolbar,
  CardContent,
  Card,
  CardActions,
} from "@mui/material";
import {
  getDialogBoxHeader,
  getIntlTranslation,
  commentsEditorConfig,
  customMentionItemRenderer,
  getMentionFeedItems,
} from "../../../../../utils/helper";
import SendIcon from "@mui/icons-material/Send";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import CustomButton from "../../../../../components/buttons/CustomButton";
import CloseIcon from "@mui/icons-material//Close";
import { useSelector } from "react-redux";
import Popper from '@mui/material/Popper';
import { useIntl } from "react-intl";

const CommentsPopup = ({ id, anchorEl, onClose, node, onSave}) => {
  const usersState = useSelector((state) => state.users);
  const intl = useIntl();
  const editorRef = useRef();
  const saveComment = () => {
    const range = editorRef.current.model.createRangeIn(
      editorRef.current.model.document.getRoot()
    );

    const mentions = [];

    //iterate through the whole tree in that range (TreeWalker)
    for (const treeWalker of range.getWalker({ ignoreElementEnd: true })) {
      if (treeWalker.type === "text") {
        //the item property represents TextProxy which is not instance of node
        const node = treeWalker.item.textNode;

        if (node.hasAttribute("mention")) {
          const mention = node.getAttribute("mention");
          if (mention) {
            mentions.push(mention);
          }
        }
      }
    }
    let newComment = {
      type: 'commentValue',
      value: editorRef.current.getData(),
      propertyIdent: node.nodeIdent,
      propertyKey: node.nodeKey,
      replies: [],
      new: true,
      modified: false,
      deleted: false,
      mentionedInComments: mentions,
    };
    onSave(newComment);
  };




  const open = Boolean(anchorEl);
  const popId = open ? id : undefined;

  return (
    <Popper
        id={popId}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        style={{zIndex:1300}}
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
    <Card
    {...TransitionProps}
      className="rounded-1 w-100 m-1"
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader(`_add-comment`)}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={onClose}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <CardContent dividers className="p-1">
        <Box
          id={`editor_box_${node.ident}`}
          className="g-text-editor custom-scrollBar p-1"
          style={{
            minHeight: "200px",
            width: 400,
            height: "98%",
          }}
        >
          <CKEditor
            id={`editor_${node.ident}`}
            ref={editorRef}
            editor={GuruEditor.ClassicEditor}
            onReady={(editor) => {
              editorRef.current = editor;
            }}
            config={{
              ...commentsEditorConfig,
              placeholder: intl
              .formatMessage({ id:"_type-comments" })
              .toLowerCase(),
              mention: {
                feeds: [
                  {
                    marker: "@",
                    feed: (queryText) => getMentionFeedItems(queryText, usersState),
                    itemRenderer: customMentionItemRenderer,
                  },
                ],
              },
            }}
          />
        </Box>
      </CardContent>
      <CardActions className="p-1">
      <CustomButton
          id={`cancel-popover-${node.nodeIdent}`}
          buttonText={"_cancel"}
          variant="text"
          style={{ marginLeft: 5, height: 30 }}
          className="btn"
          tooltip={{
            title: getIntlTranslation("tooltip.cancel"),
          }}
          handleClick={(e) => {
            onClose();
          }}
        />
        <CustomButton
          id={`post_${node.ident}`}
          buttonText={"_post"}
          style={{
            margin: 5,
            color: "#254a9a",
            height: 40,
            marginLeft: "auto"
          }}
          className="btn g-btn-primary"
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={(e) => saveComment()}
        />
      </CardActions>
    </Card>
      )}
    </Popper>
  );
};

CommentsPopup.propTypes = {};

export default CommentsPopup;
