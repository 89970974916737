import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";
export const TypeHeaderTreeRender = ({ item, selectedItem, index }) => {
  const scope = useSelector((state) => state.container.scope);
  const heading = item.settings.find(
    (f) => f.language === scope.projectLanguageCode
  );
  return (
    <Box className={"d-flex w-100 align-items-baseline"}>
      <Typography
        style={{
          marginLeft: 4,
          fontSize: 12,
          fontWeight: item.id === selectedItem.id ? "bold" : "normal",
          color: item.id === selectedItem.id ? "#ffffff" : "#254a9a",
        }}
        variant="caption"
      >
        {index}
      </Typography>
      {selectedItem.ident === item.ident && (
        <Box>
        <DoubleArrowSharpIcon
          style={{
            width: 15,
            height: 15,
            margin: 2,
            color: "#ffffff",
          }}
        />
        </Box>
      )}
      <Typography
        variant="caption"
        style={{
          fontSize: 12,
          marginLeft: 5,
          fontWeight: item.id === selectedItem.id ? "bold" : "normal",
        }}
        className="p-1"
      >
        {heading.title.toUpperCase()}
      </Typography>
    </Box>
  );
};

const getPreviewStyle = (node) => {
  if (node.nodeType === "header-chapter") {
    return {
      color: "#ffffff",
      backgroundColor: "#254a9a",
      fontWeight: "bold",
      fontsize: 14,
      borderRadius: 4,
    };
  } else if (node.nodeType === "header-section" && node.depth === 2) {
    return {
      color: "#254a9a",
      backgroundColor: "#D9D9D9",
      fontWeight: "bold",
      fontsize: 13,
      marginLeft: 2,
      borderRadius: 4,
    };
  } else if (node.depth > 2) {
    return {
      color: "#254a9a",
      backgroundColor: "#ffffff",
      fontWeight: "bold",
      fontsize: 11,
      marginLeft: 2,
    };
  } else {
    return {
      color: "#254a9a",
      fontsize: 11,
    };
  }
};

export const TypeHeadersDataRender = ({ item }) => {
  const scope = useSelector((state) => state.container.scope);
  const heading = item.settings.find(
    (f) => f.language === scope.projectLanguageCode
  );
  return (
    <>
      {!item.default && (
         <Box className="d-flex align-items-center w-100 m-1" style={{height:35}}>
        <Typography
          style={getPreviewStyle(item)}
          id={item.ident}
          className="p-2 w-100 h-100"
        >
          {item.index} {heading?.title}
        </Typography>
        </Box>
      )}
    </>
  );
};

export const TypeHeadersPreviewRender = ({ item }) => {
  const scope = useSelector((state) => state.container.scope);
  const heading = item.settings.find(
    (f) => f.language === scope.projectLanguageCode
  );
  return (
    <>
      {!item.default && (
         <Box className="d-flex align-items-center w-100 m-1" style={{height:40}}>
        <Typography
          style={getPreviewStyle(item)}
          id={item.ident}
          className="p-2 w-100 h-100"
        >
          {item.index} {heading?.title}
        </Typography>
        </Box>
      )}
    </>
  );
};
