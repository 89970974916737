import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tooltip,
} from "reactstrap";
import { Avatar, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LogoutIcon from "@mui/icons-material/Logout";
import { reset } from "../../authentication/authSlice";
import { useNavigate } from "react-router-dom";
import IntlMessages from "../../utils/IntlMessages";
import UserProfile from "../../user-profile/UserProfile";
import { getCurrentUser } from "../../authentication/authTokenService";
import {setProjectClose}  from "./containerSlice"
import {resetState}  from "./containerActionSlice"
import {resetOpenAssetState} from "../../guru-suite/component-bundle/assetSwitcher/OpenAssetSlice"

const UserProfileWidget = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getCurrentUser();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openUserProfile, setOpenUserProfile] = React.useState(false);
  const logoutUser = () => {
    dispatch(setProjectClose());
    dispatch(resetOpenAssetState());
    dispatch(resetState());
    dispatch(reset());
    window.localStorage.clear();
    window.sessionStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <div className="g-btn-elevated m-1 p-0">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className="p-0"
          >
            <Avatar
             variant="square"
              style={{ width: 25, height: 25, backgroundColor:"#244a9a",borderRadius: 4}}
            >
              <Typography variant="caption">
              {user.data.name.charAt(0).toUpperCase()}
              </Typography>
            </Avatar>
          </IconButton>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

 <MenuItem  onClick={(e) => setOpenUserProfile(true)}>
 <AssignmentIndIcon
                style={{width: 20, height: 20, color:"#0000008a" }}
              />
              <Typography variant="subtitle" style={{marginLeft:5, color:"#0000008a"}}><IntlMessages id="user.account" /></Typography>
        </MenuItem>
        <MenuItem onClick={logoutUser}>
        <LogoutIcon
                style={{ width: 20, height: 20, color:"#0000008a" }}
              />
              <Typography variant="subtitle" style={{marginLeft:5, color:"#0000008a"}}><IntlMessages id="user.signOut" /></Typography>
        </MenuItem>
        </Menu>
      </div>
      {openUserProfile && (
        <UserProfile onClose={() => setOpenUserProfile(false)} open={openUserProfile}/>
      )}
    </>
  );
};

export default UserProfileWidget;
