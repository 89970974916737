import React, { useEffect, useState } from "react";
import {
  CardContent,
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { scroller } from "react-scroll";
import useChapterHook from "../report-component/hooks/useChapterHook";
import { renderNode } from "../report-component/reportItemRender";
import { useReviewContextHook } from "./ReviewContext";
import useSaveDocumentMapFireBase from "../report-component/hooks/firebase/useSaveDocumentMapHook";

const scrollTo = function (id) {
  scroller.scrollTo(`${id}`, {
    containerId: "review-data-container",
    horizontal: false,
    isDynamic: true,
    duration:500,
    offset: -3,
    smooth: true,
    spy: true,
  });
};

const TreeNode = ({ node, index, chapterIdent }) => {
  const {selectedItem, onSelectedItem} = useReviewContextHook();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onItemSelection = (item) => {
    if (item.type.includes("header-chapter")) {
      let firstChild = item.children[0];
      item = {
        ...firstChild,
        parentId: item.parentId,
        chapterIdent:item.ident,
        id: firstChild.default ? item.ident : firstChild.ident,
      };
    }
   if(selectedItem.ident !== item.ident || selectedItem.item !== item.parentId) {
      toggleCollapse();
      onSelectedItem(item);
    }
    setTimeout(() => {
      scrollTo(node.id);
    }, 500);
  
  };

  const checkDataNodes = () => node.children?.filter((f) => !f.type.includes("data-") && !f.default).length > 0;
  
  return (
    <div
      key={node.id}
      className={`w-100 ${
        selectedItem.parentId === node.id ? "g-selected-parent-list p-1" : ""
      }`}
      style={{ borderRadius: 4 }}
    >
      <ListItem
        className={`p-1 list-item m-0 w-100 ${
          selectedItem.id === node.id ? "g-selected-list-item" : ""}
        `}
        key={node.id}
      >
        <Box className="d-flex flex-column align-items-center w-100">
          <Box className="d-flex align-items-center w-100">
            <ListItemButton
              className={`d-flex align-items-center p-0 justify-content-start`}
              onClick={(e) => onItemSelection(node)}
              key={`lb-${node.id}`}
            >
              <Box
                className={"d-flex w-100 align-items-center ml-1"}
                style={{ width: "80%" }}
              >
                <ListItemText primary={renderNode(node, selectedItem, index)} />
              </Box>
            </ListItemButton>
          </Box>
        </Box>
      </ListItem>
      {node.children?.length > 0 && (
          <List className="p-0" dense>
            {node.children.map((child, i) => {
              if (child.children?.length > 0) {
                child = {
                  ...child,
                  children: child.children.map((f) => {
                    return { ...f, header: f.default ? false : true };
                  }),
                };
              }
              if (child.type.includes("data-") || child.default) {
                return <></>;
              }
              index = `${index}`.replace(/\.$/, "");
              return (
                <TreeNode
                  index={`${index}.${i + 1}.`}
                  key={`${index}-${node.id}-${i + 1}.`}
                  node={{ ...child, parentId: node.id, id: child.ident, chapterIdent:chapterIdent }} 
                />
              );
            })}
          </List>
      )}
    </div>
  );
};

const ReviewDocumentMap = () => {
  const [documentTree, setDocumentTree] = useState([]);
  const {reportType, selectedItem, onSelectedItem} = useReviewContextHook();
  const { chapterList} = useChapterHook({
    reportType: reportType,
  });


  const {saveDocumentTreeToFirebase} = useSaveDocumentMapFireBase({
    reportType: reportType,
  });


  const appendPathToNodes = (node, parent = null, parentPath="" , order=0) => {
    const currentPath = `${parentPath ? parentPath + '/' : ''}${node.ident || node.nodeType}`;
    let tempKey =  node.settings.length > 0 ? node.settings?.find((f) => f.language === "de")?.value : node.key;
     const newNode  = {
      node: tempKey,
      ident: node.ident,
      nodeType: node.nodeType,
      path: currentPath,
      order: order,
      reviewComplete: node.reviewComplete || false,
      parentIdent: !parent ? node.ident : parent.ident,
  };

    if (node.children && node.children.length > 0) {
              newNode.children =  node.children.map((child,index) => {
                if(!child.type.includes("data-")){
                return appendPathToNodes(child, node, currentPath, index+1)
                }else{
                  return null;
                }
              
              }
              );
    }
    return newNode;
  }

  useEffect(() => {
    if (chapterList.length > 0) {
      let tree = []
      let chapterIndex = 0;
      const headers = chapterList
        .map((m) => m.reportDataNode)
        .map((c) => {
          if (c.header) {
            chapterIndex = chapterIndex + 1;
            c = { ...c, index: chapterIndex };
          } else {
            c = { ...c, index: null };
          }
          tree.push(appendPathToNodes(c));
          return { ...c, id: c.ident };
        });
      setDocumentTree(headers);
      saveDocumentTreeToFirebase(tree, reportType);
    }
  }, [chapterList]);

  useEffect(() => {
    if(documentTree.length > 0 && !selectedItem.ident) {
      let item = documentTree[0];
      if (item.type.includes("header-chapter")) {
        let firstChild = item.children[0];
        item = {
          ...firstChild,
          parentId: item.ident,
          chapterIdent:item.ident,
          id: firstChild.default ? item.ident : firstChild.ident,
          selectedIndex: firstChild.default ? '1' : '1.1.',
        };
      }
      onSelectedItem(item);
      setTimeout(() => {
        scrollTo(item.id);
      }, 200);
    }
    }, [documentTree])

  return (
    <>
      <CardContent
        className="custom-scrollBar p-1"
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100% - 50px)",
        }}
        id={`review-document-map`}
      >
        {!!documentTree && documentTree.length > 0 && (
          <List className="p-0 w-100" dense>
            {documentTree?.map((item, index) => {
              item = {
                ...item,
                children: item.children.map((f) => {
                  return { ...f, header: f.default ? false : true };
                }),
              };
              if (item.type.includes("data-") || item.default) {
                return <></>;
              }
              return (
                <TreeNode
                  key={`${item.id}-${index}`}
                  node={{...item, parentId:item.id,chapterIdent:item.id}}
                  index={item.index}
                  chapterIdent={item.id}
                />
              );
            })}
          </List>
        )}
      </CardContent>
    </>
  );
};

ReviewDocumentMap.propTypes = {};

export default ReviewDocumentMap;
