import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import { isOpen } from "./DocumentMap";
import AddChapterFnc from "./AddChapterFnc";
import Box from "@mui/system/Box";
import { useDispatch, useSelector } from "react-redux";
import { addNewSection } from "../../../api/customChaptersApi";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RenderSubSection from "./RenderSubSection";

const RenderSection = ({
  o,
  activeSection,
  activeSubSection,
  index,
  handleSUbSectionSelection,
  handleSectionSelection,
  setActiveSection,
  setActiveSubSection,
  current,
}) => {
  const [sectionData, setSectionData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const scope = useSelector((state) => state.container.scope);
  const chapter = useSelector((state) => state.tddChapters);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!o && o.children.length) {
      setSectionData(o.children.filter((f) => !f.isDefault));
    }
  }, [o]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = (values, { resetForm }) => {
    const order = sectionData.length + 1;
    const projectIdent = scope.projectIdent;
    const data = {
      type: "custom-section",
      custom: {
        type: "custom",
        name: values.german?.toUpperCase(),
        node: "section",
        order: order,
        parentIdent: o.ident,
        reportType: chapter.customType, // FULL REPORT or RED FLAG
        nameTranslation: [
          {
            type: "title",
            title: values.english?.toUpperCase(),
            language: "english", //german,deutsch,english
            value: values.german?.toUpperCase(),
          },
          {
            type: "title",
            title: values.german?.toUpperCase(),
            language: "deutsch", //german,deutsch,english
            value: values.german?.toUpperCase(), // always german one
          },
        ],
      },
      subSectionList: values.option.map((el, index) => {
        return {
          type: "custom",
          name: el.optionGerman?.toUpperCase(),
          node: "sub-section",
          order: index,
          parentIdent: "",
          reportType: chapter.customType, // full or red flag,
          nameTranslation: [
            {
              type: "title",
              title: el.optionEnglish?.toUpperCase(),
              language: "english", //german,deutsch,english
              value: el.optionGerman?.toUpperCase(),
            },
            {
              type: "title",
              title: el.optionGerman?.toUpperCase(),
              language: "deutsch", //german,deutsch,english
              value: el.optionGerman?.toUpperCase(), // always german one
            },
          ],
        };
      }),
    };
    dispatch(addNewSection(data, projectIdent));
    resetForm();
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginLeft: 0.5,
      }}
    >
      <List component="div" disablePadding sx={isOpen}>
        {sectionData.map((s, s_index) => {
          return (
            <>
              <Box>
                <Card
                  sx={{
                    width: "100%",
                    marginTop: "2px",
                    minHeight: 40,
                    padding: 1,
                  }}
                >
                  <ListItemButton
                    style={activeSection.index === s.ident ? {...isOpen, marginRight:5} : {marginRight:5}}
                    selected={activeSection.index === s.ident}
                    onClick={(e) => handleSectionSelection(s)}
                  >
                    <ListItemIcon>
                      <Box style={{ color: "#5f9ea0", marginRight: "2px" }}>
                        {current.ident === s.ident && <DoubleArrowSharpIcon />}
                      </Box>
                      {current.ident !== s.ident && (
                        <Typography
                          variant="body1"
                          style={{ color: "#254a9a" }}
                        >{`${index}.${s_index + 1}`}</Typography>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {s.name?.toLowerCase().startsWith("default") ? "default" : s.name}
                        </Typography>
                      }
                    />
                    {s.children.length > 0 &&
                      (activeSection.index === s.ident ? (
                        <ExpandMore />
                      ) : (
                        <ChevronRightIcon />
                      ))}
                  </ListItemButton>
                </Card>
                <Collapse
                  direction="up"
                  in={activeSection.index === s.ident}
                  mountOnEnter
                  unmountOnExit
                >
                  <RenderSubSection
                    s={s}
                    activeSubSection={activeSubSection}
                    handleSUbSectionSelection={handleSUbSectionSelection}
                    index={index}
                    s_index={s_index}
                    current={current}
                  />
                </Collapse>
              </Box>
            </>
          );
        })}
      </List>

      <AddChapterFnc
        type="Section"
        handleSubmit={handleSubmit}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        openDialog={openDialog}
      />
    </Box>
  );
};

export default RenderSection;
