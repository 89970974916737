import { createSlice } from "@reduxjs/toolkit";
import { splitArray } from "../../../utils/helper";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  error: {},
  data: null,
  commentList:[],
  selectedCommentNode: null,
  reviewCommentList:[],
};


const removeDuplicateComments = (state,payload) => {
let temp = [...state.commentList];

payload.forEach(p => {
  let index = temp.findIndex((f) => (p.ident && p.ident === f.ident && p.reportType === f.reportType) || (!p.ident && p.id === f.id && p.reportType === f.reportType))
  if(index !== -1){
    temp[index] = p;
  } else{
    temp.push(p)
  }
  
});
return temp;
}
const commentsSlice = createSlice({
  name: "commentSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
      data: {},
    }),
    getCommentListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      error: null,
      data: payload.guruDto,
    }),
    getCommentSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      error: null,
      data: payload.guruDto,
    }),
    resetCommentListState: (state) => ({
      ...initialState,
  }),
    resetCommentState: (state) => ({
      ...initialState,
      commentList:state.commentList,
      selectedCommentNode:state.selectedCommentNode,
  }),
  setSelectedCommentNode: (state, { payload }) => ({
    ...state,
    selectedCommentNode:payload,
}),
  addCommentList: (state, { payload }) => ({
    ...state,
    commentList:removeDuplicateComments(state, payload)
}),
updateCommentList: (state, { payload }) => ({
  ...state,
  commentList:payload,
}),
}
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  getCommentListSuccess,
  getCommentSuccess,
  resetCommentState,
  addCommentList,
  setSelectedCommentNode,
  updateCommentList,
  resetCommentListState,
} = commentsSlice.actions;

// The reducer
export default commentsSlice.reducer;
