import React, {useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../api/customAxios";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {
    getFailure,
    getCommentListSuccess,
    resetCommentState,
    updateCommentList,
  } from "../comments/commentSlice.js";

const  useCommentSave = () => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);

  const dispatch = useDispatch();

  const onCommentsSave = useCallback(async (request) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "post",
          url: `${scope.api}/comments/${request.commentsApi}/${scope.projectIdent}/${request.apiIdent}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
          data: {...request.data,
            id: `${openAsset.ident}-${request.commentsApi}`,
            key: `${request.data.section}-${openAsset.ident}`,
            apiIdent:request.apiIdent,
          }
            
    })
      );
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getCommentListSuccess(response.data));
      }
    } catch (error) {
      if (error.response || error.request) {
        let payload = {};
        payload = {
          type: "ERROR",
          messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact it.support@taeurope.com",
        error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
      }
    }
  },[dispatch,scope.api, scope.projectIdent]);

 

  const onResetResponse = () => {
   dispatch(resetCommentState());
  };
  return [onCommentsSave, onResetResponse];
}

export default useCommentSave;
