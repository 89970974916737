export const columnTypes = [
  {
    type: "string",
    inputProps: {
      type: "text",
    },
    operatiorOptions: [
      {
        label: "contains",
        value: "contains",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) =>
            f[col].toLowerCase().includes(val.toLowerCase())
          );
        },
      },
      {
        label: "starts with",
        value: "startsWith",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) =>
            f[col].toLowerCase().startsWith(val.toLowerCase())
          );
        },
      },
      {
        label: "ends with",
        value: "endsWith",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) =>
            f[col].toLowerCase().endsWith(val.toLowerCase())
          );
        },
      },
      {
        label: "equals",
        value: "equals",
        filterFunction: (filterList, col, val) => {
          return filterList.filter(
            (f) => f[col].toLowerCase() === val.toLowerCase()
          );
        },
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => !!f[col]);
        },
      },
      {
        label: "is empty",
        value: "isEmpty",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => !f[col].toLowerCase());
        },
      },
    ],
  },
  {
    type: "number",
    inputProps: {
      type: "number",
    },
    operatiorOptions: [
      {
        label: "=",
        value: "=",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => Number(f[col]) === Number(val));
        },
      },
      {
        label: "!=",
        value: "!=",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => Number(f[col]) !== Number(val));
        },
      },
      {
        label: "<",
        value: "<",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => Number(f[col]) < Number(val));
        },
      },
      {
        label: "<=",
        value: "<=",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => Number(f[col]) <= Number(val));
        },
      },
      {
        label: ">",
        value: ">",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => Number(f[col]) > Number(val));
        },
      },
      {
        label: ">=",
        value: ">=",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => Number(f[col]) >= Number(val));
        },
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => !!Number(f[col]));
        },
      },
      {
        label: "is empty",
        value: "isEmpty",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => !Number(f[col]));
        },
      },
    ],
  },
  {
    type: "date",
    inputProps: {
      type: "date",
      min: "1900-01-01",
      max: "2099-12-31",
      step: "1",
      required: true,
    },

    operatiorOptions: [
      {
        label: "is",
        value: "is",
        filterFunction: (filterList, col, val) => {
          return filterList.filter(
            (f) => new Date(f[col]).getTime() === new Date(val).getTime()
          );
        },
      },
      {
        label: "is not",
        value: "isNot",
        filterFunction: (filterList, col, val) => {
          return filterList.filter(
            (f) => new Date(f[col]).getTime() !== new Date(val).getTime()
          );
        },
      },
      {
        label: "is after",
        value: "isAfter",
        filterFunction: (filterList, col, val) => {
          let temp = filterList.filter(
            (f) => new Date(f[col]).getTime() > new Date(val).getTime()
          );
          return temp;
        },
      },
      {
        label: "is on or after",
        value: "isOnOrAfter",
        filterFunction: (filterList, col, val) => {
          return filterList.filter(
            (f) => new Date(f[col]).getTime() >= new Date(val).getTime()
          );
        },
      },
      {
        label: "is before",
        value: "isBefore",
        filterFunction: (filterList, col, val) => {
          return filterList.filter(
            (f) => new Date(f[col]).getTime() < new Date(val).getTime()
          );
        },
      },
      {
        label: "is on or before",
        value: "isOnOrBefore",
        filterFunction: (filterList, col, val) => {
          return filterList.filter(
            (f) => new Date(f[col]).getTime() <= new Date(val).getTime()
          );
        },
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => !!new Date(f[col]).getTime());
        },
      },
      {
        label: "is empty",
        value: "isEmpty",
        filterFunction: (filterList, col, val) => {
          return filterList.filter((f) => !new Date(f[col]).getTime());
        },
      },
    ],
  },
];
