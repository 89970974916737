import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  AppBar,
  Toolbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AssetSwitcher from "../../../../component-bundle/assetSwitcher/AssetSwitcher";
import {
  getTranslationVariantText,
  getVariantText,
} from "../../../../../utils/helper";
import { usePhotoDocumentationContextHook } from "../PhotoDocumentationContext";

const HeaderContainer = () => {
  const dispatch = useDispatch();
  const { isDataUpdated, setBlockAssetChange } =
    usePhotoDocumentationContextHook();
  const state = useSelector((state) => state.monitoringPhotoDocumentation);
  const [currentVersion, setCurrenVersion] = useState(null);

  useEffect(() => {
    if (!!state.currentVersion && !!state.currentVersion.ident) {
      setCurrenVersion(state.currentVersion);
    }
  }, [state.currentVersion]);

  return (
    <>
      <Box className={"h-100 w-100"}>
        <Box
          className="d-flex align-item-center justify-content-start"
          style={{ height: 50 }}
        >
          <div style={{ width: "25%" }}>
            <AssetSwitcher
              dirtyState={isDataUpdated()}
              setBlockState={(val) => {
                setBlockAssetChange({
                  block: true,
                  newAsset: val,
                });
              }}
            />
          </div>
          {!!currentVersion && (
            <div style={{ width: "75%" }} className={"p-1"}>
              <AppBar
                className={"appBar"}
                style={{
                  zIndex: 2000,
                  height: 40,
                  width: "100%",
                  borderRadius: 4,
                }}
              >
                <Toolbar className={"dialog-toolbar"} style={{ height: 40 }}>
                  <div className="d-flex align-items-center justify-content-start flex-wrap">
                    {getTranslationVariantText("version", "body2", {
                      color: "#ffffff",
                    })}
                    <>
                      {getVariantText(
                        ` : ${currentVersion?.versionName}`,
                        "body2",
                        { color: "#ffffff" }
                      )}
                    </>
                  </div>
                </Toolbar>
              </AppBar>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HeaderContainer;
