/* eslint-disable react/no-array-index-key */
import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Typography from "@mui/material/Typography";
import { globalRoot } from "../../utils/defaultValues";
import { getRoutes } from "../../routes/appRouter";
import { NavLink, useLocation, matchPath } from "react-router-dom";

const getPaths = pathname => {
  const paths = ['/']
  if (pathname === '/') return paths;
  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`
    paths.push(currPath)
    return currPath
  })
  return paths
}

const BreadcrumbRouteItem = (route, fullCurrPath) => {
  if (route.currPath === fullCurrPath) {
    return (
      <BreadcrumbItem key={route.currPath} active className={"breadcrumb_item m-0"}>
        {route.icon}
        <Typography variant="caption" style={{color:"#ffffff"}}>{route.name}</Typography>
      </BreadcrumbItem>
    );
  } else {
    return (route.showBreadCrumbs &&
      <BreadcrumbItem key={route.currPath} className={"breadcrumb_item m-0"}>
        <NavLink to={route.path}>
        {route.icon}
          <Typography variant="caption"  style={{color:"#ffffff"}}>{route.name}</Typography>
        </NavLink>
      </BreadcrumbItem>
    );
  }
};

const BreadcrumbContainer = (props) => {
  const routes = getRoutes();
  const location = useLocation();
  function buildBreadCrumbs() {
    let items = [];
    const currPath = location.pathname;
    const paths = getPaths(currPath);
    const currRoutes = paths
      .map((currPath) => {
        const route = routes.find((route) =>
          matchPath(
            {
              path: route.path,
              exact: route.exact,
            },
            currPath
          )
        );
        return { ...route, currPath };
      })
      .filter((route) => route && route.name);

      const uniqueRoutes = currRoutes.reduce(
        (unique, item) =>
          unique.find((v) => v.id === item.id) ? unique : [...unique, item],
        []
      );

    items = uniqueRoutes.map((route) => {
      return BreadcrumbRouteItem(route, currPath);
    });
    return items;
  }
  return (
    <div className="m-1">
      <Breadcrumb className="breadcrumb-container d-none d-sm-block d-lg-inline-block">
        {routes.length > 0 && buildBreadCrumbs()}
      </Breadcrumb>
    </div>
  );
};

BreadcrumbContainer.propTypes = {};

export default BreadcrumbContainer;
