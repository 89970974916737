import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Outlet } from "react-router-dom";
import {
  Alert,
} from "@mui/material";
import{getTranslationVariantText} from "../../utils/helper"

const ProjectmonitoringModuleHome = () => {
  const state = useSelector((state) => state.readOnly);

  return (
    <>
     {state.isReadOnly &&
            <Alert variant="filled" severity="error" className="w-100 p-1">
            {getTranslationVariantText("_readOnly_mode","caption")}
          </Alert>
          }
      <Outlet />
    </>
  );
};
export default ProjectmonitoringModuleHome;

