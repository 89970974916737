import React from "react";
import CostDataContainer from "./structure/CostDataContainer";
import Grid from "@mui/material/Grid";

const CostDataHome = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <CostDataContainer />
      </Grid>
    </Grid>
  );
};
export default CostDataHome;
