import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import clsx from "clsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../components/buttons/CustomButton";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import InputSearch from "../../../../../components/InputSearch";
import { getVariantText, getIntlTranslation } from "../../../../../utils/helper";
import EditPropertyLabel from "../../../version-comparison/EditPropertyLabel";
import { useCategoriesData } from "../context/CategoriesDataContext";
import { useSelectedProperty } from "../context/SelectedPropertyContext";
import AddCustomPropertiesContainer from "./AddCustomPropertiesContainer";
import { useKeyFindingsContext } from "../context/KeyFindingsContext";

export const getBorderColor = (p) => {
  if (p) {
    return {
      borderLeft: "4px solid rgb(211, 0, 56)",
      backgroundColor: "rgba(255, 42, 81, 0.19)",
    };
  } else {
    return {};
  }
};

const DraggableListItem = ({ id, node, activeIndex, overlay = false }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
    index,
  } = useSortable({ id });
  const {selectedCategory, setSelectedCategory } = useSelectedProperty();
  const { onCategoryDeleteProperty, onCategoryEditProperty } = useKeyFindingsContext();
  const state = useSelector((state) => state.currentVersion);
  const [openPopOver, setOpenPopOver] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  };

  const getInserPosition =
    activeIndex !== undefined && over?.id === node.nodeIdent && !isDragging
      ? index > activeIndex
        ? "after"
        : "before"
      : undefined;

  return (
    <ListItem
      key={id}
      divider
      ref={setNodeRef}
      className={clsx("drag-item p-1", {
        overlay: overlay,
        active: isDragging,
        insertBefore: getInserPosition === "before",
        insertAfter: getInserPosition === "after",
      })}
      onClick={(e) => setSelectedCategory(node)}
      style={getBorderColor(!!state.updatedRows[node.nodeIdent])}
    >
      <Box className={`d-flex w-100 align-items-center justify-content-around`}>
        <div style={{ ...style }} {...attributes} {...listeners}>
          <DragIndicatorIcon style={{ color: "#d08f8e", fontSize: 20 }} />
        </div>
        <ListItemText
          className="w-75"
          primary={
            <div
              className="d-flex flex-wrap align-items-center justify-content-start p-1"
              style={{
                width: "100%",
                cursor: "pointer",
              }}
            >
              {selectedCategory?.nodeIdent === node.nodeIdent && (
                <div className="ml-1 mr-1">
                  <DoubleArrowIcon style={{ color: "#5f9ea0", fontSize: 20 }} />
                </div>
              )}
              {getVariantText(node.nodeTitle, "caption", {
                fontWeight: "bold",
                color: "#254a9a",
                marginLeft: 2,
                textTransform: "uppercase",
              })}
              {node.custom && (
                <>
                  <CustomIconButton
                    id={`menu_button_${node.nodeName}`}
                    icon={
                      <ArrowDropDownIcon
                        style={{ color: "#d08f8e", fontSize: 20 }}
                      />
                    }
                    permission={null}
                    handleClick={(e) => {
                      e.stopPropagation();
                      setOpenPopOver(node.nodeIdent);
                      setAnchorEl(e.currentTarget);
                    }}
                    tooltip={{
                      title: getIntlTranslation("tooltip.edit"),
                    }}
                    style={{
                      borderRadius: 0,
                    }}
                    aria-describedby={`prop_menu_${node.nodeName}`}
                  />
                  <Popover
                    id={`prop_menu_${node.nodeName}`}
                    open={openPopOver === node.nodeIdent}
                    anchorEl={anchorEl}
                    onClose={(e) => {
                      setAnchorEl(null);
                      setOpenPopOver(null);
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <EditPropertyLabel
                      property={node.original}
                      onClose={(e) => {
                        setAnchorEl(null);
                        setOpenPopOver(null);
                      }}
                      handleSave={(val) => onCategoryEditProperty([{ ...val, updated: true}])}
                      onDelete={(val) => {
                        onCategoryDeleteProperty(val);
                      }}
                    />
                  </Popover>
                </>
              )}
            </div>
          }
        />
      </Box>
    </ListItem>
  );
};

const DraggableListContainer = ({ items, onDragEnd }) => {
  const [activeId, setActiveId] = React.useState();
  const activeIndex = activeId
    ? items.findIndex((item) => item.nodeIdent === activeId)
    : undefined;

  const handleDragStart = React.useCallback(
    ({ active }) => setActiveId(active.id),
    []
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!!active && !!over && active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.nodeIdent === active.id);
      const newIndex = items.findIndex((item) => item.nodeIdent === over.id);
      onDragEnd(arrayMove(items, oldIndex, newIndex));
      setActiveId(undefined);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <List className="p-1">
          {items.map((item) => (
            <DraggableListItem
              key={item.nodeIdent}
              id={item.nodeIdent}
              node={item}
              activeIndex={activeIndex}
            />
          ))}
        </List>
      </SortableContext>
      <DragOverlay>
        {!!activeIndex && (
          <DraggableListItem
            overlay={true}
            key={items[activeIndex].nodeIdent}
            id={items[activeIndex].nodeIdent}
            node={items[activeIndex]}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};
export const KeyFindingsCategoriesContainer = () => {
  const { structuredCategories, handleReorder } =
  useCategoriesData();
  const { onAdditionalProperty,categories } = useKeyFindingsContext();
  const { selectedCategory } = useSelectedProperty();
  const [filteredCategories, setFilteredCategories] = useState({});
  const [openAddLabelDialog, setOpenAddLabelDialog] = useState(false);

  useEffect(() => {
    if (!!structuredCategories) {
      setFilteredCategories(structuredCategories.nodes);
    }
  }, [structuredCategories]);

  const filterData = useCallback(
    (search) => {
      let nodeObj = structuredCategories.nodes;
      if (!!search) {
        const result = Object.keys(nodeObj).reduce((acc, key) => {
          if (
            nodeObj[key].nodeTitle.toLowerCase().includes(search.toLowerCase())
          ) {
            acc[key] = nodeObj[key];
          }
          return acc;
        }, {});
        setFilteredCategories(result);
      } else {
        setFilteredCategories(nodeObj);
      }
    },
    [structuredCategories.nodes]
  );

  const items = useMemo(
    () => Object.keys(filteredCategories).map((key) => filteredCategories[key]),
    [filteredCategories]
  );

  return (
    <>
      <Card className="w-100 h-100">
        <Box style={{ height: 50, width: "100%", padding: 2 }}>
          <InputSearch onFilterInput={filterData} />
        </Box>
        <CardContent
          className="w-100 overflow-y-auto overflow-x-hidden custom-scrollBar p-0"
          style={{ height: "calc(100% - 100px)" }}
        >
          <DraggableListContainer
            items={items}
            onDragEnd={(items) => handleReorder(items,"category")}
          />
        </CardContent>
        <CardActions style={{ height: 40 }} className="mt-auto m-1">
          <CustomButton
            id={`add_optional_properties`}
            buttonText={"Optional Properties"}
            endIcon={<AddIcon />}
            variant="button"
            buttonColor={"inherit"}
            style={{
              margin: 5,
              height: 40,
              marginLeft: "auto",
              width: "100%",
              padding: 5,
            }}
            className="g-btn-secondary"
            tooltip={{
              title: getIntlTranslation("Optional Properties"),
            }}
            size="small"
            handleClick={(e) => setOpenAddLabelDialog(true)}
          />
        </CardActions>
      </Card>
      {openAddLabelDialog && (
        <AddCustomPropertiesContainer
          open={ openAddLabelDialog }
          selectedParent={ selectedCategory }
          data={categories} 
          onClose={ () => setOpenAddLabelDialog( false ) }
          onAddOptional={ ( data ) => {
            onAdditionalProperty( data );
            setOpenAddLabelDialog( false );
          } } 
                 />
      )}
    </>
  );
};

