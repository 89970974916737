import axios from "../../../api/customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";
import {
  getFailure,
  getSuccess,
  getSubAssetImages,
  getSaveSubAssetImage,
  getUpdateImage,
  getDeleteImage,
} from "../feature-bundle/photo-documentation/photoDocumentationSlice";

// Create sub-asset
export const createSubAsset = (projectIdent, assetIdent, subAssetDto) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await axios({
        method: "post",
        url: `/monitoring/sub-assets/create/${projectIdent}/${assetIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: subAssetDto,
      });
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getSuccess(response.data));
      }
    } catch (error) {
      if (error.response || error.request) {
        let payload = {};
        payload = {
          type: "ERROR",
          messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
      }
    }
};

export const updateSubAsset = (subAssetDto) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/monitoring/sub-assets/update`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: subAssetDto,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

// Delete sub-asset
export const deleteSubAsset = (projectIdent, ident) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await axios({
      method: "delete",
      url: `/monitoring/sub-assets/delete/${projectIdent}/${ident}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: tokenHeader,
      },
    });
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const fetchSubAssetList = (projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/api/monitoring/sub-assets/fetch-sub-assets/${projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const saveSubAssetImage = ({projectIdent, formData,versionIdent}) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "post",
          url: `/monitoring/sub-assets/upload/${projectIdent}/${versionIdent}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
          data: formData,
        })
      );
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getSaveSubAssetImage(response.data));
      }
    } catch (error) {
      if (error.response || error.request) {
        let payload = {};
        payload = {
          type: "ERROR",
          messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
      }
    }
};

export const fetchSubAsset = (projectIdent, ident) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/monitoring/sub-assets/fetch/${projectIdent}/${ident}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const updateImage = ({projectIdent, formData}) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/monitoring/sub-assets/update-image/${projectIdent}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: formData,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getUpdateImage(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const deleteImage = ({projectIdent, formData}) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/monitoring/sub-assets/delete-image/${projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: formData,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDeleteImage(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getImages = ({projectIdent, subAssetIdent,versionIdent}) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await axios({
      method: "get",
      url: `/monitoring/sub-assets/list-images/${projectIdent}/${subAssetIdent}/${versionIdent}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: tokenHeader,
      },
    });
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSubAssetImages(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
