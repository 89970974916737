import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import containerActions from './layout/app-container/containerActionSlice';
import container from './layout/app-container/containerSlice';
import system from './layout/app-container/systemSlice';
import auth from './authentication/authSlice';
import readOnly from './guru-suite/component-bundle/slice/readOnlySlice';
import errorhandling from './error/errorHandlingSlice';
import userprojects from './user-dashboard/userProjectSlice';
import userProfile from './user-profile/userProfileSlice';
import projectList from './guru-suite/component-bundle/project-list/projectListSlice';
import createProject from './guru-suite/component-bundle/create-project/createProjectSlice';
import projectDashboard from './guru-suite/component-bundle/project-dashboard/projectDashboardSlice';
import users from './guru-suite/component-bundle/users/usersSlice';
import suggestionSlice from './guru-suite/component-bundle/suggestion-values/suggestionSlice';
import assetOverviewSlice from './guru-suite/component-bundle/asset-overview/assetOverviewSlice';
import userManagement from './guru-suite/user-management/userManagementSlice';
import openAsset from './guru-suite/component-bundle/assetSwitcher/OpenAssetSlice';
import reportSlice from './guru-suite/component-bundle/generate-report/reportSlice';
import currentVersion from './guru-suite/projectmonitoring-module/version-comparison/slice/CurrentVersionSlice';
import subAssetSlice from './guru-suite/tdd-module/feature-bundle/sub-asset-management/sub-asset/subAssetSlice';
import tddBuildingProfileSlice from './guru-suite/tdd-module/feature-bundle/building-profile/tddBuildingProfileSlice';
import {
  monitoringReducers,
  monitoringBlackList,
} from './guru-suite/projectmonitoring-module/monitoringStore';
import {
  tddStoreReducers,
  tddStoreBlackList,
} from './guru-suite/tdd-module/tddStore';
import imageProcessSlice from './components/imageUploader/imageProcessSlice';
import assetSwitcherSlice from './guru-suite/component-bundle/assetSwitcher/assetSwitcherSlice';
import costDataSlice from './guru-suite/cost-data-room/costDataSlice';
import commentSlice from './guru-suite/component-bundle/comments/commentSlice';
import {
  clientManagementStoreReducers,
  clientManagementStoreBlackList,
} from './guru-suite/client-management/clientManagementStore';
import DataCollectionSlice from './guru-suite/data-collection/DataCollectionSlice';
import CostSuggestionSlice from './guru-suite/component-bundle/cost-suggestions/costSuggestionSlice';
import { documentStoreBlackList, documentStoreReducers } from './guru-suite/document-builder/documentBuilderStore';

const systemPersistConfig = {
  key: 'system',
  storage: storage,
};

const authPersistConfig = {
  key: 'authentication',
  storage: storage,
};

const actionsPersistConfig = {
  key: 'sidebar',
  storage: storage,
};

const rootReducer = combineReducers({
  authentication: persistReducer(authPersistConfig, auth),
  containerActions: persistReducer(actionsPersistConfig, containerActions),
  system: persistReducer(systemPersistConfig, system),
  errorhandling: errorhandling,
  readOnly: readOnly,
  container: container,
  userprojects: userprojects,
  userProfile: userProfile,
  projectList: projectList,
  createProject: createProject,
  projectDashboard: projectDashboard,
  users: users,
  suggestions: suggestionSlice,
  assetOverview: assetOverviewSlice,
  userManagement: userManagement,
  openAsset: openAsset,
  subasset: subAssetSlice,
  report: reportSlice,
  currentVersion: currentVersion,
  tddBuildingProfile: tddBuildingProfileSlice,
  imageProcess: imageProcessSlice,
  assetswitcher: assetSwitcherSlice,
  costData: costDataSlice,
  comments: commentSlice,
  dataCollection: DataCollectionSlice,
  costSuggestion:CostSuggestionSlice,
  ...monitoringReducers,
  ...tddStoreReducers,
  ...clientManagementStoreReducers,
  ...documentStoreReducers,
});

const rootPersistConfig = {
  key: 'data',
  storage: sessionStorage,
  blacklist: [
    'authentication',
    'errorhandling',
    'userprojects',
    'userProfile',
    'projectList',
    'createProject',
    'containerActions',
    'projectDashboard',
    'users',
    'suggestions',
    'assetOverview',
    'currentVersion',
    'tddBuildingProfile',
    'assetswitcher',
    'photoDocumentation',
    'comments',
    'imageProcess',
    'dataCollection',
    'report',
    ...monitoringBlackList,
    ...tddStoreBlackList,
    ...clientManagementStoreBlackList,
    ...documentStoreBlackList,
  ],
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({ reducer: persistedReducer });
export const persistor = persistStore(store);
