import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getLabel,
  getDialogBoxHeader,
  getIntlTranslation,
} from "../../../../../utils/helper";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { getBuildingType } from "../../../../../api/suggestionApi";
import { createMonitoringSubAsset } from "../../../../../api/assetOverviewApi";
import { resetState } from "../../../../component-bundle/asset-overview/assetOverviewSlice";
import { useSnackbar } from "notistack";
import SubAssetCreateForm from "./SubAssetCreateForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const SubAssetCreateContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.assetOverview);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const suggestionState = useSelector((state) => state.suggestions);
  const [buildingType, setBuildingType] = useState([]);
  const [createAnother, setCreateAnother] = useState(false);
  const [intialFormData,setInitalFormData] = useState({
    subAssets: [
      {
        id: Date.now(),
        name: "",
        typeOfUse: { title: "", value: null },
        addressLabel: "",
        isGeneral: false,
        order: 1,
        addressCheckbox: true,
        assetIdent: "",
      },
    ],
  });

  useEffect(() => {
    dispatch(getBuildingType());
  }, [dispatch]);

  useEffect(() => {
    if (
      suggestionState.buildingType.type === "FETCHED" &&
      suggestionState.buildingType.data.length > 0
    ) {
      setBuildingType([...suggestionState.buildingType.data]);
    }
  }, [suggestionState.buildingType]);

  useEffect(() => {
    if (state.createSubAsset.type === "SAVED") {
      state.createSubAsset.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      props.onCreate();
      props.onClose();
      dispatch(resetState());
    }
  }, [
    createAnother,
    dispatch,
    enqueueSnackbar,
    props,
    state.create.messages,
    state.createSubAsset,
  ]);

  const handleSave = (values) => {
    const subAssets = values.subAssets.map((s) => {
      return {
        type: "monitoringSubAsset",
        name: s.name,
        typeOfUse: s.typeOfUse.value,
        address: s.addressLabel,
        projectIdent: scope.projectIdent,
        assetIdent: props.asset.assetIdent,
        general: s.isGeneral,
      };
    });

    dispatch(
      createMonitoringSubAsset({
        data: subAssets,
        api: scope.api,
        assetIdent: props.asset.assetIdent,
        projectIdent: scope.projectIdent,
      })
    );
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.onClose();
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("Sub-Asset")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_asset_close"
            onClick={(e) => props.onClose()}
            style={{ marginLeft: "auto" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <SubAssetCreateForm
          intialFormData={intialFormData}
          generalExist = {!!props.asset.subAssets.find((f) => f.general)}
          handleSave={(values) => handleSave(values)}
          formRef={formRef}
          buildingType={buildingType}
        />
      </DialogContent>
      <DialogActions className="justify-content-between" style={{marginLeft:"auto"}}>
        <SaveButton
          id="create-sub-asset-button"
          buttonText="_create"
          variant="button"
          style={{ margin: 5 }}
          editDialog={false}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

SubAssetCreateContainer.defaultProps = {
  readOnly: false,
};
SubAssetCreateContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  onCreate: PropTypes.func,
  assetIdent: PropTypes.string,
  asset :PropTypes.object,
};

export default SubAssetCreateContainer;
