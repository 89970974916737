import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data:[],
};

const dataSlice = createSlice({
  name: "dataSlice",
  initialState,
  reducers: {
    promptDataSlice: (state, { payload }) => ({
        ...state,
      data:payload,
    }),
    removeDataSlice: (state, { payload }) =>{
      let temp = state.data.filter((f) => f.nodeIdent !== payload.nodeIdent);
      temp.push(payload);
      return({
      ...state,
    data:[...temp]
  })},
  selectedOptionStructure: (state, { payload }) =>{
    let temp = state.data.filter((f) => f.nodeIdent !== payload.nodeIdent);
    temp.push(payload);
    return({
    ...state,
    data: [...temp]
  })},
    resetPromptState: (state) =>({
      ...state,
      data: []
  }),
  },
});

// eslint-disable-next-line max-len
export const {
  promptDataSlice,
  addUpdatedDataSlice,
  removeDataSlice,
  resetPromptState,
  selectedOptionStructure
} = dataSlice.actions;

// A selector
export const dataSliceSelector = (state) => state.dataSlice;

// The reducer
export default dataSlice.reducer;
