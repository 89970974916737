import React from 'react'
import PropTypes from 'prop-types'
import ReviewContentContainer from './ReviewContentContainer'
import ReviewCommentsContainer from './ReviewCommentsContainer'
import { Card } from '@mui/material'
import AssetSwitcher from '../../../component-bundle/assetSwitcher/AssetSwitcher'
import ReviewDocumentMap from './ReviewDocumentMap'
import { useReviewContextHook } from './ReviewContext'
import useChapterHook from '../report-component/hooks/useChapterHook'
import { DocumentTreeContextProvider } from '../report-component/comments/DocumentTreeContext'

const ReviewContainer = props => {
  const {reportType, selectedComment} = useReviewContextHook();
  return (
    <div className='w-100 h-100 d-flex align-items-center'>
        <Card className="p-1 h-100 d-flex flex-column" style={{ width: "20%" }}>
        <AssetSwitcher
          dirtyState={false}
          setBlockState={(val) => {}}
        />
        <ReviewDocumentMap />
        </Card>
        <Card className="m-1 h-100 d-flex flex-column" style={{ width: "55%" }}>
        <ReviewContentContainer />
        </Card>
        <Card className="m-1 h-100 d-flex flex-column" style={{ width: "25%" }}>
        <DocumentTreeContextProvider reportType={reportType}>
        <ReviewCommentsContainer reportType={reportType} selectedComment={selectedComment}/>
        </DocumentTreeContextProvider>
        </Card>

    </div>
  )
}

ReviewContainer.propTypes = {}

export default ReviewContainer