import React from 'react'
import ClientManagementDataWrapper from "./structure/ClientManagementDataWrapper"
import {Box} from '@mui/material';

const ClientManagementHome = () => {
  return (
    <Box className={"h-100 w-100"}>
 <Box className={"h-100 w-100"}>
  <ClientManagementDataWrapper/>
  </Box>
  </Box>
  );
};
export default ClientManagementHome;