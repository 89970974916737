import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Formik, Form } from "formik";
import { sendPasswordResetLink } from "../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "./authSlice";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { resetErrorState } from "../error/errorHandlingSlice";
import { getTranslationVariantText,getLabel } from "../utils/helper";
import {Button, TextField, Box,Card} from "@mui/material";
import IntlMessages from "../utils/IntlMessages";

const ForgotPassword = () => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.authentication);
  const errorState = useSelector((state) => state.errorhandling);
  const intitialValues = {
    emailAddress: "",
  };

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().email("EmailInvalid").required("EmailRequired"),
  });

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
      setLoader(false);
      enqueueSnackbar(errorState.messages, {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      dispatch(resetErrorState());
    } else if (userLogin.resetlink.type === "ERROR") {
      enqueueSnackbar(userLogin.resetlink.messages, {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      setLoader(false);
      dispatch(reset());
    } else if (userLogin.resetlink.type === "SUCCESS") {
      enqueueSnackbar(userLogin.resetlink.messages, {
        variant: "success",
        persist: false,
        autoHideDuration: 2000,
        preventDuplicate: true,
      });
      setLoader(false);
      dispatch(reset());
      navigate("/login");
    }
  }, [dispatch, enqueueSnackbar, errorState, navigate, userLogin.resetlink]);

  function onSubmit(values) {
    dispatch(sendPasswordResetLink(values.emailAddress));
  }

  return (
    <div className="fixed-background">
          <div className="h-100 d-flex align-items-center justify-content-center m-auto">
              {/* <Paper variant="outlined" elevation={3}>
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/assets/images/server_down.svg"
                    alt="server_down"
                    style={{
                      width: "50%",
                      height: "50%",
                      marginTop: "15px",
                      maxWidth: "300px",
                      maxHeight: "300px",
                    }}
                  />
                </Box>
                <Divider className="loginDivider" />
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 50,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginLeft: "5%",
                      marginRight: "5%",
                      fontWeight: "bold",
                    }}
                  >
                    {getIntlTranslation("_technical_issues")}
                  </Typography>
                </Box>
              </Paper> */}
              <Card className="login-card">
                <div className="position-relative login-image">
                  <p className="text-white h6">
                    <IntlMessages id="guru.welcome" />
                  </p>
                  <p
                    className="text-white h6"
                    style={{ marginTop: "2%", marginBottom: "50%" }}
                  >
                    <IntlMessages id="guru.messages" />
                  </p>
                </div>
                <div className="login-side">
                  <div style={{ marginBottom: 40 }}>
                    <NavLink to="/">
                      <img
                        src="/assets/images/ta-logo.jpg"
                        width="50"
                        height="50"
                        alt=""
                      />
                    </NavLink>
                  </div>
                <Box
                  style={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Box style={{width: "100%",margin: "auto", padding:5}}>
                  {getTranslationVariantText("_forgot-password","body1",{
                     margin: "15px 0px",
                     fontWeight:"bold",
                     color:"#244a9a",
                     padding:5
                  })}
                {getTranslationVariantText("_enter-your-email","body1",{
                  margin: "15px 0px",
                  fontWeight:"bold",
                  color:"#d08f8e",
                  padding:5,
                })}
                <Formik
                  initialValues={intitialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                      <Box
                        style={{ width: "100%"}}
                        className={"d-flex flex-column align-items-center justify-content-center"}
                      >
                          <TextField
                            name="emailAddress"
                            label={getLabel("user.emailAddress")}
                            style={{ width: "100%" }}
                            onChange={(e)=> setFieldValue("emailAddress", e.target.value)}
                            type="email"
                            error={touched.emailAddress && errors.emailAddress}
                            helperText={
                              touched.emailAddress && errors.emailAddress
                            }
                            size="small"
                          />
                       
                       <Box className={"d-flex align-item-center w-100 justify-content-between flex-wrap"}>
                       <Button variant="text" onClick={(e) => { navigate("/login")}}>{getTranslationVariantText("_cancel")}</Button>
                          <Button
                            variant="contained"
                            className="g-btn-primary"
                            disableElevation
                            type="submit"
                            style={{ margin: "15px 0px"}}
                          >
                           {getTranslationVariantText("_submit")}
                          </Button>
                        
                          
                          </Box>
                       </Box>
                    </Form>
                  )}
                </Formik>
                </Box>
              
                </Box>
                </div>
              </Card>
            </div>
    </div>
  );
};

export default ForgotPassword;
