import {
  Box,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RenderTextEditor from "./TextEditor";
import useChapterHook from "../../hooks/useChapterHook";
import { useSelector } from "react-redux";
import {
  getIntlTranslation,
  getStylingForSelectedComment,
} from "../../../../../../utils/helper";
import CommentIcon from "@mui/icons-material/Comment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CommentsPopup from "../../comments/CommentsPopup";
import useCommentFetchHook from "../../comments/useCommentFetchHook";
import useCommentSaveHook from "../../../../../component-bundle/hooks/useCommentSaveHook";
import { useSnackbar } from "notistack";
import useFetchContentFireBase from "../../hooks/firebase/useFetchContentHook";
import useSaveContentFireBase from "../../hooks/firebase/useSaveContentHook";
import { EditingIndicator } from "../EditingIndicator";
export const TypeTextDataRender = ({ item, reportType }) => {
  const node = item.node;
  const { onReportTextSave } = useChapterHook({
    reportType,
  });
  const apiParam = "report-text";
  const { onSave } = useSaveContentFireBase({});

  return (
    <>
      {node.userDefined ? (
        <Box className="w-100 d-flex flex-column">
          <RenderTextEditor
            node={{ ...node, text: node.freeText }}
            onSave={(data) => onReportTextSave(data, reportType)}
            onSaveSuccess={(savedNode) => {
              onSave(
                apiParam,
                {
                  ident: savedNode.ident,
                  path: node.path,
                  properties: [savedNode],
                },
                reportType
              );
            }}
          />
        </Box>
      ) : (
        <Box className="d-flex align-items-baseline w-100">
          {!!node.freeText ? (
            <Typography
              style={{
                fontSize: 14,
                width: "98%",
                textAlign: "justify",
                padding: 5,
              }}
              dangerouslySetInnerHTML={{ __html: node.freeText }}
              className="label-text m-1"
            ></Typography>
          ) : (
            <Chip
              label={
                <Typography
                  style={{
                    fontSize: 14,
                    width: "95%",

                    marginRight: 5,
                    textWrap: "wrap",
                  }}
                  className="text-danger m-1"
                  variant="subtitle2"
                >
                  {getIntlTranslation("_no-data-available")}
                </Typography>
              }
              color="error"
              variant="outlined"
              className="w-100 h-auto"
            />
          )}
        </Box>
      )}
    </>
  );
};

export const TypeTextPreviewRender = ({ item, reportType }) => {
  let node = {
    ...item.node,
    text: item.node.freeText,
    value: item.node.freeText,
  };
  const [nodeData, setNodeData] = useState(node);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const { enqueueSnackbar } = useSnackbar();
  const [commentPopup, setCommentPopup] = useState({
    open: false,
    node: null,
    id: null,
    uniqueIdentifier: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setEditing] = useState({
    id: null,
    editing: false,
  });
  const apiParam = "report-text";
  const dataRef = useRef(node);
  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );
  const [onCommentsSave] = useCommentSaveHook();
  const { onReportTextSave } = useChapterHook({
    reportType,
  });

  const { onSave, onEdit } = useSaveContentFireBase({
    apiParam,
    data: {
      ident: node.ident,
      path: node.path,
      properties: [node],
      timestamp: node.timestamp,
    },
    reportType,
  });
  const { updatedNode, setUpdatedNode, locked, getUser } =
    useFetchContentFireBase({
      nodePath: `${apiParam}-${node.ident}`,
      reportType,
    });

  useEffect(() => {
    if (
      !isEditing.editing &&
      !!updatedNode &&
      `${apiParam}-${dataRef.current.ident}` === updatedNode?.ident &&
      scope.projectIdent === updatedNode?.projectIdent &&
      openAsset.ident === updatedNode?.assetIdent &&
      reportType === updatedNode?.reportType
    ) {
      let tempNode = Object.values(updatedNode.node)[0];
      setNodeData({
        ...tempNode,
        freeText: tempNode.value,
        text: tempNode.value,
      });
      setUpdatedNode(null);
    }
  }, [isEditing.editing, updatedNode?.ident, , scope, openAsset]);

  useEffect(() => {
    if (
      isCommentSaved.saved &&
      dataRef.current.uniqueIdentifier === commentPopup.uniqueIdentifier
    ) {
      dataRef.current.comment = {
        ...dataRef.current.comment,
        ident: isCommentSaved.commentsIdent,
      };

      isCommentSaved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setCommentPopup({
        open: false,
        node: null,
        id: null,
        uniqueIdentifier: null,
      });
      resetState();
    }
  }, [isCommentSaved]);

  const processSaveComments = (value) => {
    if (!!dataRef.current.comment) {
      let tempCommentsWrapper = {
        ...dataRef.current.comment,
        apiParam: apiParam,
        scrollParent: "scrollParent",
        reportType: reportType,
        section: `report-text-${node.ident}`,
      };
      const comments = [...tempCommentsWrapper.commentValues];
      comments.push({
        ...value,
        reportType: reportType,
        parentPath: dataRef.current.path,
        order: commentPopup?.node?.order,
        assetIdent: openAsset.ident,
      });
      onCommentsSave({
        commentsApi: tempCommentsWrapper.apiParam,
        data: { ...tempCommentsWrapper, commentValues: comments },
        apiIdent: dataRef.current.ident,
      });
    }
  };

  const lockCheck = (locked) => {
    return (
      !!locked?.lockedId &&
      locked.isLocked &&
      locked.lockedId === `${apiParam}-${dataRef.current.ident}` &&
      scope.projectIdent === locked?.projectIdent &&
      openAsset.ident === locked?.assetIdent &&
      reportType === locked?.reportType &&
      locked?.lockedBy.id !== getUser().id
    );
  };

  const actionButtons = (node, locked) => {
    return (
      <>
        {node.userDefined && (
          <Box
            className="d-flex flex-column align-baseline justify-content-between flex-wrap flex-row g-btn-secondary m-1 rounded-3"
            style={{ width: 40, position: "sticky", top: 10 }}
          >
            {!lockCheck(locked) && (
              <Tooltip title={getIntlTranslation("tooltip.edit")}>
                <IconButton
                  id={`edit_${node.id}`}
                  onClick={(event) => {
                    event?.stopPropagation();
                    setEditing({ id: node.ident, editing: true });
                    onEdit(apiParam, node.ident, reportType, true);
                  }}
                >
                  <ModeEditIcon style={{ fontSize: 18, color: "#244a9a" }} />
                </IconButton>
              </Tooltip>
            )}
            <Divider
              orientation="vertical"
              style={{
                width: 2,
                backgroundColor: "#ffffff",
              }}
              flexItem
            />
            <Tooltip title={getIntlTranslation("tooltip.add-comments")}>
              <IconButton
                id={`comment_${node.ident}`}
                aria-describedby={`pop-comment-${node.ident}`}
                onClick={(event) => {
                  setCommentPopup({
                    open: true,
                    node: node,
                    id: `pop-comment-${node.ident}`,
                    uniqueIdentifier: `${apiParam}-${node.ident}`,
                  });
                  setAnchorEl(event.currentTarget);
                  dataRef.current.uniqueIdentifier = `${apiParam}-${node.ident}`;
                }}
              >
                <CommentIcon style={{ fontSize: 18, color: "#d08f8e" }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        className={`d-flex align-items-baseline w-100 flex-wrap p-1 box ${
          isEditing.editing ? "elevated p-1 rounded-3" : ""
        }
        ${!isEditing.editing && lockCheck(locked) ? "locked-indicator" : ""}
      `}
        id={`scroll_item_${nodeData.ident}_${item.nodeType}`}
        key={nodeData.ident}
        style={{
          ...getStylingForSelectedComment(
            isCommentSelected()?.propertyIdent,
            nodeData.ident
          ),
          marginTop: 20,
        }}
      >
        {!nodeData.text && !isEditing.editing ? (
          <Box className="d-flex align-items-baseline w-100">
            <Chip
              label={
                <Typography
                  style={{
                    fontSize: 14,
                    width: "95%",

                    marginRight: 5,
                    textWrap: "wrap",
                  }}
                  className="text-danger m-1"
                  variant="subtitle2"
                >
                  {getIntlTranslation("_no-data-available")}
                </Typography>
              }
              color="error"
              variant="outlined"
              className="w-100 h-auto"
            />
            {actionButtons(nodeData, locked)}
          </Box>
        ) : (
          <>
            {isEditing.editing && isEditing.id === dataRef.current.ident ? (
              <Box
                className="d-flex align-items-baseline"
                style={{
                  minHeight: "200px",
                  width: "98%",
                }}
              >
                <RenderTextEditor
                  node={nodeData}
                  onSave={(data) => onReportTextSave(data, reportType)}
                  inReviewMode={true}
                  onSaveSuccess={(node) =>
                    onSave(
                      apiParam,
                      {
                        ident: node.ident,
                        path: dataRef.current.path,
                        properties: [node],
                      },
                      reportType
                    )
                  }
                  onClose={(node) => {
                    setEditing({
                      id: null,
                      editing: false,
                    });
                    onEdit(apiParam, node.ident, reportType, false);
                  }}
                />
              </Box>
            ) : (
              <Box className="d-flex align-items-baseline w-100">
                <Typography
                  style={{
                    fontSize: 14,
                    width: "98%",
                    textAlign: "justify",
                    padding: 5,
                  }}
                  dangerouslySetInnerHTML={{ __html: nodeData.value }}
                  className="label-text"
                ></Typography>
                {actionButtons(nodeData, locked)}
              </Box>
            )}
          </>
        )}
        {!isEditing.editing && lockCheck(locked) && (
          <EditingIndicator user={locked.lockedBy} />
        )}
      </Box>
      {commentPopup.open && !!commentPopup.node && (
        <CommentsPopup
          id={commentPopup.id}
          anchorEl={anchorEl}
          onClose={() => {
            setCommentPopup({
              open: false,
              node: null,
              id: null,
            });
            setAnchorEl(null);
          }}
          node={{
            ...commentPopup.node,
            nodeIdent: commentPopup.node.ident,
            nodeKey: "text",
          }}
          onSave={(value) => {
            processSaveComments(value);
          }}
        />
      )}
    </>
  );
};
