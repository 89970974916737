/* eslint-disable array-callback-return */
import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const EditRowFormFields = ({
  headerList,
  data,
  setYearOnly,
  setOverdue,
  setInterval,
  interval,
  overdue,
  yearOnly,
  setData,
}) => {
  const groupBy = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      const curGroup = acc[key] ?? [];

      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  };

  const renderRow = () => {
    let headers = headerList;
    if(!!data && !!data.category)
    {
      headers = [];
      headerList.forEach((item, index) => {
        if(item.categoryList.some((cat) => cat.category.ident === data.category.ident)){
          headers.push(item);
        }
      })
    }
    let temp = [];
    let grouped = groupBy(headers, "editor");
    if (Object.keys(grouped).length > 0) {
      let tempObject = {
        text: [],
        date: [],
        dropdown: [],
        checkbox: grouped?.checkbox,
        textarea: grouped?.textarea,
      };
      if(grouped["text"]?.length > 0){
        tempObject = {...tempObject, text:[...tempObject.text, ...grouped["text"]]};
      }
      if(grouped["number"]?.length > 0){
        tempObject = {...tempObject, text:[...tempObject.text, ...grouped["number"]]};
      }
      if(grouped["date"]?.length > 0){
        tempObject = {...tempObject, date:[...tempObject.date, ...grouped["date"]]};
      }
      if(grouped["date-year"]?.length > 0){
        tempObject = {...tempObject, date:[...tempObject.date, ...grouped["date-year"]]};
      }
      if(grouped["dropdown"]?.length > 0){
        tempObject = {...tempObject, dropdown:[...tempObject.dropdown, ...grouped["dropdown"]]};
      }
      if(grouped["multi-dropdown"]?.length > 0){
        tempObject = {...tempObject, dropdown:[...tempObject.dropdown, ...grouped["multi-dropdown"]]};
      }
      for (const key in tempObject) {
        if (key === "text") {
          temp.push(
            <>
              <Box className={"w-100 d-flex align-items-center flex-wrap"}>
                {tempObject[key]
                ?.filter((f) => !!f)
                  .sort(
                    (a, b) =>
                      a.categoryList[0].headerOrder -
                      b.categoryList[0].headerOrder
                  )
                  .map((value) => {
                    const id = data.id + "" + value.key;
                    if (value.editor === "number" && value.key === "interval") {
                      setInterval(data[value.key]);
                      return (
                        <Box  className="w-100 p-1">
                          <TextField
                            id={id}
                            className="w-100 m-1"
                            variant="standard"
                            label={value.name}
                            type="number"
                            value={data[value.key]}
                            onChange={(e) => {
                              setData({ ...data, [value.key]: e.target.value });
                              setInterval(e.target.value);
                            }}
                          />
                        </Box>
                      );
                    } else if (value.editor === "number") {
                      return (
                        <Box  className="w-100 p-1">
                          <TextField
                            variant="standard"
                            className="w-100 m-1"
                            id={id}
                            label={value.name}
                            value={data[value.key]}
                            onChange={(e) => {
                              setData({ ...data, [value.key]: e.target.value });
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return (
                        <Box  className="w-100 p-1">
                          <TextField
                            id={id}
                            className="w-100 m-1"
                            fullWidth={true}
                            variant="standard"
                            label={value.name}
                            maxRows={8}
                            multiline
                            value={data[value.key]}
                            onChange={(e) => {
                              setData({ ...data, [value.key]: e.target.value });
                            }}
                          />
                        </Box>
                      );
                    }
                  })}
              </Box>
            </>
          );
        } else if (key === "date") {
          temp.push(
            <>
              <Box className={"w-100 d-flex align-items-center flex-wrap"}>
                {tempObject[key]
                ?.filter((f) => !!f)
                  .sort(
                    (a, b) =>
                      a.categoryList[0].headerOrder -
                      b.categoryList[0].headerOrder
                  )
                  .map((value) => {
                    const id = data.id + "" + value.key;
                    if (value.editor === "date-year") {
                      setYearOnly(data[value.key]);
                      const date = !!data[value.key]
                        ? dayjs(new Date(data[value.key], 1, 1))
                        : null;
                      return (
                        <Box  className="w-100 p-1">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            className="w-100"
                          >
                            <DatePicker
                              id={id}
                              className="w-100 m-1"
                              value={date}
                              dense={"true"}
                              views={["year"]}
                              inputVariant="standard"
                              minDate={dayjs(new Date(1800, 1, 1))}
                              label={value.name}
                              onChange={(e) => {
                                if (!!e) {
                                  setData({ ...data, [value.key]: e.year() });
                                } else {
                                  setData({ ...data, [value.key]: null });
                                }
                              }}
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  size: "small",
                                  style: { width: "90%" },
                                  helperText: null,
                                  error: null,
                                },
                                popper:{
                                  style: { zIndex: 1900 },
                                  disablePortal: true,
                                },
                                inputAdornment: {
                                  position: "start",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      );
                    } else {
                      const date = !!data[value.key]
                        ? dayjs(new Date(data[value.key]))
                        : null;
                      return (
                        <Box className="w-100 p-1">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            className="w-100"
                          >
                            <DatePicker
                              id={id}
                              value={date}
                              disablePortal
                              className="w-100 m-1"
                              variant="inline"
                              format="DD/MM/YYYY"
                              style={{zIndex:1900}}
                              minDate={dayjs(new Date(1800, 1, 1))}
                              label={value.name}
                              onChange={(e) => {
                                setData({ ...data, [value.key]: e });
                              }}
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  size: "small",
                                  style: { width: "90%" },
                                  helperText: null,
                                  error: null,
                                },
                                popper:{
                                  style: { zIndex: 1900 },
                                  disablePortal: true,
                                },
                                inputAdornment: {
                                  position: "start",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      );
                    }
                  })}
              </Box>
            </>
          );
        } else if (key === "dropdown") {
          temp.push(
            <>
              <Box className={"w-100 d-flex align-items-center flex-wrap"}>
                {tempObject[key]
                ?.filter((f) => !!f)
                  .sort(
                    (a, b) =>
                      a.categoryList[0].headerOrder -
                      b.categoryList[0].headerOrder
                  )
                  .map((value) => {
                    const id = data.id + "" + value.key;
                    if (value.editor === "multi-dropdown") {
                      return (
                        <Box  className="w-100 p-1">
                          <Autocomplete
                            id={id}
                            multiple
                            disablePortal
                            style={{ height: "100%", zIndex: 1900 }}
                            value={data[value.key]?.options}
                            options={value.options}
                            onChange={(event, newValue) => {
                              let comb = newValue
                                .map((item) => item.title)
                                .join();
                              setData({
                                ...data,
                                [value.key]: {
                                  name: comb,
                                  multiple: true,
                                  options: newValue,
                                },
                              });
                            }}
                            getOptionLabel={(option) => option.title || []}
                            getOptionSelected={(option, value) =>
                              value.value === option.value
                            }
                            renderInput={(params) => (
                              <TextField
                              className="w-100 m-1"
                                id={id}
                                {...params}
                                label={value.name}
                                variant="standard"
                              />
                            )}
                          />
                        </Box>
                      );
                    } else {
                      return (
                        <Box  className="w-100 p-1">
                          <Autocomplete
                            id={id}
                            disablePortal
                            style={{ height: "100%" ,  zIndex: 1900 }}
                            value={data[value.key]?.options}
                            options={value.options}
                            onChange={(event, newValue) => {
                              setData({
                                ...data,
                                [value.key]: {
                                  name: newValue.title,
                                  multiple: false,
                                  options: newValue,
                                },
                              });
                            }}
                            getOptionLabel={(option) =>  option?.title ? option.title : "" }
                            getOptionSelected={(option, value) =>
                              value.value === option.value
                            }
                            renderInput={(params) => (
                              <TextField
                              className="w-100 m-1"
                                id={id}
                                {...params}
                                label={value.name}
                                variant="standard"
                              />
                            )}
                          />
                        </Box>
                      );
                    }
                  })}
              </Box>
            </>
          );
        } else if (key === "checkbox") {
          temp.push(
            <>
              <Box className={"w-100 d-flex align-items-center flex-wrap"}>
                {tempObject[key]
                ?.filter((f) => !!f)
                  .sort(
                    (a, b) =>
                      a.categoryList[0].headerOrder -
                      b.categoryList[0].headerOrder
                  )
                  .map((item) => {
                    if (item.key === "overdue") {
                      setOverdue(data[item.key])
                      const sum =
                        yearOnly && interval
                          ? parseInt(yearOnly) + parseInt(interval)
                          : 0;
                      const nowDate = new Date().getFullYear();
                      const overdueYears = !sum ? 0 : nowDate - sum;
                      let res = overdueYears <= 0 ? false : true;
                      if (!yearOnly) {
                        res = false;
                      }
                      return (
                        <Box  className="w-100 p-1">
                          <FormControlLabel
                            className="w-100 m-1"
                            control={
                              <Checkbox
                                checked={data[item.key]}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    [item.key]: e.target.checked,
                                  });
                                }}
                              />
                            }
                            label={item.name}
                          />
                        </Box>
                      );
                    } else {
                      return (
                        <Box  className="w-100 m
                      p-1">
                          <FormControlLabel
                            className="w-100 m-1"
                            control={
                              <Checkbox
                                checked={data[item.key]}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    [item.key]: e.target.checked,
                                  });
                                }}
                              />
                            }
                            label={item.name}
                          />
                        </Box>
                      );
                    }
                  })}
              </Box>
            </>
          );
        }
        if (key === "textarea") {
          temp.push(
            <>
              <Box className={"w-100 d-flex align-items-center flex-wrap"}>
                {tempObject[key]
                ?.filter((f) => !!f)
                  .sort(
                    (a, b) =>
                      a.categoryList[0].headerOrder -
                      b.categoryList[0].headerOrder
                  )
                  .map((value) => {
                    const id = data.id + "" + value.key;
                    if (value.editor === "textarea" && value.key !== "trade") {
                      return (
                        <Box  className="w-100 p-1">
                        <TextField
                          id={id}
                          className="w-100 m-1"
                          fullWidth={true}
                          variant="standard"
                          label={value.name}
                          maxRows={8}
                          multiline
                          value={data[value.key]}
                          onChange={(e) => {
                            setData({ ...data, [value.key]: e.target.value });
                          }}
                        />
                        </Box>
                      );
                    }
                  })}
              </Box>
            </>
          );
        }
      }
    }

    return temp;
  };

  return (
    <Box className={"d-flex align-items-center flex-wrap w-100"}>
      {renderRow()}
    </Box>
  );
};

export default EditRowFormFields;
