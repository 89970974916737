import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../../../utils/defaultValues";

export const initialState = {
  saved :{ ...response },
  hasErrors: false,
  autoSave:{
    type: "",
    messages: [],
    savedData: {},
  },
};

const textEditorSlice = createSlice({
  name: "texteditor",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      saved : {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      }
    }),
    resetState: (state) => ({
      ...initialState,
    }),
    getAutoSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      autoSave:{
        type: payload.type,
        messages: payload.messages,
        savedData: payload.guruDto,
      }
    }),
  },
});

// eslint-disable-next-line max-len
export const { getFailure, resetState, getSuccess} = textEditorSlice.actions;

// A selector
export const textEditorSelector = (state) => state.textEditor;

// The reducer
export default textEditorSlice.reducer;
