import { useSnackbar } from "notistack";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { response } from "../../../../../utils/defaultValues";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import {
  addCategorAndProperties,
  deleteCustomCategory,
  deleteCustomProperty,
  editCustomCategory,
  editCustomProperty,
  fetchCategory,
  fetchPropertiesToCompare,
  savePropertiesData,
} from "../../../api/monitoringKeyFindingsApi";
import {
  getAddOptionalSaveSuccess,
  getCustomCategoryDelete,
  getCustomCategoryUpdate,
  getFetchCategoriesSuccess,
  onSelectReportVersion,
  resetState,
  setCurrentVersion,
  setVersions,
} from "../keyFindingsSlice";

export const KeyFindingsContext = createContext(undefined);

export const useKeyFindingsContext = () => {
  const context = useContext(KeyFindingsContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

export const KeyFindingsProvider = ({ children, moduleApi }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const state = useSelector((state) => state.keyFindings);
  const { assetList } = useAssetListHook();
  const [assets, setAssets] = useState({
    isAssigned: false,
    data: [],
  });
  const [subAssetList, setSubAssetList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [blockAssetChange, setBlockAssetChange] = useState({
    block: false,
    newAsset: {},
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!!openAsset.ident) {
      setCategories([]);
      setSubAssetList([]);
      dispatch(resetState());
      dispatch(setCurrentVersion({}));
      setAssets({
        isAssigned: false,
        data: [],
      });
    }
  }, [dispatch, openAsset.ident]);

  useEffect(() => {
    if (!!openAsset.ident && assetList.isFetchSuccess) {
      const list = assetList.assets.map((m) => {
        return {
          ...m,
          address: { ...m.addressDto },
          reportVersion: [
            ...m.reportVersionDtos.map((rv) => {
              return { ...rv, label: rv.versionName, value: rv.ident };
            }),
          ],
        };
      });

      let found = list.find(
        (f) =>
          f.assetIdent === openAsset.ident &&
          scope?.projectIdent === openAsset?.projectIdent
      );
      let currentversion = found.reportVersion.find((f) => f.current);
      if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
        currentversion = found.reportVersion.find(
          (f) => f.ident === tempVersion.reportVersionIdent
        );
      }
      let temp = [
        ...found?.subAssets.map((m) => m).sort((a, b) => b.general - a.general),
      ];
      setSubAssetList(temp);
      dispatch(setCurrentVersion(currentversion || {}));
      dispatch(onSelectReportVersion(null));
      dispatch(setVersions(found.reportVersion));
      setAssets({
        isAssigned: true,
        data: list,
      });
    }
  }, [
    assetList,
    assets.isAssigned,
    dispatch,
    openAsset.ident,
    openAsset?.projectIdent,
    scope?.projectIdent,
    tempVersion,
  ]);

  const fetchProps = useCallback(() => {
    if (!!state.currentVersion && !!state.currentVersion.ident) {
      dispatch(
        fetchCategory({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          version: state.currentVersion.ident,
        })
      );
    }
  }, [
    dispatch,
    moduleApi,
    openAsset.ident,
    scope.api,
    scope.projectIdent,
    state.currentVersion,
  ]);

  useEffect(() => {
    if (assets.isAssigned && !!state.currentVersion) {
      fetchProps();
    }
  }, [assets.isAssigned, state.currentVersion, fetchProps]);

  useEffect(() => {
    if (
      state.fetchCategories.type === "FETCHED" &&
      state.fetchCategories.data.length > 0
    ) {
      setCategories([
        ...state.fetchCategories.data.map((m) => ({
          ...m,
          assetIdent: openAsset.ident,
        })),
      ]);
      dispatch(getFetchCategoriesSuccess({ ...response, guruDtoList: [] }));
    }
    if (state.saveAddOptional.type === "SAVED") {
      state.saveAddOptional.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getAddOptionalSaveSuccess({ ...response, guruDto: {} }));
      fetchProps();
    }
    if (state.customCategoryUpdate.type === "SAVED") {
      state.customCategoryUpdate.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      fetchProps();
      dispatch(getCustomCategoryUpdate({ ...response, guruDtoList: [] }));
    } else if (state.customCategoryDelete.type === "DELETED") {
      state.customCategoryDelete.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      fetchProps();
      dispatch(getCustomCategoryDelete({ ...response, guruDto: {} }));
    }
  }, [
    dispatch,
    enqueueSnackbar,
    fetchProps,
    openAsset.ident,
    state.customCategoryDelete,
    state.customCategoryUpdate,
    state.fetchCategories,
    state.saveAddOptional,
  ]);

  const onFetchCompareProperties = useCallback((versionIdent, categoryIdent) => {
    dispatch(
      fetchPropertiesToCompare({
        moduleApi: moduleApi,
        api: scope.api,
        projectIdent: scope.projectIdent,
        ident: categoryIdent,
        version: versionIdent,
      })
    );

  }, [dispatch, moduleApi, scope.api, scope.projectIdent]);

  
  
  const onCategoryEditProperty = useCallback(
    (data) =>
      dispatch(
        editCustomCategory({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          data: data,
        })
      ),
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  const onCategoryDeleteProperty = useCallback(
    (data) =>
      dispatch(
        deleteCustomCategory({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          categoryIdent: data.nodeIdent,
        })
      ),
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  
  const onAdditionalProperty = useCallback(
    (data) =>
      dispatch(
        addCategorAndProperties({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          data: data,
        })
      ),
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  const isDataUpdated = useCallback(() => {
    return (
      Object.keys(state.updatedRows)?.filter(
        (f) => state.updatedRows[f].status === "new"
      ).length > 0
    );
  }, [state.updatedRows]);

  return (
    <KeyFindingsContext.Provider
      value={{
        assets,
        subAssetList,
        blockAssetChange,
        categories,
        setCategories,
        setBlockAssetChange,
        isDataUpdated,
        onAdditionalProperty,
        onCategoryDeleteProperty,
        onCategoryEditProperty,
        onFetchCompareProperties,
        fetchProps,
      }}
    >
      {children}
    </KeyFindingsContext.Provider>
  );
};
