import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IntlMessages from "../../utils/IntlMessages";

const FileDownloadButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const state = useSelector((state) => state.readOnly);

  useEffect(() => {
    if (state.isReadOnly) {
      setReadOnly(state.isReadOnly);
    }
  }, [state]);

  const checkPermission = () => {
    if (!!props.permission) {
      return (
        <>
          {props.permission.create && props.permission.update ? (
            <Tooltip
              title={props.tooltip.title}
              aria-label={props.tooltip.title}
            >
              <Button
                id={props.id}
                className={props.className}
                onClick={(e)=>props.handleClick()}
                variant={props.variant}
                style={{ ...props.style }}
                startIcon={props.startIcon && <props.startIcon />}
                endIcon={props.endIcon && <props.endIcon />}
              >
                <Typography variant="button" display="block">
             <IntlMessages id={`${props.buttonText}`}/>
                </Typography>
              </Button>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <Tooltip
          title={props.tooltip.title}
          aria-label={props.tooltip.title}
        >
          <Button
            id={props.id}
            className={props.className}
            variant={props.variant}
            style={{ ...props.style }}
            onClick={(e)=>props.handleClick()}
            startIcon={props.startIcon && <props.startIcon />}
            endIcon={props.endIcon && <props.endIcon />}
          >
            <Typography variant="button" display="block">
         <IntlMessages id={`${props.buttonText}`}/>
            </Typography>
          </Button>
        </Tooltip>
      );
    }
  };

  return <>{!readOnly && checkPermission()}</>;
};

FileDownloadButton.propTypes = {
  id: PropTypes.string.isRequired,
  permission: PropTypes.object,
  handleClick: PropTypes.func,
  onChange: PropTypes.func,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  style: PropTypes.object,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
};

export default FileDownloadButton;
