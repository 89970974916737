import { createSlice } from "@reduxjs/toolkit";
import {encrypt} from "../utils/helper"

export const initialState = {
  loading: false,
  hasErrors: false,
  loggedIn: false,
  user: { uid: "" ,name:"" ,email:""},
  language: "",
  error: {},
  resetlink: { type: "", messages: "", data: {} },
  validateToken: { tokenStatus: "" },
  validteTokenError: {},
  passwordSuccess: { type: "", message: "" },
  passwordFailure: {},
};
// A slice for auth with our success or failure reducers
const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    getAuthTokenSuccess: (state, { payload }) => ({
      ...state,
      loggedIn: encrypt(true),
      loading: false,
      hasErrors: false,
      user: encrypt({
        data:payload,
        loggedIn:true
      }),
      language: payload.language,
    }),
    getAuthTokenFailure: (state, { payload }) => ({
      ...state,
      loggedIn: false,
      loading: false,
      hasErrors: true,
      error: payload,
    }),
    getResetLinkSuccess: (state, { payload }) => ({
      ...state,
      resetlink: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getResetLinkFailure: (state, { payload }) => ({
      ...state,
      resetlink: {
        type: payload.type,
        messages: payload.messages,
        error: payload,
      },
    }),
    getValidateTokenSuccess: (state, { payload }) => ({
      ...state,
      validateToken: payload ,
    }),
    getValidateTokenFailure: (state, { payload }) => ({
      ...state,
      validteTokenError: payload,
    }),
    getPasswordSuccess: (state, { payload }) => ({
      ...state,
      passwordSuccess: payload,
    }),
    getPasswordFailure: (state, { payload }) => ({
      ...state,
      passwordFailure: payload,
    }),
    updateLanguage: (state, { payload }) => ({
      ...state,
      language: payload,
    }),
    reset: (state) => ({
      ...state,
      loading: false,
      hasErrors: false,
      loggedIn: false,
      user: { uid: "" ,name:"", email:""},
      language: "",
      error: {},
      resetlink: { type: "", messages: "", data: {} },
      validateToken: { tokenStatus: "" },
      validteTokenError: {},
      passwordSuccess: { type: "", message: "" },
      passwordFailure: {},
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getAuthToken,
  getAuthTokenSuccess,
  getAuthTokenFailure,
  getResetLinkSuccess,
  getResetLinkFailure,
  getValidateTokenSuccess,
  getValidateTokenFailure,
  getPasswordSuccess,
  getPasswordFailure,
  updateLanguage,
  reset,
} = authSlice.actions;

// A selector
export const authSelector = (state) => state.authentication;

// The reducer
export default authSlice.reducer;
