import React from 'react';
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import CapexOverviewHome from './CapexOverviewHome.js';

const CapexOverviewRouteConfig = {
	routes: [
    {
      path: `${tddRoot}/project/capex-overview`,
      exact: true,
      name: <IntlMessages id={"menu.capexOverview"}/>,
      element: <CapexOverviewHome/>,
      auth: [],
    },
    ]
};


export default CapexOverviewRouteConfig;