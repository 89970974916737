import axios from './TddCustomAxios';
import { trackPromise } from 'react-promise-tracker';
import {
  getFailure,
  getSuccess,
  setCustomProperties,
} from '../feature-bundle/building-profile/tddBuildingProfileSlice';
import { getErrorFailure } from '../../../error/errorHandlingSlice';
import { renewAccessTokenIfGoingExpire } from '../../../api/authApi';
import {
  getSaveSuccess,
  getFailure as failure,
} from '../common/report-component/items/building-profile/buildingProfileTableSlice';

export const save = (profile) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'post',
        url: `/building-profile/save/${profile.projectIdent}`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
        data: {
          type: profile.type,
          subAssetIdent: profile.subAssetIdent,
          subAssetType: profile.subAssetType,
          properties: profile.properties,
          name: profile.name,
        },
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getBuildingProfile = (subAsset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'get',
        url: `/building-profile/fetch/${subAsset.projectIdent}/${subAsset.ident}`,
        headers: {
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getFailure(payload));
    }
  }
};

export const saveProperties = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'post',
        url: `/building-profile/save-properties/${request.projectIdent}`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
        data: request.data,
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(failure(response.data));
    } else {
      dispatch(getSaveSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact it.support@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const saveCustomProperties = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'post',
        url: `/building-profile/save-custom-properties/${request.projectIdent}/${request.subAssetIdent}`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
        data: request.data,
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(failure(response.data));
    } else {
      dispatch(setCustomProperties(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact it.support@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
