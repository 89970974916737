import React, { useCallback, useEffect, useState } from "react";
import { ListItem, Box, Divider, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getTranslationVariantText,
  getVariantText,
} from "../../../utils/helper";
import NumberInput from "./input-types/NumberInput";
import AreaInput from "./input-types/AreaInput";
import DateInput, { YearDateInput } from "./input-types/DateInput";
import TextEditorInput from "./input-types/TextEditorInput";
import TextInput from "./input-types/TextInput";
import QuelleInput from "./input-types/QuelleInput";
import AddressInput from "./input-types/AddressInput";
import { useSelectedProperty } from "./context/SelectedPropertyContext";
import { useUpdateState } from "./context/UpdateStateContext";
import { usePropertiesData } from "./context/PropertiesDataContext";

const RenderNodeValues = ({ property }) => {
  const { structuredProperties } = usePropertiesData();
  const { selectedProperty } = useSelectedProperty();
  const { onRowUpdate } = useUpdateState();
  const [inputValue, setInputValue] = useState({});
  const state = useSelector((state) => state.currentVersion);

  useEffect(() => {
    if (selectedProperty) {
      const subAssets =
        structuredProperties.nodes[selectedProperty.nodeIdent]?.subAssets;
      if (!!subAssets) {
        const temp = Object.keys(subAssets).map((key) => subAssets[key]);
        setInputValue({ [selectedProperty.nodeIdent]: temp });
      }
    }
  }, [selectedProperty, structuredProperties]);

  const getNodeValue = useCallback(
    (subAsset, item) => {
      let node = { ...subAsset.node, selectedProperty };
      const property = state.updatedRows[item.nodeIdent];
      if (!!property && property.updated) {
        let found = property.subAssets.find(
          (f) => f.subAssetIdent === subAsset.subAssetIdent
        );
        if (!!found) {
          node = found.node;
        }
      }
      return { ...node, selectedProperty };
    },
    [selectedProperty, state.updatedRows]
  );

  const onChange = useCallback(
    (value, node, isUpdated, subAsset, item) => {
      
      if (isUpdated) {
        let updatedSubAssets = inputValue[selectedProperty.nodeIdent].map(
          (m) => {
            if (m.subAssetIdent === subAsset.subAssetIdent) {
              return {
                ...m,
                node: { quelle:node.quelle, value: value },
                updated: isUpdated,
              };
            } else {
              return m;
            }
          }
        );
        const updatedValue = { [selectedProperty.nodeIdent]: updatedSubAssets };
        setInputValue(updatedValue);
        onRowUpdate(item, updatedSubAssets);
      }
    },
    [inputValue, selectedProperty.nodeIdent, onRowUpdate]
  );

  const onQuelleChange = useCallback(
    (value, node, isUpdated, subAsset, item) => {
      if (isUpdated) {
        let updatedSubAssets = inputValue[selectedProperty.nodeIdent].map(
          (m) => {
            if (m.subAssetIdent === subAsset.subAssetIdent) {
              return {
                ...m,
                node: { value:node.value, quelle: value },
                updated: isUpdated,
              };
            } else {
              return m;
            }
          }
        );
        const updatedValue = { [selectedProperty.nodeIdent]: updatedSubAssets };
        setInputValue(updatedValue);
        onRowUpdate(item, updatedSubAssets);
      }
    },
    [inputValue, selectedProperty.nodeIdent, onRowUpdate]
  );

  const getInputFields = (item, subAsset) => {
    const id = `id-${item.nodeName}-${subAsset.subAssetIdent}`;
    const disabled = item.disabled;
    const node = getNodeValue(subAsset, item);
    if(item.disabled) {
      return  (<Typography
        style={{
          color:"#1d1b1b8c"
        }}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: node.value }}
        className="label-text m-1 p-1 w-100"
      ></Typography>)
    }
    if (item.nodeType.includes("-number")) {
      return (
        <Box className="d-flex flex-wrap w-100 p-1">
          <Box style={{ width: "50%" }} className="m-1">
            <NumberInput
              id={id}
              disabled={disabled}
              node={node}
              handleChange={(value, node, isUpdated) =>
                onChange(value, node, isUpdated, subAsset, item)
              }
            />
          </Box>
          <Box style={{ width: "50%" }} className="m-1">
            <QuelleInput
              id={id}
              disabled={disabled}
              node={node}
              handleQuelleChange={(value, node, isUpdated) =>
                onQuelleChange(value, node, isUpdated, subAsset, item)
              }
            />
          </Box>
        </Box>
      );
    }
    if (item.nodeType.includes("-area")) {
      return (
        <Box className="d-flex w-100 p-1">
          <Box style={{ width: "50%" }} className="m-1">
            <AreaInput
              id={id}
              disabled={disabled}
              node={node}
              handleChange={(value, node, isUpdated) =>
                onChange(value, node, isUpdated, subAsset, item)
              }
            />
          </Box>
          <Box style={{ width: "50%" }} className="m-1">
            <QuelleInput
              id={id}
              disabled={disabled}
              node={node}
              handleQuelleChange={(value, node, isUpdated) =>
                onQuelleChange(value, node, isUpdated, subAsset, item)
              }
            />
          </Box>
        </Box>
      );
    }

    switch (item.nodeType) {
      case "textarea":
        return (
          <Box className="d-flex flex-column w-100">
            <Box style={{ width: "100%" }}>
              <TextEditorInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, nodeVal, isUpdated) =>
                  onChange(value, nodeVal, isUpdated, subAsset, item)
                }
              />
            </Box>
            <Box style={{ width: "100%" }} className="my-2">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                  onQuelleChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
          </Box>
        );

      case "number":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <NumberInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) =>
                  onChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                  onQuelleChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
          </Box>
        );

      case "area":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <AreaInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) =>
                  onChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                  onQuelleChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
          </Box>
        );

      case "date":
      case "date-future":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <DateInput
                id={id}
                disabled={disabled}
                node={node}
                disableFuture={item.type === "date"}
                handleChange={(value, node, isUpdated) =>
                  onChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                  onQuelleChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
          </Box>
        );

      case "date-year":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <YearDateInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) =>
                  onChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                  onQuelleChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
          </Box>
        );

      case "address":
        return (
          <Box className="d-flex  flex-column w-100">
            <Box style={{ width: "100%" }} className="m-1">
              <AddressInput
                id={id}
                disabled={disabled}
                node={node}
                mapConfig={{
                  nodeIdent: item.nodeIdent,
                  ident: subAsset.subAssetIdent,
                  value: node.value,
                }}
                handleChange={(value, node, isUpdated) =>
                  onChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
            <Box style={{ width: "100%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                  onQuelleChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
          </Box>
        );

      default:
        return (
          <Box className="d-flex w-100 ">
            <Box style={{ width: "50%" }} className="m-1">
              <TextInput
                id={id}
                disabled={disabled}
                node={node}
                handleChange={(value, node, isUpdated) =>
                  onChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                  onQuelleChange(value, node, isUpdated, subAsset, item)
                }
              />
            </Box>
          </Box>
        );
    }
  };

  const renderNodeValues = (item, subAsset, index) => {
    return (
      <ListItem
        className={"d-flex flex-column justify-contents-start w-100 p-0"}
        id={`id-${item.nodeIdent}-${subAsset.subAssetIdent}`}
        key={`key-${item.nodeIdent}-${subAsset.subAssetIdent}`}
      >
        <Card className="p-1 m-1 w-100">
          <div style={{ height: 30 }} className="d-flex my-1 w-100">
            {getVariantText(
              subAsset.name,
              "subtitle",
              {
                color: "#254a9a",
                textTransform: "uppercase",
                fontWeight: "bold",
              },
              "p-1"
            )}

            {subAsset.general &&
              getTranslationVariantText(
                "_marked-general",
                "subtitile2",
                {
                  fontWeight: "bold",
                  color: "#d08f8e",
                  marginLeft: 5,
                },
                "p-1"
              )}
          </div>
          <Divider variant="middle" className="g-divider-vertical w-100 m-1" />
          <CardContent className="p-0">
            {getInputFields(item, subAsset)}
          </CardContent>
        </Card>
      </ListItem>
    );
  };

  return (
    <>
      {!!inputValue &&
        selectedProperty.nodeIdent &&
        inputValue[selectedProperty.nodeIdent]?.map((sub, index) => {
          return renderNodeValues(selectedProperty, sub, index);
        })}
    </>
  );
};

export default RenderNodeValues;
