import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IntlMessages from "../../utils/IntlMessages";

const SaveButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);
  const state = useSelector((state) => state.readOnly);

useEffect(() => {
      setReadOnly(state.isReadOnly);
  }, [state.isReadOnly]);


  return (
    <>
      {!readOnly && (
        <>
          <Tooltip title={props.tooltip.title}>
              <Button
                id={props.id}
                className={props.className}
                variant={props.variant}
                style={{ ...props.style, backgroundColor: "#254a9a" }}
                onClick={(e) => props.handleClick(e)}
                startIcon={props.startIcon && <props.startIcon/>}
                endIcon={props.endIcon && <props.endIcon/>}
                size={props.size}
              >
                <Typography
                  variant="button"
                  display="block"
                  style={{ color: "#fff" }}
                >
              <IntlMessages id={`${props.buttonText}`}/>
                </Typography>
              </Button>
          </Tooltip>
        </>
      )}
    </>
  );
};

SaveButton.defaultProps = {
  buttonText: "_save",
  variant:'text',
  className: "g-btn-primary"
};
SaveButton.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  style:PropTypes.object,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  variant:PropTypes.string,
  startIcon: PropTypes.object,
  endIcon:PropTypes.object
};

export default SaveButton;
