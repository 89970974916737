import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getLabel, getDialogBoxHeader,getIntlTranslation,  decrypt,
} from "../../../../../../utils/helper";
import SaveButton from "../../../../../../components/buttons/SaveButton";
import { createAsset } from "../../../../../../api/assetOverviewApi";
import { resetState } from "../../../../../component-bundle/asset-overview/assetOverviewSlice";
import { useSnackbar } from "notistack";
import CreateAssetDialog from "./CreateAssetDialog"
import { fetchSubAssetTypeList } from "../../../../../../api/suggestionApi";
import { resetState  as resetAssetState} from "../../../../../component-bundle/asset-overview/assetOverviewSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});



const AssetCreateContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [buildingType, setBuildingType] = useState([]);
  const [createAnother, setCreateAnother] = useState(false);
  const { open, onClose, projectInfo } = props;
  const assetState = useSelector((state) => state.assetOverview.create);
  const [project, setProject] = useState(projectInfo);
  const suggestionsState = useSelector((state) => state.suggestions);
  const [intialFormData, setIntialFormData] = useState({
    assetName: "",
    numberOfBuildings: 1,
    regionalFactor: 1.0,
    numberOfSubAssets: 1,
    address: "",
    city: "",
    postalCode: "",
    state: "",
    country: "",
    users: [],
    subAssets:[
      {
        name: "",
        typeOfUse: { title: "", value: null },
        constructionYear:null,
        addressCheckbox: true,
        assetIdent:"",
      }
    ]
  });

  useEffect(() => {
    dispatch(resetState());
    dispatch(fetchSubAssetTypeList(projectInfo.projectIdent));
    setProject(projectInfo);
  }, [dispatch, projectInfo]);
  useEffect(() => {
    if (
      !!suggestionsState.data &&
      !!suggestionsState.data.subAsset_type_sv &&
      suggestionsState.data.subAsset_type_sv.length > 0
    ) {
      const decrypted = decrypt(suggestionsState.data.subAsset_type_sv);
      let data = JSON.parse(decrypted);
      let temp = data.filter((e) => e.language === scope.projectLanguageCode);
      setBuildingType(temp);
    }
  }, [scope.projectLanguageCode, suggestionsState]);
  useEffect(() => {
    if (assetState.type === "SAVED") {
      assetState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      if (createAnother) {
        formRef.current.resetForm();
      } else {
        onClose();
      }
      dispatch(resetAssetState());
    }
  }, [
    assetState,
    dispatch,
    enqueueSnackbar,
    onClose,
    projectInfo,
    props,
    createAnother,
  ]);

  const handleSave = (values) => {
    const sub_address = {
      type: "addressDto",
      streetName: values.address,
      optionalStreetName: "",
      postalCode: values.postalCode,
      city: values.city,
      state: values.state,
      country: values.country,
    };

    const subAssets = values.subAssets.map((e) => { 
          return {
            type: "subAssetsDto",
            projectIdent: project.projectIdent,
            name: e.name,
            subAssetType: e.typeOfUse.value,
            constructionYear: e.constructionYear,
            addressDto: sub_address,
          };
      });
    const asset = {
      type: "tddAssetDto",
      projectIdent: project.projectIdent,
      name: values.assetName,
      regionalFactor: 1,
      numberofBuildings:
        values.numberOfBuildings < 1 ? 1 : values.numberOfBuildings,
      constructionPriceIndex: values.bauPreisIndex,
      assetUserPermissionsDtos: [],
      subAssetListDto: subAssets,
    };
    if (subAssets.length > 0) {
      const request = {
        api: scope.api,
        data: asset,
      };
      dispatch(createAsset(request));
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={(e) => onClose()}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className="appBar">
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("Assets")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_asset_close"
            onClick={(e) => props.onClose()}
            style={{ marginLeft: "auto" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <CreateAssetDialog 
        intialFormData={intialFormData} 
        handleSave={(values) => handleSave(values)} 
        formRef={formRef}
        buildingType={buildingType}/>

      </DialogContent>
      <DialogActions className="justify-content-between">
        <FormControlLabel
          label={getLabel("_create another")}
          style={{ margin: 5 }}
          control={
            <Checkbox
              checked={createAnother}
              onChange={(e) => setCreateAnother(e.target.checked)}
            />
          }
        />
        <SaveButton
          id="create-asset-button"
          buttonText="_create"
          variant="button"
          style={{ margin: 5 }}
          editDialog = {false}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

AssetCreateContainer.defaultProps = {
  readOnly: false,
};
AssetCreateContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  onCreate: PropTypes.func,
};

export default AssetCreateContainer;
