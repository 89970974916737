import { Box, Avatar, Typography } from "@mui/material";
import React from "react";
import { Tooltip } from "reactstrap";

export const EditingIndicator = ({ user }) => {
  const tooltipContent = (
    <div>
      <Typography color="inherit">{user.displayName}</Typography>
      <Typography color="inherit">{user.email}</Typography>
    </div>
  );

  return (
    <>
      {!!user && (
        <Box
          className="rounded-3 m-1 editing-indicator"
          style={{ height: 30, width: "100%" }}
        >
          <span className="mention" data-mention={user.displayName}>
            @{user.displayName} is editing
            <span className="editing-animation"></span>
          </span>
        </Box>
      )}
    </>
  );
};
