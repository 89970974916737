import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '../../../../utils/IntlMessages';
import { getIntlTranslation } from '../../../../utils/helper';
import CreateProject from '../../create-project/CreateProjectHome';
import SaveButton from '../../../../components/buttons/SaveButton';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

const ProjectListContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [createProjectFlag, setcreateProjectFlag] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <SaveButton
          id="create-project-button"
          buttonText="menu.create-project"
          variant="button"
          style={{ margin: 5 }}
          tooltip={{
            title: getIntlTranslation('tooltip.create-project'),
          }}
          handleClick={(e) => setcreateProjectFlag((prev) => !prev)}
        />
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: 'projectName',
      headerName: <IntlMessages id="Project Name" />,
      headerClassName: 'gridcolumn-header',
      width: 300,
      editable: false,
    },
    {
      field: 'projectNumber',
      headerName: <IntlMessages id="_project-number" />,
      headerClassName: 'gridcolumn-header',
      width: 150,
      editable: false,
    },
    {
      field: 'projectDescription',
      headerName: <IntlMessages id="Project Description" />,
      headerClassName: 'gridcolumn-header',
      width: 300,
      editable: false,
    },
    {
      field: 'status',
      headerName: <IntlMessages id="_status" />,
      headerClassName: 'gridcolumn-header',
      width: 120,
      editable: false,
    },
  ];

  return (
    <Box
      className={'w-100 h-100 custom-scrollBar'}
      style={{ overflowY: 'auto', overflowX: 'hidden' }}
    >
      <>
        <DataGrid
          className="data-grid"
          autoHeight
          rows={props.list?.filter(
            (item) =>
              props.serviceLine?.name?.toLowerCase() ===
              item?.serviceLine?.toLowerCase()
          )}
          getRowId={(row) => row?.projectIdent}
          onRowClick={(row) => props.onProjectOpen(row)}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableExportSelector
          components={{ Toolbar: CustomToolbar }}
          componentProps={{
            toolbar: {
              className: 'grid-toolbar',
              variant: 'outlined',
            },
          }}
        />

        <CreateProject
          open={createProjectFlag}
          serviceLine={props.serviceLine}
          projectList={props.list}
          onClose={() => setcreateProjectFlag(false)}
          onProjectCreate={() => {
            setcreateProjectFlag(false);
            props.onProjectCreate()
          }}
        />
      </>
    </Box>
  );
};

ProjectListContainer.propTypes = {
  list: PropTypes.array,
  serviceLine: PropTypes.string,
  onProjectCreate: PropTypes.func,
  onProjectOpen: PropTypes.func,
};

export default ProjectListContainer;
