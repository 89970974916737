import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar } from '@mui/material'
import ReviewContainer from './ReviewContainer';
import { CloseOutlined } from '@mui/icons-material';
import { getDialogBoxHeader } from '../../../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReviewContextProvider } from './ReviewContext';
import { tddRoot } from '../../../../utils/defaultValues';
import useSaveContentFireBase from '../report-component/hooks/firebase/useSaveContentHook';
import { useSelector } from 'react-redux';
import BreadcrumbProjectContainer from '../../../../layout/app-container/BreadcrumbProjectContainer';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ReviewContainerDialog = props => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
    const location = useLocation();
    const navigate = useNavigate();
    const getPath = () => location.state.api ==="redflag" ? "red-flag" :"full-report"
    const {releaseUserLocks} = useSaveContentFireBase({});

    const onClose = () => {
      releaseUserLocks(scope.projectIdent,openAsset.ident,location.state.api);
      navigate({pathname: `${tddRoot}/project/${getPath()}`});
    }

    useEffect(() => {
      const handleBeforeUnload = () => {
        releaseUserLocks(scope.projectIdent,openAsset.ident,location.state.api);
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    }, [scope.projectIdent,openAsset.ident,location.state.api])
    
  return (
    <Dialog
    fullScreen
    open={true}
    TransitionComponent={Transition}
  >
     <ReviewContextProvider report = {location.state.api} selectedComment={location.state.selectedComment}>
     <AppBar className={"header rounded-0"}  style={{height:40}}>
        <Toolbar className={"dialog-toolbar"}>
        <div className="d-flex align-items-center justify-content-between w-25">
          {getDialogBoxHeader("_review")}
          </div>
          <div className="d-flex align-items-center justify-content-between w-75">
          <BreadcrumbProjectContainer 
             showAsset={true}
             showSubAsset={false}/>
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) =>  onClose()}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseOutlined />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <DialogContent className='p-1 w-100'>
    <ReviewContainer/>
    </DialogContent>
    </ReviewContextProvider>
    </Dialog>
   
  )
}

ReviewContainerDialog.propTypes = {}

export default ReviewContainerDialog