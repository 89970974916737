import React from "react";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TextField from "@mui/material/TextField";
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import Typography from "@mui/material/Typography";

function CustomAutoComplete(props) {
  const { id, options, value, style } = props;
  const customfilter = createFilterOptions({
    matchFrom: "contain",
    stringify: (option) => option.title,
  });

  return (
    <Autocomplete
      freeSolo={true}
      id={id}
      value={value}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      disableClearable
      autoSelect={true}
      autoComplete={true}
      blurOnSelect={true}
      style={style}
      options={options}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          props.onChange({value:newValue});
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          props.onChange({value:newValue.inputValue});
        } else {
            if(!!newValue){
                props.onChange(newValue);
            }else{
                props.onChange("");
            }
          props.onChange(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={props.label}
          variant={props.variant}
          dense="true"
          maxRows={5}
          error={props.error}
          helperText={props.helperText}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);

        return (
          <>
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    color: part.highlight ? "red" : "black",
                  }}
                >
                  {part.text}
                </span>
              ))}
               {option.type==="measure"  && option.hasChildren ?
               <Typography variant="caption">
                <span style={{color:"#008000", marginLeft:5}}>{("_cost suggestion available")}</span> 
                  </Typography>
              
              :null}
            </div>
          </li>
         
          </>
        );
      }}
    />
  );
}

CustomAutoComplete.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  style: PropTypes.object,
  label: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

export default CustomAutoComplete;
