import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getUsers, getRolesList,getTAEuropeOffices } from "../../../api/userManagementApi";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import TablePagination from "@mui/material/TablePagination";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CreateUserForm from "./CreateUserForm";
import EditUserForm from "./EditUserForm";
import { useSnackbar } from "notistack";
import { resetCreateUserState, resetEditState } from "../userManagementSlice";
import {getButtonLabel} from "../../../utils/helper"
import Chip from "@mui/material/Chip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UserManagementTable() {
  const [userData, setUserData] = useState([]);
  const [copyUserData, setCopyUserData] = useState([]);
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state.userManagement.users);
  const createUser = useSelector((state) => state.userManagement.createUsers);
  const editUser = useSelector((state) => state.userManagement.editUser);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState({
    userName: "",
    isOpen: false,
  });
  const [selectUser, setSelectUser] = useState({});
  const [page, setPage] = React.useState(0);
  const [verified, setVerified] = React.useState(["All", "Yes", "Non"]);
  const [verifiedValue, setVerifiedValue] = React.useState("All");
  const [rolesValue, setRolesValue] = React.useState("All");
  const [statusValue, setStatusValue] = React.useState("All");
  const [emailValue, setEmailValue] = React.useState("");
  const [userLastName, setUserLastName] = React.useState("");
  const [userFirstName, setUserfirstName] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [usersRoles, setUsersRoles] = React.useState([
    {
      name: "All",
      label: "All",
    },
  ]);
  const roleList = useSelector((state) => state.userManagement.roles);
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getRolesList());
    dispatch(getTAEuropeOffices());
  }, []);

  useEffect(() => {
    if (roleList.type === "SUCCESS") {
      const data = roleList.data.suggestionvaluesList.map((role) => {
        return {
          value: role,
          label: role,
        };
      });
      setUsersRoles([
        {
          name: "All",
          label: "All",
        },
        ...data,
      ]);
    }
  }, [roleList]);

  useEffect(() => {
    let user = [];
    if (!!usersList?.data & (usersList?.data?.length > 0)) {
      usersList.data.forEach((element) => {
        user.push({
          userName: element.userName,
          firstName: element.firstName,
          lastName: element.lastName,
          name: element.firstName + " " + element.lastName,
          email: element.emailAddress,
          verified: element.userStatus,
          roles: element.roles,
          status: element.status,
          office: element.taOfficeDto,
          businessLine: element.businessLine,
        });
      });
      setUserData(user);
      setCopyUserData(user);
      // dispatch(resetState());
    }
  }, [usersList.data]);

  useEffect(() => {
    if (createUser.type === "SUCCESS") {
      createUser.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getUsers());
      dispatch(resetCreateUserState());
    }
  }, [dispatch, enqueueSnackbar, createUser]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const renderCreateUser = () => {
    setOpen(true);
  };
  const renderEditUser = (user) => {
    setSelectUser(user);
    if (user.userName === selectUser.userName) {
      setOpenEdit({
        userName: user.userName,
        isOpen: !openEdit.isOpen,
      });
    } else {
      setOpenEdit({
        userName: user.userName,
        isOpen: true,
      });
    }
  };
  function handleVerifiedChange(newPage) {
    let verified = "";
    if (newPage === "Non") {
      verified = false;
    }
    if (newPage === "Yes") {
      verified = true;
    }
    if (newPage === "All") {
      verified = "All";
    }
    const data = copyUserData
      .filter((user) =>
        userFirstName.length > 0
          ? user.firstName.toLowerCase().includes(userFirstName.toLowerCase())
          : user
      )
      .filter((user) =>
        userLastName.length > 0
          ? user.lastName.toLowerCase().includes(userLastName.toLowerCase())
          : user
      )
      .filter((user) =>
        emailValue.length > 0
          ? user.email.toLowerCase().includes(emailValue.toLowerCase())
          : user
      )
      .filter((user) =>
        verified !== "All" ? user.verified === verified : user
      )
      .filter((user) =>
        rolesValue !== "All" ? user.roles.includes(rolesValue) : user
      )
      .filter((user) =>
        statusValue !== "All" ? user.status === statusValue : user
      );
    setUserData(data);
    setVerifiedValue(verified);
  }
  function handleRoleChange(newPage) {
    const role = newPage;
    const data = copyUserData
      .filter((user) =>
        userFirstName.length > 0
          ? user.firstName.toLowerCase().includes(userFirstName.toLowerCase())
          : user
      )
      .filter((user) =>
        userLastName.length > 0
          ? user.lastName.toLowerCase().includes(userLastName.toLowerCase())
          : user
      )
      .filter((user) =>
        emailValue.length > 0
          ? user.email.toLowerCase().includes(emailValue.toLowerCase())
          : user
      )
      .filter((user) =>
        verifiedValue !== "All" ? user.verified === verifiedValue : user
      )
      .filter((user) => (role !== "All" ? user.roles.includes(role) : user))
      .filter((user) =>
        statusValue !== "All" ? user.status === statusValue : user
      );
    setUserData(data);
    setRolesValue(newPage);
  }
  function handleStatusChange(newPage) {
    const status = newPage;
    const data = copyUserData
      .filter((user) =>
        userFirstName.length > 0
          ? user.firstName.toLowerCase().includes(userFirstName.toLowerCase())
          : user
      )
      .filter((user) =>
        userLastName.length > 0
          ? user.lastName.toLowerCase().includes(userLastName.toLowerCase())
          : user
      )
      .filter((user) =>
        emailValue.length > 0
          ? user.email.toLowerCase().includes(emailValue.toLowerCase())
          : user
      )
      .filter((user) =>
        verifiedValue !== "All" ? user.verified === verifiedValue : user
      )
      .filter((user) =>
        rolesValue !== "All" ? user.roles.includes(rolesValue) : user
      )
      .filter((user) => (status !== "All" ? user.status === status : user));
    setUserData(data);
    setStatusValue(newPage);
  }
  function handleEmailChange(newPage) {
    const email = newPage;
    const data = copyUserData
      .filter((user) =>
        userFirstName.length > 0
          ? user.firstName.toLowerCase().includes(userFirstName.toLowerCase())
          : user
      )
      .filter((user) =>
        userLastName.length > 0
          ? user.lastName.toLowerCase().includes(userLastName.toLowerCase())
          : user
      )
      .filter((user) =>
        email.length > 0
          ? user.email.toLowerCase().includes(email.toLowerCase())
          : user
      )
      .filter((user) =>
        verifiedValue !== "All" ? user.verified === verifiedValue : user
      )
      .filter((user) =>
        rolesValue !== "All" ? user.roles.includes(rolesValue) : user
      )
      .filter((user) =>
        statusValue !== "All" ? user.status === statusValue : user
      );

    setUserData(data);
    setEmailValue(newPage);
  }
  function handleNameChange(newPage) {
    const name = newPage;
    const data = copyUserData
      .filter((user) =>
        userFirstName.length > 0
          ? user.firstName.toLowerCase().includes(userFirstName.toLowerCase())
          : user
      )
      .filter((user) =>
        name.length > 0
          ? user.lastName.toLowerCase().includes(name.toLowerCase())
          : user
      )
      .filter((user) =>
        emailValue.length > 0
          ? user.email.toLowerCase().includes(emailValue.toLowerCase())
          : user
      )
      .filter((user) =>
        verifiedValue !== "All" ? user.verified === verifiedValue : user
      )
      .filter((user) =>
        rolesValue !== "All" ? user.roles.includes(rolesValue) : user
      )
      .filter((user) =>
        statusValue !== "All" ? user.status === statusValue : user
      );
    setUserData(data);
    setUserLastName(newPage);
  }

  function handleUsernameChange(newPage) {
    const userName = newPage;
    const data = copyUserData
      .filter((user) =>
        userName.length > 0
          ? user.firstName.toLowerCase().includes(userName.toLowerCase())
          : user
      )
      .filter((user) =>
        userLastName.length > 0
          ? user.lastName.toLowerCase().includes(userLastName.toLowerCase())
          : user
      )
      .filter((user) =>
        emailValue.length > 0
          ? user.email.toLowerCase().includes(emailValue.toLowerCase())
          : user
      )
      .filter((user) =>
        verifiedValue !== "All" ? user.verified === verifiedValue : user
      )
      .filter((user) =>
        rolesValue !== "All" ? user.roles.includes(rolesValue) : user
      )
      .filter((user) =>
        statusValue !== "All" ? user.status === statusValue : user
      );
    setUserData(data);
    setUserfirstName(newPage);
  }
  const handleSearch = (value) => {
    if (!!value.length) {
      const data = copyUserData
        .filter((user) =>
          value.length > 0
            ? user.firstName.toLowerCase().includes(value.toLowerCase())
            : user
        )
        .filter((user) =>
          userLastName.length > 0
            ? user.lastName.toLowerCase().includes(value.toLowerCase())
            : user
        )
        .filter((user) =>
          emailValue.length > 0
            ? user.email.toLowerCase().includes(value.toLowerCase())
            : user
        )
        .filter((user) =>
          verifiedValue !== "All" ? user.verified === verifiedValue : user
        )
        .filter((user) =>
          rolesValue !== "All" ? user.roles.includes(rolesValue) : user
        )
        .filter((user) =>
          statusValue !== "All" ? user.status === statusValue : user
        );
      setUserData(data);
    } else {
      setUserData(copyUserData);
    }
  };
  const parPage = [2, 5, 10, 25, 100];
  const getbadgeForRole = (role) => {
    switch (role.toLowerCase()) {
      case "admin":
        return (
          <Chip
            label={"Admin"}
            className="chip-class bg-success"
            color="primary"
          />
        );
        case "project assistant":
          return (
            <Chip
              label={"Project Assistant"}
              className="chip-class bg-pink"
              color="primary"
            />
          );
      case "user":
        return (
          <Chip
            label={"User"}
            className="chip-class bg-primary"
            color="primary"
          />
        );
      case "project leader":
        return (
          <Chip
            label={"Project Leader"}
            className="chip-class bg-info"
            color="primary"
          />
        );
      case "user (external)":
        return (
          <Chip
            label={"User (External)"}
            className="chip-class bg-extern"
            color="secondary"
          />
        );
      default:
        return (
          <Chip
            label={"User"}
            className="chip-class bg-warning"
            color="primary"
          />
        );
    }
  };
  const getBadge = (status) => {
    switch (status) {
      case "Active":
        return (
          <Chip
            label={"Active"}
            className="chip-class bg-success"
            color="primary"
          />
        );
      case "Inactive":
        return <Chip label={"Inactive"} className="chip-class" disabled />;
      default:
        return <Chip label="" disabled />;
    }
  };
  return (
    <Grid style={{ height:"100%", overflowY:"scroll"}}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            border: "solid .5px grey",
            minWidth: 400,
            maxWidth: 650,
            flex: 1,
            marginRight: 5,
          }}
        >
          <InputBase
            sx={{ flex: 1 }}
            placeholder="Search User"
            inputProps={{ "aria-label": "search google maps" }}
            variant="standard"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: "#254a9a" }}
          color="primary"
          onClick={renderCreateUser}
          endIcon={<PersonAddIcon className="add-user" fontSize="large" />}
        >
         {getButtonLabel("create user")}
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 700, minHeight: 500 }}>
          <Table
            sx={{ minWidth: 700 }}
            size="small"
            aria-label="customized table"
          >
            <TableHead className="header">
              <TableRow>
                <StyledTableCell>First Name</StyledTableCell>
                <StyledTableCell align="left">Last Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Verified</StyledTableCell>
                <StyledTableCell align="left">Role</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">edit</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>
                  <Grid
                    style={{
                      background: "white",
                      borderRadius: 5,
                      padding: 2,
                      maxWidth: 170,
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      options={verified}
                      sx={{ width: "100%" }}
                      onChange={(event, newPage) => {
                        if (!!newPage) {
                          handleVerifiedChange(newPage);
                        } else {
                          handleVerifiedChange("All");
                          setVerifiedValue("All");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Verified" />
                      )}
                    />
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Grid
                    style={{
                      background: "white",
                      borderRadius: 5,
                      padding: 2,
                      maxWidth: 170,
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={usersRoles}
                      size="small"
                      sx={{ width: "100%" }}
                      onChange={(event, newPage) => {
                        if (!!newPage) {
                          const value = newPage.label;
                          handleRoleChange(value);
                        } else {
                          handleRoleChange("All");
                          setRolesValue("All");
                        }
                      }}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField {...params} label="Role" />
                      )}
                    />
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Grid
                    style={{
                      background: "white",
                      borderRadius: 5,
                      padding: 2,
                      maxWidth: 170,
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      sx={{ width: "100%" }}
                      id="combo-box-demo"
                      size="small"
                      options={["All", "Active", "Inactive"]}
                      onChange={(event, newPage) => {
                        if (!!newPage) {
                          handleStatusChange(newPage);
                        } else {
                          handleStatusChange("All");
                          setStatusValue("All");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Status" />
                      )}
                    />
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ minHeight: 500 }}>
              {userData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <>
                    <StyledTableRow key={row.userName}>
                      <StyledTableCell component="th" scope="row">
                        {row.firstName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.lastName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {!!row.verified ? "Yes" : "Non"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box>
                          {row.roles.map((e) => {
                            return getbadgeForRole(e);
                          })}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {getBadge(row.status)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        onClick={() => renderEditUser(row)}
                      >
                        <IconButton fontSize="small">
                          <BorderColorIcon
                            fontSize="small"
                            style={{ color: "#254a9a" }}
                          />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <>
                          <Box sx={{ margin: 1 }}>
                            {openEdit.isOpen &&
                              openEdit.userName === row.userName && (
                                <EditUserForm
                                  open={openEdit}
                                  onClose={() => setOpenEdit(false)}
                                  selectUser={selectUser}
                                />
                              )}
                          </Box>
                        </>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={parPage}
          component="div"
          count={userData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {open && <CreateUserForm open={open} onClose={() => setOpen(false)}  />}
    </Grid>
  );
}
