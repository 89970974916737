import React,{useState, useEffect, useMemo, useCallback} from 'react'
import PropTypes from 'prop-types'
import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import SortableDroppedItems from './SortableDroppedItems';
import { AppBar, Box, CardContent, Toolbar, Typography } from '@mui/material';
import { useDocumentContextHook } from './DocumentBuilderContext';
import { arrayMove } from "@dnd-kit/sortable";

function DropContainer(props) {
  const { documentItems, selectedItem,reOrderDropItems} = useDocumentContextHook();
  const [currentItem, setCurrentItem] = useState(selectedItem)
  const [activeId, setActiveId] = useState();

  const findItemInDocumentItems = (node, id) => {
    if (!node || !node.children) {
      return null;
    }
  
    const foundItem = node.children.find(item => item.id === id);
    if (foundItem) {
      return foundItem;
    }
  
    for (const child of node.children) {
      const result = findItemInDocumentItems(child, id);
      if (result) {
        return result;
      }
    }
    return null;
  };
  

  useEffect(() => {
    if(!!selectedItem){
      if(selectedItem.nodeType ==="document-root"){
        setCurrentItem(documentItems);
      }else{
       let id =  selectedItem.depth === 1 ? selectedItem.id : selectedItem.parentNode.id;
        let header = documentItems.children.find((f) => f.id === id);
        let foundItem = {};
        if(!!header && selectedItem.depth === 1){
          setCurrentItem(header);
        }else{
          foundItem = findItemInDocumentItems(header, selectedItem.id);
          setCurrentItem(foundItem);
        }
        
      }
    }
  }, [documentItems,selectedItem]);

  const dropContainerItems = useMemo(() => currentItem?.children?.filter((f) => f.nodeType.includes("data-")), [currentItem])
  const dropOverlay = useMemo(() => currentItem?.children?.find((f) => f.id === activeId), [currentItem,activeId])
  const handleDropContainerDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = currentItem.children.findIndex((item) => item.id === active.id);
      const newIndex = currentItem.children.findIndex((item) => item.id === over.id);
      const afterDrag = arrayMove(currentItem.children, oldIndex, newIndex);
      reOrderDropItems({...currentItem, children:afterDrag})
    }
  };

  const handleDragStart = useCallback(
    ({ active }) => setActiveId(active.id),
    []
  );

  return (
    <>
    <AppBar
            style={{
              backgroundColor: "#d08f8e",
              position: "sticky",
              borderRadius: 4,
              height: 40,
            }}
          >
            <Toolbar className={"d-flex align-items-center dialog-toolbar"} 
           style={{
            height: 40,
           }}>
              <Box className={"d-flex align-items-center"}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#ffffff",
                  marginRight: 5,
                  fontWeight: "bold",
                }}
              >
                 {currentItem?.index} 
              </Typography>
            </Box>
              <Typography variant="subtitle1" className='p-1'>
              {currentItem?.settings?.title}
              </Typography>
            </Toolbar>
          </AppBar>
          <CardContent
            className="custom-scrollBar m-1"
            style={{
              width: "98%",
              overflowY: "auto",
              overflowX: "hidden",
              padding: 2,
              height: "100%",
            }}
            id={`${documentItems.id}-container`}
          > 
          {!!currentItem &&     
    <DndContext  collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDropContainerDragEnd}>
      <SortableContext items={dropContainerItems} id="drop-container">
        <div className='w-100 d-flex flex-column'>
          {dropContainerItems.map((item) => (
            <SortableDroppedItems key ={`key_${item.id}`} item={item} />
          ))}
         
        </div>
      </SortableContext>
      <DragOverlay>
      {activeId && (
          <SortableDroppedItems overlay item={dropOverlay}/>
        )}
      </DragOverlay>
    </DndContext>
     }
    </CardContent>
    </>
  );
}

DropContainer.propTypes = {
    onDrop: PropTypes.func
}

export default DropContainer

