import {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "react-data-grid";
import ToolPanel from "./ToolPanel";
import {
  getIntlTranslation,
  getTextSize,
  isEmpty,
} from "../../../../../utils/helper";
import { useSnackbar } from "notistack";
import CategoriesTab from "./CategoriesTab";
import { saveRowsData } from "../../../api/statutoryComplianceApi";
import { setUpdateRows } from "../statutoryComplianceSlice";
import { resetState } from "../statutoryComplianceSlice";
import RouterPrompt from "../../../../../components/prompt/RouterPrompt";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import SearchBar from "./SearchBar";

const CategoriesSectionStyle = {
  maxHeight: "calc(100% - 45px)",
  height: "calc(100% - 45px)",
  overflow: "hidden",
};

function EmptyRowsRenderer() {
  return (
    <div style={{ textAlign: "center", gridColumn: "1/-1" }}>
      {getIntlTranslation("_No Rows")}
      <span lang="eng" title="empty row"></span>
    </div>
  );
}

const getRowHeight = (p) => {
  return !!p.row.height ? p.row.height : 40;
};

function rowKeyGetter(row) {
  return row.id;
}

const StatutoryDataGrid = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const inspection = useSelector((state) => state.tddInspection);
  const editRow = useSelector((state) => state.tddInspection.rowData);
  const [rows, setRows] = useState(props.rows);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [columns, setColumns] = useState([]);
  const [filteredRows, setFilteredRows] = useState(props.rows);
  const [tabSelected, setTabSelected] = useState({});
  const [updateRowsList, setUpdateRowsList] = useState([]);
  const [currentCategorie, setCurrentCategorie] = useState({});
  const readOnlystate = useSelector((state) => state.readOnly);
  const headerList = props.headers;

  function getColumns() {
    const temp = columns.filter((f) => {
      return (
        f.key === "action" ||
        f.key === "select-row" ||
        (f.checked &&
          (f.optionalHeaders.length > 0
            ? f.optionalHeaders.includes(tabSelected.ident)
            : true) &&
          f.categories.find((o) => o.category.ident === tabSelected.ident))
      );
    });
    return temp;
  }

  const getWarningText = useCallback(() => {
    const element = inspection.updatedRows.reduce((group, row, index) => {
      const { trade } = row;
      group[trade] = group[trade] ?? [];
      group[trade].push(row);
      return group;
    }, {});

    return (
      <>
        <Typography variant="body1">
          {getIntlTranslation("_unsaved changes")}
        </Typography>
        <List>
          {Object.keys(element).map((el, index) => {
            return (
              <ListItem key={index} style={{ padding: 0 }}>
                <ListItemText
                  id={`${index}_${el}`}
                  primary={`${index + 1}. ${el}`}
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  }, []);

  function getNonCheckedColumns(columns) {
    return columns.filter((f) => {
      return (
        !f.required &&
        f.categories.find((o) => o.category.ident === tabSelected.ident)
      );
    });
  }

  useEffect(() => {
    setRows(props.rows);
    setFilteredRows(props.rows);
    setColumns([...props.columns]);
  }, [props.columns, props.rows]);

  useEffect(() => {
    const getUpdateRows = rows.filter(
      (el) => (el.updated || el.deleted) && el.type === "trade"
    );
    setUpdateRowsList(getUpdateRows);
    dispatch(setUpdateRows(getUpdateRows));
  }, [dispatch, filteredRows, rows]);

  useEffect(() => {
    if (editRow.type === "SAVED") {
      editRow.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      let temp = [];
      rows.forEach((el, index) => {
        const rowFound = editRow.data.find((f) => f.rowId === el.id);
        if (!!rowFound) {
          if (!rowFound.deleted) {
            const customData = JSON.parse(rowFound.customData);
            const originalValue = {};
            let data = {
              id: rowFound.rowId,
              category: rowFound.category,
              subCategory: rowFound.subCategory,
              tradeGroup: rowFound.tradeGroup,
              tradeIdent: rowFound.tradeIdent,
              tradeOrder: rowFound.tradeOrder,
              custom: rowFound.custom,
              deleted: rowFound.deleted,
              inspectionDataIdent: rowFound.inspectionDataIdent,
              updated: false,
              type: "trade",
            };
            headerList.forEach((h) => {
              if (h.editor === "textarea") {
                let tsize = getTextSize(rowFound[h.key], "35px");
                let height = getHeight(tsize[`width`]);
                if (!data["height"]) {
                  data["height"] = height;
                } else {
                  data["height"] = data.height >= height ? data.height : height;
                }
              }
              if (h.editor === "multi-dropdown") {
                data[h.key] = {
                  name: rowFound[h.key],
                  options: [],
                  multiple: true,
                };

                if (!!rowFound[h.key]) {
                  let multiOption = rowFound[h.key].split(",");
                  let muiltiTemp = multiOption.map((k) => {
                    return h.options.find(
                      (option) => option.title.toLowerCase() === k.toLowerCase()
                    );
                  });
                  data[h.key] = { ...data[h.key], options: muiltiTemp };
                }
                originalValue[h.key] = data[h.key];
              } else if (h.editor === "dropdown") {
                data[h.key] = {
                  name: rowFound[h.key],
                  options: [],
                  multiple: false,
                };
                if (!!rowFound[h.key]) {
                  data[h.key] = {
                    ...data[h.key],
                    options: h.options.find(
                      (option) =>
                        option.title.toLowerCase() ===
                        rowFound[h.key]?.toLowerCase()
                    ),
                  };
                }
                originalValue[h.key] = data[h.key];
              } else if (h.custom) {
                let headerData = customData?.find((d) => d.key === h.key);
                if (!!headerData) {
                  data[h.key] = headerData.value;
                  let tsize = getTextSize(headerData.value, "35px");
                  let height = getHeight(tsize[`width`]);
                  if (!data["height"]) {
                    data["height"] = height;
                  } else {
                    data["height"] =
                      data.height >= height ? data.height : height;
                  }
                } else {
                  data[h.key] = "";
                }
                originalValue[h.key] = data[h.key];
              } else {
                data[h.key] = rowFound[h.key];
                originalValue[h.key] = rowFound[h.key];
              }
            });
            data = { ...data, original: originalValue };
            temp.push(data);
          }
        } else {
          temp.push(el);
        }
      });

      setRows(temp);
      setFilteredRows(temp);

      dispatch(resetState());
      props.onClose();
    }
  }, [
    dispatch,
    editRow,
    enqueueSnackbar,
    headerList,
    props,
    props.onClose,
    rows,
  ]);

  useEffect(() => {
    if (!!inspection.tab) {
      setTabSelected(inspection.tab);
      setFilteredRows(rows);
    }
    if (inspection.optionalHeaders.length > 0 && !!inspection.tab) {
      setColumns((prev) =>
        prev.map((col) => {
          const foundSelectedColumn = inspection.optionalHeaders.find(
            (f) =>
              f.key === col.key && f.categories.ident === inspection.tab.ident
          );
          if (!!foundSelectedColumn) {
            col.checked = foundSelectedColumn.checked;
            if (
              !col.optionalHeaders.find((c) => c === foundSelectedColumn.ident)
            ) {
              col.optionalHeaders = [
                ...col.optionalHeaders,
                ...[foundSelectedColumn.categories.ident],
              ];
            }
          }
          return col;
        })
      );
    }
    if (!!inspection.filters) {
      filterRows(inspection.filters);
    }
  }, [inspection.optionalHeaders, inspection.tab, inspection.filters, rows]);

  const summaryRows = useMemo(() => {
    const summaryRow = {
      id: "total_0",
      totalCount: filteredRows.filter((f) => f.type !== "inspection_category")
        .length,
      missing: filteredRows.filter(
        (r) => r.documentStatusObj?.value === "Missing"
      ).length,
      defectFree: filteredRows.filter(
        (r) => r.documentStatusObj?.value === "Defect Free"
      ).length,
      yesCount: filteredRows.filter((r) => r.missing).length,
    };
    return [summaryRow];
  }, [filteredRows]);

  useImperativeHandle(ref, () => ({
    insertRow(p) {
      let insertRowIdx = 1 + rows.findIndex((f) => f.id === p.row.id);
      let data = {
        id: `row${Math.random() * 30}-${insertRowIdx}`,
        category: p.row.category,
        subCategory: p.row.subCategory,
        tradeGroup: p.row.tradeGroup,
        tradeIdent: null,
        inspectionDataIdent: null,
        tradeOrder: p.row.tradeOrder,
        custom: true,
        updated: true,
        type: "trade",
        original: {},
      };
      headerList.forEach((h) => {
        if (h.editor === "multi-dropdown") {
          data[h.key] = p.row[h.key];
          data.original[h.key] = data[h.key];
        } else if (h.editor === "dropdown") {
          data[h.key] = {
            name: null,
            options: [],
            multiple: false,
          };
          data.original[h.key] = data[h.key];
        } else {
          data[h.key] = "";
          data.original[h.key] = "";
        }
      });

      let temp = [
        ...rows.slice(0, insertRowIdx),
        data,
        ...rows.slice(insertRowIdx),
      ];
      addToFilteredRow(p, data);
      setRows(temp);
    },

    duplicateRow(p) {
      let insertRowIdx = 1 + rows.findIndex((f) => f.id === p.row.id);
      let data = {
        id: `row${Math.random() * 30}-${insertRowIdx}`,
        category: p.row.category,
        subCategory: p.row.subCategory,
        tradeGroup: p.row.tradeGroup,
        tradeIdent: p.row.tradeIdent,
        inspectionDataIdent: null,
        tradeOrder: p.row.tradeOrder,
        custom: true,
        updated: true,
        type: "trade",
        height: p.row.height,
        original: {},
      };
      headerList.forEach((h) => {
        data[h.key] = p.row[h.key];
        data.original[h.key] = p.row[h.key];
      });

      let temp = [
        ...rows.slice(0, insertRowIdx),
        data,
        ...rows.slice(insertRowIdx),
      ];
      addToFilteredRow(p, data);
      setRows(temp);
    },

    deleteRow(p) {
      let insertRowIdx = rows.findIndex((f) => f.id === p.row.id);
      let row = rows[insertRowIdx];
      let temp = [...rows];
      temp[insertRowIdx] = { ...row, deleted: true };

      //temp.splice(insertRowIdx, 1);
      deleteFromFiltered(p);
      setRows(temp);
    },

    restoreRow(p) {
      let insertRowIdx = rows.findIndex((f) => f.id === p.row.id);
      let row = rows[insertRowIdx];
      let temp = [...rows];
      temp[insertRowIdx] = { ...row, deleted: false };

      //temp.splice(insertRowIdx, 1);
      restoreFromFiltered(p);
      setRows(temp);
    },

    blockSelect(p, checked) {
      const selectedId = rows
        .filter((m) => m.subCategory.ident === p.row.subCategory.ident)
        .map((m) => m.id);
      if (checked) {
        setSelectedRows(new Set(selectedId));
      } else {
        setSelectedRows((prev) => {
          return prev.filter((f) => !selectedId.includes(f));
        });
      }
    },

    blockPasteSelect(p) {
      let allRows = [...rows];
      let filterRows = [...filteredRows];
      const selection = Array.from(selectedRows);
      const toPaste = rows.filter(
        (m) =>
          m.subCategory.ident === p.row.subCategory.ident &&
          m.type === "trade" &&
          selection.includes(m.id)
      );
      const groupByTradeGroup = toPaste.reduce((group, val) => {
        const { tradeGroup } = val;
        group[tradeGroup.ident] = group[tradeGroup.ident] ?? [];
        group[tradeGroup.ident].push(val);
        return group;
      }, {});

      Object.keys(groupByTradeGroup).forEach((key) => {
        const lastIndexRow = allRows.findLastIndex(
          (r) =>
            r.subCategory.ident === p.row.subCategory.ident &&
            r.tradeGroup.ident === key
        );
        const lastIndexFilterRow = filterRows.findLastIndex(
          (r) =>
            r.subCategory.ident === p.row.subCategory.ident &&
            r.tradeGroup.ident === key
        );
        const toAdd = groupByTradeGroup[key].map((tp, index) => {
          let data = {
            id: `row${Math.random() * 30}-${index}-${lastIndexRow}`,
            category: tp.category,
            subCategory: tp.subCategory,
            tradeGroup: tp.tradeGroup,
            tradeIdent: tp.tradeIdent,
            inspectionDataIdent: null,
            tradeOrder: tp.tradeOrder,
            custom: true,
            updated: true,
            type: "trade",
            height: tp.height,
            original: {},
          };
          headerList.forEach((h) => {
            data[h.key] = tp[h.key];
            data.original[h.key] = tp[h.key];
          });
          return data;
        });
        allRows.splice(lastIndexRow + 1, 0, ...toAdd);
        filterRows.splice(lastIndexFilterRow + 1, 0, ...toAdd);
      });
      setRows([...allRows]);
      setFilteredRows([...filteredRows]);

      // remove copied rows selection
      const copied = toPaste.map((t) => t.id);
      const notCopied = selection.filter(
        (t) => !copied.includes(t) && t !== p.row.id
      );
      setSelectedRows(new Set(notCopied));
    },
  }));

  const addToFilteredRow = (p, data) => {
    let filterRowIdx = 1 + filteredRows.findIndex((f) => f.id === p.row.id);
    let filterTemp = [
      ...filteredRows.slice(0, filterRowIdx),
      data,
      ...filteredRows.slice(filterRowIdx),
    ];
    setFilteredRows(filterTemp);
  };

  const deleteFromFiltered = (p) => {
    let insertRowIdx = filteredRows.findIndex((f) => f.id === p.row.id);
    let row = filteredRows[insertRowIdx];
    let temp = [...filteredRows];
    temp[insertRowIdx] = { ...row, deleted: true };
    //temp.splice(insertRowIdx, 1);
    setFilteredRows(temp);
  };

  const restoreFromFiltered = (p) => {
    let insertRowIdx = filteredRows.findIndex((f) => f.id === p.row.id);
    let row = filteredRows[insertRowIdx];
    let temp = [...filteredRows];
    temp[insertRowIdx] = { ...row, deleted: false };
    //temp.splice(insertRowIdx, 1);
    setFilteredRows(temp);
  };
  const getHeight = (t) => {
    if (t >= 250 && t < 450) {
      return 60;
    } else if (t >= 450 && t < 800) {
      return 80;
    } else if (t >= 800) {
      return 100;
    } else {
      return 40;
    }
  };

  const updateRow = (p, x) => {
    x.indexes.forEach((i) => {
      let row = p[i];
      let index = rows.findIndex((f) => f.id === row.id);
      var tsize = getTextSize(row[x.column.key], "35px");
      var height = getHeight(tsize[`width`]);
      if (!row.height) {
        row["height"] = height;
      } else {
        row["height"] = row.height >= height ? row.height : height;
      }
      if (p.length < rows.length) {
        rows[index] = row;
      } else {
        p[index] = row;
      }
    });

    if (p.length < rows.length) {
      setRows(rows);
      setFilteredRows(p);
    } else {
      setRows(p);
      setFilteredRows(p);
    }
  };

  const filterRows = useCallback(
    (search) => {
      let temp = rows.filter((r) => {
        return (
          (search.trade !== "" && r.type === "trade"
            ? r.trade.toLowerCase().includes(search.trade?.value.toLowerCase())
            : true) &&
          (r.type === "trade" && search.applicable.selected.length > 0
            ? search.applicable.selected.some((c) => {
                if (c.value) {
                  return c.value === r.inObject || c.value === r.objectRelevant;
                } else {
                  return c.value === r.inObject && c.value === r.objectRelevant;
                }
              })
            : true) &&
          (search.documentStatus.selected.length > 0
            ? search.documentStatus.selected.some((c) => r[c.key])
            : true) &&
          (search.status.selected.length > 0
            ? search.status.selected.some((c) => r[c.key]?.name === c.title)
            : true)
        );
      });
      setFilteredRows(temp);
    },
    [rows]
  );

  function handleFill({ columnKey, sourceRow, targetRow }) {
    const targetHeader = headerList.find((h) => h.key === columnKey);
    if (
      targetRow.type === "trade" &&
      columnKey === "trade" &&
      targetRow.custom &&
      targetHeader.editor.includes("text")
    ) {
      return {
        ...targetRow,
        [columnKey]: sourceRow[columnKey],
        original: sourceRow.original,
        updated:
          sourceRow[columnKey] !== targetRow.original[columnKey] ||
          targetRow.updated,
      };
    }
    if (targetRow.type === "trade" && columnKey !== "trade") {
      if (targetHeader.editor.includes("dropdown")) {
        return {
          ...targetRow,
          [columnKey]: sourceRow[columnKey],
          original: sourceRow.original,
          updated:
            sourceRow[columnKey].name !== targetRow.original[columnKey].name ||
            targetRow.updated,
        };
      } else {
        return {
          ...targetRow,
          [columnKey]: sourceRow[columnKey],
          original: sourceRow.original,
          updated:
            sourceRow[columnKey] !== targetRow.original[columnKey] ||
            targetRow.updated,
        };
      }
    } else {
      return targetRow;
    }
  }

  function handlePaste({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) {
    const targetHeader = headerList.find((h) => h.key === targetColumnKey);
    const sourceHeader = headerList.find((h) => h.key === sourceColumnKey);

    if (
      targetRow.type === "trade" &&
      targetColumnKey === "trade" &&
      targetRow.custom &&
      sourceHeader.editor.includes("text")
    ) {
      return {
        ...targetRow,
        [targetColumnKey]: sourceRow[sourceColumnKey],
        original: sourceRow.original,
        updated:
          sourceRow[sourceColumnKey] !== targetRow.original[targetColumnKey] ||
          targetRow.updated,
      };
    }

    if (targetRow.type === "trade" && targetColumnKey !== "trade") {
      if (
        targetHeader.editor.includes("dropdown") &&
        sourceColumnKey === targetColumnKey
      ) {
        return {
          ...targetRow,
          [targetColumnKey]: sourceRow[sourceColumnKey],
          original: sourceRow.original,
          updated:
            sourceRow[sourceColumnKey].name !==
              targetRow.original[targetColumnKey].name || targetRow.updated,
        };
      } else if (
        sourceHeader.editor.includes("text") &&
        targetHeader.editor.includes("text")
      ) {
        return {
          ...targetRow,
          [targetColumnKey]: sourceRow[sourceColumnKey],
          original: sourceRow.original,
          updated:
            sourceRow[sourceColumnKey] !==
              targetRow.original[targetColumnKey] || targetRow.updated,
        };
      } else if (targetHeader.editor === sourceHeader.editor) {
        return {
          ...targetRow,
          [targetColumnKey]: sourceRow[sourceColumnKey],
          original: sourceRow.original,
          updated:
            sourceRow[sourceColumnKey] !==
              targetRow.original[targetColumnKey] || targetRow.updated,
        };
      } else {
        const message = ("Can not paste", { targetHeader }, { sourceHeader });
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          preventDuplicate: true,
          variant: "error",
        });
        return targetRow;
      }
    } else {
      const message = ("Can not paste", { targetHeader }, { sourceHeader });
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        preventDuplicate: true,
        variant: "error",
      });
      return targetRow;
    }
  }

  function handleCopy({ sourceRow, sourceColumnKey }) {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
    }
  }

  const handleSave = () => {
    const temp = filteredRows.filter(
      (f) => (f.updated || f.deleted) && !isEmpty(f.trade)
    );

    let saveData = temp.map((m) => {
      let tradeTranslation = [];
      let customData = [];
      let data = {
        type: "inspectionData",
        category: m.category,
        rowId: m.id,
        subCategory: m.subCategory,
        tradeGroup: m.tradeGroup,
        tradeIdent: m.tradeIdent,
        tradeOrder: m.tradeOrder,
        deleted: m.deleted,
        custom: m.custom,
        inspectionDataIdent: m.inspectionDataIdent,
      };
      headerList.forEach((h) => {
        if (h.custom === true) {
          customData.push({
            header: h.name,
            headerIdent: h.headerIdent,
            key: h.key,
            value: m[h.key],
            custom: true,
          });
        } else if (h.editor.includes("dropdown")) {
          data[h.key] = m[h.key].name;
        } else if (h.editor === "date") {
          if (!!m[h.key]) {
            data[h.key] = new Date(m[h.key]);
          }
        } else {
          data[h.key] = m[h.key];
        }
      });
      if (customData.length > 0) {
        data.customData = JSON.stringify(customData);
      }
      if (tradeTranslation.length) {
        data.tradeTranslation = tradeTranslation;
      }
      return data;
    });
    dispatch(
      saveRowsData({
        projectIdent: scope.projectIdent,
        subAssetIdent: openAsset.subAssetIdent,
        rows: saveData,
      })
    );
  };

  const handleSelectedRowsChange = (selected) => {
    const selection = Array.from(selected);
    const prevSelected = Array.from(selectedRows);
    let temp = [];
    let combined = [];
    if (prevSelected.length > 0 && prevSelected.length > selection.length) {
      //something has been removed
      prevSelected.forEach((item) => {
        let found = selection.find((f) => f === item);
        if (!found) {
          let row = rows.find((r) => r.id === item);
          if (!!row && row.type === "inspection_category") {
            let toBeRemoved = rows
              .filter((m) => m.subCategory.ident === row.subCategory.ident)
              .map((k) => k.id);
            temp = [...temp, ...toBeRemoved];
          } else if (!!row && row.type === "trade") {
            let toBeRemoved = rows
              .filter(
                (m) =>
                  m.subCategory.ident === row.subCategory.ident &&
                  m.type === "inspection_category"
              )
              .map((k) => k.id);
            temp = [...temp, ...toBeRemoved];
          }
        }
      });
      combined = [...selection.filter((f) => !temp.includes(f))];
    } else {
      selection.forEach((row) => {
        let found = rows.find((r) => r.id === row);
        if (!!found && found.type === "inspection_category") {
          temp = [
            ...temp,
            ...rows
              .filter((m) => m.subCategory.ident === found.subCategory.ident)
              .map((k) => k.id),
          ];
        }
      });
      combined = [...temp, ...selection];
    }

    setSelectedRows(new Set(combined));
  };

  const onFilter = (search) => {
    let temp = rows.filter((r) =>
      !!search && r.type === "trade"
        ? r.trade.toLowerCase().includes(search.toLowerCase())
        : true
    );
    setFilteredRows(temp);
  };

  return (
    <>
      <SearchBar
        onFilter={(val) => onFilter(val)}
        warningText={getWarningText()}
        headers={headerList}
        categories={props.filterOptions.categories}
        onSave={handleSave}
      />
      <div style={CategoriesSectionStyle} className="w-100">
        <Box
          style={{
            width: "100%",
            height: 45,
            marginBottom: 5,
          }}
        >
          <CategoriesTab
            categories={props.filterOptions.categories}
            onSave={handleSave}
            headers={headerList}
            currentCategorie={currentCategorie}
            setCurrentCategorie={setCurrentCategorie}
          />
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "calc(100% - 50px)",
          }}
        >
          {columns.length > 0 && (
            <ToolPanel
              columnList={getNonCheckedColumns(columns)}
              headerList={headerList}
              categories={props.filterOptions.categories}
              currentCategorie={currentCategorie}
            />
          )}
          <DataGrid
            rowKeyGetter={rowKeyGetter}
            columns={getColumns()}
            rows={filteredRows.filter((f) => {
              return !!tabSelected && !!tabSelected.ident
                ? f.category.ident === tabSelected.ident
                : true;
            })}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
              minWidth: 60,
            }}
            onFill={handleFill}
            onCopy={handleCopy}
            onPaste={(val) => handlePaste(val)}
            selectedRows={selectedRows}
            onSelectedRowsChange={(x) => handleSelectedRowsChange(x)}
            onRowsChange={(p, x) => updateRow(p, x)}
            summaryRows={summaryRows}
            headerRowHeight={50}
            rowHeight={(p) => getRowHeight(p)}
            enableVirtualization={true}
            components={{
              noRowsFallback: <EmptyRowsRenderer />,
            }}
            style={{
              resize: "both",
              width: "100%",
              overflow: "auto",
              maxHeight: "99%",
            }}
            direction={"ltr"}
            className="fill-grid  rdg-light custom-scrollBar"
            cellNavigationMode={"CHANGE_ROW"}
          />
        </Box>
      </div>
      {!readOnlystate.isReadOnly && (
        <RouterPrompt
          when={rows.filter((f) => f.updated || f.deleted).length > 0}
      cancelText="_back"
          okText="_continue"
          onOK={() => true}
          onCancel={() => false}
          warningHeader={"Save And Leave"}
          message={getWarningText()}
        />
      )}
    </>
  );
});
export default StatutoryDataGrid;
