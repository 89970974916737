import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onDelete, saveImages } from "../../../../../components/imageUploader/imageProcessing";
import { useSnackbar } from "notistack";
import { resetState } from "../../slice/ImageUploadSlice";

function useImageHook () {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const imageUploadState = useSelector((state) => state.tddImageUpload);
  const [imageData, setImageData] = useState(null);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (
      (imageUploadState.type === "SAVED" ||
        imageUploadState.type === "UPDATED") &&
      !!imageUploadState.data &&
      file?.parentIdent === imageUploadState.data.parentIdent
    ) {
      imageUploadState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });

      setImageData(imageUploadState);
      dispatch(resetState());
    }
    if (
      imageUploadState.type === "DELETED" &&
      !!imageUploadState.data &&
      file?.parentIdent === imageUploadState.data.parentIdent
    ) {
      imageUploadState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
      setLoader(false);
      dispatch(resetState());
    }
  }, [dispatch, enqueueSnackbar, imageUploadState, file]);

 const onImageDelete = (file, node) => {
  setLoader(true);
  setFile(file);
  onDelete(file, node, dispatch, scope.projectIdent);
 }
 const onImagesave = (file, node) => {
  setLoader(true);
  setFile(file);
  saveImages(file, node,dispatch, scope.projectIdent)
 }

  const onResetState = () => {
    setImageData(null);
    setFile(null);
  };

  const onSetLoader = () => {
    setLoader(false);
  }
  return {imageData,loader, onSetLoader, onImageDelete, onImagesave, onResetState};
}

export default useImageHook;
