import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generateCapex,
  generateCapexSummary,
  generateCompletePortfolioCapex,
  generateFullReport,
  generatePhotoDocumentation,
  generateRedFlag,
} from "../../tdd-module/api/reportApi";
import { createAndDownloadBlobFile } from "../../../utils/helper";
import { resetErrorState } from "../../../error/errorHandlingSlice";
import { resetState,setUserSelection } from "../generate-report/reportSlice";
import { useSnackbar } from "notistack";

function useGenerateReportHook() {
  const [loading, setLoading] = useState(false);
  const report = useSelector((state) => state.report);
  const scope = useSelector((state) => state.container.scope);
  const errorState = useSelector((state) => state.errorhandling);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [requestData, setRequestData] = useState({request:{}, userSelection:{}});

  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
      enqueueSnackbar(errorState.messages, {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      dispatch(resetErrorState());
      setLoading(false);
    }
    if (
      report.data.ident === requestData.request?.ident &&
      report.type === "REPORT_SUCCESS"
    ) {
      setLoading(true);
      enqueueSnackbar(report.messages[0], {
        variant: "success",
        persist: false,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      const arrayBuffer = base64ToArrayBuffer(report.data.fileData);
      createAndDownloadBlobFile(
        arrayBuffer,
        report.data.fileName,
        report.data.contentType
      );
      dispatch(resetState());
      dispatch(setUserSelection(requestData.userSelection));
      setTimeout(function () {
        setLoading(false);
        setRequestData({request:{}, userSelection:{}});
      }, 600);
    }
  }, [report, dispatch, errorState, enqueueSnackbar, requestData]);

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  const handleReportSubmit = (report) => {
    console.log('report submit', report);
    setLoading(true);
    const requestedReport = {
      api: scope.api,
      projectIdent: scope.projectIdent,
      ident: report.selectedAsset.ident,
      parentIdent: report.selectedAsset.parentIdent,
      isParent: report.selectedAsset.isParent,
      name: report.selectedAsset.name,
      language: report.lang.value,
      version: report.version.value,
    };
    setRequestData({request:requestedReport,userSelection: report});
    if (report.type.key === "cp") {
      dispatch(generateCapex(requestedReport));
    } else if (report.type.key === "cps") {
      dispatch(generateCapexSummary(requestedReport));
    } else if (report.type.key === "cpd") {
      dispatch(generateCompletePortfolioCapex(requestedReport));
    } else if (report.type.key === "pd") {
      dispatch(generatePhotoDocumentation(requestedReport));
    } else if (report.type.key === "rf") {
      dispatch(
        generateRedFlag({
          ...requestedReport,
          users: report.selectedAsset.users.map((m) => ({
            type: "userDto",
            emailAddress: m.value,
          }))
        })
      );
    } else if (report.type.key === "fr") {
      dispatch(
        generateFullReport({
          ...requestedReport,
          users: report.selectedAsset.users.map((m) => ({
            type: "userDto",
            emailAddress: m.value,
          }))
        })
      );
    }
  };

  return [handleReportSubmit, loading];
}

export default useGenerateReportHook;
