import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Avatar,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  CardContent,
  Divider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { getData } from "../../../../api/historyReportTextApi";
import { resetState } from "../../../report-text-history/historyReportTextSlice";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import {
  decrypt,
  getTranslationVariantText,
  getIntlTranslation,
  getDialogBoxHeader,
} from "../../../../../../utils/helper";

const TextEditorHistory = ({ isOpen, label, close, rtIdent}) => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const rtHistory = useSelector((state) => state.tddReportHistory);
  const [historyList, sethistoryList] = useState([]);
  const [selectedhistoryValue, setselectedhistoryValue] = useState({});
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);
  const system = useSelector((state) => state.system);

  const getCurrentUser = () => {
    const decrypted = decrypt(auth.user);
    const user = JSON.parse(decrypted);
    return user;
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getData({
          api: scope.api,
          projectIdent: scope.projectIdent,
          reportTextIdent: rtIdent,
          assetIdent: openAsset.ident,
        })
      );
    }
  }, [
    dispatch,
    isOpen,
    openAsset.ident,
    rtIdent,
    scope.api,
    scope.projectIdent,
  ]);

  useEffect(() => {
    if (rtHistory.type === "SUCCESS" && !!rtHistory.data) {
      sethistoryList(rtHistory.data);
      setselectedhistoryValue(rtHistory.data[0]);
      dispatch(resetState());
    }
  }, [rtHistory, dispatch]);

  const renderHistory = () => (
    <Box className="w-100 d-flex flex-column p-1">
      {historyList.map((m, index) => {
        const time = new Date(m.lastUpdatedTime);
        return (
          <><Card key={ `key-${index}` } className="p-1 m-1">
            <Box className="d-flex">
              <Avatar
                style={ { width: 35, height: 35 } }
                variant="rounded"
                className="m-1"
              >

              </Avatar>
              <Box className="d-flex flex-column m-1">
                <Box>
                  <Typography variant="subtitle" className="p-1 fw-bold">
                    { m.lastUpdatedBy }
                  </Typography>
                </Box>
                <Box className="d-flex">
                  <Typography variant="caption">
                    { `${time.toLocaleString( system.language, {
                      weekday: "short",
                    } )},
                ${time.toLocaleDateString( system.language )}
                 ${time.toLocaleTimeString( system.language )}
                 ${system.language === "de" ? "Uhr" : ""}` }
                  </Typography>
                </Box>
              </Box>
            </Box>
            <CardContent className="w-100 p-1 text-data">
              <Typography
                style={ {
                  fontSize: 11,
                  width: "100%",
                  textAlign: "justify",
                } }
                dangerouslySetInnerHTML={ { __html: m?.inputValue } }
                className="label-text"
              ></Typography>
            </CardContent>
          
          </Card>
          <Divider
              orientation="vertical"
              className="w-100 mb-1 g-divider rounded-3"
              style={ { height: 10} } />
              </>
        );
      })}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"history"}>
        <Drawer
          anchor={"left"}
          style={{ width: 500 }}
          role="presentation"
          open={isOpen}
          onClose={(e) => close()}
        >
          <Box
            style={{ width: 500, overflow: "auto" }}
            className="custom-scrollBar"
          >
            <AppBar
              style={{
                backgroundColor: "#d08f8e",
                position: "sticky",
                width: "100%",
                height: 40,
              }}
            >
              <Toolbar className={"dialog-toolbar"}>
                {getDialogBoxHeader("_history")}
                <IconButton
                  edge="start"
                  color="inherit"
                  id="create_project_close"
                  onClick={() => close()}
                  aria-label="close"
                  style={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            {historyList.length > 0 ? (
              renderHistory()
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  component="div"
                  style={{ margin: 10, fontWeight: "bold" }}
                >
                  {getIntlTranslation("_no-data")}
                </Typography>
              </>
            )}
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default TextEditorHistory;
