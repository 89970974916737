import React from 'react';
import ProjectInfromationHome from "./ProjectInfromationHome"
import { pmRoot} from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const ProjectInformationRouteConfig = {
  routes: [
		{
    path: `${pmRoot}/project/project-information`,
    exact: true,
    name: <IntlMessages id={"menu.project-information"}/>,
    element: <ProjectInfromationHome/>,
    auth: [],
  },
	]
};
export default ProjectInformationRouteConfig;