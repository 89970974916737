import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUsers, getRolesList } from "../../../api/userManagementApi";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
// 
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Dialog,
  Chip,
  Grid,
  TextField,
  Backdrop,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete from "@mui/material/Autocomplete";
import { resetCreateUserState } from "../userManagementSlice";
import { resetErrorState } from "../../../error/errorHandlingSlice";
import { getTAEuropeOffices } from "../../../api/userProfileApi";
import { styled } from "@mui/material/styles";
import CustomButton from "../../../components/buttons/CustomButton";
import { getLabel, getDialogBoxHeader } from "../../../utils/helper";

const useStyles = styled("div")((theme) => ({
  appBar: {
    position: "relative",
    zIndex: "0",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1350,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateUserForm = (props) => {
  const { onClose, open, history } = props;
  const [openDialog, setOpenDialog] = useState(open);
  const [roles, setRoles] = useState([]);
  const [userRole, setUserRole] = useState([
    { value: "User", label: "User", isFixed: true },
  ]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const createUser = useSelector((state) => state.userManagement.createUsers);
  const roleList = useSelector((state) => state.userManagement.roles);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [offices, setOffices] = useState([]);
  const [taOffice, setTaOffice] = useState({});
  const getOffice = useSelector((state) => state);
  const profile = useSelector((state) => state.userProfile);
  const { enqueueSnackbar } = useSnackbar();

  const errorState = useSelector((state) => state.errorhandling);
  // formik intial values:
  const intitialValues = {
    firstName: "",
    lastName: "",
    userName: "",
    emailAddress: "",
    taOfficeDto: [],
    role:  userRole ,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("FNameRequired"),
    lastName: Yup.string().required("LNameRequired"),
    userName: Yup.string().required("UserNameRequired"),
    emailAddress: Yup.string().email("EmailInvalid").required("EmailRequired"),
  });

  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
      setLoader(false);
      dispatch(resetErrorState());
    }
  }, [dispatch, errorState]);

  useEffect(() => {
    dispatch(getRolesList());
    handleTAEuropeCity();
  }, [dispatch]);

  useEffect(() => {
    setOffices(profile.offices.data);
  }, [profile.offices]);

  useEffect(() => {
    if (roleList.type === "SUCCESS") {
      setRoles(
        roleList.data.suggestionvaluesList.map((role) => {
          return {
            value: role,
            label: role,
          };
        })
      );
    }
  }, [roleList]);
  const handleTAEuropeCity = () => {
    dispatch(getTAEuropeOffices());
  };
  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  const renderValues = (tagValue, getTagProps) => {
    return tagValue.map((option, index) => (
      <Chip
        label={option.label}
        color="primary"
        size="small"
        {...getTagProps({ index })}
        disabled={option.isFixed}
      />
    ));
  };
  const roleOnChange = (event, newValue) => {
    newValue.push({ value: "User", label: "User", isFixed: true });
    const uniqueSetRoles = newValue.reduce(
      (unique, item) =>
        unique.find((v) => v.value === item.value) ? unique : [...unique, item],
      []
    );
    setUserRole([...uniqueSetRoles]);
  };
  const changeUserCity = (event, newValue) => {
    setTaOffice(newValue);
  };

  const onSubmit = (values) => {
    values.roles = [];
    values.taOfficeDto = taOffice;
    userRole.forEach((element) => {
      values.roles.push(element.value);
    });
    dispatch(createUsers(values));
    setLoader(true);
  };

  useEffect(() => {
    if (createUser.type === "ERROR") {
      setError(true);
      setLoader(false);
      Object.keys(createUser.errorFields).forEach(function eachKey(key) {
        formikRef.current.setFieldError(key, createUser.errorFields[key]);
      });
      dispatch(resetCreateUserState());
    }

    if (createUser.type === "SUCCESS") {
      if (typeof onClose === "function") {
        onClose();
      }
      setLoader(false);
      setError(false);
    }
  }, [createUser, dispatch, enqueueSnackbar]);
  
  return (
    <>
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        TransitionComponent={Transition}
        open={openDialog}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="Add user Dialog"
      >
        <AppBar className={"appBar"}>
          <Toolbar
            className={"dialog-toolbar"}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {getDialogBoxHeader("create user")}
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={(e) => handleClose((prev) => !prev)}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Formik
            initialValues={intitialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formikRef}
            style={{ width: "100%" }}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form style={{ width: "100%" }}>
                <Grid
                  style={{ width: "100%" }}
                  container
                  className="custom-grid-container"
                  justify="flex-start"
                  spacing={3}
                >
                  <Grid item md={6} xs={12}>
                    <Field
                      name="userName"
                      label={getLabel("user.username")}
                      as={TextField}
                      className="grid-fields"
                      variant={"standard"}
                      error={touched.userName && errors.userName}
                      helperText={touched.userName && errors.userName}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      as={TextField}
                      name="emailAddress"
                      label={getLabel("user.emailAddress")}
                      className="grid-fields"
                      type="email"
                      variant={"standard"}
                      error={touched.emailAddress && errors.emailAddress}
                      helperText={touched.emailAddress && errors.emailAddress}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      name="firstName"
                      label={getLabel("user.firstName")}
                      as={TextField}
                      variant={"standard"}
                      className="grid-fields"
                      error={touched.firstName && errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Field
                      name="lastName"
                      label={getLabel("user.lastName")}
                      as={TextField}
                      variant={"standard"}
                      className="grid-fields"
                      error={touched.lastName && errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      style={{ maxWidth: "100%" }}
                      options={offices}
                      getOptionLabel={(option) => option.city}
                      name="taOfficeDto"
                      className="grid-fields"
                      disableClearable
                      onChange={(event, newValue) => {
                        changeUserCity(event, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getLabel("user.office")}
                          variant={"standard"}
                          name="taOfficeDto"
                          // onClick={handleTAEuropeCity}
                        />
                      )}
                      error={touched.taOfficeDto && errors.taOfficeDto}
                      helperText={touched.taOfficeDto && errors.taOfficeDto}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      className="grid-fields"
                      multiple
                      id="fixed-tags"
                      value={userRole}
                      siyze="small"
                      onChange={(event, newValue) =>
                        roleOnChange(event, newValue)
                      }
                      options={roles}
                      getOptionLabel={(option) => option.label}
                      renderTags={(tagValue, getTagProps) =>
                        renderValues(tagValue, getTagProps)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getLabel("user.role")}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <CustomButton
            id={`create-user`}
            buttonText={"register"}
            variant="button"
            style={{ margin: 5}}
            tooltip={{
              title: "click to create a project",
            }}
            handleClick={(e) => formikRef.current.handleSubmit()}
          />
        </DialogActions>
      </Dialog>
      {loader && (
        <Backdrop className={useStyles.backdrop} open={loader}>
          <div className="preloader">
            <div></div>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default CreateUserForm;
