import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../error/errorHandlingSlice";
import {
  getFailure,
  getSuccess,
  getProjectData,
  getClientData,
} from "../guru-suite/component-bundle/project-list/projectListSlice.js";
import { renewAccessTokenIfGoingExpire } from "./authApi";

export const getProjectList = () => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios.get(`/projects/project-list`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));

   }
  }};

export const updateProjectFavourite = (project) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/projects/addOrUpdateFav`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          projectName: project.name,
          projectNumber: project.number,
          favourite: project.isFavourite,
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getProjectList());
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};

  export const fetchProjectData = (projectIdent) => async (dispatch) => {
    try {
       const tokenHeader = await renewAccessTokenIfGoingExpire();
       const response = await trackPromise(
        axios.get(`/projects/project-info/${projectIdent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
        })
      );
      if(response.data.type==='ERROR' || response.data.type==="ACCESS_DENIED" ){
        dispatch(getFailure(response.data));
      }else{
      dispatch(getProjectData(response.data));
      }
      
    } catch (error) {
     if (error.response || error.request){
       let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
     }
    }};

    export const fetchClientData = () => async (dispatch) => {
      try {
         const tokenHeader = await renewAccessTokenIfGoingExpire();
         const response = await trackPromise(
          axios.get(`/clients/fetch-clients`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: tokenHeader,
            },
          })
        );
        if(response.data.type==='ERROR' || response.data.type==="ACCESS_DENIED" ){
          dispatch(getFailure(response.data));
        }else{
        dispatch(getClientData(response.data));
        }
        
      } catch (error) {
       if (error.response || error.request){
         let payload = {};
        payload = {
          type: "ERROR",
          messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
       }
      }};


