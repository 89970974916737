import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import IntlMessages from "../../../utils/IntlMessages";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Typography from "@mui/material/Typography";

const EditIconButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const { icon, tooltip, label, color, ...restProps } = props;
  const state = useSelector((state) => state.readOnly);

  useEffect(() => {
    if (state.isReadOnly) {
      setReadOnly(state.isReadOnly);
    }
  }, [state]);
  const checkPermission = () => {
    if (!!props.permission) {
      return (
        <>
          {props.permission.create && props.permission.update ? (
            <Tooltip title={props.tooltip.title}>
            <IconButton
              id={props.id}
              className={props.className}
              
              onClick={(e) => props.handleClick(e)}
              data-index={props.dataIndex}
              {...restProps}
            >
              <ModeEditIcon style={{ ...props.iconStyle,color: props.color}} />
            </IconButton>
          </Tooltip>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <Tooltip title={props.tooltip.title}>
          <IconButton
            id={props.id}
            className={props.className}
            autoFocus
            onClick={(e) => props.handleClick(e)}
            data-index={props.dataIndex}
            {...restProps}
          >
            <ModeEditIcon style={{ ...props.iconStyle,color: props.color}} />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return <>{!readOnly && checkPermission()}</>;
};
EditIconButton.defaultProps = {};
EditIconButton.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  color: PropTypes.string,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  permission: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  iconStyle:PropTypes.object,
};

export default EditIconButton;
