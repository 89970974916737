import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography, Autocomplete, TextField, Grid } from '@mui/material';
import CreateProjectForm from './CreateProjectForm';
import AddUsersAndRole from './AddUsersAndRole';
import { Formik, Form } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import * as Yup from 'yup';
import IntlMessages from '../../../../utils/IntlMessages';
import { getLabel } from '../../../../utils/helper';
import { fetchProjectData } from '../../../../api/projectListApi';
import { createProject } from '../../../../api/createProjectApi';
import { resetState } from '../../project-list/projectListSlice';
import { setReadOnly } from '../../slice/readOnlySlice';

const validationSchema = Yup.object().shape({
  projectName: Yup.string()
    .min(1, <IntlMessages id="required_field" />)
    .required(<IntlMessages id="required_field" />),
  projectNumber: Yup.string().required(<IntlMessages id="required_field" />),
  projectDescription: Yup.string().required(<IntlMessages id="required_field" />),
  language: Yup.string().nullable().required(<IntlMessages id="required_field" />),
  clientName: Yup.string().required(<IntlMessages id="required_field" />),
  clientAddress: Yup.string().required(<IntlMessages id="required_field" />),
  clientCity: Yup.string().required(<IntlMessages id="required_field" />),
  clientZip: Yup.string().required(<IntlMessages id="required_field" />),
  clientCountry: Yup.string().required(<IntlMessages id="required_field" />),
  projectThreshold: Yup.number().required(<IntlMessages id="required_field" />),
});

const CreateProjectContainer = (props) => {
  const projectlistState = useSelector((state) => state.projectList);
  const [projectToCopy, setprojectToCopy] = useState({ projectName: '' });

  const dispatch = useDispatch();
  const [intialFormData, setIntialFormData] = useState({
    projectName: '',
    projectNumber: '',
    startDate: null,
    endDate: null,
    isUsersAdded: false,
    users: {},
    language: '',
    projectDescription: '',
    clientName: '',
    clientIdent: '',
    clientContactPerson: '',
    clientAddress: '',
    clientStreet2: '',
    clientCity: '',
    clientState: '',
    clientZip: '',
    clientCountry: '',
    serviceLine: props.serviceLine.name,
    projectThreshold: 10000, // Set default value to 10000
  });

  useEffect(() => {
    if (
      projectlistState.projectData.type === 'SUCCESS' &&
      !!projectlistState.projectData.data
    ) {
      const temp = projectlistState.projectData.data;
      const client = temp.clientData;
      const address = client.addressData;
      let projectThreshold = temp.projectThreshold;
      if (projectThreshold === null || projectThreshold === undefined) {
        projectThreshold = 10000;
      } else if (projectThreshold === 0) {
        projectThreshold = 0;
      }
      let intialFormData = {
        projectName: temp.projectName,
        projectNumber: temp.projectNumber,
        startDate: null,
        endDate: null,
        language: '',
        projectDescription: temp.projectDescription,
        clientName: client.clientName,
        clientIdent: client.clientIdent,
        clientContactPerson: '',
        clientAddress: address.streetName,
        clientStreet2: '',
        clientCity: address.city,
        clientState: address.state,
        clientZip: address.postalCode,
        clientCountry: address.country,
        serviceLine: props.serviceLine.name,
        users: {},
        projectThreshold: projectThreshold, // Ensure default value is set
      };
      setIntialFormData(intialFormData);
      dispatch(setReadOnly(false));
      dispatch(resetState());
    }
  }, [dispatch, projectlistState.projectData, props.serviceLine]);

  const handleSave = (values) => {
    const address = {
      type: 'addressDto',
      streetName: values.clientAddress,
      optionalStreetName: values.clientStreet2,
      postalCode: values.clientZip,
      city: values.clientCity,
      state: values.clientState,
      country: values.clientCountry,
    };
    const clientData = {
      type: 'clientDto',
      clientName: values.clientName,
      clientIdent: values.clientIdent,
      addressData: address,
    };

    const userRoleList = Object.keys(values.users)
      ?.map((key) => values.users[key])
      .flat(1);
    let temp = [];
    userRoleList?.forEach((e) => {
      let index = temp.findIndex(
        (item) => item.emailAddress === e.user.emailAddress
      );
      if (index >= 0) {
        temp[index] = {
          ...temp[index],
          projectRolesList: [
            ...temp[index].projectRolesList,
            ...[
              {
                type: 'projectRolesDto',
                name: e.role,
              },
            ],
          ],
        };
      } else {
        const projectUserDto = {
          type: 'projectUserDto',
          firstName: e.user.firstName,
          lastName: e.user.lastName,
          emailAddress: e.user.emailAddress,
          projectRolesList: [
            {
              type: 'projectRolesDto',
              name: e.role,
            },
          ],
        };
        temp.push(projectUserDto);
      }
    });

    const projectThreshold = parseFloat(values.projectThreshold).toFixed(2);

    const project = {
      type: 'projectsDto',
      projectName: values.projectName,
      projectNumber: values.projectNumber,
      projectDescription: values.projectDescription,
      projectOwner: values.owner,
      projectLanguage: values.language,
      startDate: values.startDate,
      endDate: values.endDate,
      projectUserDtos: temp,
      clientData: clientData,
      serviceLine: values.serviceLine,
      projectThreshold: projectThreshold, // Ensure value is sent as decimal with 2 decimal places
    };

    dispatch(createProject({ api: props.serviceLine.api, data: project }));
  };

  return (
    <Card style={{ height: '100%', marginTop: 5 }}>
      {props.projectList.length > 0 && (
        <Box container style={{ marginTop: 10 }} justify="center">
          <Grid item md={6} xs={12}>
            <Autocomplete
              disablePortal
              size={'small'}
              id="project-list-portal"
              value={projectToCopy}
              options={props.projectList.sort((a, b) =>
                a.serviceLine.localeCompare(b.serviceLine)
              )}
              getOptionLabel={(option) => option.projectName}
              isOptionEqualToValue={(option, value) =>
                option.projectIdent === value.projectIdent
              }
              groupBy={(option) => option.serviceLine}
              onChange={(e, value) => {
                if (!!value && value.projectIdent) {
                  dispatch(fetchProjectData(value?.projectIdent));
                  setprojectToCopy(value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={getLabel('_project-library')}
                  size={'small'}
                  className="grid-fields"
                />
              )}
              renderGroup={(params) => (
                <li>
                  <div
                    style={{
                      position: 'sticky',
                      padding: '4px 10px',
                      top: '-8px',
                      borderRadius: 5,
                      backgroundColor: '#d08f8e',
                      color: '#ffffff',
                    }}
                  >
                    <Typography variant="caption">{params.group}</Typography>
                  </div>
                  <div>
                    <Typography variant="body1">{params.children}</Typography>
                  </div>
                </li>
              )}
            />
          </Grid>
        </Box>
      )}
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={intialFormData}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSave(values)}
          innerRef={props.formikRef}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleChange,
            isSubmitting,
          }) => (
            <>
              <Form>
                <CreateProjectForm
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  readOnly={false} // Ensure readOnly is passed
                />
                <Box>
                  <Typography variant="body1" className="toolbar-heading">
                    <Checkbox
                      fontSize="small"
                      onChange={(e) => {
                        setFieldValue('isUsersAdded', e.target.checked);
                      }}
                    />
                    <IntlMessages id={'add_team_member'} />
                  </Typography>
                  <Collapse
                    in={values.isUsersAdded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <AddUsersAndRole
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      users={props.users.filter(
                        (e) => e.emailAddress !== 'it.support@taeurope.com'
                      )}
                    />
                  </Collapse>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

CreateProjectContainer.propTypes = {
  users: PropTypes.array,
  serviceLine: PropTypes.object,
  projectList: PropTypes.array,
  formikRef: PropTypes.object,
};

export default CreateProjectContainer;
