import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  error: {
    type: "",
    messages: [],
    data: {},
  },
  temp: {
    isTemp:false,
    assetName:"",
    assetIdent: "",
    reportVersionIdent: "",
    reportVersionObject: {},
  },
  isCompareMode: false,
  data:[],
};

const projectmonitoringModuleSlice = createSlice({
  name: "projectmonitoringModule",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getTemp: (state, { payload }) => ({
      ...state,
      temp: {
        isTemp: payload.isTemp,
        assetName: payload.assetName,
        assetIdent: payload.assetIdent,
        reportVersionIdent: payload.reportVersionIdent,
        reportVersionObject: payload.reportVersionObject,
      },
    }),
    updateDataList: (state, { payload }) => {
      return {
        ...state,
      data:payload
    }
    },
    resetPromptState: (state) => ({
      ...initialState,
      temp: state.temp,
      isCompareMode: state.isCompareMode,
      data:[],
    }),
    resetState: (state) => ({
      ...initialState,
      temp: state.temp,
      isCompareMode: state.isCompareMode,
    }),
    setCompareMode: (state, { payload }) => ({ ...state, hasErrors: false, isCompareMode:payload}),
    setCompleteReset: (state, { payload }) => ({  ...initialState}),
  },
 
});

export const { resetState,setCompleteReset, getFailure,getTemp,updateDataList,resetPromptState,setCompareMode} = projectmonitoringModuleSlice.actions;

// A selector
export const projectmonitoringModuleSelector = (state) =>
  state.projectmonitoringModule;

// The reducer
export default projectmonitoringModuleSlice.reducer;
