import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RestoreIcon from "@mui/icons-material/Restore";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import MenuList from "@mui/material/MenuList";
import { getIntlTranslation, getLabel } from "../../../../../utils/helper";
import { Box } from "@mui/material";
const ITEM_HEIGHT = 48;

function RowOptionsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="d-flex w-100 h-100">
      <IconButton
        aria-label="more"
        id="menu-button-inspection"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon style={{ color: "#3f51b5", fontSize: 20 }} />
      </IconButton>
      <Menu
        id="grid-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "15ch",
            maxWidth: "20ch",
          },
        }}
      >
        <MenuList className="d-flex flex-column p-1">
          {!props.grid.row.deleted && (
            <MenuItem
              key={`${Math.random() * 10}-edit`}
              onClick={() => {
                props.onEditRow(props.grid);
                handleClose();
              }}
              className="p-1 grid-option"
            >
              <>
              <EditIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
              {getLabel("_edit")}
            </Typography>
          </>
        </MenuItem>
          )}
          <MenuItem
            key={`${Math.random() * 10}-add`}
            onClick={(e) => {
              props.gridRef.current.insertRow(props.grid)
              handleClose();
            }}
            className="p-1 grid-option"
          >
            <>
              <AddCircleIcon
               className="grid-option-icon m-1"/>
               <Typography className="grid-option-label">
              {getLabel("add")}
            </Typography>
            </>
          </MenuItem>
          <MenuItem
            key={`${Math.random() * 10}-copy`}
            onClick={(e) => {
              props.gridRef.current.duplicateRow(props.grid)
              handleClose();
            }}
            className="p-1 grid-option"
          >
            <>
            <FileCopyIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
              {getLabel("_Copy")}
            </Typography>
            </>
          </MenuItem>
          {props.grid.row.custom && !props.grid.row.deleted && (
            <MenuItem
              key={`${Math.random() * 10}-remove`}
              onClick={(e) => {
                props.gridRef.current.deleteRow(props.grid)
                handleClose();
            }}
            className="p-1 grid-option"
            >
               <>
              <RemoveCircleIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
                {getLabel("_delete")}
              </Typography>
            </>
            </MenuItem>
          )}
          {props.grid.row.deleted && (
            <MenuItem
              key={`${Math.random() * 10}-remove`}
              onClick={(e) => {
                props.gridRef.current.restoreRow(props.grid)
                handleClose();
            }}
            className="p-1 grid-option"
            >
              <>
              <RestoreIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
                {getLabel("_Restore")}
              </Typography>
            </>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
}

RowOptionsMenu.propTypes = {
  grid: PropTypes.object,
  gridRef: PropTypes.object,
  onEditRow: PropTypes.func,
};

export default RowOptionsMenu;
