import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getDialogBoxHeader } from "../../../../../utils/helper";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { getBuildingType } from "../../../../../api/suggestionApi";
import { updateAsset } from "../../../../../api/assetOverviewApi";
import { resetState } from "../../../../component-bundle/asset-overview/assetOverviewSlice";
import { useSnackbar } from "notistack";
import AssetCreateForm from "./AssetCreateForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const AssetEditContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.assetOverview);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const suggestionState = useSelector((state) => state.suggestions);
  const [buildingType, setBuildingType] = useState([]);
  const [intialFormData, setIntialFormData] = useState({
    name: "",
    addressLabel: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    subAssetCount: 1,
    subAssets: [
      {
        id: Date.now(),
        name: "",
        typeOfUse: { title: "", value: null },
        addressLabel: "",
        isGeneral: false,
        order: 1,
        addressCheckbox: true,
        assetIdent: "",
      },
    ],
    assetIdent: "",
    reportVersion: "",
  });

  useEffect(() => {
    dispatch(getBuildingType());
  }, [dispatch]);

  useEffect(() => {
    if (
      suggestionState.buildingType.type === "FETCHED" &&
      suggestionState.buildingType.data.length > 0
    ) {
      setBuildingType([...suggestionState.buildingType.data]);
    }
  }, [suggestionState.buildingType]);

  useEffect(() => {
    if (!!props.asset) {
      let tempObj = props.asset;
      setIntialFormData({
        name: tempObj.name,
        assetIdent: tempObj.assetIdent,
        address: tempObj.addressDto.streetName,
        city: tempObj.addressDto.city,
        state: tempObj.addressDto.state,
        postalCode: tempObj.addressDto.state,
        country: tempObj.addressDto.country,
        reportVersion: tempObj.reportName,
        subAssetCount: tempObj.subAssets.length,
        subAssets: tempObj.subAssets.map((m, index) => {
          let found = buildingType.find(
            (f) =>
              f.value === m.typeOfUse &&
              f.language === scope.projectLanguageCode
          )
          return {
            id: Date.now(),
            name: m.name,
            typeOfUse: !!found ? found : {title: m.typeOfUse, value: m.typeOfUse},
            addressLabel: m.address,
            isGeneral: m.general,
            order: index + 1,
            addressCheckbox: false,
            assetIdent: m.assetIdent,
            subAssetIdent: m.subAssetIdent,
          };
        }),
      });
    }
  }, [
    buildingType,
    props.asset,
    scope.projectLanguageCode,
    state.fetchAsset.data,
    state.fetchAsset.type,
  ]);

  useEffect(() => {
    if (
      suggestionState.buildingType.type === "FETCHED" &&
      suggestionState.buildingType.data.length > 0
    ) {
      setBuildingType([...suggestionState.buildingType.data]);
    }
  }, [suggestionState.buildingType]);

  useEffect(() => {
    if (state.create.type === "UPDATED") {
      state.create.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      props.onUpdate();
      props.onClose();
      dispatch(resetState());
    }
  }, [
    dispatch,
    enqueueSnackbar,
    props,
    state.create.messages,
    state.create.type,
  ]);

  const handleSave = (values) => {
    const address = {
      type: "addressDto",
      streetName: values.address,
      optionalStreetName: "",
      postalCode: values.postalCode,
      city: values.city,
      state: values.state,
      country: values.country,
    };
    const subAssets = values.subAssets.map((s) => {
      return (
        {
            type: "monitoringSubAsset",
            name: s.name,
            typeOfUse: s.typeOfUse.value,
            address: s.addressLabel,
            projectIdent: scope.projectIdent,
            assetIdent: s.assetIdent,
            subAssetIdent: s.subAssetIdent,
            general: s.isGeneral,
          }
      )
    });
    const asset = {
      type: "monitoringAsset",
      name: values.name,
      addressDto: address,
      projectIdent: scope.projectIdent,
      reportName:values.reportVersion,
      assetIdent: values.assetIdent,
      subAssets:subAssets
    };

    dispatch(
      updateAsset({
        data: asset,
        api: scope.api,
      })
    );
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"md"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.onClose();
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("Assets")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_asset_close"
            onClick={(e) => props.onClose()}
            style={{ marginLeft: "auto" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <AssetCreateForm
          intialFormData={intialFormData}
          handleSave={(values) => handleSave(values)}
          formRef={formRef}
          buildingType={buildingType}
          editDialog={true}
        />
      </DialogContent>
      <DialogActions className="justify-content-end">
        <SaveButton
          id="create-asset-button"
          buttonText="_create"
          variant="button"
          style={{ margin: 5 }}
          tooltip={{
            title: "click to save an asset",
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

AssetEditContainer.defaultProps = {
  readOnly: false,
};
AssetEditContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.func,
  asset: PropTypes.object,
};

export default AssetEditContainer;
