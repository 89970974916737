import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import { Backdrop, Card, CardActions, CardContent } from "@mui/material";
import {
  getImageFiles,
  updateDefectImage,
  removeDefectImage,
  updateMultiDefectImage,
} from "../../guru-suite/tdd-module/api/defectImageApi";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  resetSliceState,
  startMultiUpdate,
  stopMultiUpdate,
} from "./imageProcessSlice";

import { useSnackbar } from "notistack";
import { b64toBlob, getIntlTranslation, getLabel } from "../../utils/helper";
import Box from "@mui/material/Box";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomButton from "../buttons/CustomButton";
import NonSortablePhotoAlbum from "../../guru-suite/component-bundle/image-gallery/NonSortablePhotoAlbum";
import { ImageProvider } from "../../guru-suite/component-bundle/image-gallery/ImageContext";
import { onDelete, saveImages } from "./imageProcessing";

const useStyles = styled((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: "100%",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1500,
    color: "#fff",
  },
  large: {
    width: 300,
    height: 200,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
}));

const DefectImages = (props) => {
  const { node } = props;
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const imageState = useSelector((state) => state.imageProcess);
  const [loader, setLoader] = useState(false);
  const [isMultiUpdate, setIsMultiUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [currentSubAssetIdent, setCurrentSubAssetIdent] = useState(null);
  const openAsset = useSelector((state) => state.openAsset);

  useEffect(() => {
    if(!!node && !!node.ident){
      setCurrentSubAssetIdent(node.ident);
      setFiles([]);
      dispatch(
        getImageFiles({
          ident: node.ident,
          projectIdent: scope.projectIdent,
        })
      );
    }
  }, [node,dispatch,scope.projectIdent]);

  useEffect(() => {
    if (imageState.type === "SUCCESS") {
      setLoader(true);
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetSliceState());
    } else if (imageState.type === "UPDATED" && !imageState.isMultiUpdate) {
      setLoader(true);
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(
        getImageFiles({
          ident: currentSubAssetIdent,
          projectIdent: scope.projectIdent,
        })
      );
      dispatch(resetSliceState());
      setLoader(false);
    } else if (imageState.type === "UPDATED" && imageState.isMultiUpdate) {
      setLoader(true);
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetSliceState());
    } else if (imageState.type === "DELETED") {
      setLoader(true);
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
      dispatch(
        getImageFiles({
          ident: currentSubAssetIdent,
          projectIdent: scope.projectIdent,
        })
      );
      dispatch(resetSliceState());
      setLoader(false);
    }
    setLoader(false);
  }, [
    currentSubAssetIdent,
    dispatch,
    enqueueSnackbar,
    imageState.type,
    isMultiUpdate,
    scope.projectIdent,
  ]);

  useEffect(() => {
    if (!!imageState.data && imageState.data.length > 0) {
      setLoader(true);
      let dataWithCapexId = imageState.data.filter((data) => !!data.capexID);
      let dataWithoutCapexId = imageState.data
        .filter((data) => data.capexID.length === 0)
        .sort((a, b) => {
          return a.order - b.order;
        });
      let tempFile = [];
      let sortedArray = [...dataWithCapexId, ...dataWithoutCapexId];
      sortedArray.forEach((image) => {
        tempFile.push({
          preview: b64toBlob(image.file),
          id: image.imageIdent,
          status: "saved",
          caption: image.caption,
          capexID: image.capexID,
          order: image.order,
          fileName: image.fileName,
          parentIdent: image.parentIdent,
          primary: image.isPrimary,
          assetIdent: openAsset.ident,
          src: b64toBlob(image.file),
          key: image.imageIdent,
          width: 300,
          height: 200,
          markerState: !!image.markerState ? JSON.parse(image.markerState) : {},
          editedFile: !!image.editedFile ? b64toBlob(image.editedFile) : {},
          isEdited: image.edited,
          originalFile: {},
        });
      });
      setFiles([...tempFile]);
      dispatch(resetSliceState());
      setLoader(false);
    }
  }, [dispatch, imageState]);

  const onImageSave = (file) => {
    setLoader(true);
    const index = files.findIndex(
      (f) => f.key === file.key && f.fileName === file.fileName
    );
    let tempFile = { ...files[index] };
    saveImages(
      tempFile,
      { ident: tempFile.parentIdent, type: "defect" },
      dispatch,
      scope.projectIdent
    );
    setLoader(false);
  };
  const onRemove = (file) => {
    const index = files.findIndex(
      (f) => f.key === file.key && f.fileName === file.fileName
    );
    let tempFile = { ...files[index] };
    onDelete(
      tempFile,
      { ident: tempFile.parentIdent, type: "defect" },
      dispatch,
      scope.projectIdent
    );
  };

  const onCaptionChange = (key, value) => {
    let tempFile = [];
    files.forEach((element) => {
      if (key === element.key) {
        element.caption = value;
      }
      tempFile.push(element);
    });
    setFiles(tempFile);
  };

  const handleSubmit = () => {
    setLoader(true);
    dispatch(startMultiUpdate());
    files.forEach((file) => {
      saveImages(
        file,
        { ident: file.parentIdent, type: "defect" },
        dispatch,
        scope.projectIdent
      );
    });
    
    dispatch(stopMultiUpdate());
    dispatch(
      getImageFiles({
        ident: currentSubAssetIdent,
        projectIdent: scope.projectIdent,
      })
    );
  };

  // const handleSubmit = () => {
  //   files.forEach((file, index) => {
  //     let data = new FormData();
  //     data.append("type", "imageMetaDto");
  //     data.append("imageIdent", file.id);
  //     data.append("parentIdent", file.parentIdent);
  //     data.append("isPrimary", file.primary);
  //     data.append("deleted", false);
  //     data.append("caption", file.caption || " ");
  //     data.append("fileName", file.fileName);
  //     data.append("order", file.order);
  //     data.append("assetIdent", openAsset.ident);

  //     dispatch(
  //       updateMultiDefectImage({
  //         formData: data,
  //         projectIdent: scope.projectIdent,
  //       })
  //     );
  //   });
  //   setIsUpdateDone(true);
  //   setLoader(true);
  // };
  return (
    <>
    <Card className="w-100 h-100 p-1">
      <CardContent className="w-100 p-1 custom-scrollBar" style={{ height: "calc(100% - 50px)",  overflowY: "auto",
              overflowX: "hidden"}}>
      {!!files && files.length > 0 ? (
            <ImageProvider
              onRemove={(file) => onRemove(file)}
              onImageSave={(file) => onImageSave(file)}
              onCaptionChange={(key, value) => onCaptionChange(key, value)}
              onImageEdit={(photo, editedImage, markerState) => {
                let temp = files.map((file) => {
                  if (
                    file.key === photo.key &&
                    photo.fileName === file.fileName
                  ) {
                    if (!!markerState) {
                      file.markerState = markerState;
                      file.status =
                        file.status === "saved" ? "edited" : file.status;
                      file.isEdited = true;
                      file.editedFile = editedImage;
                      file.originalFile = { ...file, editedFile: null };
                    }
                  }
                  return file;
                });
                setFiles(temp);
              }}
              isDragable={false}
              onDragEnd={(photos) => setFiles(photos)}
            >
              <NonSortablePhotoAlbum photoSet={files} />
            </ImageProvider>
        ) : (
          // </section>
          <List>
            <ListItem key="no-images">
              <ListItemIcon>
                <HighlightOffIcon />
              </ListItemIcon>
              <ListItemText primary={getIntlTranslation("_No-Defect-Images")} />
            </ListItem>
          </List>
        )}
      </CardContent>

      <CardActions>
      {files.length > 0 && (
        <Box
          style={{
            width: "100%",
            overflowY: "hidden",
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 5,
          }}
        >
          <CustomButton
            id="save-all-images"
            tooltip={{
              title: getIntlTranslation("tooltip.Save-All"),
            }}
            buttonText={"_Save-All"}
            handleClick={handleSubmit}
            style={{
              backgroundColor: "#d9534f",
              color: "#ffffff",
              width: "98%",
            }}
            variant="contained"
            disabled={!files.length}
          />
        </Box>
      )}
      </CardActions>  
      </Card>   
      {loader && (
        <Backdrop className={useStyles.backdrop} open={loader}>
          <div className="preloader">
            <div></div>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default DefectImages;
