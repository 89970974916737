import Box from "@mui/material/Box";
import PhotoDocumentationWrapper from "./structure/PhotoDocumentationWrapper";
import React, { useEffect } from "react";
import { setCompleteReset } from "./photoDocumentationSlice";
import { resetPromptState } from "../../projectmonitoringModuleSlice";
import { useDispatch } from "react-redux";

const PhotoDocumentationHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCompleteReset());
    dispatch(resetPromptState());
  }, [dispatch]);

  return (
    <Box
      style={{ height: "100%", overflow: "hidden", backgroundColor: "#ebedef" }}
    >
      <PhotoDocumentationWrapper />
    </Box>
  );
};
export default PhotoDocumentationHome;
