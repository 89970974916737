import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { getIntlTranslation } from "../../../../../utils/helper";
import RenderSubAssetsCard from "./RenderSubAssetsCard";

const RenderCompareItemProgress = ({data, selectedProperty, onCopy}) => {
  const [node, setNode] = useState(data);

  useEffect(() => {
    if (!!selectedProperty && !!data) {
      setNode(data);
    }
  }, [selectedProperty, data]);

  return (
    <>
      <ListItem
        className="w-100 p-0 d-flex flex-column my-1"
        key={`key-${node.ident}`}
      >
        <Box className="w-100 d-flex justify-content-between my-1">
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
            }}
            className="w-75 mx-1"
          >
            {node?.subAsset?.name}
          </Typography>
          <CustomButton
            id="button-copy"
            buttonText={"_copy"}
            className={"g-btn-primary g-btn-elevated"}
            style={{
              marginLeft: "auto",
              height: 30,
            }}
            variant="button"
            tooltip={{
              title: getIntlTranslation("tooltip.copy"),
            }}
            handleClick={(e) => onCopy(node.subAsset)}
          />
        </Box>
        <Divider variant="middle" className="g-divider-vertical w-100 m-1" />
        <Card className="w-100 rounded-3">
          <CardContent className="w-100 p-1">
            <RenderSubAssetsCard node={node} key={"compare-mode"}/>
          </CardContent>
        </Card>
      </ListItem>
      <Divider
        variant="middle"
        className="g-divider-vertical"
        style={{ margin: "10px 10px", width: "99%" }}
      />
    </>
  );
};

RenderCompareItemProgress.propTypes = {};

export default RenderCompareItemProgress;
