import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  AlertTitle,
  Alert,
} from "@mui/material";
import CustomButton from "../buttons/CustomButton";
import PropTypes from "prop-types";
import {getIntlTranslation, getVariantText} from "../../utils/helper"
import { useSelector } from "react-redux";

function InternalPrompt(props) {
  const readOnlystate = useSelector((state) => state.readOnly);

  const { when, onLeave, onStay,message, leaveText, stayText,warningHeader } =
    props;

  return when && !readOnlystate.isReadOnly ? (
    <>
    <Dialog
      open={when}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onStay();
        }
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
       <DialogTitle>
        <Alert severity="warning" className={"d-flex align-items-center justify-items-center"}>
            <AlertTitle style={{ fontWeight: "bold" }}>
              {warningHeader}
            </AlertTitle>
            {getIntlTranslation("_action prompt","caption")}
            </Alert>
        </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
            {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          id="cancel_prompt_01"
          buttonText={stayText}
          variant="text"
          tooltip={{
            title: "close dialog",
          }}
          style={{ margin: 5 }}
          handleClick={(e) => onStay()}
        />
        <CustomButton
          id="leave_prompt_01"
          buttonText={leaveText}
          style={{ color: "#ffffff", backgroundColor: "#254a9a", margin: 5 }}
          variant="button"
          tooltip={{
            title: getIntlTranslation("tooltip.leaving-warn"),
          }}
          handleClick={(e) => onLeave()}
        />
      </DialogActions>
    </Dialog>
    </>
  ) : null;
}

InternalPrompt.propTypes = {};

export default InternalPrompt;
