import React from 'react';
import ProjectDocumentationHome from "./ProjectDocumentationHome"
import { pmRoot} from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const ProjectDocumentationRouteConfig = {
  routes: [
		{
    path: `${pmRoot}/project/project-documentation`,
    exact: true,
    name: <IntlMessages id={"menu.project-documentation"}/>,
    element: <ProjectDocumentationHome/>,
    auth: [],
  },
	]
};
export default ProjectDocumentationRouteConfig;