import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../api/customAxios";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
const interval = 1000;

function useReorder(props) {
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  const [reorderResponse, setResponse] = useState({
    type: "",
    data: null,
  });

  const onReorder = useCallback(async (request) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "put",
          url: `${scope.api}/${request.reorderApi}/re-order/${scope.projectIdent}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
          data: request.data,
        })
      );
      if (response.data.type === "SAVED") {
        setResponse({ type: "SAVED", data: request.data, reorderOf:request.reorderOf || "" });
      } else {
        console.log("Error: ", response.data);
        setResponse({ type: "ERROR", data: response.data });
      }
    } catch (error) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  },[dispatch, scope.api, scope.projectIdent]);

  const onResetResponse = () => {
    setResponse({
      type: "",
      data: null,
    });
  };
  return [reorderResponse, onReorder, onResetResponse];
}

export default useReorder;
