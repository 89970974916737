import React, { useEffect, useState } from 'react'
import { Box} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


const DateInput = ({id,disabled=false,node,disableFuture,handleChange}) => {
    const [value, setValue] = useState( node.value === null ? null : node.value);
    
    useEffect(() => {
      if(node.value !== null){
        setValue(node.value);
      }else{
        setValue(null);
      }
    }, [node.value])
    
  return (
    <Box className="d-flex w-100">
     <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id={`date-${id}`}
                key={`date-${id}`}
                value={!!value ? dayjs(new Date(value)) : null}
                style={{ width: "100%" }}
                disabled={disabled}
                format="DD/MM/YYYY"
                autoOk
                disableFuture={disableFuture}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => {
                  if (!!date) {
                    setValue(date);
                    handleChange(date, node, true);
                  } else {
                    setValue(null);
                    handleChange(null, node, true);
                  }
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    style: { width: "100%" },
                    helperText: null,
                    error: null,
                    minRows: 2,
                    maxRows: 5,
                    multiline: true,
                  },
                  inputAdornment: {
                    position: "start",
                    className: "align-items-end pt-4",
                  },
                }}
              />
            </LocalizationProvider>
  </Box>
  )
}

export default DateInput
  export const YearDateInput = ({id,disabled=false,node,handleChange}) => {
    const [value, setValue] = useState( node.value === null ? null : node.value);

    useEffect(() => {
      if(node.value !== null){
        setValue(node.value);
      }else{
        setValue(null);
      }
    }, [node.value])
    return (
      <Box className="d-flex w-100">
       <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
               id={`date-year-${id}`}
               key={`date-year-${id}`}
                value={!!value ? dayjs(new Date(value, 1, 1)) : null}
                style={{ width: "100%" }}
                disabled={disabled}
                autoOk
                autoFocus
                minRows={2}
                maxRows={5}
                views={["year"]}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => {
                  if (!!date) {
                    setValue(date.year());
                    handleChange(date.year(), node, true);
                  } else {
                    setValue(null);
                    handleChange(null, node, true);
                  }
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    helperText: null,
                    error: null,
                    minRows: 2,
                    maxRows: 5,
                    multiline: true,
                    className: "w-100 p-0",
                  },
                  inputAdornment: {
                    position: "start",
                    className: "align-items-end pt-4",
                  },
                }}
              />
            </LocalizationProvider>
    </Box>
    )
  }
