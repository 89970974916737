export const createPropertyStructure = (properties, subAssets) => {
    let structureArry = [];
    properties
      .filter((f) => f.required && !!f.nodeName)
      .forEach((el, index) => {
        subAssets.forEach((v, i) => {
          let found = el.nodeValue?.find(
            (s) => s.subAsset.subAssetIdent === v.subAssetIdent
          );
          let propObj = {
            subAsset: v,
            subAssetIdent: v.subAssetIdent,
            nodeName: el.nodeName,
            custom: el.custom,
            nodeValue: "",
            disabled: el.disabled,
            hidden: el.hidden,
            required: el.required,
            updated: false,
            nodeIdent: el.nodeIdent,
            labelTranslation: el.labelTranslation,
            order: i,
            nodeType: el.nodeType,
            nodeQuelle: "",
          };
          if (!!found) {
            propObj = {
              ...propObj,
              nodeValue: found.value,
              nodeQuelle: found.quelle,
            };
          }
          structureArry.push({ ...propObj, original: propObj });
        });
      });
    
    return structureArry;
  };

  export const normalizeProperties = (properties, subAssetList, language) => {
    const data = {
      nodes: {}
    };
  
    [...properties]
      .sort((a, b) => a.nodeOrder - b.nodeOrder)
      .filter((f) => f.required && !!f.nodeName)
      .forEach((node) => {
        const nodeId = node.nodeIdent;
        const labelTranslation = node.labelTranslation.reduce((acc, item) => {
          acc[item.language] = { value: item.value, title: item.title };
          return acc;
        }, {});
  
        let tempSubAssets = {};
  
        node.nodeValue.forEach((sub) => {
          const subAsset = sub.subAsset;
          const subAssetId = subAsset.subAssetIdent;
          tempSubAssets[subAssetId] = {
            type: subAsset.type,
            name: subAsset.name,
            subAssetIdent: subAsset.subAssetIdent,
            assetIdent: subAsset.assetIdent,
            typeOfUse: subAsset.typeOfUse,
            address: subAsset.address,
            general: subAsset.general,
            lastUpdatedUserId: subAsset.lastUpdatedUserId,
            lastUpdatedByUser: subAsset.lastUpdatedByUser,
            lastUpdated: subAsset.lastUpdated,
            original: sub,
            node: {
              value: sub.value,
              quelle: sub.quelle,
            },
          };
        });
  
        subAssetList.forEach((subAsset) => {
          const subAssetId = subAsset.subAssetIdent;
          if (!tempSubAssets[subAssetId]) {
            tempSubAssets[subAssetId] = {
              type: "monitoringSubAsset",
              projectIdent: subAsset.projectIdent,
              name: subAsset.name,
              subAssetIdent: subAsset.subAssetIdent,
              assetIdent: subAsset.assetIdent,
              typeOfUse: subAsset.typeOfUse,
              address: subAsset.address,
              general: subAsset.general,
              lastUpdatedUserId: subAsset.lastUpdatedUserId,
              lastUpdatedByUser: subAsset.lastUpdatedByUser,
              lastUpdated: subAsset.lastUpdated,
              original: {
                quelle: "",
                value: "",
                type: "nodeValues",
                subAsset: subAsset,
              },
              node: {
                value: "",
                quelle: "",
              },
            };
          }
        });
  
        data.nodes[nodeId] = {
          nodeName: node.nodeName,
          nodeIdent: node.nodeIdent,
          nodeType: node.nodeType,
          nodeOrder: node.nodeOrder,
          nodeQuelle: node.nodeQuelle,
          subAssetIdent: node.nodeValue.map(
            (value) => value.subAsset.subAssetIdent
          ),
          currentVersion: node.currentVersion,
          nodeTitle: labelTranslation[language].title,
          labelTranslation: labelTranslation,
          hidden: node.hidden,
          required: node.required,
          disabled: node.disabled,
          custom: node.custom,
          original: node,
          subAssets: { ...tempSubAssets },
        };
      });
    return data;
  };