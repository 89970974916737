
import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../error/errorHandlingSlice";
import {
  getBuildingType as buildingType,
  getFailure,  
  getSubCategoryFetchListSuccess,
  getCategoryFetchListSuccess,
  getSubAssetTypeListSuccess,
  getElementTypeListSuccess,
  getReportsPropertyLabel,
} from "../guru-suite/component-bundle/suggestion-values/suggestionSlice";
import { renewAccessTokenIfGoingExpire } from "./authApi";


export const getBuildingType = () => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios.get(`/suggestions/fetch-building-type`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "error") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(buildingType(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};

  export const fetchSubAssetTypeList = (projectIdent) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "get",
          url: `/subassets/fetch-subasset-type/${projectIdent}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
        })
      );
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getSubAssetTypeListSuccess(response.data));
      }
    } catch (error) {
      let payload = {};
        payload = {
          type: "ERROR",
          messages:"OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
       error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
    }
  };