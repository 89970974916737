import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  rowData: {
    type: null,
    messages: [],
    data: null,
  },
  grid: {
    type: null,
    messages: [],
    data: null,
  },
  headers: {
    type: null,
    messages: [],
    data: [],
  },
  selectionHeaders: {
    type: null,
    messages: [],
    data: null,
  },
  tab: {},
  updatedRows: [],
  optionalHeaders: [],
  filters: {
    trade: {
      label: "Trade",
      key: "trade",
      value: "",
    },
    applicable: {
      selected: [],
    },
    documentStatus: {
      selected: [],
    },
    status: {
      selected: [],
    },
  },
  fullMode: false,
};

const statutoryComplianceSlice = createSlice({
  name: "statutoryCompliance",
  initialState,
  reducers: {
    getHeaderSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      headers: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),

    getDataRowSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      rowData: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getDataHeaderSave: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      selectionHeaders: {
        type: payload.type,
        messages: payload.messages,
        data: JSON.parse(payload.guruDto.inspectionHeader),
      },
    }),
    getGridListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      grid: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      data: payload,
      grid: [],
    }),
    setTab: (state, { payload }) => ({
      ...state,
      tab: payload,
    }),
    setOptionalHeaders: (state, { payload }) => ({
      ...state,
      optionalHeaders: payload,
    }),
    setUpdateRows: (state, { payload }) => ({
      ...state,
      updatedRows: payload,
    }),
    setSelectionHeaders: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      selectionHeaders: {
        type: payload.type,
        messages: payload.messages,
        data: JSON.parse(payload.guruDto.inspectionHeader),
      },
    }),
    setFilters: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
    setFullMode: (state, { payload }) => ({
      ...state,
      fullMode: payload,
    }),
    resetState: (state) => ({
      ...initialState,
      tab: state.tab,
      fullMode: state.fullMode,
      optionalHeaders: state.optionalHeaders,
      selectionHeaders: {
        type: null,
        messages: [],
        data: state.selectionHeaders.data,
      },
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getHeaderSaveSuccess,
  getDataRowSaveSuccess,
  getDataHeaderSave,
  getGridListSuccess,
  setTab,
  setOptionalHeaders,
  setSelectionHeaders,
  getFailure,
  resetState,
  setFilters,
  setUpdateRows,
  setFullMode,
} = statutoryComplianceSlice.actions;

// A selector
export const statutoryComplianceSelector = (state) => state.statutoryCompliance;

// The reducer
export default statutoryComplianceSlice.reducer;
