import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import AssetNameAndBuildingCount from "./AssetNameAndBuildingCount";
import AddressForm from "./AddressForm";
import {
  getLabel,
  getOverlineText,
  getIntlTranslation,
} from "../../../../../../utils/helper";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import CustomIconButton from "../../../../../../components/buttons/icons-buttons/CustomIconButton";
import DeleteForever from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import IntlMessages from "../../../../../../utils/IntlMessages";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const validationSchema = yup.object().shape({
  assetName: yup.string().required(<IntlMessages id="required_field" />),
  regionalFactor: yup.number().required(<IntlMessages id="required_field" />),
  address: yup.string().required(<IntlMessages id="required_field" />),
  city: yup.string().required(<IntlMessages id="required_field" />),
  state: yup.string().required(<IntlMessages id="required_field" />),
  postalCode: yup.string().required(<IntlMessages id="required_field" />),
  country: yup.string().required(<IntlMessages id="required_field" />),
  subAssets: yup.array().of(
    yup.object().shape({
      name: yup.string().required(<IntlMessages id="required_field" />),
      constructionYear: yup.string()
      .required(<IntlMessages id="required_field" />)
      .length(4, <IntlMessages id="required_field" />)
      .nullable(),
      typeOfUse: yup.object().shape({
        title: yup.string()
          .min(1, <IntlMessages id="required_field" />)
          .required(<IntlMessages id="required_field" />),
        value: yup.string()
          .nullable()
          .required(<IntlMessages id="required_field" />)
          .required(<IntlMessages id="required_field" />),
      }),
    })
  ),
});


const CreateAssetDialog = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [dateError, setDateError] = React.useState(null);

  const createSubAssets = (formProps) => {
    let rows = [];
    formProps.form.values.subAssets.forEach((m, i) => {
      rows.push(
        <>
          <Box
            className="d-flex flex-row align-items-center justify-content-start w-100"
            style={{ marginLeft: 15, marginTop: 10 }}
          >
            <div style={{ width: "20%" }}>
              {getOverlineText("_building")} {i + 1}
            </div>
            <div className="d-flex flex-column" style={{ width: "80%" }}>
              <div className="d-flex flex-row align-items-center justify-content-start w-100">
                <div style={{ marginTop: 5, width: "50%" }}>
                  <Field
                    name={`subAssets[${i}].name`}
                    id={`subasset_name_${i + 1}`}
                    disabled={props.readOnly}
                    label={getLabel("Name")}
                    as={TextField}
                    multiline
                    size="small"
                    dense="true"
                    className="grid-fields"
                    error={
                      getIn(formProps.form.touched, `subAssets[${i}].name`) &&
                      getIn(formProps.form.errors, `subAssets[${i}].name`)
                    }
                    helperText={
                      getIn(formProps.form.touched, `subAssets[${i}].name`) &&
                      getIn(formProps.form.errors, `subAssets[${i}].name`)
                    }
                  />
                </div>
                <div style={{ marginTop: 5, width: "50%" }}>
                  <Autocomplete
                    freeSolo
                    id={`subAssets[${i}].typeOfUse`}
                    size="small"
                    value={
                      !!formProps.form.values.subAssets[i].typeOfUse
                        ? formProps.form.values.subAssets[i].typeOfUse
                        : ""
                    }
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    disableClearable
                    autoComplete={true}
                    blurOnSelect={true}
                    options={props.buildingType?.filter(
                      (f) => f.language === scope.projectLanguageCode
                    )}
                    getOptionSelected={(option, value) =>
                      value.value === option.value
                    }
                    inputValue={formProps.form.values.subAssets[i].typeOfUse.title}
                    onInputChange={(event, newInputValue) => {
                      if (typeof newInputValue === "string") {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          {
                            title: newInputValue,
                            value: newInputValue,
                          }
                        );
                      }
                    }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          {
                            title: newValue,
                            value: newValue,
                          }
                        );
                      } else if (newValue !== null) {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          newValue
                        );
                      } else {
                        formProps.form.setFieldValue(
                          `subAssets[${i}].typeOfUse`,
                          {
                            title: "",
                            value: null,
                          }
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label={getLabel("_building type")}
                          name={`subAssets[${i}].typeOfUse`}
                          className="grid-fields"
                          size="small"
                          dense="true"
                          error={
                            getIn(
                              formProps.form.touched,
                              `subAssets[${i}].typeOfUse.value`
                            ) &&
                            getIn(
                              formProps.form.errors,
                              `subAssets[${i}].typeOfUse.value`
                            )
                          }
                          helperText={
                            getIn(
                              formProps.form.touched,
                              `subAssets[${i}].typeOfUse.value`
                            ) &&
                            getIn(
                              formProps.form.errors,
                              `subAssets[${i}].typeOfUse.value`
                            )
                          }
                        />
                      </>
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.title, inputValue);
                      const parts = parse(option.title, matches);

                      return (
                        <>
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    color: part.highlight ? "red" : "black",
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        </>
                      );
                    }}
                  />
                </div>
                <Grid item md={2} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={dayjs(new Date(1800,1,1))}
                      name={`subAssets[${i}].constructionYear`}
                      views={["year"]}
                      variant="inline"
                      id={`subAssets[${i}].constructionYear`}
                      className="grid-fields"
                      label={getIntlTranslation("_Construction Year")}
                      value={!!formProps.form.values.subAssets[i].constructionYear ? 
                        dayjs(new Date(formProps.form.values.subAssets[i].constructionYear,1,1)) 
                        : null
                      }
                    
                      onChange={(date) => {
                        if(!!date){
                          formProps.form.setFieldValue(
                            `subAssets[${i}].constructionYear`,
                            date.year(),
                          );
                        }else{
                          formProps.form.setFieldValue(
                              `subAssets[${i}].constructionYear`,
                              null,
                            );
                        }
                      }}
                      slotProps={{
                        textField: {
                            variant: "outlined",
                            size: "small",
                            style: {width:"100%"},
                            error: getIn(
                              formProps.form.touched,
                              `subAssets[${i}].constructionYear`
                            ) &&
                            getIn(
                              formProps.form.errors,
                              `subAssets[${i}].constructionYear`
                            ),
                            helperText:  (getIn(
                              formProps.form.touched,
                              `subAssets[${i}].constructionYear`
                            ) &&
                            getIn(
                              formProps.form.errors,
                              `subAssets[${i}].constructionYear`
                            ))
                        },
                        inputAdornment:{
                          position:"start"
                        },
                    }}
                    error={false}
                    helperText={null}
                    />
                  </LocalizationProvider>
                </Grid>
              </div>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: "10px", backgroundColor: "#254a9a" }}
            />
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "10%",
                height: "100%",
              }}
            >
              <CustomIconButton
                id={`add_row_button_${i}`}
                icon={<AddIcon style={{ color: "#d08f8e", fontSize: 25 }} />}
                permission={null}
                handleClick={(e) => {
                  formProps.push({
                    name: "",
                    typeOfUse: { title: "", value: null },
                    addressCheckbox: true,
                    constructionYear:"",
                    assetIdent:"",
                  });
                }}
                tooltip={{
                  title: getIntlTranslation("tooltip.add-row"),
                }}
                style={{
                  borderRadius: 0,
                }}
                aria-describedby={`add_row_button_${i}`}
              />
              {formProps.form.values.subAssets.length > 1 && (
                <CustomIconButton
                  id={`delete_row_button_${i}`}
                  icon={
                    <DeleteForever style={{ color: "#f50057", fontSize: 25 }} />
                  }
                  permission={null}
                  handleClick={(e) => {
                    formProps.remove(i);
                  }}
                  tooltip={{
                    title: getIntlTranslation("tooltip.delete"),
                  }}
                  style={{
                    borderRadius: 0,
                  }}
                  aria-describedby={`delete_row_button_${i}`}
                />
              )}
            </div>
          </Box>

          {i < formProps.form.values.subAssets.length - 1 && (
            <Divider component="div" style={{ padding: 5, width: "98%" }} />
          )}
        </>
      );
    });
    return rows;
  };

  return (
    <Box
      style={{ overflowY: "auto", marginTop: 5 }}
      className={"custom-scrollBar"}
    >
      <Card>
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={props.intialFormData}
            validationSchema={validationSchema}
            onSubmit={(values) => props.handleSave(values)}
            innerRef={props.formRef}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              isSubmitting,
            }) => (
              <>
                <Form>
                  <AssetNameAndBuildingCount
                    values={values}
                    errors={errors}
                    touched={touched}
                    readOnly={false}
                  />
                  <AddressForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    readOnly={false}
                  />
                  <Grid item md={12} xs={12}>
                    {getOverlineText("_Sub-Asset Information")}
                  </Grid>
                  <FieldArray name="subAssets">
                    {(fieldArrayProps) => {
                      return createSubAssets(fieldArrayProps);
                    }}
                  </FieldArray>
                </Form>
              </>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

CreateAssetDialog.propTypes = {
  intialFormData: PropTypes.object,
  handleSave: PropTypes.func,
  formRef: PropTypes.object,
  buildingType: PropTypes.array,
  editDialog: PropTypes.bool,
};

export default CreateAssetDialog;
