import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import { AppBar, Toolbar, IconButton, Drawer } from "@mui/material";
import EditRowForm from "./EditRowForm";
import { saveRowData } from "../../../api/statutoryComplianceApi";
import SaveButton from "../../../../../components/buttons/SaveButton";
import {
  getIntlTranslation,
  getDialogBoxHeader,
} from "../../../../../utils/helper";
import CloseIcon from "@mui/icons-material//Close";

function EditRowDialog(props) {
  const { row, onClose, headerList, open } = props;
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const [data, setData] = useState({});
  const [categoryHeaders, setCategoryHeaders] = useState([]);
  const [tradeIsMissing, setTradeIsMissing] = useState(false);
  useEffect(() => {
    setData(row);
    const temp = headerList.map((m) => {
      const obj = { ...m };
      const found = m.categoryList.find(
        (o) => o.category.ident === row.category.ident
      );
      return { ...obj, order: found?.headerOrder };
    });
    temp.sort((a, b) => a.order - b.order);
    setCategoryHeaders(temp);
  }, [headerList, row]);

  const handleSave = () => {
    const customData = [];
    const rowData = {
      type: "inspectionData",
      rowId: data.id,
      category: data.category,
      subCategory: data.subCategory,
      tradeGroup: data.tradeGroup,
      tradeIdent: data.tradeIdent,
      tradeOrder: data.tradeOrder,
      deleted: data.deleted,
      custom: data.custom,
      inspectionDataIdent: data.inspectionDataIdent,
    };
    headerList.forEach((h) => {
      if (h.custom === true) {
        customData.push({
          header: h.name,
          headerIdent: h.headerIdent,
          key: h.key,
          value: data[h.key],
          custom: true,
        });
      } else if (h.editor.includes("dropdown")) {
        rowData[h.key] = data[h.key].name;
      } else if (h.editor === "date") {
        if (data[h.key]) {
          rowData[h.key] = new Date(data[h.key]);
        }
      } else {
        rowData[h.key] = data[h.key];
      }
    });

    if (customData.length > 0) {
      rowData.customData = JSON.stringify(customData);
    }
    if (rowData.trade) {
      dispatch(
        saveRowData({
          projectIdent: scope.projectIdent,
          subAssetIdent: openAsset.subAssetIdent,
          rows: [rowData],
        })
      );
      setTradeIsMissing(false);
    } else {
      setTradeIsMissing(true);
    }
  };

  return (
    <Drawer
         anchor={"right"}
         sx={{
           width: "35%",
           zIndex: 1800,
           flexShrink: 0,
           [`& .MuiDrawer-paper`]: {
             width: "35%",
             boxSizing: "border-box",
           },
         }}
         className="h-100"
         open={open}
       >
      <AppBar className={"header rounded-0"}  style={{height:40}}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("_trade-information")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => onClose()}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={"w-100 h-100 custom-scrollBar"} dividers>
        <EditRowForm
          data={data}
          setData={setData}
          headerList={categoryHeaders}
          tradeIsMissing={tradeIsMissing}
        />
      </DialogContent>
      <DialogActions className="w-100">
        <SaveButton
          id="edit_row_save_01"
          buttonText="_save"
          variant="button"
          style={{ marginLeft: 5, height: 30 }}
          className={"w-100"}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={handleSave}
        />
      </DialogActions>
    </Drawer>
  );
}

EditRowDialog.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  headerList: PropTypes.instanceOf(Array).isRequired,
};

export default EditRowDialog;
