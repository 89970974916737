import React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import useAssetListHook from "../../../component-bundle/hooks/useAssetListHook";
import {
  addProperties,
  deleteProperty,
  editProperty,
  fetchProperties,
  fetchPropertiesToCompare,
  saveProperties,
} from "../../api/versionDataApi";
import {
  onSelectReportVersion,
  resetState,
  setCurrentVersion,
  setUpdatedRows,
  setVersions,
} from "../slice/CurrentVersionSlice";
import { useSnackbar } from "notistack";
import { response } from "../../../../utils/defaultValues";
import {
  getCustomDelete,
  getCustomSuccess,
  getCustomUpdate,
  getSaveSuccess,
} from "../slice/VersionDataSlice";
import { resetPromptState } from "../../projectmonitoringModuleSlice";


export const DataAggregatorContext = createContext(undefined);

export const useDataAggregatorContextHook = () => {
  const context = useContext(DataAggregatorContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

export const DataAggregatorProvider = ({ children, moduleApi }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const dataState = useSelector((state) => state.versionData);
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const state = useSelector((state) => state.currentVersion);
  const { assetList } = useAssetListHook();
  const [assets, setAssets] = useState({
    isAssigned: false,
    data: [],
  });
  const [subAssetList, setSubAssetList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    if (!!openAsset.ident){
      setSubAssetList([]);
      dispatch(resetState())
      dispatch(setCurrentVersion({}));
      setAssets({
        isAssigned: false,
        data: [],
      });
    }
  }, [dispatch, openAsset.ident])
  

  useEffect(() => {
    if (!!openAsset.ident && assetList.isFetchSuccess) {
      const list = assetList.assets.map((m) => {
        return {
          ...m,
          address: { ...m.addressDto },
          reportVersion: [
            ...m.reportVersionDtos.map((rv) => {
              return { ...rv, label: rv.versionName, value: rv.ident };
            }),
          ],
        };
      });

      let found = list.find(
        (f) =>
          f.assetIdent === openAsset.ident &&
          scope?.projectIdent === openAsset?.projectIdent
      );
      let currentversion = found.reportVersion.find((f) => f.current);
      if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
        currentversion = found.reportVersion.find(
          (f) => f.ident === tempVersion.reportVersionIdent
        );
      }
      let temp = [
        ...found?.subAssets.map((m) => m).sort((a, b) => b.general - a.general),
      ];
      setSubAssetList(temp);
      dispatch(setCurrentVersion(currentversion || {}));
      dispatch(onSelectReportVersion(null));
      dispatch(setVersions(found.reportVersion));
      setAssets({
        isAssigned: true,
        data: list,
      });
    }
  }, [assetList, assets.isAssigned, dispatch, openAsset.ident, openAsset?.projectIdent, scope?.projectIdent, tempVersion]);

  const fetchProps = useCallback(() => {
    if(!!openAsset.ident && !!state.currentVersion &&  !!state.currentVersion.ident){
    dispatch(
      fetchProperties({
        api: scope.api,
        projectIdent: scope.projectIdent,
        assetIdent: openAsset.ident,
        version: state.currentVersion.ident,
        moduleApi: moduleApi,
      })
    );
  }
  }, [
    state.currentVersion,
    dispatch,
    moduleApi,
    openAsset.ident,
    scope.api,
    scope.projectIdent,
  ]);

  useEffect(() => {
    if (assets.isAssigned && !!state.currentVersion) {
      fetchProps();
    }
  }, [
    assets.isAssigned,
    state.currentVersion,
    fetchProps,
  ]);

  useEffect(() => {
    if (dataState.custom.type === "SAVED") {
      dataState.custom.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomSuccess({ ...response }));
      fetchProps();
    }
    if (dataState.save.type === "SAVED") {
      dataState.save.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(setUpdatedRows([]));
      dispatch(getSaveSuccess({ ...response }));
      dispatch(resetPromptState());
      fetchProps();
    }
    if (dataState.custom.type === "UPDATED") {
      dataState.custom.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomUpdate({ ...response, guruDtoList: [] }));
      fetchProps();
    }
    if (dataState.customDelete.type === "DELETED") {
      dataState.customDelete.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomDelete({ ...response, guruDto: {} }));
      fetchProps();
    }
  }, [dispatch, enqueueSnackbar, fetchProps, dataState.custom, dataState.customDelete, dataState.save,]);




  const onFetchCompareProperties = useCallback((versionIdent) => {
    dispatch(
      fetchPropertiesToCompare({
        moduleApi: moduleApi,
        api: scope.api,
        projectIdent: scope.projectIdent,
        assetIdent: openAsset.ident,
        version: versionIdent,
      })
    );
  },[dispatch, moduleApi, openAsset.ident, scope.api, scope.projectIdent]);

  const onSave = useCallback(
    (data) => {
      dispatch(
        saveProperties({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          data,
        })
      );
    },
    [dispatch, moduleApi, openAsset.ident, scope.api, scope.projectIdent]
  );

  const onEditProperty = useCallback(
    (data) => {
      dispatch(
        editProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          data,
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  const onDeleteProperty = useCallback(
    (data) => {
      dispatch(
        deleteProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          propertyIdent: data.propertyIdent,
          version: data.version,
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  const onAdditionalProperty = useCallback(
    (data) => {
      dispatch(
        addProperties({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          data,
        })
      );
    },
    [dispatch, moduleApi, openAsset.ident, scope.api, scope.projectIdent]
  );

  return (
    <DataAggregatorContext.Provider
      value={{
        assets,
        subAssetList,
        onAdditionalProperty,
        onDeleteProperty,
        onEditProperty,
        onFetchCompareProperties,
        onSave,
      }}
    >
      {children}
    </DataAggregatorContext.Provider>
  );
};
