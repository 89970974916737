import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useRanger } from "./RangeSlider"


const DisplayPriorityRangeSlider = (props) => {
    const {onChange, data } = props;
    const [values, setValues] = useState([30, 70]);

    useEffect(() => {
       if(data!==undefined && data!==null){
           setValues(data)
       }
    }, [data])

    const handleChanges = (value) =>{
        setValues(value)
       if (typeof onChange === "function") {
        onChange(value);
      }
    }

     const { getTrackProps, ticks, segments, handles } = useRanger({
    min: 0,
    max: 100,
    stepSize: .1,
    values,
    onChange: handleChanges
  });

  return (
    <div>
      <div className="track"
        {...getTrackProps()}
      >
       {ticks.map(({ value, getTickProps }) => (
          <div className="tick" {...getTickProps()}>
            <div className="tickLabel">{value}</div>
          </div>
        ))}
         {segments.map(({ getSegmentProps }, i) => (
          <div {...getSegmentProps()} className={i===0 ? "shortTerm" : i===1 ? "mediumTerm" : "longTerm"} index={i} />
        ))}

        {handles.map(({ value, active, getHandleProps }) => (
          <Button className="handleButton"
            {...getHandleProps()}
          >
          <div className="handleLable">{value}</div>
          </Button>
        ))}
      </div>
    </div>
  );

}

export default DisplayPriorityRangeSlider;