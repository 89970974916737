import { createSlice } from '@reduxjs/toolkit';
import { response } from '../../../utils/defaultValues';

export const initialState = {
  hasErrors: false,
  error: {
    ...response,
  },
  users: {
    ...response,
  },
  createProject: {
    ...response,
  },
};

const createProjectSlice = createSlice({
  name: 'createProject',
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getCreateSuccess: (state, { payload }) => ({
      ...state,
      createProject: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getUserListSuccess: (state, { payload }) => ({
      ...state,
      users: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    resetState: (state) => ({
      ...initialState,
      tab: state.tab,
    }),
  },
});

export const {
  resetState,
  getFailure,
  getUserListSuccess,
  getProjectData,
  getCreateSuccess,
} = createProjectSlice.actions;

// A selector
export const createProjectSelector = (state) => state.createProject;

// The reducer
export default createProjectSlice.reducer;
