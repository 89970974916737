import React from 'react';
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import FullReportHome from './FullReportHome';

const FullReportRouteConfig = {
  routes: [
		{
    path: `${tddRoot}/project/full-report`,
    exact: true,
    name: <IntlMessages id={"menu.fullReport"}/>,
    element: <FullReportHome/>,
    auth: [],
  },
	]
};

export default FullReportRouteConfig;
