import imageCompression from "browser-image-compression";
import {
  saveDefectImage,
  updateDefectImage,
  removeDefectImage,
} from "../../guru-suite/tdd-module/api/defectImageApi";

import {
  saveSubAssetImage,
  updateSubAssetImage,
  removeSubAssetImage,
} from "../../guru-suite/tdd-module/api/subAssetImageApi.js";
import { useDispatch, useSelector } from "react-redux";
import { removeImage, saveImage, updateImage } from "../../guru-suite/tdd-module/api/imageContainerApi.js";

const options = {
  maxSizeMB: 6,
  useWebWorker: true,
};
export const onDelete = (file, node, dispatch, projectIdent) => {
  const data = new FormData();
  data.append("type", "imageMetaDto");
  data.append("imageIdent", file.id);
  data.append("parentIdent", node.ident);
  data.append("deleted", true);
  data.append("fileName", file.fileName);
  data.append("assetIdent", file.assetIdent);
  if(node.type === "report-image"){
    dispatch(
      removeImage({
        formData: data,
        projectIdent: projectIdent,
        api: node.reportType,
      })
    );

  }
  else if (node.type === "defect") {
    dispatch(
      removeDefectImage({
        formData: data,
        projectIdent: projectIdent,
      })
    );
  }

  else if (node.type === "sub-asset") {
    dispatch(
      removeSubAssetImage({
        formData: data,
        projectIdent: projectIdent,
      })
    );
  }
};

export const saveImages = async (file, node, dispatch, projectIdent) => {
  if (file.status === "new" || file.status === "edited") {
    try {
      let originalFile = await getImageFileFromUrl(
        file.src,
        file.fileName
      );
      const compressedFile = await imageCompression(originalFile, options);
      return saveCompressedFile({
        ...file,
        imageFile: compressedFile,
        parentIdent: node.ident,
        primary: file.order === 1 ? true : false,
        dispatch: dispatch,
        projectIdent: projectIdent,
        nodeType: node.type,
        reportType: node.reportType
      });
    } catch (error) {
      console.log(error.message);
    }
  } else {
    saveCompressedFile({
      ...file,
      parentIdent: node.ident,
      primary: file.order === 1 ? true : false,
      dispatch: dispatch,
      projectIdent: projectIdent,
      nodeType: node.type,
      reportType: node.reportType
    });
  }
};

const saveCompressedFile = async (file) => {
  const data = new FormData();
  data.append("type", "imageMetaDto");
  data.append("imageIdent", file.id);
  data.append("parentIdent", file.parentIdent);
  data.append("primary", file?.primary);
  data.append("deleted", false);
  data.append("caption", file.caption || " ");
  data.append("fileName", file.fileName);
  data.append("order", file.order);
  data.append("assetIdent", file.assetIdent);
  data.append("edited", file.isEdited);
  data.append("source", file?.source);

  if (file.status === "new" || file.status === "edited") {
    data.append("multipartFile", file.imageFile);
    if (file.isEdited) {
      let editedFile = await getImageFileFromUrl(
        file.editedFile,
        file.imageFile.name
      );
      const compressedEditedFile = await imageCompression(editedFile, options);
      data.append("editedMultipartFile", compressedEditedFile);
      data.append("markerState", JSON.stringify(file.markerState));
    }

    if(file.nodeType === "report-image"){
      file.dispatch(
        saveImage({
          formData: data,
          projectIdent: file.projectIdent,
          api: file.reportType,
        })
      )
    }
    else if (file.nodeType === "defect") {
      file.dispatch(
        saveDefectImage({
          formData: data,
          projectIdent: file.projectIdent,
          id: file.id,
        })
      );
     
    }
    else if (file.nodeType === "sub-asset") {
      file.dispatch(
        saveSubAssetImage({
          formData: data,
          projectIdent: file.projectIdent,
          id: file.id,
        })
      );
    }
  }

  if (file.status === "saved") {
    if(file.nodeType === "report-image"){
      file.dispatch(
        updateImage({
          formData: data,
          projectIdent: file.projectIdent,
          api: file.reportType,
        })
      )
    }
    else if (file.nodeType === "defect") {
      file.dispatch(
        updateDefectImage({
          formData: data,
          projectIdent: file.projectIdent,
        })
      );
    }
    else if (file.nodeType === "sub-asset") {
      file.dispatch(
        updateSubAssetImage({
          formData: data,
          projectIdent: file.projectIdent,
        })
      );
    }
  }
};

export const getImageFileFromUrl = async (editedFile, name) => {
  let response = await fetch(editedFile);
  const blob = await response.blob();
  const editedBlobfile = new File([blob], name, {
    type: blob.type,
  });
  return editedBlobfile;
};
