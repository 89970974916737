import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  suggestionElements:{
    type: "",
    messages: [],
    data:{}
  },
  error: {},
};
// A slice for auth with our success or failure reducers
const elementSectionSlice = createSlice({
  name: "elementSectionSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    resetSliceState: (state) => ({
      ...initialState
    }),
     getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
    getElementSuggestionsSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      suggestionElements:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      }
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetSliceState,
  getElementSuggestionsSuccess,
  getSuccess,
} = elementSectionSlice.actions;

// A selector
export const elementSectionSelector = (state) => state.tddElementSection;

// The reducer
export default elementSectionSlice.reducer;
