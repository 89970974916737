import React from "react";
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import CustomReportHome from "./CustomReportHome";
const customReportRouteConfig = {
  routes: [
		{
    path: `${tddRoot}/project/custom-report`,
    exact: true,
    name: <IntlMessages id={"menu.customReport"}/>,
    element: <CustomReportHome/>,
    auth: [],
  },
	]
};

export default customReportRouteConfig;
