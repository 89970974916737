import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  Card,
  CardContent,
  CardActions,
  TextField,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getVariantText,
  getTranslationVariantText,
  minimalEditorConfig,
} from "../../../../../utils/helper";
import CustomButton from "../../../../../components/buttons/CustomButton";
import {
  riskTypeOptions,
  statusTypeOptions,
} from "../../../../../common/dropdowns";
import GuruEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import debounce from "lodash.debounce";
import { updateDataList,resetPromptState } from "../../../projectmonitoringModuleSlice";
import { useIntl } from "react-intl";

const splitArray = (array, callback) => {
  const matches = [];
  const nonMatches = [];
  array.forEach((element) =>
    (callback(element) ? matches : nonMatches).push(element)
  );
  return [matches, nonMatches];
};

const ProjectProgressDataEditDialog = ( {subAssetList,editSubAsset,selected,rows,onAdd,onClose}) => {
  const intl = useIntl();
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  const riskOption = useMemo(
    () =>
      riskTypeOptions.filter((f) => f.language === scope.projectLanguageCode),
    [scope.projectLanguageCode]
  );
  const statusOptions = useMemo(
    () =>
      statusTypeOptions.filter((f) => f.language === scope.projectLanguageCode),
    [scope.projectLanguageCode]
  );
  const [selectedSubAsset, setSelectedSubAsset] = useState(null);
  const dataSlice = useSelector((state) => state.projectMonitoring.data);
  const state = useSelector((state) => state.projectProgress);
  const [dataRows, setDataRows] = useState([]);
  const readOnlystate = useSelector((state) => state.readOnly);


  if (state.save.type === "SAVED") {
    onClose();
  }

  useEffect(() => {
    if(!!editSubAsset){
      setSelectedSubAsset(editSubAsset);
    }else{
      setSelectedSubAsset(null)
    }
    if (rows.length > 0) {
      setDataRows([...rows]);
    } else {
      setDataRows([]);
    }
  }, [rows,selected ,editSubAsset, subAssetList, riskOption, statusOptions]);

  const addUpdatedValue = useCallback(
    (val,nodeIdent) => {
      let temp = dataSlice;
      let found = temp?.find((f) => f.nodeIdent === nodeIdent);
      if (!found) {
        let tempObj = {
          nodeIdent: nodeIdent,
          name: selected.name,
          asset: val?.asset?.name,
          subAsset: val?.subAsset?.name,
          updated: true,
        };
        let restArry = temp?.filter((f) => {
          if (f.nodeIdent === nodeIdent) {
            return false;
          } else {
            return true;
          }
        });
        if (!readOnlystate.isReadOnly) {
          dispatch(updateDataList([...restArry, { ...tempObj }]));
        }
      }
    },
    [dataSlice, selected.name, readOnlystate.isReadOnly, dispatch]
  );
  const handleRowChange = useCallback(
    (value, name, data) => {
      const [selectedPropertyArray, rest] = splitArray(
        dataRows,
        (f) => f?.subAssetIdent === data.subAssetIdent
      );

      let found = selectedPropertyArray.find(
        (f) => f?.subAssetIdent === data.subAssetIdent
      );
      if (found) {
        let tempData = { ...found, [name]: value, isUpdated: true };
        setDataRows([...rest, { ...tempData }]);
        addUpdatedValue(data,data.ident);
      }
    },
    [addUpdatedValue, dataRows]
  );

  const debouncedHandleChange = useMemo(
    (value, name, data) => debounce(handleRowChange, 300),
    [handleRowChange]
  );

  const checkIfDataExist = (value, name) => {
    setSelectedSubAsset(value);
    let found = dataRows.find((f) => f?.subAssetIdent === value?.subAssetIdent);
    if (!found) {
      let data = {
        ident: "",
        subAsset: value,
        subAssetIdent: value.subAssetIdent,
        comments: "",
        status: statusOptions[0],
        start: "",
        finish: "",
        risk: { title: "", value: "" },
        id: `${Date.now()}_${value.subAssetIdent}`,
        order: 0,
        isNew: true,
        isUpdated: true,
        isDeleted: false,
      };
      setDataRows((prev) => [...prev, { ...data }]);
    }
  };

  const createStructureForSave = () => {
    let temp = dataRows.map((data) => {
      return {
        type: "mapProjectProgress",
        ident: data.ident,
        comment:data.comment,
        startDate:data.startDate,
        finishDate:data.finishDate,
        status: data.status.value,
        risk: data.risk.value,
        subAssetIdent: data.subAsset.subAssetIdent,
        reportVersionIdent: state.currentVersion.ident,
        new: !!data?.ident ? false : true,
        updated: data.isUpdated,
        deleted: data.isDeleted,
      };
    });
   onAdd({ ...selected, mapProgressDtos: [...temp] });
  };


  const createDataFields = () => {
    let data = dataRows.find(
      (f) => f.subAssetIdent === selectedSubAsset?.subAssetIdent
    );

    return (
      <>
        {!!data && (
          <List className={"p-1 d-flex flex-column"}>
            <ListItem
              className={"d-flex align-items-center justity-content-start p-1"}
            >
              <Autocomplete
                fullWidth
                disableClearable={true}
                value={data?.status || ""}
                options={statusOptions}
                getOptionLabel={(option) => option.title || ""}
                onChange={(event, value) => {
                  if (!!value) {
                    handleRowChange(value, "status", data);
                  }
                }}
                style={{ width: "100%" }}
                dense={"true"}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"outlined"}
                    label={getTranslationVariantText("_status", "body1", {
                      width: "100%",
                      color: "#254a9a",
                      fontWeight: "bold",
                    })}
                  />
                )}
              />
            </ListItem>
            <ListItem
              className={
                "d-flex flex-column align-items-center justity-content-start p-1"
              }
            >
              <Box style={{ width: "100%", margin: 2 }}>
                {getTranslationVariantText("_comments-Ta", "body1", {
                  width: "100%",
                  color: "#254a9a",
                  fontWeight: "bold",
                })}
              </Box>
              <Box
                id={`box_comments`}
                className="d-flex g-text-editor align-items-center justify-content-center"
                style={{ minHeight: "200px", width: "100%" }}
                tabIndex={-1}
              >
                <CKEditor
                  id={`${data.subAssetIdent}_comments`}
                  key={`${data.subAssetIdent}_comments`}
                  editor={GuruEditor.ClassicEditor}
                  config={{ ...minimalEditorConfig,placeholder: intl.formatMessage({ id:"_text-editor-placeholder"}) }}
                  data={data?.comment}
                  onChange={(event, editor) => {
                    const text = editor.getData();
                    debouncedHandleChange(text, "comment", data);
                  }}
                />
              </Box>
            </ListItem>
            <ListItem
              className={
                "d-flex flex-column align-items-center justity-content-start p-1"
              }
            >
              <Box style={{ width: "100%", margin: 2 }}>
                {getTranslationVariantText("_start", "body1", {
                  width: "100%",
                  color: "#254a9a",
                  fontWeight: "bold",
                })}
              </Box>
              <Box style={{ width: "100%", margin: 2 }}>
                <TextField
                  multiline={true}
                  rows={2}
                  style={{ width: "100%" }}
                  key={`${data.subAssetIdent}_key`}
                  defaultValue={data?.startDate}
                  variant={"outlined"}
                  dense={"true"}
                  onChange={(e) => {
                    debouncedHandleChange(e.target.value, "startDate", data);
                  }}
                />
              </Box>
            </ListItem>
            <ListItem
              className={
                "d-flex flex-column align-items-center justity-content-start p-1"
              }
            >
              <Box style={{ width: "100%", margin: 2 }}>
                {getTranslationVariantText("_finish", "body1", {
                  width: "100%",
                  color: "#254a9a",
                  fontWeight: "bold",
                })}
              </Box>
              <Box style={{ width: "100%", margin: 2 }}>
                <TextField
                  multiline={true}
                  rows={2}
                  style={{ width: "100%" }}
                  key={`${data.subAssetIdent}_finish`}
                  defaultValue={data?.finishDate}
                  variant={"outlined"}
                  dense={"true"}
                  onChange={(e) => {
                    debouncedHandleChange(e.target.value, "finishDate", data);
                  }}
                />
              </Box>
            </ListItem>
            <ListItem
              className={"d-flex align-items-center justity-content-start p-1"}
            >
              <Autocomplete
                fullWidth
                disableClearable={true}
                value={data?.risk || ""}
                options={riskOption}
                getOptionLabel={(option) => option.title || ""}
                onChange={(event, value) => {
                  if (!!value) {
                    handleRowChange(value, "risk", data);
                  }
                }}
                style={{ width: "100%" }}
                dense={"true"}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"outlined"}
                    label={getTranslationVariantText("_risk-Type", "body1", {
                      width: "100%",
                      color: "#254a9a",
                      fontWeight: "bold",
                    })}
                  />
                )}
              />
            </ListItem>
          </List>
        )}
      </>
    );
  };
  return (
    <Card className={"h-100 d-flex flex-column"}>
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
        
          <div>
            {getVariantText(selected.title, "body2", {
              textTransform: "uppercase",
            })}
             </div>
             <div style={{ marginLeft: "auto" }}>
              <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => {
              dispatch(resetPromptState([]))
             onClose()
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <CardContent
        dividers="true"
        style={{ height: "calc(100%-50px)", overflowY: "auto" }}
        className="custom-scrollBar"
      >
        {!!selectedSubAsset && (!selectedSubAsset.subAssetIdent) && (
          <Box className={"p-1"}>
            {getTranslationVariantText(
              "_select sub-asset-to-start",
              "caption",
              { textTransform: "uppercase"},
              "w-100 p-1 rounded-1 g-btn-secondary"
            )}
          </Box>
        )}
        <Box className={"p-1"}>
          <Autocomplete
            fullWidth
            disableClearable={true}
            value={selectedSubAsset}
            options={subAssetList}
            getOptionLabel={(option) => option.name || ""}
            onChange={(event, value) => {
              if (!!value) {
                checkIfDataExist(value, "subAsset");
              }
            }}
            style={{ width: "100%" }}
            dense={"true"}
            size={"small"}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                label={getTranslationVariantText("_Subasset", "body1", {
                  width: "100%",
                  color: "#254a9a",
                  fontWeight: "bold",
                })}
              />
            )}
          />
        </Box>
        {createDataFields()}
      </CardContent>
      <CardActions style={{ marginTop: "auto" }}>
      {!!selectedSubAsset && (<CustomButton
          id={`add-options-custom-button`}
          buttonText={"add"}
          variant="button"
          style={{ margin: 5, width: "100%" }}
          tooltip={{
            title: "click to add",
          }}
          handleClick={(e) => createStructureForSave()}
        />)}
      </CardActions>
    </Card>
  );
};
ProjectProgressDataEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.object,
  editSubAsset: PropTypes.object,
  subAssetList: PropTypes.array,
  rows: PropTypes.array,
  onAdd:PropTypes.func,
};

export default ProjectProgressDataEditDialog;
