import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getDialogBoxHeader } from "../../../../../utils/helper";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { getBuildingType } from "../../../../../api/suggestionApi";
import { update } from "../../../api/subAssetApi";
import SubAssetCreateForm from "./SubAssetCreateForm";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const SubAssetEditContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  // const state = useSelector((state) => state.assetOverview);
  const formRef = useRef();
  const dispatch = useDispatch();
  const suggestionState = useSelector((state) => state.suggestions);
  const [buildingType, setBuildingType] = useState([]);
  const [intialFormData, setIntialFormData] = useState({
    assetIdent:"",
    name: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    subAssetType:{ title: "", value: null },
    subAssetIdent: "",
    renovationYear: null,
    constructionYear: null,
  });

  useEffect(() => {
    dispatch(getBuildingType());
  }, [dispatch]);

  useEffect(() => {
    if (
      suggestionState.buildingType.type === "FETCHED" &&
      suggestionState.buildingType.data.length > 0
    ) {
      setBuildingType([...suggestionState.buildingType.data]);
    }
  }, [suggestionState.buildingType]);

  useEffect(() => {
    if (!!props.subAsset) {
      let tempObj = props.subAsset;
      let found = buildingType.find(
        (f) =>
          f.value === tempObj.subAssetType &&
          f.language === scope.projectLanguageCode
      )
      
      setIntialFormData({
        assetIdent: tempObj.assetIdent,
        name: tempObj.name,
        subAssetIdent: tempObj.subAssetIdent,
        address: tempObj.addressDto?.streetName,
        city: tempObj.addressDto?.city,
        state: tempObj.addressDto?.state,
        postalCode: tempObj.addressDto?.postalCode,
        country: tempObj.addressDto?.country,
        subAssetType: !!found ? found : {title: tempObj.subAssetType, value: tempObj.subAssetType},
        renovationYear: !!tempObj.renovationYear ? tempObj.renovationYear : null,
        constructionYear: !!tempObj.constructionYear ? tempObj.constructionYear : null,
      });
    }
  }, [buildingType, props.subAsset]);

  const handleSave = (values) => {
    const address = {
      type:"addressDto",
      streetName: values.address,
      optionalStreetName: "",
      postalCode: values.postalCode,
      city: values.city,
      state: values.state,
      country: values.country,
    };
    const data = {
      projectIdent: scope.projectIdent,
      assetIdent: values.assetIdent,
      subAssetIdent: values.subAssetIdent,
      subAssetType: values.subAssetType.value,
      name: values.name,
      addressDto : address,
      renovation_year: !!values.renovationYear ? new Date(values.renovationYear).getFullYear() : null,
      construction_year: !!values.constructionYear ? values.constructionYear : null,
    }
    dispatch(
      update(data)
    );
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"md"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.onClose();
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("_Subasset")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_asset_close"
            onClick={(e) => props.onClose()}
            style={{ marginLeft: "auto" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <SubAssetCreateForm
          intialFormData={intialFormData}
          handleSave={(values) => handleSave(values)}
          formRef={formRef}
          buildingType={buildingType}
          editDialog={true}
        />
      </DialogContent>
      <DialogActions className="justify-content-end">
        <SaveButton
          id="create-subAsset-button"
          buttonText="_save"
          variant="button"
          style={{ margin: 5 }}
          tooltip={{
            title: "click to save an subAsset",
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

SubAssetEditContainer.defaultProps = {
  readOnly: false,
};
SubAssetEditContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.func,
  subAsset: PropTypes.object,
};

export default SubAssetEditContainer;
