import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import {
  loadUserProjects,
  markProjectFavorite,
} from "../../api/userProjectsApi";
import { resetState } from "../userProjectSlice";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { setProjectOpen } from "../../layout/app-container/containerSlice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputSearch from "../../components/InputSearch";
import Toolbar from "@mui/material/Toolbar";
import Chip from "@mui/material/Chip";
import IntlMessages from "../../utils/IntlMessages";
import { serviceLines } from "../../utils/defaultValues";
import ListItem from "@mui/material/ListItem";
import { getIntlTranslation, getLabel } from "../../utils/helper";
import { useUserDashBoardContextHook } from "./UserDashBoardContext";
import CommentIcon from "@mui/icons-material/Comment";
import { Badge, CircularProgress, Skeleton, Tooltip } from "@mui/material";
import { styled } from "@mui/system";

const CustomBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#C1D0F0",
    color: "#254a9a",
    minWidth: "16px", // Adjust the width of the badge content
    height: "16px", // Adjust the height of the badge content
    fontSize: "11px", // Adjust the font size of the badge content
    borderRadius: "25%", // Make the badge content circular
    padding: "5px", // Add padding to the badge content
  },
}));

export function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={20} height={20}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="30%" stopColor="#244a9a" />
            <stop offset="70%" stopColor="#d08f8e" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress style={{ 'svg circle': { stroke: 'url(#my_gradient)' }, width:20, height:20 }} />
    </React.Fragment>
  );
}

const UserProjects = () => {
  const {
    projectList,
    markProjectFav,
    openProject,
    commentsData,
    loading,
    setLoading,
  } = useUserDashBoardContextHook();
  const [userComments, setUserComments] = useState([]);
  const [filterList, setFilterList] = useState([]);
  useEffect(() => {
    if (projectList.length > 0) {
      setFilterList(projectList);
      setLoading(false);
    }
  }, [projectList]);

  const onFilter = (search) => {
    !!search
      ? setFilterList(
          projectList.filter((e) =>
            e.projectName.toLowerCase().includes(search.toLowerCase())
          )
        )
      : setFilterList(projectList);
  };

  const renderProject = (p) => {
    return (
      <ListItem
        key={p.projectIdent}
        secondaryAction={
          <IconButton onClick={(e) => markProjectFav(p)}>
            {p.favoriteProject ? (
              <StarIcon style={{ color: "#ffb300" }} />
            ) : (
              <StarOutlineIcon />
            )}
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          key={p.projectNumbef}
          onClick={(e) => openProject(p)}
          disablePadding
        >
          <Box className="d-flex flex-wrap align-items-center justify-content-center w-100">
            <ListItemText
              style={{ width: "50%" }}
              primary={
                <Typography
                  variant="subtitle2"
                  component="div"
                  style={{ fontWeight: "bold", color: "#254a9a" }}
                >
                  {p.projectName}
                </Typography>
              }
              secondary={
                <Typography
                  variant="caption"
                  display="block"
                >{`${p.projectNumber}, ${p.projectDescription}`}</Typography>
              }
            />
            {!p.comments && 
            <Box
            className="mx-1"
            style={{ width: "10%" }}
            className="g-comment"
          >
            <GradientCircularProgress/>
            </Box>
            }
            {p.comments?.length > 0 && (
              <Box
                className="mx-1"
                style={{ width: "10%" }}
                className="g-comment"
              >
                <CustomBadge badgeContent={p.comments?.length}>
                  <Tooltip
                    title={getIntlTranslation("tooltip.comments-assigned")}
                  >
                    <CommentIcon style={{ fontSize: 20, color: "#d08f8e" }} />
                  </Tooltip>
                </CustomBadge>
              </Box>
            )}

            <Box style={{ marginRight: 5 }}>
              <Typography
                variant="caption"
                component="div"
                style={{ fontWeight: "bold", color: "#254a9a" }}
              >
                {p.serviceLine}
              </Typography>
            </Box>
            <Box></Box>
          </Box>
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <>
      <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
        <Card style={{width:"60%"}} className="h-100 m-1 rounded-3">
          <Toolbar style={{ height: 40 }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block", width: "40%" },
              }}
            >
              <IntlMessages id="_projects" />
            </Typography>
            <Box
              style={{ width: "100%" }}
              className="d-flex align-items-center justify-content-start p-1 m-1 rounded-3"
            >
              <InputSearch onFilterInput={(input) => onFilter(input)} />
            </Box>
          </Toolbar>
          <CardContent
            style={{ height: "calc(100% - 40px)", overflowY: "auto" }}
            className="p-1"
          >
            {loading ? (
              <List>
                {[1, 2, 3, 4, 5].map((index) => (
                  <ListItem key={index}>
                    <ListItemText>
                      <Skeleton variant="text" />
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            ) : (
              <List
                style={{
                  width: "100%",
                  bgcolor: "background.paper",
                  height: "100%",
                  overflow: "auto",
                  scrollBehavior: "smooth",
                }}
                className="custom-scrollBar"
              >
                {filterList.length === 0 ? (
                  <IntlMessages id="_no records" />
                ) : (
                  filterList
                    .filter((f) => f.favoriteProject)
                    .map((p) => renderProject(p))
                )}
                <Divider>
                  <Chip label={getLabel("_my-projects")} />
                </Divider>
                {filterList
                  .filter((f) => !f.favoriteProject)
                  .map((p) => renderProject(p))}
              </List>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default UserProjects;
