import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  Card,
  CardContent,
  CardActions,
  Autocomplete,
  TextField,
  List,
  Drawer,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getLabel,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import { useComparisonMode } from "../context/ComparisonModeContext";
import { onSelectReportVersion } from "../projectProgressSlice";
import { useSelectedProperty } from "../context/SelectedPropertyContext";
import RenderCompareItemProgress from "./RenderCompareItemProgress";
import { useProjectProgressContext } from "../context/ProjectProgressContext";
import ProjectProgressDataEditDialog from "./ProjectProgressDataEditDialog";

const ProgressComparisionContainer = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.projectProgress);
  const { onSave, subAssetList } = useProjectProgressContext();
  const { selectedProperty, getRisk, getStatus } = useSelectedProperty();
  const { loading, versionSelected, structuredCompareProperties } =
    useComparisonMode();
  const [currentVersion, setCurrenVersion] = useState(null);
  const [comparingProperty, setComparingProperty] = useState({});
  const [edit, setEdit] = useState({
    open: false,
    subAsset: null,
    selected: null,
  });

  useEffect(() => {
    if (!!state.currentVersion.ident) {
      setCurrenVersion(state.currentVersion);
    }
  }, [state.currentVersion]);

  useEffect(() => {
    if (
      selectedProperty &&
      !!structuredCompareProperties.nodes &&
      Object.keys(structuredCompareProperties.nodes).length > 0
    ) {
      setComparingProperty(
        structuredCompareProperties.nodes[selectedProperty.nodeIdent]
      );
    }
  }, [selectedProperty, structuredCompareProperties]);

  const getRows = useCallback(() => {
    if (
      !!comparingProperty &&
      Object.keys(comparingProperty.subAssets).length > 0
    ) {
      return Object.keys(comparingProperty.subAssets).map((m) => ({
        ...comparingProperty.subAssets[m],
        ident: "",
        status: getStatus(comparingProperty.subAssets[m].status),
        risk: getRisk(comparingProperty.subAssets[m].risk),
      }));
    }
    return [];
  }, [getRisk, getStatus, comparingProperty]);

  return (
    <Box
      style={{ width: state.isCompareMode ? "49%" : "0%", height: "100%" }}
      className="mx-1"
    >
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Card className="w-100 h-100 d-flex flex-column g-btn-secondary">
        {!!selectedProperty && !!comparingProperty.subAssets && (
          <CardContent
            className="w-100 overflow-x-hidden overflow-y-auto custom-scrollBar p-1"
            style={{ height: "calc(100% - 60px" }}
          >
            {Object.keys(comparingProperty.subAssets).length > 0 ? (
              <List className="p-1 w-100 custom-scrollBar mt-1 h-100">
                {Object.keys(comparingProperty.subAssets).map((m) => {
                  return (
                    <RenderCompareItemProgress
                      data={comparingProperty.subAssets[m]}
                      selectedProperty={selectedProperty}
                      onCopy={(subAsset) => {
                        setEdit({
                          open: true,
                          subAsset: subAsset,
                          selected: selectedProperty.original,
                        });
                      }}
                    />
                  );
                })}
              </List>
            ) : (
              <Box className="w-100 d-flex align-items-center justify-content-around h-100 g-btn-secondary rounded-3">
                {getTranslationVariantText(
                  "_property not found",
                  "subtitle 2",
                  { fontSize: 14 },
                  "p-1  mx-auto g-btn-secondary rounded-3"
                )}
              </Box>
            )}
          </CardContent>
        )}
        {!!versionSelected && state.versions.length > 0 && (
          <CardActions
            className="p-0 w-100 w-100 mt-auto"
            style={{ height: 50, backgroundColor: "#c0c0c0"}}
          >
            {getTranslationVariantText(
              "_compare",
              "body1",
              {
                fontWeight: "bold",
                color: "#254a9a",
              },
              "w-25 p-1"
            )}
            <Autocomplete
              className="p-1 w-75"
              id="rerport_version"
              value={versionSelected.label}
              options={state.versions.filter(
                (f) => f.ident !== state.currentVersion.ident
              )}
              isOptionEqualToValue={(option, value) =>
                option.ident === value.ident
              }
              onChange={(e, newValue) => {
                dispatch(onSelectReportVersion(newValue));
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={getLabel("version")}
                  size="small"
                  style={{ color: "#ffffff", width: "100%" }}
                />
              )}
            />
          </CardActions>
        )}
      </Card>
      {edit.open && (
        <Drawer
          anchor={"right"}
          open={edit.open}
          style={{ borderRadius: 4 }}
          className="w-100 rounded-2"
        >
          <Box style={{ width: "700px" }} className="h-100" role="presentation">
            <ProjectProgressDataEditDialog
              subAssetList={subAssetList}
              editSubAsset={edit.subAsset}
              selected={edit.selected}
              rows={getRows()}
              onAdd={(data) => onSave(data)}
              onClose={() =>
                setEdit({
                  open: false,
                  subAsset: null,
                  selected: null,
                })
              }
            />
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

ProgressComparisionContainer.propTypes = {};

export default ProgressComparisionContainer;
