import React from "react";
import PropTypes from "prop-types";
import SaveButton from "../../../../components/buttons/SaveButton";
import CustomButton from "../../../../components/buttons/CustomButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { getLabel, getIntlTranslation } from "../../../../utils/helper";


function DefectFormFooter(props) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {props.displayCreateAnother && (
        <FormControlLabel
          control={
            <Checkbox
              checked={props.createAnother}
              style={{
                color: props.createAnother ? "#f50057" : "#6b7280",
                margin: 5,
              }}
              onChange={(e) => props.onSelect(e.target.checked)}
            />
          }
          label={
            <Typography
              variant="body2"
              style={{
                marginLeft: 4,
              }}
            >
              {getLabel("_Create-Another-Defect")}
            </Typography>
          }
          labelPlacement="end"
        />
      )}
      <div style={{ display: "flex" }}>
        <CustomButton
          id="create_defect_cancel_01"
          buttonText="_cancel"
          className={"btn"}
          variant="text"
          tooltip={{
            title: getIntlTranslation("tooltip.close-defect-dialog"),
          }}
          style={{ margin: 5 }}
          handleClick={() => props.onClose()}
        />
        <SaveButton
          id="create_defect_save_01"
          buttonText="_create"
          variant="button"
          style={{ margin: 5 }}
          tooltip={{
            title: getIntlTranslation("tooltip.create-Defect"),
          }}
          handleClick={props.handleSubmit}
        />
      </div>
    </div>
  );
}

DefectFormFooter.defaultProps = {
  displayCreateAnother: true,
};

DefectFormFooter.propTypes = {
  displayCreateAnother: PropTypes.bool,
  createAnother: PropTypes.bool,
  onSelect: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default DefectFormFooter;
