import React from 'react'
import TamModuleDataWrapper from "./structure/TamModuleDataWrapper"
import Grid from '@mui/material/Grid';

const TamModuleHome = () => {
  return (
    <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
  <Grid item xs={12}>
  <TamModuleDataWrapper/>
  </Grid>
  </Grid>
  );
};
export default TamModuleHome;