import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
  CardActions,
  TextField,
  CardHeader,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getVariantText,
  getTranslationVariantText,
  minimalEditorConfig,
} from "../../../../../utils/helper";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { riskTypeOptions } from "../../../../../common/dropdowns";
import GuruEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelectedProperty } from "../context/SelectedPropertyContext";

const KeyFindingsCopyDialog = ({
  open,
  subAssetList,
  onClose,
  selectedProperty,
  onAdd,
  onCopyEdit = {},
}) => {
  const scope = useSelector((state) => state.container.scope);
  const { getRisk } = useSelectedProperty();
  const riskOption = useMemo(
    () =>
      riskTypeOptions.filter((f) => f.language === scope.projectLanguageCode),
    [scope.projectLanguageCode]
  );
  const [toCopyData, setToCopyData] = useState({});

  useEffect(() => {
    if (
      !!selectedProperty &&
      Object.keys(selectedProperty.dataNodes).length > 0
    ) {
      if (Object.keys(selectedProperty.dataNodes).length === 1) {
        Object.keys(selectedProperty.dataNodes).forEach((key) => {
          let temp = selectedProperty.dataNodes[key];
          if (!!temp && temp?.nodeIdent === key) {
            setToCopyData({ [key]: { ...temp, checked: true } });
          }
        });
      } else {
        setToCopyData(selectedProperty.dataNodes);
      }
    } else {
      setToCopyData({});
    }
  }, [selectedProperty, selectedProperty.dataNodes]);

  const handleChanges = (event, value, key, ident) => {
    setToCopyData((prev) => {
      return {
        ...prev,
        [ident]: {
          ...prev[ident],
          [key]: value,
        },
      };
    });
  };

  const transformData = () => {
    const toCopyList = Object.keys(toCopyData)
      .filter((m) => toCopyData[m].checked)
      .map((key) => toCopyData[key])
      .map((copy) => {
        return {
          ...copy.original,
          nodeIdent: "",
          quelle: copy.quelle,
          description: copy.description,
          risk: getRisk(copy.risk),
          isNew: true,
          isUpdated: false,
          isDeleted: false,
        };
      });
    onAdd(toCopyList);
  };

  return (
    <Card className={"h-100 d-flex flex-column"}>
      <AppBar
        style={{
          backgroundColor: onCopyEdit ? "#d08f8e" : "#254a9a",
          position: "sticky",
          zIndex: 5,
        }}
      >
        <Toolbar className={"dialog-toolbar"}>
          <div>
            {getVariantText(selectedProperty.nodeTitle, "body2", {
              textTransform: "uppercase",
            })}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <IconButton
              edge="start"
              color="inherit"
              id="_close"
              onClick={(e) => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <CardContent
        style={{ height: "calc(100%-50px)" }}
        className="custom-scrollBar overflow-y-auto w-100 p-0"
      >
        <Box>
          <List className="p-1 w-100">
          <ListItem
                  key={`key-caption`}
                  className={"d-flex align-items-center justify-content-center w-100 p-1 m-1"}
                >
          {getTranslationVariantText(
          "_checkbox-copy-data",
          "caption",
          { textTransform: "uppercase" },
          "w-100 p-1 m-1 rounded-1 g-btn-secondary"
        )}
        </ListItem>
            {Object.keys(toCopyData).map((f, index) => {
              const data = toCopyData[f];
              return (
                <ListItem
                  key={`key-${data.nodeIdent}`}
                  className={"d-flex flex-column w-100 p-1 m-1"}
                >
                  <Card className="w-100 d-flex flex-column align-items-center justify-content-start p-0">
                   <CardHeader
                   className="w-100"
                   action={<Checkbox
                    checked={data.checked}
                    onChange={(e) => {
                      handleChanges(
                        e,
                        e.target.checked,
                        "checked",
                        data.nodeIdent
                      );
                    }}
                  />
                  }
                   
                   title={
                    <Typography variant="subtitle2" className="w-75 fw-bold">
                      {data.subAsset?.name}
                    </Typography>
                   }
                   />
                   
                  <CardContent className="w-100 p-1 d-flex flex-column align-items-center justify-content-between">
                    <Box
                      id={`box_comments`}
                      className="d-flex g-text-editor align-items-center justify-content-center p-1"
                      style={{ minHeight: "150px", width: "100%" }}
                      tabIndex={-1}
                    >
                      <CKEditor
                        id={`${data.nodeIdent}_description`}
                        key={`${data.nodeIdent}_description`}
                        editor={GuruEditor.ClassicEditor}
                        config={minimalEditorConfig}
                        data={data?.description}
                        onBlur={(event, editor) => {
                          const text = editor.getData();
                          handleChanges(
                            event,
                            text,
                            "description",
                            data.nodeIdent
                          );
                        }}
                      />
                    </Box>
                    <Box className="w-100 p-1">
                      <TextField
                        style={{ width: "100%", marginTop: 5 }}
                        id={`{quelle}_${data.nodeIdent}`}
                        label={getTranslationVariantText("_quelle", "body1", {
                          width: "100%",
                          color: "#254a9a",
                          fontWeight: "bold",
                        })}
                        defaultValue={data.quelle}
                        key={`{quelle}_${data.nodeIdent}`}
                        onBlur={(event) => {
                          handleChanges(
                            event,
                            event.target.value,
                            "quelle",
                            data.nodeIdent
                          );
                        }}
                        InputProps={{
                          "aria-label": "quelle",
                        }}
                        multiline
                        maxRows={2}
                        variant="outlined"
                        size="small"
                        dense="true"
                      />
                    </Box>
                    <Box className="w-100 p-1">
                      <Autocomplete
                        fullWidth
                        disableClearable={true}
                        value={getRisk(data.risk) || ""}
                        options={riskOption}
                        getOptionLabel={(option) => option.title || ""}
                        onChange={(event, newValue) => {
                          if (!!newValue) {
                            handleChanges(
                              event,
                              newValue.value,
                              "risk",
                              data.nodeIdent
                            );
                          }
                        }}
                        style={{ width: "100%" }}
                        dense={"true"}
                        size={"small"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant={"outlined"}
                            label={getTranslationVariantText(
                              "_risk-Type",
                              "body1",
                              {
                                width: "100%",
                                color: "#254a9a",
                                fontWeight: "bold",
                              }
                            )}
                          />
                        )}
                      />
                    </Box>
                  </CardContent>
                  </Card>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </CardContent>
      <CardActions style={{ marginTop: "auto" }}>
        <CustomButton
          id={`add-options-custom-button`}
          buttonText={"add"}
          variant="button"
          style={{ margin: 5, width: "100%" }}
          tooltip={{
            title: "click to add",
          }}
          handleClick={(e) => transformData()}
        />
      </CardActions>
    </Card>
  );
};
export default KeyFindingsCopyDialog;
