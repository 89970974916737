import React from 'react';
import { globalRoot } from "../../utils/defaultValues";
import IntlMessages from "../../utils/IntlMessages";
import CostDataHome from './CostDataHome';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const CostDataRouteConfig = {
  routes: [
    {
      id:"menu.cost-dataRoom",
      path: `${globalRoot}/cost-dataRoom`,
      exact: true,
      name: <IntlMessages id={`menu.cost-dataRoom`}/>,
      icon: <RequestQuoteIcon style={{ width:22, height:22, color: "#637381"}} />,
      element: <CostDataHome />,
    },
  ],
};
export default CostDataRouteConfig;
