import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IntlMessages from "../../utils/IntlMessages";

const FileButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const state = useSelector((state) => state.readOnly);

useEffect(() => {
      setReadOnly(state.isReadOnly);
  }, [state.isReadOnly]);

  const checkPermission = () => {
    if (!!props.permission) {
      return (
        <>
          {props.permission.create && props.permission.update ? (
            <Tooltip title={props.tooltip.title}>
            <Button
              id={props.id}
              className={props.className}
              variant={props.variant}
              style={{ ...props.style }}
              startIcon={props.startIcon && <props.startIcon />}
              endIcon={props.endIcon && <props.endIcon />}
              component="label"
            >
              <Typography variant="button" display="block">
           <IntlMessages id={`${props.buttonText}`}/>
              </Typography>
              <input
                id="assetFile"
                type="file"
                accept=".xlsx"
                hidden
                onChange={(e) => props.onChange(e)}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
              />
            </Button>
          </Tooltip>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <Tooltip title={props.tooltip.title}>
          <Button
            id={props.id}
            className={props.className}
            variant={props.variant}
            style={{ ...props.style }}
            startIcon={props.startIcon && <props.startIcon />}
            endIcon={props.endIcon && <props.endIcon />}
            component="label"
          >
            <Typography variant="button" display="block">
         <IntlMessages id={`${props.buttonText}`}/>
            </Typography>
            <input
              id="assetFile"
              type="file"
              accept=".xlsx"
              hidden
              onChange={(e) => props.onChange(e)}
              onClick={(event) => {
                event.currentTarget.value = null;
              }}
            />
          </Button>
        </Tooltip>
      );
    }
  };

  return <>{!readOnly && checkPermission()}</>;
};

FileButton.defaultProps = {
  buttonText: "",
  variant: "",
};
FileButton.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  permission: PropTypes.object,
  onChange: PropTypes.func,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  style: PropTypes.object,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
};

export default FileButton;
