import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import PhotoFrame from "./PhotoFrame";
import PhotoAlbum from "./PhotoAlbum";
import { useImageContext } from "./ImageContext";

function SortablePhotoFrame(props) {
  const { photo, activeIndex } = props;
  const { attributes, listeners, isDragging, index, over, setNodeRef } =
    useSortable({ id: photo.id });

let tempPhoto = {...photo, index:index+1, order: index+1}
props = {...props,photo:tempPhoto};

  return (
    <PhotoFrame
      ref={setNodeRef}
      active={isDragging}
      insertPosition={
        activeIndex !== undefined && over?.id === photo.id && !isDragging
          ? index > activeIndex
            ? "after"
            : "before"
          : undefined
      }
      aria-label="sortable image"
      attributes={attributes}
      listeners={listeners}
      index={index+1}
      {...props}
    />
  );
}

const SortablePhotoAlbum = (props) => {
  const [photos, setPhotos] = useState([]);
  const renderedPhotos = useRef({});
  const [activeId, setActiveId] = React.useState();
  const [dragEndState, setDragEndState] = useState([]);
  const {
    onDragEnd
  } = useImageContext();
  const activeIndex = activeId
    ? photos.findIndex((photo) => photo.id === activeId)
    : undefined;

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 50, tolerance: 10 },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  useEffect(() => {
    setPhotos(
      props?.photoSet?.map((photo) => ({
        ...photo,
        id: photo.key || photo.src,
      }))
    );
  }, [props.photoSet]);

useEffect(() => {
  if(dragEndState.length > 0) {
    onDragEnd(dragEndState.map((d, index) => ({...d, order:index+1})));
  }
},[dragEndState])

  const handleDragStart = React.useCallback(
    ({ active }) => setActiveId(active.id),
    []
  );

  const handleDragMovement = (items, active, over) => {
    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over.id);

    const afterDrag = arrayMove(items, oldIndex, newIndex);
    setDragEndState(afterDrag);
  return afterDrag;
  }

  const handleDragEnd = React.useCallback((event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setPhotos((items) => handleDragMovement(items,active,over));
      //
    }
    setActiveId(undefined);
  }, []);

  const renderPhoto = (props) => {
    // capture rendered photos for future use in DragOverlay
    renderedPhotos.current[props.photo.id] = props;
    return <SortablePhotoFrame activeIndex={activeIndex} {...props} />;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={photos}>
        <div style={{ margin: 10, width: "100%" }}>
          <PhotoAlbum
            photos={photos}
            rowConstraints={{ maxPhotos: 2 }}
            spacing={10}
            padding={10}
            renderPhoto={renderPhoto}
          />
        </div>
      </SortableContext>
      <DragOverlay>
        {activeId && (
          <PhotoFrame overlay {...renderedPhotos.current[activeId]} />
        )}
      </DragOverlay>
    </DndContext>
  );
};

SortablePhotoAlbum.propTypes = {};

export default SortablePhotoAlbum;
