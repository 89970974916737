import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "react-data-grid";

import { getTextSize, getTextHeight,getWarningText, getIntlTranslation } from "../../../../../utils/helper";
import DefectImagesDrawer from "./DefectImagesDrawer";
import DefectCopyDrawer from "./DefectCopyDrawer";
import RouterPrompt from "../../../../../components/prompt/RouterPrompt";
import { useSnackbar } from "notistack";
import { updateDefectAndMeasure } from "../../../api/defectApi";
import { setUpdatedRows } from "../keyFindingSlice";
// import 'react-data-grid/lib/styles.css';

function EmptyRowsRenderer() {
  return (
    <div style={{ textAlign: "center", gridColumn: "1/-1" }}>
      {getIntlTranslation("_No Rows")}
      <span lang="eng" title="empty row"></span>
    </div>
  );
}

const getRowHeight = (p) => {
  return !!p.row.height ? p.row.height : 40;
};

function rowKeyGetter(row) {
  return row.id;
}

const DefectDataGrid = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const state = useSelector((state) => state.tddKeyFinding);
;
  const [rows, setRows] = useState(props.rows);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [columns, setColumns] = useState([]);
  const [filteredRows, setFilteredRows] = useState(props.rows);
  const [openImageDrawer, setOpenImageDrawer] = useState({
    open: false,
    row: {},
  });
  const [openCopySubAssetDrawer, setOpenCopySubAssetDrawer] = useState({
    open: false,
    row: {},
  });
  const readOnlystate = useSelector((state) => state.readOnly);

  useEffect(() => {
    setRows(props.rows);
    setFilteredRows(props.rows.sort((a, b) => a.rowOrder - b.rowOrder));
    setColumns([...props.columns]);
  }, [props.columns, props.rows]);


  const getFilteredRows = useMemo(() => filteredRows?.sort((a, b) => a.rowOrder - b.rowOrder),[filteredRows]);

  const filterRows = useCallback(
    (search) => {
      let temp = rows.filter((r) => {
        return (
          (r.type === "defect_row" && search.costType.selected.length > 0
            ? search.costType.selected.some(
                (c) => r.costDefect === c.costDefect
              )
            : true) &&
          (r.type === "defect_row" && search.defectType.selected.length > 0
            ? search.defectType.selected.some(
                (c) => r.defectType?.name === c.title
              )
            : true) &&
          (r.type === "defect_row" && search.priorityType.selected.length > 0
            ? search.priorityType.selected.some(
                (c) => r.priority?.name === c.title
              )
            : true) &&
          (r.type === "defect_row" && search.riskType.selected.length > 0
            ? search.riskType.selected.some((c) => r.riskType?.name === c.title)
            : true)
        );
      });
      setFilteredRows(temp);
    },
    [rows]
  );

  useEffect(() => {
    if (!!state.filters) {
      filterRows(state.filters);
    }
  }, [filterRows, state.filters]);

  useEffect(() => {
    if (state.optionalSubCategories.length > 0) {
      let checked = state.optionalSubCategories.filter(
        (f) => f.checked && f.parentKey === state.tab.key
      );
      if (checked.length > 0) {
        setFilteredRows(
          rows.filter((item) =>
            checked.find((f) =>
              f.title.find((t) => t.title === item.trade.value)
            )
          )
        );
      } else {
        setFilteredRows(rows);
      }
    }
  }, [rows, state.optionalSubCategories, state.tab.key]);

  useImperativeHandle(ref, () => ({
    openImageDrawer(val) {
      setOpenImageDrawer({
        open: true,
        row: val.row,
      });
    },

    openSubAssetCopyDrawer(val) {
      var arr = Array.from(selectedRows);
      setOpenCopySubAssetDrawer({
        open: true,
        row: rows.filter((f) => arr.includes(f.id) && f.type === "defect_row"),
      });
    },

    duplicateRow(p) {
      let insertRowIdx = 1 + rows.findIndex((f) => f.id === p.row.id);
      let data = {
        ...p.row,
        id: `row${Math.random() * 30}-${insertRowIdx}`,
        measureIdent: "",
        defectIdent: "",
        capexRef: null,
        updated: true,
        imageCount: 0,
        original: {
          ...p.original,
          defectIdent: "",
          capexRef: null,
          updated: true,
          imageCount: 0,
        },
      };
      let temp = [
        ...rows.slice(0, insertRowIdx),
        data,
        ...rows.slice(insertRowIdx),
      ];
      addToFilteredRow(p, data);
      setRows(temp);
      let updatedRows = [...state.updatedRows];
      updatedRows.push(data);
      dispatch(setUpdatedRows(updatedRows));
    },

    deleteRow(p) {
      let insertRowIdx = rows.findIndex((f) => f.id === p.row.id);
      let row = rows[insertRowIdx];
      let temp = [...rows];
      if (!row.defectIdent && !row.measureIdent) {
        temp.splice(insertRowIdx, 1);
        let updatedRows = [...state.updatedRows];
        updatedRows.splice(updatedRows.findIndex((f) => f.id === p.row.id),1);
        dispatch(setUpdatedRows(updatedRows));
      } else {
        temp[insertRowIdx] = { ...row, deleted: true };
        let updatedRows = [...state.updatedRows];
        updatedRows.push({ ...row, deleted: true });
        dispatch(setUpdatedRows(updatedRows));
      }
      //temp.splice(insertRowIdx, 1);
      deleteFromFiltered(p);
      setRows(temp);
    },

    restoreRow(p) {
      let insertRowIdx = rows.findIndex((f) => f.id === p.row.id);
      let row = rows[insertRowIdx];
      let temp = [...rows];
      temp[insertRowIdx] = { ...row, deleted: false };

      //temp.splice(insertRowIdx, 1);
      restoreFromFiltered(p);
      setRows(temp);
    },

    blockSelect(p, checked) {
      const selectedId = rows
        .filter((m) => m.trade.ident === p.row.trade.ident)
        .map((m) => m.id);
      if (checked) {
        setSelectedRows(
          (prev) => new Set([...Array.from(prev), ...selectedId])
        );
      } else {
        setSelectedRows((prev) => {
          return prev.filter((f) => !selectedId.includes(f));
        });
      }
    },

    onSingleRowSave(row) {
      const updatedRow = createRowToSave(row);
      dispatch(
        updateDefectAndMeasure({
          projectIdent: scope.projectIdent,
          defectList: [updatedRow],
        })
      );
    },

    onSave() {
      let saveRows = rows
        .filter((f) => f.updated || f.deleted)
        .map((values) => {
          return createRowToSave(values);
        });

      dispatch(
        updateDefectAndMeasure({
          projectIdent: scope.projectIdent,
          defectList: saveRows,
        })
      );
    },
  }));

  const createRowToSave = (values) => {
    let measure = {
      type: "measureDto",
      measureIdent: values.measureIdent,
      measureDescription: values.measure,
      pricePerUnit: values.pricePerUnit,
      quantity: values.quantity,
      priority: values.priority.options.find(
        (f) => values.priority.name.toLowerCase() === f.title.toLowerCase()
      ).value,
      percentageShortTermPriority: values.percentageShortTermPriority,
      percentageMidTermPriority: values.percentageMidTermPriority,
      percentageLongTermPriority: values.percentageLongTermPriority,
      extraCostComment: values.extraCostComment,
      extraCost: values.extraCost,
      elementAffected: values.elementAffected,
      comments: values.comments,
      unit: values.unit,
      active: true,
      totalCost: values.totalCost,
      costElement:
        values?.costElement !== undefined ? values?.costElement : null,
    };

    let defect = {
      type: "defectDto",
      assetIdent: openAsset.ident,
      elementIdent: values.element.ident,
      risk: values.riskType.options.find(
        (f) => values.riskType.name.toLowerCase() === f.title.toLowerCase()
      ).value,
      inRedFlag: values.inRedFlag,
      defect: values.defect,
      defectType: values.defectType.options.find(
        (f) => values.defectType.name.toLowerCase() === f.title.toLowerCase()
      ).value,
      costDefect: values.costDefect,
      defectIdent: values.defectIdent,
      deleted: values.deleted,
      dataRoomIndex: values.dataRoomIndex,
      quelle: values.quelle,
      questionAndAnswer: values.questionAndAnswer,
    };
    return { ...defect, measureDtoList: [measure] };
  };

  const addToFilteredRow = (p, data) => {
    let filterRowIdx = 1 + filteredRows.findIndex((f) => f.id === p.row.id);
    let filterTemp = [
      ...filteredRows.slice(0, filterRowIdx),
      data,
      ...filteredRows.slice(filterRowIdx),
    ];
    setFilteredRows(filterTemp);
  };

  const deleteFromFiltered = (p) => {
    let insertRowIdx = filteredRows.findIndex((f) => f.id === p.row.id);
    let row = filteredRows[insertRowIdx];
    let temp = [...filteredRows];
    temp[insertRowIdx] = { ...row, deleted: true };
    //temp.splice(insertRowIdx, 1);
    setFilteredRows(temp);
  };

  const restoreFromFiltered = (p) => {
    let insertRowIdx = filteredRows.findIndex((f) => f.id === p.row.id);
    let row = filteredRows[insertRowIdx];
    let temp = [...filteredRows];
    temp[insertRowIdx] = { ...row, deleted: false };
    //temp.splice(insertRowIdx, 1);
    setFilteredRows(temp);
  };

  const summaryRows = useMemo(() => {
    const summaryRow = {
      id: "total_0",
    };
    return [summaryRow];
  }, []);

  const handleSelectedRowsChange = (selected) => {
    const selection = Array.from(selected);
    const prevSelected = Array.from(selectedRows);
    let temp = [];
    let combined = [];
    if (prevSelected.length > 0 && prevSelected.length > selection.length) {
      //something has been removed
      prevSelected.forEach((item) => {
        let found = selection.find((f) => f === item);
        if (!found) {
          let row = rows.find((r) => r.id === item);
          if (!!row && row.type === "trade_row") {
            let toBeRemoved = rows
              .filter((m) => m.trade.ident === row.trade.ident)
              .map((k) => k.id);
            temp = [...temp, ...toBeRemoved];
          } else if (!!row && row.type === "defect_row") {
            let toBeRemoved = rows
              .filter(
                (m) =>
                  m.trade.ident === row.trade.ident && m.type === "trade_row"
              )
              .map((k) => k.id);
            temp = [...temp, ...toBeRemoved];
          }
        }
      });
      combined = [...selection.filter((f) => !temp.includes(f))];
    } else {
      selection.forEach((row) => {
        let found = rows.find((r) => r.id === row);
        if (!!found && found.type === "trade_row") {
          temp = [
            ...temp,
            ...rows
              .filter((m) => m.trade.ident === found.trade.ident)
              .map((k) => k.id),
          ];
        }
      });
      combined = [...temp, ...selection];
    }

    setSelectedRows(new Set(combined));
  };

  const updateRow = (p, x) => {
    x.indexes.forEach((i) => {
      let row = p[i];
      let index = rows.findIndex((f) => f.id === row.id);
      var tsize = getTextSize(row[x.column.key], "35px");
      var height = getTextHeight(tsize[`width`]);
      if (!row.height) {
        row["height"] = height;
      } else {
        row["height"] = row.height >= height ? row.height : height;
      }
      if (p.length < rows.length) {
        rows[index] = row;
      } else {
        p[index] = row;
      }
    });

    if (p.length < rows.length) {
      setRows(rows);
      setFilteredRows(p);
    } else {
      setRows(p);
      setFilteredRows(p);
    }
  };

  function handleFill({ columnKey, sourceRow, targetRow }) {}

  function handlePaste({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) {

  }

  function handleCopy({ sourceRow, sourceColumnKey }) {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
    }
  }

  return (
    <>
      <DataGrid
        rowKeyGetter={rowKeyGetter}
        columns={columns}
        rows={getFilteredRows}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
          minWidth: 60,
        }}
        onRowsChange={(p, x) => updateRow(p, x)}
        selectedRows={selectedRows}
        onSelectedRowsChange={(x) => handleSelectedRowsChange(x)}
        summaryRows={summaryRows}
        headerRowHeight={50}
        rowHeight={(p) => getRowHeight(p)}
        enableVirtualization={true}
        components={{
          noRowsFallback: <EmptyRowsRenderer />,
        }}
        style={{
          resize: "both",
          width: "100%",
          overflow: "auto",
          maxHeight: "100%",
        }}
        direction={"ltr"}
        className="fill-grid  rdg-light custom-scrollBar data-grid-container"
        cellNavigationMode={"CHANGE_ROW"}
      />
      {openImageDrawer.open && (
        <DefectImagesDrawer
          open={openImageDrawer.open}
          row={openImageDrawer.row}
          onClose={(needGridRefresh) => {
            setOpenImageDrawer({
              open: false,
              row: {},
            });
            if (needGridRefresh) {
              props.needGridRefresh();
            }
          }}
        />
      )}
      {openCopySubAssetDrawer.open && (
        <DefectCopyDrawer
          open={openCopySubAssetDrawer.open}
          row={openCopySubAssetDrawer.row}
          onClose={() =>
            setOpenCopySubAssetDrawer({
              open: false,
              row: {},
            })
          }
        />
      )}
      {!readOnlystate.isReadOnly && (
        <RouterPrompt
          when={rows.filter((f) => f.updated || f.deleted).length > 0}
      cancelText="_back"
          okText="_continue"
          onOK={() => true}
          onCancel={() => false}
          warningHeader={("Save And Leave")}
          message={getWarningText(rows
            .filter((f) => f.updated || f.deleted))}
        />

      )}
    </>
  );
});

DefectDataGrid.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  needGridRefresh: PropTypes.func,
};

export default DefectDataGrid;
