/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form, Field, } from "formik";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IntlMessages from "../../../../../utils/IntlMessages";
import {
  getLabel,
  getOverlineText,
} from "../../../../../utils/helper";
import {
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { NumberFormatBase } from "react-number-format";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, <IntlMessages id="required_field" />)
    .required(<IntlMessages id="required_field" />),
});

const AssetCreateForm = (props) => {
  return (
    <Box
      style={{ overflowY: "auto", marginTop: 5 }}
      className={"custom-scrollBar"}
    >
      <Card>
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={props.intialFormData}
            validationSchema={validationSchema}
            onSubmit={(values) => props.handleSave(values)}
            innerRef={props.formRef}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              isSubmitting,
            }) => (
              <>
                <Form>
                  <Grid container justify="center" spacing={1}>
                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      {getOverlineText("_Asset Information")}
                      <Field
                        name="name"
                        disabled={props.readOnly}
                        label={getLabel("_Name")}
                        style={{ marginTop: 10 }}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      {getOverlineText("_Building Count")}
                        <NumberFormatBase
                          customInput={TextField}
                          value={values.numberofBuildings}
                          name="numberofBuildings"
                          size="small"
                          className="grid-fields"
                          label={getLabel("_Building Count")}
                          style={{ marginTop: 10 }}
                          onChange={(e) =>
                            (values.numberofBuildings = Number(e.target.value))
                          }
                          error={
                            touched.numberofBuildings &&
                            errors.numberofBuildings
                          }
                          helperText={
                            touched.numberofBuildings &&
                            errors.numberofBuildings
                          }
                        />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

AssetCreateForm.propTypes = {
  intialFormData: PropTypes.object,
  handleSave: PropTypes.func,
  formRef: PropTypes.object,
  editDialog: PropTypes.bool,
};

export default AssetCreateForm;
