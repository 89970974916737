/**
 * Authorization Roles
 */
 export const authRoles = {
	admin: ['Admin'],
	project_leader: ['Admin', 'Project Leader'],
	project_assistant: ['Project Leader', 'Project Assistant'],
	user: ['User'],
	all: ['Admin', 'Project Leader', 'Project Assistant','User','USER (READONLY)'],
	onlyGuest: []
};

export const projectRoles = {
	project_leader: ['Project Leader'],
	project_assistant: ['Project Leader', 'Project Assistant'],
	project_user: ['Project Leader', 'Project Assistant','USER (INTERNAL)','USER (READONLY)'],
	user: ['USER (INTERNAL)', 'USER (EXTERNAL)','USER (RESTRICTED)'],
	all: ['Project Leader', 'Project Assistant','USER (INTERNAL)', 'USER (EXTERNAL)','USER (READONLY)','QM PROCESS',"Default"],
}

export const projectAccessRoles = {
	all: ['Admin', 'Project Leader', 'Project Assistant','User','USER (INTERNAL)', 'USER (EXTERNAL)','QM PROCESS',"Default","USER (READONLY)"],
	testAll: ['Admin', 'Project Leader', 'Project Assistant'],
}

