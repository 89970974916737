import { List } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { getVariantText } from '../../../../../../utils/helper';
import { useSelector } from 'react-redux';
import RenderNodeValues from './RenderNodeValues';

const RenderProperty = ({properties, onListUpdate}) => {
    const [propertyList, setPropertyList] = useState(properties);
    const state = useSelector((state) => state.buildingProfileTable);
    const scope = useSelector((state) => state.container.scope);

    const updatePropertyList = (item, nodeValues) => {
      let temp = [...propertyList];
      const index = temp.findIndex((t) => t.nodeIdent === item.nodeIdent);
      if(index !== -1) {
        temp[index] = {...temp[index], nodeValue: nodeValues, isUpdated: true};
      }
      setPropertyList(temp);
      onListUpdate(temp);
    }

    const sortedItemList = useMemo(
      () => propertyList.toSorted((a, b) => a.nodeOrder - b.nodeOrder),
      [propertyList]
    );

    const getPropertyName = (property) => {
        const inputObj = property?.labelTranslation.find(
          (e) => e.language === scope.projectLanguageCode
        );
        return getVariantText(inputObj.title || property?.name, "body1", {
          fontWeight: "bold",
          color: "#254a9a",
          margin: 5,
          textTransform: "uppercase",
        });
      };

  return (
    <>
       {sortedItemList.map((item, index) => {
              return (
                <tr
                  key={`section-${index}`}
                  id={item.nodeIdent}
                >
                  <td
                    id={`text-${item.nodeIdent}`}
                    style={{
                      wordWrap: "break-word",
                      width: "25%",
                      fontSize: "12px",
                      verticalAlign: "baseline",
                    }}
                  >
                    <div className="sticky-text p-1"
                    >
                    {getPropertyName(item)}
                    </div>
                  </td>
                  <td style={{ width: "75%" }}>
                    <List className={"w-100 p-0"}>
                      <RenderNodeValues item={item} 
                      onUpdate={(item, nodeValue) =>{
                        updatePropertyList(item, nodeValue)
                      }}/>
                    </List>
                  </td>
                </tr>
              );
            })}
            </>
  )
}

export default RenderProperty