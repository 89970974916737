import React from "react";
import { makeStyles } from 'tss-react/mui';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  headerCells: {
    backgroundColor: "#254a9a",
    color: "#FFFFFF",
    align: "center",
  },
});

export default function FreeTextTable(props) {
  const classes = useStyles();
  const { data,reportType } = props;

  const createTableStructure = (data) => {
    let temp = [];
    if (data.headers.length > 0) {
      temp.push(
        <TableHead>
          {data.headers.map((dRow) => createTableHeader(dRow.row))}
        </TableHead>
      );
    }
    if (data.rows.length > 0) {
      temp.push(
        <TableBody>
          {data.rows.map((dRow) => createTableRow(dRow.row))}
        </TableBody>
      );
    }
    return temp;
  };

  const createTableHeader = (headers) => {
    return (
      <TableRow>
        {headers.map((h) => {
          return (
            <TableCell className={classes.headerCells}>
              <Typography variant="body1" gutterBottom>
                {h.value}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const createTableRow = (row) => {
    return (
      <TableRow key={row.value}>
        {row.map((r) => {
          const styling = {
            backgroundColor: r.bgColor!==null ? `#${r.bgColor}` : null,
            color:r.textColor!==null ? r.textColor : "#000000"
          }
       
          return (
            <TableCell
              colSpan={r.colSpan != null ? r.colSpan : null}
              style={{ whiteSpace: "pre-wrap" ,backgroundColor:styling.backgroundColor,color: styling.color }}
            >
              <Typography variant="body2" gutterBottom>
                <div style = {{listStyleType: "square"}} dangerouslySetInnerHTML={{__html: `${r.value}`}} />
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
        {createTableStructure(data)}
      </Table>
    </TableContainer>
  );
}
