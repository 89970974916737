import React, {  useRef } from "react";
import clsx from "clsx";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIconButton from "../../../components/buttons/icons-buttons/EditIconButton";
import { b64toBlob, getIntlTranslation, getLabel } from "../../../utils/helper";
import DeleteIconButton from "../../../components/buttons/icons-buttons/DeleteIconButton";
import { Chip, TextField, Divider, InputAdornment } from "@mui/material";
import { useImageContext } from "./ImageContext";
import SaveIconButton from "../../../components/buttons/icons-buttons/SaveIconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import * as markerjs2 from "markerjs2";

const PhotoFrame = React.memo(
  React.forwardRef(function PhotoFrame(props, ref) {
    const { photo, overlay, active, insertPosition, attributes, listeners } =
      props;
    const { style, ...restImageProps } = photo;

    const {
      onRemove,
      onImageSave,
      onCaptionChange,
      isDragable,
      onImageEdit,
      isQuelleNeeded,
      onQuelleChange,
    } = useImageContext();

    const editorRef = useRef();

    const showMarkerArea = () => {
      if (editorRef.current !== null) {
        // create a marker.js MarkerArea
        editorRef.current.src = photo.src;
        const markerArea = new markerjs2.MarkerArea(editorRef.current);
        // attach an event handler to assign annotated image back to our image element
        markerArea.addEventListener("render", (event) => {
          if (editorRef.current) {
            editorRef.current.src = event.dataUrl;
            var encodedBase64 = event.dataUrl.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );
            let editedImage = b64toBlob(encodedBase64);
            onImageEdit(photo, editedImage, event.state);
          }
        });

        markerArea.addEventListener("beforeclose", (event) => {
          editorRef.current.src = photo.isEdited ? photo.editedFile : photo.src;
        });
        // launch marker.js
        markerArea.settings.displayMode = "popup";
        markerArea.settings.defaultColorSet = ["red", "green", "blue"];
        markerArea.settings.defaultColor = "red";
        markerArea.availableMarkerTypes = [
          "LineMarker",
          "MeasurementMarker",
          "ArrowMarker",
          "FrameMarker",
          "CoverMarker",
          "EllipseMarker",
          "EllipseFrameMarker",
          "TextMarker",
          "HighlightMarker",
          "CalloutMarker",
          "CurveMarker",
        ];
        markerArea.renderImageQuality = 1;
        markerArea.show();
        if (photo.markerState?.markers?.length > 0) {
          markerArea.restoreState(photo.markerState);
        }
      }
    };

    return (
      <>
      {!!photo.src && 
      <div
        className="d-flex flex-column"
        style={{
          width: style.width + style.padding,
          margin: style.margin,
        }}
      >
        <div
          style={{
            width: style.width,
            padding: style.padding,
          }}
          className={clsx("photo-frame", {
            overlay: overlay,
            active: active,
            insertBefore: insertPosition === "before",
            insertAfter: insertPosition === "after",
          })}
          ref={ref}
          {...attributes}
          {...listeners}
        >
          <div className="d-flex flex-column w-100">
            <img
            {...restImageProps}
            src={photo.isEdited ? photo.editedFile : photo.src}
            alt={photo.caption || ""}
              ref={editorRef}
              key={`photo-${photo.key}-${photo.isEdited}`}
              style={{
                ...style,
                height: "auto",
                padding: 0,
                margin: 0,
              }}
            />
            <Divider
              style={{
                margin: "2px 0px",
                backgroundColor: "#d08f8e",
                height: 4,
              }}
            />
            <div
              className="d-flex flex-row justify-content-between align-items-center"
              style={{ paddingLeft: 4 }}
            >
              {isDragable && (
                <CustomIconButton
                  id={`drag-${photo.key}`}
                  icon={
                    <DragIndicatorIcon
                      style={{
                        color: "#d08f8e",
                        fontSize: 22,
                        padding: 0,
                        marginTop: 10,
                      }}
                    />
                  }
                  tooltip={{
                    title: getIntlTranslation("tooltip.reorder"),
                  }}
                  label={""}
                  aria-label="drag handler"
                  handleClick={(e) => {}}
                />
              )}
              {photo.status === "saved" && (
                <CheckBoxIcon
                  style={{
                    fontSize: 22,
                    color: "#42c51a",
                    marginTop: 10,
                  }}
                />
              )}
              <EditIconButton
                id={`edit-${photo.id}`}
                handleClick={(event) => showMarkerArea()}
                tooltip={{
                  title: getIntlTranslation("tooltip.edit"),
                }}
                iconStyle={{
                  fontSize: 22,
                }}
                style={{
                  padding: 0,
                  marginTop: 10,
                }}
                color="#254a9a"
              />
              <DeleteIconButton
                id={`delete-image-${photo.id}`}
                tooltip={{
                  title: getIntlTranslation("tooltip.Delete-Image"),
                }}
                handleClick={(event) => {
                  onRemove(photo);
                }}
                style={{
                  padding: 0,
                  marginTop: 10,
                }}
                iconStyle={{
                  fontSize: 22,
                }}
              />
               <SaveIconButton
                  id={`save-generale-image-${photo.key}`}
                  tooltip={{
                    title: getIntlTranslation("tooltip.Save-Image"),
                  }}
                  color="#254a9a"
                  iconStyle={{
                    fontSize: 22,
                  }}
                  style={{
                    padding: 0,
                    marginTop: 10,
                  }}
                  handleClick={(event) => onImageSave(photo)}
                  edge="start"
                />
              <Chip
                style={{
                  padding: 0,
                  marginTop: 10,
                }}
                label={photo.order ? photo.order : "..."}
                variant="outlined"
                color="primary"
                size="small"
              />
            </div>
          </div>
        </div>
        <TextField
          id={`outlined-caption-${photo.key}`}
          key={`outlined-caption-${photo.key}`}
          defaultValue={photo.caption}
          placeholder={!!photo.captionPlaceholder ? photo.captionPlaceholder : "Caption"}
          variant="standard"
          onBlur={(e) => 
            onCaptionChange(photo.key, e.target.value)}
          multiline
          style={{
            width: style.width ,
            margin: 5,
          }}
          maxRows={4}
          InputProps={{
          endAdornment: <InputAdornment position="start">
            {!!photo.capexID ? photo.capexID : ""}
          </InputAdornment>,
          }}
        />
        {isQuelleNeeded && (
          <TextField
            key={`outlined-qulle-${photo.key}`}
            label={getLabel("_quelle")}
            defaultValue={photo.source}
            variant="standard"
            onBlur={(e) =>
              onQuelleChange(photo.key, e.target.value)
            }
            multiline
            style={{
              width: style.width,
              margin: 5,
            }}
            maxRows={4}
          />
        )}
      </div>
  }
      </>
    );
  })
);

export default PhotoFrame;
