import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Divider } from "@mui/material";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import {
  getTranslationVariantText,
  getVariantText,
  getIntlTranslation,
} from "../../../../../utils/helper";
import parse from "html-react-parser";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelectedProperty } from "../context/SelectedPropertyContext";
import CustomButton from "../../../../../components/buttons/CustomButton";
const RenderPropertyDataCard = ({ data, onDelete, isCompare, onCopy }) => {
  const { getRisk } = useSelectedProperty();
  return (
    <Box>
      <ul
        style={{ listStyleType: "square", width: "100%" }}
        className="g-bullet-list m-2"
      >
        {data.mapProperties.map((m, index) => {
          return (
            <li
              style={{
                display: "list-item",
                padding: "5px 5px",
              }}
            >
              <div className={"d-flex flex-column w-100"}>
                  <Box className="w-100 d-flex align-items-center justify-content-between">
                  <Typography variant="subtitle2" className="w-75 fw-bold">
                   {m.subAsset?.name}
                   </Typography>
                  </Box>
                  <Box className="w-100 d-flex align-items-center justify-content-between">
                  <Typography variant="subtitle2" className="w-75">
                  <div className="highlight-comment">
                    {parse(m.description)}
                  </div>
                  <span style={{ marginLeft: 5 }}>
                    {getTranslationVariantText("_quelle", "caption")} [{" "}
                    {getVariantText(m.quelle, "caption")} ]
                  </span>
                </Typography>
                <Divider orientation="vertical" flexItem />
                <div
                  className="w-25 d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: getRisk(m.risk)?.monitoringColor,
                    color: "#ffffff",
                    height: 25,
                    marginLeft: "auto",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: !!m.riskType ? "#ffffff" : "inherit",
                      textTransform: "uppercase",
                    }}
                  >
                    {getRisk(m.risk).title || m.risk}
                  </Typography>
                </div>
                              
                {!isCompare && (
                  <div style={{ width: "5%" }}>
                    <CustomIconButton
                      id={`delete_row_button_${m.nodeIdent}`}
                      icon={
                        <DeleteForeverIcon
                          style={{
                            color: "#f50057",
                            fontSize: 25,
                          }}
                        />
                      }
                      permission={null}
                      handleClick={(e) => {
                        onDelete(m);
                      }}
                      tooltip={{
                        title: getIntlTranslation("tooltip.delete"),
                      }}
                      style={{
                        borderRadius: 0,
                        padding: 2,
                        width: "100%",
                        marginLeft: "auto",
                      }}
                      aria-describedby={`delete_row_button_${m.nodeIdent}`}
                    />
                  </div>
                )}
                  </Box>
                  

              </div>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

RenderPropertyDataCard.propTypes = {};

export default RenderPropertyDataCard;
