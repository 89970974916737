import React, { useMemo, useState } from "react";

import {Box, CardContent} from "@mui/material";
import DragableItems from "./DragableItems";

import {
  DndContext,
  useSensor,
  useSensors,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useDocumentContextHook } from "./DocumentBuilderContext";

function DragContainer() {
  const { dragItems, handleDragContainerDrop } = useDocumentContextHook();
  const [activeId, setActiveId] = useState();

  const filteredDragItems = (searchQuery) => {
    return searchQuery
      ? dragItems.filter((item) =>
          item.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : dragItems;
  };

  const dragOverlay = useMemo(() => dragItems?.find((f) => f.key === activeId), [dragItems,activeId])

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 50, tolerance: 10 },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragStart = React.useCallback(
    ({ active }) => setActiveId(active.id),
    []
  );

  return (
    <>
      <CardContent
        className="custom-scrollBar m-1"
        style={{
          width: "98%",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 2,
          height: "100%",
        }}
      >        
        <Box className="d-flex flex-column w-100 align-items-center justify-content-between">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragContainerDrop}
          >
            <SortableContext
              items={dragItems.map((item) => item.id)}
              id="drag-container"
            >
              {filteredDragItems().map((m, index) => (
                <DragableItems item={{ ...m }} />
              ))}
            </SortableContext>
            <DragOverlay>
      {activeId && (
          <DragableItems overlay item ={dragOverlay}/>
        )}
      </DragOverlay>
          </DndContext>
        </Box>
      </CardContent>
    </>
  );
}

DragContainer.propTypes = {};

export default DragContainer;
