import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MapIcon from "@mui/icons-material/Map";
import Card from "@mui/material/Card";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Maps = forwardRef((props, _ref) => {
  const { provided, node, data,remove } = props;
  const [editable, seteditable] = useState(true);

  useEffect(() => {
    if (node.children.length > 0) {
      var obj = node.children.some((s) => !s.editable);
      if (!!obj) {
        seteditable(false);
      }
    }
  }, [node.children]);

  const handleRemoveOption = () => {
    if (typeof remove === "function") {
      remove(node, data);
    }
  };

  useImperativeHandle(_ref, () => ({
    getNode: () => {
      return { ...node };
    },
  }));

  return (
    <Card
      sx={{ width: "100%", marginTop: 1 }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      raised={true}
    >
      <ListItem
        key={node.key}
        secondaryAction={
          <IconButton
            edge="end"
            component="span"
            style={{ color: "#f50057" }}
            onClick={(e) => handleRemoveOption()}
          >
            <DeleteForeverIcon />
          </IconButton>
        }
      >
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <ListItemText primary="Google Maps" />
      </ListItem>
    </Card>
  );
});
export default Maps;
