import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography,} from "@mui/material";
import IntlMessages from "../../../../utils/IntlMessages";
import {getLabel,getVariantText} from "../../../../utils/helper";
import { roles } from "../../../../common/dropdowns";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Collapse from "@mui/material/Collapse";
import "react-quill/dist/quill.snow.css";
import { useIntl } from "react-intl";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const projectNotifications = [
  {
    label: "Internal Users",
    roles: [
      "PROJECT LEADER",
      "PROJECT ASSISTANT",
      "USER (INTERNAL)",
      "QM PROCESS",
    ],
    defaultText: {
      en: "",
      de: "",
    },
  },
  {
    label: "External Users",
    roles: [
      "PROJECT LEADER",
      "PROJECT ASSISTANT",
      "USER (INTERNAL)",
      "QM PROCESS",
    ],
    defaultText: {
      en: "",
      de: "",
    },
  },
];

function AddUsersAndRole(props) {
  const intl = useIntl();
  const [userRoles, setUserRoles] = useState({});
  const [userEmail, setUserEmail] = useState({});
  const [configureEmail, setconfigureEmail] = useState(false);

  useEffect(() => {
    if (Object.keys(props.values.users).length > 0) {
      setUserRoles(props.values.users);
    }
  }, [props.values.users]);

  // Determine if the dropdown should be disabled
  const isDropdownDisabled = () => {
    const userRoles = props.userRoles || [];  // Assuming `userRoles` is passed as a prop
    const hasEditRole = userRoles.some(role => role.trim().toUpperCase() === "PROJECT LEADER" || role.trim().toUpperCase() === "PROJECT ASSISTANT");
    return !(hasEditRole && props.userPermission.update);  // Disable if no edit role or no update permission
  };

  return (
    <>
      <Box>
        <List>
        <Typography variant="overline" 
        style={{ borderBottom: "2px solid #254a9a",color: "#254a9a", marginLeft:20}}>
        <IntlMessages id={"Add Users TO Project"} />
      </Typography>
          {roles.map((m) => {
            const labelId = `checkbox-list-label-${m}`;
            return (
              <Box className="d-flex flex-column" style={{ minHeight: 60,marginLeft:20 }}>
                <ListItem
                  key={m}
                  style={{ marginTop: 20 }}
                  disablePadding
                  className="w-100"
                  >
                  <Box className={"w-100 d-flex flex-wrap"}>
                  <ListItemText id={labelId} 
                  primary={getVariantText(m,"body1",
                  {fontWeight:"bold",color:"#254a9a"})} 
                  style={{width:"30%"}}/>
                  <Box>
                    <Autocomplete
                      multiple
                      size="small"
                      id="checkboxes-users"
                      options={props.users}
                      value={props.users.filter((u) =>
                        userRoles[m]?.some(
                          (s) => s?.user?.emailAddress === u.emailAddress
                        )
                      )}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {
                        let temp = newValue.map((value) => {
                          return {
                            user: value,
                            role: m,
                          };
                        });
                        setUserRoles((prev) => {
                          props.setFieldValue("users", {
                            ...prev,
                            [m]: temp,
                          });
                          return {
                            ...prev,
                            [m]: temp,
                          };
                        });
                      }}
                      disabled={isDropdownDisabled()}  // Disable the dropdown if necessary
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getLabel("Team Members")}
                          placeholder={intl.formatMessage({ id:"_type-user-search"}).toLowerCase()}
                          size="small"
                        />
                      )}
                    />
                  </Box>
                  </Box>
                </ListItem>
              </Box>
            )
          })
        }
        </List>
      </Box>
      <Box>
        {/* <Typography variant="body1" className="toolbar-heading">
          <Checkbox
            fontSize="small"
            onChange={(e) => setconfigureEmail(e.target.checked)}
          />
          <IntlMessages id={"_trigger_team_member_add_email"} />
        </Typography> */}
        <Collapse in={configureEmail} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {projectNotifications.map((m) => {
              const labelId = `trigger-${m.label}`;
              return (
                <>
                  <ListItem style={{ minHeight: 60 }} key={m.label}>
                    <ListItemButton dense>
                      <ListItemText id={labelId} primary={m.label} />
                    </ListItemButton>
                  </ListItem>
                  <Box
                    className="customQuill report"
                    style={{ height: "100%", width: "90%", margin: 5 }}
                  >
                  </Box>
                </>
              );
            })}
          </List>
        </Collapse>
      </Box>
    </>
  );
}

AddUsersAndRole.propTypes = {
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  users: PropTypes.array,
  userRoles: PropTypes.array,  // Ensure userRoles is received as a prop
  userPermission: PropTypes.object,  // Ensure userPermission is received as a prop
};

export default AddUsersAndRole;
