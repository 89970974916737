import axios from "../../../api/customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {
  getFailure,
  getReportSuccess,
} from "../feature-bundle/report-generation/monitoringReportSlice.js";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";

export const generateReport = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `${request.api}/reports/${request.language}/${request.versionType}/generate-report/${request.versionIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: request.data,
        config: {
          timeout: 1000 * 5,
        },
      })
      );
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getReportSuccess(response.data));
      }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};