import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";

export const initialState = {
  hasErrors: false,
    error: { ...response },
    fetch: { ...response },
    fetchCompare: { ...response },
    save: { ...response },
    custom: { ...response },
    customDelete: { ...response },
    currentData: [],
    updatedRows: [],
    isCompareMode: false,
    currentVersion:{},
    versions:[],
    additionalProperties: { ...response },
    propertyAction: {
      action: null,
      status: null,
      data: null,
    },
    selectedComparingVersion:{},
    copyData:[],
  };

const projectDescriptionSlice = createSlice({
  name: "projectDescription",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetch: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getFetchCompareSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchCompare: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
        response:payload.response,
      },
    }),
    setUpdatedRows: (state, { payload }) => ({
      ...state,
      updatedRows: payload,
    }),
    onSelectReportVersion: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      selectedComparingVersion:payload
    }),
    resetState: (state) => ({
      ...initialState,
      currentVersion:state.currentVersion,
      isCompareMode: state.isCompareMode,
    }),
  setVersions: (state, { payload }) => ({...state, hasErrors: false, versions:payload}),
  setCompareMode: (state, { payload }) => ({ ...state, hasErrors: false, isCompareMode:payload}),
  setCurrentVersion: (state, { payload }) =>({ ...state, hasErrors: false, currentVersion:payload}),
  setCompleteReset: (state, { payload }) => ({  ...initialState}),
  },
});

export const {
  resetState,
  getFailure,
  getFetchSuccess,

  getSaveSuccess,
  getFetchCompareSuccess,
  setUpdatedRows,
  setCompareMode,
  setCurrentVersion,
  onSelectReportVersion,
  setVersions,
  setCompleteReset,
} = projectDescriptionSlice.actions;

// A selector
export const projectDescriptionSelector = (state) => state.projectDescription;

// The reducer
export default projectDescriptionSlice.reducer;
