
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { response } from "../../../../../utils/defaultValues";
import { getFetchCompareSuccess, onSelectReportVersion } from "../keyFindingsSlice";
import { useKeyFindingsContext } from "./KeyFindingsContext";
import { useCategoriesData } from "./CategoriesDataContext";
import { useSelectedProperty } from "./SelectedPropertyContext";

const ComparisonModeContext = createContext();

export const useComparisonMode = () => useContext(ComparisonModeContext);

export const ComparisonModeProvider = ({ children }) => {
  const scope = useSelector((state) => state.container.scope);
  const { normalizeCategories } = useCategoriesData();
  const {onFetchCompareProperties} = useKeyFindingsContext();
  const { selectedCategory } = useSelectedProperty();
  const state = useSelector((state) => state.keyFindings);
  const monitoringState = useSelector((state) => state.projectMonitoring);
  const [versionSelected, setVersionSelected] = useState(null);
  const [compareCategory, setCompareCategory] = useState([]);
  const [ structuredCompareCategory,setStructuredCompareCategory] = useState({})
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      monitoringState.isCompareMode &&
      state.versions?.length > 0
      && !!state.currentVersion.ident
      && !state.selectedComparingVersion
      
    ) {
      let temp =  state.versions
        .filter((f) => f.ident !== state.currentVersion.ident)
        .sort((a, b) => a.reportOrder - b.reportOrder);
      dispatch(onSelectReportVersion(temp[0]));
      setLoading(true);
    }
  }, [
    monitoringState.isCompareMode,
    state.currentVersion,
    dispatch,
    state.selectedComparingVersion,
    state.versions
  ]);

  useEffect(() => {
    if (!!state.selectedComparingVersion && !!state.selectedComparingVersion.ident && !!selectedCategory) {
      setVersionSelected(state.selectedComparingVersion);
      onFetchCompareProperties(state.selectedComparingVersion.ident, selectedCategory?.nodeIdent);
    }
  }, [onFetchCompareProperties, selectedCategory, state.selectedComparingVersion]);

  useEffect(() => {
    if (
      state.fetchCompare.type === "FETCHED" &&
      state.fetchCompare.data.length > 0
    ) {
      setCompareCategory({...selectedCategory.original, properties:state.fetchCompare.data});
      setStructuredCompareCategory(normalizeCategories(
        [{...selectedCategory.original, properties:state.fetchCompare.data}],
        scope.projectLanguageCode
      ));
      dispatch(getFetchCompareSuccess({ ...response, guruDtoList: [] }));
      setLoading(false);
    }
  }, [dispatch, normalizeCategories, scope.projectLanguageCode, selectedCategory, state.fetchCompare.data, state.fetchCompare.type]);


  return (
    <ComparisonModeContext.Provider
      value={{
        versionSelected,
        loading,
        compareCategory,
        structuredCompareCategory,
        setStructuredCompareCategory
      }}
    >
      {children}
    </ComparisonModeContext.Provider>
  );
};
