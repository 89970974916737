import React, { useEffect, useState } from "react";
import { database } from "../../../../../index";
import { ref, set } from "firebase/database";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";

const useCommentsFireBase = () => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);

  const saveCommentsToFirebase = async (commentParent, reportType) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if(user){
      const section = commentParent.section;
      const comments = commentParent.commentValues.map((m) => {
        m = {...m, queryDetails:{
          ...commentParent, commentValues:[],
          apiParam:commentParent.apiParam,
          reportType:reportType,
          apiIdent:commentParent.apiIdent,
        }}
        return m;
      })
      const commentPath = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/sections/${section}/comments`;
      const commentsRef = ref(database, commentPath);
      try {
        await set(commentsRef, comments);
      } catch (error) {
        console.error("Error adding comment: ", error);
      }
    }else {
      console.error("Error adding comment, Authentication Error");
    }

   
  };

  return { saveCommentsToFirebase };
};

export default useCommentsFireBase;

function push(commentsRef, arg1) {
  throw new Error("Function not implemented.");
}
