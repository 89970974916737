import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ref, get, off } from "firebase/database";
import { database } from "../../../index";
import { getCurrentUser } from "../../../authentication/authTokenService";


function useFetchCommentsHook () {
  const dispatch = useDispatch();
  const userData = getCurrentUser();

  const onFetch = useCallback(async (projectIdent) => {
    const projectPath = `projects/${projectIdent}/`
    const projectsRef = ref(database, projectPath);

    try {
        let response = {
            comments:[],
            projectIdent:projectIdent,
        }
        const snapshot = await get(projectsRef);
        if ( snapshot.exists() ) {  
            const projectData = snapshot.val(); 
            const assets = projectData.assets;
            const comments = [];
            for (const assetId in assets) {
                const assetData = assets[assetId];
                const reportTypes = assetData.reports;
                for (const reportType in reportTypes) {
                    const path = `projects/${projectIdent}/assets/${assetId}/reports/${reportType}/sections`;
                    const commentsRef = ref(database, path);
                    try {
                        const commentsSnapshot = await get(commentsRef);
                        if (commentsSnapshot.exists()) {
                            const assetComments = commentsSnapshot.val();
                                    let temp = [];
                                    for (const key in assetComments) {
                                        let found =  findUserInMentions( assetComments[key].comments)
                                        if (found.length > 0){
                                        temp.push(found);
                                      }
                                    }
                                    const flatten = temp.flat(1);
                                    if(flatten.length > 0){
                                        flatten.filter((f) => f.assetIdent === assetId).forEach(f => {
                                            comments.push(
                                                {   
                                                    type: reportType,
                                                    path: path,
                                                    assetIdent: assetId,
                                                    reportType: reportType,
                                                    comment: f,
                                                }
                                            )
                                        })
                                }
                        } else {
                            console.log(`No comments available for path: ${path}`);
                        }
                    } catch (error) {
                        console.error("Error fetching comments:", error);
                    }
                }
            }
            console.log("comments", comments);
            return {...response,comments: comments};
        }
        return {...response,comments: []};        
    } catch ( error ) {
        console.error( "Failed to clean up database:", error );
      }
  },[]);

const findUserInMentions = (comments) => {
   let found =  comments.filter((comment) => {
    return (comment.mentionedInComments?.some((s) => s.userId === userData.data.uid && !comment.resolved)
    || comment.replies?.some(reply => reply.mentionedInComments?.some((s) => s.userId === userData.data.uid)))
   }
)
return found;
}



  const onResetState = () => {
   
  };
  return {onFetch, onResetState};
}

export default useFetchCommentsHook;
