/* eslint-disable no-param-reassign */

  
export const addClasses = (classToBeAdded, containerClasses) => {
  let temp = containerClasses.filter((f) => f !== classToBeAdded);
  temp.push(classToBeAdded);
  return temp ;
};

export const removeClasses = (classToBeAdded, containerClasses) => {
  let temp = containerClasses.filter((f) => f !== classToBeAdded);
  return temp ;
};


export const addAndRemoveClasses = (
  addClassname,
  containerClasses,
  toBeRemovedClass
) => {
  const temp = [toBeRemovedClass, addClassname];
  const currentClasses = containerClasses
  if (!!currentClasses && currentClasses.length > 0) {
    let remainingClasses = currentClasses.filter((f) => !temp.includes(f));
    remainingClasses.push(addClassname);
    return remainingClasses;
  } else {
    return containerClasses;
  }
};
