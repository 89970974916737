import React, { useEffect, useMemo, useState } from "react";
import { Card, CardActions, CardContent, List } from "@mui/material";
import { useProjectInformationContext } from "../context/ProjectInformationContext";
import RenderProperty from "./RenderProperty";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { getIntlTranslation } from "../../../../../utils/helper";
import AddIcon from "@mui/icons-material/Add";
import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import AddOptionCustomPropertiesContainer from "../../../version-comparison/AddOptionCustomPropertiesContainer";

const DraggableListContainer = ({ items, onDragEnd }) => {
  const [activeId, setActiveId] = React.useState();
  const activeIndex = activeId
    ? items.findIndex((item) => item.nodeIdent === activeId)
    : undefined;

  const handleDragStart = React.useCallback(
    ({ active }) => setActiveId(active.id),
    []
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!!active && !!over && active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.nodeIdent === active.id);
      const newIndex = items.findIndex((item) => item.nodeIdent === over.id);
      onDragEnd(arrayMove(items, oldIndex, newIndex));
      setActiveId(undefined);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <List className="p-1">
          {items.map((item) => (
            <RenderProperty
              key={item.nodeIdent}
              id={item.nodeIdent}
              node={item}
              activeIndex={activeIndex}
            />
          ))}
        </List>
      </SortableContext>
      <DragOverlay>
        {!!activeIndex && (
          <RenderProperty
            overlay={true}
            key={items[activeIndex].nodeIdent}
            id={items[activeIndex].nodeIdent}
            node={items[activeIndex]}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};

const RenderPropertyContainer = (props) => {
  const { properties, handleReorder, onSave, onAdditionalProperty } =
    useProjectInformationContext();
  const [openAddLabelDialog, setOpenAddLabelDialog] = useState(false);
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    if (properties.length > 0) {
      setListItems(properties);
    }
  }, [properties]);

  const requiredProperties = useMemo(
    () => listItems?.filter((f) => f.required),
    [listItems]
  );
  const optionalProperties = useMemo(
    () => listItems?.filter((f) => !f.required),
    [listItems]
  );

  return (
    <>
      <Card className="w-100 h-100" style={{ backgroundColor: "transparent" }}>
        <CardContent
          style={{ height: "calc(100% - 60px)" }}
          className="w-100 overflow-y-auto custom-scrollBar"
        >
          <DraggableListContainer
            items={listItems}
            onDragEnd={(items) => handleReorder(items)}
          />
        </CardContent>
        <CardActions
          style={{ marginTop: "auto" }}
          className={
            "d-flex align-items-center justify-content-between p-1 w-100"
          }
        >
          <CustomButton
            id={`add_optional_properties`}
            buttonText={"_add-properties"}
            endIcon={<AddIcon style={{ color: "#d08f8e", fontSize: 20 }} />}
            variant="button"
            textVariant="caption"
            buttonColor={"inherit"}
            style={{
              margin: 5,
              height: 40,
              width: "35%",
              padding: 5,
            }}
            className="g-btn-secondary"
            tooltip={{
              title: getIntlTranslation("Optional Properties"),
            }}
            size="small"
            handleClick={(e) => setOpenAddLabelDialog(true)}
          />
          <CustomButton
            id={"save_current_version"}
            buttonText="_save"
            variant="button"
            className="g-btn-primary"
            style={{
              margin: 5,
              height: 40,
              width: "60%",
              padding: 5,
            }}
            tooltip={{
              title: getIntlTranslation("tooltip.save"),
            }}
            handleClick={(e) => onSave()}
          />
        </CardActions>
      </Card>
      {openAddLabelDialog && (
        <AddOptionCustomPropertiesContainer
          open={openAddLabelDialog}
          onClose={() => setOpenAddLabelDialog(false)}
          data={optionalProperties}
          requiredProperties={requiredProperties}
          onAddOptional={(data) => {
            onAdditionalProperty(data);
            setOpenAddLabelDialog(false);
          }}
        />
      )}
    </>
  );
};

RenderPropertyContainer.propTypes = {};

export default RenderPropertyContainer;
