import axios from 'axios';
import  { store}  from "../store";
import {setReadOnly} from "../guru-suite/component-bundle/slice/readOnlySlice";
const { REACT_APP_API_URL } = process.env;

const customAxios = axios.create({
    baseURL: REACT_APP_API_URL, 
});

const responseHandler = response => {
    if(!!response.data){
        if (!!response.data.projectReadable) {
            store.dispatch(setReadOnly(response.data.projectReadable))
        } else {
            store.dispatch(setReadOnly(false))
            
        }
       }
    return response;
};

const errorHandler = error => {
    return Promise.reject(error);
};

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
 );


export default customAxios;