import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";
export const initialState = {
  isCompareMode: false,
  currentData: {},
  updatedRows: {},
  comparisonProperties: { ...response },
  currentVersion:{},
  versions:[],
  additionalProperties: { ...response },
  selectedComparingVersion:{},
  propertyAction: {
    action: null,
    status: null,
    data: null,
  },
};

const currentVersionSlice = createSlice({
  name: "currentVersionSlice",
  initialState,
  reducers: {
    setCurrentData: (state, { payload }) => ({
      ...state,
      currentData: payload,
    }),
    setUpdatedRows: (state, { payload }) => ({
      ...state,
      updatedRows: payload,
    }),
    setpropertyAction: (state, { payload }) => ({
      ...state,
      propertyAction: {
        action: payload.action,
        status: payload.status,
        data: payload.data,
      },
    }),
    resetPropertyAction: (state) => ({
      ...state,
      propertyAction: {
        action: null,
        status: null,
        data: null,
      },
    }),
    resetState: (state) => ({
      ...initialState,
      currentVersion:state.currentVersion,
      isCompareMode: state.isCompareMode,
    }),
    setCompareProperties: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      comparisonProperties: {
        type: payload.type,
        messages: payload.messages,
        data: payload.data,
      },
    }),
    setAdditionalProperties: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      additionalProperties: {
        type: payload.type,
        messages: payload.messages,
        data: payload.data,
      },
    }),
    onSelectReportVersion: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      selectedComparingVersion:payload
    }),

    setVersions: (state, { payload }) => ({...state, hasErrors: false, versions:payload}),
    setCompareMode: (state, { payload }) => ({ ...state, hasErrors: false, isCompareMode:payload}),
    setCurrentVersion: (state, { payload }) =>({ ...state, hasErrors: false, currentVersion:payload}),
    setCompleteReset: (state, { payload }) => ({  ...initialState}),
  },
});

export const {
  resetState,
  setCurrentData,
  setUpdatedRows,
  resetPropertyAction,
  setpropertyAction,
  setAdditionalProperties,
  setCompareProperties,
  setCompareMode,
  setCurrentVersion,
  onSelectReportVersion,
  setVersions,
  setCompleteReset,
} = currentVersionSlice.actions;

// A selector
export const currentVersionSelector = (state) => state.currentVersion;

// The reducer
export default currentVersionSlice.reducer;
