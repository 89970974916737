import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getLabel,
  getDialogBoxHeader,
  getIntlTranslation,
} from "../../../../../utils/helper";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { getBuildingType } from "../../../../../api/suggestionApi";
import { createMonitoringSubAsset, fetchAssetList } from "../../../../../api/assetOverviewApi";
import { resetState } from "../../../../component-bundle/asset-overview/assetOverviewSlice";
import { useSnackbar } from "notistack";
import SubAssetCreateForm from "./SubAssetCreateForm";
import { v4 as uuidv4 } from "uuid";
import { create } from "../../../api/subAssetApi";
import { resetSubAssetState } from "./subAssetSlice";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const SubAssetCreateContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.assetOverview);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const suggestionState = useSelector((state) => state.suggestions);
  const assetState = useSelector((state) => state.assetOverview.create);
  const subassetsState = useSelector((state) => state.subasset);
  const { onClose, node, open, mode } = props;

  const [uniqueId, setuniqueId] = useState(uuidv4());

  const [buildingType, setBuildingType] = useState([]);
  const [createAnother, setCreateAnother] = useState(false);
  const intialFormData = {
    subAssets: [
      {
        id: "",
        order: 1,
        type: { title: "", value: "" },
        name: "",
        constructionYear: new Date().getFullYear(),
        checked: true,
        deleted: false,
        renovationYear: "",
        streetName: "",
        optionalStreetName: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
        address:""
      },
    ],
  };

  useEffect(() => {
    dispatch(getBuildingType());
  }, [dispatch]);

  useEffect(() => {
    if (
      suggestionState.buildingType.type === "FETCHED" &&
      suggestionState.buildingType.data.length > 0
    ) {
      setBuildingType([...suggestionState.buildingType.data]);
    }
  }, [suggestionState.buildingType]);

  useEffect(() => {
    if (subassetsState.type === "SUCCESS") {
      subassetsState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      props.onClose();
      dispatch(resetSubAssetState());
          const request = {
        api:"tdd",
        projectIdent:node.projectIdent
      }
      dispatch(fetchAssetList(request));
    }
  },[dispatch, enqueueSnackbar, node.projectIdent, props, subassetsState]);

  const handleSave = async (values) => {
   await values.subAssets.forEach((s,index) => {
          let year = new Date(s.constructionYear).getFullYear()
          const data = {
            api:"tdd",
            type: "subAssetsDto",
            assetIdent: node.assetIdent,
            projectIdent: node.projectIdent,
            name: s.name,
            subAssetType: s.type.value,
            construction_year: s.constructionYear,
            renovation_year: s.renovationYear,
          }
          dispatch(
            create(data)
          );
    });

  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"md"}
      onClose={(e) => props.onClose()}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className="appBar">
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("Sub-Asset")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_asset_close"
            onClick={(e) => props.onClose()}
            style={{ marginLeft: "auto" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <SubAssetCreateForm
          intialFormData={intialFormData}
          handleSave={(values) => handleSave(values)}
          formRef={formRef}
          buildingType={buildingType}
        />
      </DialogContent>
      <DialogActions className="justify-content-between" style={{marginLeft:"auto"}}>
        <SaveButton
          id="create-sub-asset-button"
          buttonText="_create"
          variant="button"
          style={{ margin: 5 }}
          editDialog={false}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

SubAssetCreateContainer.defaultProps = {
  readOnly: false,
};
SubAssetCreateContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  onCreate: PropTypes.func,
  assetIdent: PropTypes.string,
  asset :PropTypes.object,
};

export default SubAssetCreateContainer;
