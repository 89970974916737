import React from 'react';
import DocumentBuilderHome from "./DocumentBuilderHome"
import { globalRoot } from "../../utils/defaultValues";
import IntlMessages from "../../utils/IntlMessages";
import SummarizeIcon from '@mui/icons-material/Summarize';

// change path  
const DocumentBuilderRouteConfig = {
	routes: [
		{
    path: `${globalRoot}/customizable-report`,
    exact: true,
    name: <IntlMessages id={"menu.customizable-report"}/>,
    element: <DocumentBuilderHome/>,
    icon: <SummarizeIcon style={{ width:22, height:22, color: "#637381"}} />,
    auth: [],
  },
	]
};
export default DocumentBuilderRouteConfig;