import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import VersionContainerWrapper from "../../version-comparison/VersionContainerWrapper";
import { resetState } from "../../version-comparison/slice/VersionDataSlice";
import Box from "@mui/material/Box";
import { setCompleteReset } from "../../version-comparison/slice/CurrentVersionSlice";
import { resetPromptState } from "../../projectmonitoringModuleSlice";

const ConclusionHome = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCompleteReset());
    dispatch(resetState());
    dispatch(resetPromptState());
  }, [dispatch]);

  return (
    <Box
      style={{ height: "100%", overflow: "hidden", backgroundColor: "#ebedef" }}
    >
      <VersionContainerWrapper moduleApi="conclusion" />
    </Box>
  );
};
export default ConclusionHome;
