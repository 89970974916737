import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  AppBar,
  Toolbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getTranslationVariantText, getVariantText, getLabel } from "../../../../../utils/helper";
import AssetSwitcher from "../../../../component-bundle/assetSwitcher/AssetSwitcher";
import { useUpdateState } from "../../../version-comparison/context/UpdateStateContext";
import { setCompareMode } from "../projectProgressSlice";
import { useProjectProgressContext} from "../context/ProjectProgressContext";



const HeaderContainer = () => {
  const dispatch = useDispatch();
  const { isDataUpdated, setBlockAssetChange } = useProjectProgressContext();
  const state = useSelector((state) => state.projectProgress);
  const [currentVersion, setCurrenVersion] = useState(null)
  useEffect(() => {
    if(!!state.currentVersion && !!state.currentVersion.ident){
      setCurrenVersion(state.currentVersion)
    }
  }, [state.currentVersion])
  

  const isComparisionAvailable = useMemo(() => state.versions?.filter(
    (f) => f.ident !== currentVersion?.ident
  ).length > 0, [state.versions,currentVersion])

  return (
    <>
        <Box className={"h-100 w-100"}>
          <Box
            className="d-flex align-item-center justify-content-start"
            style={{ height: 50 }}
          >
            <div style={{ width: "25%" }}>
              <AssetSwitcher
                dirtyState={isDataUpdated()}
                setBlockState={(val) => {
                     setBlockAssetChange({
                    block: true,
                    newAsset: val,
                  })
                }}
              />
            </div>
            {!!currentVersion && 
            <div style={{ width: "75%" }} className={"p-1"}>
              <AppBar
                className={"appBar"}
                style={{
                  zIndex: 2000,
                  height: 40,
                  width: "100%",
                  borderRadius: 4,
                }}
              >
                <Toolbar className={"dialog-toolbar"} style={{ height: 40 }}>
                  <div className="d-flex align-items-center justify-content-start flex-wrap">
                    {getTranslationVariantText("version", "body2", {
                      color: "#ffffff",
                    })}
                    <>
                    {
                      getVariantText(
                        ` : ${currentVersion?.versionName}`,
                        "body2",
                        { color: "#ffffff" }
                      )}
                    </>
                  </div>
                   {isComparisionAvailable && (
                    <FormGroup style={{ marginLeft: "auto" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{
                              color: state.isCompareMode ? "#244a9a" : "#ffffff",
                            }}
                            checked={state.isCompareMode}
                            onChange={(e) =>dispatch(setCompareMode(e.target.checked))}
                          />
                        }
                        label={getLabel("comparison mode option", "body2", {
                          color: "#ffffff",
                        })}
                        labelPlacement="start"
                      />
                    </FormGroup>
                  )}
                </Toolbar>
              </AppBar>
              </div>
            }
          </Box>
        </Box>
    </>
  );
};

export default HeaderContainer;
