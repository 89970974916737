import React from 'react';
import { globalRoot } from "../../../utils/defaultValues";
import IntlMessages from "../../../utils/IntlMessages";
import DocumentScannerSharpIcon from '@mui/icons-material/DocumentScannerSharp';
import PreviewDocumentHome from './PreviewDocumentHome';
import ChatWithPdf from '../../chatwithpdf/ChatWithPdf';  // Correct path to ChatWithPdf component

const PreviewDocumentRouteConfig = {
  routes: [
    {
      id: "preview-document",
      path: `${globalRoot}/preview-document/`,
      exact: false,  // Make sure exact is false to allow nested routes
      showBreadCrumbs: true,
      name: <IntlMessages id={"menu.preview-document"} />,
      element: <PreviewDocumentHome />,  // This is the main preview component
      isParent: true,
      children: [
      ],
    },
    {
      path: `${globalRoot}/preview-document/chat/:pdfLink`,  // Add the dynamic parameter for the PDF link here
      element: <ChatWithPdf />,  // This is where we display the chat and PDF viewer
      id: "preview-project-list",
      parentPath: `${globalRoot}/preview-document`,
      name: <IntlMessages id={"menu.chat"}/>,
      showBreadCrumbs: true,
    }
    
  ],
};

export default PreviewDocumentRouteConfig;
