import { createContext, useContext, useEffect, useState } from "react";
import useAssetListHook from "../../../component-bundle/hooks/useAssetListHook";
import { useDispatch, useSelector } from "react-redux";
import useChapterHook from "../report-component/hooks/useChapterHook";
import useProjectUsersHook from "../report-component/hooks/useProjectUsers";
import useSaveContentFireBase from "../report-component/hooks/firebase/useSaveContentHook";
import { resetCommentListState, setSelectedCommentNode } from "../../../component-bundle/comments/commentSlice";


export const ReviewContext = createContext();

export const useReviewContextHook = () => {
  const context = useContext(ReviewContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

const appendPathToNodes = (
  node,
  parent = null,
  parentPath = "",
  order = 0
) => {
  let newNode = { ...node };
  let currentPath = "";
  if (!node.nodeType.includes("data-")) {
    currentPath = `${parentPath ? parentPath + "/" : ""}${node.ident}`;
  }

  if (node.nodeType.includes("data-")) {
    newNode = {
      ...newNode,
      node: { ...newNode.node, path: parentPath, order: order , timestamp: Date.now()},
    };
  }

  if (node.children && node.children.length > 0) {
    newNode.children = node.children.map((child, index) =>
      appendPathToNodes(child, node, currentPath, index + 1)
    );
  }

  return newNode;
};

export const ReviewContextProvider = ({ report, selectedComment, children }) => {
  const [selectedAsset,setSelectedAsset] = useState({})
  const [assets, setAssetList] = useState([]);
  const [currentChapter, setCurrentChapter] = useState({});
  const [selectedIndex, setSelectedIndex] = useState({});
  const [selectedItem, setSelectedItem] = useState({id:"" });
  const [reportType, setReportType] = useState(report);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const { assetList, fetchList } = useAssetListHook();
  const dataSlice = useSelector((state) => state.dataSlice);
  const { chapterList, onLoadAllChapters } = useChapterHook({
    reportType: reportType,
  });
  const [documentItems, setDocumentItems] = useState({
    node: "document",
    nodeType: "document-root",
    assetIdent:null,
    children: [],
  });

  const {releaseUserLocks} = useSaveContentFireBase({});

  const { users } = useProjectUsersHook({
    isFetching: true,
  }
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (chapterList.length > 0) {
      let chapterIndex = 0;
      const headers = chapterList
        .map((m) => m.reportDataNode)
        .map((c) => {
          if (c.header) {
            chapterIndex = chapterIndex + 1;
            c = { ...c, index: chapterIndex };
          } else {
            c = { ...c, index: null };
          }
          const header = appendPathToNodes(c);
          return { ...c, id: c.ident, children: header.children };
        });

      setDocumentItems((prev) => ({
        ...prev,
        assetIdent:openAsset.ident,
        children: [...headers],
      }));
      setLoading(false);
    }
  }, [chapterList,openAsset.ident]);

  const getPath = () => reportType ==="redflag" ? "red-flag" :"full-report"

  useEffect(() => {
    if(assetList.isFetchSuccess && assetList.assets.length > 0){
      setAssetList(assetList.assets);
    } else if(assetList.isFetchSuccess && assetList.assets.length <= 0) {
      setAssetList([]);
     }
      else{
      fetchList();
     }
  }, [assetList]);

  useEffect(() => {
    if (!!openAsset && !!openAsset.ident) {
      setSelectedAsset((prev) => {
        if(!!prev.ident){
          releaseUserLocks(scope.projectIdent,prev.ident,reportType);
        }
        return openAsset;
      });
      setLoading(true);
      onLoadAllChapters(reportType);
      setSelectedItem({});
      dispatch(resetCommentListState());
      setDocumentItems((prev) => ({
        ...prev,
        assetIdent:openAsset.ident,
        children: [],
      }));
    }
  }, [
    openAsset.ident,
    reportType,
    scope.projectIdent,
    scope.projectLanguageCode,
  ]);

  const onChapterSelection = (chapter) => {
    setCurrentChapter(chapter);
  };

  const onSelectedIndex = (selectedIndex) => {
    setSelectedIndex((prev) => (prev !== selectedIndex ? selectedIndex : prev));
  };

  const onSelectedItem = (selected) => {
    setSelectedItem(selected);
  };

  const getUpdatedRows = () => dataSlice?.data?.filter((f) => f.updated) || [];


  return (
    <ReviewContext.Provider
      value={{
        openAsset,
        assets,
        reportType,
        currentChapter,
        selectedIndex,
        selectedItem,
        documentItems,
        loading,
        setLoading,
        onSelectedItem,
        onChapterSelection,
        onSelectedIndex,
        getUpdatedRows,
        selectedComment,
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
};
