import React from "react";
import ProjectDashboardContainer from "./structure/ProjectDashboardContainer";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getProject, updateProjectUsers } from "../../../api/openProjectApi";
import { setProjectClose } from "../../../layout/app-container/containerSlice";
import { resetOpenProjectState, resetState } from "./projectDashboardSlice";
import { useNavigate } from "react-router-dom";
import { resetErrorState } from "../../../error/errorHandlingSlice";
import { useSnackbar } from "notistack";
import { getUsers } from "../../../api/userManagementApi";
import { resetState as resetUserState } from "../users/usersSlice";
import { createProject } from "../../../api/createProjectApi";
import { resetState as createProjectReset } from "../create-project/createProjectSlice";
import { setProjectOpen } from "../../../layout/app-container/containerSlice";

const ProjectDashboardHome = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const createState = useSelector((state) => state.createProject);
  const state = useSelector((state) => state.projectDashboard);
  const errorState = useSelector((state) => state.errorhandling);
  const usersState = useSelector((state) => state.users);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [projectData, setprojectData] = useState({});
  const [userPermission, setUserPermission] = useState({
    delete: false,
    update: false,
    read: false,
    create: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (scope.projectIdent !== null && scope.projectIdent !== "") {
      dispatch(getProject({
        projectIdent:scope.projectIdent,
        api:scope.api
      }));
      dispatch(getUsers());
    }
  }, [dispatch, scope.api, scope.projectIdent]);

  useEffect(() => {
    if (
      createState.createProject.type === "SUCCESS" ||
      state.project.type === "SUCCESS"
    ) {
      let messages =  state.project.type === "SUCCESS" ? state.project.messages : createState.createProject.messages;
     messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
      dispatch(createProjectReset());
      dispatch(getProject({
        projectIdent:scope.projectIdent,
        api:scope.api}
        ));
    }
    if (state.project.type === "FETCHED" && !!state.project.data) {
      setprojectData(state.project.data);
      dispatch(
        setProjectOpen({
          ...scope,
          projectLanguageCode: !!state.project.data.languageCode
          ? state.project.data.languageCode
          : "de",
        })
        );
      if (
        state.project.data.currentUserPermissionsDto !== undefined &&
        state.project.data.currentUserPermissionsDto !== null
      ) {
        setUserPermission(state.project.data.currentUserPermissionsDto);
        dispatch(resetState());
      }
    } else if (
      state.project.type === "ERROR" ||
      state.project.type === "ACCESS_DENIED" ||
      state.project.type === "DELETED" ||
      (errorState.hasErrors && errorState.responseError)
    ) {
      dispatch(setProjectClose());
      dispatch(resetOpenProjectState());
      navigate(`${props.routePrefix}/project-list`);
    }
  }, [createState, dispatch, enqueueSnackbar, errorState, navigate, props.routePrefix, scope, state.project]);

  useEffect(() => {
    if (usersState.userList.type === "FETCHED" && !!usersState.userList.data) {
      let temp = usersState.userList.data.map((user) => {
        return {
          title: `${user.firstName} ${user.lastName}`,
          ...user,
        };
      });
      setUsers(temp);
      dispatch(resetUserState());
    }
  }, [dispatch, usersState.userList]);

  const updateProject = (project) => {
    dispatch(createProject({
      api:scope.api,
      data:project
    }));
  };

  const updateUsers = (users) => {
    dispatch(updateProjectUsers({
      users:users,
      api:scope.api
    }));
  };

  return (
    <Box style={{width:"100%"}}>
        <ProjectDashboardContainer
          data={projectData}
          serviceLine={props.serviceLine}
          users={users}
          updateProject={updateProject}
          updateProjectUsers={updateUsers}
          userPermission={userPermission}
        />
    </Box>
  );
};

ProjectDashboardHome.propTypes = {
  serviceLine: PropTypes.string,
  routePrefix: PropTypes.string,
  onProjectCreate: PropTypes.func,
};
export default ProjectDashboardHome;
