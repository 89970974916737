import React from "react";
import {
  TypeHeaderTreeRender,
  TypeHeadersDataRender,
  TypeHeadersPreviewRender,
} from "./items/TypeHeaders";
import {
  TypeCostSummaryDataRender,
  TypeCostSummaryPreviewRender,
} from "./items/TypeSummaryCostDataTable";
import {
  TypeDefectTableDataRender,
  TypeDefectTablePreviewRender,
} from "./items/defect-table/TypeDefectTable";
import {
  TypeBuildingProfileDataRender,
  TypeBuildingProfilePreviewRender,
} from "./items/building-profile/TypeBuildingProfile";
import {
  TypeContactCardsDataRender,
  TypeContactCardsPreviewRender,
} from "./items/contact-cards/TypeContactCards";
import {
  TypeImageContainerDataRender,
  TypeImagesPreviewRender,
} from "./items/images/TypeImages";
import {
  TypeImageTableDataRender,
  TypeImageTablePreviewRender,
} from "./items/images/TypeImageTable";
import {
  TypePropertyTableDataRender,
  TypePropertyTablePreviewRender,
  TypePropertyTableTreeRender,
} from "./items/text-editors/TypeTextWithLabel";
import {
  TypeTextDataRender,
  TypeTextPreviewRender,
} from "./items/text-editors/TypeText";
import {
  TypeProjectInformationDataRender,
  TypeProjectInformationPreviewRender,
} from "./items/project-information/TypeProjectInformation";

export const renderNode = (node, selectedItem, index) => {
  switch (node.nodeType) {
    case "document-root":
      return (
        <TypeHeaderTreeRender
          item={node}
          selectedItem={selectedItem}
          index={index}
        />
      );
    case "header-chapter":
      return (
        <TypeHeaderTreeRender
          item={node}
          selectedItem={selectedItem}
          index={index}
        />
      );
    case "header-section":
      return (
        <TypeHeaderTreeRender
          item={node}
          selectedItem={selectedItem}
          index={index}
        />
      );
    case "header-subsection":
      return (
        <TypeHeaderTreeRender
          item={node}
          selectedItem={selectedItem}
          index={index}
        />
      );
    case "data-property_table":
      return (
        <TypePropertyTableTreeRender
          item={node}
          selectedItem={selectedItem}
          index={index}
        />
      );
    default:
      return (
        <TypeHeaderTreeRender
          item={node}
          selectedItem={selectedItem}
          index={index}
        />
      );
  }
};

export const dataRender = (node, reportType, selectedItem) => {
  switch (node.nodeType) {
    case "document-root":
      return <TypeHeadersDataRender item={node} />;
    case "header-chapter":
      return <TypeHeadersDataRender item={node} />;
    case "header-section":
      return <TypeHeadersDataRender item={node} />;
    case "header-subsection":
      return <TypeHeadersDataRender item={node} />;
    case "data-table_project_information":
      return (
        <TypeProjectInformationDataRender item={node} selectedItem={selectedItem} reportType={reportType} />
      );
    case "data-property_table":
      return (
        <TypePropertyTableDataRender item={node} reportType={reportType} />
      );
    case "data-text":
      return <TypeTextDataRender item={node} reportType={reportType} />;
    case "data-table-cost-summary":
      return <TypeCostSummaryDataRender item={node} reportType={reportType} />;
    case "data-defect-table":
      return <TypeDefectTableDataRender item={node} selectedItem={selectedItem} reportType={reportType} />;
    case "data-table-building-profile":
      return (
        <TypeBuildingProfileDataRender item={node} reportType={reportType} selectedItem={selectedItem}/>
      );
    case "data-contact-cards":
      return <TypeContactCardsDataRender item={node} reportType={reportType} />;
    case "data-image_container":
      return (
        <TypeImageContainerDataRender item={node} reportType={reportType} />
      );
    case "data-image_table":
      return <TypeImageTableDataRender item={node} reportType={reportType} />;
  }
};

export const renderPreviewNode = (node, reportType) => {
  switch (node.nodeType) {
    case "document-root":
      return <TypeHeadersPreviewRender item={node} />;
    case "header-chapter":
      return <TypeHeadersPreviewRender item={node} />;
    case "header-section":
      return <TypeHeadersPreviewRender item={node} />;
    case "header-subsection":
      return <TypeHeadersPreviewRender item={node} />;
    case "data-table_project_information":
      return (
        <TypeProjectInformationPreviewRender
          item={node}
          reportType={reportType}
        />
      );
    case "data-property_table":
      return (
        <TypePropertyTablePreviewRender item={node} reportType={reportType} />
      );
    case "data-text":
      return <TypeTextPreviewRender item={node} reportType={reportType} />;
    case "data-table-cost-summary":
      return (
        <TypeCostSummaryPreviewRender item={node} reportType={reportType} />
      );
    case "data-defect-table":
      return (
        <TypeDefectTablePreviewRender item={node} reportType={reportType} />
      );
    case "data-table-building-profile":
      return (
        <TypeBuildingProfilePreviewRender item={node} reportType={reportType} />
      );
    case "data-contact-cards":
      return (
        <TypeContactCardsPreviewRender item={node} reportType={reportType} />
      );
    case "data-image_container":
      return <TypeImagesPreviewRender item={node} reportType={reportType} />;
    case "data-image_table":
      return (
        <TypeImageTablePreviewRender item={node} reportType={reportType} />
      );
  }
};
