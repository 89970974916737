import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  tab: {},
  
};

const photoDocumentationSlice = createSlice({
  name: "photoDocumentation",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      data: payload,
      grid: [],
    }),
    setTab: (state, { payload }) => ({
      ...state,
      tab: payload,
    }),
    resetState: (state) => ({
      ...initialState,
      tab: state.tab,
      optionalHeaders: state.optionalHeaders,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetState,
  setTab
} = photoDocumentationSlice.actions;

// A selector
export const photoDocumentationSelector = (state) => state.photoDocumentationS;

// The reducer
export default photoDocumentationSlice.reducer;
