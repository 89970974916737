import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import IntlMessages from "../../../utils/IntlMessages";
import { Typography } from "@mui/material";

const CustomIconButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const { icon, tooltip, label, color, ...restProps } = props;
  const state = useSelector((state) => state.readOnly);

  useEffect(() => {
    if (state.isReadOnly) {
      setReadOnly(state.isReadOnly);
    }
  }, [state]);

  const checkPermission = () => {
    if (!!props.permission) {
      return (
        <>
          {props.permission.create && props.permission.update ? (
            <>
            <IconButton
              style={{ ...props.style }}
              id={props.id}
              className={props?.className}
              onClick={(e) => props.handleClick(e)}
              {...restProps}
            >
              <Tooltip title={props.tooltip.title}>{props.icon}</Tooltip>
            </IconButton>
            {!!props.label && (
              <Typography variant="caption" style={{ color: props.color ,...props.labelStyle}} className="w-100 d-flex align-items-center justify-content-center text-center">
                <IntlMessages id = {props.label}/>
              </Typography>
            )}
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <>
        <IconButton
          style={{ ...props.style }}
          id={props.id}
          className={props?.className}
          onClick={(e) => props.handleClick(e)}
          {...restProps}
        >
          <Tooltip title={props.tooltip.title}>{props.icon}</Tooltip>
        </IconButton>
        {!!props.label && (
          <Typography variant="caption" style={{ color: props.color ,...props.labelStyle}} className="w-100 d-flex align-items-center justify-content-center text-center">
            <IntlMessages id = {props.label}/>
          </Typography>
          
        )}
        </>
      );
    }
  };

  return <>{!readOnly && checkPermission()}</>;
};

CustomIconButton.defaultProps = {};
CustomIconButton.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.element,
  permission: PropTypes.object,
  handleClick: PropTypes.func,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default CustomIconButton;
