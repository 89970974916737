import React from 'react';
import Box from '@mui/material/Grid';
import { tddServiceLine } from '../../../../utils/defaultValues';
import ProjectListHome from '../../../component-bundle/project-list/ProjectListHome';

const TddProjectListHome = () => {
  return (
    <Box
      style={{
        background: '#ffffff',
        height: '100%',
        padding: '10px',
        borderRadius: 4,
      }}
    >
      <ProjectListHome serviceLine={tddServiceLine} />
    </Box>
  );
};
export default TddProjectListHome;
