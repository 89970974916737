import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CommentIcon from "@mui/icons-material/Comment";
import {
  getIntlTranslation,
  getStylingForSelectedComment,
} from "../../../../../../utils/helper";
import RenderTextEditor from "./TextEditor";
import useChapterHook from "../../hooks/useChapterHook";
import CommentsPopup from "../../comments/CommentsPopup";
import useCommentFetchHook from "../../comments/useCommentFetchHook";
import useCommentSaveHook from "../../../../../component-bundle/hooks/useCommentSaveHook";
import { useSnackbar } from "notistack";
import useSaveContentFireBase from "../../hooks/firebase/useSaveContentHook";
import useFetchContentFireBase from "../../hooks/firebase/useFetchContentHook";
import { EditingIndicator } from "../EditingIndicator";

import { useSelector } from "react-redux";

export const TypePropertyTableTreeRender = ({ item, selectedItem }) => {
  return (
    <Typography
      variant="subtitle2"
      style={{
        color: selectedItem?.id === item.id ? "#ffffff" : "#254a9a",
        fontWeight: selectedItem?.id === item.id ? "bold" : "normal",
      }}
      className="p-1"
    >
      {item?.label}
    </Typography>
  );
};

export const RenderPreviewTextEditor = ({ node, reportType }) => {
  const [nodeData, setNodeData] = useState(node);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const { enqueueSnackbar } = useSnackbar();
  const [commentPopup, setCommentPopup] = useState({
    open: false,
    node: null,
    id: null,
    uniqueIdentifier: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setEditing] = useState({
    id: null,
    editing: false,
  });
  const apiParam = "report-text";
  const dataRef = useRef(node);
  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType
  );
  const [onCommentsSave] = useCommentSaveHook();
  const { onReportTextSave } = useChapterHook({
    reportType,
  });

  const { onSave, onEdit } = useSaveContentFireBase({
    apiParam,
    data: {
      ident: node.ident,
      path: node.path,
      properties: [node],
      timestamp: node.timestamp,
    },
    reportType,
  });
  const { updatedNode, setUpdatedNode, locked, getUser } = useFetchContentFireBase({
    nodePath: `${apiParam}-${node.ident}`,
    reportType,
  });

  const lockCheck = (locked) => {
    return (
      !!locked?.lockedId &&
      locked.isLocked &&
      locked.lockedId === `${apiParam}-${dataRef.current.ident}` &&
      scope.projectIdent === locked?.projectIdent &&
      openAsset.ident === locked?.assetIdent &&
      reportType === locked?.reportType &&
      locked?.lockedBy.id !== getUser().id
    );
  };

  const processSaveComments = (value) => {
    if (!!dataRef.current.comment) {
      let tempCommentsWrapper = {
        ...dataRef.current.comment,
        apiParam: apiParam,
        scrollParent: "scrollParent",
        reportType: reportType,
        section: `${node.label}-${node.ident}`,
      };
      const comments = [];
      comments.push({
        ...value,
        reportType: reportType,
        parentPath: dataRef.current.path,
        order: commentPopup?.node?.order,
        assetIdent: openAsset.ident,
      });
      onCommentsSave({
        commentsApi: tempCommentsWrapper.apiParam,
        data: { ...tempCommentsWrapper, commentValues: comments },
        apiIdent: dataRef.current.ident,
      });
    }
  };

  useEffect(() => {
    if (
      !isEditing.editing &&
      updatedNode &&
      `${apiParam}-${dataRef.current.ident}` === updatedNode?.ident &&
      scope.projectIdent === updatedNode?.projectIdent &&
      openAsset.ident === updatedNode?.assetIdent &&
      reportType === updatedNode?.reportType
    ) {
      const tempNode = Object.values(updatedNode.node)[0];
      
      if (openAsset.ident === tempNode.assetIdent) {
        dataRef.current = { ...tempNode, text: tempNode.value };
        setNodeData({ ...tempNode, text: tempNode.value });
        setUpdatedNode(null);
      }
    }
  }, [isEditing.editing, updatedNode, scope, openAsset]);

  useEffect(() => {
    if (
      isCommentSaved.saved &&
      dataRef.current.uniqueIdentifier === commentPopup.uniqueIdentifier
    ) {
      dataRef.current.comment = {
        ...dataRef.current.comment,
        ident: isCommentSaved.commentsIdent,
      };
      isCommentSaved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      setCommentPopup({
        open: false,
        node: null,
        id: null,
        uniqueIdentifier: null,
      });
      resetState();
    }
  }, [isCommentSaved, commentPopup.uniqueIdentifier, enqueueSnackbar, resetState]);

  const actionButtons = (node, locked) => {
    return (
      <>
        {node.userDefined && (
          <Box
            className="d-flex flex-column align-baseline justify-content-between flex-wrap flex-row g-btn-secondary rounded-3"
            style={{ width: 40, position: "sticky", top: 10 }}
          >
            {!lockCheck(locked) && (
              <Tooltip title={getIntlTranslation("tooltip.edit")}>
                <IconButton
                  id={`edit_${node.id}`}
                  onClick={(event) => {
                    event?.stopPropagation();
                    setEditing({ id: node.ident, editing: true });
                    onEdit(apiParam, node.ident, reportType, true);
                  }}
                >
                  <ModeEditIcon style={{ fontSize: 18, color: "#244a9a" }} />
                </IconButton>
              </Tooltip>
            )}

            <Divider
              orientation="vertical"
              style={{
                width: 2,
                backgroundColor: "#ffffff",
              }}
              flexItem
            />
            <Tooltip title={getIntlTranslation("tooltip.add-comments")}>
              <IconButton
                id={`comment_${node.ident}`}
                aria-describedby={`pop-comment-${node.ident}`}
                onClick={(event) => {
                  setCommentPopup({
                    open: true,
                    node: node,
                    id: `pop-comment-${node.ident}`,
                    uniqueIdentifier: `${apiParam}-${node.ident}`,
                  });
                  setAnchorEl(event.currentTarget);
                  dataRef.current.uniqueIdentifier = `${apiParam}-${node.ident}`;
                }}
              >
                <CommentIcon style={{ fontSize: 18, color: "#d08f8e" }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <ListItem
        className="p-1 action-item w-100 "
        id={`scroll_item_${nodeData.ident}`}
        style={getStylingForSelectedComment(
          isCommentSelected()?.propertyIdent,
          nodeData.ident
        )}
      >
        <Box
          className={`d-flex align-items-baseline w-100 flex-wrap p-1 box ${
            isEditing.editing ? "elevated p-1 rounded-3" : ""
          }
            ${!isEditing.editing && lockCheck(locked) ? "locked-indicator" : ""}
          `}
          key={node.ident}
          style={{ marginTop: 20 }}
        >
          <Box className="d-flex flex-column" style={{ width: "25%" }}>
            <Typography
              style={{
                fontSize: 12,
                color: "#254a9a",
              }}
            >
              {node.label}
            </Typography>
          </Box>
          {!nodeData.text && !isEditing.editing ? (
            <Box className="d-flex flex-column" style={{ width: "75%" }}>
              <Box className="d-flex align-items-baseline w-100 p-1 h-auto">
                <Chip
                  label={
                    <Typography
                      style={{
                        fontSize: 14,
                        width: "95%",
                        marginRight: 5,
                        textWrap: "wrap",
                      }}
                      className="text-danger m-1"
                      variant="subtitle2"
                    >
                      {getIntlTranslation("_no-data-available")}
                    </Typography>
                  }
                  color="error"
                  variant="outlined"
                  style={{ width: "95%", marginRight: 5 }}
                />
                {actionButtons(nodeData, locked)}
              </Box>
            </Box>
          ) : (
            <>
              {isEditing.editing && isEditing.id === nodeData.ident ? (
                <Box
                  className="d-flex align-items-baseline"
                  style={{
                    minHeight: "200px",
                    width: "100%",
                  }}
                >
                  <RenderTextEditor
                    node={nodeData}
                    onSave={(data) => onReportTextSave(data, reportType)}
                    inReviewMode={true}
                    onSaveSuccess={(node) =>
                      onSave(
                        apiParam,
                        {
                          ident: node.ident,
                          path: dataRef.current.path,
                          properties: [node],
                        },
                        reportType
                      )
                    }
                    onClose={(node) => {
                      setEditing({
                        id: null,
                        editing: false,
                      });
                      onEdit(apiParam, node.ident, reportType, false);
                    }}
                  />
                </Box>
              ) : (
                <Box className="d-flex flex-column" style={{ width: "75%" }}>
                  <Box className="d-flex align-items-baseline" style={{ width: "100%" }}>
                    <Typography
                      style={{
                        fontSize: 14,
                        width: "95%",
                        textAlign: "justify",
                        padding: 5,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: nodeData.value,
                      }}
                      className="label-text review-data"
                    ></Typography>
                    {actionButtons(nodeData, locked)}
                  </Box>
                </Box>
              )}
            </>
          )}
          {!isEditing.editing && lockCheck(locked) && <EditingIndicator user={locked.lockedBy} />}
        </Box>
      </ListItem>
      {commentPopup.open && !!commentPopup.node && (
        <CommentsPopup
          id={commentPopup.id}
          anchorEl={anchorEl}
          onClose={() => {
            setCommentPopup({
              open: false,
              node: null,
              id: null,
              uniqueIdentifier: null,
            });
            setAnchorEl(null);
          }}
          node={{
            ...commentPopup.node,
            nodeIdent: commentPopup.node.ident,
            nodeKey: commentPopup.node.label,
          }}
          onSave={(value) => {
            processSaveComments(value);
          }}
        />
      )}
    </>
  );
};

export const TypePropertyTablePreviewRender = ({ item, reportType }) => {
  const node = item.node;
  return (
    <>
      <List className="w-100 p-0">
        {node?.propertyNodes?.map((m, index) => {
          return (
            <RenderPreviewTextEditor
              node={{
                ...m,
                text: m.value,
                path: node.path,
                timestamp: node.timestamp,
              }}
              key={index}
              reportType={reportType}
            />
          );
        })}
      </List>
    </>
  );
};

export const TypePropertyTableDataRender = ({ item, reportType }) => {
  const node = item.node;
  const apiParam = "report-text";
  const { onSave } = useSaveContentFireBase({});
  const { onReportTextSave } = useChapterHook({
    reportType,
  });
  return (
    <Box className="w-100 d-flex flex-column">
      {!!node &&
        node?.propertyNodes?.map((pNode) => (
          <RenderTextEditor
            node={{ ...pNode, text: pNode.value }}
            onSave={(data) => onReportTextSave(data, reportType)}
            onSaveSuccess={(savedNode) => {
              onSave(
                apiParam,
                {
                  ident: savedNode.ident,
                  path: node.path,
                  properties: [savedNode],
                },
                reportType
              );
            }}
          />
        ))}
    </Box>
  );
};
