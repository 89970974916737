import { useMemo } from 'react';

// Custom React Hook
function useChartData(data, groupByArray, operation, chartType) {
    return useMemo(() => {
      // Helper function to group data by multiple properties
      const groupByMultiple = (objectArray, keys) => {
        return objectArray.reduce((acc, obj) => {
          const key = keys.map((key) => obj[key]).join('|');
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      };
  
      // Group data by the specified properties
      const groupedData = groupByMultiple(data, groupByArray);
      console.log(groupedData);
      // Perform the specified operation (e.g., count, sum)
      const chartData = Object.entries(groupedData).map(([group, groupItems]) => {
        let result;
        switch (operation) {
          case 'count':
            result = groupItems.length;
            break;
          case 'sum':
            result = groupItems.reduce((sum, item) => sum + item.totalCost, 0);
            break;
          // Add more operations as needed
          default:
            result = null;
        }
  
        return {
          group,
          value: result,
        };
      });
  
      // Sort chart data by group
      chartData.sort((a, b) => a.group.localeCompare(b.group));
  
      return chartData;
    }, [data, groupByArray, operation]);
  }
  
// function useChartData(data, groupByArray) {
//   // Create an empty object to store the grouped data
//   const groupedData = {};

//   // Iterate through the data array and group by the specified properties
//   data.forEach((item) => {
//     // Generate a key for the group based on the specified properties
//     const groupKey = groupByArray.map((prop) => item[prop]).join('|');

//     // Initialize the group if it doesn't exist
//     if (!groupedData[groupKey]) {
//       groupedData[groupKey] = {
//         group: groupKey,
//         counts: {},
//       };
//     }

//     // Count the occurrences of each group
//     const group = groupedData[groupKey];
//     group.counts[groupKey] = (group.counts[groupKey] || 0) + 1;
//   });

//   // Convert the groupedData object into an array of results
//   const result = Object.values(groupedData);

//   return result;
// }
  export default useChartData;
