import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelectedProperty } from '../context/SelectedPropertyContext';
import { useSelector } from 'react-redux';
import { Box, Card, CardActions, CardContent, CardMedia, Drawer, List } from '@mui/material';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import SaveButton from '../../../../../components/buttons/SaveButton';
import { getTranslationVariantText, getIntlTranslation } from '../../../../../utils/helper';
import { useProjectProgressContext } from '../context/ProjectProgressContext';
import ProjectProgressDataEditDialog from './ProjectProgressDataEditDialog';
import CustomButton from '../../../../../components/buttons/CustomButton';
import RenderItemProgress from './RenderItemProgress';
import { usePropertiesData } from '../context/PropertiesDataContext';

const ProgressCurrentVersionContainer = () => {
const state = useSelector((state) => state.projectProgress);
const {onSave,subAssetList} = useProjectProgressContext();
const { selectedProperty, getRisk, getStatus} = useSelectedProperty();
const { structuredProperties } = usePropertiesData();
const [inputValue, setInputValue] = useState({});
const [selectedStructuredProperty, setSelectedStructuredProperty] = useState({});

useEffect(() => {
  if (selectedProperty && !!structuredProperties.nodes) {
    const subAssets =
      structuredProperties.nodes[selectedProperty.nodeIdent]?.subAssets;
    if (!!subAssets) {
      setInputValue({ subAssets: subAssets });
    }

    setSelectedStructuredProperty(structuredProperties.nodes[selectedProperty.nodeIdent])
  }
}, [selectedProperty, structuredProperties]);

const [edit, setEdit] = useState({
    open: false,
    subAsset: null,
    selected: null,
  });

  const handleDelete = (node) => {
    const temp = {
      ...node.original,
      deleted: true,
    };

    const selected = {...selectedProperty.original,  mapProgressDtos: [{ ...temp }],};
    onSave(selected);
  };

  const getRows = () => {
    if(!!selectedStructuredProperty && Object.keys(selectedStructuredProperty.subAssets).length > 0){
     return Object.keys(selectedStructuredProperty.subAssets).map((m) => ({...selectedStructuredProperty.subAssets[m], status:getStatus(selectedStructuredProperty.subAssets[m].status) ,risk:getRisk(selectedStructuredProperty.subAssets[m].risk)}));
    }
    return []
  }
  

  return (
   <Box style={{ width: state.isCompareMode ? "50%" : "100%", height: "100%" }}>
     <Card className="w-100 h-100 d-flex flex-column">
        {!!selectedStructuredProperty && 
        <>
    <CardContent className="w-100 overflow-x-hidden overflow-y-auto custom-scrollBar p-1" style={{height:"calc(100% - 60px"}}>
    {!!inputValue.subAssets && Object.keys(inputValue.subAssets).length > 0 ?
          <List
                className="p-1 w-100 custom-scrollBar mt-1 h-100"
              >
                {Object.keys(inputValue.subAssets).map((m) => {
                  return (<RenderItemProgress 
                    data={inputValue.subAssets[m]} 
                    selectedProperty={selectedProperty} 
                    onEdit={(subAsset) => {
                      setEdit({
                        open: true,
                        subAsset: subAsset,
                        selected: selectedProperty.original,
                      });
                    }}
                    onDelete={(node) =>handleDelete(node)}
                    />)
                })}
              </List>
              :
              <Box
              className="d-flex align-items-center justify-content-start w-100"
              style={{ padding: 5 }}
            >
              <>
                <Box style={{ width: "25%" }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 100 }}
                    image="/assets/images/no_data_3.svg"
                    alt="Live from space album cover"
                  />
                </Box>
                <Box style={{ width: "70%" }}>
                  <CustomButton
                    id={`create_data_properties_01`}
                    buttonText={"no_data_click_start"}
                    buttonColor={"inherit"}
                    textVariant={"caption"}
                    style={{
                      margin: 5,
                      padding: 10,
                      width: "100%",
                    }}
                    tooltip={{
                      title: getIntlTranslation("tooltip.add-custom"),
                    }}
                    size="small"
                    className="btn"
                    handleClick={(e) =>
                      setEdit({
                        open: true,
                        subAsset: {},
                        selected: selectedProperty.original,
                      })
                    }
                  />
                </Box>
              </>
            </Box>
    } 
    </CardContent>
     <CardActions
     style={{ height: 50, marginTop: "auto" }}
     className="g-btn-secondary p-1"
   >
    {state.isCompareMode && (
       <Box
         className={
           "d-flex align-items-center justify-content-start m-1 h-100"
         }
       >
         <DoubleArrowIcon style={{ color: "#5f9ea0", fontSize: 20 }} />
         {getTranslationVariantText("current version", "body1", {
           fontWeight: "bold",
           color: "#254a9a",
           marginLeft: 5,
         })}
       </Box>
     )}
      <SaveButton
        id={"create_new_data"}
       buttonText="add"
       variant="button"
       style={{ marginLeft: state.isCompareMode ? "auto" : 0, width:state.isCompareMode ? "25%" : "100%" }}
       tooltip={{
         title: getIntlTranslation("tooltip.add"),
       }}
       handleClick={(e) =>  {
        setEdit({
        open: true,
        subAsset: {},
        selected: selectedProperty.original,
      })}}
     />
 </CardActions>
 </>
    }
    </Card>
    {edit.open && (
        <Drawer
          anchor={"right"}
          open={edit.open}
          style={{ borderRadius: 4 }}
          className="w-100 rounded-2"
        >
          <Box style={{ width: "700px" }} className="h-100" role="presentation">
            <ProjectProgressDataEditDialog
              subAssetList={subAssetList}
              editSubAsset={edit.subAsset}
              selected={edit.selected}
              rows={getRows()}
              onAdd={(data) => onSave(data)}
              onClose={() =>
                setEdit({
                  open: false,
                  subAsset: null,
                  selected: null,
                })
              }
            />
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default ProgressCurrentVersionContainer