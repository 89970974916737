import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  getDefect:{
    type: "",
      messages: null,
      data: null,
  },
  copyDefect:{
    type: "",
      messages: null,
      data: null,
  },
  multiUpdate:{
    type: "",
      messages: null,
      data: null,
  },
  saveDefect:{
    type: "",
    messages: null,
    data: null,
  },
  error: {},
};
// A slice for auth with our success or failure reducers
const defectSlice = createSlice({
  name: "tddDefectSection",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    resetSliceState: (state) => ({
      ...initialState
    }),
     getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
    getDefectSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      getDefect:{
        type: payload.type,
          messages: payload.messages,
          data: payload.guruDto,
      }
    }),
    getDefectCopySuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      copyDefect:{
        type: payload.type,
          messages: payload.messages,
          data: payload.guruDto,
      }
    }),
    getMultipleUpdateSuccess :(state, { payload }) => ({
      ...state,
      hasErrors: false,
      multiUpdate:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      }
    }),
    getDefectSaveSuccess:(state, { payload }) => ({
      ...state,
      hasErrors: false,
      saveDefect:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      }
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetSliceState,
  getDefectSuccess,
  getDefectCopySuccess,
  getSuccess,
  getMultipleUpdateSuccess,
  getDefectSaveSuccess,
} = defectSlice.actions;

// A selector
export const defectSelector = (state) => state.defect;

// The reducer
export default defectSlice.reducer;
