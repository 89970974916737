import React from 'react';
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import  PhotoDocumentationHome from "./PhotoDocumentationHome";

const PhotoDocumentationRouteConfig = {
	routes: [
  {
    path: `${tddRoot}/project/photo-documentation`,
    exact: true,
    name: <IntlMessages id={"menu.photo-documentation"}/>,
    element: <PhotoDocumentationHome/>,
    auth: [],
  },
	]
};

export default PhotoDocumentationRouteConfig;