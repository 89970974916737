import React, { useState, useEffect,useCallback, useMemo} from "react";
import { useDebouncedCallback } from 'use-debounce';
import {
    TextField,
  } from "@mui/material";


const INPUT_DELAY = 200;
const TextFieldWrapper = (props) => {
    const [innerValue, setInnerValue] = useState('');
  
    useEffect(() => {
      if (props.value) {
        setInnerValue(props.value);
      } else {
        setInnerValue('');
      }
    }, [props.value]);
  
    const debouncedHandleOnChange = useDebouncedCallback(
      (event) => {
        if (props.onChange) {
          props.onChange(event);
        }
      },
      INPUT_DELAY
    );
  
    const handleOnChange = useCallback((event) => {
      const newValue = event.target.value;
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    }, [debouncedHandleOnChange]);
  
    return (
      <TextField
        {...props}
        value={innerValue}
        onChange={handleOnChange}
      />
    );
  };

  export default TextFieldWrapper;
  