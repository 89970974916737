import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRolesList,
  updateUser,
  getUsers
} from "../../../api/userManagementApi";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import {
  Chip,
  Grid,
  Button,
  TextField,
  Backdrop,
  CardHeader,
  Avatar,
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import Autocomplete from "@mui/material/Autocomplete";
import { resetCreateUserState,resetEditState } from "../userManagementSlice";
import { resetErrorState } from "../../../error/errorHandlingSlice";
import { getTAEuropeOffices } from "../../../api/userProfileApi";
import {getIntlTranslation} from "../../../utils/helper"
import CloseIcon from '@mui/icons-material/Close';

const useStyles = styled((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1350,
    color: "#fff",
  },
}));

const EditUserForm = (props) => {
  const { onClose, open, history, selectUser } = props;
  const [roles, setRoles] = useState([]);
  const [userStatus, setUserStatus] = useState("");
  const [userRole, setUserRole] = useState([
    { value: "User", label: "User", isFixed: true },
  ]);
  const [loader, setLoader] = useState(false);
  const createUser = useSelector((state) => state.userManagement.createUsers);
  const roleList = useSelector((state) => state.userManagement.roles);
  const editUser = useSelector((state) => state.userManagement.editUser);
  const officesList = useSelector((state) => state.userManagement.offices);
  const dispatch = useDispatch();
  const formikRef = React.createRef();
  const [offices, setOffices] = useState([]);
  const [taOffice, setTaOffice] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const errorState = useSelector((state) => state.errorhandling);
  // formik intial values:
  const intitialValues = {
    firstName: selectUser.firstName,
    lastName: selectUser.lastName,
    userName: selectUser.userName,
    emailAddress: selectUser.email,
    taOfficeDto: taOffice,
    roles: roles,
    status: userStatus,
    businessLine: selectUser.businessLine,

  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("FNameRequired"),
    lastName: Yup.string().required("LNameRequired"),
    userName: Yup.string().required("UserNameRequired"),
    emailAddress: Yup.string().email("EmailInvalid").required("EmailRequired"),
  });

  useEffect(() => {
    if (!!selectUser.roles) {
      const rols = selectUser.roles.map((e) => {
        return { value: e, label: e, isFixed: e === "User" ? true : false };
      });
      setUserRole(rols);
    }
    if (!!selectUser.office) {
      setTaOffice(selectUser.office);
    }
    if (!!selectUser.status) {
      setUserStatus(selectUser.status);
    }
  }, [selectUser]);

  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
      setLoader(false);
      dispatch(resetErrorState());
    }
  }, [dispatch, errorState]);

  useEffect(() => {
    setOffices(officesList);
  }, [officesList]);

  useEffect(() => {
    if (roleList.type === "SUCCESS") {
      setRoles(
        roleList.data.suggestionvaluesList.map((role) => {
          return {
            value: role,
            label: role,
          };
        })
      );
    }
  }, [roleList]);

  const roleOnChange = (event, newValue) => {
    newValue.push({ value: "User", label: "User", isFixed: true });
    const uniqueSetRoles = newValue.reduce(
      (unique, item) =>
        unique.find((v) => v.value === item.value) ? unique : [...unique, item],
      []
    );
    setUserRole([...uniqueSetRoles]);
  };
  const changeUserCity = (event, newValue) => {
    setTaOffice(newValue);
  };
  const onSubmit = (values) => {
    values.roles = [];
    values.taOfficeDto = taOffice;
    values.status = userStatus;
    userRole.forEach((element) => {
      values.roles.push(element.value);
    });
    dispatch(updateUser(values));
    setLoader(true);
  };

  useEffect(() => {
    if (createUser.type === "ERROR") {
      setLoader(false);
      Object.keys(createUser.errorFields).forEach(function eachKey(key) {
        formikRef.current.setFieldError(key, createUser.errorFields[key]);
      });
      dispatch(resetCreateUserState());
    }

    if (createUser.type === "SUCCESS") {
      if (typeof onClose === "function") {
        onClose();
      }
      setLoader(false);
    }
    if (editUser.type === "SUCCESS") {
      editUser.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetEditState());
      dispatch(getUsers());
      setLoader(false);

    }
  }, [createUser, editUser, dispatch, enqueueSnackbar]);


  const ChangeUserStatus = (event) => {
    setUserStatus(event.target.checked === true ? "Active" : "Inactive");
  };
  const renderValues = (tagValue, getTagProps) => {
    return tagValue.map((option, index) => (
      <Chip
        label={option.label}
        color="primary"
        size="small"
        {...getTagProps({ index })}
        disabled={option.isFixed}
      />
    ));
  };
  return (
    <div>
      <Formik
        initialValues={intitialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
        style={{ width: "100%" }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  {selectUser.firstName.charAt(0)}
                  {selectUser.lastName.charAt(0)}
                </Avatar>
              }
              title={getIntlTranslation("_Edit User")}
              action={
                (
                  <>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  style={{background:"#254a9a"}}
                >
                  <Typography variant="button" display="block">
                    {getIntlTranslation("_save")}
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<CloseIcon />}
                  style={{background:"#f50057", marginLeft:5}}
                  onClick={()=> onClose()}
                >
                  <Typography variant="button" display="block">
                    {getIntlTranslation("_close")}
                  </Typography>
                </Button>
                  
                  </>
                
                )
              }
            />
            <Table size="small" aria-label="purchases">
              <TableBody>
                <TableRow>
                  <Grid
                    style={{ width: "100%" }}
                    container
                    className="custom-grid-container"
                    justify="flex-start"
                    spacing={3}
                  >
                    <Grid item md={4} xs={12}>
                      <Field
                        as={TextField}
                        name="emailAddress"
                        label={"EmailAddress"}
                        className="grid-fields"
                        type="email"
                        size="small"
                        variant="outlined"
                        error={touched.emailAddress && errors.emailAddress}
                        helperText={touched.emailAddress && errors.emailAddress}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="firstName"
                        label={"FirstName"}
                        as={TextField}
                        className="grid-fields"
                        variant="outlined"
                        size="small"
                        error={touched.firstName && errors.firstName}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="lastName"
                        label={"LastName"}
                        as={TextField}
                        className="grid-fields"
                        variant="outlined"
                        size="small"
                        error={touched.lastName && errors.lastName}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Autocomplete
                        style={{ maxWidth: "100%" }}
                        options={offices}
                        getOptionLabel={(option) => option.city}
                        name="taOfficeDto"
                        className="grid-fields"
                        variant="outlined"
                        size="small"
                        disableClearable
                        value={taOffice}
                        onChange={(event, newValue) => {
                          changeUserCity(event, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${"Office"}`}
                            name="taOfficeDto"
                            size="small"
                          />
                        )}
                        error={touched.taOfficeDto && errors.taOfficeDto}
                        helperText={touched.taOfficeDto && errors.taOfficeDto}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Autocomplete
                        style={{ maxWidth: "100%" }}
                        multiple
                        size="small"
                        value={userRole}
                        onChange={(event, newValue) =>
                          roleOnChange(event, newValue)
                        }
                        options={roles}
                        variant="outlined"
                        getOptionLabel={(option) => option.label}
                        renderTags={(tagValue, getTagProps) =>
                          renderValues(tagValue, getTagProps)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="grid-fields"
                            label={"Roles"}
                            size="small"

                          />
                        )}
                        error={touched.role && errors.role}
                        helperText={touched.role && errors.role}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}  >
                        <FormControlLabel
                          value="start"
                          style={{
                            width:"90%",
                            minWidth: '0%',
                            padding:"8px",
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            display:"flex",
                            justifyContent:"flex-end"
                          }}
                          control={
                            <Switch
                              checked={
                                userStatus === "Active" ? true : false
                              }
                              onChange={ChangeUserStatus}
                              />
                          }
                          label={"Status"}
                          labelPlacement="start"
                        />
                      </Grid>
                  </Grid>
                </TableRow>
              </TableBody>
            </Table>
          </Form>
        )}
      </Formik>
      {loader && (
        <Backdrop className={useStyles.backdrop} open={loader}>
          <div className="preloader">
            <div></div>
          </div>
        </Backdrop>
      )}
    </div>
  );
};

export default EditUserForm;
