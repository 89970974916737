
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useReorder from "../../../../component-bundle/hooks/useReorderHook";
import { ComparisonModeProvider } from "./ComparisonModeContext";
import { useProjectProgressContext } from "./ProjectProgressContext";
import { SelectedPropertyProvider } from "./SelectedPropertyContext";

const PropertiesDataContext = createContext();

export const usePropertiesData = () => useContext(PropertiesDataContext);

export const PropertiesDataProvider = ({ children, moduleApi }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const [reorderResponse, onReorder, onResetResponse] = useReorder();
  const { subAssetList,properties, setProperties } = useProjectProgressContext();
  const [structuredProperties, setStructuredProperties] = useState({
    nodes: {},
  });



  const normalizeProperties = useCallback((properties,  language) => {
    const data = {
      nodes: {}
    };
  
    [...properties]
      .sort((a, b) => a.nodeOrder - b.nodeOrder)
      .filter((f) =>!!f.nodeName)
      .forEach((node) => {
        const nodeId = node.nodeIdent;
        const labelTranslation = node.labelTranslation.reduce((acc, item) => {
          acc[item.language] = { value: item.value, title: item.title };
          return acc;
        }, {});
  
        let tempSubAssets = {};
  
        node.mapProgressDtos.forEach((sub) => {
          const subAssetId = sub.subAssetIdent;
          const found = subAssetList.find((f) => f.subAssetIdent === sub.subAssetIdent);
          tempSubAssets[subAssetId] = {
            ...sub, original: {...sub}, subAsset:{...found}
          };
        });
  
        data.nodes[nodeId] = {
          ...node,
          nodeTitle: labelTranslation[language].title,
          labelTranslation: labelTranslation,
          original: node,
          subAssets: { ...tempSubAssets },
        };
      });
    return data;
  },[subAssetList]);

  useEffect(() => {
    if (properties.length > 0) {
      setStructuredProperties(
        normalizeProperties(
          properties,
          scope.projectLanguageCode
        )
      );
    }else{
      setStructuredProperties({
        nodes:{}
      })
    }
  }, [dispatch, normalizeProperties, properties, scope.projectLanguageCode,]);

  useEffect(() => {
    if (reorderResponse.type === "SAVED") {
      setProperties(reorderResponse.data);
      setStructuredProperties(
        normalizeProperties(
          reorderResponse.data,
          scope.projectLanguageCode
        )
      );
      onResetResponse();
    }
  }, [normalizeProperties, onResetResponse, reorderResponse, scope.projectLanguageCode, setProperties]);

  const handleReorder = (items) => {
    const reorder = items.map((item, index) => ({
      ...item.original,
      nodeOrder: index + 1,
    }));
    onReorder({
      reorderApi: moduleApi,
      data: reorder,
    });
  };

  return (
    <PropertiesDataContext.Provider
      value={{
        structuredProperties,
        setStructuredProperties,
        handleReorder,
        normalizeProperties,
      }}
    >
      <SelectedPropertyProvider>
        <ComparisonModeProvider>
            {children}
        </ComparisonModeProvider>
      </SelectedPropertyProvider>
    </PropertiesDataContext.Provider>
  );
};
