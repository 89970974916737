import React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Button } from "react-scroll";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { getTranslationWithVariable } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { getTemp } from "../projectmonitoringModuleSlice";
const TempVersionAlert = () => {
  const dispatch = useDispatch();
  const openAsset = useSelector((state) => state.openAsset);
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
 
  const handleUndoTempVersion = () => {
    dispatch(
      getTemp({
        isTemp: false,
        assetName: "",
        assetIdent: "",
        reportVersionIdent: "",
        reportVersionObject: {},
      })
    );
  };
  
  return (
    <>
      {tempVersion?.isTemp && openAsset.ident === tempVersion.assetIdent && (
        <Box className="w-100 mb-1" style={{ height: 40 }}>
          <>
            <List
              style={{
                width: "100%",
                background: "#fff4e5",
                padding: 0,
                height: 35,
              }}
              className={
                "d-flex flex-wrap align-items-center g-button-secondary"
              }
              dense={"true"}
            >
              <ListItem
                style={{ padding: "4px 4px", borderRadius: 5 }}
                secondaryAction={
                  <Button
                    onClick={() => handleUndoTempVersion()}
                    className="g-btn-secondary rounded-3"
                    size="small"
                  >
                    UNDO
                  </Button>
                }
              >
                <ListItemIcon style={{ minWidth: 20 }}>
                  <WarningAmberIcon
                    style={{ color: "#ed6c02", fontSize: "22px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getTranslationWithVariable(
                    {
                      id: "_temp alert",
                      values: {
                        asset_name: (
                          <strong
                            style={{ color: "#dc3545", fontWeight: "bold" }}
                          >
                            {tempVersion.assetName}
                          </strong>
                        ),
                        version_name: (
                          <strong
                            style={{ color: "#dc3545", fontWeight: "bold" }}
                          >
                            {tempVersion.reportVersionObject.versionName}
                          </strong>
                        ),
                      },
                    },
                    "subtitile2",
                    {
                      padding: 5,
                      fontWeight: "bold",
                      color: "#254a9a",
                    }
                  )}
                />
              </ListItem>
            </List>
          </>
        </Box>
      )}
    </>
  );
};

TempVersionAlert.propTypes = {};

export default TempVersionAlert;
