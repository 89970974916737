import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useSelector } from 'react-redux';
import GridViewIcon from "@mui/icons-material/GridView";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { NavLink, useNavigate } from 'react-router-dom';
import { serviceLines } from '../../utils/defaultValues';
import { Chip } from '@mui/material';


const BreadcrumbProjectContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const navigate = useNavigate(); 

  const getRoot = () => {
    const found =  serviceLines.find(
      (f) => f.name.toLowerCase() === scope.serviceLine.toLowerCase()
    );

    return found?.root;
  }  
  const navigateToCrumb = (crumbType) => {
    switch (crumbType){
      case "project-dashboard":
        return  `${getRoot()}/project/project-dashboard`;
      case "asset-overview":  
      return `${getRoot()}/project/assets-overview`;
        
    }
  }


  return (
    <>
    {!!scope.projectIdent && (
    <Chip className="m-1 w-100 h-100 rounded-3" 
    sx={{
      '.MuiChip-label': { 
        padding: 0 
      }
    }}
    label= {
      <div className="p-1 w-100 h-100">
      <Breadcrumb className="d-flex align-items-center h-100">
        <>
        <BreadcrumbItem key={"project-dashboard"} className={"breadcrumb_item m-0 p-1 breadcrumb-project"}>
       <NavLink to={navigateToCrumb("project-dashboard")} className="d-flex align-items-center">
         <GridViewIcon
            style={{ marginLeft: 5, width: 20, height: 20, color: "#ffffff" }}
          />
         <Typography variant="caption" style={{color:"#ffffff"}}>{scope.projectName}</Typography>
       </NavLink>
       </BreadcrumbItem>
       <>
       {!!openAsset.ident && props.showAsset &&
        <BreadcrumbItem key={"asset-overview"} className={"breadcrumb_item m-0 p-1 breadcrumb-project"}>
        <NavLink to={navigateToCrumb("asset-overview")}>
          <ApartmentIcon
             style={{ marginLeft: 5, width: 20, height: 20, color: "#ffffff" }}
           />
          <Typography variant="caption" style={{color:"#ffffff"}}>{openAsset.assetName}</Typography>
        </NavLink>
        </BreadcrumbItem> 
       }
       {!!openAsset.subAssetIdent && props.showSubAsset &&
        <BreadcrumbItem key={"sub-asset-overview"} className={"breadcrumb_item m-0 p-1"}>
          <Typography variant="caption" style={{color:"#ffffff"}}>{openAsset.subAssetName}</Typography>
        </BreadcrumbItem> 
       }
       </>
       </>
    </Breadcrumb>
    </div>
    }
    />
  )}
    </>
  )
}

BreadcrumbProjectContainer.propTypes = {}

export default BreadcrumbProjectContainer