import {
  ListItem,
  Box,
  ListItemText,
  Divider,
  Chip,
  Popover,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getIntlTranslation,
  getTranslationVariantText,
  getVariantText,
} from "../../../../../utils/helper";
import { getBorderColor } from "./BuildingProfileContainer";
import { useBuildingProfileContextHook } from "./BuildingProfileContext";
import { useSelector } from "react-redux";
import RenderInputType from "./RenderInputType";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import EditCustomPropertyLabel from "../../../common/input-types/EditCustomPropertyLabel";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";

export const RenderPropertyNode = () => {
  const scope = useSelector((state) => state.container.scope);
  const { itemList, properties, setProperties, setItemList } =
    useBuildingProfileContextHook();
  const [propertyList, setPropertyList] = useState(itemList);
  const [propertyToEdit, setPropertyToEdit] = useState({});
  const [openPopOver, setOpenPopOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedPropertyList, setUpdatedPropertyList] = useState([]);

useEffect(() => {
 setPropertyList(itemList);
}, [itemList])

  const getPropertyName = (property) => {
    const inputObj = property?.labelTranslation.find(
      (e) => e.language === scope.projectLanguageCode
    );
    return (
      <>
        {getVariantText(inputObj?.title || property?.name, "caption", {
          fontWeight: "bold",
          color: "#254a9a",
          margin: 5,
          textTransform: "uppercase",
        })}
      </>
    );
  };

  /*{property.custom && getCustomOptions(property)}*/

  const createListItemRow = (property, index) => {
    return (
      <>
      {properties?.has(property.nodeName) ?
        (
        <ListItem
          key={`item-${property.nodeIdent}`}
          style={{
            margin: "4px 0px",
            borderRadius: 4,
            borderLeft: getBorderColor(properties?.get(property.nodeName)),
            backgroundColor: properties.get(property.nodeName).updated
              ? "#ff2a5130"
              : "#ffffff",
          }}
          className="d-flex flex-row flex-wrap w-100 p-1"
        >
          <Box className="d-flex align-items-center w-25 p-1">
            <ListItemText
              primary={
                <>
                  <div
                    className={"d-flex align-items-center property-name"}
                    id={property.nodeIdent}
                  >
                    {getPropertyName(property)}
                  </div>
                </>
              }
            />
            {property.custom && renderCustomAnchor(property)}
          </Box>
          <Box className="w-75 p-1">
            <RenderInputType item={property} />
          </Box>
        </ListItem>) :
        <></>
            }
      </>
    );
  };

  const onPropertyEdit = (event, property) => {
    setOpenPopOver(true);
    setAnchorEl(event.currentTarget);
    setPropertyToEdit(property);
  }

  const onPropertyEditClose = () => {
    setAnchorEl(null);
    setOpenPopOver(false);
  };

  const updateItemList = (val, tempArray) => {
    setItemList((prev) => {
      const newItems = {...prev};
      if (
        val.nodeType === "area" ||
        val.nodeType.includes("-area")
      ) {
        newItems.inputAreaFields = [...tempArray];
      } else if (val.nodeType.includes("parking")) {
        newItems.inputParkingFields = [...tempArray];
      } else if (val.nodeType.includes("floor")) {
        newItems.inputFloorFields = [...tempArray];
      } else {
        newItems.inputFields = [...tempArray];
      }
      return newItems;
    })
  }

  function updateAndReplaceObjectInArray(lookupArray, selectedProperty, val) {
    let translation = val.labelTranslation.find(
      (e) => e.language === scope.projectLanguageCode
    );
    const index = lookupArray.findIndex(
      (item) => item.nodeName === selectedProperty.nodeName
    );

    if (index !== -1) {
      const updatedObject = { ...lookupArray[index], deleted: true };
      if (
        selectedProperty.isTemporary &&
        val.nodeName !== selectedProperty.nodeName
      ) {
        lookupArray[index] = val;
        const oldObject = properties.get(selectedProperty.nodeName);
        setProperties(prevProperties => {
          const newProperties = new Map(prevProperties);
          newProperties.set(val.nodeName,{
            ...oldObject,
            nodeName: val.nodeName,
            deleted: val.deleted,
            updated: val.updated,
            labelTranslation : translation,
          });
          newProperties.delete(oldObject.nodeName);
          return newProperties;
        });
      } else {
        lookupArray[index] = val;
        lookupArray.push(updatedObject);
        const oldObject = properties.get(selectedProperty.nodeName);
        setProperties(prevProperties => {
          const newProperties = new Map(prevProperties);
          newProperties.set(val.nodeName,{
            ...oldObject,
            nodeName: val.nodeName,
            deleted: val.deleted,
            updated: val.updated,
            labelTranslation : translation,
          });
          newProperties.get(selectedProperty.nodeName).deleted = true;
          return newProperties;
        });
      }
    }
    return [...lookupArray];
  }

  const updateProperty = (val, propertyToEdit) => {
    let tempArray = [];
    if (val.nodeType === "area" || val.nodeType.includes("-area")) {
      tempArray = [...propertyList.inputAreaFields];
    } else if (val.nodeType.includes("parking")) {
      tempArray = [...propertyList.inputParkingFields];
    } else if (val.nodeType.includes("floor")) {
      tempArray = [...propertyList.inputFloorFields];
    } else {
      tempArray = [...propertyList.inputFields];
    }

    let updatedArr = updateAndReplaceObjectInArray(
      tempArray,
      propertyToEdit,
      val
    );

    updateItemList(val, updatedArr);
  }

  const renderCustomAnchor = (property) => {
    return (
      property.custom && (
        <>
          <CustomIconButton
            id={`menu_button_${property.nodeName}`}
            icon={
              <ArrowDropDownIcon
                style={{
                  color: "#d08f8e",
                  fontSize: 20,
                }}
              />
            }
            permission={null}
            handleClick={(e) => onPropertyEdit(e, property)}
            tooltip={{
              title: getIntlTranslation("tooltip.edit"),
            }}
            style={{
              borderRadius: 0,
            }}
            aria-describedby={`prop_menu_${property.nodeName}`}
          />
          <Popover
            id={`prop_menu_${property.nodeName}`}
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={(e) => onPropertyEditClose(e)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <EditCustomPropertyLabel
              onClose={() => onPropertyEditClose()}
              property={propertyToEdit}
              handleSave={(val) => {
                updateProperty(val, propertyToEdit);
                onPropertyEditClose();
              }}
              onDelete={(val) => {
                let tempArray = [...propertyList.inputFields];
                if (val.nodeType === "area" || val.nodeType.includes("-area")) {
                  tempArray = [...propertyList.inputAreaFields];
                } else if (val.nodeType.includes("parking")) {
                  tempArray = [...propertyList.inputParkingFields];
                } else if (val.nodeType.includes("floor")) {
                  tempArray = [...propertyList.inputFloorFields];
                }
                let findIndex = tempArray.findIndex(
                  (f) => f.nodeName === val.nodeName
                );
                if (findIndex !== -1) {
                  tempArray[findIndex] = {
                    ...tempArray[findIndex],
                    deleted: true,
                  };

                  setProperties(prevProperties => {
                    const newProperties = new Map(prevProperties);
                    newProperties.get(val.nodeName).updated = true;
                    return newProperties;
                  });

                  updateItemList(val, tempArray);
                 
                }
                onPropertyEditClose();
              }}
            />
          </Popover>
        </>
      )
    );
  };

  const getDivider = (label) => {
    return [
      <>
        <Chip
          textAlign="left"
          className="g-btn-secondary"
          style={{ borderRadius: 4, margin: 4, width: "98%" }}
          label={getTranslationVariantText(label, "body2", {
            fontWeight: "bold",
          })}
        />
      </>,
    ];
  };

  const renderGroupedFiles = (fields) => {
    return fields.map((el, index) => {
      if (el.hidden || el.deleted) {
        return null;
      }
      return createListItemRow(el, index);
    });
  };

  const renderProperties = () => {
    let temp = [];
    let inputFieldsRender = propertyList?.inputFields.map((el, index) => {
      if (el.hidden || el.deleted) {
        return null;
      }
      return createListItemRow(el, index);
    });

    temp = [...inputFieldsRender];

    if (propertyList?.inputFloorFields.length > 0) {
      let floorDivider = getDivider("_floor-applicable");
      let floorRender = renderGroupedFiles(propertyList.inputFloorFields);
      temp = [...temp, ...floorDivider, ...floorRender];
    }
    if (propertyList?.inputAreaFields.length > 0) {
      let areaDivider = getDivider("_area-applicable");
      let areaRender = renderGroupedFiles(propertyList.inputAreaFields);
      temp = [...temp, ...areaDivider, ...areaRender];
    }
    if (propertyList?.inputParkingFields.length > 0) {
      let parkingDivider = getDivider("_parking-applicable");
      let parkingRender = renderGroupedFiles(propertyList.inputParkingFields);
      temp = [...temp, ...parkingDivider, ...parkingRender];
    }
    return temp;
  };

  return <>{!!propertyList && renderProperties()}</>;
};
