import { useSnackbar } from "notistack";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { response } from "../../../../../utils/defaultValues";
import {
  deleteCustomProperty,
  editCustomProperty,
  savePropertiesData,
} from "../../../api/monitoringKeyFindingsApi";
import {
  getCustomPropertyDelete,
  getCustomPropertyUpdate,
  getPropertyDataSaveSuccess,
} from "../keyFindingsSlice";
import { useKeyFindingsContext } from "./KeyFindingsContext";

export const KeyFindingsPropertyContext = createContext(undefined);

export const useKeyFindingsPropertyContext = () => {
  const context = useContext(KeyFindingsPropertyContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

export const KeyFindingsPropertyProvider = ({ children, moduleApi }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.keyFindings);
  const { fetchProps } = useKeyFindingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isSaveSuccessFull, setIsSaveSuccessFull] = useState(false);

  useEffect(() => {
    if (state.savePropertyData.type === "SAVED") {
      state.savePropertyData.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getPropertyDataSaveSuccess({ ...response, guruDtoList: [] }));
      setIsSaveSuccessFull(true);
      fetchProps();
    }
    if (
      state.customPropertyUpdate.type === "SAVED" &&
      state.customPropertyUpdate.data.length > 0
    ) {
      state.customPropertyUpdate.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomPropertyUpdate({ ...response, guruDtoList: [] }));
      fetchProps();
    }
    if (
      state.customPropertyDelete.type === "DELETED" &&
      !!state.customPropertyDelete.data
    ) {
      state.customPropertyDelete.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomPropertyDelete({ ...response, guruDto: {} }));
      fetchProps();
    }
  }, [
    dispatch,
    enqueueSnackbar,
    fetchProps,
    state.customPropertyDelete,
    state.customPropertyUpdate,
    state.savePropertyData,
  ]);

  const checkEmptyValues = (f) => {
    if (f.isNew) {
      return (
        f.description.length > 0 ||
        f.risk.value.length > 0 ||
        f.quelle.length > 0
      );
    } else {
      return f.nodeIdent.length > 0;
    }
  };

  const onSave = useCallback(
    (rows, property) => {
      let temp = rows
        .filter((f) => !!f.subAsset?.subAssetIdent && checkEmptyValues(f))
        .map((m) => ({
          ...m,
          new: m.isNew,
          updated: m.isUpdated,
          deleted: m.isDeleted,
          risk: !!m.risk ? m.risk.value : " ",
          subAsset: m.subAsset,
          versionIdent: state.currentVersion.ident,
          type: "mapKeyFindingsProperty",
        }));

      dispatch(
        savePropertiesData({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          propertyIdent: property.nodeIdent,
          versionIdent: state.currentVersion.ident,
          data: temp,
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent, state.currentVersion]
  );

  const onEditProperty = useCallback(
    (data) =>
      dispatch(
        editCustomProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          data: data,
        })
      ),
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  const onDeleteProperty = useCallback(
    (data) =>
      dispatch(
        deleteCustomProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          propertyIdent: data.nodeIdent,
        })
      ),
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  const onDeleteRecord = useCallback(
    (row, property) =>
      dispatch(
        savePropertiesData({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          propertyIdent: property.nodeIdent,
          versionIdent: state.currentVersion.ident,
          data: [{ ...row, deleted: true }],
        })
      ),
    [dispatch, moduleApi, scope.api, scope.projectIdent, state.currentVersion]
  );

  return (
    <KeyFindingsPropertyContext.Provider
      value={{
        isSaveSuccessFull,
        setIsSaveSuccessFull,
        onEditProperty,
        onDeleteProperty,
        onDeleteRecord,
        onSave,
      }}
    >
      {children}
    </KeyFindingsPropertyContext.Provider>
  );
};
