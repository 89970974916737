import React from 'react';
import ProjectProgressHome from "./ProjectProgressHome"
import { pmRoot} from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const ProjectProgressRouteConfig = {
  routes: [
		{
    path: `${pmRoot}/project/project-progress`,
    exact: true,
    name: <IntlMessages id={"menu.project-progress"}/>,
    element: <ProjectProgressHome/>,
    auth: [],
  },
	]
};
export default ProjectProgressRouteConfig;