import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import ListItemButton from "@mui/material/ListItemButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import { useSnackbar } from "notistack";
import CardHeader from "@mui/material/CardHeader";
import { generateInspectionReport } from "../../../api/reportApi";
import { resetErrorState } from "../../../../../error/errorHandlingSlice";
import { resetState } from "../../../../component-bundle/generate-report/reportSlice";
import {
  createAndDownloadBlobFile,
  getDialogBoxHeader,
  getIntlTranslation,
  getLabel,
} from "../../../../../utils/helper";
import LinearProgress from "@mui/material/LinearProgress";
import SaveIcon from "@mui/icons-material/Save";
import {
  fetchReportHeader,
  saveProjectHeader,
} from "../../../api/statutoryComplianceApi";
import { resetState as resetHeader } from "../statutoryComplianceSlice";
import CustomButton from "../../../../../components/buttons/CustomButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import BreadcrumbProjectContainer from "../../../../../layout/app-container/BreadcrumbProjectContainer"
import {
  draftVersion,
  englishLanguage,
  germanLanguage,
  finalVersion,
} from "../../../../../utils/defaultValues";

const reportVersions = [draftVersion, finalVersion];
const reportLanguageOptions = [germanLanguage, englishLanguage];
function ReportGenerationDialog(props) {
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [assets, setAssets] = useState([]);
  const openAsset = useSelector((state) => state.openAsset);
  const [checkedClient, setCheckedClient] = useState(false);
  const [checkedInternal, setCheckedInternal] = useState(false);
  const selectionHeaders = useSelector(
    (state) => state.tddInspection.selectionHeaders
  );
  const [checkeData, setCheckeData] = useState(selectionHeaders.data);
  const [report, setReport] = useState({
    type: {
      value: "Statutory Compliance",
      label: "Statutory Compliance",
    },
    lang: { label: "Deutsch", value: "de" },
    version: {
      value: "Draft",
      label: "Draft",
      isDefault: false,
    },
  });
  const scope = useSelector((state) => state.container.scope);
  const errorState = useSelector((state) => state.errorhandling);
  const reportState = useSelector((state) => state.report);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const groupHeadersBauForCategory = (ident) => {
    return !!props.categories.find((f) => {
      return (
        f.ident === ident &&
        (f.value.toLowerCase() === "baurecht" ||
          f.value.toLowerCase() === "building law" ||
          f.value.toLowerCase() === "building documentation" ||
          f.value.toLowerCase() === "baudokumentation")
      );
    });
  };

  const groupHeadersTUForCategory = (ident) => {
    return !!props.categories.find((f) => {
      return (
        f.ident === ident &&
        (f.value.toLowerCase() === "technische unterlagen" ||
          f.value.toLowerCase() === "technical documentation")
      );
    });
  };

  useEffect(() => {
    if (
      scope.projectIdent !== null &&
      scope.projectIdent !== "" &&
      openAsset.ident !== null
    ) {
      dispatch(fetchReportHeader(scope.projectIdent, openAsset.ident));
    }
  }, [dispatch, openAsset.ident, scope]);

  useEffect(() => {
    if (selectionHeaders.data !== null) {
      setCheckeData(selectionHeaders.data);
    } else {
      setCheckeData([
        {
          type: "client",
          headers: [],
        },
        {
          type: "internal",
          headers: [],
        },
      ]);
    }
  }, [selectionHeaders.data]);

  useEffect(() => {
    if (!!props.headers && props.headers.length)
      setHeaders(props.headers.map((m) => ({ ...m, inReport: true })));
  }, [props.headers]);

  useEffect(() => {
    if (selectionHeaders.type === "SAVED") {
      enqueueSnackbar(selectionHeaders.messages[0], {
        variant: "success",
        persist: false,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      dispatch(resetHeader());
    }
  }, [dispatch, enqueueSnackbar, selectionHeaders]);
  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
      enqueueSnackbar(errorState.messages, {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      dispatch(resetErrorState());
      setLoading(false);
    }
    if (
      reportState.type === "REPORT_SUCCESS" &&
      reportState.data?.ident === openAsset.ident
    ) {
      enqueueSnackbar(reportState.messages[0], {
        variant: "success",
        persist: false,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      const arrayBuffer = base64ToArrayBuffer(reportState.data.fileData);
      createAndDownloadBlobFile(
        arrayBuffer,
        reportState.data.fileName,
        reportState.data.contentType
      );

      dispatch(resetState());
      setTimeout(() => {
        setLoading(false);
        props.onClose();
      }, 600);
    }
  }, [
    report,
    dispatch,
    errorState,
    enqueueSnackbar,
    openAsset.ident,
    reportState.type,
    reportState.data?.ident,
    props,
  ]);

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  const setHeadersInReport = (e, h) => {
    setHeaders(
      headers.map((m) => {
        if (m.ident === h.ident) {
          h.inReport = e.target.checked;
        }
        return m;
      })
    );
    if (checkedClient) {
      const check = e.target.checked;
      if (check) {
        const data = checkeData[0]?.headers.filter(
          (element) => element.headerIdent === h.headerIdent
        );
        if (data.length <= 0) {
          const data = {
            ...h,
            updated: true,
          };
          const internalData = checkeData[1];
          const client = {
            type: "client",
            headers: [...checkeData[0].headers, data],
          };
          const finalData = [client, internalData];
          setCheckeData(finalData);
        }
      } else {
        const data = checkeData[0].headers.filter(
          (element) => element.headerIdent !== h.headerIdent
        );
        const internalData = checkeData[1];
        const client = {
          type: "client",
          headers: data,
        };
        const finalData = [client, internalData];
        setCheckeData(finalData);
      }
    }
    if (checkedInternal) {
      const check = e.target.checked;
      if (check) {
        const data = checkeData[1].headers.filter(
          (element) => element.headerIdent === h.headerIdent
        );
        if (data.length <= 0) {
          const data = {
            ...h,
            updated: true,
          };
          const client = checkeData[0];
          const internalData = {
            type: "internal",
            headers: [...checkeData[1].headers, data],
          };
          const finalData = [client, internalData];
          setCheckeData(finalData);
        }
      } else {
        const data = checkeData[1].headers.filter(
          (element) => element.headerIdent !== h.headerIdent
        );
        const client = checkeData[0];
        const internalData = {
          type: "internal",
          headers: data,
        };
        const finalData = [client, internalData];
        setCheckeData(finalData);
      }
    }
  };

  const handleHeaderSave = () => {
    const newData = {
      type: "assetInspectionHeaderDto",
      asssetIdent: openAsset.ident,
      inspectionHeader: JSON.stringify(checkeData),
    };
    const data = {
      projectIdent: scope.projectIdent,
      headers: newData,
    };
    dispatch(saveProjectHeader(data));
  };
  const selectAll = (e) => {
    setHeaders(
      headers.map((m) => {
        m.inReport = e.target.checked;
        return m;
      })
    );
    if (e.target.checked === false) {
      setCheckedInternal(false);
      setCheckedClient(false);
    }
  };

  const numberOfChecked = () => {
    return headers.filter((f) => f.inReport).length;
  };

  const handleSubmit = () => {
    setLoading(true);
    const requestedReport = {
      projectIdent: scope.projectIdent,
      assetIdent: openAsset.ident,
      headers: headers.filter((f) => f.inReport),
      language: report.lang.value,
      version: report.version.value,
      isDefault: report.version.isDefault,
    };
    dispatch(generateInspectionReport({ ...requestedReport }));
  };

  const getListItem = (arr) => {
    return arr.map((m) => {
      return (
        <ListItem
          key={m.key}
          style={{ marginLeft: 20, width: "30%", whiteSpace: "pre-wrap" }}
          disablePadding
        >
          <ListItemIcon style={{ height: 56, paddingLeft: 10 }}>
            <Checkbox
              edge="start"
              checked={m.inReport}
              onChange={(e) => setHeadersInReport(e, m)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText id={m.key} primary={m.name} />
        </ListItem>
      );
    });
  };

  const getCustomHeaderOptions = () => {
    let commonFilter = headers.filter((f) => f.categoryList.length >= 5);

    let bauFilter = headers
      .filter((f) => f.categoryList.length < 5)
      .filter(
        (z) =>
          z.categoryList.filter((c) =>
            groupHeadersBauForCategory(c.category.ident)
          ).length > 0
      );

    let others = headers
      .filter((f) => f.categoryList.length < 5)
      .filter(
        (z) =>
          z.categoryList.filter((c) =>
            groupHeadersTUForCategory(c.category.ident)
          ).length > 0
      );

    return (
      <>
        <Typography variant="Button" style={{ margin: 5, fontWeight: "bold" }}>
          {" "}
          Baurecht + Doku + TGA Hochbau
        </Typography>
        <List style={{ display: "flex", flexWrap: "wrap" }}>
          {getListItem(commonFilter)}
        </List>
        <Typography variant="Button" style={{ margin: 5, fontWeight: "bold" }}>
          Baurecht + Doku
        </Typography>
        <List style={{ display: "flex", flexWrap: "wrap" }}>
          {getListItem(bauFilter)}
        </List>
        <Typography variant="Button" style={{ margin: 5, fontWeight: "bold" }}>
          TGA Hochbau
        </Typography>
        <List style={{ display: "flex", flexWrap: "wrap" }}>
          {getListItem(others)}
        </List>
      </>
    );
  };

  const handleInternalChange = (event) => {
    setCheckedInternal(event.target.checked);
    setCheckedClient(false);
    if (event.target.checked) {
      const e = event.target.checked;
      setHeaders(
        headers.map((m) => {
          m.inReport = false;
          return m;
        })
      );
      checkeData[1].headers.forEach((h) => {
        setHeaders(
          headers.map((m) => {
            if (m.headerIdent === h.headerIdent) {
              m.inReport = e;
            }
            return m;
          })
        );
      });
    } else {
      const e = event.target.checked;
      checkeData[1].headers.forEach((h) => {
        setHeaders(
          headers.map((m) => {
            if (m.headerIdent === h.headerIdent) {
              m.inReport = e;
            }
            return m;
          })
        );
      });
    }
  };

  const handleClientChange = (event) => {
    setCheckedClient(event.target.checked);
    setCheckedInternal(false);
    const check = event.target.checked;
    if (event.target.checked) {
      setHeaders(
        headers.map((m) => {
          m.inReport = false;
          return m;
        })
      );
      checkeData[0].headers.forEach((h) => {
        setHeaders(
          headers.map((m) => {
            if (m.headerIdent === h.headerIdent) {
              m.inReport = check;
            }
            return m;
          })
        );
      });
    } else {
      checkeData[0].headers.forEach((h) => {
        setHeaders(
          headers.map((m) => {
            if (m.headerIdent === h.headerIdent) {
              m.inReport = check;
            }
            return m;
          })
        );
      });
    }
  };

  const onSelectionChange = (checked, value, type) => {
    if (checked) {
      setReport((prev) => ({ ...prev, [type]: value }));
    }
  };

  const children = (
    <>
      <ListItemButton sx={{ pl: 5, maxHeight: 30 }}>
        <FormControlLabel
          label={getIntlTranslation("_Internal")}
          control={
            <Checkbox
              checked={checkedInternal}
              onChange={handleInternalChange}
            />
          }
        />
      </ListItemButton>
      <ListItemButton sx={{ pl: 5, maxHeight: 30 }}>
        <FormControlLabel
          label={getIntlTranslation("_Client")}
          control={
            <Checkbox checked={checkedClient} onChange={handleClientChange} />
          }
        />
      </ListItemButton>
    </>
  );
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={props.open}
      onClose={props.onClose}
    >
      <AppBar
        style={{
          backgroundColor: "#d08f8e",
          position: "sticky",
          width: "100%",
          borderRadius: 4,
          height: 40,
        }}
      >
        <Toolbar
          className={"dialog-toolbar d-flex justify-content-between"}
        >
          <div className="d-flex align-items-center justify-content-between w-25">
            {getDialogBoxHeader("Statutory Compliance")}
          </div>
          <div className="d-flex align-items-center justify-content-between w-75">
            <Box className="w-75">
            <BreadcrumbProjectContainer 
            showAsset={true} showSubAsset={true} 
            />
            </Box>
           
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={(e) => props.onClose()}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <DialogContent className="custom-scrollBar p-1 m-0" dividers>
        <DialogContentText style={{ margin: 5, color:"#244a9a" }}>
          {getIntlTranslation("_Select Headers")}
        </DialogContentText>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CardHeader
            avatar={
              <Checkbox
                onClick={(e) => selectAll(e)}
                checked={
                  numberOfChecked() === headers.length && headers.length !== 0
                }
                indeterminate={
                  numberOfChecked() !== headers.length &&
                  numberOfChecked() !== 0
                }
                disabled={headers.length === 0}
                inputProps={{
                  "aria-label": "all items selected",
                }}
              />
            }
            title={getLabel("_headers")}
            subheader={`${numberOfChecked()}/${headers.length} selected`}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton>
                <ListItemText primary={getIntlTranslation("_Save or select")} />
              </ListItemButton>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <List component="div" disablePadding>
                  {children}
                </List>
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ maxHeight: 40, marginRight: 10 }}
                >
                  {(checkedClient === true || checkedInternal === true) && (
                    <Button
                      variant="outlined"
                      startIcon={<SaveIcon />}
                      onClick={handleHeaderSave}
                    >
                      {getIntlTranslation("_save")}
                    </Button>
                  )}
                </Stack>
              </Box>
            </List>
          </Box>
        </Card>
        <Card>
          <Divider />
          {headers.length && getCustomHeaderOptions()}
        </Card>
      </DialogContent>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <DialogActions className="d-flex justify-content-between">
      <Box className="w-100 d-flex align-items-center g-btn-secondary rounded-3">
          <Box className="d-flex flex-wrap align-items-center w-50 p-1">
            <Typography variant="subtitle2" className="p-1 w-25 fw-bold">
              {getIntlTranslation("_report-language")}
            </Typography>
            <List className="d-flex align-items-center p-0 w-75 m-0">
              {reportLanguageOptions.map((option, index) => (
                <ListItem
                  className="d-flex align-items-center p-0 m-1 w-50"
                  key={`lang-${index}`}
                >
                  <Checkbox
                    checked={report.lang.value === option.value}
                    onChange={(e) =>
                      onSelectionChange(e.target.checked, option, "lang")
                    }
                    inputProps={{ "aria-label": "report language" }}
                  />
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">
                        {option.label}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Divider
            orientation="vertical"
            style={{
              width: 4,
              margin: "10px 0px",
              backgroundColor: "#ffffff",
            }}
            flexItem
          />
          <Box className="d-flex flex-wrap align-items-center w-50 p-1">
            <Typography variant="subtitle2" className="p-1 w-25 fw-bold">
              {getIntlTranslation("_report-version")}
            </Typography>
            <List className="d-flex flex-row flex-wrap align-items-center p-0 w-75 m-0">
              {reportVersions.map((option, index) => (
                <ListItem
                  className="d-flex align-items-center p-0 m-0 w-50"
                  key={`verion-${index}`}
                >
                  <Checkbox
                    checked={report.version.value === option.value}
                    onChange={(e) =>
                      onSelectionChange(e.target.checked, {...option, isDefault:false}, "version")
                    }
                    inputProps={{ "aria-label": "report version" }}
                  />
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">
                        {option.label}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <CustomButton
          id={`Generate-capex`}
          buttonText={"_Generate"}
          variant="button"
          style={{
            margin: 5,
            backgroundColor: "#254a9a",
            color: "#ffffff",
            height: 50,
          }}
          tooltip={{
            title: getIntlTranslation("tooltip.click-to-generate"),
          }}
          handleClick={(e) => handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
}

ReportGenerationDialog.propTypes = {
  headers: PropTypes.array,
  categories: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ReportGenerationDialog;
