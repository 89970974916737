import {
  AppBar,
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
} from "../../../utils/helper";
import CustomButton from "../../../components/buttons/CustomButton";
import Collapse from "@mui/material/Collapse";
import { useDocumentContextHook } from "../structure/DocumentBuilderContext";
import CloseIcon from "@mui/icons-material/Close";

const settingOptions = [
  {
    label: "_Name",
    type: "string",
    onChangeField: "title",
  },
  {
    label: "_translation",
    type: "header",
  },
  {
    label: "_German-Translation",
    type: "string",
    onChangeField: "deTranslation",
  },
  {
    label: "_English-Translation",
    type: "string",
    onChangeField: "enTranslation",
  },
];

export const TypeHeaderTreeRender = ({ item }) => {
  const { selectedItem } = useDocumentContextHook();
  return (
    <Typography
      variant="subtitle2"
      style={{
        color: selectedItem?.id === item.id ? "#ffffff" : "#254a9a",
        fontWeight: selectedItem?.id === item.id ? "bold" : "normal",
      }}
      className="p-1"
    >
      {item?.settings?.title}
    </Typography>
  );
};

export const TypeHeadersRenderSettings = ({ item }) => {
  const { editItem, setEditItem, saveSettings } = useDocumentContextHook();
  const [settings, setSettings] = useState({
    title: "",
    enTranslation: "",
    deTranslation: "",
  });

  useEffect(() => {
    if (!!editItem && !!editItem.id) {
      setSettings({
        title: editItem.settings.title,
        enTranslation: editItem.settings.translation?.en,
        deTranslation: editItem.settings.translation?.de,
      });
    }
  }, [editItem?.id]);

  const handleSave = () => {
    saveSettings(editItem, {
      ...editItem.settings,
      title: settings.title,
      translation: {
        en: settings.enTranslation,
        de: settings.deTranslation,
      },
    });
    setEditItem(null);
  };

  const renderInputField = (input) => {
    if (input.type === "number") {
      return (
        <TextField
          key={`name_${item.id}-${input.label}`}
          className="w-100 p-1"
          type={input.type}
          defaultValue={
            !!editItem
              ? settings[input.onChangeField]
              : item.settings.defaultTitle
          }
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    } else if (input.type === "checkbox") {
      return (
        <Typography variant="body1">
          <Checkbox
            key={`name_${item.id}-${input.label}`}
            checked={settings[input.onChangeField]}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                [input.onChangeField]: e.target.checked,
              }))
            }
          />
        </Typography>
      );
    } else {
      return (
        <TextField
          key={`name_${item.id}-${input.label}`}
          className="w-100 p-1"
          multiline={true}
          maxRows={5}
          type={input.type || "text"}
          defaultValue={settings[input.onChangeField]}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              [input.onChangeField]: e.target.value,
            }))
          }
          size="small"
          helperText={!!input.hint ? input.hint : null}
        />
      );
    }
  };
  return (
    <>
      <Collapse
        className="w-100 m-1 p-1"
        in={editItem?.id === item.id}
        timeout="auto"
        unmountOnExit
      >
        <Card className="w-100 p-1" style={{ backgroundColor: "#e6e8ed" }}>
          <AppBar
            style={{
              backgroundColor: "#d08f8e",
              position: "sticky",
              width: "98%",
              borderRadius: 4,
              marginLeft: 2,
              height: 40,
            }}
          >
            <Toolbar className={"dialog-toolbar"}>
              <Typography variant="body1">{editItem?.label}</Typography>
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={() => setEditItem(null)}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <CardContent className="w-100 p-1">
            <List className="w-100 p-0">
              {settingOptions.map((o, index) => {
                if (o.type === "header") {
                  return (
                    <ListItem
                      className="w-100 p-0"
                      key={`option-${o.label}-${index}}`}
                    >
                      {getTranslationVariantText(o.label, "caption", {
                        fontWeight: "bold",
                        color: "#244a9a",
                        textAlign: "center",
                      })}
                    </ListItem>
                  );
                } else {
                  return (
                    <>
                      <ListItem
                        className="w-100 p-0 d-flex flex-row align-items-baseline m-1 flex-wrap"
                        key={`option-${o.label}-${index}}`}
                      >
                        <Box
                          style={{ textTransform: "capitalize", width: "80%" }}
                        >
                          {getLabel(o.label)} {o.optional && `(Optional)`}
                        </Box>
                        {renderInputField(o)}
                      </ListItem>
                    </>
                  );
                }
              })}
            </List>
          </CardContent>
          <CardActions
            className="w-100 p-0 justify-content-end"
            style={{ marginTop: "auto" }}
          >
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ width: "30%" }}
            >
              <CustomButton
                id={`save_${item.id}`}
                buttonText={"_save"}
                variant="button"
                textVariant="body1"
                buttonColor={"inherit"}
                style={{
                  margin: 5,
                  height: 30,
                  marginLeft: "auto",
                  width: "70%",
                  padding: 5,
                }}
                className="g-btn-primary"
                tooltip={{
                  title: getIntlTranslation("tooltip.Save"),
                }}
                size="small"
                handleClick={(e) => handleSave()}
              />
            </div>
          </CardActions>
        </Card>
      </Collapse>
    </>
  );
};

const getPreviewStyle = (node) => {
  if (node.nodeType === "header-chapter") {
    return {
      color: "#ffffff",
      backgroundColor: "#254a9a",
      fontWeight: "bold",
      fontsize: 14,
    };
  } else if (node.nodeType === "sub-header" && node.depth === 2) {
    return {
      color: "#254a9a",
      backgroundColor: "#D9D9D9",
      fontWeight: "bold",
      fontsize: 13,
      marginLeft: 2,
    };
  } else if (node.nodeType === "sub-header" && node.depth > 2) {
    return {
      color: "#254a9a",
      backgroundColor: "#ffffff",
      fontWeight: "bold",
      fontsize: 11,
      marginLeft: 2,
    };
  } else {
    return {
      color: "#254a9a",
      fontsize: 11,
    };
  }
};

export const TypeHeadersPreviewRender = ({ item }) => {
  return (
    <Typography style={getPreviewStyle(item)} className="p-1">
      {item.index}. {item?.settings?.title}
    </Typography>
  );
};
