import React, { useState, useEffect } from "react";
import {Button, TextField, Box,CardContent,Card,CardActions,Alert} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validateResetToken, resetPassword } from "../api/authApi";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { reset } from "./authSlice";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getTranslationVariantText,getLabel } from "../utils/helper";
import IntlMessages from "../utils/IntlMessages";

const ResetPassword = () => {
  const TokenStatus = {
    Validating: "Validating",
    Valid: "Valid",
    Invalid: "Invalid",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.authentication);

  const [token, setToken] = useState(null);
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

  useEffect(() => {
    const tokenUrl = new URLSearchParams(window.location.search);
    const token = tokenUrl.get("token");
    setToken(token);
    // remove token from url to prevent http referer leakage
    dispatch(reset());
    dispatch(validateResetToken(token));
  }, [dispatch]);

  useEffect(() => {
    if (userLogin.validateToken.type === "VALID_TOKEN") {
      setTokenStatus(TokenStatus.Valid);
    } else if (
      userLogin.validateToken.type === "INVALID_TOKEN"
    ) {
      setTokenStatus(TokenStatus.Invalid);
    } else {
      setTokenStatus(TokenStatus.Validating);
    }
  }, [TokenStatus.Invalid, TokenStatus.Valid, TokenStatus.Validating, userLogin.validateToken.type]);

  useEffect(() => {
    if (userLogin.passwordSuccess.type === "SUCCESS") {
      navigate("/login");
    }
  }, [navigate, userLogin.passwordSuccess.type]);

  function getForm() {
    const initialValues = {
      password: "",
      confirmPassword: "",
    };

    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .min(6, <IntlMessages id="_valid-password-format" />)
        .required(<IntlMessages id="user.passwordRequired" />)
        .matches(
          /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Das Passwort muss mindestens 6 Zeichen lang sein und aus einer Kombination von Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen bestehen."
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null],<IntlMessages id="_passwords-match"/>)
        .required(<IntlMessages id="user.passwordRequired" />),
    });

    function onSubmit({ password, confirmPassword }, { setSubmitting }) {
      dispatch(resetPassword(token, password, confirmPassword));
    }

    return (
      <>
        <div className="auth-wrapper auth-bg" style={{backgroundImage: `url(/static/images/auth_5.jpg)`}}>
          {tokenStatus === TokenStatus.Validating && (
            <>
            </>
            // <Card className="width-25">
            //   <CardHeader>
            //     <CRow>
            //       <CCol xs="12">
            //         <div>Token is being validated...</div>
            //       </CCol>
            //     </CRow>
            //   </CCardHeader>
            // </CCard>
          )}
          {tokenStatus === TokenStatus.Invalid && (
            <>
             <div className="fixed-background">
             <Box className="h-100 d-flex align-items-center justify-content-center m-auto">
             <Card className="login-card">
                <div className="position-relative login-image">
                  <p className="text-white h6">
                    <IntlMessages id="guru.welcome" />
                  </p>
                  <p
                    className="text-white h6"
                    style={{ marginTop: "2%", marginBottom: "50%" }}
                  >
                    <IntlMessages id="guru.messages" />
                  </p>
                </div>
                <div className="login-side">
                  <div style={{ marginBottom: 40 }}>
                    <NavLink to="/">
                      <img
                        src="/assets/images/ta-logo.jpg"
                        width="50"
                        height="50"
                        alt=""
                      />
                    </NavLink>
                  </div>
                  <CardContent className="d-flex flex-column">
                    {getTranslationVariantText("_reset_inavlid_taken","body1",{
                   margin: "15px 0px",
                   fontWeight:"bold",
                   color:"#d08f8e",
                   padding:5
                })}
                </CardContent>
                <CardActions className={"d-flex align-items-center w-100 justify-content-center"}>
                <Button variant="text" onClick={(e) => { navigate("/login/forgot-password")}}>{getTranslationVariantText("user.forgotPassword")}</Button>
                  </CardActions>
                  </div>
              
              </Card>
              </Box>
              </div>
            </>
          )}
          {tokenStatus === TokenStatus.Valid && (
            <>
             <div className="fixed-background">
           <Box className="h-100 d-flex align-items-center justify-content-center m-auto">
           <Card className="login-card">
                <div className="position-relative login-image">
                  <p className="text-white h6">
                    <IntlMessages id="guru.welcome" />
                  </p>
                  <p
                    className="text-white h6"
                    style={{ marginTop: "2%", marginBottom: "50%" }}
                  >
                    <IntlMessages id="guru.messages" />
                  </p>
                </div>
                <div className="login-side">
                  <div style={{ marginBottom: 40 }}>
                    <NavLink to="/">
                      <img
                        src="/assets/images/ta-logo.jpg"
                        width="50"
                        height="50"
                        alt=""
                      />
                    </NavLink>
                  </div>
              
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ errors, touched, isSubmitting,setFieldValue }) => (
                        
                        <Form>
                          <CardContent dividers className="d-flex flex-column align-items-center w-100">
                            <Box className="w-100 p-2">
                          <TextField
                          name="password"
                          type="password"
                            label={getLabel("user.password")}
                            className="w-100"
                            onChange={(e)=> setFieldValue("password", e.target.value)}
                           
                            error={touched.password && errors.password}
                            helperText={
                              touched.password && errors.password
                            }
                            size="small"
                          />
                          </Box>
                           <Box className="w-100 p-2">
                            <TextField
                          name="confirmPassword"
                          type="password"
                            label={getLabel("user.confirmPassword")}
                            style={{ width: "100%" }}
                            onChange={(e)=> setFieldValue("confirmPassword", e.target.value)}
                           
                            error={touched.confirmPassword && errors.confirmPassword}
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            size="small"
                          />
                           </Box>
                           <Alert severity="info">{getTranslationVariantText("_valid-password-format")}</Alert>
                           </CardContent>
                           <CardActions className={"d-flex align-item-center w-100 justify-content-between flex-wrap"}>
                       <Button variant="text" onClick={(e) => { navigate("/login")}}>{getTranslationVariantText("_cancel")}</Button>
                          <Button
                            variant="contained"
                            className="g-btn-primary"
                            disableElevation
                            disabled={isSubmitting}
                            type="submit"
                            style={{ margin: "15px 0px"}}
                          >
                           {getTranslationVariantText("_submit")}
                           {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                          </Button>
                          </CardActions>
                        </Form>
                      )}
                    </Formik>
                    </div>
                    </Card>
                    </Box>
                    </div>
                    </>
         
          )}
        </div>
      </>
    );
  }

  return <div>{getForm()}</div>;
};

export default ResetPassword;
