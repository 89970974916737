import React from 'react'
import CapexOverview from './CapexOverview'

const CapexOverviewHome = () => {
  return (
    <div>
        <CapexOverview/>
    </div>
  )
}

export default CapexOverviewHome