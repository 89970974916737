import { createSlice } from "@reduxjs/toolkit";
import {response} from "../../../utils/defaultValues"

export const initialState = {

  hasErrors: false,
  error:{
   ...response
 },
 userList:{
  ...response
 },
 projectUsers:{
  ...response
 },
mentionList:[],
};

const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }
    }),
    getUserListSuccess: (state, { payload }) => ({
      ...state,
      userList:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
       }
    }),
    addMentions: (state, { payload }) => ({
      ...state,
      mentionList:payload,
    }),
    getProjectUserListSuccess:(state, { payload }) => ({
      ...state,
      projectUsers:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
       }
    }),
    resetState: (state) => ({
      ...initialState,
      tab: state.tab,
      mentions:state.mentions,
    }),
  },
});

export const { resetState,getFailure,getUserListSuccess,getProjectUserListSuccess,addMentions} = usersSlice.actions;

// A selector
export const usersSelector = (state) => state.users;

// The reducer
export default usersSlice.reducer;