import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import { SelectCellFormatter, SelectColumn } from "react-data-grid";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import RowOptionsMenu from "./RowOptionsMenu";
import SubAssetSwitcher from "../../../../component-bundle/assetSwitcher/SubAssetSwitcher";
import StatutoryFilterBar from "./StatutoryFilterBar";
import { resetState } from "../statutoryComplianceSlice";
import { fetchRows } from "../../../api/statutoryComplianceApi";
import EditRowDialog from "./EditRowDialog";
import {
  getTextSize,
  isEmpty,
  getTranslationVariantText,
  getIntlTranslation,
} from "../../../../../utils/helper";
import StatutoryDataGrid from "./StatutoryDataGrid";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import { setMinimized } from "../../../../../layout/app-container/containerSlice";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { setOpenAsset } from "../../asset-management/asset/OpenAssetSlice";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Drawer } from "@mui/material";
import NoAssets from "../../../../component-bundle/NoAssets";
import React from "react";
import InputSearch from "../../../../../components/InputSearch";

const headerStyle = {
  height: "100%",
  width: "100%",
  whiteSpace: "pre-wrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  lineHeight: "normal",
};

const cellStyle = {
  height: "100%",
  width: "100%",
  whiteSpace: "pre-wrap",
  display: "flex",
  alignItems: "normal",
  justifyContent: "start",
  lineHeight: "normal",
  textAlign: "start",
};

const headingStyle = {
  fontSize: 14,
  height: "100%",
  width: "100%",
  whiteSpace: "pre-wrap",
  display: "flex",
  alignItems: "normal",
  justifyContent: "start",
  lineHeight: "normal",
  padding: 5,
};

const deleteRow = {
  textDecoration: "line-through",
  color: "#ff0000",
};



const renderHeader = (name) => (
  <div style={headerStyle}>
    <Typography variant="Heading" style={{ fontWeight: "bold", margin: 5 }}>
      {name}
    </Typography>
  </div>
);

const isSectionRow = (params) => {
  let bool = false;
  if (params.type === "ROW") {
    bool =
      params.row.type === "inspection_category" ||
      params.row.type === "inspection_tradeGroup";
  }
  return bool;
};

const getStyle = (p) => {
  let style = {
    height:"100%",
    width:"100%",
  }
  if (p.row.updated && !p.row.deleted) {
    style = {...style, height:"100%", backgroundColor:"rgba(255, 42, 81, 0.19)"}
  }
  if (p.row.deleted) {
    style = {...style, height:"100%", backgroundColor:"rgba(255, 42, 81, 0.19)"}
  }
  if(p.row.updated && p.column.key==="action"){
    style = {...style,  borderLeft: "4px solid rgb(211, 0, 56)"};
  }
  return style
};

const checkIfUpdated = (newVal, oldValue, isUpdated) => {
  let bool = false;
  if (isEmpty(newVal) && !!oldValue) {
    bool = true;
  } else if (!isEmpty(newVal) && newVal !== oldValue) {
    bool = true;
  }

  return bool || isUpdated;
};

const updateDataRow = ({ newVal, type, data }) => {
  const isUpdated = checkIfUpdated(
    newVal,
    data.row.original[type],
    data.row.updated
  );
  data.onRowChange(
    {
      ...data.row,
      [type]: newVal,
      updated: isUpdated,
    },
    true
  );
};

const getFormatter = (p, h) => {
  if (h.editor === "checkbox") {
    return (
      <SelectCellFormatter
        tabIndex={-1}
        className="p-0"
        value={p.row[h.key]}
        onChange={(e) => {
          updateDataRow({
            newVal: e,
            type: h.key,
            data: p,
          });
        }}
      />
    );
  }
  if ((h.editable || p.row.custom) && h.editor.includes("dropdown")) {
    return (
      <div style={{ ...cellStyle, cursor: "pointer" }}>
        <p> {p.row[h.key]?.name}</p>
      </div>
    );
  }
  if (
    (h.editable || p.row.custom) &&
    h.editor === "date-year" &&
    !!p.row[h.key]
  ) {
    return (
      <div style={{ ...cellStyle, cursor: "pointer" }}>
        <p> {p.row[h.key]}</p>
      </div>
    );
  }
  if ((h.editable || p.row.custom) && h.editor === "date" && !!p.row[h.key]) {
    return (
      <div style={{ ...cellStyle, cursor: "pointer" }}>
        <p> {new Date(p.row[h.key]).toLocaleDateString("de")}</p>
      </div>
    );
  }
  if (p.row.deleted && h.key === "trade") {
    return (
      <div style={{ ...cellStyle, cursor: "pointer" }}>
        <p style={deleteRow}> {p.row[h.key]}</p>
      </div>
    );
  }
  return (
    <div style={{ ...cellStyle, cursor: "pointer" }}>
      <p
        className={"custom-scrollBar"}
        style={{ height: "98%", width: "100%", overflowY: "auto", padding: 5 }}
      >
        {" "}
        {p.row[h.key]}
      </p>
    </div>
  );
};

const updateDropDownDataRow = ({ newValue, type, data }) => {
  if (data.row[type].multiple) {
    const comb = newValue.map((item) => item.title).join();
    data.onRowChange(
      {
        ...data.row,
        [type]: {
          name: comb,
          options: newValue,
          multiple: true,
        },
        updated: checkIfUpdated(
          comb,
          data.row.original[type].name,
          data.row.updated
        ),
      },
      true
    );
  } else {
    data.onRowChange(
      {
        ...data.row,
        [type]: {
          name: newValue.title,
          options: newValue,
          multiple: false,
        },

        updated: checkIfUpdated(
          newValue.title,
          data.row.original[type].name,
          data.row.updated
        ),
      },
      true
    );
  }
};

const getEditor = (p, h, headers) => {
  if (
    p.row.type === "inspection_category" ||
    p.row.type === "inspection_tradeGroup"
  ) {
    return null;
  }
  return (
    <EditRowDialog
      row={p.row}
      headerList={headers}
      open={true}
      onClose={() => p.onClose(true)}
    />
  );
};

const getWidth = (h) => {
  switch (h.editor) {
    case "date":
      return 170;
    case "date-year":
      return 130;
    case "checkbox":
      return 120;
    case "text":
      return 120;
    case "textarea":
      return 320;
    case "number":
      return 100;
    case "multi-dropdown":
      return 250;
    case "dropdown":
      return 200;
    default:
      return 200;
  }
};

const getHeight = (th) => {
  if (th >= 250 && th < 450) {
    return 60;
  }
  if (th >= 450 && th < 800) {
    return 80;
  }
  if (th >= 800 && th < 1400) {
    return 100;
  }
  if (th >= 1400) {
    return 140;
  }
  return 40;
};

const getTradeGroupHeight = (th) => {
  if (th >= 250 && th < 800) {
    return 60;
  }
  if (th >= 800 && th < 1400) {
    return 100;
  }
  if (th >= 1400) {
    return 120;
  }
  return 30;
};

const createFormatter = (p, h) => {
  if (p.row.type === "inspection_tradeGroup" && h.key === "trade") {
    const text = p.row.tradeGroup?.value.split(":");
    if (text.length > 1) {
      return (
        <div style={{ ...headingStyle, margin: 0, padding: 5 }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            <strong style={{ color: "#000000" }}>
              {text[0].trim()}
              :&nbsp;
            </strong>
            {text[1].trim()}
          </Typography>
        </div>
      );
    }
    return (
      <div style={headingStyle}>
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          {p.row.tradeGroup?.value}{" "}
        </Typography>
      </div>
    );
  } else if (p.row.type === "inspection_tradeGroup" && h.key === "info") {
    return getFormatter(p, h);
  } else if (p.row.type === "trade") {
    return getFormatter(p, h);
  } else if (p.row.type === "inspection_category" && h.key === "trade") {
    return (
      <>
        <div style={headingStyle}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {p.row.subCategory.value}
          </Typography>
        </div>
      </>
    );
  } else {
    return null;
  }
};

const createSelectRowFormatter = (p, x, dataGridRef, setEditRow) => {
  if (p.row.type === "inspection_tradeGroup") {
    return null;
  }
  if (p.row.type === "inspection_category") {
    return (
      <>
        <div
          className="d-flex justify-content-start align-items-center"
          style={{
            width: "50%",
            position: "relative",
            margin: 1,
            height: 30,
          }}
        >
          {SelectColumn.formatter(p, x)}
        </div>
        <Divider orientation="vertical" flexItem />

        <CustomIconButton
          id="custom_block-paste"
          handleClick={() => dataGridRef.current.blockPasteSelect(p)}
          icon={<ContentPasteGoIcon style={{ color: "#254a9a" }} />}
          tooltip={{
            title: getIntlTranslation("tooltip.Click-to-paste-selected-rows"),
          }}
        />
      </>
    );
  }
  return (
    <>
      <div
        className="d-flex justify-content-start"
        style={{
          width: "50%",
          position: "relative",
        }}
      >
        {SelectColumn.formatter(p, x)}
      </div>
      <Divider orientation="vertical" flexItem />
      <div style={{ width: "50%" }}>
        <RowOptionsMenu
          grid={p}
          gridRef={dataGridRef}
          onEditRow={(x) => {
            setEditRow({
              isOpen: true,
              selectedRow: x.row,
            });
          }}
        />
      </div>
    </>
  );
};
function StatutoryGridWrapper(props) {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const inspection = useSelector((state) => state.tddInspection);
  const readOnlystate = useSelector((state) => state.readOnly);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const dataGridRef = useRef(null);
  const [headers, setHeaders] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [editRow, setEditRow] = useState({
    isOpen: false,
    selectedRow: null,
  });
  const [blockSubAssetChange, setBlockSubAssetChange] = useState({
    block: false,
    newSubAsset: {},
  });

  const selectRow = {
    ...SelectColumn,
    name: "Action",
    key: "action",
    required: true,
    checked: true,
    order: 0,
    headerCellClass: "header-cell",
    width: 80,
    maxWidth: 80,
    frozen: true,
    headerRenderer: (col) => renderHeader(col.column.name),
    cellClass(args) {
      if (args.type === "inspection_category") {
        return "g-btn-secondary fw-bold";
      }
      if (args.type === "inspection_tradeGroup") {
        return "group-cell";
      }
      return undefined;
    },
    formatter(p, x) {
      return (
        <Box
          className="d-flex align-align-items-center justify-content-between p-0 w-100"
          style={getStyle(p)}
        >
          {createSelectRowFormatter(p, x, dataGridRef, setEditRow)}
        </Box>
      );
    },
  };
  const createFilterOptions = useCallback(
    (tempHeaders, catogeryArr) => {
      let status = [];
      const documentStatus = [];
      const documentStatusOptions = [
        "missing",
        "defectFree",
        "significantly",
        "simply",
      ];
      tempHeaders.forEach((h) => {
        if (h.key === "status") {
          status = {
            options: h.options
              .filter((f) => f.language.toLowerCase() === "de")
              .map((m) => ({ ...m, key: h.key, header: h })),
          };
        } else if (documentStatusOptions.includes(h.key)) {
          documentStatus.push({
            title: h.name,
            language: scope.projectLanguageCode,
            value: h.name,
            key: h.key,
            header: { ...h },
          });
        }
      });
      return {
        categories: { options: catogeryArr },
        documentStatus: { options: documentStatus },
        status,
      };
    },
    [scope.projectLanguageCode]
  );

  useEffect(() => {
    if (!!openAsset && openAsset?.subAssetIdent) {
      dispatch(
        fetchRows({
          projectIdent: scope.projectIdent,
          subAssetIdent: openAsset.subAssetIdent,
        })
      );
      dispatch(setMinimized(true));
    }
  }, [dispatch, openAsset, openAsset.subAssetIdent, scope.projectIdent]);

  useEffect(() => {
    if (!!inspection.grid?.data && inspection.grid?.type === "SUCCESS") {
      const grid = inspection.grid.data;
      const tempColumn = [];
      const catogeryArr = [];
      tempColumn.push(selectRow);
      //tempColumn.push(columnAction);
      const tempHeaders = grid.headers.filter((f) => f.key !== "position");
      tempHeaders.forEach((h) => {
        tempColumn.push({
          name: h.name,
          key: h.key,
          required: h.required,
          order: h.order,
          frozen: h.frozen,
          checked: h.required,
          headerCellClass: "header-cell",
          width: getWidth(h),
          categories: h.categoryList,
          optionalHeaders: [],
          headerRenderer: (col) => renderHeader(col.column.name),
          colSpan: (params) => {
            if (isSectionRow(params)) {
              return 3;
            }
            return 1;
          },
          cellClass(args) {
            if (args.type === "inspection_category") {
              return "g-btn-secondary";
            }
            if (args.type === "inspection_tradeGroup") {
              return "group-cell";
            }
            return null;
          },
          formatter: (p) => <div style={getStyle(p)}>{createFormatter(p, h)}</div>,
          editor: (p) =>
            p.row.type === "trade" ? getEditor(p, h, tempHeaders) : null,
          editorOptions: {
            renderFormatter: true,
          },
        });
      });

      const temp = [];
      grid.rows.forEach((m, index) => {
        if (
          !catogeryArr.find(
            (f) =>
              f.type === "inspection_category" && f.ident === m.category.ident
          )
        ) {
          catogeryArr.push({
            ...m.category,
            type: m.dataType,
            title: m.category?.value,
            header: "category",
            editor: "textarea",
          });
        }
        if (
          !temp.find(
            (f) =>
              f.type === "inspection_category" &&
              f.subCategory.ident === m.subCategory.ident
          )
        ) {
          temp.push({
            id: `row${Math.random() * 30}-${index}`,
            category: m.category,
            subCategory: m.subCategory,
            tradeGroup: m.tradeGroup,
            type: m.dataType,
            height: 35,
          });
        }

        if (m.tradeGroup.value.toLowerCase() !== "none") {
          const tsize = getTextSize(m.tradeGroup.value, "40px");
          const tgHeight = getTradeGroupHeight(tsize.width);

          const tInfoSize = getTextSize(m.info, "40px");
          const infoHeight = getTradeGroupHeight(tInfoSize.width);
          temp.push({
            id: `row${Math.random() * 30}-${index}`,
            category: m.category,
            subCategory: m.subCategory,
            info: m.info,
            tradeGroup: m.tradeGroup,
            type: "inspection_tradeGroup",
            height: tgHeight > infoHeight ? tgHeight : infoHeight,
            interval: m.interval,
          });

          if (!m.inspectionData.length) {
            Array.from({ length: 2 }, (x, i) => {
              const customRow = {
                id: `row${Math.random() * 30}-${i}`,
                category: m.category,
                subCategory: m.subCategory,
                tradeGroup: m.tradeGroup,
                tradeIdent: null,
                inspectionDataIdent: null,
                tradeOrder: 1,
                custom: true,
                type: "trade",
                original: {},
              };
              tempHeaders.forEach((h) => {
                if (h.editor === "multi-dropdown") {
                  customRow[h.key] = {
                    name: null,
                    options: [],
                    multiple: true,
                  };
                  customRow.original[h.key] = customRow[h.key];
                } else if (h.editor === "dropdown") {
                  customRow[h.key] = {
                    name: null,
                    options: [],
                    multiple: false,
                  };
                  customRow.original[h.key] = customRow[h.key];
                } else {
                  customRow[h.key] = "";
                  customRow.original[h.key] = "";
                }
              });
              customRow.interval = m.interval;
              temp.push(customRow);
            });
          }
        }
        //
        temp.push(
          m.inspectionData.map((c, i) => {
            const customData = JSON.parse(c.customData);
            const originalValue = {};
            let data = {
              id: `row${Math.random() * 30}-${i}`,
              category: c.category,
              subCategory: c.subCategory,
              tradeGroup: m.tradeGroup,
              tradeIdent: c.tradeIdent,
              tradeOrder: c.tradeOrder,
              custom: c.custom,
              deleted: c.deleted,
              inspectionDataIdent: c.inspectionDataIdent,
              updated: false,
              type: "trade",
            };
            tempHeaders.forEach((h) => {
              if (h.editor === "textarea") {
                const tsize = getTextSize(c[h.key], "35px");
                const height = getHeight(tsize.width);
                if (!data.height) {
                  data.height = height;
                } else {
                  data.height = data.height >= height ? data.height : height;
                }
              }
              if (h.editor === "multi-dropdown") {
                data[h.key] = {
                  name: c[h.key],
                  options: [],
                  multiple: true,
                };

                if (c[h.key]) {
                  const multiOption = c[h.key].split(",");
                  const muiltiTemp = multiOption.map((k) =>
                    h.options.find(
                      (option) => option.title.toLowerCase() === k.toLowerCase()
                    )
                  );
                  data[h.key] = { ...data[h.key], options: muiltiTemp };
                }
                originalValue[h.key] = data[h.key];
              } else if (h.editor === "dropdown") {
                data[h.key] = {
                  name: c[h.key],
                  options: [],
                  multiple: false,
                };
                if (c[h.key]) {
                  data[h.key] = {
                    ...data[h.key],
                    options: h.options.find(
                      (option) =>
                        option.title.toLowerCase() === c[h.key]?.toLowerCase()
                    ),
                  };
                }
                originalValue[h.key] = data[h.key];
              } else if (h.custom) {
                const headerData = customData?.find((d) => d.key === h.key);
                if (headerData) {
                  data[h.key] = headerData.value;
                  const tsize = getTextSize(headerData.value, "35px");
                  const height = getHeight(tsize.width);
                  if (!data.height) {
                    data.height = height;
                  } else {
                    data.height = data.height >= height ? data.height : height;
                  }
                } else {
                  data[h.key] = "";
                }
                originalValue[h.key] = data[h.key];
              } else {
                data[h.key] = c[h.key];
                originalValue[h.key] = c[h.key];
              }
            });
            data = { ...data, original: originalValue };
            return data;
          })
        );
      });

      const filters = createFilterOptions(tempHeaders, catogeryArr);
      setFilterOptions(filters);
      setHeaders([...tempHeaders]);
      setColumns(tempColumn);
      setDataRows(temp.flat(1));
      dispatch(resetState());
    } else if (inspection.grid?.type === "SAVED") {
      inspection.grid?.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(
        fetchRows({
          projectIdent: scope.projectIdent,
          subAssetIdent: openAsset.subAssetIdent,
        })
      );
      dispatch(resetState());
    }
  }, [inspection]);

  const getWarningText = () => {
    const element = inspection.updatedRows.reduce((group, row, index) => {
      const { trade } = row;
      group[trade] = group[trade] ?? [];
      group[trade].push(row);
      return group;
    }, {});

    return (
      <>
        <Typography variant="body1">
          {getIntlTranslation("_unsaved changes")}
        </Typography>
        <List>
          {Object.keys(element).map((el, index) => {
            return (
              <ListItem key={index} style={{ padding: 0 }}>
                <ListItemText
                  id={`${index}_${el}`}
                  primary={`${index + 1}. ${el}`}
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  const setBlockState = (val) => {
    setBlockSubAssetChange({
      block: true,
      newSubAsset: val,
    });
  };

  return (
    <>
      <div
      className="w-100 d-flex flex-row h-100 flex-wrap align-items-center"
      >
        <div style={{ width: "25%" ,  height: 50,  marginBottom: 2  }}>
          <SubAssetSwitcher
            dirtyState={inspection.updatedRows.length > 0}
            setBlockState={(val) => setBlockState(val)}
          />
        </div>
      {!!openAsset && (openAsset?.subAssetIdent || openAsset.ident) ? (
          <>
          {!!dataRows && dataRows.length > 0 && (
            <StatutoryDataGrid
              ref={dataGridRef}
              rows={dataRows}
              headers={headers}
              columns={columns}
              filterOptions={filterOptions}
              onClose={() =>
                setEditRow({
                  open: false,
                  selectedRow: null,
                })
              }
            />
          )}
        </>
      ) : (
        <Box className="w-100 m-1" style={{ height: "calc(100% - 50px)" }}>
          <NoAssets />
        </Box>
      )}
    </div>

      {editRow.isOpen && (
        <EditRowDialog
          row={editRow.selectedRow}
          headerList={headers}
          open={editRow.isOpen}
          onClose={() =>
            setEditRow({
              open: false,
              selectedRow: null,
            })
          }
        />
      )}
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={blockSubAssetChange.block}
          stayText="_back"
          saveText="Save And Leave"
          leaveText="_continue"
          onLeave={() => {
            dispatch(setOpenAsset(blockSubAssetChange.newSubAsset));
            setBlockSubAssetChange({
              block: false,
              newSubAsset: {},
            });
          }}
          onStay={() =>
            setBlockSubAssetChange({
              block: false,
              newSubAsset: {},
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText()}
        />
      )}
    </>
  );
}

StatutoryGridWrapper.propTypes = {};

export default StatutoryGridWrapper;
