import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { taskData } from "./taskData";
import { pink, yellow } from "@mui/material/colors";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useIntl } from "react-intl";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Autocomplete from '@mui/material/Autocomplete';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TaskContent = () => {
  const [searchString, setSearchString] = useState();
  const intl = useIntl();

  const filterData=[
    {
    title: "Ascending",
  },{
    title: "Descending",
  }]
  return (
    <div style={{ width: "100%" }}>
      <Grid style={{display:"flex"}}>
        <Grid
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "80%",
            height: 40,
            marginBottom: 0.5,
          }}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={intl.formatMessage({ id: "_Search Task" })}
            inputProps={{ "aria-label": "search Task" }}
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
          />
        </Grid>
        <Grid
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "20%",
            height: 40,
            marginBottom: 0.5,
            border:"solid 1px black",
            borderRadius:"5px"
          }}
        >
          <IconButton type="button"  aria-label="search">
            <FilterAltIcon />
          </IconButton>
          <Autocomplete
            sx={{ ml: 1, flex: 1,borderBottom:"0px solid" , border:"none !importent"}}
            placeholder={intl.formatMessage({ id: "_Search Task" })}
            options={filterData}
            getOptionLabel={(option) => option.title}
            inputProps={{ "aria-label": "search Task" }}
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
            renderInput={(params) => <TextField {...params} label="Sort" variant="standard" style={{borderBottom:"0px solid" , border:"none !importent"}} />}

          />
        </Grid>
      </Grid>
      <Stack
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
      >
        {taskData.map((e, index) => {
          return (
            <Item>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Checkbox />
                  <Typography variant="body2" gutterBottom>
                    {e.title}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {e.tags.map((tag) => (
                    <Chip label={tag} />
                  ))}

                  <Avatar
                    alt="Remy Sharp"
                    src={e.created_user.image}
                    sx={{ width: 30, height: 30 }}
                  />
                  <IconButton>
                    <StarBorderIcon sx={{ color: yellow[900] }} />
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Item>
          );
        })}
      </Stack>
    </div>
  );
};

export default TaskContent;
