import { useCallback } from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { PhotoDocumentationContextProvider } from "../PhotoDocumentationContext";
import TempVersionAlert from "../../../version-comparison/TempVersionAlert";
import React from "react";
import HeaderContainer from "./HeaderContainer";
import SubAssetContainer from "./SubAssetContainer";
import ImageBox from "./ImageBox";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import NoAssets from "../../../../component-bundle/NoAssets";

const PhotoDocumentationWrapper = () => {
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const openAsset = useSelector((state) => state.openAsset);
  const { assetList } = useAssetListHook();

  const getHeight = useCallback(() => {
    if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
      return { height: "calc(100% - 100px)" };
    } else {
      return { height: "calc(100% - 50px)" };
    }
  }, [openAsset.ident, tempVersion.assetIdent, tempVersion.isTemp]);
  return (
    <>
      <Box className="w-100 h-100 d-flex flex-column align-items-baseline">
        <PhotoDocumentationContextProvider>
          {tempVersion.isTemp && <TempVersionAlert />}
          <Box className="w-100" style={{ height: 50 }}>
            <HeaderContainer />
          </Box>
          {assetList.assets.length <= 0 ? (
            <NoAssets />
          ) : (
            <Box className="w-100 d-flex flex-row" style={getHeight()}>
              <Box className="w-25 h-100 overflow-hidden m-1">
                <SubAssetContainer />
              </Box>
              <Box className="w-75 d-flex flex-row overflow-hidden h-100 m-1">
                <ImageBox />
              </Box>
            </Box>
          )}
        </PhotoDocumentationContextProvider>
      </Box>
    </>
  );
};

PhotoDocumentationWrapper.propTypes = {};

export default PhotoDocumentationWrapper;
