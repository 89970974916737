import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import GuruEditor from "ckeditor5-custom-build";
import {
  Box,
  AppBar,
  IconButton,
  Toolbar,
  CardContent,
  Card,
} from "@mui/material";
import CloseIcon from "@mui/icons-material//Close";
import { useSelector } from "react-redux";
import { getDialogBoxHeader, commentsEditorConfig, getMentionFeedItems, customMentionItemRenderer } from "../../../../../../utils/helper";

const TextEditorMentions = ({editorRef ,onClose}) => {
  const usersState = useSelector((state) => state.users);

  return (
    <Card
      className="rounded-1 w-100"
    >
      <CardContent dividers className="p-1">
        <Box
          id={`editor_box_email`}
          className="g-text-editor custom-scrollBar p-1"
          style={{
            minHeight: "200px",
            width: "100%",
            height: "98%",
          }}
        >
          <CKEditor
            id={`editor_email`}
            ref={editorRef}
            editor={GuruEditor.ClassicEditor}
            onReady={(editor) => {
              editorRef.current = editor;
            }}
            config={{
              ...commentsEditorConfig,
              placeholder: "Type your message here...",
              mention: {
                feeds: [
                  {
                    marker: "@",
                    feed: (queryText) => getMentionFeedItems(queryText, usersState),
                    itemRenderer: customMentionItemRenderer,
                  },
                ],
              },
            }}
          />
        </Box>
      </CardContent>
      
    </Card>
  );
};

export default TextEditorMentions;
