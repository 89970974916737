import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import DocumentMap from "./DocumentMap";
import CustomOption from "./CustomOption";
import CustomBox from "./CustomBox";
import { useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";

const ChapterCustomStyle = {
  width: "25%",
  borderRight: "2px solid",
  borderColor: "#808080",
  minHeight: `calc(100vh - 140px)`,
  maxHeight: `calc(100vh - 140px)`,
  overflowY: "auto",
  overflowX: "hidden",
};

const SectionMap = () => {
  const [currentChapter, setcurrentChapter] = useState({});
  const [currentSelect, setcurrentSelect] = useState({});
  const [currentSection, setcurrentSection] = useState({});
  const [currentSubSection, setcurrentSubSection] = useState({});
  const [option, setOption] = useState([]);
  const [isdragging, setIsDragging] = useState(false);
  const chapter = useSelector((state) => state.tddChapters);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "card",
    drop: (item) => addCardToBoard(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addCardToBoard = (element) => {
    const el = { ...element.option, key: uuidv4() };
    setOption((option) => [...option, el]);
  };

  useEffect(() => {
    setOption([]);
  }, [currentChapter, currentSelect, currentSection, currentSubSection,chapter]);

  const handleRemoveOption = (item) => {
    const newData = option.filter((el) => el.key !== item.key);
    setOption(newData);
  };
  const renderChapterData = useCallback(() => {    
    if (!!currentChapter && !!currentChapter.name) {
      return (
        <CustomBox
          option={option}
          current={currentSelect}
          chapterData={currentChapter}
          section={currentSection}
          subSection={currentSubSection}
          isdragging={isdragging}
          remove={(node) => handleRemoveOption(node)}
        />
      );
    } else {
      return null;
    }
  }, [currentChapter, option, currentSelect, currentSection, currentSubSection, isdragging]);

  return (
    <Box
      sx={{
        width: "100%",
        height:"100%",
        display: "flex",
        backgroundColor: "#ffffff",
      }}
    >
      <Box sx={ChapterCustomStyle} className="custom-scrollBar">
        <DocumentMap
          setReport={(val) => {
            setcurrentChapter(null);
          }}
          onNodeSelect={(node) => setcurrentSelect(node)}
          onChapterSelect={(node) => setcurrentChapter(node)}
          onSectionSelect={(node) => setcurrentSection(node)}
          onSubSectionSelect={(node) => setcurrentSubSection(node)}
          current={currentSelect}
        />
      </Box>
      <Box
        sx={{
          width: "55%",
          height:"100%",
          // borderStyle: "dashed",
        }}
        ref={drop}
      >
        {renderChapterData()}
      </Box>
      <Box sx={{ width: "25%" }}>
        {currentSelect.children?.length <= 0 && (
          <CustomOption
            current={currentSelect}
            type="section"
            setIsDragging={setIsDragging}
          />
        )}
      </Box>
    </Box>
  );
};

export default SectionMap;
