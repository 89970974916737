import React, { useRef } from "react";
import { Box, Chip, InputLabel, ListItem, Typography } from "@mui/material";
import { ImageUploaderContainer } from "./ImageUploaderContainer";
import { b64toBlob, getIntlTranslation } from "../../../../../../utils/helper";

export const TypeImageTableDataRender = ({ item, reportType }) => {
  let data = item.node;
  const imageRef = useRef(null);
  return (
    <Box className="w-100 d-flex flex-column p-1">
      <div className="m-1">
        <InputLabel
          htmlFor={data.label}
          style={{
            width: "100%",
            margin: 5,
            fontWeight: "bold",
            color: "#778BC4",
          }}
        >
          {data.label}
        </InputLabel>
      </div>

      <Box className="d-flex justify-content-start w-100 m-2">
        <ImageUploaderContainer
          images={[data]}
          ref={imageRef}
          reportType={reportType}
          width={500}
          height={350}
          maxFiles={1}
          isDragable={false}
          isQuelleNeeded={false}
        />
      </Box>
    </Box>
  );
};

const GetImageStructure = ({ images }) => {
  return (
    <Box className="d-flex flex-wrap w-100">
      {images.map((image, index) => {
        const imageData = { ...image.imageMetaDto, src: "" };
        if (!!imageData.file) {
          imageData["src"] = b64toBlob(imageData.file);
          return (
            <div
              className="d-flex flex-column m-1"
              style={{ width: 500, height: 350 }}
              key={`${index}-${imageData.imageIdent}-key`}
            >
              <img
                src={imageData.src}
                alt={imageData.caption || ""}
                key={`photo-${imageData.imageIdent}-${imageData.edited}`}
                style={{
                  width: 500,
                  height: 350,
                  padding: 0,
                  margin: 0,
                }}
              />
              <Box style={{ textAlign: "center" }}>
                <Typography variant="caption">{imageData.caption}</Typography>
              </Box>
            </div>
          );
        } else {
          return (
            <React.Fragment key={`empty-image-${index}`}>
              <Chip
                label={
                  <Typography
                    style={{
                      fontSize: 14,
                      width: "95%",

                      marginRight: 5,
                      textWrap: "wrap",
                    }}
                    className="text-danger m-1"
                    variant="subtitle2"
                  >
                    {getIntlTranslation("_no-data-available")}
                  </Typography>
                }
                color="error"
                variant="outlined"
                style={{ width: "95%", height: "auto", marginRight: 5 }}
              />
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
};

export const TypeImageTablePreviewRender = ({ item, reportType }) => {
  let data = item.node;
  return (
    <ListItem className="w-100 d-flex flex-column p-1 m-1">
      <InputLabel
        htmlFor={data.label}
        style={{
          width: "100%",
          margin: 5,
          fontWeight: "bold",
          color: "#778BC4",
        }}
      >
        {data.label}
      </InputLabel>

      <Box className="d-flex justify-content-start w-100 m-2">
        <GetImageStructure images={[data]} />
      </Box>
    </ListItem>
  );
};
