import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../asset-overview/assetOverviewSlice";
import { fetchAssetList } from "../../../api/assetOverviewApi";

function useAssetListHook (props) {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const [assetList, setAssetList] = useState({
    isFetchSuccess:false,
    assets:[],
  });
  const assets = useSelector((state) => state.assetOverview);

  useEffect(() => {
    if (assets.assetList.type === "SUCCESS" || assets.assetList.type ==="FETCHED") {
      setAssetList({
        isFetchSuccess:true,
        assets: assets.assetList.data.filter((e) => !!e),
      });
      dispatch(resetState());
    }
  }, [assets, dispatch]);

  const fetchList = useCallback(() => {
    if (scope.projectIdent) {
      const request = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(request));
    }
  }, [scope.api, scope.projectIdent, dispatch]);

  return {assetList, fetchList};
}

export default useAssetListHook;
