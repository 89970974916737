import React, { useState,useEffect} from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { getLabel } from "../../../../utils/helper";

function CustomAutoComplete(props) {
  const { id, options, defaultValue, style } = props;
  const [value, setValue] = useState(defaultValue)


  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  

const checkNeedToUpdate=() => {
  if(value !== defaultValue){
    props.onChange({value:value})
  }
}

  return (
    <Autocomplete
      freeSolo={true}
      id={id}
      key={id}
      value={!!value ? value : ""}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      disableClearable
      autoComplete={true}
      blurOnSelect={true}
      style={style}
      options={options}
      inputValue={!!value ? value : ""}
      onInputChange={(event, newInputValue) => {
        if (typeof newInputValue === "string") {
          //debouncedTextChangeHandler(newInputValue);
          setValue(newInputValue);
        }
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue(newValue.inputValue);
        } else {
          if (!!newValue) {
            props.onChange(newValue);
          } else {
            props.onChange("");
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          multiline
          label={props.label}
          variant={props.variant}
          dense="true"
          maxRows={5}
          error={props.error}
          helperText={props.helperText}
          onBlur={ (e) => {
            checkNeedToUpdate()
          }
        }
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);

        return (
          <>
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "red" : "black",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                {option.type === "measure" && option.hasChildren ? (
                  <Typography variant="caption">
                    <span style={{ color: "#008000", marginLeft: 5 }}>
                      {getLabel("_cost-suggestion-available")}
                    </span>
                  </Typography>
                ) : null}
              </div>
            </li>
          </>
        );
      }}
    />
  );
}

CustomAutoComplete.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  style: PropTypes.object,
  label: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

export default CustomAutoComplete;
