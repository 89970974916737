import { List, ListItem, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import RouterPrompt from "../../../../../components/prompt/RouterPrompt";
import { response } from "../../../../../utils/defaultValues";
import {
  getTranslationVariantText,
  getVariantText,
  getNodeTitle,
} from "../../../../../utils/helper";
import { setOpenAsset } from "../../../../component-bundle/assetSwitcher/OpenAssetSlice";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import useReorder from "../../../../component-bundle/hooks/useReorderHook";
import {
  addProperties,
  deleteProperty,
  editProperty,
  fetchProjectUsers,
  fetchProperties,
  saveProperties,
} from "../../../api/monitoringProjectInformationApi";
import { resetPromptState } from "../../../projectmonitoringModuleSlice";
import {
  onSelectReportVersion,
  setVersions,
} from "../../key-findings/keyFindingsSlice";
import {
  getCustomDelete,
  getCustomSuccess,
  getCustomUpdate,
  getFetchSuccess,
  getProjectUserSuccess,
  getSaveSuccess,
  resetState,
  setCurrentVersion,
  setUpdatedRows,
} from "../projectInformationSlice";

export const ProjectInformationContext = createContext(undefined);

export const useProjectInformationContext = () => {
  const context = useContext(ProjectInformationContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

export const ProjectInformationContextProvider = ({ children, moduleApi }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.projectInformation);
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const openAsset = useSelector((state) => state.openAsset);
  const readOnlystate = useSelector((state) => state.readOnly);
  const [blockAssetChange, setBlockAssetChange] = useState({
    block: false,
    newAsset: {},
  });
  const { assetList } = useAssetListHook();
  const [assets, setAssets] = useState({
    isAssigned: false,
    data: [],
  });
  const [reorderResponse, onReorder, onResetResponse] = useReorder();
  const [properties, setProperties] = useState([]);
  const [userList, setUserList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [customActionSuccess,setCustomActionSuccess] = useState(false);

  useEffect(() => {
    if (!!openAsset.ident) {
      dispatch(resetState());
      dispatch(setCurrentVersion({}));
      setAssets({
        isAssigned: false,
        data: [],
      });
    }
  }, [dispatch, openAsset.ident]);

  useEffect(() => {
    if (!!openAsset.ident && assetList.isFetchSuccess) {
      const list = assetList.assets.map((m) => {
        return {
          ...m,
          address: { ...m.addressDto },
          reportVersion: [
            ...m.reportVersionDtos.map((rv) => {
              return { ...rv, label: rv.versionName, value: rv.ident };
            }),
          ],
        };
      });

      let found = list.find(
        (f) =>
          f.assetIdent === openAsset.ident &&
          scope?.projectIdent === openAsset?.projectIdent
      );
      let currentversion = found.reportVersion.find((f) => f.current);
      if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
        currentversion = found.reportVersion.find(
          (f) => f.ident === tempVersion.reportVersionIdent
        );
      }
      let temp = [
        ...found?.subAssets.map((m) => m).sort((a, b) => b.general - a.general),
      ];
      dispatch(setCurrentVersion(currentversion || {}));
      dispatch(onSelectReportVersion(null));
      dispatch(setVersions(found.reportVersion));
      setAssets({
        isAssigned: true,
        data: list,
      });
    }
  }, [
    assetList,
    assets.isAssigned,
    dispatch,
    openAsset.ident,
    openAsset?.projectIdent,
    scope?.projectIdent,
    tempVersion,
  ]);

  const fetchProps = useCallback(() => {
    if (
      !!openAsset.ident &&
      !!state.currentVersion &&
      !!state.currentVersion.ident
    ) {
      dispatch(
        fetchProperties({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          version: state.currentVersion.ident,
        })
      );
    }
  }, [
    state.currentVersion,
    dispatch,
    moduleApi,
    openAsset.ident,
    scope.api,
    scope.projectIdent,
  ]);

  const fetchUsers = useCallback(() => {
    dispatch(
      fetchProjectUsers({
        moduleApi: moduleApi,
        api: scope.api,
        projectIdent: scope.projectIdent,
      })
    );
  }, [dispatch, moduleApi, scope.api, scope.projectIdent]);

  const handleReorder = useCallback(
    (items) => {
      const reorder = items.map((item, index) => ({
        ...item,
        nodeOrder: index + 1,
      }));
      onReorder({
        reorderApi: moduleApi,
        data: reorder,
      });
    },
    [moduleApi, onReorder]
  );

  const onSave = useCallback(() => {
    let temp = { ...state.updatedRows };
    const updatedRows = [];
    Object.keys(temp).forEach((key) => {
      let item = state.updatedRows[key];
      if (item.updated) {
        const users = item.userDtos.map((u) => ({
          ...u,
          type: "projectUserDto",
        }));
        item = { ...item, userDtos: users };
        updatedRows.push(item);
      }
    });
    dispatch(
      saveProperties({
        moduleApi: moduleApi,
        api: scope.api,
        projectIdent: scope.projectIdent,
        version: state.currentVersion.ident,
        data: updatedRows,
      })
    );
  }, [dispatch, moduleApi, scope.api, scope.projectIdent, state.currentVersion.ident, state.updatedRows]);

  useEffect(() => {
    if (assets.isAssigned && !!state.currentVersion) {
      fetchProps();
      fetchUsers();
    }
  }, [assets.isAssigned, state.currentVersion, fetchProps, fetchUsers]);

  useEffect(() => {
    if (state.fetch.type === "FETCHED" && state.fetch.data.length > 0) {
      setProperties(
        state.fetch.data.map((m) => ({
          ...m,
          value: m.nodeValue,
          nodeTitle: getNodeTitle(m, scope.projectLanguageCode),
        }))
      );
      dispatch(getFetchSuccess({ ...response, guruDtoList: [] }));
    }
    if (
      state.projectUsers.type === "FETCHED" &&
      state.projectUsers.data.length > 0
    ) {
      setUserList(
        state.projectUsers.data.map((m) => ({
          ...m,
          name: `${m.firstName} ${m.lastName}`,
        }))
      );
      dispatch(getProjectUserSuccess({ ...response, guruDtoList: [] }));
    }
    if (state.save.type === "SAVED") {
      state.save.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getSaveSuccess({ ...response, guruDto: {} }));
      dispatch(setUpdatedRows({}))
      fetchProps();
    }
    if (state.custom.type === 'SAVED') {
      state.custom.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomSuccess({...response,guruDtoList:{}}));
      fetchProps();
    }
    if (state.customUpdate.type === 'SAVED') {
      dispatch(getCustomUpdate({...response,guruDtoList:{}}));
      state.customUpdate.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      setCustomActionSuccess(true);
      fetchProps();
    }
    if (state.customDelete.type === 'DELETED') {
      dispatch(getCustomDelete({...response,guruDto:{}}));
      state.customDelete.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
      setCustomActionSuccess(true);
      fetchProps();
    }
    if (reorderResponse.type === "SAVED") {
      setProperties(
        reorderResponse.data.map((m) => ({
          ...m,
          value: m.nodeValue,
          nodeTitle: getNodeTitle(m, scope.projectLanguageCode),
        }))
      );
      onResetResponse();
    }
  }, [dispatch, enqueueSnackbar, fetchProps, onResetResponse, reorderResponse.data, reorderResponse.type, scope.projectLanguageCode, state.custom.messages, state.custom.type, state.customDelete.messages, state.customDelete.type, state.customUpdate.messages, state.customUpdate.type, state.fetch, state.projectUsers, state.save.messages, state.save.type]);

  const onRowUpdate = useCallback(
    (node, value) => {
      let temp = { ...state.updatedRows };
      temp[node.nodeIdent] = { ...node, nodeValue: value, updated: true };
      dispatch(setUpdatedRows(temp));
    },
    [dispatch, state.updatedRows]
  );

  const isDataUpdated = useCallback(() => {
    return (
      Object.keys(state.updatedRows)?.filter(
        (f) => state.updatedRows[f].updated
      ).length > 0
    );
  }, [state.updatedRows]);


  const onEditProperty = useCallback(
    (data) => {
      dispatch(
        editProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          version: state.currentVersion.ident,
          data: data,
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent, state.currentVersion.ident]
  );

  const convertToRequiredStructure = useCallback((data) => {
    return data.map((d) => {
      return {
        assetIdent:openAsset.ident,
        custom: d.custom,
        nodeIdent: d.nodeIdent,
        labelTranslation: d.labelTranslation,
        nodeName: d.nodeName,
        nodeType: d.nodeType,
        nodeOrder: d.nodeOrder,
        currentVersion: state.currentVersion.ident,
        required: false,
        type: 'monitoringProjectInformation',
      };
    });
  },[openAsset.ident, state.currentVersion.ident]);


  const onAdditionalProperty = useCallback(
    (data) => {
      dispatch(
        addProperties({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          assetIdent: openAsset.ident,
          data:convertToRequiredStructure(data),
          versionIdent:state.currentVersion.ident
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent, openAsset.ident, convertToRequiredStructure, state.currentVersion.ident]
  );

  const onDeleteProperty = useCallback(
    (data) => {
      dispatch(
        deleteProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          propertyIdent: data.propertyIdent,
          version: state.currentVersion.ident,
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent,state.currentVersion]
  );

  const getWarningText = useMemo(() => {
    const updatedRows = [];
    Object.keys(state.updatedRows)?.forEach((f) => {
      if (state.updatedRows[f].updated) {
        updatedRows.push(state.updatedRows[f]);
      }
    });
    return (
      <>
        {updatedRows.length > 0 ? (
          <>
            {getTranslationVariantText("_unsaved changes", "subtitle2")}
            <List>
              {updatedRows.map((el, index) => {
                return (
                  <ListItem key={index} style={{ padding: 0 }}>
                    <ListItemText
                      id={`${index}_${el}`}
                      primary={getVariantText(
                        `${index + 1}. ${el.nodeTitle}`,
                        "subtitle2",
                        { fontWeight: "bold" }
                      )}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : null}
      </>
    );
  }, [state.updatedRows]);

  return (
    <ProjectInformationContext.Provider
      value={{
        properties,
        userList,
        customActionSuccess,
        setCustomActionSuccess,
        isDataUpdated,
        setBlockAssetChange,
        onRowUpdate,
        handleReorder,
        onSave,
        onEditProperty,
        onDeleteProperty,
        onAdditionalProperty,

      }}
    >
      {children}
      {!readOnlystate.isReadOnly && blockAssetChange.block && (
        <InternalPrompt
          when={blockAssetChange.block}
          stayText={"_back"}
          saveText="Save And Leave"
          leaveText={"_continue"}
          onLeave={() => {
            dispatch(setCurrentVersion({}));
            dispatch(setOpenAsset(blockAssetChange.newAsset));
            setBlockAssetChange({
              block: false,
              newAsset: {},
            });
          }}
          onStay={() =>
            setBlockAssetChange({
              block: false,
              newAsset: {},
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText}
        />
      )}
      {!readOnlystate.isReadOnly && isDataUpdated() && (
        <RouterPrompt
          when={isDataUpdated()}
          cancelText="_back"
          okText="_continue"
          warningHeader={"Save And Leave"}
          onOK={() => dispatch(resetPromptState())}
          onCancel={() => false}
          message={getWarningText}
        />
      )}
    </ProjectInformationContext.Provider>
  );
};
