import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isReadOnly:false,
};

const readOnlySlice = createSlice({
  name: "readOnly",
  initialState,
  reducers: {
    setReadOnly: (state, { payload }) =>{ 
      return {
      ...state,
      isReadOnly: payload === null ? false : payload
    }},
    resetState: (state) => ({
      ...initialState,
      isReadOnly: false,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  setReadOnly,
  resetState,
} = readOnlySlice.actions;

// A selector
export const readOnlySliceSelector = (state) => state.readOnly;

// The reducer
export default readOnlySlice.reducer;
