import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import GuruEditor from "ckeditor5-custom-build";
import CustomIconButton from "../../../../../../components/buttons/icons-buttons/CustomIconButton";
import SaveIconButton from "../../../../../../components/buttons/icons-buttons/SaveIconButton";
import {
  normalEditorConfig,
  getIntlTranslation,
  getBorderColor,
  getBackground,
} from "../../../../../../utils/helper";
import TextEditorHistory from "./TextEditorHistory";
import HistoryIcon from "@mui/icons-material/History";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { resetState } from "./textEditorSlice";
import { useIntl } from "react-intl";

const RenderTextEditor = ({ node, onSave, inReviewMode = false,onSaveSuccess,onClose }) => {
  const intl = useIntl();
  const [currNode, setCurrNode] = useState(null);
  const [isTextUpdated, setIsTextUpdated] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.textEditor);
  const [viewHistory, setViewHistory] = React.useState({
    open: false,
    label: null,
    data: null,
  });
  const editorRef = useRef();
  const dataRef = useRef(node);

  const handleSave = () => {
    if (!!editorRef.current) {
      const data = {
        type: "reportTextDto",
        inputValue: editorRef.current.getData(),
        reportIdent: dataRef.current.ident,
        syncData: false,
      };
      onSave(data);
    }
  };

  useEffect(() => {
    if (!!node) {
      dataRef.current = node;
      setCurrNode(node);
    }
  }, [node]);

  useEffect(() => {
    if (
      state.saved.type === "SAVED" &&
      !!state.saved.data &&
      dataRef.current?.ident === state.saved.data.reportIdent
    ) {
      state.saved.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });

      const savedData = {
        ...dataRef.current,
        ident: state.saved.data.reportIdent,
        value: state.saved.data.inputValue,
      };
      setCurrNode(savedData);
      dataRef.current = savedData;
      dispatch(resetState());
      setIsTextUpdated(false);
      onSaveSuccess(savedData);
    }
  }, [dispatch, enqueueSnackbar, state.saved]);

  return (
    <>
      {!!currNode ? (
        <>
          <Box
            className="d-flex align-items-baseline w-100 flex-wrap p-1"
            key={currNode.ident}
            style={{ 
              marginTop: 10,
              borderLeft: getBorderColor(isTextUpdated),
              backgroundColor: getBackground(isTextUpdated)
            }}
          >
            {(!!currNode.label && !inReviewMode) && (
              <Box style={{ width: "100%" }}>
                <Typography
                  style={{
                    fontSize: 11,
                    color: "#254a9a",
                    fontWeight: "bold",
                  }}
                  className="m-1"
                >
                  {currNode.label}
                </Typography>
              </Box>
            )}
            <Box
              className="d-flex align-items-baseline"
              style={{
                minHeight: "200px",
                width: "100%",
              }}
            >
              {!!currNode && currNode.userDefined ? (
                <>
                  <Box
                    id={`editor_box_${currNode.ident}`}
                    className="g-text-editor custom-scrollBar p-1"
                    style={{
                      minHeight: "200px",
                      width: "100%",
                      height: "98%",
                    }}
                  >
                    <CKEditor
                      id={`editor_${currNode.ident}`}
                      ref={editorRef}
                      editor={GuruEditor.ClassicEditor}
                      onReady={(editor) => {
                        editorRef.current = editor;
                      }}
                      onChange={(event, editor) => setIsTextUpdated(dataRef.current.text !== editor.getData())}
                      disabled={!currNode.userDefined}
                      config={{ ...normalEditorConfig,placeholder: intl.formatMessage({ id:"_text-editor-placeholder"}) }}
                      data={currNode.text}
                    />
                  </Box>
                  <Box
                    style={{
                      width: "10%",
                      minHeight: "200px",
                      position: "sticky",
                      top: "0",
                    }}
                    className="d-flex flex-column align-items-baseline"
                  >
                    <>
                    {inReviewMode && 
                    <IconButton
                      edge="start"
                      id={`close_${node.ident}`}
                      onClick={(e) => onClose(node)}
                      className="w-100 p-2"
                      aria-label="close"
                    >
                      <CloseIcon style={{ color: "#d08f8e" }} />
                    </IconButton>
                    }

                      <SaveIconButton
                        id={`save_${currNode.ident}`}
                        edge="start"
                        style={{ marginLeft: 0, width: "100%" }}
                        handleClick={() => {
                          handleSave();
                        }}
                        tooltip={{
                          title: getIntlTranslation("tooltip.Save-report-Text"),
                        }}
                        color={"#254a9a"}
                        label={"_save"}
                      />
                      {!inReviewMode && 
                      <CustomIconButton
                        id={`show_history_${currNode.ident}`}
                        icon={<HistoryIcon />}
                        edge="start"
                        style={{ marginLeft: 0, width: "100%" }}
                        handleClick={(e) => {
                          setViewHistory({
                            open: true,
                            label: null,
                            data: null,
                          });
                        }}
                        color="primary"
                        tooltip={{
                          title: getIntlTranslation("_history"),
                        }}
                        label={"_history"}
                        aria-label="show history"
                      />
                        }
                    </>
                  </Box>
                </>
              ) : (
                <Typography
                  style={{
                    fontSize: 11,
                    width: "90%",
                    textAlign: "justify",
                  }}
                  dangerouslySetInnerHTML={{ __html: currNode.text }}
                  className="label-text"
                ></Typography>
              )}
            </Box>
          </Box>
          {viewHistory.open && (
            <TextEditorHistory
              isOpen={viewHistory.open}
              rtIdent={currNode.ident}
              currentValue={currNode.text}
              close={() =>
                setViewHistory({
                  open: false,
                  label: null,
                  data: null,
                })
              }
            />
          )}
        </>
      ) : (
        <List>
          {[1, 2, 3, 4, 5].map((index) => (
            <ListItem key={index}>
              <ListItemText>
                <Skeleton variant="text" />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default RenderTextEditor;
