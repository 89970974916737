import { createSlice } from "@reduxjs/toolkit";
import { response } from "../utils/defaultValues";

export const initialState = {
  loading: false,
  hasErrors: false,
  openProfile: false,
  fetch:{...response},
  save:{...response},
  error:{...response},
  offices:{...response},
  officeFailure:{...response},



};
// A slice for auth with our success or failure reducers
const userProfileSlice = createSlice({
  name: "UserProfile",
  initialState,
  reducers: {
    getUserProfileData: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetch:{
        type:payload.type,
        message:payload.messages,
        data: payload.guruDto,
      }
    }),
    getUserUpdateSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save:{
        type:payload.type,
        message:payload.messages,
        data: payload.guruDto,
      }
    }),
     getUserUpdateFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
        data:payload.guruDto,
        type:payload.type,
        message:payload.messages,
      }
    }),
    getOfficesFailure : (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
        type:payload.type,
        message:payload.messages,
      }
    }),
    getOffices: (state, {payload})=>({
      ...state,
      offices:{
        type:payload.type,
        message:payload.messages,
        data: payload.guruDtoList,
      }
    }),
     resetState: (state) => ({
      ...initialState,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getUserProfileData,
  getUserUpdateSuccess,
  getUserUpdateFailure,
  resetState,
  toggleProfile,
  getOffices,
  getOfficesFailure
} = userProfileSlice.actions;

// A selector
export const userProfileSliceSelector = (state) => state.userProfile;

// The reducer
export default userProfileSlice.reducer;
