import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";

import {
  getFailure as failure,
  getDefectSuccess,
  getDefectCopySuccess,
  getMultipleUpdateSuccess,
  getDefectSaveSuccess,
} from "../feature-bundle/defects/defectSlice";

import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from "../../../api/authApi";

export const getDefect = (obj) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/defect/${obj.projectIdent}/fetch-defect/${obj.ident}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(failure(response.data));
    } else {
      dispatch(getDefectSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const copyDefects = (obj) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/defect/copy-defects/${obj.projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "copyDefectsDto",
          subAssetIdent: obj.subAssetIdent,
          defectIdents:obj.defectIdents
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(failure(response.data));
    } else {
      dispatch(getDefectCopySuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const updateDefectAndMeasure = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/defect/update-multiple-defects/${request.projectIdent}`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data:{
          type:"defectListDto",
          defectList:request.defectList
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(failure(response.data));
    } else {
      dispatch(getMultipleUpdateSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const saveElementAndDefect = (saveObj) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/element/saveElementAndDefect/${saveObj.projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "saveElementDefectDto",
          elementDto: saveObj.elementDto,
          defectDto: saveObj.defectDto,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(failure(response.data));
    } else {
      dispatch(getDefectSaveSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};