import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { pmServiceLine} from "../../utils/defaultValues";
import { Navigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setCompleteReset } from './projectmonitoringModuleSlice';

function MonitoringProjectScopeWrapper({ children }) {
      const scope = useSelector((state) => state.container.scope);
      const dispatch = useDispatch();
      useEffect(() => {
        dispatch(setCompleteReset());
      }, []);
      
      const validateProjectScope = () => {
        return (scope.isProjectScope && scope.serviceLine.toLowerCase() === pmServiceLine.name.toLowerCase() && !!scope.projectIdent)
    
      }
      return validateProjectScope() ? (
         children
      ) : (
        <Navigate to={`${pmServiceLine.root}/project-list`} replace />
      );
    };

export default MonitoringProjectScopeWrapper