import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  data: "",
};

const imageUploadSlice = createSlice({
  name: "imageUpload",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      data: "",
    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDto,
    }),
    resetState: (state) => ({
      ...state,
      hasErrors: false,
      type: "",
      messages: "",
      data: "",
    }),
  },
});

// eslint-disable-next-line max-len
export const { getFailure, resetState, getSuccess } = imageUploadSlice.actions;

// A selector
export const imageUploadSelector = (state) => state.tddImageUpload;

// The reducer
export default imageUploadSlice.reducer;
