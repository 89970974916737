import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemButton,
  Box,
  Card,
  CardContent,
  CardActions,
  Toolbar,
  Divider,
  Grow,
  Tooltip,
} from "@mui/material";
import {
  getVariantText,
  getTranslationVariantText,
  getIntlTranslation,
  sortColumn,
} from "../../../utils/helper";
import InputSearch from "../../../components/InputSearch";
import FolderIcon from "@mui/icons-material/Folder";

import TopicIcon from "@mui/icons-material/Topic";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SortingMenu from "./SortingMenu";
import FilteringMenu from "./FilteringMenu";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import ClientProjectList from "./ClientProjectList";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ProjectListView from "./ProjectListView";
import { useIntl } from "react-intl";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';

const ChapterListStyle = {
  width: "100%",
  borderRight: "2px double #C1D0F0",
  height: "100%",
  overflow: "auto",
  marginRight: 4,
  borderRadius: 8,
};

const cellStyle = {
  marginLeft: 5,
  borderRight: "1px solid #0000001f",
  height: "100%",
  padding: 5,
};
const ClientManagementContainer = (props) => {
  const intl = useIntl();
  const scope = useSelector((state) => state.container.scope);
  const [clientList, setClientList] = useState([]);
  const [filterList, setfilterList] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    anchor: null,
  });
  const [menuAnchor, setMenuAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [view, setView] = useState({
    isClientListView: true,
    isProjectListView: false,
  });

  const clientColumnList = useMemo(
    () => [
      {
        columnName: "clientName",
        displayName: intl.formatMessage({ id: "Client Name" }),
        type: "string",
      },
      {
        columnName: "projectCount",
        displayName: intl.formatMessage({ id: "_projects" }),
        type: "number",
      },
    ],
    []
  );

  useEffect(() => {
    if (!!props.data && props.data.length > 0) {
      let temp = props.data.map((m,index) => ({
        ...m,
        projectCount: m.projects.length,
        id:index + 1,
      }));
      setClientList(temp);
      setfilterList(temp);
    }
  }, [props.data]);

  const filterData = (value) => {
    if (!!value && value.length) {
      setfilterList(
        clientList.filter((e) => e.clientName.toUpperCase().includes(value))
      );
    } else {
      setfilterList(clientList);
    }
  };



  const applyFilter = useCallback((dataList) => {
    setfilterList(dataList);
  }, []);

  const resetFilters = useCallback(() => {
    setfilterList(clientList);
  }, [clientList]);

  const getProjectCount = (projects) => {
    let temp = [];
    projects.forEach((project) => {
      let index = temp.findIndex(
        (f) => f.label.toLowerCase() === project.serviceLine.toLowerCase()
      );
      if (index !== -1) {
        temp[index] = {
          label: project.serviceLine,
          count: temp[index].count + 1,
        };
      } else {
        temp.push({
          label: project.serviceLine,
          count: 1,
        });
      }
    });
    return (
      <>
        <Box className="d-flex flex-column w-100">
          {temp
            .sort((a, b) => a.label - b.label)
            .map((item, index) => {
              return (
                <>
                  <Box className="d-flex w-100">
                    {getVariantText(item.label, "caption", {
                      width: "60%",
                      padding: 2,
                    })}
                    {getVariantText(item.count, "caption", {
                      fontWeight: "bold",
                    })}
                  </Box>
                </>
              );
            })}
          <Divider
            variant="middle"
            className="g-divider-vertical"
            style={{ margin: 3, width: "80%", height: 2 }}
          />
          <Box className="d-flex w-100">
            {getTranslationVariantText("_Total", "caption", {
              width: "60%",
              fontWeight: "bold",
            })}
            {getVariantText(projects.length, "caption", {
              fontWeight: "bold",
            })}
          </Box>
        </Box>
      </>
    );
  };

  const handleMenuOpen = (event, columnName) => {
    setMenuAnchorEl({
      anchor: event.currentTarget,
      column: columnName,
      dataList: clientList,
    });
  };

  const sortAsc = (col) => {
    let temp = sortColumn(col.columnName, filterList);
    setfilterList(temp);
  };

  const sortDesc = (col) => {
    let temp = sortColumn(col.columnName, filterList);
    setfilterList(temp.reverse());
  };

  const getProjectTotalCount = () => {
    return filterList.reduce((a, c) => a + c.projectCount, 0);
  }

  const createClientListStruct = () => {
    return (
      <>
        <Card style={ChapterListStyle} className="h-100">
          <Toolbar
            style={{ height: 50, position: "sticky", zIndex: 1500, top:0, backgroundColor:"#ffffff" }}
            className="w-100 d-flex align-items-center justify-content-start mb-1"
          >
            <Box className="d-flex align-items-center justify-content-start">
              <IconButton
                edge="start"
                color="inherit"
                id="switch to client view"
                onClick={() =>
                  setView({
                    isClientListView: false,
                    isProjectListView: true,
                  })
                }
                aria-label="close"
              >
                <Tooltip
                  title={getIntlTranslation("tooltip.switch-to-project-list")}
                >
                  <BroadcastOnPersonalIcon
                    style={{ width: 25, height: 25, color: "#d08f8e" }}
                  />
                </Tooltip>
              </IconButton>
            </Box>
            <Divider orientation="vertical" flexItem style={{ margin: "15px 5px", backgroundColor: "#254a9a" }} />
            <Box
              style={{ width: "40%" }}
              className="d-flex align-items-center justify-content-start"
            >
              {getTranslationVariantText(`_client-list`, "caption", {
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#254a9a",
                margin: "0px 5px",
              })}
            </Box>
            <Box
              style={{ width: "50%", marginLeft: "auto" }}
              className="d-flex align-items-center justify-content-start"
            >
              <InputSearch onFilterInput={filterData} />
            </Box>
            <Box className="d-flex align-items-center justify-content-start">
              <CustomIconButton
                id={`reset-filter-options`}
                icon={
                  <RestartAltIcon
                    style={{ fontSize: "25", color: "#d08f8e" }}
                  />
                }
                edge="end"
                style={{ margin: 5, borderRadius: 4, width: 30, height: 30 }}
                handleClick={(e) => {
                  resetFilters();
                }}
                tooltip={{
                  title: getIntlTranslation("_reset"),
                }}
                label={""}
                aria-label="all filter"
              />
            </Box>
          </Toolbar>
          <CardContent className={"w-100 d-flex flex-column p-0 custom-scrollBar"} style={{ height:"calc(100% - 120px)", overflowY:"auto"}}>
            <List className={"w-100 d-flex flex-column p-2"}>
              <ListItem
                divider
                style={{
                  position: "sticky",
                  top: 0,
                  height: 40,
                  borderRadius: 4,
                  zIndex: 1500,
                  borderLeft: "1px solid #d08f8e",
                  borderRight: "1px solid #d08f8e",
                }}
                className="p-0 g-btn-primary"
                key="headers"
              >
                <ListItemButton className="p-0">
                  <>
                    <Box
                      className="d-flex align-items-center w-100"
                      style={{ height: 40 }}
                    >
                       <div
                        style={{
                          ...cellStyle,
                          width: 50,
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >{getTranslationVariantText("ID", "caption", {
                        fontWeight: "Bold",
                        textTransform: "uppercase",
                      })}

                        </div>
                   
                      <div
                        style={{
                          ...cellStyle,
                          width: "70%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                           <ListItemIcon className="p-1">
                    <TopicIcon style={{color: "#ffffff" }} />
                  </ListItemIcon>
                        {getTranslationVariantText("Client Name", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_client_name`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#ffffff" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "clientName",
                              type: "string",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by client name"
                        />
                      </div>
                     
                      <div
                        style={{
                          ...cellStyle,
                         width: "calc(30% - 50px)",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("_projects", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_projects`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#ffffff" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "projectCount",
                              type: "number",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by projects count"
                        />
                      </div>
                    </Box>
                  </>
                </ListItemButton>
              </ListItem>
              {filterList.map((m, index) => {
                return (
                  <>
                    <ListItem
                      key={index}
                      divider
                      className="p-0 d-flex flex-column"
                      style={{
                        borderLeft: "1px solid #0000001f",
                        borderRight: "1px solid #0000001f",
                      }}
                    >
                      <Box className={"w-100"}>
                        <ListItemButton
                          className="p-0"
                          onClick={(e) =>
                            setSelectedClient({
                              anchor: m.clientIdent,
                            })
                          }
                        >
                          
                          <>
                            <Box className="d-flex align-items-center justify-content-between h-100 w-100">
                            <div
                                style={{
                                  ...cellStyle,
                                  width:50,
                                  minHeight: 40,
                                }}
                              >
                                {getVariantText(m.id, "subtitle2", {
                                  padding: 5,
                                })}
                              </div>
                            
                              <div
                              className="d-flex align-items-center"
                                style={{
                                  ...cellStyle,
                                  width: "70%",
                                  minHeight: 40,
                                }}
                              >
                                {selectedClient.anchor === m.clientIdent ? (
                            <ListItemIcon
                              className="p-1"
                              style={{ borderLeft: "4px solid #5f9ea0" }}
                            >
                              <FolderOpenIcon style={{ color: "#5f9ea0" }} />
                            </ListItemIcon>
                          ) : (
                            <ListItemIcon className="p-1">
                              <FolderIcon style={{ color: "#F8D775" }} />
                            </ListItemIcon>
                          )}
                                {getVariantText(m.clientName, "subtitle2", {
                                  padding: 5,
                                })}
                              </div>
                             
                              <div
                                style={{
                                  ...cellStyle,
                                  width: "calc(30% - 50px)",
                                  minHeight: 40,
                                }}
                              >
                                {/* {getProjectCount(m.projects)} */}
                                {getVariantText(m.projectCount, "caption", {
                                  fontWeight: "bold",
                                  padding: 5,
                                })}
                              </div>
                            </Box>
                          </>
                        </ListItemButton>
                      </Box>

                      <Grow
                        in={selectedClient.anchor === m.clientIdent}
                        timeout="auto"
                        unmountOnExit
                        className={"m-2"}
                        style={{ transformOrigin: "0 0 0", width: "98%" }}
                        {...(selectedClient.anchor === m.clientIdent
                          ? { timeout: 1000 }
                          : {})}
                      >
                        <Box className={"d-flex align-center w-100"}>
                          <ClientProjectList
                            data={m}
                            onFilterSelect={(anchor) =>
                              setFilterAnchorEl({ ...anchor, openFilter: true })
                            }
                            onClose={() =>
                              setSelectedClient({
                                anchor: null,
                              })
                            }
                            resetFilters={() => resetFilters()}
                          />
                        </Box>
                      </Grow>
                    </ListItem>
                  </>
                );
              })}
            </List>
          </CardContent>
          <CardActions style={{ height: 50, marginTop: "auto"}} className="d-flex flex-column p-0">
           <List className={"w-100 h-100 p-2"} style={{ marginRight:10}}>
           <ListItem
                divider
                style={{
                  position: "sticky",
                  top: 0,
                  height: 40,
                  borderRadius: 4,
                  zIndex: 1500,
                }}
                className="p-0 g-btn-secondary"
                key="headers-1"
              >
                      <Box className={"w-100 d-flex align-items-center h-100"}>
                      <div
                                style={{
                                  ...cellStyle,
                                  width:50,
                                  borderColor: "#ffffff",
                                }}
                              >
                                {getVariantText(filterList.length, "body1", {
                                  padding: 5,
                                  fontWeight: "bold",
                                })}
                              </div>
                              <div
                                style={{
                                  ...cellStyle,
                                  width:"70%",
                                  borderColor: "#ffffff",
                                }}
                              >
                                {getVariantText("", "subtitle2", {
                                  padding: 5,
                                })}
                              </div>

                              <div
                                style={{
                                  ...cellStyle,
                                  width: "calc(30% - 50px)",
                                  borderColor: "#ffffff",
                                }}
                              >
                                {getVariantText(getProjectTotalCount(), "body1", {
                                  padding: 5,
                                  fontWeight: "bold",
                                })}
                              </div>
                        </Box>

              </ListItem>
            </List>
          </CardActions>

        </Card>
        <SortingMenu
          menuAnchor={menuAnchor}
          onClose={() => setMenuAnchorEl(null)}
          sortAsc={(col) => sortAsc(col)}
          sortDesc={(col) => sortDesc(col)}
          onFilterSelect={(anchor) =>
            setFilterAnchorEl({
              ...anchor,
              openFilter: true,
              columnList: clientColumnList,
              applyFilter,
              resetFilters,
              dataList: filterList,
            })
          }
        />
      </>
    );
  };

  return (
    <div className="d-flex h-100 w-100">
      {view.isClientListView && createClientListStruct()}
      {view.isProjectListView && (
        <ProjectListView data={clientList} switchView={(val) => setView(val)} />
      )}
      {filterAnchorEl?.openFilter && view.isClientListView && (
        <Box style={{ width: "30%" }} className="h-100">
          <FilteringMenu
            menuAnchor={filterAnchorEl}
            onClose={() => setFilterAnchorEl(null)}
            columnList={filterAnchorEl.columnList}
            dataList={filterAnchorEl.dataList}
            onSortSelect={(anchor) =>
              setMenuAnchorEl({ ...anchor, openSort: true })
            }
            applyFilter={(dataList) => filterAnchorEl.applyFilter(dataList)}
            resetFilters={() => filterAnchorEl.resetFilters()}
          />
        </Box>
      )}
    </div>
  );
};

ClientManagementContainer.propTypes = {
  data: PropTypes.array,
};

export default ClientManagementContainer;
