import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'

const DataAnalysisContainer = props => {
    const scope = useSelector((state) => state.container.scope);
  return (
    <div>DataAnalysis</div>
  )
}

DataAnalysisContainer.propTypes = {}

export default DataAnalysisContainer;