import { Typography, Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddressInput from "../../../version-comparison/input-types/AddressInput";
import AreaInput from "../../../version-comparison/input-types/AreaInput";
import DateInput, {
  YearDateInput,
} from "../../../version-comparison/input-types/DateInput";
import NumberInput from "../../../version-comparison/input-types/NumberInput";
import TextEditorInput from "../../../version-comparison/input-types/TextEditorInput";
import TextInput from "../../../version-comparison/input-types/TextInput";
import UserInput from "../../../version-comparison/input-types/UserInput";
import { useProjectInformationContext } from "../context/ProjectInformationContext";
import parse from "html-react-parser";

const RenderPropertyValue = ({ node }) => {
  const { onRowUpdate, userList } = useProjectInformationContext();
  const [inputValue, setInputValue] = useState({});
  const state = useSelector((state) => state.projectInformation);

  useEffect(() => {
    if (!!node) {
      setInputValue(node);
    }
  }, [node]);

  const getSelectedUsers = useCallback((node) => {
    return node.userDtos.map((m) => ({
      ...m,
      name: `${m.firstName} ${m.lastName}`,
    }));
  }, []);

  const renderNodeValues = (item) => {
    let node = item;
    let updatedRows = { ...state.updatedRows };
    if (!!updatedRows[node.nodeIdent]) {
      node = updatedRows[node.nodeIdent];
    }
    const id = node.nodeIdent;
    if (node.disabled) {
      return (
        <Typography
          style={{
            color:"#000000"
          }}
          variant="body1"
          dangerouslySetInnerHTML={{ __html: node.value }}
          className="label-text text-html m-1 p-1 w-100"
        ></Typography>
      );
    } else if (node.nodeType === "text-html") {
      return <div className="text-html"
      dangerouslySetInnerHTML={{ __html: node.value }}/>;
    } else if (node.nodeType.includes("_user")) {
      return (
        <UserInput
          id={`id-${id}`}
          node={{ ...node, users: getSelectedUsers(node) }}
          disabled={node.disabled}
          userList={userList}
          handleChange={(value) => {
            onRowUpdate({ ...node, userDtos: value }, "");
          }}
        />
      );
    } else if (node.nodeType.includes("-number")) {
      return (
        <Box className="d-flex flex-wrap w-100 p-1">
          <NumberInput
            id={id}
            disabled={node.disabled}
            node={node}
            handleChange={(value, node, isUpdated) => {
              if (isUpdated) {
                onRowUpdate(node, value);
              }
            }}
          />
        </Box>
      );
    } else if (node.nodeType.includes("-area")) {
      return (
        <Box className="d-flex w-100 p-1">
          <AreaInput
            id={id}
            disabled={node.disabled}
            node={node}
            handleChange={(value, node, isUpdated) => {
              if (isUpdated) {
                onRowUpdate(node, value);
              }
            }}
          />
        </Box>
      );
    }

    switch (node.nodeType) {
      case "textarea":
        return (
          <Box className="d-flex flex-column w-100">
            <TextEditorInput
              id={id}
              disabled={node.disabled}
              node={node}
              handleChange={(value, nodeVal, isUpdated) => {
                if (isUpdated) {
                  onRowUpdate(node, value);
                }
              }}
            />
          </Box>
        );

      case "number":
        return (
          <Box className="d-flex w-100 p-1">
            <NumberInput
              id={id}
              disabled={node.disabled}
              node={node}
              handleChange={(value, node, isUpdated) => {
                if (isUpdated) {
                  onRowUpdate(node, value);
                }
              }}
            />
          </Box>
        );

      case "area":
        return (
          <Box className="d-flex w-100 p-1">
            <AreaInput
              id={id}
              disabled={node.disabled}
              node={node}
              handleChange={(value, node, isUpdated) => {
                if (isUpdated) {
                  onRowUpdate(node, value);
                }
              }}
            />
          </Box>
        );

      case "date":
      case "date-future":
        return (
          <Box className="d-flex w-100 p-1">
            <DateInput
              id={id}
              disabled={node.disabled}
              node={node}
              disableFuture={node.nodeType === "date"}
              handleChange={(value, node, isUpdated) => {
                if (isUpdated) {
                  onRowUpdate(node, value);
                }
              }}
            />
          </Box>
        );

      case "date-year":
        return (
          <Box className="d-flex w-100 p-1">
            <YearDateInput
              id={id}
              disabled={node.disabled}
              node={node}
              handleChange={(value, node, isUpdated) => {
                if (isUpdated) {
                  onRowUpdate(node, value);
                }
              }}
            />
          </Box>
        );

      case "address":
        return (
          <Box className="d-flex  flex-column w-100">
            <AddressInput
              id={id}
              disabled={node.disabled}
              node={node}
              mapConfig={{
                nodeIdent: node.nodeIdent,
                value: node.value,
              }}
              handleChange={(value, node, isUpdated) => {
                if (isUpdated) {
                  onRowUpdate(node, value);
                }
              }}
            />
          </Box>
        );

      default:
        return (
          <Box className="d-flex w-100">
            <TextInput
              id={id}
              disabled={node.disabled}
              node={node}
              handleChange={(value, node, isUpdated) => {
                if (isUpdated) {
                  onRowUpdate(node, value);
                }
              }}
            />
          </Box>
        );
    }
  };

  return (
    <>{Object.keys(inputValue).length > 0 && renderNodeValues(inputValue)}</>
  );
};

export default RenderPropertyValue;
