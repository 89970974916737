import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../utils/defaultValues";
export const initialState = {
  hasErrors: false,
  error: { ...response },
  create: { ...response },
  createMulti: { ...response },
  assetList: { ...response },
  fetchAsset: { ...response },
  deleteAsset: { ...response },
  createSubAsset: { ...response },
  deleteSubAsset: { ...response },
  fetchSubAsset: { ...response },
  createReportVersion: { ...response },
  updateReportVersion: { ...response },
};

const assetOverviewSlice = createSlice({
  name: "assetOverview",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getAssetCreateSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      create: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getSubAssetCreateSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      createSubAsset: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getAssetCreateMultiSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      createMulti: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getAssetListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      assetList: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getFetchAssetSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchAsset: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getFetchSubAssetSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchSubAsset: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getAssetDeleteSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      deleteAsset: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getSubAssetDeleteSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      deleteSubAsset: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getReportVersionCreateSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      createReportVersion: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getReportVersionUpdateSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      updateReportVersion: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

export const {
  resetState,
  getFailure,
  getAssetCreateSuccess,
  getAssetListSuccess,
  getAssetDeleteSuccess,
  getFetchAssetSuccess,
  getAssetCreateMultiSuccess,
  getSubAssetCreateSuccess,
  getSubAssetDeleteSuccess,
  getReportVersionCreateSuccess,
  getReportVersionUpdateSuccess,
  getFetchSubAssetSuccess
} = assetOverviewSlice.actions;

// A selector
export const assetOverviewSelector = (state) => state.assetOverview;

// The reducer
export default assetOverviewSlice.reducer;
