import React from 'react';
import GeneralAssessmentHome from "./GeneralAssessmentHome"
import { pmRoot} from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const GeneralAssessmentRouteConfig = {
  routes: [
		{
    path: `${pmRoot}/project/general-assessment`,
    exact: true,
    name: <IntlMessages id={"menu.general-assessment"}/>,
    element: <GeneralAssessmentHome/>,
    auth: [],
  },
	]
};
export default GeneralAssessmentRouteConfig;