import { createSlice } from '@reduxjs/toolkit';
import { response } from '../../../../utils/defaultValues';

export const initialState = {
  hasErrors: false,
  updatedRows: [],
  getData: { ...response },
  customProperties: { ...response },
};

const tddBuildingProfileSlice = createSlice({
  name: 'tddBuildingProfile',
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    setCustomProperties: (state, { payload }) => ({
      ...state,
      customProperties: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      getData: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    resetState: () => ({
      ...initialState,
    }),
    setUpdatedRows: (state, { payload }) => {
      const temp = [];
      state.updatedRows?.forEach((f) =>  {
        if(f.nodeIdent !== payload.nodeIdent){
          temp.push(payload);
        }
        temp.push(f);
      });

      if(!state.updatedRows.length){
        temp.push(payload);
      }

      return {
        ...state,
        updatedRows: [...temp],
      };
    },
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  resetState,
  setUpdatedRows,
  getSuccess,
  setCustomProperties,
} = tddBuildingProfileSlice.actions;

// A selector
export const buildingProfileSelector = (state) => state.buildingProfile;

// The reducer
export default tddBuildingProfileSlice.reducer;
