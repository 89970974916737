import React from 'react'
import {useSelector } from "react-redux";
import KeyFindingsDataWrapper from "./structure/KeyFindingsDataWrapper"
import Box from '@mui/material/Box';
import FullScreenDialog from "./dialog-full/FullScreenDialog"



const KeyFindingsHome = () => {
  const state = useSelector((state) => state.tddKeyFinding);
  return (
    <>
    {state.fullMode ? 
    <FullScreenDialog
    open={state.fullMode}
    children={ <Box className={"h-100 w-100"}>
      <KeyFindingsDataWrapper/>
      </Box>}
    headerLabel = {"menu.defects"}
    />
     :
    <>
     <Box className={"h-100 w-100"}>
     <Box className={"h-100 w-100"}>
       <KeyFindingsDataWrapper />
     </Box>
   </Box>
   </>
    }
    </>
  );
};

export default KeyFindingsHome;
