import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomAutoComplete from './CustomAutoComplete';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { getLabel } from '../../../../utils/helper';
import { setDefectAndMeasureList } from './costElementSlice';

function DefectSuggestion(props) {
  const { values, touched, errors, setFieldValue } = props;
  const dispatch = useDispatch();
  const costElementsState = useSelector((state) => state.tddCostElement);

  useEffect(() => {
    if (
      costElementsState?.type === 'SUCCESS' &&
      costElementsState?.data !== null
    ) {
      let temp = [];
      costElementsState.data.defectList.forEach((e) => {
        if (e.nodeType === 'defect') {
          temp.push({
            value: e.name,
            title: e.name,
            code: e.code,
            parentIdent: e.parentIdent,
            defectType: e.defectType,
            type: 'defect',
          });
        }
      });

      let mList = [];
      costElementsState.data.measureList.forEach((e) => {
        if (e.nodeType === 'measure') {
          mList.push({
            value: e.name,
            title: e.name,
            code: e.code,
            hasChildren: e.hasChildren,
            children: e.children,
            type: 'measure',
          });
        }
      });
      setFieldValue('costSuggestions', []);
      setFieldValue('costParameters', null);
      dispatch(
        setDefectAndMeasureList({
          defectList: temp,
          measureList: mList.sort(
            (a, b) => Number(b.hasChildren) - Number(a.hasChildren)
          ),
        })
      );
    }
  }, [costElementsState, dispatch]);
  return (
    <>
      <Grid item md={12} xs={12}>
        <CustomAutoComplete
          id="defect-suggestion"
          key="defect-suggestion"
          options={costElementsState.defectList}
          defaultValue={values.defectDescription}
          label={getLabel('Defect Description')}
          onChange={(val) => setFieldValue('defectDescription', val?.value)}
          style={{ width: '98%', marginLeft: 20 }}
          variant="standard"
          error={touched.defectDescription && errors.defectDescription}
          helperText={touched.defectDescription && errors.defectDescription}
        />
      </Grid>
      <Grid item md={12} xs={12} className="m-1">
        <CustomAutoComplete
          id="measure-suggestion"
          key="measure-suggestion"
          options={costElementsState.measureList}
          defaultValue={values.measureDescription}
          label={getLabel('Measure Description')}
          onChange={(val) => {
            if (val.hasChildren) {
              setFieldValue('costSuggestions', val.children);
            }
            setFieldValue('measureDescription', val?.value);
          }}
          style={{ width: '98%', marginLeft: 20 }}
          variant="standard"
          error={touched.measureDescription && errors.measureDescription}
          helperText={touched.measureDescription && errors.measureDescription}
        />
      </Grid>
    </>
  );
}

DefectSuggestion.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
};

export default DefectSuggestion;
