const SUCCESS = "SUCCESS";
const UPDATED = "UPDATED";
const DELETED = "DELETED";
const ERROR = "ERROR";
const FETCHED = "FETCHED";
const SAVED = "SAVED";
const ACCESS_DENIED = "ACCESS_DENIED";


export {
    SUCCESS,
    UPDATED,
    DELETED,
    ERROR,
    FETCHED,
    SAVED,
    ACCESS_DENIED
}