import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box, IconButton } from "@mui/material";
import GuruEditor  from "ckeditor5-custom-build";
import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import SaveIconButton from "../../../../../components/buttons/icons-buttons/SaveIconButton";
import { commentsEditorConfig, getMentionFeedItems, customMentionItemRenderer, getIntlTranslation } from "../../../../../utils/helper";
import useCommentSaveHook from "../../../../component-bundle/hooks/useCommentSaveHook";
import useCommentFetchHook from "./useCommentFetchHook";
import CloseIcon from "@mui/icons-material/Close";
import SaveButton from "../../../../../components/buttons/SaveButton";
import CustomButton from "../../../../../components/buttons/CustomButton";

const ReplyCommentContainer = ({comment, onClose} ) => {
    const { apiParam, reportType, apiIdent } = comment.queryDetails;
    const editorRef = useRef();
    const usersState = useSelector((state) => state.users);
    const [onCommentsSave] = useCommentSaveHook();
    const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
      apiParam,
      reportType,
      apiIdent
    );
  
    useEffect(() => {
      if (isCommentSaved.saved) {
        onClose();
        resetState();
      }
    }, [isCommentSaved]);
  
    const onSave = () => {
      const range = editorRef.current.model.createRangeIn(
        editorRef.current.model.document.getRoot()
      );
  
      const mentions = [];
  
      //iterate through the whole tree in that range (TreeWalker)
      for (const treeWalker of range.getWalker({ ignoreElementEnd: true })) {
        if (treeWalker.type === "text") {
          //the item property represents TextProxy which is not instance of node
          const node = treeWalker.item.textNode;
  
          if (node.hasAttribute("mention")) {
            const mention = node.getAttribute("mention");
            if (mention) {
              mentions.push(mention);
            }
          }
        }
      }
  
      const reply = {
        type: "commentValue",
        value: editorRef.current.getData(),
        propertyIdent: comment.propertyIdent,
        propertyKey: comment.propertyKey,
        replies: [],
        deleted: false,
        new: true,
        modified: false,
        ident: "",
        createdBy: "",
        creationDate: "",
        updatedAt: "",
        updatedBy: "",
        reportType: comment.reportType,
        mentionedInComments:mentions
      };
      if (!!comment.queryDetails) {
        const { queryDetails, ...rest } = comment;
        let replies = comment.replies;
        if(!comment.replies){
          replies = [];
        }
        onCommentsSave({
          commentsApi: queryDetails.apiParam,
          data: {
            ...queryDetails,
            commentValues: [
              {
                ...rest,
                replies:[...replies, {...reply}],
                new: false,
                modified:false,
              },
            ],
          },
          apiIdent: queryDetails.apiIdent,
        });
      }
    };
  
    return (
      <Box className="d-flex flex-column">
        <Box
          id={`editor_box_${comment.ident}`}
          className="g-text-editor custom-scrollBar p-1 w-100"
          style={{
            minHeight: "200px",
            height: "98%",
            width: "90%",
          }}
        >
          <CKEditor
            id={`editor_${comment.ident}`}
            ref={editorRef}
            editor={GuruEditor.ClassicEditor}
            onReady={(editor) => {
              editorRef.current = editor;
            }}
            config={{
              ...commentsEditorConfig,
              placeholder: "Type your comment here...",
              mention: {
                feeds: [
                  {
                    marker: "@",
                    feed: (queryText) =>
                      getMentionFeedItems(queryText, usersState),
                    itemRenderer: customMentionItemRenderer,
                  },
                ],
              },
            }}
          />
        </Box>
        <Box
          className="w-100 d-flex align-items-center justify-content-between"
        >
          <>
          <CustomButton
            id={`cancel_reply_${comment.ident}`}
            buttonText={"_close"}
            style={{
              margin: 5,
              color: "#254a9a",
              height: 30,
            }}
            className = "btn"
            tooltip={{
              title: getIntlTranslation("tooltip.Click-to-close"),
            }}
            handleClick={(e) => onClose()}
          />
  
            <SaveButton
          id={`post_reply_${comment.ident}`}
          buttonText="_post"
          variant="button"
          style={{ margin: 5 , marginLeft: "auto", height:30}}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={(e) => onSave()}
        />
          </>
        </Box>
      </Box>
    );
  
  }

  export default ReplyCommentContainer