import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import IntlMessages from "../../../utils/IntlMessages";

const EsgModuleContainer = props => {
  return (
    <div>EsgModule</div>
  )
}

EsgModuleContainer.propTypes = {}

export default EsgModuleContainer;