import React from "react";
import {
  getIntlTranslation,
} from "../../../../../utils/helper";
import { resetState} from "../tddBuildingProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import CustomButton from "../../../../../components/buttons/CustomButton";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import { NoData } from "../../../../../common/commonImport";
import RouterPrompt from "../../../../../components/prompt/RouterPrompt";
import Slide from "@mui/material/Slide";
import SubAssetSwitcher from "../../../../component-bundle/assetSwitcher/SubAssetSwitcher";
import { useBuildingProfileContextHook } from "./BuildingProfileContext.js";
import { RenderPropertyNode } from "./RenderPropertyNode.js";
import AddOptionCustomProperties from "../../../common/AddOptionCustomProperties.js";
import NoAssets from "../../../../component-bundle/NoAssets.js";

export const getBorderColor = (p) => {
  if (p.updated) {
    return "solid 4px #d30038";
  } else {
    return "none";
  }
};
const switcher = {
  zIndex: "1",
  position: "sticky",
  borderRadius: 4,
  padding: 1,
};

const BuildingProfileContainer = () => {
  const {
    currentSubAsset,
    properties,
    setBlockState,
    onSave,
    getWarningText,
    onUserLeave,
    blockSubAssetChange,
    setBlockSubAssetChange,
    openCustomItem, 
    setOpenCustomItem,
    onCustomPropertySave,
    getOptionalProperties,
    getRequiredProperties,
  } = useBuildingProfileContextHook();
  const dispatch = useDispatch();
  const readOnlystate = useSelector((state) => state.readOnly);
  return (
    <>
      <Box style={{ height: "100%", padding: 5 }}>
        <div
          style={{
            width: "100%",
            height: 50,
          }}
        >
          <SubAssetSwitcher
            style={switcher}
            dirtyState={
              properties.size > 0
                ? [...properties.values()]?.some( value => value.updated)
                : false
            }
            setBlockState={(val) => setBlockState(val)}
          />
        </div>
        
        {!!currentSubAsset ? ( <Box
          style={{
            width: "100%",
            overflowY: "auto",
            height: `calc(100% - 110px)`,
          }}
          className={"custom-scrollBar"}
        >
            <RenderPropertyNode />
            </Box>
          ) : (
            <Box style={{ width: '100%', height: `calc(100% - 50px)`, margin: 5 }}>
            <NoAssets />
            </Box>
          )}
        
        {!!currentSubAsset &&
        <Box
          style={{
            width: "100%",
            overflowY: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 5,
            marginTop: 5,
          }}
        >
          <CustomButton
            id={`add_optional_properties`}
            buttonText={"_add-properties"}
            endIcon={<AddIcon style={{ color: "#d08f8e", fontSize: 20 }} />}
            variant="button"
            textVariant="caption"
            buttonColor={"inherit"}
            style={{
              margin: 5,
              height: 40,
              width: "35%",
              padding: 5,
            }}
            className="g-btn-secondary"
            tooltip={{
              title: getIntlTranslation("Optional Properties"),
            }}
            size="small"
            handleClick={(e) => setOpenCustomItem(true)}
          />
          <CustomButton
            id="save-building-info"
            tooltip={{
              title: getIntlTranslation("tooltip.Save-Building-Information"),
            }}
            buttonText={"_save"}
            handleClick={onSave}
            style={{
              backgroundColor: "#d9534f",
              color: "#ffffff",
              width: "99%",
              height: "40px",
            }}
            variant="contained"
          />
        </Box>
  }
        {!readOnlystate.isReadOnly && properties.size > 0 && [...properties.values()]?.some( value => value.updated) && (
            <>
            <RouterPrompt
              when={
                properties.size > 0
                ? [...properties.values()]?.some( value => value.updated)
                : false
              }
              cancelText="_back"
              okText="_continue"
              onOK={() => {
                dispatch(resetState());
                return true;
              }}
              onCancel={() => false}
              warningHeader={"Save And Leave"}
              message={getWarningText()}
            />
            <InternalPrompt
              when={blockSubAssetChange.block}
              stayText="_back"
              saveText="Save And Leave"
              leaveText="_continue"
              onLeave={() => {
                onUserLeave();
              }}
              onStay={() =>
                setBlockSubAssetChange({
                  block: false,
                  newSubAsset: {},
                })
              }
              warningHeader={"_leave warning"}
              message={getWarningText()}
            />
          </>
        )}
      </Box>
      { openCustomItem && (
        <AddOptionCustomProperties
          open={openCustomItem}
          handleClose={() => setOpenCustomItem(false)}
          data={getOptionalProperties}
          requiredProperties={getRequiredProperties}
          onSave={(data) => onCustomPropertySave(data)}
        />
      )}
    </>
  );
};

export default BuildingProfileContainer;
