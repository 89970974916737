import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Box,
  Card,
  CardContent,
  Toolbar,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  getVariantText,
  getTranslationVariantText,
  getIntlTranslation,
  createAddress,
  sortColumn,
} from "../../../utils/helper";
import {serviceLines} from "../../../utils/defaultValues";
import InputSearch from "../../../components/InputSearch";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SortingMenu from "./SortingMenu";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import CloseIcon from "@mui/icons-material//Close";
import { useIntl } from "react-intl";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { setProjectOpen } from "../../../layout/app-container/containerSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ChapterListStyle = {
  width: "100%",
  border: "2px double #C1D0F0",
  height: "100%",
  overflow: "auto",
  marginRight: 4,
  borderRadius: 8,
};

const cellStyle = {
  marginLeft: 5,
  borderRight: "1px solid #0000001f",
  height: "100%",
  padding: 5,
};



const ClientProjectList = (props) => {
  const intl = useIntl();
  const system = useSelector((state) => state.system);
  const [projectList, setProjectList] = useState([]);
  const [filterList, setfilterList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState({
    anchor: null,
  });
  const [menuAnchor, setMenuAnchorEl] = useState(null);
  const projectColumnList = useMemo(
    () => [
      {
        columnName: "projectName",
        displayName: intl.formatMessage({ id: "_Name" }),
        type: "string",
      },
      {
        columnName: "projectNumber",
        displayName: intl.formatMessage({ id: "_project-number" }),
        type: "string",
      },
      {
        columnName: "serviceLine",
        displayName: intl.formatMessage({ id: "Service Line" }),
        type: "string",
      },
      {
        columnName: "date",
        displayName: intl.formatMessage({ id: "_start-date" }),
        type: "date",
      },
       {
        columnName: "address",
        displayName: intl.formatMessage({ id: "_Address" }),
        type: "string",
      },
    ],
    []
  );

  useEffect(() => {
    if (!!props.data) {
      let temp = props.data.projects.map((m) => {
        let tempDate = new Date(m.creationDate);
        return {
          ...m,
          date: m.creationDate,
          displayDate: `${tempDate.toLocaleDateString(system.language, {
            month: "short",
          })},${tempDate.getFullYear()}`,
          parent: {
            clientIdent: props.data.clientIdent,
            clientName: props.data.clientName,
          },
        };
      });
      setProjectList(temp);
      setfilterList(temp);
    }
  }, [props.data, system.language]);

  const filterData = (value) => {
    if (!!value && value.length) {
      setfilterList(
        projectList.filter((e) => e.projectName.toUpperCase().includes(value))
      );
    } else {
      setfilterList(projectList);
    }
  };

  const handleMenuOpen = (event, columnName) => {
    setMenuAnchorEl({
      anchor: event.currentTarget,
      column: columnName,
      dataList:projectList,
    });
  };

  const createClientAddress = (value) => {
    let address = createAddress({ ...value, street: value?.streetName });
    return (
      <Box className="d-flex align-items-center">
        <LocationOnIcon
          style={{ fontSize: "20", color: "#018075 ", marginRight: 5 }}
        />

        {getVariantText(address, "caption", { padding: 5 })}
      </Box>
    );
  };

  const sortAsc = (col) => {
    let temp = sortColumn(col.columnName, filterList);
    setfilterList(temp);
  };

  const sortDesc = (col) => {
    let temp = sortColumn(col.columnName, filterList);
    setfilterList(temp.reverse());
  };

  const applyFilter = useCallback((dataList) => {
    setfilterList(dataList);
  }, []);

  const resetFilters = useCallback(() => {
    setfilterList(projectList);
    props.resetFilters();
  }, [projectList]);


  const openProject = (data) => {
    const found = serviceLines.find(
      (f) => f.name.toLowerCase() === data.serviceLine.toLowerCase()
    );
    if (!!found) {
      dispatch(
        setProjectOpen({
          serviceLine: data.serviceLine.toLowerCase(),
          api: found.api,
          isProjectScope: true,
          projectIdent: data.projectIdent,
          projectName: data.projectName,
          isReadOnly: data.isReadOnly || true,
          currentProjectRole: "Default",
          projectLanguageCode: !!data.languageCode ? data.languageCode : "de",
        })
      );
      navigate(`${found.root}/project/project-dashboard`);
    }
  };

  const createProjectListStruct = () => {
    return (
      <>
        <Card style={ChapterListStyle} className="custom-scrollBar">
          <Toolbar
            style={{ height: "10%" }}
            className="w-100 d-flex align-items-center justify-content-start"
          >
            <Box className="d-flex align-items-center justify-content-start">
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={() => props.onClose()}
                aria-label="close"
              >
                <Tooltip title={getIntlTranslation("tooltip.Click-to-close")}>
                  <CloseIcon style={{ fontSize: 20, color: "#254a9a" }} />
                </Tooltip>
              </IconButton>
            </Box>
            <Box
              style={{ width: "40%" }}
              className="d-flex align-items-center justify-content-start"
            >
              {getTranslationVariantText(`menu.project-list`, "caption", {
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#254a9a",
                margin: "0px 5px",
              })}
              ({" "}
              {getVariantText(props.data.clientName, "caption", {
                fontWeight: "bold",
                margin: "0px 5px",
              })}{" "}
              )
            </Box>
            <Box
              style={{ width: "50%", marginLeft: "auto" }}
              className="d-flex align-items-center justify-content-start"
            >
              <InputSearch onFilterInput={filterData} />
            </Box>
            <Box className="d-flex align-items-center justify-content-start">
              <CustomIconButton
                id={`reset-filter-options`}
                icon={
                  <RestartAltIcon
                    style={{ fontSize: "25", color: "#d08f8e" }}
                  />
                }
                edge="end"
                style={{ margin: 5, borderRadius: 4, width: 30, height: 30 }}
                handleClick={(e) => {
                  resetFilters();
                }}
                tooltip={{
                  title: getIntlTranslation("_reset"),
                }}
                label={""}
                aria-label="all filter"
              />
            </Box>
          </Toolbar>
          <CardContent className={"w-100 d-flex flex-column p-0"}>
            <List className={"p-1"}>
              <ListItem
                divider
                style={{ height: 40, borderRadius: 4 }}
                className="p-0 g-btn-secondary"
                key="headers"
              >
                <ListItemButton className="p-0">
                  <ListItemIcon className="p-1">
                    <DescriptionIcon
                      style={{ fontSize: "20", color: "#254a9a" }}
                    />
                  </ListItemIcon>
                  <>
                    <Box
                      className="d-flex align-items-center w-100"
                      style={{ height: 40 }}
                    >
                      <div
                        style={{
                          ...cellStyle,
                          width: "40%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("_Name", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_project_name`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#254a9a" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "projectName",
                              type: "string",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by project name"
                        />
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "20%",
                          minHeight: 40,
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText(
                          "_project-number",
                          "caption",
                          {
                            fontWeight: "Bold",
                            textTransform: "uppercase",
                          }
                        )}
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "20%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("Service Line", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_project_service_line`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#254a9a" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "serviceLine",
                              type: "string",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by service Line"
                        />
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "20%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("_start-date", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                        <CustomIconButton
                          id={`menu_projects`}
                          icon={
                            <MoreVertIcon
                              style={{ fontSize: "20", color: "#254a9a" }}
                            />
                          }
                          edge="end"
                          style={{ marginRight: 1 }}
                          handleClick={(e) =>
                            handleMenuOpen(e, {
                              columnName: "date",
                              type: "date",
                            })
                          }
                          color="primary"
                          tooltip={{
                            title: getIntlTranslation("tooltip.menu"),
                          }}
                          label={""}
                          aria-label="sorting and filtering by projects count"
                        />
                      </div>
                      <div
                        style={{
                          ...cellStyle,
                          width: "40%",
                          borderColor: "#ffffff",
                        }}
                        className={
                          "d-flex align-items-center justify-content-between h-100"
                        }
                      >
                        {getTranslationVariantText("_Address", "caption", {
                          fontWeight: "Bold",
                          textTransform: "uppercase",
                        })}
                      </div>
                    </Box>
                  </>
                </ListItemButton>
              </ListItem>
              {filterList.map((m, index) => {
                return (
                  <>
                    <ListItem
                      key={index}
                      divider
                      className="p-0"
                      style={{
                        
                        borderLeft: "1px solid #0000001f",
                        borderRight: "1px solid #0000001f",
                      }}
                    >
                      <ListItemButton
                        className="p-0"
                        onClick={(e) =>{
                          openProject(m)
                          setSelectedProject({
                            anchor: m.ident,
                          })
                        }
                          
                        }
                      >
                        <ListItemIcon className="p-1">
                          <FolderIcon style={{ color: "#F8D775" }} />
                        </ListItemIcon>
                        <>
                          <Box className="d-flex align-items-center h-100 w-100">
                            <div
                              style={{
                                ...cellStyle,
                                width: "40%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.projectName, "subtitle2", {
                                padding: 5,
                              })}
                            </div>
                            <div
                              style={{
                                ...cellStyle,
                                width: "20%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.projectNumber, "caption", {
                                padding: 5,
                              })}
                            </div>
                            <div
                              style={{
                                ...cellStyle,
                                width: "20%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.serviceLine, "caption", {
                                padding: 5,
                              })}
                            </div>
                            <div
                              style={{
                                ...cellStyle,
                                width: "20%",
                                minHeight: 40,
                              }}
                            >
                              {getVariantText(m.displayDate, "caption", {
                                padding: 5,
                              })}
                            </div>
                            <div style={{ ...cellStyle, width: "40%" }}>
                                {createClientAddress(m.address)}
                              </div>
                          </Box>
                        </>
                      </ListItemButton>
                    </ListItem>
                  </>
                );
              })}
            </List>
          </CardContent>
        </Card>
        <SortingMenu
          menuAnchor={menuAnchor}
          onClose={() => setMenuAnchorEl(null)}
          sortAsc={(col) => sortAsc(col)}
          sortDesc={(col) => sortDesc(col)}
          onFilterSelect={(anchor) =>
            props.onFilterSelect({
              ...anchor,
              columnList: projectColumnList,
              applyFilter,
              resetFilters,
              dataList: filterList,
            })
          }
        />
      </>
    );
  };
  return <div className="m-2 w-100">{createProjectListStruct()}</div>;
};

ClientProjectList.propTypes = {
  data: PropTypes.object,
  onFilterSelect: PropTypes.func,
  onClose: PropTypes.func,
  resetFilters: PropTypes.func,
};

export default ClientProjectList;
