import { List, ListItem, ListItemText } from "@mui/material";
import React, { createContext, useContext, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InternalPrompt from "../../../../components/prompt/InternalPrompt";
import RouterPrompt from "../../../../components/prompt/RouterPrompt";
import {
  getTranslationVariantText,
  getVariantText,
} from "../../../../utils/helper";
import { setOpenAsset } from "../../../component-bundle/assetSwitcher/OpenAssetSlice";
import { subAssetListSelector } from "../../../tdd-module/feature-bundle/sub-asset-management/sub-asset/subAssetListSlice";
import { resetPromptState } from "../../projectmonitoringModuleSlice";
import {
  setCurrentVersion,
  setUpdatedRows,
} from "../slice/CurrentVersionSlice";
import { useDataAggregatorContextHook } from "./DataAggregatorContext";
import { usePropertiesData } from "./PropertiesDataContext";

const UpdateStateContext = createContext();

export const useUpdateState = () => useContext(UpdateStateContext);

export const UpdateStateProvider = ({ children }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.currentVersion);
  const readOnlystate = useSelector((state) => state.readOnly);
  const {setStructuredProperties} = usePropertiesData();
  const {onSave} = useDataAggregatorContextHook();
  const [blockAssetChange, setBlockAssetChange] = useState({
    block: false,
    newAsset: {},
  });

  const onRowUpdate = useCallback(
    (property, subAssets) => {
      let temp = { ...state.updatedRows };
      temp[property.nodeIdent] = {
        subAssets: subAssets,
        property: property,
        updated: true,
      };

      setStructuredProperties((prev) => {
        const updatedNodes = { ...prev.nodes };
        const existingNode = updatedNodes[property.nodeIdent];
        const updatedSubAssets = {
          ...existingNode.subAssets,
          ...subAssets.reduce((acc, subAsset) => {
            acc[subAsset.subAssetIdent] = {
              ...existingNode.subAssets[subAsset.subAssetIdent],
              ...subAsset,
            };
            return acc;
          }, {})
        };
  
        updatedNodes[property.nodeIdent] = {
          ...existingNode,
          subAssets: updatedSubAssets,
        };
  
        return {
          ...prev,
          nodes: updatedNodes,
        };
      });
  
      dispatch(setUpdatedRows(temp));
    },
    [state.updatedRows, setStructuredProperties, dispatch]
  );

  const onPropertiesSave = useCallback(() => {
    let properties = [];
    Object.keys(state.updatedRows).forEach((key) => {
      let obj = state.updatedRows[key];
      let originalProp = obj.property.original;
      const nodeValues = obj.subAssets.map((m) => {
        return { ...m.original, value: m.node.value, quelle: m.node.quelle };
      });
      properties.push({ ...originalProp, nodeValue: [...nodeValues] });
    });
    if (properties.length > 0) {
      onSave(properties);
      dispatch(setUpdatedRows({}));
    }
  }, [state.updatedRows, dispatch, onSave]);

  const isDataUpdated = useCallback(() => {
    return (
      Object.keys(state.updatedRows)?.filter(
        (f) => state.updatedRows[f].updated
      ).length > 0
    );
  }, [state.updatedRows]);

  const getWarningText = useCallback(() => {
    const updatedRows = [];
    Object.keys(state.updatedRows)?.forEach((f) => {
      if (state.updatedRows[f].updated) {
        updatedRows.push(state.updatedRows[f]);
      }
    });
    return (
      <>
        {updatedRows.length > 0 ? (
          <>
            {getTranslationVariantText("_unsaved changes", "subtitle2")}
            <List>
              {updatedRows.map((el, index) => {
                const inputObj =
                  el.property.labelTranslation[scope.projectLanguageCode];

                return (
                  <ListItem key={index} style={{ padding: 0 }}>
                    <ListItemText
                      id={`${index}_${el}`}
                      primary={getVariantText(
                        `${index + 1}. ${inputObj.title}`,
                        "subtitle2",
                        { fontWeight: "bold" }
                      )}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : null}
      </>
    );
  }, [scope.projectLanguageCode, state.updatedRows]);

  const onCopyData = (property, subAsset) => {
    let temp = { ...state.updatedRows };
    if (!!temp[property.nodeIdent]) {
      let newTemp = { ...temp[property.nodeIdent] };
      let tempSubAssets = [...newTemp.subAssets].filter(
        (sub) => sub.subAssetIdent !== subAsset.subAssetIdent
      );
      tempSubAssets.push(subAsset);
      newTemp["subAssets"] = tempSubAssets;
      temp[property.nodeIdent] = { ...newTemp };
      dispatch(setUpdatedRows(temp));
    } else {
      temp[property.nodeIdent] = {
        subAssets: [{ ...subAsset }],
        property: property,
        updated: true,
      };
    }
    setStructuredProperties((prev) => {
      const updatedNodes = { ...prev.nodes };
      const existingNode = updatedNodes[property.nodeIdent];
      const updatedSubAssets = {
        ...existingNode.subAssets,
        [subAsset.subAssetIdent]: {
          ...existingNode.subAssets[subAsset.subAssetIdent],
          ...subAsset,
        }
      };
    
      updatedNodes[property.nodeIdent] = {
        ...existingNode,
        subAssets: updatedSubAssets,
      };
    
      return {
        ...prev,
        nodes: updatedNodes,
      };
    });
    dispatch(setUpdatedRows(temp));
  };

  return (
    <UpdateStateContext.Provider
      value={{
        onRowUpdate,
        onPropertiesSave,
        isDataUpdated,
        onCopyData,
        setBlockAssetChange,
      }}
    >
      {children}
      {!readOnlystate.isReadOnly && blockAssetChange.block && (
        <InternalPrompt
          when={blockAssetChange.block}
          stayText={"_back"}
          saveText="Save And Leave"
          leaveText={"_continue"}
          onLeave={() => {
            dispatch(setCurrentVersion({}));
            dispatch(setOpenAsset(blockAssetChange.newAsset));
            setBlockAssetChange({
              block: false,
              newAsset: {},
            });
          }}
          onStay={() =>
            setBlockAssetChange({
              block: false,
              newAsset: {},
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText()}
        />
      )}
      {!readOnlystate.isReadOnly && isDataUpdated() && (
        <RouterPrompt
          when={isDataUpdated()}
      cancelText="_back"
          okText="_continue"
          warningHeader={"Save And Leave"}
          onOK={() => dispatch(resetPromptState())}
          onCancel={() => false}
          message={getWarningText()}
        />
      )}
    </UpdateStateContext.Provider>
  );
};
