import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Drawer as MuiDrawer } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import CustomButton from "../../../../../components/buttons/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import { copyDefects } from "../../../api/defectApi";
import { resetSliceState } from "../../defects/defectSlice";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useSnackbar } from "notistack";
import ListSubheader from "@mui/material/ListSubheader";
import {Card,CardActions,CardContent,CardHeader} from "@mui/material";
import Alert from "@mui/material/Alert";
import { getLabel, getIntlTranslation,getDialogBoxHeader } from "../../../../../utils/helper";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";

const DefectCopyDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const [assets, setAssets] = useState([]);
  const openAsset = useSelector((state) => state.openAsset);
  const scope = useSelector((state) => state.container.scope);
  const tddDefectSection = useSelector((state) => state.tddDefectSection);
  const state = useSelector((state) => state.assetswitcher);
  const dispatch = useDispatch();
  const [selectedSubAsset, setSelectedSubAsset] = useState([]);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {assetList,fetchList} = useAssetListHook();
  useEffect(() => {
    if(assetList.isFetchSuccess && assetList.assets.length > 0){
      let temp = assetList.assets.map((a, i) => {
        return {
          name: a.name,
          ident: a.assetIdent,
          children: a.subAssetListDto.map((s, index) => {
            return {
              index: i,
              asset: a.name,
              ident: a.assetIdent,
              label: s.name,
              value: s.name,
              sIdent: s.subAssetIdent,
              sType: s.subAssetType,
              checked: false,
              status: "none",
            };
          }),
        };
      });
      setAssets(temp);
      setSelectedSubAsset(temp.flatMap((t) => t.children));
    } else if(assetList.isFetchSuccess && assetList.assets.length <= 0) {
      setAssets([]);
     }
      else{
      fetchList();
     }
  }, [assetList]);
  
  useEffect(() => {
    if (
      tddDefectSection.copyDefect.type === "SAVED" &&
      !!tddDefectSection.copyDefect.data
    ) {
      tddDefectSection.copyDefect.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });

      let temp = assets.map((f) => {
        let saved = [];
        f.children.forEach((child) => {
          if (child.sIdent === tddDefectSection.copyDefect.data.subAssetIdent) {
            child.status = "saved";
          }
          saved.push(child);
        });
        return { ...f, children: saved };
      });
      setAssets(temp);
      dispatch(resetSliceState());
    }
  }, [tddDefectSection.copyDefect, dispatch, enqueueSnackbar]);

  const selectSubAsset = (value) => {
    setSelectedSubAsset((prev) =>
      prev.map((item) => {
        if (item.sIdent === value.sIdent) {
          item.checked = !item.checked;
        }
        return item;
      })
    );
  };

  const handleSubmit = () => {
    let defectIdents = props.row.map((r) => r.defectIdent);
    selectedSubAsset
      .filter((f) => f.checked)
      .forEach((e) => {
        dispatch(
          copyDefects({
            projectIdent: scope.projectIdent,
            subAssetIdent: e.sIdent,
            defectIdents: defectIdents,
          })
        );
      });
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
    props.onClose();
  };

  const sortAsset = useMemo(() => {
    return assets.sort((a, b) => a.name.localeCompare(b.name));
  }, [assets]);

  const createList = (asset, index) => {
    let subAssets = asset.children.filter(
      (f) => f.sIdent !== openAsset?.subAssetIdent
    );
    return (
      <>
        {subAssets.length > 0 && (
          <>
            <List
              dense={"true"}
              style={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                flexDirection: "column",
                display: "flex",
                padding: 0,
              }}
              subheader={
                <ListSubheader style={{ marginTop: 2, lineHeight: "30px" }}>
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    {index + 1}. Asset: {asset.name}
                  </Typography>
                </ListSubheader>
              }
            >
              {subAssets.map((m, index) => {
                const labelId = `checkbox-list-label-${index}`;
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        onClick={(e) => selectSubAsset(m)}
                        dense
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography variant="subtitle2">
                              {m.label}
                            </Typography>
                          }
                        />
                        {m.status === "saved" && (
                          <DoneAllIcon
                            style={{
                              color: "#18f918",
                              width: 25,
                              height: 20,
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
            <Divider />
          </>
        )}
      </>
    );
  };
  return (
    <>
      <MuiDrawer
        anchor={"left"}
        open={props.open}
        onClose={(e) => handleDrawerOpen()}
        style={{
          marginBottom: 20,
          zIndex: 1500,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box style={{ height: "100%", overflow: "hidden" }} role="presentation">
          <AppBar className="appBar">
            <Toolbar style={{ minHeight: 30, height: 40 }} className="p-0">
              <Typography variant="body1" style={{ marginLeft: 5, flex: 1 }}>
                {getDialogBoxHeader("_List-of-Sub-Assets")}
              </Typography>
              <CustomIconButton
                id="image_drawer_close_01"
                handleClick={(e) => props.onClose()}
                icon={<CloseIcon style={{ color: "#ffffff" }} />}
                tooltip={{
                  title: getIntlTranslation("tooltip.Click-to-close"),
                }}
              />
            </Toolbar>
          </AppBar>
          <Divider />
          {loader && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}

          <Card
            style={{
              width: "100%",
              height: "calc(100% - 40px)",
              overflowY: "hidden",
            }}
            className="d-flex flex-column custom-scrollBar"
          >
            <CardHeader
              title={
                <Alert
                  severity="info"
                  style={{ width: "100%", overflow: "hidden" }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      width: "90%",
                      overflow: "hidden",
                      overflowWrap: "anywhere",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {getLabel("_Select-sub-Asset-to-copy")}
                  </Typography>
                </Alert>
              }
            />
            <CardContent
              style={{
                width: "100%",
                height: "calc(100% - 40px)",
                overflowY: "auto",
              }}
              className="d-flex flex-column custom-scrollBar"
            >
              {sortAsset.map((a, index) => {
                return createList(a, index);
              })}
            </CardContent>
            <CardActions style={{ marginTop: "auto" }}>
              <CustomButton
                id="copy-defect-01"
                buttonText="_Copy"
                style={{
                  margin: 5,
                  backgroundColor: "#d9534f",
                  color: "#ffffff",
                  height: 30,
                  width: "100%",
                }}
                variant="button"
                tooltip={{
                  title: getIntlTranslation("tooltip.Copy-Defects"),
                }}
                handleClick={(e) => handleSubmit()}
              />
            </CardActions>
          </Card>
        </Box>
      </MuiDrawer>
    </>
  );
};

DefectCopyDrawer.propTypes = {
  open: PropTypes.bool,
  row: PropTypes.array,
  onClose: PropTypes.func,
};

export default DefectCopyDrawer;
