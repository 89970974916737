import React, { useEffect, useState } from "react";
import RenderPropertyValue from "./RenderPropertyValue";
import { Box, ListItem, ListItemText, Popover } from "@mui/material";
import {
  getIntlTranslation,
  getVariantText,
} from "../../../../../utils/helper";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useProjectInformationContext } from "../context/ProjectInformationContext";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import EditPropertyLabel from "../../../version-comparison/EditPropertyLabel";

export const getBorderColor = (p) => {
  if (p) {
    return {
      borderLeft: "4px solid rgb(211, 0, 56)",
      backgroundColor: "rgba(255, 42, 81, 0.19)",
    };
  } else {
    return {backgroundColor:"#ffffff"};
  }
};

const RenderProperty = ({ id, node, activeIndex, overlay = false }) => {
  const state = useSelector((state) => state.projectInformation);
  const {
    onDeleteProperty,
    onEditProperty,
    customActionSuccess,
    setCustomActionSuccess,
  } = useProjectInformationContext();
  const [openPopOver, setOpenPopOver] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
    index,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  };

  const getInserPosition =
    activeIndex !== undefined && over?.id === node.nodeIdent && !isDragging
      ? index > activeIndex
        ? "after"
        : "before"
      : undefined;

  useEffect(() => {
    if (customActionSuccess) {
      setAnchorEl(null);
      setOpenPopOver(null);
      setCustomActionSuccess(false);
    }
  }, [customActionSuccess, setCustomActionSuccess]);

  return (
    <ListItem
      ref={setNodeRef}
      key={id}
      className={clsx(
        "drag-item w-100 d-flex align-items-center justify-content-between p-1 my-1 rounded-1",
        {
          overlay: overlay,
          active: isDragging,
          insertBefore: getInserPosition === "before",
          insertAfter: getInserPosition === "after",
        }
      )}
      style={getBorderColor(!!state.updatedRows[node.nodeIdent])}
    >
      <Box className={`d-flex w-100 align-items-center justify-content-around`}>
        <div style={{ ...style }} {...attributes} {...listeners}>
          <DragIndicatorIcon style={{ color: "#d08f8e", fontSize: 20 }} />
        </div>
        <Box className="w-50 d-flex align-items-center justify-content-around">
          <ListItemText
            primary={getVariantText(
              node.nodeTitle || node.nodeName,
              "caption",
              {
                fontWeight: "bold",
                color: "#254a9a",
                margin: 5,
                textTransform: "uppercase",
              }
            )}
            className="w-75 m-1"
          />
          {node.custom && (
            <>
              <CustomIconButton
                id={`menu_button_${node.nodeName}`}
                className="mx-1"
                icon={
                  <ArrowDropDownIcon
                    style={{ color: "#d08f8e", fontSize: 20 }}
                  />
                }
                permission={null}
                handleClick={(e) => {
                  e.stopPropagation();
                  setOpenPopOver(node.nodeIdent);
                  setAnchorEl(e.currentTarget);
                }}
                tooltip={{
                  title: getIntlTranslation("tooltip.edit"),
                }}
                style={{
                  borderRadius: 0,
                }}
                aria-describedby={`prop_menu_${node.nodeName}`}
              />
              <Popover
                id={`prop_menu_${node.nodeName}`}
                open={openPopOver === node.nodeIdent}
                anchorEl={anchorEl}
                onClose={(e) => {
                  setAnchorEl(null);
                  setOpenPopOver(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <EditPropertyLabel
                  property={node}
                  onClose={(e) => {
                    setAnchorEl(null);
                    setOpenPopOver(null);
                  }}
                  handleSave={(val) => onEditProperty([val])}
                  onDelete={(val) => {
                    onDeleteProperty({
                      propertyIdent: val.nodeIdent,
                      version: val.currentVersion,
                    });
                  }}
                />
              </Popover>
            </>
          )}
        </Box>

        <RenderPropertyValue node={node} />
      </Box>
    </ListItem>
  );
};

RenderProperty.propTypes = {};

export default RenderProperty;
