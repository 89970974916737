import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardContent,
  Grow,
  Divider,
} from '@mui/material';
import { getDialogBoxHeader } from '../../../../../utils/helper';
import CloseIcon from '@mui/icons-material/Close';
import CapexCostTable from './CapexCostTable';
import CapexCharts from './CapexCharts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const CapexSummary = (props) => {
  const [capexSummaryData, setCapexSummaryData] = useState([]);
  useEffect(() => {
    if (!!props.data) {
      setCapexSummaryData(props.data);
    }
  }, [props.data]);

  return (
    <>
     <Dialog
        fullScreen
        open={props.open}
        onClose={(e) => props.onClose()}
        TransitionComponent={Transition}
        style={{ width: "100%"}}
      >
        <AppBar
          style={{
            backgroundColor: '#d08f8e',
            position: 'sticky',
            width: '100%',
            borderRadius: 4,
           
            height: 40,
          }}
        >
          <Toolbar className={'dialog-toolbar'}>
            {getDialogBoxHeader(`_capex-summary`)}
            <IconButton
              edge="start"
              color="inherit"
              id="capex_summary_close"
              onClick={() => props.onClose()}
              aria-label="close"
              style={{ marginLeft: 'auto' }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box className={'d-flex w-100'} style={{ height: 'calc(100% - 40px)', overflow:"hidden" }}>
          <Card className={'w-100 p-1'}>
            <CardContent
              style={{ height: '100%', width: '100%', overflowY:"auto", overflowX:"hidden"}}
              className={
                "p-1 m-1 custom-scrollBar"
              }
            >
                <CapexCharts data={capexSummaryData}/>
                <CapexCostTable data={capexSummaryData} />
             
            </CardContent>
          </Card>
        </Box>
      </Dialog>
    </>
  );
};

CapexSummary.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.array,
  onClose: PropTypes.func,
};

export default CapexSummary;
