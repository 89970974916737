import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  data:{},
  error: {},
};
const historyReportTextSlice = createSlice({
  name: "historyReportText",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    getSuccess: (state, { payload }) => ({
     ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDtoList,
    }),
    resetState: (state) => ({
      ...state,
      hasErrors: false,
      type: "",
      messages: "",
      error: {},
      data:{}
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  getSuccess,
  resetState,
} = historyReportTextSlice.actions;

// A selector
export const historyReportTextSelector = (state) => state.tddReportHistory;

// The reducer
export default historyReportTextSlice.reducer;
