/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../utils/IntlMessages";
import { NavLink, Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { authenticateUser } from "../api/authApi";
import { reset } from "./authSlice";
import { useSnackbar } from "notistack";
import { resetErrorState } from "../error/errorHandlingSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { removeAccessToken } from "./authTokenService";
import {
  Card,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { decrypt, isEmpty, getLabel } from "../utils/helper";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state) => state.authentication);
  const errorState = useSelector((state) => state.errorhandling);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowpassword] = useState(false);
  let user = {
    data:{}
  }

  useEffect(() => {
    dispatch(reset());
    removeAccessToken();
  }, [dispatch]);

  useEffect(() => {
    const decrypted = decrypt(userLogin.user);
    if (!isEmpty(decrypted)) {
      user = JSON.parse(decrypted);
    }
    if (errorState.hasErrors && errorState.responseError) {
      enqueueSnackbar(errorState.messages, {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      dispatch(resetErrorState());
    }
    if (user.loggedIn) {
      if (!!location.state && !!location.state.from) {
        navigate(location.state.from);
      } else {
        navigate("/guru/");
      }
    }
  }, [
    dispatch,
    enqueueSnackbar,
    errorState,
    navigate,
    location.state,
    userLogin.loggedIn,
  ]);

  const intitialValues = {
    emailAddress: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().required(
      <IntlMessages id="user.emailRequired" />
    ),
    password: Yup.string().required(
      <IntlMessages id="user.passwordRequired" />
    ),
  });

  function onSubmit(values) {
    let emailAddress = values.emailAddress.concat("@taeurope.com");
    dispatch(
      authenticateUser({
        email: emailAddress,
        password: values.password,
      })
    );
  }

  const handleClickShowPassword = () => {
    setShowpassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="fixed-background" />
      <div className="container h-100">
        <div className="h-100 row">
          <div className="m-auto col-12 col-md-10">
            <Card className="login-card">
              <div className="position-relative login-image">
                <p className="text-white h6">
                  <IntlMessages id="guru.welcome" />
                </p>
                <p
                  className="text-white h6"
                  style={{ marginTop: "2%", marginBottom: "50%" }}
                >
                  <IntlMessages id="guru.messages" />
                </p>
              </div>
              <div className="login-side">
                <div style={{ marginBottom: 40 }}>
                  <NavLink to="/">
                    <img
                      src="/assets/images/ta-logo.jpg"
                      width="50"
                      height="50"
                      alt=""
                    />
                  </NavLink>
                </div>
                <Formik
                  initialValues={intitialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ values, errors, touched }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Grid
                        style={{ width: "100%" }}
                        container
                        className="custom-grid-container"
                        justify="flex-start"
                        spacing={3}
                      >
                        <Grid md={12} xs={12} className="p-2">
                          <Field
                            as={TextField}
                            name="emailAddress"
                            id="lp_email"
                            label={getLabel("user.emailAddress")}
                            className="grid-fields"
                            size="small"
                            style={{ width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ fontWeight: "bold" }}
                                  >
                                    @taeurope.com
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                            error={touched.emailAddress && errors.emailAddress}
                            helperText={
                              touched.emailAddress && errors.emailAddress
                            }
                          />
                        </Grid>
                        <Grid md={12} xs={12} className="p-2">
                          <Field
                            name="password"
                            id="lp_password"
                            label={getLabel("user.password")}
                            as={TextField}
                            size="small"
                            style={{ width: "100%" }}
                            type={showPassword ? "text" : "password"}
                            className="grid-fields"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {values.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            error={touched.password && errors.password}
                            helperText={touched.password && errors.password}
                          />
                        </Grid>
                        <Grid
                          md={6}
                          xs={12}
                          style={{ textAlign: "center" }}
                          className="p-2"
                        >
                          <Link to="/login/forgot-password">
                            <Typography variant="overline">
                              <IntlMessages id={"user.forgotPassword"} />
                            </Typography>
                          </Link>
                        </Grid>
  
                        {/* Flexbox container for buttons */}
                        <Grid
                          container
                          justifyContent="center"
                          spacing={2}
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly", // Evenly space buttons
                            alignItems: "center", // Align vertically in the center
                            width: "100%",
                          }}
                        >
                          {/* Login with SSO Button */}
                          <Grid item>
                            <Button
                              variant="contained"
                              className="btn g-btn-secondary"
                              disableElevation
                              type="button"
                              id="sso_login"
                              onClick={() =>
                                (window.location.href =
                                `${process.env.REACT_APP_API_URL}/oauth2/authorization/microsoft`)
                              }
                            >
                              Login with SSO
                            </Button>
                          </Grid>
  
                          {/* Anmeldung Button */}
                          <Grid item>
                            <Button
                              variant="contained"
                              className="btn g-btn-primary"
                              disableElevation
                              type="submit"
                              id="lp_submit"
                            >
                              <IntlMessages id="user.login" />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
  
};

export default Login;


