import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

const DateWidget = ()=> {
	const [time, setTime] = useState(moment());
	const intervalRef = useRef();

	useEffect(() => {
		intervalRef.current = setInterval(update, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	});

	function update() {
		setTime(moment());
	}

	return (
		<div className="m-1">
			<div style={{justifyContent: "space-evenly",alignItems: "center"}}>
			<Typography variant="caption" style={{color:"#ffffff"}}>{time.format('dddd, HH:mm')}</Typography>
			</div>
		</div>
	);
}

export default React.memo(DateWidget);
