import React from 'react';
import { tddServiceLine, tddRoot } from '../../../../utils/defaultValues';
import AssetsOverview from './AssetsOverview';
import { Box } from '@mui/material';

const AssetOverviewHome = () => {
  return (
    <Box
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100%' }}
    >
      <AssetsOverview serviceLine={tddServiceLine} routePrefix={tddRoot} />
    </Box>
  );
};
export default AssetOverviewHome;
