import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  list: [],
  data: {},
  error: {},
};

const userProjectSlice = createSlice({
  name: "userprojects",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
      list: [],
      data: {},
    }),
    getListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      list: payload.guruDtoList,
      data: {},
    }),
    getDataSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      list: [],
      data: payload.guruDto,
    }),
    resetState: (state) => ({
      ...state,
      hasErrors: false,
      type: "",
      messages: "",
      list: [],
      data: {},
      error: {},
    }),
  },
});

export const { getFailure, getListSuccess, getDataSuccess, resetState } =
  userProjectSlice.actions;

// A selector
export const userProjectsSelector = (state) => state.userProjects;

// The reducer
export default userProjectSlice.reducer;
