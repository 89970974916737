import { useSnackbar } from "notistack";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBuildingProfile, save, saveCustomProperties } from "../../../api/tddBuildingProfileApi";
import { resetState, setUpdatedRows } from "../tddBuildingProfileSlice";
import { getTranslationVariantText } from "../../../../../utils/helper";
import { List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { setOpenAsset } from "../../asset-management/asset/OpenAssetSlice";

export const BuildingProfileContext= createContext(undefined);

export const  useBuildingProfileContextHook = () => {
  const context = useContext(BuildingProfileContext);

  if(context === undefined){
    throw new Error("context should be used")
  }
return context;
}


export const BuildingProfileContextProvider = ({children}) => {
    const scope = useSelector((state) => state.container.scope);
    const buildingProfile = useSelector((state) => state.tddBuildingProfile);
    const openAsset = useSelector((state) => state.openAsset);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openCustomItem, setOpenCustomItem] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [currentSubAsset, setCurrentSubAsset] = useState(null);
    const [itemList, setItemList] = useState({
        inputFields:[],
        inputAreaFields:[],
        inputParkingFields:[],
        inputFloorFields:[],
        optionalFields:[],
    })
    const [properties, setProperties] = useState(new Map());
    const [blockSubAssetChange, setBlockSubAssetChange] = useState({
      block: false,
      newSubAsset: {},
    });
    const [alert, setAlert] = useState({
        isWarning: false,
        message: "",
      });

      useEffect(() => {
        if (!!openAsset?.subAssetIdent) {
          dispatch(resetState());
          dispatch(
            getBuildingProfile({
              projectIdent: scope.projectIdent,
              ident: openAsset?.subAssetIdent,
            })
          );
          setProperties(new Map());
          setItemList({
            inputFields:[],
            inputAreaFields:[],
            inputParkingFields:[],
            inputFloorFields:[],
        })
          setCurrentSubAsset(null);
        }
      }, [dispatch, openAsset?.subAssetIdent, scope.projectIdent]);

      useEffect(() => {
        if (
          buildingProfile.getData.type === "SUCCESS" ||
          (buildingProfile.getData.type === "SAVED" &&
            Object.keys(buildingProfile.getData.data).length > 0)
        ) {
          let tempObj = new Map();
          let tempArea = [];
          let tempParking = [];
          let tempFloors = [];
          let tempRest = [];
          let tempOptional = [];
          buildingProfile.getData.data.properties.forEach((el) => {
            let translation = el.labelTranslation.find(
              (e) => e.language === scope.projectLanguageCode
            );
            if (
                (!el.optional && el.nodeType === "area") ||
                el.nodeType.includes("-area")
              ) {
                tempArea.push(el);
              } else if (!el.optional && el.nodeType.includes("parking")) {
                tempParking.push(el);
              } else if (!el.optional && el.nodeType.includes("floor")) {
                tempFloors.push(el);
              } else if(!el.optional) {
                  tempRest.push(el);
                }
                else {
                  tempOptional.push(el);
                }
              
            tempObj.set(el.nodeName, {
              nodeName: el.nodeName,
              labelTranslation : translation,
              value: el.nodeValue || "",
              quelle: el.nodeQuelle || "",
              disabled: el.disabled,
              hidden: el.hidden,
              updated: false,
              deleted: false,
              original:{
                value:el.nodeValue,
                quelle:el.nodeQuelle,
              } 
            })
          });
          setProperties(tempObj);
          //setGetProperty(buildingProfile.getData.data.properties);
          setItemList({
            inputFields:tempRest,
            inputAreaFields:tempArea,
            inputParkingFields:tempParking,
            inputFloorFields:tempFloors,
            optionalFields:tempOptional,
        })
          setCurrentSubAsset(buildingProfile.getData.data);
          if (buildingProfile.getData.type === "SAVED") {
            buildingProfile.getData.messages.forEach((element) => {
              enqueueSnackbar(element, {
                variant: "success",
                autoHideDuration: 2000,
              });
            });
          }
          dispatch(resetState());
        }
        if (buildingProfile.getData.type === "NO_DATA") {
          setAlert({
            isWarning: true,
            message: buildingProfile.getData.messages[0],
          });
          dispatch(resetState());
        }
        if (buildingProfile.customProperties.type === 'SAVED') {
            setOpenCustomItem(false);
            dispatch(
                getBuildingProfile({
                  projectIdent: scope.projectIdent,
                  ident: openAsset?.subAssetIdent,
                })
              );
          }
      }, [buildingProfile, dispatch, enqueueSnackbar, openAsset.assetName]);
    
      const redirect = () => {
        setTimeout(() => {
          navigate(`/tdd/project/assets-overview`);
          setAlert({
            isWarning: false,
            message: "",
          });
        }, 5000);
      };

      const setBlockState = (val) => {
        setBlockSubAssetChange({
          block: true,
          newSubAsset: val,
        });
      };

      const getGroupedFieldsData = (fields) => {
        return fields.map((el) => {
          return {
            ...el,
            nodeValue: properties.get(el.nodeName).value,
            nodeQuelle: properties.get(el.nodeName).quelle,
          };
        });
      };


      const completeFields  = useMemo(() => [
        ...itemList.inputFields,
        ...itemList.inputAreaFields,
        ...itemList.inputFloorFields,
        ...itemList.inputParkingFields,], [itemList])


      const onSave= () => {
        let dataFields = getGroupedFieldsData(itemList.inputFields);
        let areaFields = getGroupedFieldsData(itemList.inputAreaFields);
        let parkingFields = getGroupedFieldsData(itemList.inputParkingFields);
        let floorFields = getGroupedFieldsData(itemList.inputFloorFields);
    
        let profile = {
          type: "buildingProfile",
          subAssetIdent: currentSubAsset.subAssetIdent,
          subAssetType: currentSubAsset.subAssetType,
          properties: [
            ...dataFields,
            ...areaFields,
            ...parkingFields,
            ...floorFields,
          ],
          name: currentSubAsset.name,
          projectIdent: scope.projectIdent,
        };
        dispatch(save(profile));
      }

      const getWarningText = useCallback(() => {
        let updatedRows =[...properties.values()]?.filter( value => value.updated)
          .map((m) => m.labelTranslation);
        return (
          <>
            {updatedRows.length > 0 ? (
              <>
                {getTranslationVariantText("_unsaved changes", "subtitle2")}
                <List>
                  {updatedRows?.map((el, index) => {
                    return (
                      <ListItem key={index} style={{ padding: 0 }}>
                        <ListItemText
                          id={`${index}_${el}`}
                          primary={`${index + 1}. ${el.title}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
          </>
        );
      }, [properties]);

      const onUserLeave = () => {
            dispatch(resetState());
              dispatch(setOpenAsset(blockSubAssetChange.newSubAsset));
              setBlockSubAssetChange({
                block: false,
                newSubAsset: {},
              });
      }

      const onCustomPropertySave = (data) => {
        dispatch(
            // @ts-ignore
            saveCustomProperties({
              data: data,
              projectIdent: scope.projectIdent,
              subAssetIdent: openAsset.subAssetIdent,
            })
          );
      }


      const getOptionalProperties = useMemo(() => itemList.optionalFields, [itemList]);
      const getRequiredProperties = useMemo(() => completeFields, [completeFields]);
    
    return <BuildingProfileContext.Provider value={{
        itemList,
        setItemList,
        properties,
        currentSubAsset,
        setCurrentSubAsset,
        setBlockState,
        setProperties,
        onSave,
        getWarningText,
        blockSubAssetChange, 
        setBlockSubAssetChange,
        onUserLeave,
        openCustomItem, 
        setOpenCustomItem,
        onCustomPropertySave,
        getOptionalProperties,
        getRequiredProperties,
    }}>
        {children}
    </BuildingProfileContext.Provider>

}