import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import * as Yup from "yup";
import {
  Backdrop,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import {
  updateAsset,
} from "../../../../../api/assetOverviewApi";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material//Close";
import Slide from "@mui/material/Slide";
import {
  getDialogBoxHeader,
  getIntlTranslation,
} from "../../../../../utils/helper";
import AssetCreateForm from "./AssetCreateForm";
import IntlMessages from "../../../../../utils/IntlMessages";
import SaveButton from "../../../../../components/buttons/SaveButton";

const useStyles = styled((theme) => ({
  appBar: {
    position: "relative",
    zIndex: "0",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1350,
    color: "#fff",
  },
  backdrop2: {
    zIndex: theme.zIndex.drawer + 1400,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditAssetDialog = (props) => {
  const [intialFormData, setIntialFormData] = useState({
    name: "",
    numberofBuildings: 1,
    assetIdent: "",
  });
  const dispatch = useDispatch();
  const { asset, onClose, open, projectInfo, mode } = props;
  const [project, setProject] = useState(projectInfo);
  const scope = useSelector((state) => state.container.scope);
  const [openDialog, setOpenDialog] = useState(open);
  const [loader, setLoader] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    if (!!props.asset) {
      let tempObj = props.asset;
      setIntialFormData({
        name: tempObj.name,
        assetIdent: tempObj.assetIdent,
        numberofBuildings: !!tempObj.numberofBuildings
          ? tempObj.numberofBuildings
          : 1,
      });
    }
  }, [
    props.asset,
    scope.projectLanguageCode,
  ]);

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    setOpenDialog(false);
    setLoader(false);
  };

  const handleSubmit = (values) => {
    const editAsset = {
      api: scope.api,
      data: {
        type: "tddAssetDto",
        projectIdent: project.projectIdent,
        name: values.name,
        assetIdent: asset.assetIdent,
        numberofBuildings:
          values.numberofBuildings < 1 ? 1 : values.numberofBuildings,
      },
    };
    dispatch(updateAsset(editAsset));
    setLoader(true);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
        maxWidth="md"
        TransitionComponent={Transition}
        disableBackdropClick={!mode.isReadOnly}
        disableEscapeKeyDown={!mode.isReadOnly}
      >
        <AppBar className="appBar">
          <Toolbar className={"dialog-toolbar"}>
            {getDialogBoxHeader(`dialog.Update-Asset`)}
            {!props.isReadOnly && (
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={()=>handleClose()}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
          <DialogContent dividers>
            <AssetCreateForm
              intialFormData={intialFormData}
              handleSave={(values) => handleSubmit(values)}
              formRef={formRef}
              editDialog={true}
              mode={mode}
              onClose={handleClose}
            />
          </DialogContent>
          <DialogActions>
                    <SaveButton
                      id="save-asset-01"
                      buttonText={"_save"}
                      style={{
                        margin: 5,
                        backgroundColor: "#d9534f",
                        color: "#ffffff",
                        height: 40,
                      }}
                      variant="button"
                      tooltip={{
                        title: getIntlTranslation("tooltip.Save"),
                      }}
                      handleClick={(e) => formRef.current.handleSubmit()}
                    />
                  </DialogActions>
      </Dialog>
      {loader && (
        <Backdrop className={useStyles.backdrop2} open={loader}>
          <div className="preloader">
            <div></div>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default EditAssetDialog;
