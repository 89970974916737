import React from "react";
import { pmRoot } from "../../utils/defaultValues";
import ProjectmonitoringModuleHome from "./ProjectmonitoringModuleHome";
import IntlMessages from "../../utils/IntlMessages";
import ProjectListRouteConfig from "./feature-bundle/project-list/ProjectListRouteConfig";
import ProjectDashboardRouteConfig from "./feature-bundle/project-dashboard/ProjectDashboardRouteConfig";
import AssetOverviewRouteConfig from "./feature-bundle/asset-overview/AssetOverviewRouteConfig";
import BuildingProfileRouteConfig from "./feature-bundle/building-profile/BuildingProfileRouteConfig";
import ProjectDescriptionRouteConfig from "./feature-bundle/project-description/ProjectDescriptionRouteConfig";
import GeneralAssessmentRouteConfig from "./feature-bundle/general-assessment/GeneralAssessmentRouteConfig";
import ConclusionRouteConfig from "./feature-bundle/conclusion/ConclusionRouteConfig";
import KeyFindingsRouteConfig from "./feature-bundle/key-findings/KeyFindingsRouteConfig";
import ProjectDocumentationRouteConfig from "./feature-bundle/project-documentation/ProjectDocumentationRouteConfig";
import ProjectVariationsRouteConfig from "./feature-bundle/project-variations/ProjectVariationsRouteConfig";
import ProjectProgressRouteConfig from "./feature-bundle/project-progress/ProjectProgressRouteConfig";
import PhotoDocumentationRouteConfig from "./feature-bundle/photo-documentation/PhotoDocumentationRouteConfig";
import ProjectInformationRouteConfig from "./feature-bundle/project-information/ProjectInformationRouteConfig";
import MonitoringProjectScopeWrapper from "./MonitoringProjectScopeWrapper";
import FoundationIcon from "@mui/icons-material/Foundation";
import ListIcon from '@mui/icons-material/List';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import KeyIcon from '@mui/icons-material/Key';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import GridViewIcon from '@mui/icons-material/GridView';
import TimelineIcon from '@mui/icons-material/Timeline';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';

const ProjectmonitoringModuleRouteConfig = {
  routes: [
    {
      path: `${pmRoot}`,
      exact: true,
      id: "pm-management",
      name: <IntlMessages id={`menu.pm-management`} />,
      element: <ProjectmonitoringModuleHome />,
      icon: <FoundationIcon style={{ width:15, height:15, color: "#ffffff"}} />,
      auth: [],
      isParent: true,
      showBreadCrumbs: true,
      children: [
        ...ProjectListRouteConfig.routes.map((u) => ({...u, 
          id: 'pm-project-list',
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <ListIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
        })),
        ...ProjectDashboardRouteConfig.routes.map((u) => ({
          ...u,
          showBreadCrumbs: false,
          id:"pm-project-dasboard",
          parentPath:`${pmRoot}`,
          icon: <GridViewIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...AssetOverviewRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-assets-overview",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <LocationCityIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...ProjectInformationRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-project-information",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <BusinessCenterIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...BuildingProfileRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-building-profile",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <RoomPreferencesIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...ProjectDescriptionRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-project-description",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <DesignServicesIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...GeneralAssessmentRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-general-assessment",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <InsertChartIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...ConclusionRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-conclusion",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <PendingActionsIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...KeyFindingsRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-key-findings",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <KeyIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...ProjectDocumentationRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-documentation",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <TextSnippetIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...ProjectVariationsRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-variations",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <LegendToggleIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...ProjectProgressRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-project-progress",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <TimelineIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
        ...PhotoDocumentationRouteConfig.routes.map((u) => ({
          ...u,
          id: "pm-photo-documentation",
          showBreadCrumbs: true,
          parentPath:`${pmRoot}`,
          icon: <LinkedCameraIcon style={{marginLeft:5, width:22, height:22, color: "#244a9a"}}/>,
          element: (
            <MonitoringProjectScopeWrapper>
              {u.element}
            </MonitoringProjectScopeWrapper>
          ),
        })),
      ],
    },
  ],
};
export default ProjectmonitoringModuleRouteConfig;
