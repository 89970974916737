import axios from "../../../api/customAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import { renewAccessTokenIfGoingExpire } from "../../../api/authApi";
import { getFailure, getDataCollectionSuccess } from "../DataCollectionSlice";

export const getDataCollection = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/tdd/data-collection/fetch`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataCollectionSuccess(response.data));
    }
  } catch (error) {
    let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
  }
};
