import areaInputImage from "../assets/images/areaInputImage.png";
import dataYearInputImage from "../assets/images/dataYearInputImage.jpeg";
import dateFuturInputImage from "../assets/images/dateFuturInputImage.jpeg";
import dateInputImage from "../assets/images/dateInputImage.jpeg";
import locationInputImage from "../assets/images/locationInputImage.jpeg";
import numberInputImage from "../assets/images/numberInputImage.jpeg";
import textEditorImage from "../assets/images/textEditor.jpeg";
import textInputImage from "../assets/images/textInputImage.jpeg";


export const globalRoot = '/guru';
export const tddRoot = '/guru/tdd';
export const esgRoot = '/guru/esg';
export const pmRoot = '/guru/monitoring';
export const tamRoot = '/guru/tam';

export const tddServiceLine = {
    name:'Tdd',
    api:"tdd",
    root:'/guru/tdd',
};
export const pmServiceLine = {
    name:'Project Monitoring',
    api:'monitoring',
    root:'/guru/monitoring'
}
export const response={
    type:"",
    message:[],
    data:{}
}

export const serviceLines = [
    {
        name:'Tdd',
        api:"tdd",
        root:'/guru/tdd'
    },
    {
        name:'Project Monitoring',
        api:'monitoring',
        root:'/guru/monitoring'
    }

];


export const capex =
    {
      value: "CAPEX",
      label: "CAPEX",
      key: "cp",
      disabled: false,
    };
export const capexPortfolioDetail =
    {
      value: "CAPEX Portfolio Detail",
      label: "CAPEX Portfolio Detail",
      key: "cpd",
      disabled: false,
    };

 export const capexPortfolioSummary =   
    {
      value: "CAPEX Portfolio Summary",
      label: "CAPEX Portfolio Summary",
      key: "cps",
      disabled: false,
    }

    export const draftVersion = {
        value: "Draft",
        label: "Draft",
      }

export const finalVersion =
      {
        value: "Final",
        label: "Final",
      }

export const englishLanguage =  { label: "English", value: "en" }

export const germanLanguage =  { label: "Deutsch", value: "de" }

export const photoDocumentation = {
    value: "Photodocumentation",
    label: "Photodocumentation",
    key: "pd",
    disabled: false,
  };

  export const redFlag = 
  {
    value: "Redflag",
    label: "Redflag",
    key: "rf",
    disabled: false,
    heading:'menu.redFlag',
  }

  export const fullReport =
  {
    value: "Full Report",
    label: "Full Report",
    key: "fr",
    disabled: false,
    heading:'menu.fullReport',
  }

  export const propertyType = [
    {
      label: {
        en: "Text",
        de: "Text",
      },
      value: "text",
      message:
        "The Text type allows users to enter simple text input, such as their name, description, or a short message. It's a common input type used in forms, surveys, and other data collection applications.",
      image: textInputImage,
    },
    {
      label: {
        en: "Text-Editor",
        de: "Text-Editor",
      },
      value: "textarea",
      message:
        "The Text Editor type allows users to input, modify, and format text in various ways, such as bold, italic, underline, and font size. It's a more advanced input type than simple text and is commonly used in applications that require users to create and edit documents, articles, or blog posts.",
      image: textEditorImage,
    },
    {
      label: {
        en: "Number",
        de: "Nummer",
      },
      value: "number",
      message:
        "The Number type is used for capturing numerical input from the user. Users can enter whole numbers or decimal values.",
      image: numberInputImage,
    },
    {
      label: {
        en: "Location",
        de: "Adresse",
      },
      value: "address",
      message:
        "The Location type allows users to input a location by entering an address or by searching for it using Google Maps.",
      image: locationInputImage,
    },
    {
      label: { en: "Date", de: "Datum" },
      value: "date",
      message:
        "The Date type allows users to pick a specific date from a calendar, including the day, month, and year.",
      image: dateInputImage,
    },
    {
      label: { en: "Date (Year Only)", de: "Datum (nur Jahr)" },
      value: "date-year",
      message:
        "The Date (Year Only) type allows users to pick a specific year from a calendar",
      image: dataYearInputImage,
    },
    {
      label: { en: "Date (Future)", de: "Datum (Zukunft)" },
      value: "date-future",
      message:
        "The Date (Future) type allows users to pick a future date from a calendar, including the day, month, and year.",
      image: dateFuturInputImage,
    },
    {
      label: { en: "Area", de: "Area" },
      value: "area",
      message: "The Area type allows users to specify area in m².",
      image: areaInputImage,
    },
    {
      label: { en: "Parking", de: "Parkplatz" },
      value: "parking-number",
      message: "The Parking type allows users to specify parking fields.",
    },
    {
      label: { en: "Floor", de: "Stockwerk" },
      value: "floor-number",
      message: "The Floor type allows users to specify floor fields.",
    },
  ];


  export const getServiceLine = (name) => {
    return serviceLines.find((line) => line.name.toLowerCase() === name.toLowerCase())
  }

