import { Box, OutlinedInput, InputAdornment, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CustomIconButton from "../../../../components/buttons/icons-buttons/CustomIconButton";
import PlacesAutocomplete from "../../../../components/locations/PlacesAutocomplete";
import { createAddress } from "../../../../utils/helper";

const AddressInput = ({ id, disabled, node, mapConfig, handleChange }) => {
  const [value, setValue] = useState(node.value);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [openMap, setOpenMap] = useState({
    open: false,
    anchorEl: null,
    nodeIdent: null,
    ident: null,
    value: null,
  });

  useEffect(() => {
    if (node.value !== null) {
      setValue(node.value);
    } else {
      setValue(null);
    }
  }, [node.value]);

  return (
    <Box className="d-flex w-100">
      <OutlinedInput
        fullWidth
        id={id}
        key={`address-${id}`}
        disabled={disabled}
        value={value}
        multiline
        rowsMax={Infinity}
        variant="outlined"
        size="small"
        dense="true"
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => {
          if (e.target.value !== node.value) {
            handleChange(e.target.value, node, true);
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <CustomIconButton
              id="custom_address_search"
              handleClick={(e) => {
                setOpenMap({
                  ...mapConfig,
                  open: true,
                  anchorEl: e.currentTarget,
                });
              }}
              icon={<TravelExploreIcon style={{ color: "#1f88db" }} />}
              tooltip={{
                title: "Click to search address",
              }}
              aria-label="toggle visibility"
              aria-describedby="googleMaps"
              aria-haspopup="true"
            />
          </InputAdornment>
        }
      />
      <Popover
        id={"googleMaps"}
        open={openMap.open}
        anchorEl={openMap.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "40%" },
        }}
        onClose={() =>
          setOpenMap({
            open: false,
            anchorEl: null,
            nodeIdent: null,
            ident: null,
            value: null,
          })
        }
      >
        <PlacesAutocomplete
          setLat={setLat}
          setLng={setLng}
          setGetAddress={(val) => {
            let address = createAddress(val);
            setValue(address);
            handleChange(address, node, true);
            setOpenMap({
              open: false,
              anchorEl: null,
              nodeIdent: null,
              ident: null,
              value: null,
            });
          }}
          fullAddress={openMap.value}
        />
      </Popover>
    </Box>
  );
};

export default AddressInput;
