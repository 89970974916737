import React from "react";
import PropTypes from "prop-types";
import { Badge, Button, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import {
  orange,
  pink,
  yellow,
  deepPurple,
  deepOrange,
  red,
} from "@mui/material/colors";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DoneIcon from "@mui/icons-material/Done";

const SideBarMenu = (props) => {
  return (
    <Grid style={{ width: "250px", backgroundColor: "white" }}>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 5,
        }}
      >
        <Button sx={{ background: red[600], color: "white", width: "80%" }}>
          <AddIcon />
          New Task
        </Button>
      </Grid>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 5,
        }}
      >
        <Button
          className="g-btn-primary"
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <ListIcon />
          <Typography style={{ marginLeft: 5 }}>All</Typography>
        </Button>
      </Grid>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: 5,
          marginTop:10
        }}
      >
        <Typography style={{ marginLeft: 5 }}>Filters</Typography>
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems:"flex-start",
            padding: 5,
          }}
        >
          <IconButton>
            <StarBorderIcon />
            <Typography style={{ marginLeft: 1 }}>Favourites</Typography>
          </IconButton>
          <IconButton>
            <DoneIcon />
            <Typography style={{ marginLeft: 1 }}>Done</Typography>
          </IconButton>
          <IconButton>
            <DeleteOutlineIcon />
            <Typography style={{ marginLeft: 1 }}>Deleted</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: 5,
        }}
      >
        <Typography style={{ marginLeft: 5 }}>Labels</Typography>
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems:"flex-start",
            padding: 15,
          }}
        >
          <Grid style={{width: "100%",display:"flex", justifyContent:"space-between",alignItems:"center",padding:4}}>
            <Typography style={{ marginLeft: 1 }}>TDD</Typography>
            <Grid style={{width: 15,height:15,background:"#666ee8", borderRadius:"50%"}}></Grid>
          </Grid>
          <Grid style={{width: "100%",display:"flex", justifyContent:"space-between",alignItems:"center",padding:4}}>
            <Typography style={{ marginLeft: 1 }}>Monitoring</Typography>
            <Grid style={{width: 15,height:15,background:"#28d094", borderRadius:"50%"}}></Grid>
          </Grid>
          <Grid style={{width: "100%",display:"flex", justifyContent:"space-between",alignItems:"center",padding:4}}>
            <Typography style={{ marginLeft: 1 }}>Esg</Typography>
            <Grid style={{width: 15,height:15,background:"#ff4961", borderRadius:"50%"}}></Grid>
          </Grid>
          <Grid style={{width: "100%",display:"flex", justifyContent:"space-between",alignItems:"center",padding:4}}>
            <Typography style={{ marginLeft: 1 }}>Tam</Typography>
            <Grid style={{width: 15,height:15,background:"#ff9149", borderRadius:"50%"}}></Grid>
          </Grid>
          <Grid style={{width: "100%",display:"flex", justifyContent:"space-between",alignItems:"center",padding:4}}>
            <Typography style={{ marginLeft: 1 }}>Infrastructure</Typography>
            <Grid style={{width: 15,height:15,background:"#1e9ff2", borderRadius:"50%"}}></Grid>
          </Grid>
          <Grid style={{width: "100%",display:"flex", justifyContent:"space-between",alignItems:"center",padding:4}}>
            <Typography style={{ marginLeft: 1 }}>Personnal</Typography>
            <Grid style={{width: 15,height:15,background:"#86bf36", borderRadius:"50%"}}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SideBarMenu.propTypes = {};

export default SideBarMenu;
