import { useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { authenticateUserWithSSO } from "../api/authApi";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const token = location.search?.split("=")?.[1]?.split("&")?.[0];

  useLayoutEffect(() => {

  const fn = async () => {
    await new Promise((res, reject) => {
        res(dispatch(authenticateUserWithSSO(token)))
    })
    window.location.replace("/");
    
  }

  fn()
    

  }, [token]);

//   useEffect(() => {

//   }, [])

  return null;
};

export default Dashboard;
