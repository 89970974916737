import {
  List,
  ListItem,
  Typography,
  Box,
  Card,
  CardContent,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import InputSearch from "../../../components/InputSearch";
import { useIntl } from "react-intl";
import { FETCHED } from "../../../common/apiStatus";
import useCostSuggestionHook from "./useCostSuggestionHook";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CloseIcon from "@mui/icons-material//Close";
import { useSelector } from "react-redux";
import { AutoSizer, List as VirtualizedList, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

const cellStyle = {
    marginLeft: 5,
    borderRight: "1px solid #0000001f",
    height: "100%",
    padding: 5,
  };

const CostSuggestionContainer = ({selectedItem, onSelect}) => {
  const intl = useIntl();
  const [itemList, setItemList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const costSuggestion = useSelector((state) => state.costSuggestion);
  const [onDataFetch] = useCostSuggestionHook();

  useEffect(() => {
    if(!costSuggestion.success.list.length){
      onDataFetch();
    }
   
  }, [costSuggestion.success.list]);

  function removeDuplicates(array) {
    return array.filter((item, index, self) => {
      return index === self.findIndex((t) => (
        t.ident === item.ident
      ));
    });
  }
  
  

  useEffect(() => {
    if (!!costSuggestion.success && costSuggestion.success.type === FETCHED) {
      const uniqueArray = removeDuplicates(costSuggestion.success.list);
      let temp = uniqueArray.map((m, index) => {
        return {
          id: index +1 ,
          ident: m.ident,
          costCode: m.costCode,
          parentName: m.parentName,
          parentIdent: m.parentIdent,
          description: m.name,
          searchable: `${m.parentName} : ${m.name}`,
          defectType: {
            isLifeCycle: m.lifeCycle,
            isDefect: m.defect,
          },
          typeOfElement: {
            isMisch: m.default,
            isDetail: !m.default,
          },
          unit: m.unit,
          cost: {
            von: m.von,
            mittel: m.mittel,
            bis: m.bis,
          },
          technicalLifeCycle: "",
        };
      });
      setItemList(temp);
      setFilterList(temp);
    }
  }, [costSuggestion.success]);

  const headers = [
    {
      columnName: "id",
      displayName: "id",
      order: 1,
      width: "5%",
      childHeaders: [],
    },
    {
      columnName: "parentName",
      displayName: intl.formatMessage({ id: "_Name" }),
      order: 1,
      width: "20%",
      childHeaders: [],
    },
    {
      columnName: "description",
      displayName: intl.formatMessage({ id: "_description" }),
      order: 2,
      width: "35%",
      childHeaders: [],
    },
    // {
    //   columnName: "defectType",
    //   displayName: intl.formatMessage({ id: "_Defect-Type" }),
    //   order: 3,
    //   width: "15%",
    //   childHeaders: [
    //     {
    //       columnName: "isLifeCycle",
    //       displayName: intl.formatMessage({ id: "_lifecycle" }),
    //       order: 1,
    //       type: "boolean",
    //     },
    //     {
    //       columnName: "isDefect",
    //       displayName: intl.formatMessage({ id: "_defect" }),
    //       order: 2,
    //       type: "boolean",
    //     },
    //   ],
    // },
    // {
    //   columnName: "typeOfElement",
    //   displayName: intl.formatMessage({ id: "_type-of-element" }),
    //   order: 4,
    //   width: "12%",
    //   childHeaders: [
    //     {
    //       columnName: "isMisch",
    //       displayName: "misch",
    //       order: 1,
    //       type: "boolean",
    //     },
    //     {
    //       columnName: "isDetail",
    //       displayName: "detail",
    //       order: 2,
    //       type: "boolean",
    //     },
    //   ],
    // },
    {
      columnName: "unit",
      displayName: intl.formatMessage({ id: "_Unit" }),
      order: 5,
      width: "5%",
      childHeaders: [],
    },
    {
      columnName: "cost",
      displayName: intl.formatMessage({ id: "_cost-estimates" }),
      order: 6,
      width: "30%",
      childHeaders: [
        {
          columnName: "von",
          displayName: intl.formatMessage({ id: "_from" }),
          order: 1,
        },
        {
          columnName: "mittel",
          displayName: intl.formatMessage({ id: "_middle" }),
          order: 2,
        },
        {
          columnName: "bis",
          displayName: intl.formatMessage({ id: "_upto" }),
          order: 3,
        },
      ],
    },
    // {
    //   columnName: "technicalLifeCycle",
    //   displayName: intl.formatMessage({ id: "_technical-lifecycle" }),
    //   order: 7,
    //   childHeaders: [],
    //   width: "10%",
    // },
    {
      columnName: "Select",
      displayName: intl.formatMessage({ id: "_select" }),
      order: 7,
      childHeaders: [],
      width: "5%",
      type: "checkbox",
    },
  ];

  const filteredItems = (searchQuery) => {
    searchQuery
      ? setFilterList(
          itemList.filter((item) =>
            item.searchable.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
      : setFilterList(itemList);
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 50, // Default minimum height
  });

  const createHeader = () => {
    return (
      <List className={"p-1 w-100"}>
        <ListItem
          divider
          style={{
            height: 60,
            zIndex: 1500,
            borderRadius: 4,
          }}
          className="p-0 g-btn-primary w-100"
          key="headers"
        >
          {headers.map((item) => (
            <div
              style={{
                ...cellStyle,
                width: item.width,
                borderColor: "#ffffff",
              }}
              className={
                "d-flex align-items-center justify-content-center  h-100"
              }
            >
              <Typography
                style={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {item.displayName}
              </Typography>
            </div>
          ))}
        </ListItem>
        <ListItem
          divider
          style={{
            height: 30,
            zIndex: 1500,
            borderRadius: 4,
          }}
          className="p-0 g-btn-secondary"
          key="child-headers"
        >
          {headers.map((item) => {
            if (item.childHeaders.length > 0) {
              return (
                <div
                  style={{
                    ...cellStyle,
                    width: item.width,
                    borderColor: "#ffffff",
                  }}
                  className={
                    "d-flex align-items-center justify-content-between h-100"
                  }
                >
                  {item.childHeaders.map((child, index) => {
                    return (
                      <div
                        style={{
                          borderColor: "#ffffff",
                          width: `calc(100% / ${item.childHeaders.length}`,
                          borderRight:
                            index < item.childHeaders.length - 1
                              ? "1px solid #fff"
                              : "none",
                        }}
                        className={
                          "d-flex align-items-center justify-content-center h-100"
                        }
                      >
                        <Typography
                          style={{
                            textTransform: "capitalize",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {child.displayName}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    ...cellStyle,
                    width: item.width,
                    borderColor: "#ffffff",
                  }}
                  className={
                    "d-flex align-items-center justify-content-center h-100"
                  }
                ></div>
              );
            }
          })}
        </ListItem>
      </List>
    );
  };
  const calculateListHeight = () => {
    return window.innerHeight - 100;
  };

  const rowRenderer = ({ index, key, parent, style }) => {
    const item = filterList[index];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>
        <CreateRows
              item={item}
              rowIndex={index}
              headers={headers}
              selectedItem={selectedItem}
              onCheckBoxSelect={(item) => onSelect(item)}
            />
        </div>
      </CellMeasurer>
    );
  };

  const createDataRows = () => {
    return (
      <AutoSizer disableHeight>
      {({ width }) => (
        <VirtualizedList
          width={width}
          height={calculateListHeight()}
          rowCount={filterList.length}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
    );
  };

  return (
    <>
      <Box
        className="d-flex flex-column align-items-center w-100"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1800,
          borderRadius: 4,
          backgroundColor: "#ffffff",
        }}
      >
        <Box
          className="d-flex align-items-center justify-content-end"
          style={{ width: "70%", marginBottom: "5px", marginLeft: "auto" , height:50}}
        >
          <InputSearch onFilterInput={(input) => filteredItems(input)} />
        </Box>
        {createHeader()}
        {filterList.length === 0 && 
        <Box className="w-100">
        <LinearProgress style={{backgroundColor:"#c1d0f0"}}/>
      </Box>
        }
      </Box>
{filterList.length > 0 && 
      <Card className="p-0">
        <CardContent className="p-0">{createDataRows()}</CardContent>
      </Card>
}
    </>
  );
};

const CreateRows = ({ item, rowIndex, headers, selectedItem, onCheckBoxSelect}) => {
  const itemRef = useRef();
  return (
    <ListItem
      ref={itemRef}
      divider
      style={{
        top: 0,
        zIndex: 1500,
        borderRadius: 4,
        backgroundColor: "#f2f2f2e6",
      }}
      className={`p-0 w-100`}
      key={`data-row-${rowIndex}`}
    >
      {headers.map((header, index) => {
        if (header.childHeaders.length > 0) {
          return (
            <div
              style={{
                ...cellStyle,
                width: header.width,
                borderColor: "#ffffff",
              }}
              className={
                "d-flex align-items-center justify-content-between h-100"
              }
            >
              {header.childHeaders.map((child, index) => {
                return (
                  <div
                    style={{
                      borderColor: "#ffffff",
                      width: `calc(100% / ${header.childHeaders.length}`,
                      borderRight:
                        index < header.childHeaders.length - 1
                          ? "1px solid #fff"
                          : "none",
                    }}
                    className={
                      "d-flex align-items-center justify-content-center h-100"
                    }
                  >
                    {!!child.type && child.type === "boolean" ? (
                      item[header.columnName][child.columnName] ? (
                        <CloseIcon
                          style={{
                            fontSize: "18",
                            color: "#254a9a",
                          }}
                        />
                      ) : (
                        <PanoramaFishEyeIcon
                          style={{
                            fontSize: "18",
                          }}
                        />
                      )
                    ) : (
                      <Typography
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {item[header.columnName][child.columnName]}
                      </Typography>
                    )}
                  </div>
                );
              })}
            </div>
          );
        } else if (header.type === "checkbox") {
          return (
            <div
              style={{
                ...cellStyle,
                width: header.width,
                borderColor: "#ffffff",
              }}
              className={
                "d-flex align-items-center justify-content-center h-100"
              }
            >
              <Checkbox
                onChange={(e) => onCheckBoxSelect(item)}
                checked={selectedItem?.ident === item.ident}
                key={`checkbox-${rowIndex}`}
              />
            </div>
          );
        } else {
          return (
            <div
              style={{
                ...cellStyle,
                width: header.width,
                borderColor: "#ffffff",
              }}
              className={
                "d-flex align-items-center justify-content-center h-100"
              }
            >
              <Typography
                style={{
                  textAlign: "center",
                  overflowWrap: "anywhere",
                }}
              >
                {item[header.columnName]}
              </Typography>
            </div>
          );
        }
      })}
    </ListItem>
  );
};

export default CostSuggestionContainer;
