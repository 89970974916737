import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../utils/defaultValues";

export const initialState = {
  hasErrors: false,
  error:{
    type: "",
   messages: [],
   data: {},
 },
 clients:{...response}
};

const clientManagementSlice = createSlice({
  name: "clientManagement",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }
    }),
    getDataSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      clients:{
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDtoList,
     }
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

export const { resetState,getDataSuccess,getFailure } = clientManagementSlice.actions;

// A selector
export const clientManagementSelector = (state) => state.clientManagement;

// The reducer
export default clientManagementSlice.reducer;