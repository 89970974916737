import { Box, Typography } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { b64toBlob, splitArray, getLabel } from "../../../../../../utils/helper";
import { ImageProvider } from "../../../../../component-bundle/image-gallery/ImageContext";
import SortablePhotoAlbum from "../../../../../component-bundle/image-gallery/SortablePhotoAlbum";
import useImageHook from "../../hooks/useImageHook";

export const ImageUploaderContainer = ({
  images,
  reportType,
  width = 300,
  height = 200,
  maxFiles = 2,
  isQuelleNeeded= true,
  isDragable= true,
}) => {
  const [files, setFiles] = useState([]);
  const openAsset = useSelector((state) => state.openAsset);
  const {
    imageData,
    loader,
    onSetLoader,
    onImageDelete,
    onImagesave,
    onResetState,
  } = useImageHook();

  const createImageObj = (file, img) => {
    let generatedId = crypto.randomUUID();
    return Object.assign(file, {
      id: generatedId,
      key: generatedId,
      status: "new",
      caption: "",
      source: "",
      orgCaption: "",
      orgSource: "",
      order: null,
      parentIdent: img.parentIdent,
      fileName: file.name,
      assetIdent: openAsset.ident,
      src: URL.createObjectURL(file),
      width: width,
      height: height,
      isEdited: false,
      markerState: {},
      editedFile: {},
      captionPlaceholder:img.captionPlaceholder,
      label: img.label,
    });
  };

  const onImageDrop = useCallback(
    (acceptedFiles) => {
      let updatedFiles = [...files];
      for (let i = 0; i < Math.min(acceptedFiles.length, maxFiles); i++) {
        const fileToUpdate = updatedFiles.findIndex(
          (file) => file.src === null
        );
        if (fileToUpdate !== -1) {
          updatedFiles[fileToUpdate] = createImageObj(
            acceptedFiles[i],
            updatedFiles[fileToUpdate]
          );
        }
      }
      setFiles(updatedFiles);
    },
    [files]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles,
    accept: "image/jpg, image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      onImageDrop(acceptedFiles);
    },
  });

  useEffect(() => {
    if (images.length > 0) {
      setFiles(
        images.map((i) => {
          const image = i.imageMetaDto;
          return {
            id: image.imageIdent,
            status: "saved",
            caption: image.caption,
            source: image?.source,
            orgCaption: image.caption,
            orgSource: image?.source,
            order: image.order,
            fileName: image.fileName,
            assetIdent: openAsset.ident,
            parentIdent: image.parentIdent,
            src: !!image.file ? b64toBlob(image.file) : null,
            key: image.imageIdent,
            width: width,
            height: height,
            markerState: !!image.markerState
              ? JSON.parse(image.markerState)
              : {},
            editedFile: !!image.editedFile ? b64toBlob(image.editedFile) : {},
            isEdited: image.edited,
            captionPlaceholder: image.captionPlaceholder,
            label:i.label,
          };
        })
      );
    }
  }, [images]);

  useEffect(() => {
    if (
      !!imageData &&
      !!imageData.data &&
      !!files.find((f) => f.parentIdent === imageData.data.parentIdent)
    ) {
      const image = imageData.data;
      setFiles(
        files.map((f) => {
          if (f.parentIdent === image.parentIdent) {
            return {
              ...f,
              status: "saved",
              id: image.imageIdent,
              parentIdent: image.parentIdent,
              caption: image.caption,
              source: image?.source,
              orgCaption: image.caption,
              orgSource: image?.source,
              fileName: image.fileName,
              order: image.order,
              src: b64toBlob(image.file),
              key: image.imageIdent,
              width: width,
              height: height,
              markerState: !!image.markerState
                ? JSON.parse(image.markerState)
                : {},
              editedFile: !!image.editedFile ? b64toBlob(image.editedFile) : {},
              isEdited: image.edited,
              captionPlaceholder: image.captionPlaceholder,
            };
          }
          return f;
        })
      );
      onSetLoader(false);
      onResetState();
    }
  }, [imageData, files]);

  const isDataPresent = useCallback(() => {
    return files?.filter((file) => file.src).length > 0;
  }, [files]);
  const isDropZoneRequired = useCallback(() => {
    return files?.filter((file) => file.src).length < 2;
  }, [files]);

  const onRemove = (file) => {
    const [toBeDeleted, rest] = splitArray(
      files,
      (f) =>
        f.key === file.key &&
        f.fileName === file.fileName &&
        f.parentIdent === file.parentIdent
    );
    let deletedFiles = toBeDeleted.map((f) => {
      if (f.status !== "new") {
        onImageDelete(f, {
          ident: f.parentIdent,
          reportType: reportType,
          type: "report-image",
        });
      }
      return { ...f, src: null };
    });
    setFiles([...rest, ...deletedFiles]);
  };
  const onSave = (file) => {
    const index = files.findIndex(
      (f) => f.key === file.key && f.fileName === file.fileName
    );
    files[index].order = index + 1;
    let node = {
      ident: files[index].parentIdent,
      reportType: reportType,
      type: "report-image",
    };
    onImagesave(files[index], node);
  };
  const onCaptionChange = useCallback(
    (key, value) => {
      const tempFile = files.map((file) => {
        if (key === file.key) {
          if (value !== file.orgCaption) {
            file.caption = value;
          }
        }
        return file;
      });
      setFiles(tempFile);
    },
    [files]
  );

  const onQuelleChange = useCallback(
    (key, value) => {
      const tempFile = files.map((file) => {
        if (key === file.key) {
          if (value !== file.orgSource) {
            file.source = value;
          }
        }
        return file;
      });
      setFiles(tempFile);
    },
    [files]
  );

  return (
    <>
      {isDropZoneRequired() && (
        <Box
          style={{
            width: "20%",
          }}
        >
          <div
            className={`image-drop ${isDragActive ? "image-drop-active" : ""}`}
            {...getRootProps()}
            style={{
              height: "100%",
              border: "4px double #d08f8e",
              cursor: "pointer",
            }}
          >
            <input className="dropzone-input" {...getInputProps()} />
            <div style={{ textAlign: "center" }} className="p-1">
              {isDragActive ? (
                <Typography className="dropzone-content">
                  {getLabel("_drop-the-files")}
                </Typography>
              ) : (
                <>
                  <Typography className="dropzone-content">
                    {getLabel("_Drag-Images")}
                  </Typography>
                  <em>{getLabel("_Image-Allowed")}</em>
                </>
              )}
            </div>
          </div>
        </Box>
      )}

      {isDataPresent() && (
        <ImageProvider
          onRemove={(file) => onRemove(file)}
          onImageSave={(file) => {
            onSetLoader(true);
            onSave(file)
          }}
          onCaptionChange={(key, value) => onCaptionChange(key, value)}
          onQuelleChange={(key, value) => onQuelleChange(key, value)}
          onImageEdit={(photo, editedImage, markerState) => {
            let temp = files.map((file) => {
              if (file.key === photo.key && photo.fileName === file.fileName) {
                if (!!markerState) {
                  file.markerState = markerState;
                  file.status =
                    file.status === "saved" ? "edited" : file.status;
                  file.isEdited = true;
                  file.editedFile = editedImage;
                }
              }
              return file;
            });
            setFiles(temp);
          }}
          isQuelleNeeded={isQuelleNeeded}
          isDragable={isDragable}
          onDragEnd={(photos) => setFiles(photos)}
        >
          <SortablePhotoAlbum photoSet={files} />
        </ImageProvider>
      )}
    </>
  );
};