import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { Grid, Typography, Box } from "@mui/material";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";


const { GOOGLE_MAP_TOKEN } = process.env;

const MapCarte = ({
  lat,
  lng,
  fullAddress,
  id,
  makeSnape,
  setMakeSnape,
  setScreenSnape,
  onZoomChange,
  onMapTypeIdChange,

}) => {

  const AnyReactComponent = ({ text }) => (
    <LocationOnRoundedIcon sx={{ color: "red" }} />
  );

  const defaultProps = {
    center: {
      lat: lat ? lat : 52.5214364,
      lng: lng ? lng : 13.4031828,
    },
    zoom: 16,
  };
  const createMapOptions = function (maps) {
    return {
      panControl: false,
      mapTypeControl: true,
      scrollwheel: false,
      gestureHandling: "greedy",
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
      rotateControl: true,
      maxZoom: 20,
      minZoom: 12,
      styles: [
        {
          stylers: [
            { saturation: -50 },
            { gamma: 0.8 },
            { lightness: 8 },
            { visibility: "of" },
          ],
        },
      ],
    };
  };
  return (
    <>
      <Grid style={{ marginLeft: 20, width: "90%" }}>
        <Box>
          <Typography variant="overline">{fullAddress}</Typography>
        </Box>
      </Grid>
      <Grid id={id} style={{ marginLeft: 20, height: "40vh", width: "90%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAP_TOKEN }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={defaultProps.center}
          options={createMapOptions}
          onChange={(e) => onZoomChange(e)}
          onMapTypeIdChange={(mapType)=> onMapTypeIdChange(mapType)}
        >
          <AnyReactComponent
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
          />
        </GoogleMapReact>
      </Grid>
    </>
  );
};

export default MapCarte;
