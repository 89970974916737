import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { resetState } from './createProjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { getUsers } from '../../../api/userManagementApi';
import { resetState as reset } from '../users/usersSlice';
import React, { useEffect, useRef, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveButton from '../../../components/buttons/SaveButton';
import { AppBar, Box, IconButton, Slide, Toolbar } from '@mui/material';
import CreateProjectContainer from './structure/CreateProjectContainer';
import { setProjectOpen } from '../../../layout/app-container/containerSlice';
import {
  getDialogBoxHeader,
  getIntlTranslation,
  getTranslationVariantText,
} from '../../../utils/helper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateProjectHome = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.createProject);
  const usersState = useSelector((state) => state.users);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const formRef = useRef();
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleChange = (event) => {
    setRedirect(event.target.checked);
  };

  const openProject = (data) => {
    navigate(`${props.serviceLine.root}/project/project-dashboard`);
    dispatch(
      setProjectOpen({
        serviceLine: props.serviceLine.name.toLowerCase(),
        api: props.serviceLine.api,
        isProjectScope: true,
        projectIdent: data.projectIdent,
        projectName: data.projectName,
        isReadOnly: data.isReadOnly,
        currentProjectRole: 'Default',
        projectLanguageCode: !!data.languageCode ? data.languageCode : 'de',
      })
    );
    setRedirect(false);
  };

  useEffect(() => {
    if (usersState.userList.type === 'FETCHED' && !!usersState.userList.data) {
      let temp = usersState.userList.data.map((user) => {
        return {
          title: `${user.firstName} ${user.lastName}`,
          ...user,
        };
      });
      setUsers(temp);
      dispatch(reset());
    }
    if (state.createProject.type === 'SUCCESS' && !!state.createProject.data) {
      state.createProject.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      if (redirect) {
        openProject(state.createProject.data);
        dispatch(resetState());
      } else {
        props.onProjectCreate();
        dispatch(resetState());
      }
    }
  }, [dispatch, enqueueSnackbar, props, state, usersState]);

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'lg'}
      onClose={(e) => props.onClose()}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ overflow: 'hidden' }}
    >
      <AppBar className={'appBar'}>
        <Toolbar className={'dialog-toolbar'}>
          {getDialogBoxHeader('menu.create-project')}
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => props.onClose()}
            aria-label="close"
            style={{ marginLeft: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers className={'custom-scrollBar'}>
        <div className="container">
          <CreateProjectContainer
            users={users}
            serviceLine={props.serviceLine}
            projectList={props.projectList}
            formikRef={formRef}
          />
        </div>
      </DialogContent>
      <DialogActions className="w-100 d-flex align-items-center justify-content-between">
        <FormControlLabel
          control={
            <Checkbox
              checked={redirect}
              onChange={handleChange}
              name="redirect"
            />
          }
          label={getTranslationVariantText('_projectRedirect',"subtitle2")}
        />
        <Box className="w-25 d-flex">
        <SaveButton
          id="create-project-button"
          buttonText="_create"
          
          variant="button"
          style={{ marginLeft: "auto" }}
          tooltip={{
            title: getIntlTranslation("tooltip.create-project"),
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CreateProjectHome.propTypes = {
  serviceLine: PropTypes.string,
  projectList: PropTypes.array,
  onProjectCreate: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default CreateProjectHome;
