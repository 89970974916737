import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import {
  AppBar,
  Toolbar,
  Card,
  CardContent,
  IconButton,
  Zoom,
  Tooltip,
  Autocomplete,
  TextField,
  CardActions,
  Typography,
  Checkbox,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  getVariantText,
  getTranslationVariantText,
  getIntlTranslation,
  getDialogBoxHeader,
  randomIdGenerator,
} from "../../../utils/helper";
import CloseIcon from "@mui/icons-material//Close";
import { columnTypes } from "./ColumnDef";
import CustomButton from "../../../components/buttons/CustomButton";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilteringMenu = (props) => {
  const [filterArry, setFilterArry] = useState([]);

  useEffect(() => {
    if (props.columnList.length > 0) {
      setFilterArry([
        {
          id: randomIdGenerator("filter"),
          columnName: "",
          columnValues: [],
          displayName: "",
          type: "",
          operation: null,
          operationValue: null,
          order: 1,
        },
      ]);
    }
  }, [props.columnList]);

  const getOperatorOptions = (filterObj) => {
    return (
      columnTypes.find((f) => !!filterObj.type && f.type === filterObj.type)
        ?.operatiorOptions || []
    );
  };

  const getInputProps = (filterObj) => {
    return columnTypes.find(
      (f) => !!filterObj.type && f.type === filterObj.type
    )?.inputProps;
  };

  const getColumnOptions = useCallback((filterObj) => {
    let temp = [];
    props?.dataList?.forEach((m) => {
      if (!temp.some((s) => s.value === m[filterObj?.columnName])) {
        temp.push({
          label: m[filterObj?.columnName],
          value: m[filterObj?.columnName],
        });
      }
    });
    return temp;
  }, []);

  const applyFilter = (filterObj, filterList) => {
    if (!!filterObj.columnName && filterObj.columnValues.length > 0) {
      let temp = filterList.filter(
        (f) => filterObj.columnValues.some((s) => s.value === f[filterObj.columnName])
      );
      return temp;
    }
    else if (!!filterObj.columnName && !!filterObj.operation) {
      let temp = filterObj.operation.filterFunction(
        filterList,
        filterObj.columnName,
        !!filterObj.operationValue ? filterObj.operationValue.trim() : " "
      );
      return temp;
    } else{
      return filterList
    }
  };

  const filterDataList = (dataList) => {
    let filterList = dataList || [];
    filterArry.forEach((item) => {
      let temp = applyFilter(item, filterList);
      filterList = temp;
    });
    props.applyFilter(filterList);
  };

  const renderFilterColumns = (filterObj, index) => {
    return (
      <>
        <Box className="d-flex flex-column">
          <Box
            style={{ width: "100%" }}
            className="d-flex justify-content-between"
          >
            <Avatar
              variant="rounded"
              style={{ margin: 5, borderRadius: 4, width: 20, height: 20 }}
              className="g-btn-secondary"
            >
              {getVariantText(filterObj.order, "caption")}
            </Avatar>
            <CustomIconButton
              id={`remove-filter-options`}
              icon={<CloseIcon style={{ fontSize: "25", color: "#f50057" }} />}
              edge="end"
              style={{ margin: 5, borderRadius: 4, width: 30, height: 30 }}
              handleClick={(e) => {
                setFilterArry((prev) =>
                  prev.filter((f) => f.id !== filterObj.id)
                );
              }}
              tooltip={{
                title: getIntlTranslation("tooltip.delete"),
              }}
              label={""}
              aria-label="all filter"
            />
          </Box>
          <Box className={"w-100 m-1"}>
            <Autocomplete
              value={filterObj || " "}
              onChange={(event, newValue) => {
                setFilterArry((prev) =>
                  prev.map((f) => {
                    if (f.id === filterObj.id) {
                      f = { ...f, ...newValue };
                    }
                    return f;
                  })
                );
              }}
              id="column_name"
              options={props.columnList}
              getOptionLabel={(option) => option.displayName || " "}
              isOptionEqualToValue={(option, value) => {
                return option.columnName === value.columnName;
              }}
              style={{ width: "100%" }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={getIntlTranslation("column")}
                  variant="standard"
                />
              )}
            />
          </Box>
          {filterObj.columnName && (
            <>
              <Box className={"w-10 m-1"}>
                <Autocomplete
                  multiple
                  limitTags={3}
                  value={filterObj.columnValues}
                  onChange={(event, newValue) => {
                    setFilterArry((prev) =>
                      prev.map((f) => {
                        if (f.id === filterObj.id) {
                          f = { ...f, columnValues: [...newValue] };
                        }
                        return f;
                      })
                    );
                  }}
                  id="column-values-default"
                  options={getColumnOptions(filterObj)}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <Typography variant="caption">{option.label}</Typography>
                    </li>
                  )}
                  getOptionDisabled={(option) => option.disabled}
                  style={{ width: "100%" }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Column Values"
                      size="small"
                      variant="standard"
                    />
                  )}
                />
              </Box>
              <Box className={"d-flex justify-content-center w-100 m-1"}>
                {getTranslationVariantText("_or", "subtitle2", {
                  fontWeight: "bold",
                  color: "#d08f8e",
                })}
              </Box>

              <Box className={"d-flex justify-content-start w-100 m-1"}>
                {getTranslationVariantText("_custom-operation", "caption", {
                  fontWeight: "bold",
                  color: "#254a9a",
                })}
              </Box>
              <Box className={"w-100 m-1"}>
                <Autocomplete
                  value={filterObj.operation || " "}
                  onChange={(event, newValue) => {
                    setFilterArry((prev) =>
                      prev.map((f) => {
                        if (f.id === filterObj.id) {
                          f = { ...f, operation: { ...newValue } };
                        }
                        return f;
                      })
                    );
                  }}
                  id="operation_type"
                  options={getOperatorOptions(filterObj)}
                  getOptionLabel={(option) => option.label || " "}
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                  }}
                  getOptionDisabled={(option) => option.disabled}
                  style={{ width: "100%" }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={getIntlTranslation("operation")}
                      variant="standard"
                    />
                  )}
                />
                </Box>
                <Box className={"w-100 m-1"}>
                <TextField
                  key={filterObj.id}
                  value={filterObj.operationValue}
                  {...getInputProps(filterObj)}
                  onChange={(e) => {
                    setFilterArry((prev) =>
                      prev.map((f) => {
                        if (f.id === filterObj.id) {
                          f = { ...f, operationValue: e.target.value };
                        }
                        return f;
                      })
                    );
                  }}
                  onBlur={(e) => {
                    setFilterArry((prev) =>
                      prev.map((f) => {
                        if (f.id === filterObj.id) {
                          f = { ...f, operationValue: e.target.value };
                        }
                        return f;
                      })
                    );
                  }}
                  InputLabelProps={{ shrink: true }}
                  label={getIntlTranslation("Value")}
                  variant="standard"
                  className={"w-100"}
                  size="small"
                />
              </Box>
            </>
          )}
        </Box>
      </>
    );
  };

  return (
    <React.Fragment>
      <Zoom
        in={Boolean(props.menuAnchor?.anchor)}
        style={{
          transitionDelay: Boolean(props.menuAnchor?.anchor)
            ? "100ms"
            : "100ms",
        }}
      >
        <Box className="w-100 h-100">
          <Card className="w-100 h-100 d-flex flex-column">
            <AppBar
              style={{
                backgroundColor: "#d08f8e",
                position: "sticky",
                width: "98%",
                borderRadius: 4,
                marginLeft: 2,
                height: 40,
              }}
            >
              <Toolbar className={"dialog-toolbar"}>
                {getDialogBoxHeader(`_add-filters`)}
                <IconButton
                  edge="start"
                  color="inherit"
                  id="create_project_close"
                  onClick={() => props.onClose()}
                  aria-label="close"
                  style={{ marginLeft: "auto" }}
                >
                  <Tooltip title={getIntlTranslation("tooltip.Click-to-close")}>
                    <CloseIcon />
                  </Tooltip>
                </IconButton>
              </Toolbar>
            </AppBar>
            <CardContent
              className={
                "d-flex flex-column w-100 p-2 custom-scrollBar overflow-auto"
              }
            >
              {filterArry.map((filterObj, index) =>
                renderFilterColumns(filterObj, index)
              )}
              {filterArry.some((s) => !!s.columnName) && (
                <Box
                  className={
                    "d-flex flex-wrap align-items-center justify-content-between m-1"
                  }
                >
                  <CustomIconButton
                    id={`add-filter-options`}
                    icon={<AddIcon style={{ fontSize: "20" }} />}
                    edge="end"
                    style={{
                      margin: 5,
                      borderRadius: 4,
                      width: 25,
                      height: 25,
                    }}
                    handleClick={(e) => {
                      setFilterArry((prev) => {
                        let temp = prev.map((item, i) => ({
                          ...item,
                          order: i + 1,
                        }));
                        temp.push({
                          id: randomIdGenerator("filter"),
                          columnName: "",
                          columnValues: [],
                          displayName: "",
                          type: "",
                          operation: null,
                          operationValue: null,
                          order: prev.length + 1,
                        });
                        return temp;
                      });
                    }}
                    className="g-btn-primary"
                    tooltip={{
                      title: getIntlTranslation("tooltip.add-row"),
                    }}
                    label={""}
                    aria-label="all filter"
                  />
                  <CustomButton
                    id={`apply-filter-options`}
                    buttonText={"_apply-filters"}
                    textVariant="caption"
                    variant="text"
                    style={{ height: 30, width: "50%" }}
                    className="g-btn-primary"
                    tooltip={{
                      title: getIntlTranslation("tooltip.cancel"),
                    }}
                    handleClick={(e) => filterDataList(props.dataList)}
                  />
                </Box>
              )}
            </CardContent>
            <CardActions
              style={{ marginTop: "auto" }}
              className={"flex-row justify-content-center"}
            >
              {/* <CustomButton
                id={`apply-filter-options`}
                buttonText={"_apply-filters"}
                textVariant="caption"
                variant="text"
                style={{ height: 40, width: "50%" }}
                className="g-btn-primary"
                tooltip={{
                  title: getIntlTranslation("tooltip.remove-all"),
                }}
                handleClick={(e) => filterDataList(props.dataList)}
              /> */}
              <CustomButton
                id={`reset-filter-options`}
                buttonText={"_remove-all"}
                textVariant="caption"
                variant="text"
                style={{ height: 30, width: "90%" }}
                className="g-btn-secondary"
                tooltip={{
                  title: getIntlTranslation("tooltip.remove-all"),
                }}
                handleClick={(e) => {
                  setFilterArry([
                    {
                      id: randomIdGenerator("filter"),
                      columnName: "",
                      columnValues: [],
                      displayName: "",
                      type: "",
                      operation: null,
                      operationValue: null,
                      order: 1,
                    },
                  ]);
                  props.resetFilters();
                }}
              />
            </CardActions>
          </Card>
        </Box>
      </Zoom>
    </React.Fragment>
  );
};

FilteringMenu.propTypes = {
  menuAnchor: PropTypes.object,
  onClick: PropTypes.func,
  sortAsc: PropTypes.func,
  sortDesc: PropTypes.func,
  onSortSelect: PropTypes.func,
  columnList: PropTypes.array,
  applyFilter: PropTypes.func,
  resetFilters: PropTypes.func,
  dataList: PropTypes.array,
};

export default FilteringMenu;
