import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import { Box,Card } from "@mui/material";
import { DocumentContextProvider } from "./DocumentBuilderContext";
import DocumentTreeViewer from "./DocumentTreeViewer";
import DropContainer from "./DropContainer";
import DragContainer from "./DragContainer";
import { useDocumentContextHook } from "./DocumentBuilderContext";
import PreviewContainer from "./PreviewContainer";

const DocumentContainer = props => {
    const scope = useSelector((state) => state.container.scope);
    const documentBuilder = useSelector((state) => state.documentBuilder);
    
  return (
    <DocumentContextProvider>
    <Box className="w-100 h-100 d-flex flex-row">
      <Card className="m-1 h-100 d-flex flex-column" style={{width:documentBuilder.preview ? "25%" :"35%"}}>
      <DocumentTreeViewer/>
      </Card>
      <Card className="m-1 h-100 d-flex flex-column" style={{width:documentBuilder.preview ? "40%" : "50%"}}>
        <DropContainer/>
      </Card>
      <Card className="m-1 h-100 d-flex flex-column" style={{width:documentBuilder.preview ? "10":"15%"}}>
       <DragContainer/>
       </Card>
       { documentBuilder.preview &&
        <Card className="m-1 d-flex flex-column" style={{width:"30%"}}>
        <PreviewContainer/>
      </Card>
       }
    </Box>
    </DocumentContextProvider>
  )
}

DocumentContainer.propTypes = {}

export default DocumentContainer;