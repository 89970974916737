import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import ClientManagementContainer from "./ClientManagementContainer"
import {getClientList} from "../api/clientManagementApi"

const ClientManagementDataWrapper = props => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState(null);
  const state = useSelector((state) => state.clientManagement);

  useEffect(() => {
   dispatch(getClientList())
  }, [dispatch])

  useEffect(() => {
    if(state.clients.type==="FETCHED" && state.clients.data.length > 0) {
      setClientList(state.clients.data);
    }
  }, [state.clients])
  
  return (
    <div className="h-100"><ClientManagementContainer data={clientList} /></div>
  )
}

ClientManagementDataWrapper.propTypes = {

}

export default ClientManagementDataWrapper;