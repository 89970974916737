import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  status: "responsive",
  minimized :false,
  asideShow: false,
  darkMode: false,
  dirty:false,
  isReadOnly:null,
  scope: {
    serviceLine:"",
    isProjectScope: false,
    projectIdent: null,
    projectLanguageCode:"de",
    currentProjectRole:"",
  },
  language:""
  
};

const containerSlice = createSlice({
  name: "container",
  initialState,
  reducers: {
    set: (state, { payload }) => ({
      ...state,
      status: payload,
    }),
    setMinimized:(state, { payload }) => ({
      ...state,
      minimized: payload,
    }),
    setTheme: (state, { payload }) => ({
      ...state,
      darkMode: payload,
    }),
    setProjectOpen: (state, { payload }) => ({
      ...state,
      scope: payload,
    }),
    setProjectClose: (state) => ({
      ...state,
      scope: {...initialState.scope},
    }),
    setAsideBar: (state, { payload }) => ({
      ...state,
      asideShow: payload,
    }),
    setProjectInfo: (state, { payload }) => ({
      ...state,
      scope:{...state.scope,projectLanguageCode:payload.lang,currentProjectRole:payload.role}
    }),
    
    setReadOnly: (state, { payload }) => ({
      ...state,
     readOnly:payload
    }),
    
    setDirtyState: (state, { payload }) => ({
      ...state,
      scope:{...state.scope,dirty:payload}
    }),
    setSystemLanguage: (state, { payload }) => ({
      ...state,
      language: payload,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  set,
  setTheme,
  setProjectOpen,
  setProjectClose,
  setProjectInfo,
  setReadOnly,
  setAsideBar,
  setMinimized,
  setDirtyState,
  setSystemLanguage
} = containerSlice.actions;

// A selector
export const containerSliceSelector = (state) => state.container;

// The reducer
export default containerSlice.reducer;
