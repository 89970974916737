import React from "react";
import { TypeAddressDropRender, TypeAddressPreviewRender, TypeAddressRenderSettings, TypeAddressTreeRender } from "../guru-suite/document-builder/Items/TypeAddress";
import { TypeContactCardSettings, TypeContactCardTreeRender, TypeContactCardsDropRender, TypeContactCardsPreviewRender } from "../guru-suite/document-builder/Items/TypeContactCard";
import { TypeHeaderTreeRender, TypeHeadersPreviewRender, TypeHeadersRenderSettings } from "../guru-suite/document-builder/Items/TypeHeaders";
import { TypeImagesDropRender, TypeImagesPreviewRender, TypeImagesSettings, TypeImagesTreeRender } from "../guru-suite/document-builder/Items/TypeImages";
import { TypeLabelTextEditorDropRender, TypeLabelTextEditorPreviewRender, TypeLabelTextEditorSettings, TypeLabelTextEditorTreeRender } from "../guru-suite/document-builder/Items/TypeLabelTextEditor";
import { TypeParagraphDropRender, TypeParagraphPreviewRender, TypeParagraphSettings, TypeParagraphTreeRender } from "../guru-suite/document-builder/Items/TypeParagraph";
import { TypeRatingsDropRender, TypeRatingsPreviewRender, TypeRatingsSettings, TypeRatingsTreeRender } from "../guru-suite/document-builder/Items/TypeRatings";
import { TypeTwoColumnDropRender, TypeTwoColumnPreviewRender, TypeTwoColumnSettings, TypeTwoColumnTreeRender } from "../guru-suite/document-builder/Items/TypeTwoColumn";

export const renderNode = (node) => {
    switch (node.nodeType) {
      case "document-root":
        return <TypeHeaderTreeRender item={node} />
      case "header-chapter":
        return <TypeHeaderTreeRender item={node} />
      case "sub-header":
        return (
            <TypeHeaderTreeRender item={node} />
        );
      case "data-ratings":
        return <TypeRatingsTreeRender item={node} />
      case "data-contact":
        return (
            <TypeContactCardTreeRender item={node} />
        );
      case "data-address":
        return (
            <TypeAddressTreeRender item={node} />
        );
      case "data-paragraph":
        return <TypeParagraphTreeRender item={node} />
      case "data-images":
        return <TypeImagesTreeRender item={node} />
      case "data-label-text-edtior":
        return (
            <TypeLabelTextEditorTreeRender item={node} />
        );
        case "data-two-column":
            return (
                <TypeTwoColumnTreeRender item={node} />
            );
      default:
        return <TypeHeaderTreeRender item={node} />;
    }
  };


  export const renderNodeSettings = (node) => {
    switch (node.nodeType) {
        case "document-root":
          return <TypeHeadersRenderSettings item={node} />
        case "header-chapter":
          return <TypeHeadersRenderSettings item={node} />
        case "sub-header":
          return (
              <TypeHeadersRenderSettings item={node} />
          );
        case "data-ratings":
          return <TypeRatingsSettings item={node} />
        case "data-contact":
          return (
              <TypeContactCardSettings item={node} />
          );
        case "data-address":
          return (
              <TypeAddressRenderSettings item={node} />
          );
        case "data-paragraph":
          return <TypeParagraphSettings item={node} />
        case "data-images":
          return <TypeImagesSettings item={node} />
        case "data-label-text-edtior":
          return (
              <TypeLabelTextEditorSettings item={node} />
          );
          case "data-two-column":
              return (
                  <TypeTwoColumnSettings item={node} />
              );
        default:
          return <TypeHeadersRenderSettings item={node} />;
      }
    };



  export const renderNodeDropRender = (node) => {
    switch (node.nodeType) {
        case "document-root":
          return <TypeHeadersRenderSettings item={node} />
        case "header-chapter":
          return <TypeHeadersRenderSettings item={node} />
        case "sub-header":
          return (
              <TypeHeadersRenderSettings item={node} />
          );
        case "data-ratings":
          return <TypeRatingsDropRender item={node} />
        case "data-contact":
          return (
              <TypeContactCardsDropRender item={node} />
          );
        case "data-address":
          return (
              <TypeAddressDropRender item={node} />
          );
        case "data-paragraph":
          return <TypeParagraphDropRender item={node} />
        case "data-images":
          return <TypeImagesDropRender item={node} />
        case "data-label-text-edtior":
          return (
              <TypeLabelTextEditorDropRender item={node} />
          );
          case "data-two-column":
              return (
                  <TypeTwoColumnDropRender item={node} />
              );
        default:
          return <TypeHeadersRenderSettings item={node} />;
      }
    };
  

    export const previewRender = (node) => {
      switch (node.nodeType) {
        case "document-root":
          return <TypeHeadersPreviewRender item={node} />
        case "header-chapter":
          return <TypeHeadersPreviewRender item={node} />
        case "sub-header":
          return (
              <TypeHeadersPreviewRender item={node} />
          );
        case "data-ratings":
          return <TypeRatingsPreviewRender item={node} />
        case "data-contact":
          return (
              <TypeContactCardsPreviewRender item={node} />
          );
        case "data-address":
          return (
              <TypeAddressPreviewRender item={node} />
          );
        case "data-paragraph":
          return <TypeParagraphPreviewRender item={node} />
        case "data-images":
          return <TypeImagesPreviewRender item={node} />
        case "data-label-text-edtior":
          return (
              <TypeLabelTextEditorPreviewRender item={node} />
          );
          case "data-two-column":
              return (
                  <TypeTwoColumnPreviewRender item={node} />
              );
        default:
          return <TypeHeadersPreviewRender item={node} />;
      }
    };