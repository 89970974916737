import React, { useEffect, useMemo, useRef, useState } from "react";
import { useProjectDescriptionContext } from "../context/ProjectDescriptionContext";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
} from "@mui/material";
import {
  getIntlTranslation,
  getTranslationVariantText,
  normalEditorConfig,
} from "../../../../../utils/helper";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import GuruEditor from "ckeditor5-custom-build";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { useSelector } from "react-redux";

const CurrentVersionContainer = ({}) => {
  const { comparisonMode, descriptionText, loading, markDataUpdated, onSave, isCopy, setIsCopy } =
    useProjectDescriptionContext();
  const editorRef = useRef();
  const [currentText, setCurrentText] = useState("");
  const state = useSelector((state) => state.projectDescription);
  const [isDirty, setIsDirty] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const monitoringState = useSelector((state) => state.projectMonitoring);

  useEffect(() => {
    if (!!descriptionText.description) {
      setCurrentText(descriptionText.description);
    } else {
      setCurrentText("");
    }
  }, [descriptionText]);

  useEffect(() => {
    if(isCopy.copy) {
      setCurrentText((prev) =>  `${editorRef.current.getData()} ${isCopy.text}`);
      setIsCopy({
        copy:false,
        text:""
      })
    }
  }, [isCopy, setIsCopy]);


  const handleEditorReady = (editor) => {
    editorRef.current = editor;
    setEditorLoaded(true);
  };

  const handleSave = () => {
    if (!!descriptionText && descriptionText?.description && !!descriptionText.ident) {
      const value = {
        type: "monitoringProjectDescription",
        description: editorRef.current.getData(),
        ident: descriptionText.ident,
        assetDto: descriptionText.assetDto,
        reportVersionDto: descriptionText.reportVersionDto,
      };
      onSave(value, true);
    } else {
      const value = {
        type: "monitoringProjectDescription",
        description: editorRef.current.getData(),
        ident: "",
        reportVersionDto: null,
        assetDto: null,
      };
      onSave(value, false);
    }
  };

  return (
    <>
      <Box
        style={{ width: monitoringState.isCompareMode ? "50%" : "100%", height: "100%" }}
      >
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Card className="w-100 h-100 d-flex flex-column">
          <CardContent
            className="w-100 p-1 overflow-y-auto overflow-x-hidden custom-scrollBar"
            style={{ height: "calc(100% - 50px)" }}
          >
            <Box
              id={`box_project_description`}
              className="g-text-editor custom-scrollBar p-1"
              style={{
                minHeight: "calc(100% - 50px)",
                padding: 5,
                width: "100%",
                height: "calc(100% - 5px)",
              }}
              tabIndex={-1}
            >
              <CKEditor
                id={`editor_cusrrent version`}
                editor={GuruEditor.ClassicEditor}
                ref={editorRef}
                onReady={(editor) => handleEditorReady(editor)}
                config={{ ...normalEditorConfig }}
                data={currentText}
                onChange={(event, editor) =>{
                  if(editorLoaded){
                    setIsDirty(true)
                    if (!!currentText && currentText !== editor.getData()) {
                      markDataUpdated(true);
                    }
                  }
                  setEditorLoaded(true)
                 }
                }
              />
            </Box>
          </CardContent>
          <CardActions
            style={{ height: 50, marginTop: "auto" }}
            className="g-btn-secondary p-1"
          >
            {comparisonMode && (
              <Box
                className={
                  "d-flex align-items-center justify-content-start m-1 h-100"
                }
              >
                <DoubleArrowIcon style={{ color: "#5f9ea0", fontSize: 20 }} />
                {getTranslationVariantText("current version", "body1", {
                  fontWeight: "bold",
                  color: "#254a9a",
                  marginLeft: 5,
                })}
              </Box>
            )}
            <SaveButton
              id="save-project-description"
              buttonText="_save"
              variant="button"
              style={{
                marginLeft: comparisonMode ? "auto" : 0,
                width: comparisonMode ? "25%" : "100%",
              }}
              tooltip={{
                title: getIntlTranslation("tooltip.save"),
              }}
              handleClick={(e) => handleSave()}
            />
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

CurrentVersionContainer.propTypes = {};

export default CurrentVersionContainer;
