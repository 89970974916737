import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconContainer from "./IconContainer";
import MapIcon from "@mui/icons-material/Map";
import SegmentIcon from "@mui/icons-material/Segment";
import ImageIcon from "@mui/icons-material/Image";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import Popover from "@mui/material/Popover";
import FormatIndentIncreaseSharpIcon from "@mui/icons-material/FormatIndentIncreaseSharp";
import textInput from "../../../../../assets/images/textInput.svg";
import imageBoxExample from "../../../../../assets/images/imageBoxExample.svg";
import textInputWithLabel from "../../../../../assets/images/textInputWithLabel.svg";

const icons = [
  {
    icon: SegmentIcon,
    type: ["section", "sub-section", "chapter"],
    option: {
      node: "text",
      key: "",
      order: "",
      ident: "",
    },
    name: "Text Input",
  },
  {
    icon: FormatIndentIncreaseSharpIcon,
    type: ["section", "sub-section"],
    option: {
      node: "property-label",
      key: "",
      order: "",
      ident: "",
    },
    name: "Label & Text Editor",
  },
  // {
  //   icon: MapIcon,
  //   type: ["section", "sub-section"],
  //   option: {
  //     node: "map",
  //     key: "",
  //     order: "",
  //     children: [],
  //     ident:""
  //   },
  //   name: "Maps",
  // },
  {
    icon: ImageIcon,
    type: ["section", "sub-section"],
    option: {
      node: "images",
      key: "",
      order: "",
      children: [],
      ident: "",
    },
    name: "Image Box",
  },
];

const OptionCustomStyle = {
  width: "100%",
  minHeight: `calc(100vh - 140px)`,
  maxHeight: `calc(100vh - 140px)`,
  overflow: "auto",
  backgroundColor: "rgb(244, 245, 247)",
};

const CustomOption = ({ current, type, setIsDragging }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [textWithLabel, setTextWithLabel] = useState(null);
  const [mapsAnchorEl, setMapAnchorEl] = useState(null);
  const [imageAnchorEl, setImageAnchorEl] = useState(null);
  const [dragging, setDragging] = useState(false);
  const filderIcons = icons.filter((el, index) => {
    return el.type.includes(type);
  });
  const renderIcons = () => {
    return filderIcons.map((el, index) => {
      return (
        <IconContainer
          id={index}
          icon={el.icon}
          name={el.name}
          data={el}
          option={el.option}
          setDragging={setDragging}
          current={current}
        />
      );
    });
  };
  useEffect(() => {
    setIsDragging(dragging);
    return () => {
      setIsDragging(dragging);
    };
  }, [dragging]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTextLabelClick = (event) => {
    setTextWithLabel(event.currentTarget);
  };
  const handleMapsClick = (event) => {
    setMapAnchorEl(event.currentTarget);
  };
  const handleImageClick = (event) => {
    setImageAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTextWithLabelClose = () => {
    setTextWithLabel(null);
  };

  const handleMapAnchorEl = () => {
    setMapAnchorEl(null);
  };
  const handleImageAnchorEl = () => {
    setImageAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const openTextLabel = Boolean(textWithLabel);
  const openMaps = Boolean(mapsAnchorEl);
  const openImage = Boolean(imageAnchorEl);

  const idMaps = open ? "simple-popover-maps" : undefined;
  const idImage = open ? "simple-popover-image" : undefined;
  const textWithLabelId = open ? "simple-popover-textWithLabel" : undefined;
  const id = open ? "simple-popover" : undefined;

  return (
    <Box id="custom_container" sx={OptionCustomStyle} className={"element"}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          padding: "10px",
        }}
      >
        <Typography variant="h7" style={{ fontWeight: "bold" }}>
          CREATE A FIELD
        </Typography>
        <Typography>
          Drag a field type to the left area to create a custom field for your
          “chapter or section”.
        </Typography>
      </Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          padding: 10,
          width: "100%",
        }}
      >
        {renderIcons()}
      </Box>
      <Box>
        <Grid style={{ padding: 10 }}>
          <Grid>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ fontWeight: 600 }}
                gutterBottom
                component="div"
              >
                Text input
              </Typography>
              <IconButton onClick={handleClick}>
                <HelpOutlineSharpIcon fontSize="small" />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Typography
                  style={{ padding: 10, maxWidth: 450 }}
                  variant="body2"
                >
                  The Text input box gives the ability to add a text editor
                  field without any label. The user can add a default
                  description text if needed.
                </Typography>
                <img
                  src={textInput}
                  typeof="svg"
                  alt="text input"
                  loading="lazy"
                  style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    display: "block",
                    width: "100%",
                    maxWidth: 450,
                  }}
                />
              </Popover>
            </Box>
            <Box>
              <Typography variant="body2">
                * The Text input box gives the ability to add a text editor
                field without any label. The user can also add a default
                description text if needed.
              </Typography>
            </Box>
          </Grid>
          {/* with label */}
          <Grid>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ fontWeight: 600 }}
                gutterBottom
                component="div"
              >
                Label & Text Editor
              </Typography>
              <IconButton onClick={handleTextLabelClick}>
                <HelpOutlineSharpIcon fontSize="small" />
              </IconButton>
              <Popover
                id={textWithLabelId}
                open={openTextLabel}
                anchorEl={textWithLabel}
                onClose={handleTextWithLabelClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Typography
                  style={{ padding: 10, maxWidth: 450 }}
                  variant="body2"
                >
                  The Label & Text Editor gives the ability to add a text editor
                  field with a label. The user can also add a default
                  description text if needed.
                </Typography>
                <img
                  src={textInputWithLabel}
                  typeof="svg"
                  alt="text input"
                  loading="lazy"
                  style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    display: "block",
                    width: "100%",
                    maxWidth: 450,
                  }}
                />
              </Popover>
            </Box>
            <Box>
              <Typography variant="body2">
                * The Label & Text Editor gives the ability to add a text editor
                field with a label. The user can also add a default description
                text if needed.
              </Typography>
            </Box>
          </Grid>
          <Grid>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ fontWeight: 600 }}
                gutterBottom
                component="div"
              >
                Image Box
              </Typography>
              <IconButton onClick={handleImageClick}>
                <HelpOutlineSharpIcon fontSize="small" />
              </IconButton>
              <Popover
                id={idImage}
                open={openImage}
                anchorEl={imageAnchorEl}
                onClose={handleImageAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Typography
                  style={{ padding: 10, maxWidth: 450 }}
                  variant="body2"
                >
                  The images box provides a drag and drop area to upload images.
                  Each image box allows you to upload a maximum of 2 images.
                </Typography>
                <img
                  src={imageBoxExample}
                  typeof="svg"
                  alt="text input"
                  loading="lazy"
                  style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    display: "block",
                    width: "100%",
                    maxWidth: 450,
                  }}
                />
              </Popover>
            </Box>
            <Box>
              <Typography variant="body2">
                * The images box provides a drag and drop area to upload images.
                Each image box allows you to upload a maximum of 2 images.
              </Typography>
            </Box>
          </Grid>
          {/* google maps */}
          {/* <Grid>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ fontWeight: 600 }}
                gutterBottom
                component="div"
              >
                Google maps
              </Typography>
              <IconButton onClick={handleMapsClick}>
                <HelpOutlineSharpIcon fontSize="small" />
              </IconButton>
              <Popover
                id={idMaps}
                open={openMaps}
                anchorEl={mapsAnchorEl}
                onClose={handleMapAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Typography style={{ padding: 10, maxWidth: 450 }}>
                  Google Maps allows you to find locations and display the
                  location in the Maps and also allows you to take screenshots
                  of the position displayed in the map.
                </Typography>
                <img
                  src={textInputWithLabel}
                  typeof="svg"
                  alt="text input"
                  loading="lazy"
                  style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    display: "block",
                    width: "100%",
                    maxWidth: 450,
                  }}
                />
              </Popover>
            </Box>
            <Box>
              <Typography>
                * with a Google Maps you have the possibility to take 2
                screenshots if you want more screenshots in the same please add
                more. all Google Maps will be added together to give the total
                of screenshot you can take.
              </Typography>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomOption;
