import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import {
  getFailure,
  getCreateSuccess,
  getDeleteFailure,
  getDeleteSuccess,
  getNavLinks,
  getUpdatedSuccess
} from "../feature-bundle/sub-asset-management/sub-asset/subAssetSlice";

import {
  getFetchListFailure,
  getFetchListSuccess,
  getFetchSuccess,
} from "../feature-bundle/sub-asset-management/sub-asset/subAssetListSlice.js";

import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from "../../../api/authApi";

export const create = (subAsset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/subassets/create-subasset`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "subAssetsDto",
          projectIdent: subAsset.projectIdent,
          assetIdent: subAsset.assetIdent,
          name: subAsset.name,
          subAssetType: subAsset.subAssetType,
          constructionYear: subAsset.construction_year,
          renovationYear: subAsset.renovation_year,
          addressDto: subAsset.address,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getCreateSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const update = (subAsset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/subassets/update-subasset`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "subAssetsDto",
          projectIdent: subAsset.projectIdent,
          assetIdent: subAsset.assetIdent,
          name: subAsset.name,
          subAssetType: subAsset.subAssetType,
          subAssetIdent: subAsset.subAssetIdent,
          constructionYear: subAsset.construction_year,
          renovationYear: subAsset.renovation_year,
          addressDto: subAsset.addressDto,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getUpdatedSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getSubAssetList = (asset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/subassets/fetch-subassets`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "assetDto",
          projectIdent: asset.projectIdent,
          assetIdent: asset.assetIdent,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFetchListFailure(response.data));
    } else {
      dispatch(getFetchListSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getSubAsset = (subAsset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/subassets/fetch-subasset`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "subAssetsDto",
          projectIdent: subAsset.projectIdent,
          subAssetIdent: subAsset.subAssetIdent,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getFetchSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getCategoryNavlinks = (subAsset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/subassets/fetch-category-navLinks`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "subAssetsDto",
          projectIdent: subAsset.projectIdent,
          subAssetIdent: subAsset.subAssetIdent,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getNavLinks(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const deleteSubAsset = (subAsset) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/subassets/delete-subasset`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "subAssetsDto",
          projectIdent: subAsset.projectIdent,
          subAssetIdent: subAsset.subAssetIdent,
          name: subAsset.name,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getDeleteFailure(response.data));
    } else {
      dispatch(getDeleteSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
