import jwtDecode from "jwt-decode";
import { store } from "../store";
import { decrypt } from "../utils/helper";
/* eslint-disable camelcase */
const { AUTH_TOKEN } = process.env;
const token = `${AUTH_TOKEN}TOKEN_AUTH`

export const isAuthTokenValid = (access_token) => {
  if (!access_token) {
    return false;
  }
  const decoded = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("access token expired");
    return false;
  }

  return true;
};
export const removeAccessToken = () => {
	localStorage.removeItem(token);
}

export const authHeader = () => {
  const user = getCurrentUser();
  // return authorization header with jwt token
  const authToken = user.data.token;
  if (!!authToken && !!authToken.accessToken) {
    return `${authToken.tokenType || 'Bearer'} ${authToken.accessToken}`;
  }
  return {};
};

export const getCurrentUser = () => {
  const state = store.getState();
  const auth = state.authentication;
  const decrypted = decrypt(auth.user);
  const user = JSON.parse(decrypted);
  return user;
};

export const getUserData = (token) => {
  const decode = jwtDecode(token.accessToken);
  let roles = [];
  let permission = [];

  decode.roles.forEach((element) => {
    roles.push(element.name);
    element.policiesSet.forEach((policy) => {
      permission.push(policy.name);
    });
  });
  const userDetails = {
    uid: decode.id,
    name: decode.name,
    roles: roles,
    email:decode.email,
    permissions: permission,
    language: decode.language,
    avatar: decode.avatar,
    token : token,
    fireBaseAuth: decode.fireBaseAuth,
    env: process.env.AUTH_TOKEN_ENV
  };
  return userDetails;
}