import React, {  useEffect} from "react";
import Box from "@mui/material/Box";
import { useDispatch} from "react-redux";

import ProjectDescriptionWrapper from "./structure/ProjectDescriptionWrapper";
import { resetPromptState } from "../../projectmonitoringModuleSlice";
import { setCompleteReset } from "./projectDescriptionSlice";
const ProjectDescriptionHome = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setCompleteReset());
    dispatch(resetPromptState());
  }, [dispatch]);

  return (
    <Box
      style={{ height: "100%", overflow: "hidden", backgroundColor: "#ebedef" }}
    >
      <ProjectDescriptionWrapper moduleApi="project-description" />
    </Box>
  );
};
export default ProjectDescriptionHome;
