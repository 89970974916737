import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ElementSection from "../element-section/ElementSection";
import DefectSection from "../DefectSection";
import CostSection from "../CostSection";
import CostSuggestion from "../CostSuggestion";
import SourceDataRoomSection from "../SourceDataRoomSection";
import { Box, Divider, Chip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import CustomButton from "../../../../../components/buttons/CustomButton";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  validationSchema,
  createElementDto,
  createMeasureDto,
  createDefectDto,
} from "../defectHelper";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { Formik, Form } from "formik";
import DefectSuggestion from "../DefectSuggestion";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grow from "@mui/material/Grow";
import { saveElementAndDefect, getDefect } from "../../../api/defectApi";
import { useSnackbar } from "notistack";
import { resetSliceState } from "../defectSlice";
import {
  riskTypeOptions,
  defectTypeOptions,
  priorityTypeOptions,
} from "../../../../../common/dropdowns";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  getLabel,
  getIntlTranslation,
  getDialogBoxHeader,
} from "../../../../../utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditDefect(props) {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.tddDefectSection);
  const openAsset = useSelector((state) => state.openAsset);
  const [defectData, setdefectData] = useState(null);
  const formRef = useRef();
  const containerRef = React.useRef(null);
  const tddState = useSelector((state) => state.tddKeyFinding);

  const { enqueueSnackbar } = useSnackbar();
  const [intialFormData, setIntialFormData] = useState({});
  const [isEdit, setIsEdit] = useState({
    isEdit: true,
    parentIdent: "",
  });

  useEffect(() => {
    if (!!props.defectIdent) {
      dispatch(resetSliceState());
      dispatch(
        getDefect({
          projectIdent: scope.projectIdent,
          ident: props.defectIdent,
        })
      );
    }
  }, [dispatch, props.defectIdent, scope.projectIdent]);

  useEffect(() => {
    if (state.getDefect.type === "FETCHED" && !!state.getDefect.data) {
      setdefectData(state.getDefect.data);
      let elementData = state.getDefect.data.elementData;
      const element = elementData.elementName.find(
        (e) => e.language === scope.projectLanguageCode
      );
      setIsEdit({
        isEdit: true,
        parentIdent: elementData.subCategorySecondaryIdent,
      });
      const defectData = elementData.defectDtoList[0];
      const measureData = defectData.measureDtoList[0];
      const data = {
        elementName: {
          ...element,
          parentIdent: elementData.subCategorySecondaryIdent,
        },
        elementIdent: elementData.elementIdent,
        isElementOther: false,
        constructionYear: elementData.constructionYear,
        defectDescription: defectData.defect,
        defectIdent: defectData.defectIdent,
        riskType: riskTypeOptions.find(
          (f) => f.title?.toLowerCase() === defectData.risk?.toLowerCase()
        )?.value,
        defectType: defectTypeOptions.find(
          (f) => f.title?.toLowerCase() === defectData.defectType?.toLowerCase()
        )?.value,
        inRedFlag: defectData.inRedFlag,
        costDefect: defectData.costDefect,
        dataRoomIndex: defectData.dataRoomIndex,
        quelle: defectData.quelle,
        questionAndAnswer: defectData.questionAndAnswer,
        measureDescription: measureData.measureDescription,
        pricePerUnit: measureData.pricePerUnit,
        unit: measureData.unit,
        quantity: measureData.quantity,
        priority: priorityTypeOptions.find(
          (f) => f.title?.toLowerCase() === measureData.priority?.toLowerCase()
        )?.value,
        isMultiple: "multiple" === measureData.priority.toLowerCase(),
        percentageShortTermPriority: measureData.percentageShortTermPriority,
        percentageMidTermPriority: measureData.percentageMidTermPriority,
        percentageLongTermPriority: measureData.percentageLongTermPriority,
        elementAffected: measureData.elementAffected,
        extraCost: measureData.extraCost,
        extraCostComment: measureData.extraCostComment,
        measureIdent: measureData.measureIdent,
        comments: measureData.comments,
        totalCost: measureData.totalCost,
        costSuggestions: measureData.costSuggestionList?.children,
        costParameters: measureData.costSuggestionList?.children?.find(
          (f) => f.code === measureData.code
        ),
      };

      if ("multiple" === measureData.priority.toLowerCase()) {
        data["multipleCost"] = {
          short: Number(measureData.percentageShortTermPriority) / 100,
          medium: Number(measureData.percentageMidTermPriority) / 100,
          long: Number(measureData.percentageLongTermPriority) / 100,
        };
      }
      setIntialFormData(data);
      dispatch(resetSliceState());
    } else if (state.getDefect.type === "NO_DATA") {
    }
  }, [state.getDefect, dispatch, scope.projectLanguageCode]);
  useEffect(() => {
    if (state.saveDefect.type === "SUCCESS") {
      state.saveDefect.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      props.onUpdate(tddState.tab,state.saveDefect);
      dispatch(resetSliceState());
    }
  }, [
    dispatch,
    enqueueSnackbar,
    props,
    state.saveDefect.messages,
    state.saveDefect.type,
    tddState,
  ]);

  const onSave = (values) => {
    let element = {};
    // check element :
    if (values.isElementOther) {
      // new element
      element = { ...createElementDto(values), elementIdent: "" };
    } else if (
      !defectData.elementData.elementName.find(
        (f) => f.value === values.elementName.value
      )
    ) {
      // new element
      element = { ...createElementDto(values), elementIdent: "" };
    } else {
      element = {
        ...createElementDto(values),
        elementIdent: defectData.elementData.elementIdent,
      };
    }

    const measureDto = {
      ...createMeasureDto(values),
      measureIdent: values.measureIdent,
    };

    let defect = {
      ...createDefectDto(values),
      defectIdent: values.defectIdent,
    };
    const assetIdent = !!props.assetIdent ? props.assetIdent : openAsset.ident;
    const saveDefect = {
      projectIdent: scope.projectIdent,
      elementDto: { ...element, assetIdent: openAsset.ident },
      defectDto: {
        ...defect,
        measureDtoList: [measureDto],
        assetIdent: assetIdent,
      },
    };
    dispatch(saveElementAndDefect(saveDefect));
  };

  return (
    <>
      {!!defectData && (
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={props.open}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              formRef.current.resetForm();
              props.onClose();
            }
          }}
          TransitionComponent={Transition}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <Formik
            enableReinitialize={true}
            initialValues={intialFormData}
            validationSchema={validationSchema}
            onSubmit={(values) => onSave(values)}
            innerRef={formRef}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              isSubmitting,
              resetForm,
            }) => (
              <>
                <AppBar
                  className="appBar"
                  style={{ position: "relative", height: 40 }}
                >
                  <Toolbar
                    style={{ minheight: 40 }}
                    className="dialog-toolbar d-flex align-items-center p-1"
                  >
                    <Box variant="h6" style={{ flex: 1, marginLeft: 5 }}>
                      {getDialogBoxHeader("_Defect")}
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.costDefect}
                            style={{
                              color: values.costDefect ? "#254a9a" : "#ffffff",
                            }}
                            onChange={(e) =>
                              setFieldValue("costDefect", e.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="button"
                            style={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              marginLeft: 4,
                            }}
                          >
                            {getLabel("_Cost-Defect")}
                          </Typography>
                        }
                        labelPlacement="end"
                      />
                    </Box>
                    <Divider
                      orientation="vertical"
                      style={{
                        width: 4,
                        margin: "10px 10px",
                        backgroundColor: "#ffffff",
                      }}
                      flexItem
                    />
                    <div style={{ marginLeft: "auto" }}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Toolbar>
                </AppBar>
                <DialogContent
                  dividers
                  className={"custom-scrollBar"}
                  style={{ padding: "0px 5px" }}
                >
                  <Form>
                    <Box className={"d-flex align-items-start flex-wrap"}>
                      <ElementSection
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        subCategory={defectData.subCategory}
                        isEdit={isEdit}
                        resetForm={(e) => resetForm()}
                      />

                      <DefectSection
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </Box>
                    <Box style={{ width: "100%" }}>
                      <DefectSuggestion
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                    <Grow
                      direction="up"
                      container={containerRef.current}
                      in={values.costDefect}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(values.costDefect ? { timeout: 1000 } : {})}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Box style={{ marginTop: 10, marginLeft: 10 }}>
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                          }}
                        >
                          <CostSuggestion
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                          />
                          <CostSection
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                          />
                        </Box>
                      </Box>
                    </Grow>
                    <SourceDataRoomSection
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                    />
                  </Form>
                </DialogContent>
              </>
            )}
          </Formik>
          <DialogActions>
            <div style={{ display: "flex" }}>
              <CustomButton
                id="cancel_defect_edit_cancel_01"
                buttonText="_cancel"
                variant="text"
                className="btn"
                tooltip={{
                  title: getIntlTranslation("tooltip.close-defect-dialog"),
                }}
                style={{ margin: 5 }}
                handleClick={(e) => {
                  formRef.current.resetForm();
                  props.onClose();
                }}
              />
              <SaveButton
                id="save_edit_defect_save_01"
                buttonText="_save"
                variant="button"
                style={{ margin: 5 }}
                tooltip={{
                  title: getIntlTranslation("tooltip.Save-Defect"),
                }}
                handleClick={() => formRef.current.handleSubmit()}
              />
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

EditDefect.propTypes = {
  defectIdent: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  assetIdent: PropTypes.string,
};

export default EditDefect;
