import React, { useEffect, useState } from "react";
import {
  Box,
} from "@mui/material";
import { resetState } from "./buildingProfileTableSlice.js";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useSnackbar } from "notistack";
import RenderProperty from "./RenderProperty.js";
import CustomButton from "../../../../../../components/buttons/CustomButton.js";
import { saveProperties } from "../../../../api/tddBuildingProfileApi.js";
import { resetPromptState, selectedOptionStructure } from "../../../slice/dataSlice.js";
import { getIntlTranslation } from "../../../../../../utils/helper.js";

export default function BuildingProfileTable(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const chapterState = useSelector((state) => state.tddChapters);
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.buildingProfileTable);
  const openAsset = useSelector((state) => state.openAsset);
  const { data, reportType } = props;
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    if (!!data && data.properties.length > 0) {
      setItemList(
        data.properties.toSorted((a, b) => a.nodeOrder - b.nodeOrder)
      );
    }
  }, [data, data.properties, dispatch, openAsset.ident, reportType]);


  useEffect(() => {
    if (state.save.type === "SAVED") {
      state.save.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      props.onSaveSuccess(state.save.data);
      dispatch(resetState());
      dispatch(resetPromptState());
    }
  }, [
    reportType,
    chapterState.currentSelection?.chapterIdent,
    chapterState.currentSelection?.sectionIdent,
    dispatch,
    openAsset.ident,
    scope.projectIdent,
    scope.projectLanguageCode,
    state.save.type,
  ]);

  const handleSave = () => {
    let temp = [];
    itemList.forEach((item) => {
      let updatedNodes = item.nodeValue.filter((f) => f.updated);
      if (updatedNodes.length > 0) {
        temp.push({ ...item, reportType:reportType, nodeValue: updatedNodes });
      }
    });
    dispatch(
      saveProperties({
        projectIdent: scope.projectIdent,
        data: temp,
      })
    );
  };
  const renderTableStructure = () => {
    return (
      <>
        <table className="custom-table custom-scrollBar">
          <tbody>
            <RenderProperty properties={itemList} onListUpdate={(list) => setItemList(list)}/>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="d-flex flex-column h-100 w-100 justify-content-center p-1">
      {!!itemList && itemList.length > 0 ? (
        <>
          <Box
            className="d-flex flex-column w-100 justify-content-center"
            style={{ height: "calc(100% - 30px)" }}
          >
            <div
              id="building-profile-conatiner"
              className="d-flex h-100 w-100 justify-content-center"
            >
              <Box className="d-flex h-100 w-100 justify-content-center">
                {renderTableStructure()}
              </Box>
            </div>
          </Box>
          <div
            style={{
              width: "100%",
              overflowY: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 5,
              marginTop: 5,
              position: "sticky",
              bottom: 0,
              zIndex: 1500,
              backgroundColor: "#ffff",
            }}
          >
            <CustomButton
              id={"save_current_version"}
              buttonText="_save"
              variant="button"
              className="g-btn-primary"
              style={{
                margin: 2,
                height: 40,
                width: "100%",
                padding: 5,
              }}
              tooltip={{
                title: getIntlTranslation("tooltip.save"),
              }}
              handleClick={(e) => {
                handleSave();
              }}
            />
          </div>
        </>
      ) : (
        <>
          <Box className="w-100">
            <LinearProgress />
          </Box>
        </>
      )}
    </div>
  );
}
