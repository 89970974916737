import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box, IconButton } from "@mui/material";
import GuruEditor from "ckeditor5-custom-build";
import React, { useEffect, useRef } from "react";
import {
  commentsEditorConfig,
  getMentionFeedItems,
  customMentionItemRenderer,
  getIntlTranslation,
} from "../../../../../utils/helper";
import { useSelector } from "react-redux";
import SaveIconButton from "../../../../../components/buttons/icons-buttons/SaveIconButton";
import CloseIcon from "@mui/icons-material/Close";
import useCommentSaveHook from "../../../../component-bundle/hooks/useCommentSaveHook";
import useCommentFetchHook from "./useCommentFetchHook";
import CustomButton from "../../../../../components/buttons/CustomButton";
import SaveButton from "../../../../../components/buttons/SaveButton";

const EditCommentsContainer = ({ comment,isReply, onEditClose }) => {
  const { apiParam, reportType, apiIdent } = comment.queryDetails;
  const editorRef = useRef();
  const usersState = useSelector((state) => state.users);
  const [onCommentsSave] = useCommentSaveHook();
  const { isCommentSaved, isCommentSelected, resetState } = useCommentFetchHook(
    apiParam,
    reportType,
    apiIdent
  );

  useEffect(() => {
    if (isCommentSaved.saved) {
      onEditClose();
      resetState();
    }
  }, [isCommentSaved]);

  const onSave = () => {
    const range = editorRef.current.model.createRangeIn(
      editorRef.current.model.document.getRoot()
    );

    const mentions = [];

    //iterate through the whole tree in that range (TreeWalker)
    for (const treeWalker of range.getWalker({ ignoreElementEnd: true })) {
      if (treeWalker.type === "text") {
        //the item property represents TextProxy which is not instance of node
        const node = treeWalker.item.textNode;

        if (node.hasAttribute("mention")) {
          const mention = node.getAttribute("mention");
          if (mention) {
            mentions.push(mention);
          }
        }
      }
    }

    if(isReply){
      let pComment = comment.parent
      const replies = pComment.replies.map((r) => {
        if(r.ident === comment.ident) {
          r = {
            ...r,
            value: editorRef.current.getData(),
            new: false,
            modified: true,
            mentionedInComments: mentions,
          };
      }
      return r;
    });

    const { queryDetails, ...rest } = pComment;

    onCommentsSave({
      commentsApi: queryDetails.apiParam,
      data: {
        ...queryDetails,
        commentValues: [
          {
            ...rest,
            replies:replies,
            new: false,
            modified: false,
            mentionedInComments: mentions,
          },
        ],
      },
      apiIdent: queryDetails.apiIdent,
    });
    }

    if (!!comment.queryDetails && !isReply) {
      const { queryDetails, ...rest } = comment;
      onCommentsSave({
        commentsApi: queryDetails.apiParam,
        data: {
          ...queryDetails,
          commentValues: [
            {
              ...rest,
              value: editorRef.current.getData(),
              new: false,
              modified: true,
              mentionedInComments: mentions,
            },
          ],
        },
        apiIdent: queryDetails.apiIdent,
      });
    }
  };

  return (
    <Box className="d-flex flex-column">
      <Box
        id={`editor_box_${comment.ident}`}
        className="g-text-editor custom-scrollBar p-1 w-100"
        style={{
          minHeight: "200px",
          height: "98%",
          width: "90%",
        }}
      >
        <CKEditor
          id={`editor_${comment.ident}`}
          ref={editorRef}
          editor={GuruEditor.ClassicEditor}
          onReady={(editor) => {
            editorRef.current = editor;
          }}
          data={comment.value}
          config={{
            ...commentsEditorConfig,
            placeholder: "Type your comment here...",
            mention: {
              feeds: [
                {
                  marker: "@",
                  feed: (queryText) =>
                    getMentionFeedItems(queryText, usersState),
                  itemRenderer: customMentionItemRenderer,
                },
              ],
            },
          }}
        />
      </Box>
      <Box
          className="w-100 d-flex align-items-center justify-content-between"
        >
          <>
          <CustomButton
            id={`cancel_edit_${comment.ident}`}
            buttonText={"_close"}
            style={{
              margin: 5,
              height: 20,
              fontSize:12
            }}
            className = "btn"
            tooltip={{
              title: getIntlTranslation("tooltip.Click-to-close"),
            }}
            handleClick={(e) => onEditClose()}
          />
  
            <SaveButton
          id={`save_reply_${comment.ident}`}
          buttonText="_save"
          variant="button"
          style={{ margin: 5 , marginLeft: "auto", height:30}}
          tooltip={{
            title: getIntlTranslation("tooltip.Save"),
          }}
          handleClick={(e) => onSave()}
        />
          </>
        </Box>
    </Box>
  );
};


export default EditCommentsContainer;

