import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import {renewAccessTokenIfGoingExpire} from "../../../api/authApi";
import {
  getHeaderSaveSuccess,
  getDataRowSaveSuccess,
  getDataHeaderSave,
  getGridListSuccess,
  getFailure,
  setSelectionHeaders,
} from "../feature-bundle/statutory-compliance/statutoryComplianceSlice";

export const createCustomHeader = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/inspection/${request.projectIdent}/save-header/`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "inspectionHeadersWrapper",
          headers: request.headers,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getHeaderSaveSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const saveRowData = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/inspection/${request.projectIdent}/${request.subAssetIdent}/save-row/`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "inspectionDataGridRowWrapper",
          rows: request.rows,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataRowSaveSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const saveRowsData = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/inspection/${request.projectIdent}/${request.subAssetIdent}/save-row/`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
          type: "inspectionDataGridRowWrapper",
          rows: request.rows,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataRowSaveSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const fetchRows = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/inspection/${request.projectIdent}/${request.subAssetIdent}/fetch-rows/`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getGridListSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const fetchHeader = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/inspection/${request.projectIdent}/${request.subAssetIdent}/fetch-headers/`,
        headers: {
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getGridListSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const fetchReportHeader =
  (projectIdent, assetIdent) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await trackPromise(
        axios({
          method: "get",
          url: `/inspection/${projectIdent}/${assetIdent}/fetch-asset-inspection-header/`,
          headers: {
            Accept: "application/json",
            Authorization: tokenHeader,
          },
        })
      );
      if (response.data.type === "ERROR") {
        const res = {
          statusCode: 713,
          type: "ERROR",
          errorFields: {},
          messages: [
              "ERROR"
          ],
          guruDto: {
            type: "assetInspectionHeaderDto",
            assetIdent: assetIdent,
            inspectionHeader: [
              {
                type: "client",
                headers: [],
              },
              {
                type: "internal",
                headers: [],
              },
            ],
          },
          guruDtoList: [],
          projectReadable: false
      }
        dispatch(getFailure(res));
      } else {
        if (response.data.messages[0] === "No Mapping found" || response.data.guruDto.inspectionHeader === null) {
         const res = {
            statusCode: 713,
            type: "FETCHED",
            errorFields: {},
            messages: [
                "No Mapping found"
            ],
            guruDto: {
              type: "assetInspectionHeaderDto",
              assetIdent: assetIdent,
              inspectionHeader: [
                {
                  type: "client",
                  headers: [],
                },
                {
                  type: "internal",
                  headers: [],
                },
              ],
            },
            guruDtoList: [],
            projectReadable: false
        }

          dispatch(setSelectionHeaders(res));
        } else {
            dispatch(
              setSelectionHeaders(response.data)
            );
        }
      }
    } catch (error) {
      if (error.response || error.request) {
        let payload = {};
        payload = {
          type: "ERROR",
          messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
      }
    }
  };

export const saveProjectHeader = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "post",
        url: `/inspection/${request.projectIdent}/save-asset-inspection-header/`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: request.headers,
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getDataHeaderSave(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
