import { useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import CurrentVersionContainer from "./CurrentVersionContainer";

import React from "react";
import TempVersionAlert from "./TempVersionAlert";
import HeaderContainer from "./HeaderContainer";
import { PropertiesContainer } from "./PropertiesContainer";
import ComparisionVersionContainer from "./ComparisionVersionContainer";
import { DataAggregatorProvider, useDataAggregatorContextHook } from "./context/DataAggregatorContext";
import { PropertiesDataProvider } from "./context/PropertiesDataContext";
import NoAssets from "../../component-bundle/NoAssets";

const VersionContainerWrapper = ({ moduleApi }) => {
  const monitoringState = useSelector((state) => state.projectMonitoring);
 
  return (
    <>
  <Box className="w-100 h-100 d-flex flex-column align-items-baseline">
        <DataAggregatorProvider moduleApi={moduleApi}>
          {monitoringState.temp.isTemp && <TempVersionAlert />}
         <VersionContainer moduleApi={moduleApi}/>
        </DataAggregatorProvider>
      </Box>
    </>
  );
};

VersionContainerWrapper.propTypes = {
  moduleApi: PropTypes.string.isRequired,
};

export default React.memo(VersionContainerWrapper, (prevProps, nextProps) => {
  return prevProps.moduleApi === nextProps.moduleApi;
});


export const VersionContainer = ({moduleApi}) => {
  const monitoringState = useSelector((state) => state.projectMonitoring);
  const openAsset = useSelector((state) => state.openAsset);
  const state = useSelector((state) => state.currentVersion);
  const {assets} = useDataAggregatorContextHook();
  const getHeight = useCallback(() => {
    if (monitoringState.temp.isTemp && openAsset.ident === monitoringState.temp.assetIdent) {
      return { height: "calc(100% - 100px)" };
    } else {
      return { height: "calc(100% - 50px)" };
    }
  }, [openAsset.ident, monitoringState]);


  return (
    <>
   
    <PropertiesDataProvider moduleApi={moduleApi}>
    <Box className="w-100" style={{ height: 50 }}>
      <HeaderContainer />
    </Box>
    {assets.data.length <=0 ? 
    <NoAssets/> :
    <Box className="w-100 d-flex flex-row" style={getHeight()}>
      <Box className="w-25 h-100 overflow-hidden m-1">
        <PropertiesContainer />
      </Box>
      <Box className="w-75 d-flex flex-row overflow-hidden h-100 m-1">
        <CurrentVersionContainer />
        {monitoringState.isCompareMode && <ComparisionVersionContainer />}
      </Box>
    </Box>}
  </PropertiesDataProvider>
  </>
  )
}
