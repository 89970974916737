import React from "react";
import { List, ListItem, Box, Typography } from "@mui/material";
import { getTranslationVariantText } from "../../../../../utils/helper";
import { useSelectedProperty } from "../context/SelectedPropertyContext";

const RenderSubAssetsCard = ({ node, key}) => {
  const { getRisk, getStatus} = useSelectedProperty();

  return (
    <List className="w-100 p-0">
      <ListItem
        className={
          "d-flex flex-wrap align-items-center justity-content-start w-100 p-1"
        }
        key={`key-${node.subAssetIdent}-status-${key}`}
        style={{ width: "100%" }}
      >
        <Box className="w-25 p-1">
          {getTranslationVariantText("_status", "body1", {
            width: "100%",
            color: "#254a9a",
            fontWeight: "bold",
          })}
        </Box>
        <Box className="w-75 p-1">
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
            }}
          >
            {getStatus(node.status).title || node.status}
          </Typography>
        </Box>
      </ListItem>
      <ListItem
        className={
          "d-flex flex-wrap align-items-center justity-content-start p-1 w-100"
        }
        style={{ width: "100%" }}
        key={`key-${node.subAssetIdent}-start-${key}`}
      >
        <Box className="w-25 p-1">
          {getTranslationVariantText("_start", "body1", {
            width: "100%",
            color: "#254a9a",
            fontWeight: "bold",
          })}
        </Box>
        <Box className="w-75 p-1">
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
            }}
          >
            {node?.startDate}
          </Typography>
        </Box>
      </ListItem>
      <ListItem
        className={
          "d-flex flex-wrap align-items-center justity-content-start p-1"
        }
        style={{ width: "100%" }}
        key={`key-${node.subAssetIdent}-finish-${key}`}
      >
        <Box className="w-25 p-1">
          {getTranslationVariantText("_finish", "body1", {
            width: "100%",
            color: "#254a9a",
            fontWeight: "bold",
          })}
        </Box>
        <Box className="w-75 p-1">
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
            }}
          >
            {node?.finishDate}
          </Typography>
        </Box>
      </ListItem>
      <ListItem
        className={
          "d-flex flex-wrap align-items-center justity-content-start p-1 w-100"
        }
        key={`key-${node.subAssetIdent}-risk-${key}`}
      >
        <Box className="w-25 p-1">
          {getTranslationVariantText("_risk-Type", "body1", {
            width: "100%",
            color: "#254a9a",
            fontWeight: "bold",
          })}
        </Box>
        <Box
          className="w-50 p-1 rounded-3"
          style={{
            backgroundColor: getRisk(node.risk)?.monitoringColor,
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
              color: "#ffff",
              textAlign: "center",
            }}
          >
            {getRisk(node.risk).title || node.risk}
          </Typography>
        </Box>
      </ListItem>
      <ListItem
        className={
          "d-flex flex-wrap align-items-baseline justity-content-start p-1 w-100"
        }
        key={`key-${node.subAssetIdent}-comments-${key}`}
      >
        <Box className="w-25 p-1">
          {getTranslationVariantText("_comments-Ta", "body1", {
            width: "100%",
            color: "#254a9a",
            fontWeight: "bold",
          })}
        </Box>
        <Typography
          style={{
            fontSize: 11,
            textAlign: "left",
          }}
          dangerouslySetInnerHTML={{ __html: node?.comment }}
          className="label-text text-data p-1 my-1 w-75"
        ></Typography>
      </ListItem>
    </List>
  );
};

export default RenderSubAssetsCard;
