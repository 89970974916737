import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import Box from "@mui/system/Box";
import { useDispatch, useSelector } from "react-redux";
import { addNewSubSection } from "../../../api/customChaptersApi";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";
import AddChapterFnc from "./AddChapterFnc"

const isOpen = {
  borderLeft: "4px solid",
  borderColor: "#e3a805",
};
const RenderSubSection = ({
  s,
  activeSubSection,
  handleSUbSectionSelection,
  index,
  s_index,
  current
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const scope = useSelector((state) => state.container.scope);
  const chapter = useSelector((state) => state.tddChapters);
  const dispatch = useDispatch();


  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleSubmit = (values, { resetForm }) => {
    const order = s.children.length + 1;
    const projectIdent = scope.projectIdent;
    const data = {
      type: "custom",
      name: values.german,
      node: "sub-section",
      order: order,
      reportType: chapter.customType, // FULL REPORT or RED FLAG
      parentIdent: s.ident,
      nameTranslation: [
        {
          type: "title",
          title: values.english,
          language: "english", //german,deutsch,english
          value: values.german,
        },
        {
          type: "title",
          title: values.german,
          language: "deutsch", //german,deutsch,english
          value: values.german, // always german one
        },
      ],
    };
    dispatch(addNewSubSection(data, projectIdent));
    resetForm();
    handleClose();
  };
  const allowAddSubSection = s.children.length > 0 ;
  return (
    <Box>
      <List
        component="div"
        disablePadding
        style={{ marginLeft: 4 }}
        >
        {s.children.map((sub, sub_index) => {
          return (
            <>
              <Card
                sx={{
                  width: "100%",
                  marginTop: "2px",
                  minHeight: 40,
                  padding: 1,
                }}
                style={activeSubSection.index === sub.ident ? isOpen : null}
              >
                <ListItemButton
                  selected={activeSubSection.ident === sub.ident}
                  onClick={(e) => handleSUbSectionSelection(sub)}
                >
                  <ListItemIcon>
                    <Box style={{ color: "#e3a805", marginRight: "2px" }}>
                      {activeSubSection.index === sub.ident && (
                        <DoubleArrowSharpIcon />
                      )}
                    </Box>
                    {activeSubSection.index !== sub.ident  && 
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#254a9a",
                          fontWeight:
                            activeSubSection === sub.ident ? "bold" : null,
                        }}
                      >{`${index}.${s_index + 1}.${sub_index + 1}`}</Typography>
                    }
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1">{sub.name}</Typography>
                    }
                  />
                </ListItemButton>
              </Card>
            </>
          );
        })}
      </List>
      {/* {allowAddSubSection &&
        <AddChapterFnc
          type="SubSection"
          handleSubmit={handleSubmit}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          openDialog={openDialog}
        />
      } */}
    </Box>
  );
};

export default RenderSubSection;
