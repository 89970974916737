import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  IconButton,
  ListItemButton,
  Box,
  CardContent,
  Divider,
  CardHeader,
  Card,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../../authentication/authTokenService";
import {
  getDialogBoxHeader,
  getTranslationVariantText,
} from "../../../../utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetchCommentsHook from "../../../component-bundle/firebase/useFetchCommentsHook";
import { serviceLines } from "../../../../utils/defaultValues";
import { useNavigate } from "react-router-dom";
import { setOpenAsset } from "../../../component-bundle/assetSwitcher/OpenAssetSlice";

const UserComments = ({users}) => {
  const scope = useSelector((state) => state.container.scope);
  const system = useSelector((state) => state.system);
  const currentUser = getCurrentUser();
  const [userComments, setUserComments] = useState([]);
  const [commentsData, setCommentsData] = useState({
    comments:[],
    projectIdent:null,
  });
  const { onFetch } = useFetchCommentsHook();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=> {
    if(!!scope.projectIdent){
      const fetchData = async () => {
        try {
          let data = await onFetch(scope.projectIdent)
          if(!!data && data?.comments?.length > 0){
            setCommentsData(data);
          }else{
            setCommentsData({
              comments:[],
              projectIdent:null,
            })
            setLoading(false);
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  },[])

  useEffect(() => {
    if (commentsData.comments.length > 0) {
       const temp = commentsData.comments.map((c) => {
        return ({
          comment: c.comment,
          assetIdent: c.assetIdent,
          path: c.path,
          reportType: c.reportType,
        })
      });
      setUserComments(temp);
      setLoading(false);
    }
  }, [commentsData]);

  const getPath = (reportType) => reportType ==="redflag" ? "red-flag" :"full-report"
  
  const openReportReviewMode = (data) => {
    const found = serviceLines.find(
      (f) => f.name.toLowerCase() === scope.serviceLine.toLowerCase()
    );
    const path = getPath(data.reportType);
    if (!!found) {
      dispatch(setOpenAsset({
        ident: data.assetIdent,
        }));

      navigate(
        {
          pathname: `${found.root}/project/review/${path}`,
        },
        {
          state: { 
            root: found.root,
            api: data.reportType, 
            assetIdent: data.assetIdent,
            selectedComment: data.comment,
            project: data.project,
            path:path,
            fallback: `${found.root}/project/${path}`
          }
        }
      );
    }
  };

  const renderHeader = (comment, isReply) => {
    const user = users?.find(
      (user) => comment.updatedBy === user?.id
    );
    const date = new Date(comment.updatedAt);
    return (
      <CardHeader
        className="w-100 m-1"
        avatar={
          <Avatar
            style={{
              backgroundColor: "#d08f8e",
              width: 30,
              height: 30,
              padding: 5,
              marginRight: 5,
            }}
          >
            <Typography variant="caption">
              {user?.firstName?.charAt(0).toUpperCase()}
              {user?.lastName?.charAt(0).toUpperCase()}
            </Typography>
          </Avatar>
        }
        title={
          <>
            <div className={"d-flex flex-row flex-wrap align-items-center"}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold", fontSize: "12px" }}
                className={"p-1"}
              >
                {user?.display || "Unknown User"}
              </Typography>
              <Typography
                style={{
                  color: "grey",
                }}
                variant="caption"
                className="mx-1"
              >
                {`${date.toLocaleString(system.language, {
                  weekday: "short",
                })},
          ${date.toLocaleDateString(system.language)} , 
          ${date.toLocaleTimeString(system.language)} ${
                  system.language === "de" ? "Uhr" : ""
                }`}
                {comment.modified &&
                  getTranslationVariantText("_edited", "caption", {
                    textTransform: "uppercase",
                    marginLeft: 5,
                  })}
              </Typography>
            </div>
          </>
        }
        style={{ padding: 0, width: "100%", flex: 1 }}
      />
    );
  };

  return (
    <Card
      className="h-100 w-100 m-1 rounded-3 custom-scrollBar g-scroll-5"
      style={{ overflowY: "auto", overflowX: "hidden" }}
    >
      <AppBar className={"appBar g-btn-secondary m-2 rounded-2"} style={{ width: "90%" }}>
        <Toolbar
          style={{ minHeight: 40, padding: "0px 8px" }}
          className="d-flex align-items-center"
        >
          {getTranslationVariantText("_comments-assigned", "body1", {
            textTransform: "uppercase",
            
          })}
        </Toolbar>
      </AppBar>
      {(!loading && userComments.length <= 0) &&  <Typography>
      {getTranslationVariantText("_no-comments-found", "subtitle1",{
        fontSize:"12px",
      }, "m-1 p-1 fw-bold")}
    </Typography>
    }
     
      {loading ? (
        <List>
          {[1, 2, 3, 4, 5].map((index) => (
            <ListItem key={index}>
              <ListItemText>
                <Skeleton variant="text" />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <>
          {userComments.length > 0 ? (
            userComments.map((c, index) => (
              <List key={index} className="m-1 p-0">
                <ListItemButton
                  className="w-100 p-1"
                  onClick={(e) => {
                    openReportReviewMode(c);
                  }}
                >
                  <Card
                    className="w-100 m-0"
                    style={{ borderLeft: "4px solid #244a9a" }}
                  >
                    <Box>
                      {renderHeader({ ...c.comment }, false)}
                      <CardContent className="p-0 m-0">
                        <Typography
                          style={{
                            fontSize: 14,
                            width: "95%",
                            textAlign: "left",
                          }}
                          className=" label-text render-comments p-1"
                          dangerouslySetInnerHTML={{ __html: c.comment.value }}
                        />
                      </CardContent>
                      {c.comment?.replies?.map((r) => {
                        const reply = {
                          ...r,
                          queryDetails: c.comment.queryDetails,
                          parent: c.comment,
                          resolved: c.comment.resolved,
                        };
                        return (
                          <>
                            <Divider
                              orientation="vertical"
                              className="w-100 mb-1"
                              style={{
                                height: 4,
                                backgroundColor: "#c1d0f0b3",
                              }}
                            />
                            <Box className="m-0">
                              {renderHeader({ ...reply }, true)}
                              <CardContent className="p-0">
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    width: "95%",
                                    textAlign: "justify",
                                  }}
                                  className=" label-text render-comments p-1 m-1"
                                  dangerouslySetInnerHTML={{
                                    __html: reply.value,
                                  }}
                                />
                              </CardContent>
                            </Box>
                          </>
                        );
                      })}
                      <Divider
                        orientation="vertical"
                        className="g-divider mb-1"
                      />
                    </Box>
                  </Card>
                </ListItemButton>
              </List>
            ))
          ) : (
            <></>
          )}
        </>
      )}
    </Card>
  );
};

export default UserComments;
