import { Box } from '../../../../common/commonImport';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataSectionHome from '../../common/report-structure/chapter-structure/DataSectionHome';
import { getIntlTranslation } from '../../../../utils/helper';
import { resetPromptState } from '../../common/slice/dataSlice';
import { ReportStructureContextProvider } from '../../common/report-component/ReportStructureContext';
import ReportContainer from '../../common/report-component/ReportContainer';

const FullReportHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetPromptState());
  }, [])

  return (
    <ReportStructureContextProvider report={'fullreport'}>
    <Box className="h-100">
            <ReportContainer/>
        </Box>
         </ReportStructureContextProvider>
      );
};

export default FullReportHome;
