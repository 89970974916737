import axios from './TddCustomAxios';
import { trackPromise } from 'react-promise-tracker';
import {
  getFailure,
  getSuccess,
  setUploadProgress,
  getMultiImageSuccess,
} from '../../../components/imageUploader/imageProcessSlice';
import { renewAccessTokenIfGoingExpire } from '../../../api/authApi';
import { getErrorFailure } from '../../../error/errorHandlingSlice';

export const saveDefectImage = (image) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'post',
        url: `/defect/upload/${image.projectIdent}`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
        data: image.formData,
        onUploadProgress: (progress) => {
          const { loaded, total } = progress;
          const percentageProgress = Math.floor((loaded / total) * 100);
          dispatch(setUploadProgress(image.id, percentageProgress));
        },
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const updateDefectImage = (image) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'post',
        url: `/defect/update-image/${image.projectIdent}`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
        data: image.formData,
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(getFailure(response.data));
    } else {
      if (response.data.type === 'SUCCESS') {
        response.data.type = 'UPDATED';
      }
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};


export const getImageFilesForDefect = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'get',
        url: `/defect/list-images/${request.projectIdent}/${request.defectIdent}`,
        headers: {
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const getImageFiles = (request) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'get',
        url: `/defect/fetch-defect-images/${request.projectIdent}/${request.ident}`,
        headers: {
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const removeDefectImage = (image) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: 'post',
        url: `/defect/delete-image/${image.projectIdent}`,
        headers: {
          Accept: 'application/json',
          Authorization: tokenHeader,
        },
        data: image.formData,
      })
    );
    if (response.data.type === 'ERROR') {
      dispatch(getFailure(response.data));
    } else {
      if (response.data.type === 'SUCCESS') {
        response.data.type = 'DELETED';
      }
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: 'ERROR',
        messages:
          'OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com',
        error: error?.response?.data,
        responseError: 'true',
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
