import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";
export const initialState = {
  hasErrors: false,
  error: { ...response },
  fetch: { ...response },
  fetchCompare: { ...response },
  save: { ...response },
  custom: { ...response },
  customDelete: { ...response },
  currentData: [],
  updatedRows: [],
  isCompareMode: false,
  currentVersion:{},
  versions:[],
  additionalProperties: { ...response },
  propertyAction: {
    action: null,
    status: null,
    data: null,
  },
  selectedComparingVersion:{},
  copyData:[],
};

const projectProgressSlice = createSlice({
  name: "projectProgress",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetch: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getFetchCompareSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetchCompare: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
        response:payload.response,
      },
    }),
    getCustomSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      custom: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getCustomUpdate: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      custom: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    getCustomDelete: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      customDelete: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
    setCurrentData: (state, { payload }) => {
      return({
      ...state,
      currentData: payload,
    })
  },
    resetState: (state) => ({
      ...initialState,
      currentVersion:state.currentVersion,
      isCompareMode: state.isCompareMode,
    }),
    setUpdatedRows: (state, { payload }) => ({
      ...state,
      updatedRows: payload,
    }),
    setAdditionalProperties: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      additionalProperties: {
        type: payload.type,
        messages: payload.messages,
        data: payload.data,
      },
    }),
    onSelectReportVersion: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      selectedComparingVersion:payload
    }),
    onCopyData: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      copyData:payload
    }),
  setVersions: (state, { payload }) => ({...state, hasErrors: false, versions:payload}),
  setCompareMode: (state, { payload }) => ({ ...state, hasErrors: false, isCompareMode:payload}),
  setCurrentVersion: (state, { payload }) =>({ ...state, hasErrors: false, currentVersion:payload}),
  setCompleteReset: (state, { payload }) => ({  ...initialState})
  },
});

export const {
  resetState,
  getFailure,
  getFetchSuccess,
  getCustomSuccess,getCustomUpdate,
  getSaveSuccess,
  getFetchCompareSuccess,
  getCustomDelete,
  setCurrentData,
  setUpdatedRows,
  setpropertyAction,
  setAdditionalProperties,
  resetPropertyAction,
  onCopyData,
  setCompareMode,
  setCurrentVersion,
  onSelectReportVersion,
  setVersions,
  setCompleteReset,
} = projectProgressSlice.actions;

// A selector
export const projectProgressSelector = (state) => state.projectProgress;

// The reducer
export default projectProgressSlice.reducer;
