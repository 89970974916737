import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IntlMessages from '../../utils/IntlMessages';

const CustomButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const state = useSelector((state) => state.readOnly);

  useEffect(() => {
    if (state.isReadOnly) {
      setReadOnly(state.isReadOnly);
    }
  }, [state]);
  const checkPermission = () => {
    if (!!props.permission) {
      return (
        <>
          {props.permission.create && props.permission.update ? (
            <Tooltip title={props.tooltip.title}>
              <Button
                onClick={(e) => props.handleClick(e)}
                variant={props.variant}
                style={{ ...props.style }}
                
                id={props.id}
                startIcon={props.startIcon && props.startIcon}
                endIcon={props.endIcon && props.endIcon}
                data-index={props.dataIndex}
                size={props.size}
                disabled={props.disabled}
                color={props.buttonColor}
                className={props.className}
                ref={props.buttonEl}
              >
                <Typography
                  variant={props.textVariant}
                  display="block"
                  style={{
                    color: props.textColor,
                    fontWeight: props.fontWeight,
                  }}
                >
                  <IntlMessages id={`${props.buttonText}`} />
                </Typography>
              </Button>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <Tooltip title={props.tooltip.title}>
          <Button
            onClick={(e) => props.handleClick(e)}
            variant={props.variant}
            style={{ ...props.style }}
            autoFocus={props.autoFocus}
            id={props.id}
            startIcon={props.startIcon && props.startIcon}
            endIcon={props.endIcon && props.endIcon}
            data-index={props.dataIndex}
            size={props.size}
            disabled={props.disabled}
            className={props.className}
          >
            <Typography
              variant={props.textVariant}
              style={{ color: props.textColor, fontWeight: props.fontWeight }}
            >
              <IntlMessages id={`${props.buttonText}`} />
            </Typography>
          </Button>
        </Tooltip>
      );
    }
  };

  return <>{!readOnly && checkPermission()}</>;
};

CustomButton.defaultProps = {
  buttonText: '',
  variant: 'text',
  buttonColor: '#244a9a',
  autoFocus: false,
  style: { backgroundColor: '#254a9a' },
  className: 'g-btn-primary',
  textVariant: 'button',
};
CustomButton.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  permission: PropTypes.object,
  style: PropTypes.object,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  dataIndex: PropTypes.string,
  size: PropTypes.string,
  fontWeight: PropTypes.string,
  textColor: PropTypes.string,
  buttonColor: PropTypes.string,
  textVariant: PropTypes.string,
};

export default CustomButton;
