import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { resetState } from './projectListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectList } from '../../../api/projectListApi';
import ProjectListContainer from './structure/ProjectListContainer';
import { resetState as resetReadOnly } from '../slice/readOnlySlice';
import { resetOpenAssetState } from '../assetSwitcher/OpenAssetSlice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setProjectOpen } from '../../../layout/app-container/containerSlice';
import { setProjectClose } from '../../../layout/app-container/containerSlice';
import React from 'react';

const ProjectListHome = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectlistState = useSelector((state) => state.projectList);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    dispatch(setProjectClose());
    dispatch(resetOpenAssetState());
    dispatch(getProjectList());
    dispatch(resetReadOnly());
  }, [dispatch]);

  useEffect(() => {
    if (
      projectlistState.type === 'SUCCESS' &&
      projectlistState.data.length > 0
    ) {
      let pList = projectlistState.data.map((m) => {
        return {
          projectName: m.projectName,
          projectNumber: m.projectNumber,
          projectRoles: m.projectRoles,
          projectDescription: m.projectDescription,
          projectIdent: m.projectIdent,
          isFavourite: m.favoriteProject,
          status: m.status,
          serviceLine: m.serviceLine,
          projectLanguageCode: m.languageCode,
        };
      });
      setProjectList(pList);
      dispatch(resetState());
    }
  }, [dispatch, projectlistState.data, projectlistState.type]);

  const openProject = (data) => {
    navigate(`${props.serviceLine.root}/project/project-dashboard`);
    dispatch(
      setProjectOpen({
        serviceLine: props.serviceLine.name.toLowerCase(),
        api: props.serviceLine.api,
        isProjectScope: true,
        projectIdent: data.row.projectIdent,
        projectName: data.row.projectName,
        isReadOnly: data.row.isReadOnly,
        currentProjectRole: 'Default',
        projectLanguageCode: !!data.row.languageCode
          ? data.row.languageCode
          : 'de',
      })
    );
  };

  return (
    <div className="h-100">
      <ProjectListContainer
        list={projectList}
        serviceLine={props.serviceLine}
        onProjectOpen={(data) => openProject(data)}
        onProjectCreate={() => {
          dispatch(getProjectList());
        }}
      />
    </div>
  );
};

ProjectListHome.propTypes = {
  serviceLine: PropTypes.string,
  onProjectCreate: PropTypes.func,
};

export default ProjectListHome;
