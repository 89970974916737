import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { red, green, grey } from "@mui/material/colors";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

export function parentMenu(
  addNodeChild,
  path) {
  return (
    <>
        <Tooltip title="Add Child" placement="right">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addNodeChild(path);
            }}
          >
            <ControlPointIcon sx={{ color: green[700] }} />
          </IconButton>
        </Tooltip>
      {/* <Tooltip title="Add Sibling " placement="right">
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addNodeSibling(path);
          }}
        >
          <SubdirectoryArrowRightIcon sx={{ color: grey[700] }} />
        </IconButton>
      </Tooltip> */}
      {/* <Tooltip title="Delete" placement="right">
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            removeNode();
          }}
        >
          <DeleteSweepIcon sx={{ color: red[700] }} />
        </IconButton>
      </Tooltip> */}
    </>
  );
}
