import React, { useCallback } from "react";
import PropTypes from "prop-types";
import TempVersionAlert from "../../../version-comparison/TempVersionAlert";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import HeaderContainer from "./HeaderContainer";
import { ProjectProgressProvider } from "../context/ProjectProgressContext";
import { ProgressPropertiesContainer } from "./ProgressPropertiesContainer";
import { PropertiesDataProvider } from "../context/PropertiesDataContext";
import ProgressCurrentVersionContainer from "./ProgressCurrentVersionContainer";
import ProgressComparisionContainer from "./ProgressComparisionContainer";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import NoAssets from "../../../../component-bundle/NoAssets";

const ProgressWrapper = ({ moduleApi }) => {
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const openAsset = useSelector((state) => state.openAsset);
  const state = useSelector((state) => state.projectProgress);
  const { assetList } = useAssetListHook();

  const getHeight = useCallback(() => {
    if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
      return { height: "calc(100% - 100px)" };
    } else {
      return { height: "calc(100% - 50px)" };
    }
  }, [openAsset.ident, tempVersion.assetIdent, tempVersion.isTemp]);
  return (
    <>
      <Box className="w-100 h-100 d-flex flex-column align-items-baseline">
        <ProjectProgressProvider moduleApi={moduleApi}>
          {tempVersion.isTemp && <TempVersionAlert />}
          <PropertiesDataProvider moduleApi={moduleApi}>
            <Box className="w-100" style={{ height: 50 }}>
              <HeaderContainer />
            </Box>
            {assetList.assets.length <= 0 ? (
              <NoAssets />
            ) : (
              <Box className="w-100 d-flex flex-row" style={getHeight()}>
                <Box className="w-25 h-100 overflow-hidden m-1">
                  <ProgressPropertiesContainer />
                </Box>
                <Box className="w-75 d-flex flex-row overflow-hidden h-100 m-1">
                  <ProgressCurrentVersionContainer />
                  {state.isCompareMode && <ProgressComparisionContainer />}
                </Box>
              </Box>
            )}
          </PropertiesDataProvider>
        </ProjectProgressProvider>
      </Box>
    </>
  );
};

ProgressWrapper.propTypes = {
  moduleApi: PropTypes.string.isRequired,
};

export default React.memo(ProgressWrapper, (prevProps, nextProps) => {
  return prevProps.moduleApi === nextProps.moduleApi;
});
