import React from "react";
import { Field } from "formik";
import { Grid, TextField, Typography} from "@mui/material";
import {  NumberFormatBase } from 'react-number-format';
import { getLabel,getOverlineText } from "../../../../../../utils/helper";

const AssetNameAndBuildingCount = (formikProps) => {
  const { values, errors, touched, readOnly} = formikProps;
  return (
    <>
      <Grid
        container
        className="custom-grid-container"
        justify="start"
      >
        <Grid item md={12} xs={12}>
          <Typography>
            {getOverlineText("_Asset Information")}
          </Typography>
        </Grid>
        <Grid item md={5} xs={12}>
          <Field
            name="assetName"
            label={getLabel("_Name")}
            as={TextField}
            size="small"
            dense="true"
            className="grid-fields"
            error={touched.assetName && errors.assetName}
            helperText={touched.assetName && errors.assetName}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <NumberFormatBase
            customInput={TextField}
            value={values.regionalFactor}
            name="numberOfBuildings"
            size="small"
            className="grid-fields"
            label={getLabel("_Building Count")}
            onChange={(e) => (values.numberOfBuildings = Number(e.target.value))}
            error={touched.numberOfBuildings && errors.numberOfBuildings}
            helperText={touched.numberOfBuildings && errors.numberOfBuildings}
          />
        </Grid>
         <Grid item md={4} xs={12}></Grid>
      </Grid>
    </>
  );
};

export default AssetNameAndBuildingCount;
