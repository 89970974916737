import { createSlice } from "@reduxjs/toolkit";
import {response} from "../../utils/defaultValues"

export const initialState = {

  hasErrors: false,
  error:{
   ...response
 },
 users:{
  ...response
 },
 createUsers:{
  ...response
 },
 roles:{
  ...response
 },
 createUser:{
  ...response
 },
 editUser:{
  ...response
 },
 offices:{
  ...response
 },
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }
    }),
    reloadState: (state) => ({
      ...state,
      loading: false,
      hasErrors: false,
      reload: true,
    }),
    getRoles: (state, { payload }) => ({
      ...state,
      roles:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
       }
    }),
    resetCreateUserState: (state) => ({
      ...state,
      hasErrors: false,
      type: "",
      messages: "",
      errorFields:[],
      data: {},
      error: {},
      createUsers:{
        type: "",
        messages: [],
        data: [],
       }
    }),
    getCreateSuccess:(state, { payload }) => ({
      ...state,
      createUsers:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
       }
    }),
    createUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      hasErrors: true,
      type: payload.type,
      errorFields:payload.errorFields,
      messages: payload.messages,
      error: payload,
    }),
    getUserListSuccess: (state, { payload }) => ({
      ...state,
      users:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
       }
    }),
    editUserSuccess: (state, { payload }) => ({
      ...state,
      editUser:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      }
    }),
    editUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload.guruDto,
    }),
    getOffices: (state, {payload})=>({
      ...state,
      offices: payload.guruDtoList
    }),
    resetEditState: (state) => ({
      ...state,
      loading: false,
      hasErrors: true,
      type: "",
      messages: "",
      data: {},
      error: {},
      editUser:{
        type: "",
        messages: [],
        data: [],
      }
    }),
    resetState: (state) => ({
      ...initialState,
      tab: state.tab,
    }),
  },
});

export const { resetState,
  getFailure,
  reloadState,
  getUserListSuccess,
  getProjectData, 
  getCreateSuccess,
  getRoles,
  editUserSuccess,
  editUserFailure,
  resetEditState,
  createUserFailure,
  resetCreateUserState,
  getOffices
 } = userManagementSlice.actions;

// A selector
export const userManagementSelector = (state) => state.userManagement;

// The reducer
export default userManagementSlice.reducer;