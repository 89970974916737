import React, { useState } from "react";
import {
  CardActions,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  Box,
  Collapse,
  Slide,
} from "@mui/material";
import CustomButton from "../../../components/buttons/CustomButton";
import { getIntlTranslation } from "../../../utils/helper";
import { useDocumentContextHook } from "./DocumentBuilderContext";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaveIcon from "@mui/icons-material/Save";
import { scroller } from "react-scroll";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";
import { renderNode, renderNodeSettings } from "../../../utils/customReportHelper";
import { useDispatch } from "react-redux";
import { setPreview } from "../documentBuilderSlice";

const getSelectedItemStyle = (selectedItem, node) => {
  if (selectedItem?.id === node.id) {
    return {
      background: "#d08f8e",
      color: "#ffffff",
      borderRadius: 5,
      marginLeft: 5,
    };
  }
  return {
  };
};


const scrollTo = function (id, containerId) {
  scroller.scrollTo(`${id}`, {
    containerId: containerId,
    horizontal: false,
    isDynamic: true,
    offset: -3,
    smooth: true,
    spy: true,
  });
};

const TreeNode = ({ node, index }) => {
  const { documentItems, editItem, selectedItem, setSelectedItem } = useDocumentContextHook();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div key={node.id}>
      <ListItem
        className="p-1 action-item"
        key={node.id}
        style={getSelectedItemStyle(selectedItem, node)}
      >
        <Box className="d-flex flex-column align-items-center w-100 list-item">
          <Box className="d-flex align-items-center w-100">
            {!node.nodeType.includes("data-") && 
            <IconButton className="p-0" onClick={toggleCollapse}>
              {!node.children.length && (
                <FontAwesomeIcon
                  icon="fa-solid fa-square-xmark"
                  style={{
                    color: selectedItem?.id === node.id ? "#ffffff" : "#1c2025",
                    width: 18,
                    height: 18,
                    opacity: 0.3
                  }}
                />
              )}
              {(collapsed && !!node.children.length) && (
                <FontAwesomeIcon
                  icon="fa-solid fa-square-plus"
                  style={{
                    color: selectedItem?.id === node.id ? "#ffffff" : "#1c2025",
                    width: 18,
                    height: 18,
                    opacity: 0.7
                  }}
                />
              )}
              {(!collapsed && !!node.children.length) && (
                <FontAwesomeIcon
                  icon="fa-solid fa-square-minus"
                  style={{
                    color: selectedItem?.id === node.id ? "#ffffff" : "#1c2025",
                    width: 18,
                    height: 18,
                    opacity: 0.7
                  }}
                />
              )}
            </IconButton>
}
            <ListItemButton
              className="w-100 d-flex align-items-center p-0 justify-content-start"
              onClick={(e) => {
                if (!node.nodeType.includes("data")) {
                  setSelectedItem({
                    ...node,
                    index: index,
                  });
                } else {
                  scrollTo(node.id, `${documentItems.id}-container`);
                }
              }}
              key={node.id}
            >
              {/* {selectedItem?.id === node.id && (
                <DoubleArrowSharpIcon
                  style={{
                    width: 22,
                    height: 22,
                    color: "#00f817",
                  }}
                />
              )} */}
              <div
                style={{
                  display: "flex",
                  width: 20,
                  marginRight: 5,
                }}
                className="g-drag"
              >
                <CustomIconButton
                  id={`drag-${node.id}`}
                  icon={
                    <DragIndicatorIcon
                      style={{
                        fontSize: 18,
                      }}
                    />
                  }
                  tooltip={{
                    title: getIntlTranslation("tooltip.reorder"),
                  }}
                  className={"p-1"}
                  label={""}
                  aria-label="drag handler"
                />
              </div>

              <Box className={"d-flex align-items-center"}>
                <Typography
                  variant="body1"
                  style={{
                    color: selectedItem?.id === node.id ? "#ffffff" : "#254a9a",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  {index}
                </Typography>
              </Box>
              <ListItemText primary={renderNode(node)} />
            </ListItemButton>
          </Box>
          <TreeNodeAction node={node} />
        </Box>
      </ListItem>
      <Box>
        {editItem?.id === node.id && (
           <Slide direction="left"  in={editItem?.id === node.id} mountOnEnter unmountOnExit>
          <Box style={{ marginTop: 10 }}>
            {renderNodeSettings(node)}
          </Box>
          </Slide>
        )}
      </Box>
      {node.children?.length > 0 && (
        <Slide direction="left" in={!collapsed} mountOnEnter unmountOnExit
        timeout={{ enter: 1000,exit:500, easing: 'easeInOut' }}
        >
        <List
          className="p-0"
          style={{ borderLeft: "2px dashed #9d9ea1", marginLeft: 10 }}
        >
          {node?.children.map((child, i) => (
            <TreeNode
              index={`${index}.${i + 1}`}
              node={{ ...child, parentId: node.id }}
            />
          ))}
        </List>
        </Slide>
      )}
    </div>
  );
};

const TreeNodeAction = ({ node }) => {
  const { setEditItem, removeItem, copyItem, createSubHeader } =
    useDocumentContextHook();
  return (
    <Box
      className="d-flex
      g-btn-secondary 
      action"
      style={{
        borderRadius: 4,
        position: "absolute",
        left: "20%",
        top: 40,
        zIndex: 1800,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexGrow: "1",
        }}
        className="g-edit"
      >
        <Tooltip title={getIntlTranslation("tooltip.edit")}>
          <IconButton
            id={`edit_${node.id}`}
            className="g-edit"
            onClick={(event) => {
              event?.stopPropagation();
              setEditItem({ ...node });
            }}
          >
            <ModeEditIcon style={{ fontSize: 15, color: "#ffffff" }} />
          </IconButton>
        </Tooltip>
      </div>
      <Divider
        orientation="vertical"
        style={{
          width: 4,
          backgroundColor: "#ffffff",
        }}
        flexItem
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexGrow: "1",
        }}
        className="g-delete"
      >
        <Tooltip title={getIntlTranslation("tooltip.remove")}>
          <IconButton
            id={`delete_${node.id}`}
            onClick={(event) => {
              event?.stopPropagation();
              removeItem(node);
            }}
          >
            <DeleteForeverIcon style={{ fontSize: 15, color: "#ffffff" }} />
          </IconButton>
        </Tooltip>
      </div>
      <Divider
        orientation="vertical"
        style={{
          width: 4,
          backgroundColor: "#ffffff",
        }}
        flexItem
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexGrow: "1",
        }}
        className="g-copy"
      >
        <Tooltip title={getIntlTranslation("tooltip.copy")}>
          <IconButton
            id={`copy_${node.id}`}
            onClick={(event) => {
              event?.stopPropagation();
              copyItem(node);
            }}
          >
            <ContentCopyIcon style={{ fontSize: 15, color: "#ffffff" }} />
          </IconButton>
        </Tooltip>
      </div>
      {!node.nodeType.includes("data") && (
        <>
          <Divider
            orientation="vertical"
            style={{
              width: 4,
              backgroundColor: "#ffffff",
            }}
            flexItem
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexGrow: "1",
            }}
            className="g-structure"
          >
            <Tooltip title={getIntlTranslation("tooltip.add-structure")}>
              <IconButton
                id={`structure_${node.id}`}
                onClick={(event) => {
                  event?.stopPropagation();
                  createSubHeader(node);
                }}
              >
                <AccountTreeIcon style={{ fontSize: 15, color: "#ffffff" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )}
    </Box>
  );
};

const DocumentTreeViewer = () => {
  const { documentItems, editItem, addHeader, setSelectedItem, setEditItem } =
    useDocumentContextHook();
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
 
    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };

    const saveReportStructure = () => {

    }

  const renderRoot = () => {
    return (
      <>
      <ListItem
        key={documentItems.id}
        className="d-flex flex-column p-0"
        style={{ height: 30 }}
      >
        <Box className="d-flex flex-row align-items-center w-100 list-item">
        <IconButton className="p-0" onClick={toggleCollapse} style={{marginLeft:4}}>
              {!documentItems.children.length && (
                <FontAwesomeIcon
                  icon="fa-solid fa-square-xmark"
                  style={{
                    color: "#1c2025",
                    width: 18,
                    height: 18,
                    opacity: 0.3
                  }}
                />
              )}
              {(collapsed && !!documentItems.children.length) && (
                <FontAwesomeIcon
                  icon="fa-solid fa-square-plus"
                  style={{
                    color: "#1c2025",
                    width: 18,
                    height: 18,
                    opacity: 0.7
                  }}
                />
              )}
              {(!collapsed && !!documentItems.children.length) && (
                <FontAwesomeIcon
                  icon="fa-solid fa-square-minus"
                  style={{
                    color: "#1c2025",
                    width: 18,
                    height: 18,
                    opacity: 0.7
                  }}
                />
              )}
            </IconButton>
          <ListItemButton
            className="d-flex align-items-center ml-1"
            onClick={(e) =>
              setSelectedItem({ ...documentItems, parentNode: documentItems })
            }
            key={documentItems.id}
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    color: "#244a9a",
                  }}
                  variant="body1"
                >
                  {documentItems?.settings.title}
                </Typography>
              }
            />
          </ListItemButton>
          <Box
            className="d-flex g-btn-secondary"
            style={{
              width: 100,
              borderRadius: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
              className="g-edit"
            >
              <Tooltip title={getIntlTranslation("tooltip.edit")}>
                <IconButton
                  id={`edit_${documentItems.id}`}
                  className="g-edit"
                  onClick={(event) => {
                    setEditItem({
                      ...documentItems,
                      parentNode: documentItems,
                    });
                  }}
                >
                  <ModeEditIcon style={{ fontSize: 15, color: "#ffffff" }} />
                </IconButton>
              </Tooltip>
            </div>
            <Divider
              orientation="vertical"
              style={{
                width: 4,
                backgroundColor: "#ffffff",
              }}
              flexItem
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexGrow: "1",
              }}
              className="g-structure"
            >
              <Tooltip title={getIntlTranslation("tooltip.add-structure")}>
                <IconButton
                  id={`structure_${documentItems.id}`}
                  onClick={(event) => {
                    event?.stopPropagation();
                    addHeader();
                  }}
                >
                  <AccountTreeIcon style={{ fontSize: 15, color: "#ffffff" }} />
                </IconButton>
              </Tooltip>
            </div>
            <Divider
              orientation="vertical"
              style={{
                width: 4,
                backgroundColor: "#ffffff",
              }}
              flexItem
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexGrow: "1",
              }}
              className="g-structure"
            >
              <Tooltip title={getIntlTranslation("tooltip.add-structure")}>
                <IconButton
                  id={`structure_${documentItems.id}`}
                  onClick={(event) => {
                    event?.stopPropagation();
                    dispatch(setPreview());
                  }}
                >
                 <FontAwesomeIcon icon="fa-regular fa-eye" style={{ fontSize: 15, color: "#ffffff" }}/>
                </IconButton>
              </Tooltip>
            </div>
          </Box>
        </Box>
      </ListItem>
      <Box>
        {editItem?.id === documentItems.id && (
          <Slide direction="left"  in={editItem?.id === documentItems.id} mountOnEnter unmountOnExit>
          <Box style={{ marginTop: 10 }}>{renderNodeSettings(documentItems)}</Box>
          </Slide>
        )}
      </Box>
      </>
    );
  };

  return (
    <>
      <CardContent
        className="custom-scrollBar p-0"
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          height: "100%",
        }}
      >
        {!!documentItems && (
          <List className="p-1">
            {renderRoot()}
            {documentItems.children?.length > 0  && (
                <Collapse in={!collapsed} unmountOnExit>
              <List
                style={{ borderLeft: "2px dashed #9d9ea1", marginLeft: 10 }}
              >
                {documentItems.children?.map((m, index) => (
                  <TreeNode
                    key={`${m.id}-${index}`}
                    node={m}
                    index={index + 1}
                  />
                ))}
              </List>
              </Collapse>
            )}
          </List>
        )}
      </CardContent>
      <CardActions>
        <CustomButton
          id={"save_report_structure"}
          buttonText={"_save"}
          endIcon={
            <SaveIcon
              style={{
                color: "#ffffff",
                width: 20,
                height: 20,
              }}
            />
          }
          variant="button"
          className={"g-btn-primary"}
          style={{ margin: 5, height: 30, width: "100%" }}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={(e) => saveReportStructure}
        />
      </CardActions>
    </>
  );
};

DocumentTreeViewer.propTypes = {};

export default DocumentTreeViewer;
