import { pmRoot } from "../../utils/defaultValues";
import { globalRoot } from "../../utils/defaultValues";
import ListIcon from "@mui/icons-material/List";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import FlagIcon from "@mui/icons-material/Flag";
import SettingsIcon from "@mui/icons-material/Settings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import GradingIcon from "@mui/icons-material/Grading";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ConstructionIcon from "@mui/icons-material/Construction";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PostAddIcon from "@mui/icons-material/PostAdd";
import KeyIcon from "@mui/icons-material/Key";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import GridViewIcon from "@mui/icons-material/GridView";
import TimelineIcon from "@mui/icons-material/Timeline";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";

export const projectScopeNav = [];

export const nav = [
  {
    id: "pm-project-list",
    icon: (
      <ListIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
      />
    ),
    label: "menu.project-list",
    to: `${pmRoot}/project-list`,
    roles: [],
    renderScope: true,
    defaultAccess: true,
    parentId: "pm-management",
  },
  {
    id: "pm-overview-tag",
    label: "tag.overview",
    tag: true,
    withName: true,
    defaultAccess: true,
    roles: [],
    renderScope: false,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-project-dasboard",
    label: "menu.project-dashboard",
    to: `${pmRoot}/project/project-dashboard`,
    icon: (
      <GridViewIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-assets-overview",
    label: "menu.asset-overview",
    to: `${pmRoot}/project/assets-overview`,
    icon: (
      <LocationCityIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-information-tag",
    label: "",
    withName: false,
    tag: true,
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-project-information",
    label: "menu.project-information",
    to: `${pmRoot}/project/project-information`,
    icon: (
      <BusinessCenterIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-fact-sheet-tag",
    label: "tag.fact-sheet",
    tag: true,
    withName: true,
    defaultAccess: true,
    roles: [],
    renderScope: false,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },

  {
    id: "pm-project-description",
    label: "menu.project-description",
    to: `${pmRoot}/project/project-description`,
    icon: (
      <DesignServicesIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-building-profile",
    label: "menu.building-profile",
    to: `${pmRoot}/project/building-profile`,
    icon: (
      <RoomPreferencesIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-summary-tag",
    label: "tag.summary",
    tag: true,
    withName: true,
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-general-assessment",
    label: "menu.general-assessment",
    to: `${pmRoot}/project/general-assessment`,
    icon: (
      <InsertChartIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-key-findings",
    label: "menu.key-findings",
    to: `${pmRoot}/project/key-findings`,
    icon: (
      <KeyIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-project-progress-tag",
    label: "tag.progress",
    tag: true,
    withName: true,
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-project-progress",
    label: "menu.project-progress",
    to: `${pmRoot}/project/project-progress`,
    icon: (
      <TimelineIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-variations",
    label: "menu.project-variations",
    to: `${pmRoot}/project/project-variations`,
    icon: (
      <LegendToggleIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-documentation",
    label: "menu.project-documentation",
    to: `${pmRoot}/project/project-documentation`,
    icon: (
      <TextSnippetIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-conclusion-tag",
    label: "",
    withName: false,
    tag: true,
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },

  {
    id: "pm-conclusion",
    label: "menu.project-conclusion",
    to: `${pmRoot}/project/project-conclusion`,
    icon: (
      <PendingActionsIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
  {
    id: "pm-photo-documentation",
    label: "menu.photo-documentation",
    to: `${pmRoot}/project/photo-documentation`,
    icon: (
      <LinkedCameraIcon
        style={{ marginLeft: 5, width: 22, height: 22, color: "#018075" }}
      />
    ),
    roles: [],
    renderScope: false,
    defaultAccess: true,
    renderScopeCondition: {
      serviceLine: "project monitoring",
      key: "isProjectScope",
    },
    parentId: "pm-management",
  },
];
