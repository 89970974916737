// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  useSortable,
} from "@dnd-kit/sortable";
import PhotoFrame from "./PhotoFrame";
import PhotoAlbum from "./PhotoAlbum";

function RenderPhotoFrame(props) {
    const { photo, activeIndex } = props;
    const { attributes, listeners, isDragging, index, over, setNodeRef } =
      useSortable({ id: photo.id });
      let tempPhoto = {...photo, index:index+1}
      props = {...props,photo:tempPhoto};
    return (
      <PhotoFrame
        ref={setNodeRef}
        insertPosition={
          activeIndex !== undefined && over?.id === photo.id && !isDragging
            ? index > activeIndex
              ? "after"
              : "before"
            : undefined
        }
        aria-label="images"
        attributes={attributes}
        listeners={listeners}
        {...props}
      />
    );
  }

const NonSortablePhotoAlbum = (props) => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    setPhotos(
      props?.photoSet?.map((photo) => ({
        ...photo,
        id: photo.key || photo.src,
      }))
    );
  }, [props.photoSet]);

  const renderPhoto = (props) => {
    return <RenderPhotoFrame activeIndex={-1} {...props} />;
  };

  return (
        <div style={{ margin: 10, width: "100%" }}>
          <PhotoAlbum
            photos={photos}
            rowConstraints={{ maxPhotos: 2 }}
            spacing={10}
            padding={10}
            renderPhoto={renderPhoto}
          />
        </div>
  );
};

NonSortablePhotoAlbum.propTypes = {};

export default NonSortablePhotoAlbum;
