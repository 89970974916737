import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  hasErrors: false,
  type: '',
  messages: '',
  data: {},
  error: {},
  isMultiUpdate:false,
};
// A slice for auth with our success or failure reducers
const imageProcessSlice = createSlice({
  name: 'imageProcessSlice',
  initialState,
  reducers: {
    setUploadProgress: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      id: payload.id,
      progress: payload.progress,
    }),
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    resetSliceState: (state) => ({
      ...initialState,
      isMultiUpdate:state.isMultiUpdate,
    }),
    getSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: payload.guruDtoList,
      imageData: payload.guruDto,
    }),
    startMultiUpdate: (state) => ({
      ...state,
      hasErrors: false,
      isMultiUpdate:true,
    }),
    stopMultiUpdate: (state) => ({
      ...state,
      hasErrors: false,
      isMultiUpdate:false,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  setUploadProgress,
  getFailure,
  resetSliceState,
  getSuccess,
  startMultiUpdate,
  stopMultiUpdate,
} = imageProcessSlice.actions;

// A selector
export const imageProcessSelector = (state) => state.imageProcess;

// The reducer
export default imageProcessSlice.reducer;
