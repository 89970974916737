import React, { forwardRef ,useImperativeHandle} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import GridOnSharpIcon from '@mui/icons-material/GridOnSharp';

const Table = forwardRef((props, _ref) => {
  const { provided, node, data, remove } = props;

  useImperativeHandle(_ref, () => ({
    getNode: () => {
      return { ...node, data };
    },
  }));
  return (
    <Card
      sx={{ width: "100%", marginTop: 1 }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      raised={true}
    >
      <ListItemButton
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ListItemIcon>
          <GridOnSharpIcon />
        </ListItemIcon>
        <ListItemText primary="Table" />
      </ListItemButton>
    </Card>
  );
});

export default Table;
