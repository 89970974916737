import React from "react";
import { globalRoot } from "../../utils/defaultValues";
import TaskManagementHome from "./TaskManagementHome";
import IntlMessages from "../../utils/IntlMessages";

const TaskManagementRouteConfig = {
  routes: [
    {
      id:"task-management",
      path: `${globalRoot}/task-management/`,
      exact: true,
      name: <IntlMessages id={`menu.task-management`}/>,
      element: <TaskManagementHome />,
    },
  ],
};

export default TaskManagementRouteConfig;
