import React from "react";
import CreateMultipleDefects from "../../defects/create/CreateMultipleDefects"

const DefectDialogSelection = (props) => {
  const { actionScope, onClose } = props;
  
  const resetScopeState = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const renderForm = () => {
    if (actionScope.openCreateMultipleDefectDialog) {
      return (
        <CreateMultipleDefects
          open={actionScope.openCreateMultipleDefectDialog}
          projectInfo={actionScope.node.project}
          data={actionScope.node.data}
          onClose={() => resetScopeState()}
          needGridRefresh={() => props.needGridRefresh()}
        />
      );
    }
  };

  return <React.Fragment key="1">{renderForm()}</React.Fragment>;
};

export default DefectDialogSelection;
