import React from 'react'
import Box from '@mui/material/Box';
import {tddServiceLine,tddRoot} from '../../../../utils/defaultValues'
import BuildingProfileContainer from './structure/BuildingProfileContainer';
import { BuildingProfileContextProvider } from './structure/BuildingProfileContext';

function BuildingProfileHome(props) {
  return (
    <Box
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    style={{ height: '100%', width: '100%', backgroundColor: '#ffffff' , borderRadius:4}}
  >
  <BuildingProfileContextProvider>
      <BuildingProfileContainer/>
    </BuildingProfileContextProvider>
  </Box>
  )
}

export default BuildingProfileHome
