import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditHeaders from "./EditHeaders";
import DeleteDialog from "./DeleteDialog";
import Toolbar from "@mui/material/Toolbar";
import { deleteHeader, updateChapterData, updateSectionData, updateSubSectionData } from "./updateHeadersData";

const CustomStructure = ({
  currentChapterDatas,
  current,
  section,
  subSection,
  report,
}) => {
  const [toggleEdit, setToggleEdit] = useState(false);
  const [toggleSectionEdit, setToggleSectionEdit] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [toggleSectionDelete, setToggleSectionDelete] = useState(false);
  const [getElement, setGetElement] = useState(null);
  const chapter = useSelector((state) => state.tddChapters);
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();

  const handleSubmit = (values, data) => {
    const projectIdent = scope.projectIdent;
    if (data.node === "CHAPTER") {
      updateChapterData(values, data, chapter, dispatch, projectIdent);
    }
    if (data.node === "SECTION") {
      updateSectionData(values, data, chapter, dispatch, projectIdent);
    }
    if (data.node === "sub_section") {
      updateSubSectionData(values, data, chapter, dispatch, projectIdent);
    }
    handleClose();
  };

  const handleDelete = (element) => {
    const projectIdent = scope.projectIdent;
    deleteHeader(element,projectIdent,report,dispatch);
    setToggleDelete(false);
    setToggleSectionDelete(false);
  };
  const handleEdit = () => {
    setToggleEdit(true);
  };
  const handleClickOpen = () => {
    setToggleEdit(true);
  };
  
  const handleOpenDelete = () => {
    setToggleDelete(true);
  };
  
  const handleClose = () => {
    setToggleSectionDelete(false);
    setToggleEdit(false);
    setToggleDelete(false);
    setToggleSectionEdit(false);
  };
  const handleSectionOpen = () => {
    setToggleSectionEdit(true);
  };
  const handleSectionDelete = () => {
    setToggleSectionDelete(true);
  };
  
  const renderStructure = (cObj) => {
    let temp = [];
    if (!!cObj && !!cObj.data) {
      if (cObj.data.node === "CHAPTER") {
        let tempObj = cObj.data;
        temp.push(
          <>
            
              <Toolbar
                variant="dense"
                className="d-flex m-1 align-items-center justify-content-between"
                style={{
                  width:"99%",
                minHeight:30,
                borderRadius:4,
                backgroundColor: "#244a9a"}}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight:"bold",color:"#ffffff", width:"90%"
                  }}
                  >
                  {tempObj.name}
                </Typography>
                {tempObj.customizable && (
                  <Box className="d-flex alogn-items-center">
                    <IconButton onClick={handleEdit}>
                      <EditIcon  style={{color:"#ffffff",width:20, height:20}}/>
                    </IconButton>
                    <IconButton onClick={handleOpenDelete}>
                    <DeleteForeverIcon style={{  color:"#f50057", width:20,height:20}}/>
                    </IconButton>
                  </Box>
                )}
                {toggleEdit && !!tempObj.nameTranslation && (
                  <EditHeaders
                    handleSubmit={handleSubmit}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    openDialog={toggleEdit}
                    data={tempObj}
                    setGetElement={setGetElement}
                  />
                )}
                {toggleDelete && (
                  <DeleteDialog
                    handleClose={handleClose}
                    openDialog={toggleDelete}
                    severity="warning"
                    info={`Are you sure you want to delete this ${tempObj.node}? please confirm by clicking on the button below, this action is irreversible`}
                    data={tempObj}
                    handleDelete={(e) => handleDelete(tempObj)}
                  />
                )}
              </Toolbar>
          </>
        );
        if (cObj.sections.length > 0) {
          temp.push(
            cObj.sections.map((obj) => {
              return renderSectionStructure(obj, null);
            })
          );
        }
      }
    }

    return temp;
  };
  const renderSectionStructure = (obj, parentObj) => {
    let temp = [];
    if (!!obj.data) {
      let tempObj = obj.data;
      temp.push(
        <>
          {!tempObj.name.startsWith("default") && (
              <Toolbar
              variant="dense"
              className="d-flex m-1 align-items-center justify-content-between"
              style={{
                width:"99%",
              minHeight:30,
              borderRadius:4,
              backgroundColor: "#d9d9d9"}}
            >
                <Typography
                variant="body1"
                  style={{ fontWeight:"bold", color:"#244a9a", width:"90%"}}
                >
                  {tempObj.name}
                </Typography>
                {tempObj.customizable && !!tempObj.nameTranslation && (
                   <Box className="d-flex alogn-items-center">
                    <IconButton onClick={handleSectionOpen}>
                    <EditIcon  style={{color:"#ffffff",width:20, height:20}}/>
                    </IconButton>
                    <IconButton onClick={handleSectionDelete}>
                      <DeleteForeverIcon style={{  color:"#f50057", width:20,height:20}}/>
                    </IconButton>
                  </Box>
                )}
                {toggleSectionEdit && (
                  <EditHeaders
                    handleSubmit={handleSubmit}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    openDialog={toggleSectionEdit}
                    data={tempObj}
                    setGetElement={setGetElement}
                  />
                )}
                {toggleSectionDelete && (
                  <DeleteDialog
                    handleClose={handleClose}
                    openDialog={toggleSectionDelete}
                    handleDelete={(e) => handleDelete(tempObj)}
                    severity="warning"
                    info={`Are you sure you want to delete this ${tempObj.name}? please confirm by clicking on the button below, this action is irreversible`}
                    data={tempObj}
                  />
                )}
              </Toolbar>
          )}
          
        </>
        
      );
              
      if (!!obj.subSections) {
        if (obj.subSections.length >0) {
          const filter = obj.subSections.filter(e => e.data.ident === subSection.ident)
          temp.push(
            filter.map((sub)=>{
              return renderSubSectionStructure(sub, null)
            })
          )
        }
        
      } 
    }
    return temp;
  };
  const renderSubSectionStructure = (obj, parentObj) => {
    let temp = [];
    if (!!obj.data) {
      let tempObj = obj.data;
      temp.push(
        <>
          {!tempObj.name.startsWith("default") && (
            <>
              <Toolbar
                variant="dense"
               className = "w-100"
              >
                <Typography
                 variant="body1"
                 style={{fontWeight: "bold", color:"#244a9a"}}
                >
                  {tempObj.name}
                </Typography>
                {tempObj.customizable && !!tempObj.nameTranslation && (
                   <Box className="d-flex alogn-items-center">
                    <IconButton onClick={handleSectionOpen}>
                    <EditIcon  style={{color:"#ffffff",width:20, height:20}}/>
                    </IconButton>
                    <IconButton onClick={handleSectionDelete}>
                    <DeleteForeverIcon style={{  color:"#f50057", width:20,height:20}}/>
                    </IconButton>
                  </Box>
                )}
                {toggleSectionEdit && (
                  <EditHeaders
                    handleSubmit={handleSubmit}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    openDialog={toggleSectionEdit}
                    data={tempObj}
                    setGetElement={setGetElement}
                  />
                )}
                {toggleSectionDelete && (
                  <DeleteDialog
                    handleClose={handleClose}
                    openDialog={toggleSectionDelete}
                    handleDelete={(e) => handleDelete(tempObj)}
                    severity="warning"
                    info={`Are you sure you want to delete this ${tempObj.name}? please confirm by clicking on the button below, this action is irreversible`}
                    data={tempObj}
                  />
                )}
              </Toolbar>
             </>
          )}
          
        </>
        
      );
    }
    return temp;
  };
  return <>{renderStructure(currentChapterDatas)}</>;
};

export default CustomStructure;
