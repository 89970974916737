import axios from "./TddCustomAxios";
import { trackPromise } from "react-promise-tracker";
import { getErrorFailure } from "../../../error/errorHandlingSlice";
import { getFailure, getSuccess, getReportSuccess } from "../../component-bundle/generate-report/reportSlice";

import {renewAccessTokenIfGoingExpire} from "../../../api/authApi";

export const fetchProjectStructure = (projectIdent) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await trackPromise(
      axios({
        method: "get",
        url: `/assets/fetch-project-body/${projectIdent}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
      })
    );
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getSuccess(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));

   }
  }};

export const generateCapex = (data) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await axios({
      method: "post",
      url: `/reports/${data.language}/${data.version}/generate-capex`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: tokenHeader,
      },
      data: {
          type: "capexRequestDto",
          projectIdent: data.projectIdent,
          ident: data.ident,
          parentIdent: data.parentIdent,
          name:data.name,
          parent:data.isParent
      },
      config: {
        timeout: 1000 * 5,
      },
    });
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getReportSuccess(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};

  export const generateCapexSummary = (data) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await axios({
      method: "post",
      url: `/reports/${data.language}/${data.version}/generate-summary-capex`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: tokenHeader,
      },
      data: {
          type: "tddAssetDto",
          projectIdent: data.projectIdent,
          assetIdent: data.ident,
          name:data.name
      },
      config: {
        timeout: 1000 * 5,
      },
    });
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getReportSuccess(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};

   export const generateCompletePortfolioCapex = (data) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await axios({
      method: "post",
      url: `/reports/${data.language}/${data.version}/generate-complete-portfolio-capex`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: tokenHeader,
      },
      data: {
          type: "tddAssetDto",
          projectIdent: data.projectIdent,
          assetIdent: data.ident,
          name:data.name,
      },
      config: {
        timeout: 1000 * 5,
      },
    });
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getReportSuccess(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};


  export const generatePhotoDocumentation = (data) => async (dispatch) => {
  try {
    const tokenHeader = await renewAccessTokenIfGoingExpire();
    const response = await axios({
      method: "post",
      url: `/reports/${data.language}/${data.version}/generate-photo-report`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: tokenHeader,
      },
      data: {
          type: "tddAssetDto",
          projectIdent: data.projectIdent,
          assetIdent: data.ident,
          name:data.name,
      },
      config: {
        timeout: 1000 * 5,
      },
    });
    if (response.data.type === "ERROR") {
      dispatch(getFailure(response.data));
    } else {
      dispatch(getReportSuccess(response.data));
    }
  } catch (error) {
   if (error.response || error.request){
     let payload = {};
    payload = {
      type: "ERROR",
      messages:
        "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
    error: error?.response?.data,
      responseError: "true",
    };
    dispatch(getErrorFailure(payload));
   }
  }};

  export const generateRedFlag = (data) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await axios({
        method: "post",
        url: `/reports/${data.language}/${data.version}/generate-red-flag-report`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
            type: "tddAssetDto",
            projectIdent: data.projectIdent,
            assetIdent: data.ident,
            name:data.name,
            userDtos:data.users
        },
        config: {
          timeout: 1000 * 5,
        },
      });
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getReportSuccess(response.data));
      }
    } catch (error) {
     if (error.response || error.request){
       let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
     }
    }};

  export const generateFullReport = (data) => async (dispatch) => {
    try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await axios({
        method: "post",
        url: `/reports/${data.language}/${data.version}/generate-full-report`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: tokenHeader,
        },
        data: {
            type: "tddAssetDto",
            projectIdent: data.projectIdent,
            assetIdent: data.ident,
            name:data.name,
            userDtos:data.users
        },
        config: {
          timeout: 1000 * 5,
        },
      });
      if (response.data.type === "ERROR") {
        dispatch(getFailure(response.data));
      } else {
        dispatch(getReportSuccess(response.data));
      }
    } catch (error) {
     if (error.response || error.request){
       let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
     }
    }};

    export const generateInspectionReport = (request) => async (dispatch) => {
      try {
        const tokenHeader = await renewAccessTokenIfGoingExpire();
        const response = await axios({
          method: "post",
          url: `/reports/${request.language}/${request.version}/${request.isDefault}/generate-statutory-compliance`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenHeader,
          },
          data: {
              type: "inspectionReportGeneration",
              projectIdent: request.projectIdent,
              ident: request.assetIdent,
              headers:request.headers
          },
          config: {
            timeout: 1000 * 5,
          },
        });
        if (response.data.type === "ERROR") {
          dispatch(getFailure(response.data));
        } else {
          dispatch(getReportSuccess(response.data));
        }
      } catch (error) {
       if (error.response || error.request){
         let payload = {};
        payload = {
          type: "ERROR",
          messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
          error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
       }
      }};
