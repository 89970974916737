import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTranslationVariantText,
  getVariantText,
} from "../../../../utils/helper";
import useAssetListHook from "../../../component-bundle/hooks/useAssetListHook";
import {
  resetState,
  setCurrentVersion,
  setUpdatedRows,
} from "./photoDocumentationSlice";

export const PhotoDocumentationContext = createContext(undefined);

export const usePhotoDocumentationContextHook = () => {
  const context = useContext(PhotoDocumentationContext);

  if (context === undefined) {
    throw new Error("context should be used");
  }
  return context;
};

export const PhotoDocumentationContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const state = useSelector((state) => state.monitoringPhotoDocumentation);
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const { assetList } = useAssetListHook();
  const [selectedSubAsset, setSelectedSubAsset] = useState(null);
  const [imageSubAssetNode, setImageSubAssetNode] = useState(null);

  const [assets, setAssets] = useState({
    isAssigned: false,
    data: [],
  });
  const [subAssetList, setSubAssetList] = useState([]);
  const [blockAssetChange, setBlockAssetChange] = useState({
    block: false,
    newAsset: {},
  });

  useEffect(() => {
    if (!!openAsset.ident) {
      setSubAssetList([]);
      dispatch(resetState());
      dispatch(setCurrentVersion({}));
      setAssets({
        isAssigned: false,
        data: [],
      });
    }
  }, [dispatch, openAsset.ident]);

  useEffect(() => {
    if (!!openAsset.ident && assetList.isFetchSuccess) {
      const list = assetList.assets.map((m) => {
        return {
          ...m,
          address: { ...m.addressDto },
          reportVersion: [
            ...m.reportVersionDtos.map((rv) => {
              return { ...rv, label: rv.versionName, value: rv.ident };
            }),
          ],
        };
      });

      let found = list.find(
        (f) =>
          f.assetIdent === openAsset.ident &&
          scope?.projectIdent === openAsset?.projectIdent
      );
      let currentversion = found.reportVersion.find((f) => f.current);
      if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
        currentversion = found.reportVersion.find(
          (f) => f.ident === tempVersion.reportVersionIdent
        );
      }
      let temp = [
        ...found?.subAssets.map((m) => m).sort((a, b) => b.general - a.general),
      ];
      setSubAssetList(temp);
      if (temp.length > 0 && imageSubAssetNode?.ident !== temp[0].subAssetIdent) {
        setSelectedSubAsset(temp[0]);
        setImageSubAssetNode({
          type: "sub-asset",
          ident: temp[0].subAssetIdent,
          projectIdent: scope.projectIdent,
          caption: "",
          currentversion: currentversion.ident,
        });
      }
      dispatch(setCurrentVersion(currentversion));
      setAssets({
        isAssigned: true,
        data: list,
      });
    }
  }, [
    dispatch,
    openAsset,
    scope.projectIdent,
    tempVersion,
    assetList.isFetchSuccess,
    assetList.assets,
  ]);

  const isDataUpdated = useCallback(() => {
    return (
      Object.keys(state.updatedRows)?.filter(
        (f) => state.updatedRows[f].status === "new"
      ).length > 0
    );
  }, [state.updatedRows]);

  const getWarningText = () => {
    let updatedRows = Object.keys(state.updatedRows)
      .filter((f) => state.updatedRows[f].status === "new")
      .map((m) => ({
        id: state.updatedRows[m].id,
        src: state.updatedRows[m].src,
        fileName: state.updatedRows[m].fileName,
      }));
    return (
      <>
        {updatedRows.length > 0 ? (
          <>
            {getTranslationVariantText("_unsaved changes", "subtitle2")}
            <List>
              {updatedRows.map((m, index) => {
                return (
                  <ListItem key={`key-${m.id}`}>
                    <ListItemAvatar>
                      <Avatar alt={m.id} src={m.src} variant="rounded" />
                    </ListItemAvatar>
                    <ListItemText
                      id={`text-${m.id}`}
                      primary={getVariantText(`${m.fileName}`, "subtitle2", {
                        fontWeight: "bold",
                      })}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : null}
      </>
    );
  };

  const prepareSubAssetData = (subAsset, currentversion = null) => {
    if (!currentversion) {
      currentversion = state.currentVersion;
    }
    setSelectedSubAsset(subAsset);
    setImageSubAssetNode({
      type: "sub-asset",
      ident: subAsset.subAssetIdent,
      projectIdent: scope.projectIdent,
      caption: "",
      currentversion: currentversion.ident,
    });
  };

  const onFileUpdate = (files) => {
    let temp = { ...state.updatedRows };
    files.forEach((file) => {
      temp[file.id] = {
        id: file.id,
        status: file.status,
        src: file.src,
        fileName: file.fileName,
      };
    });
    dispatch(setUpdatedRows(temp));
  };

  return (
    <PhotoDocumentationContext.Provider
      value={{
        subAssetList,
        selectedSubAsset,
        imageSubAssetNode,
        blockAssetChange,
        setBlockAssetChange,
        isDataUpdated,
        prepareSubAssetData,
        onFileUpdate,
        getWarningText,
      }}
    >
      {children}
    </PhotoDocumentationContext.Provider>
  );
};
