import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../utils/defaultValues";

export const initialState = {
  hasErrors: false,
  asset:{...response},
  assetList:{...response}
};
// A slice for auth with our success or failure reducers
const assetSwitcher = createSlice({
  name: "AssetSwitch",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getAssetListSuccess: (state, { payload }) => {
      return ({
        ...state,
        hasErrors: false,
        assetList: {
          type: payload.type,
          messages: payload.messages,
          data: payload.guruDtoList,
        },
      })
    },
    resetAssetSwitcherState: (state) => ({
      ...initialState,
    }),
    getAssetSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      asset: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDto,
      },
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  getAssetSuccess,
  resetAssetSwitcherState,
  getAssetListSuccess,
} = assetSwitcher.actions;

// A selector
export const assetSelector = (state) => state.assetSwitcher;

// The reducer
export default assetSwitcher.reducer;
