import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
// documentItems:
//     {
//         "label": "Document",
//         "key": "document-root",
//         "nodeType": "document-root",
//         "depth": 0,
//         "settings": {
//             "title": "Red Flag",
//             "defaultTitle": "Document",
//             "translation": {
//                 "en": "",
//                 "de": ""
//             }
//         },
//         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//         "children": [
//             {
//                 "label": "Header",
//                 "key": "header-chapter",
//                 "nodeType": "header-chapter",
//                 "depth": 1,
//                 "settings": {
//                     "title": "Executive Summary",
//                     "defaultTitle": "Header",
//                     "translation": {
//                         "en": "",
//                         "de": ""
//                     }
//                 },
//                 "children": [
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "Conditional Status",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [
//                             {
//                                 "label": "Label & Text-Editor",
//                                 "nodeType": "data-label-text-edtior",
//                                 "key": "9_text-editor",
//                                 "order": 9,
//                                 "settings": {
//                                     "title": "Building Construction",
//                                     "defaultTitle": "Label & Text-Editor",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     },
//                                     "userEditable": true
//                                 },
//                                 "content": {
//                                     "defaultText": "<p><span style=\"background-color:rgb(255,255,255);color:rgb(102,102,102);font-family:Verdana, Geneva, sans-serif;\">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.&nbsp;</span></p><ul><li><span style=\"background-color:rgb(255,255,255);color:rgb(102,102,102);font-family:Verdana, Geneva, sans-serif;\">Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.&nbsp;</span></li><li><span style=\"background-color:rgb(255,255,255);color:rgb(102,102,102);font-family:Verdana, Geneva, sans-serif;\">Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.&nbsp;</span></li><li><span style=\"background-color:rgb(255,255,255);color:rgb(102,102,102);font-family:Verdana, Geneva, sans-serif;\">Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,</span></li></ul>"
//                                 },
//                                 "id": "36447575-c1bf-4b01-a25f-95fedc5efd95",
//                                 "parentId": "e2f44021-1cb8-47c9-9ec0-0423f195cd44",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Executive Summary",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Document",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             },
//                             {
//                                 "label": "Label & Text-Editor",
//                                 "nodeType": "data-label-text-edtior",
//                                 "key": "9_text-editor",
//                                 "order": 9,
//                                 "settings": {
//                                     "title": "TECHNICAL BUILDING EQUIPMENT",
//                                     "defaultTitle": "Label & Text-Editor",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     },
//                                     "userEditable": true
//                                 },
//                                 "content": {
//                                     "defaultText": ""
//                                 },
//                                 "id": "463c801a-93e4-4c45-8612-73f8361fc253",
//                                 "parentId": "e2f44021-1cb8-47c9-9ec0-0423f195cd44",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Executive Summary",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Document",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             },
//                             {
//                                 "label": "Label & Text-Editor",
//                                 "nodeType": "data-label-text-edtior",
//                                 "key": "9_text-editor",
//                                 "order": 9,
//                                 "settings": {
//                                     "title": "OUTDOOR FACILITIES",
//                                     "defaultTitle": "Label & Text-Editor",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     },
//                                     "userEditable": true
//                                 },
//                                 "content": {
//                                     "defaultText": ""
//                                 },
//                                 "id": "2e90a563-6b73-48df-92df-6eec1e5ab7d1",
//                                 "parentId": "e2f44021-1cb8-47c9-9ec0-0423f195cd44",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Executive Summary",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Document",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             },
//                             {
//                                 "label": "Label & Text-Editor",
//                                 "nodeType": "data-label-text-edtior",
//                                 "key": "9_text-editor",
//                                 "order": 9,
//                                 "settings": {
//                                     "title": "FIRE PROTECTION",
//                                     "defaultTitle": "Label & Text-Editor",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     },
//                                     "userEditable": true
//                                 },
//                                 "content": {
//                                     "defaultText": ""
//                                 },
//                                 "id": "5a348059-6c07-46c9-a6d8-1bc18be5f773",
//                                 "parentId": "e2f44021-1cb8-47c9-9ec0-0423f195cd44",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Executive Summary",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Document",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             },
//                             {
//                                 "label": "Rating",
//                                 "nodeType": "data-ratings",
//                                 "key": "4_rating",
//                                 "order": 4,
//                                 "settings": {
//                                     "title": "Rating",
//                                     "defaultTitle": "Rating",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     },
//                                     "level": "10"
//                                 },
//                                 "id": "e906554f-88b5-4aaf-a2b8-32daa672945c",
//                                 "parentId": "e2f44021-1cb8-47c9-9ec0-0423f195cd44",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Executive Summary",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Document",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             }
//                         ],
//                         "id": "e2f44021-1cb8-47c9-9ec0-0423f195cd44",
//                         "parentId": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Header",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Document",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     },
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "Significant Risk",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [
//                             {
//                                 "label": "Paragraph",
//                                 "nodeType": "data-paragraph",
//                                 "key": "6_paragraph",
//                                 "order": 6,
//                                 "settings": {
//                                     "title": "Paragraph",
//                                     "defaultTitle": "Paragraph",
//                                     "userEditable": false,
//                                     "sourceRequired": true,
//                                     "translation": {}
//                                 },
//                                 "content": {
//                                     "defaultText": "<p><span style=\"background-color:rgb(255,255,255);color:rgba(0,0,0,0.87);\">In this chapter you will find a summary of the main Capex risks. The shortcomings of various trades with a high risk potential are described in detail in order to draw special attention to them. It concerns defects with a cost estimate. “High” risks are essentially compliance issues that require immediate attention and clarification, as the health and safety of property users could be endangered or possible structural measures could have extensive financial consequences that would significantly reduce the value of the property. Risks with a “low” rating are not listed here and can be found in Appendix A “CAPEX”. For better traceability, we have included the corresponding source, CAPEX and Q&amp;A references wherever possible.</span></p>"
//                                 },
//                                 "id": "d337af4d-a98d-4dea-9a36-2e384e209da3",
//                                 "parentId": "e005efe8-a016-430d-8f55-83a0970d0eb8",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Executive Summary",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Document",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             }
//                         ],
//                         "id": "e005efe8-a016-430d-8f55-83a0970d0eb8",
//                         "parentId": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Header",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Document",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     },
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "Capex Summary",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [],
//                         "id": "7b52960e-5924-44b3-a194-63909d30a75a",
//                         "parentId": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Header",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Document",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     },
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "Significant Risk without Capex",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [],
//                         "id": "7d6c62fb-974e-47fe-bbfa-33158be5d1d7",
//                         "parentId": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Header",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Document",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     }
//                 ],
//                 "id": "e87b1cc7-179c-4883-9538-18f55cfcc0e8",
//                 "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                 "parentNode": {
//                     "label": "Document",
//                     "key": "document-root",
//                     "nodeType": "document-root",
//                     "depth": 0,
//                     "settings": {
//                         "title": "Document",
//                         "defaultTitle": "Document",
//                         "translation": {
//                             "en": "",
//                             "de": ""
//                         }
//                     },
//                     "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                     "children": []
//                 }
//             },
//             {
//                 "label": "Header",
//                 "key": "header-chapter",
//                 "nodeType": "header-chapter",
//                 "depth": 1,
//                 "settings": {
//                     "title": "Project Information",
//                     "defaultTitle": "Header",
//                     "translation": {
//                         "en": "",
//                         "de": ""
//                     }
//                 },
//                 "children": [
//                     {
//                         "label": "Two Column",
//                         "nodeType": "data-two-column",
//                         "key": "10_two-columr",
//                         "order": 10,
//                         "settings": {
//                             "title": "Two Column",
//                             "defaultTitle": "Two Column",
//                             "translation": {
//                                 "en": "",
//                                 "de": "",
//                                 "value": ""
//                             },
//                             "userEditable": true
//                         },
//                         "content": {
//                             "columns": [
//                                 {
//                                     "title": "Client Name",
//                                     "enTranslation": "",
//                                     "deTranslation": "",
//                                     "columnType": "text",
//                                     "ident": "3d32cb1f-4cc5-44e0-8116-03b3dd3858a4",
//                                     "nodeOrder": "",
//                                     "custom": true
//                                 },
//                                 {
//                                     "title": "Client Address",
//                                     "enTranslation": "",
//                                     "deTranslation": "",
//                                     "columnType": "address",
//                                     "ident": "680d59a2-5fb3-4cb5-95c6-6de7e9880777",
//                                     "nodeOrder": "",
//                                     "custom": true
//                                 },
//                                 {
//                                     "title": "Project Name",
//                                     "enTranslation": "",
//                                     "deTranslation": "",
//                                     "columnType": "text",
//                                     "ident": "0bbf704f-cd9b-4dd1-904e-1b053e6ad939",
//                                     "nodeOrder": "",
//                                     "custom": true
//                                 },
//                                 {
//                                     "title": "PARTICIPANTS IN THE SITE VISIT",
//                                     "enTranslation": "",
//                                     "deTranslation": "",
//                                     "columnType": "textarea",
//                                     "ident": "d04b3a6b-769d-4e1b-b40d-9360732bce3d",
//                                     "nodeOrder": "",
//                                     "custom": true
//                                 }
//                             ]
//                         },
//                         "id": "942fb0a9-86db-44af-bda1-d33fdd44f7c9",
//                         "depth": 2,
//                         "parentId": "694cef26-a679-4c40-b88c-b89cd2b3bc11",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Project Information",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "694cef26-a679-4c40-b88c-b89cd2b3bc11",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Red Flag",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     }
//                 ],
//                 "id": "694cef26-a679-4c40-b88c-b89cd2b3bc11",
//                 "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                 "parentNode": {
//                     "label": "Document",
//                     "key": "document-root",
//                     "nodeType": "document-root",
//                     "depth": 0,
//                     "settings": {
//                         "title": "Red Flag",
//                         "defaultTitle": "Document",
//                         "translation": {
//                             "en": "",
//                             "de": ""
//                         }
//                     },
//                     "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                     "children": []
//                 }
//             },
//             {
//                 "label": "Header",
//                 "key": "header-chapter",
//                 "nodeType": "header-chapter",
//                 "depth": 1,
//                 "settings": {
//                     "title": "Fact Sheet",
//                     "defaultTitle": "Header",
//                     "translation": {
//                         "en": "",
//                         "de": ""
//                     }
//                 },
//                 "children": [
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "Building Profile",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [
//                             {
//                                 "label": "Two Column",
//                                 "nodeType": "data-two-column",
//                                 "key": "10_two-columr",
//                                 "order": 10,
//                                 "settings": {
//                                     "title": "Two Column",
//                                     "defaultTitle": "Two Column",
//                                     "translation": {
//                                         "en": "",
//                                         "de": "",
//                                         "value": ""
//                                     },
//                                     "userEditable": true
//                                 },
//                                 "content": {
//                                     "columns": [
//                                         {
//                                             "title": "Building Name",
//                                             "enTranslation": "",
//                                             "deTranslation": "",
//                                             "columnType": "text",
//                                             "ident": "11340812-21b9-49c3-88a7-dfc588532914",
//                                             "nodeOrder": "",
//                                             "custom": true
//                                         },
//                                         {
//                                             "title": "Parking Spaces",
//                                             "enTranslation": "",
//                                             "deTranslation": "",
//                                             "columnType": "parking-number",
//                                             "ident": "1bd06ee8-7339-451b-9025-5955b4d9aca5",
//                                             "nodeOrder": "",
//                                             "custom": true
//                                         }
//                                     ]
//                                 },
//                                 "id": "f3be545c-8ca3-43c3-a7aa-a654e2c8ac0f",
//                                 "parentId": "e371160b-43bf-44cc-992a-cce08ba73ea8",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Fact Sheet",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "7cdc575c-1a05-46cb-9be6-f389374e41b7",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Red Flag",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             }
//                         ],
//                         "id": "e371160b-43bf-44cc-992a-cce08ba73ea8",
//                         "parentId": "7cdc575c-1a05-46cb-9be6-f389374e41b7",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Fact Sheet",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "7cdc575c-1a05-46cb-9be6-f389374e41b7",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Red Flag",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     }
//                 ],
//                 "id": "7cdc575c-1a05-46cb-9be6-f389374e41b7",
//                 "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                 "parentNode": {
//                     "label": "Document",
//                     "key": "document-root",
//                     "nodeType": "document-root",
//                     "depth": 0,
//                     "settings": {
//                         "title": "Red Flag",
//                         "defaultTitle": "Document",
//                         "translation": {
//                             "en": "",
//                             "de": ""
//                         }
//                     },
//                     "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                     "children": []
//                 }
//             },
//             {
//                 "label": "Header",
//                 "key": "header-chapter",
//                 "nodeType": "header-chapter",
//                 "depth": 1,
//                 "settings": {
//                     "title": "Photo Documentation",
//                     "defaultTitle": "Header",
//                     "translation": {
//                         "en": "",
//                         "de": ""
//                     }
//                 },
//                 "children": [
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "General Images",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [
//                             {
//                                 "label": "Images",
//                                 "nodeType": "data-images",
//                                 "key": "5_images",
//                                 "order": 5,
//                                 "settings": {
//                                     "title": "Images",
//                                     "defaultTitle": "Images",
//                                     "translation": {
//                                         "en": "",
//                                         "de": "",
//                                         "value": ""
//                                     },
//                                     "isSourceRequired": true,
//                                     "numberofImages": 2
//                                 },
//                                 "id": "e51c3f3b-5c07-44ac-b0ad-d9dff085c8ab",
//                                 "parentId": "70e197be-d024-47e6-b853-dd357fcab591",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Photo Documentation",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "cec4f736-73fa-49fa-8613-a32aaa8f4471",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Red Flag",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             }
//                         ],
//                         "id": "70e197be-d024-47e6-b853-dd357fcab591",
//                         "parentId": "cec4f736-73fa-49fa-8613-a32aaa8f4471",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Photo Documentation",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "cec4f736-73fa-49fa-8613-a32aaa8f4471",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Red Flag",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     },
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "Defect Images",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [
//                             {
//                                 "label": "Images",
//                                 "nodeType": "data-images",
//                                 "key": "5_images",
//                                 "order": 5,
//                                 "settings": {
//                                     "title": "Images",
//                                     "defaultTitle": "Images",
//                                     "translation": {
//                                         "en": "",
//                                         "de": "",
//                                         "value": ""
//                                     },
//                                     "isSourceRequired": true,
//                                     "numberofImages": 2
//                                 },
//                                 "id": "18f0b4fc-4965-472a-9cd3-5a05b1d747ab",
//                                 "parentId": "4daeecd0-709c-4b74-9e3e-9fea6ff92885",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "Photo Documentation",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "cec4f736-73fa-49fa-8613-a32aaa8f4471",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Red Flag",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             }
//                         ],
//                         "id": "4daeecd0-709c-4b74-9e3e-9fea6ff92885",
//                         "parentId": "cec4f736-73fa-49fa-8613-a32aaa8f4471",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Photo Documentation",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "cec4f736-73fa-49fa-8613-a32aaa8f4471",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Red Flag",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     }
//                 ],
//                 "id": "cec4f736-73fa-49fa-8613-a32aaa8f4471",
//                 "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                 "parentNode": {
//                     "label": "Document",
//                     "key": "document-root",
//                     "nodeType": "document-root",
//                     "depth": 0,
//                     "settings": {
//                         "title": "Red Flag",
//                         "defaultTitle": "Document",
//                         "translation": {
//                             "en": "",
//                             "de": ""
//                         }
//                     },
//                     "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                     "children": []
//                 }
//             },
//             {
//                 "label": "Header",
//                 "key": "header-chapter",
//                 "nodeType": "header-chapter",
//                 "depth": 1,
//                 "settings": {
//                     "title": "Conclusion",
//                     "defaultTitle": "Header",
//                     "translation": {
//                         "en": "",
//                         "de": ""
//                     }
//                 },
//                 "children": [
//                     {
//                         "label": "Paragraph",
//                         "nodeType": "data-paragraph",
//                         "key": "6_paragraph",
//                         "order": 6,
//                         "settings": {
//                             "title": "Paragraph",
//                             "defaultTitle": "Paragraph",
//                             "userEditable": false,
//                             "sourceRequired": true,
//                             "translation": {}
//                         },
//                         "content": {
//                             "defaultText": "<p><span style=\"background-color:rgb(255,255,255);color:rgb(102,102,102);font-family:Verdana, Geneva, sans-serif;\">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,</span></p>"
//                         },
//                         "id": "a477ff9e-2919-46c6-ae8c-299ee15f72c6",
//                         "depth": 2,
//                         "parentId": "9d510e49-0765-487f-8374-3f3727bb2b98",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Conclusion",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "9d510e49-0765-487f-8374-3f3727bb2b98",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Red Flag",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     }
//                 ],
//                 "id": "9d510e49-0765-487f-8374-3f3727bb2b98",
//                 "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                 "parentNode": {
//                     "label": "Document",
//                     "key": "document-root",
//                     "nodeType": "document-root",
//                     "depth": 0,
//                     "settings": {
//                         "title": "Red Flag",
//                         "defaultTitle": "Document",
//                         "translation": {
//                             "en": "",
//                             "de": ""
//                         }
//                     },
//                     "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                     "children": []
//                 }
//             },
//             {
//                 "label": "Header",
//                 "key": "header-chapter",
//                 "nodeType": "header-chapter",
//                 "depth": 1,
//                 "settings": {
//                     "title": "Contact",
//                     "defaultTitle": "Header",
//                     "translation": {
//                         "en": "",
//                         "de": ""
//                     }
//                 },
//                 "children": [
//                     {
//                         "label": "Contact-Cards",
//                         "nodeType": "data-contact",
//                         "key": "8_contact_cards",
//                         "order": 8,
//                         "settings": {
//                             "title": "Contact",
//                             "defaultTitle": "Contact",
//                             "translation": {
//                                 "en": "",
//                                 "de": "",
//                                 "value": ""
//                             },
//                             "numberOfContacts": 4
//                         },
//                         "id": "2ae2db44-1a20-4cc5-aed7-532c7b481e33",
//                         "depth": 2,
//                         "parentId": "d2cf772d-1838-4b74-9515-d81615c57dcb",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "Contact",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "d2cf772d-1838-4b74-9515-d81615c57dcb",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Red Flag",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     }
//                 ],
//                 "id": "d2cf772d-1838-4b74-9515-d81615c57dcb",
//                 "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                 "parentNode": {
//                     "label": "Document",
//                     "key": "document-root",
//                     "nodeType": "document-root",
//                     "depth": 0,
//                     "settings": {
//                         "title": "Red Flag",
//                         "defaultTitle": "Document",
//                         "translation": {
//                             "en": "",
//                             "de": ""
//                         }
//                     },
//                     "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                     "children": []
//                 }
//             },
//             {
//                 "label": "Header",
//                 "key": "header-chapter",
//                 "nodeType": "header-chapter",
//                 "depth": 1,
//                 "settings": {
//                     "title": "chpater 7",
//                     "defaultTitle": "Header",
//                     "translation": {
//                         "en": "",
//                         "de": ""
//                     }
//                 },
//                 "children": [
//                     {
//                         "label": "Sub Header",
//                         "key": "sub-header-2",
//                         "nodeType": "sub-header",
//                         "depth": 2,
//                         "settings": {
//                             "title": "Section 1",
//                             "defaultTitle": "Sub Header",
//                             "translation": {
//                                 "en": "",
//                                 "de": ""
//                             }
//                         },
//                         "children": [
//                             {
//                                 "label": "Sub Header",
//                                 "key": "sub-header-3",
//                                 "nodeType": "sub-header",
//                                 "depth": 3,
//                                 "settings": {
//                                     "title": "sub-section",
//                                     "defaultTitle": "Sub Header",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "children": [],
//                                 "id": "5beeab32-edd3-4584-a181-27ee10797e92",
//                                 "parentId": "f80302e0-db13-469d-927f-0e59d24e7198",
//                                 "parentNode": {
//                                     "label": "Header",
//                                     "key": "header-chapter",
//                                     "nodeType": "header-chapter",
//                                     "depth": 1,
//                                     "settings": {
//                                         "title": "chpater 7",
//                                         "defaultTitle": "Header",
//                                         "translation": {
//                                             "en": "",
//                                             "de": ""
//                                         }
//                                     },
//                                     "children": [],
//                                     "id": "ca2f97a9-5cd4-4cb5-ab22-9387efa67cfa",
//                                     "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                     "parentNode": {
//                                         "label": "Document",
//                                         "key": "document-root",
//                                         "nodeType": "document-root",
//                                         "depth": 0,
//                                         "settings": {
//                                             "title": "Red Flag",
//                                             "defaultTitle": "Document",
//                                             "translation": {
//                                                 "en": "",
//                                                 "de": ""
//                                             }
//                                         },
//                                         "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                         "children": []
//                                     }
//                                 }
//                             }
//                         ],
//                         "id": "f80302e0-db13-469d-927f-0e59d24e7198",
//                         "parentId": "ca2f97a9-5cd4-4cb5-ab22-9387efa67cfa",
//                         "parentNode": {
//                             "label": "Header",
//                             "key": "header-chapter",
//                             "nodeType": "header-chapter",
//                             "depth": 1,
//                             "settings": {
//                                 "title": "chpater 7",
//                                 "defaultTitle": "Header",
//                                 "translation": {
//                                     "en": "",
//                                     "de": ""
//                                 }
//                             },
//                             "children": [],
//                             "id": "ca2f97a9-5cd4-4cb5-ab22-9387efa67cfa",
//                             "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                             "parentNode": {
//                                 "label": "Document",
//                                 "key": "document-root",
//                                 "nodeType": "document-root",
//                                 "depth": 0,
//                                 "settings": {
//                                     "title": "Red Flag",
//                                     "defaultTitle": "Document",
//                                     "translation": {
//                                         "en": "",
//                                         "de": ""
//                                     }
//                                 },
//                                 "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                                 "children": []
//                             }
//                         }
//                     }
//                 ],
//                 "id": "ca2f97a9-5cd4-4cb5-ab22-9387efa67cfa",
//                 "parentId": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                 "parentNode": {
//                     "label": "Document",
//                     "key": "document-root",
//                     "nodeType": "document-root",
//                     "depth": 0,
//                     "settings": {
//                         "title": "Red Flag",
//                         "defaultTitle": "Document",
//                         "translation": {
//                             "en": "",
//                             "de": ""
//                         }
//                     },
//                     "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
//                     "children": []
//                 }
//             }
//         ]
//     },
documentItems:{
            "label": "Document",
            "key": "document-root",
            "nodeType": "document-root",
            "depth": 0,
            "settings": {
                "title": "Red Flag",
                "defaultTitle": "Document",
                "translation": {
                    "en": "",
                    "de": ""
                }
            },
            "id": "e9ae143e-7434-4f67-b67a-a634655dcab1",
    children:[]
},
preview:false,
};

const documentBuilderSlice = createSlice({
  name: "documentBuilderSlice",
  initialState,
  reducers: {
    syncDocumentState:(state, { payload }) => ({
        ...state,
        documentItems: payload,
       }),
       setPreview:(state) => ({
        ...state,
        preview: !state.preview,
       }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

export const { resetState,syncDocumentState, setPreview} = documentBuilderSlice.actions;

// A selector
export const documentBuilderSliceSelector = (state) => state.documentBuilderSlice;

// The reducer
export default documentBuilderSlice.reducer;