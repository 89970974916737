import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import IntlMessages from "../../../utils/IntlMessages";
import { Typography } from "@mui/material";

const SaveIconButton = (props) => {
  const [readOnly, setReadOnly] = useState(false);

  const { tooltip,label, color, ...restProps } = props;
  const state = useSelector((state) => state.readOnly);

useEffect(() => {
      setReadOnly(state.isReadOnly);
  }, [state.isReadOnly]);
  return (
    <>
      {!readOnly && (
        <>
            <IconButton
              id={props.id}
              className={props.className}
              edge={props.edge}
              onClick={(e) => props.handleClick(e)}
              {...restProps}
              >
                <Tooltip title={props.tooltip.title}>
                <SaveIcon color={props.color} style={{ color: props.color }} />
            </Tooltip>
            </IconButton>
          {!!props.label && (
            <Typography variant="caption" style={{ color: props.color ,...props.labelStyle}} className="w-100 d-flex align-items-center justify-content-center text-center">
              <IntlMessages id = {props.label}/>
            </Typography>
          )}
        </>
      )}
    </>
  );
};

SaveIconButton.defaultProps = {};
SaveIconButton.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  labelStyle: PropTypes.object,
  color: PropTypes.string,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
  }),
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  edge: PropTypes.string,
};

export default SaveIconButton;
