import React from 'react';
import DataAnalysisHome from "./DataAnalysisHome"
import { tddRoot } from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

// change path  
const DataAnalysisRouteConfig = {
	routes: [
		{
    path: `${tddRoot}/data-analysis`,
    exact: true,
    name: <IntlMessages id={"menu.data-analysis"}/>,
    element: <DataAnalysisHome/>,
    icon: <DonutLargeIcon style={{ width:22, height:22, color: "#637381"}} />,
    auth: [],
  },
	]
};
export default DataAnalysisRouteConfig;