import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
  Slide,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../components/buttons/CustomButton";
import {
  getTranslationVariantText,
  getLabel,
  getIntlTranslation,
  getMentions,
} from "../../../../../utils/helper";
import TextEditorMentions from "../items/text-editors/TextEditorMentions";
import SendIcon from "@mui/icons-material/Send";
import { useIntl } from "react-intl";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { resetState } from "../../report-review/reportReviewSlice";
import { useSnackbar } from "notistack";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EmailContainer = ({onSend}) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const reportReviewState = useSelector(state => state.reportReview);

 
  const users = useSelector((state) => state.users);
  const [userSelected, setUsersSelected] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const editorRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if(reportReviewState.email.type==="SUCCESS"){
      reportReviewState.email.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState())
      editorRef.current = null;
      setSendEmail(false);
      setUsersSelected([]);

    }
  
  }, [reportReviewState.email])

  return (
    <>
      <FormControlLabel
      className="w-100 p-1 mb-1"
        control={
          <Checkbox
            checked={sendEmail}
            onChange={(e) => setSendEmail(e.target.checked)}
            className="p-1"
          />
        }
        label={getTranslationVariantText(
          "_trigger_team_member_add_email",
          "caption",
          {
            fontWeight: "bold",
            fontSize:12,
          }
        )}
      />
        <Slide direction="up"  in={sendEmail} mountOnEnter unmountOnExit>
        <Box
          className="d-flex w-100 flex-column p-1 rounded-3 mt-2"
          style={{ backgroundColor: "#ffffff" }}
        >
          <Autocomplete
            multiple
            size="small"
            id="checkboxes-users"
            options={users.mentionList.filter(
              (e) => e.emailAddress !== "it.support@taeurope.com"
            )}
            value={userSelected}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            onChange={(event, newValue) => {
              setUsersSelected(newValue);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            style={{ width: "95%", margin: 5 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={getLabel("Team Members")}
                placeholder={intl
                  .formatMessage({ id: "_type-user-search" })
                  .toLowerCase()}
                size="small"
              />
            )}
          />
          <TextEditorMentions
            editorRef={editorRef}
            onClose={() => setSendEmail(false)}
          />

          <Box className="d-flex w-100 align-item-center justify-content-between p-1">
            <CustomButton
              id={`cancel-email`}
              buttonText={"_cancel"}
              variant="text"
              style={{ height: 30 }}
              className="btn"
              tooltip={{
                title: getIntlTranslation("tooltip.cancel"),
              }}
              handleClick={(e) => {
                setSendEmail(false);
              }}
            />
            {userSelected.length > 0 &&
            <CustomButton
              id={`post_email`}
              buttonText={"_send-email"}
              endIcon={<SendIcon style={{ fontSize: "18" }} />}
              style={{
                color: "#254a9a",
                height: 30,
              }}
              className="btn g-btn-primary m-1"
              tooltip={{
                title: getIntlTranslation("tooltip.send-email"),
              }}
              handleClick={(e) => {
                const mentions = getMentions(editorRef);
                const users = new Set();
                getMentions(editorRef).forEach((mention) => {
                  users.add(mention.emailAddress);
                })
                userSelected.forEach((user) => {
                  users.add(user.emailAddress);
                })
                onSend({
                  message:editorRef.current.getData(),
                  users:Array.from(users)?.map((m) => ({
                    type: "userDto",
                    emailAddress: m,
                  }))
                })
                }}
            />
              }
          </Box>
        </Box>
        </Slide>
    </>
  );
};

EmailContainer.propTypes = {};

export default EmailContainer;
