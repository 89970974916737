import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getLabel, getDialogBoxHeader,getIntlTranslation } from "../../../../../utils/helper";
import SaveButton from "../../../../../components/buttons/SaveButton";
import { getBuildingType } from "../../../../../api/suggestionApi";
import { createAsset } from "../../../../../api/assetOverviewApi";
import { resetState } from "../../../../component-bundle/asset-overview/assetOverviewSlice";
import { useSnackbar } from "notistack";
import AssetCreateForm from "./AssetCreateForm"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});



const AssetCreateContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.assetOverview);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const suggestionState = useSelector((state) => state.suggestions);
  const [buildingType, setBuildingType] = useState([]);
  const [createAnother, setCreateAnother] = useState(false);
  const [intialFormData, setIntialFormData] = useState(
    {
      name: "",
      addressLabel: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      subAssetCount:1,
      reportVersion:"",
      subAssets:[
        {
          id:Date.now(),
          name: "",
          typeOfUse: { title: "", value: null },
          addressLabel: "",
          isGeneral: false,
          order:1,
          assetIdent:"",
          addressSameAsAsset:false,
        }
      ]
    }
  )
  
  useEffect(() => {
    dispatch(getBuildingType());
  }, [dispatch]);

  useEffect(() => {
    if (
      suggestionState.buildingType.type === "FETCHED" &&
      suggestionState.buildingType.data.length > 0
    ) {
      setBuildingType([...suggestionState.buildingType.data]);
    }
  }, [suggestionState.buildingType]);

  useEffect(() => {
    if (state.create.type === "SAVED") {
      state.create.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      props.onCreate();
      if (createAnother) {
        formRef.current.resetForm();
      } else {
        props.onClose();
      }
      dispatch(resetState());
    }
  }, [
    createAnother,
    dispatch,
    enqueueSnackbar,
    props,
    state.create.messages,
    state.create.type,
  ]);

  const handleSave = (values) => {
    const address = {
      type: "addressDto",
      streetName: values.address,
      optionalStreetName: "",
      postalCode: values.postalCode,
      city: values.city,
      state: values.state,
      country: values.country,
    };

    const subAssets = values.subAssets.map((s) => {
      return (
        {
            type: "monitoringSubAsset",
            name: s.name,
            typeOfUse: s.typeOfUse.value,
            address: s.addressLabel,
            projectIdent: scope.projectIdent,
            assetIdent: "",
            general: s.isGeneral,
          }
      )
    });

    const asset = {
      type: "monitoringAsset",
      name: values.name,
      addressDto: address,
      projectIdent: scope.projectIdent,
      reportName:values.reportVersion,
      subAssets:subAssets
    };

    dispatch(
      createAsset({
        data: asset,
        api: scope.api,
      })
    );
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.onClose();
        }
      }}
      disableEscapeKeyDown
      style={{ overflow: "hidden" }}
    >
      <AppBar className={"appBar"}>
        <Toolbar className={"dialog-toolbar"}>
          {getDialogBoxHeader("Assets")}
          <IconButton
            edge="start"
            color="inherit"
            id="create_asset_close"
            onClick={(e) => props.onClose()}
            style={{ marginLeft: "auto" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <AssetCreateForm 
        intialFormData={intialFormData} 
        handleSave={(values) => handleSave(values)} 
        formRef={formRef}
        buildingType={buildingType}/>

      </DialogContent>
      <DialogActions className="justify-content-between">
        <FormControlLabel
          label={getLabel("_create another")}
          style={{ margin: 5 }}
          control={
            <Checkbox
              checked={createAnother}
              onChange={(e) => setCreateAnother(e.target.checked)}
            />
          }
        />
        <SaveButton
          id="create-asset-button"
          buttonText="_create"
          variant="button"
          style={{ margin: 5 }}
          editDialog = {false}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          handleClick={(e) => formRef.current.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

AssetCreateContainer.defaultProps = {
  readOnly: false,
};
AssetCreateContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  onCreate: PropTypes.func,
};

export default AssetCreateContainer;
