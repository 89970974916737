import React,{useState  } from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PercentIcon from "@mui/icons-material/Percent";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { getIntlTranslation } from "../../../utils/helper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const InflationRateDialogBox = ({ open, close, handleSave }) => {
    const [inputValue, setInputvalue] = useState({value:"",index:""});

    const handleChange = (event) => {
        const str = `${event.target.value}`;
        setInputvalue({value:str,index:str});
      };
  return (
    <>
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
          <Typography>
            This will be automatically applied to all costs values
          </Typography>
        </BootstrapDialogTitle> */}
        <DialogContent
          dividers
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection:"column",
                alignItems: "flex-start",
                width: "100%",
                minWidth: "400px",
              }}
            >
              <Grid>
                <FormLabel id="demo-radio-buttons-group-label">
                  {getIntlTranslation("_Inflation rate")}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(event) => handleChange(event)}
                  style={{display:"flex",flexDirection:"row"}}

                >
                  <FormControlLabel
                    value={"3"}
                    control={<Radio />}
                    label="3%"
                    style={{ padding: "0px" }}
                    checked={inputValue.index === "3"}

                  />
                  <FormControlLabel
                    value={"5"}
                    control={<Radio />}
                    label="5%"
                    style={{ padding: "0px" }}
                    checked={inputValue.index === "5"}
                  />
                  <FormControlLabel
                    value={"7"}
                    control={<Radio />}
                    label="7%"
                    style={{ padding: "0px" }}
                    checked={inputValue.index === "7"}

                  />
                  <FormControlLabel
                    value={"10"}
                    control={<Radio />}
                    label="10%"
                    style={{ padding: "0px" }}
                    checked={inputValue.index === "10"}

                  />
                  <FormControlLabel
                    value={"12"}
                    control={<Radio />}
                    label="12%"
                    style={{ padding: "0px" }}
                    checked={inputValue.index === "12"}

                  />
                  <FormControlLabel
                    value={"15"}
                    control={<Radio />}
                    label="15%"
                    style={{ padding: "0px" }}
                    checked={inputValue.index === "15"}
                  />
                </RadioGroup>
              </Grid>
              <Grid>
                <Box style={{marginBottom:"15px"}}>
                  <Typography>{getIntlTranslation("_percent symbol")}</Typography>
                </Box>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <TrendingUpIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="ex: 8.34"
                    type="number"
                    inputProps={{
                      "aria-label": "Enter percentage Number",
                    }}
                    value={inputValue.value}
                    onChange={(event) => handleChange(event)}
                    />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <PercentIcon />
                  </IconButton>
                </Paper>
              </Grid>
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            {getIntlTranslation("_cancel")}
          </Button>
          <Button autoFocus onClick={()=>{handleSave(inputValue)}}>
            {getIntlTranslation("_save")}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default InflationRateDialogBox;
