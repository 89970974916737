import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  getIntlTranslation,
  getLanguageCode,
  getVariantText,
} from "../../../../utils/helper";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar, Box, Typography, IconButton, Slide } from "@mui/material";
import CustomButton from "../../../../components/buttons/CustomButton";

const filter = createFilterOptions();

const validationSchema = yup.object({
  en: yup.string().required("English Translation is required"),
  de: yup.string().required("German Translation is required"),
});

const AutoCompleteFreeSolo = ({
  list,
  selectedVal,
  onLabelSelect,
  editable,
}) => {
  const [value, setValue] = useState(null);
  const [toggle, setToggleOpen] = useState({
    open:false,
    data:{
      de:"",
      en:"",
    }
  });
  const scope = useSelector((state) => state.container.scope);
  const [optionList, setoptionList] = useState([]);

  useEffect(() => {
    if (!!list.length) {
      const data = list.filter(
        (f) => getLanguageCode(f.language) === scope.projectLanguageCode
      );
      setoptionList(data);
      if (!!selectedVal) {
        setValue(selectedVal);
        if (typeof onLabelSelect === "function") {
          onLabelSelect(list.filter((f) => f.value === selectedVal.value));
        }
      } else {
        setValue(data[0]);
        if (typeof onLabelSelect === "function") {
          onLabelSelect(list.filter((f) => f.value === data[0].value));
        }
      }
    }
  }, [list, scope]);

  return (
    <React.Fragment>
      <Autocomplete
        disabled={editable}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setToggleOpen({
              open:true,
              data:{
                de: scope.projectLanguageCode === "de" ? newValue.inputValue : "",
                en: scope.projectLanguageCode === "en" ? newValue.inputValue : "",
              }
            });
          } else if (newValue && newValue.inputValue) {
            setToggleOpen({
              open:true,
              data:{
                de: scope.projectLanguageCode === "de" ? newValue.inputValue : "",
                en: scope.projectLanguageCode === "en" ? newValue.inputValue : "",
              }
            });
          } else {
            setValue(newValue);
            if (!!newValue) {
              if (typeof onLabelSelect === "function") {
                onLabelSelect(list.filter((f) => f.value === newValue.value));
              }
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        id="auto-complete-custom"
        options={optionList}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option.title;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        sx={{ width: "90%" }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Label"
            variant="standard"
            helperText={getIntlTranslation("_label-required")}
          />
        )}
      />
      {toggle.open && (
        <AddCustomLabel
          open={toggle.open}
          data={toggle.data}
          closeDialog={() => setToggleOpen({
            open:false,
            data:{
              de:"",
              en:"",
            }
          })}
          setData={(data) => {
            setValue({
              title: data[scope.projectLanguageCode]?.toUpperCase(),
              value: data["de"]?.toUpperCase(),
              language: scope.projectLanguageCode,
            });
          }}
          onSelect={(trans) => {
            if (typeof onLabelSelect === "function") {
              onLabelSelect(trans);
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCustomLabel = ({ open, data ,closeDialog, setData, onSelect }) => {
  const formRef = useRef();
  const [formData, setFormData] = useState({
    de: data["de"],
    en: data["en"],
  });

  const handleClose = () => {
    setFormData({
      de: "",
      en: "",
    });
    closeDialog();
  };

  const handleSubmit = (data) => {
    setData(data);
    const trans = [
      {
        type: "title",
        title: data["en"]?.toUpperCase(),
        value: data["de"]?.toUpperCase(),
        language: "english",
      },
      {
        type: "title",
        title: data["de"]?.toUpperCase(),
        value: data["de"]?.toUpperCase(),
        language: "german",
      },
    ];
    onSelect(trans);
    handleClose();
  };
  return (
    <Dialog open={open}
      TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
           handleClose();
          }
        }}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth="sm"
    >
      <AppBar className="appBar" style={{ height: 40 }}>
        <Toolbar
          className={"dialog-toolbar p-1"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box className="d-flex align-items-center" style={{ marginLeft: 5 }}>
            <Typography
              variant="body2"
              style={{ textTransform: "uppercase", marginLeft: 5 }}
            >
              {getIntlTranslation("_Add-new-label")}
            </Typography>
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            id="create_project_close"
            onClick={(e) => handleClose()}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Formik
        enableReinitialize={true}
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <DialogContent dividers className="w-100 p-1">
              <Box className="m-1"></Box>
              <Field
                fullWidth={true}
                label="English"
                id={`english-${Date.now()}`}
                size="small"
                as={TextField}
                name={"en"}
                value={values.en}
                error={touched.en && errors.en}
                helperText={touched.en && errors.en}
                style={{ width:"90%" , margin:"5px 5px" }}
              />
              <Field
                fullWidth={true}
                label="Deutsch"
                id={`german-${Date.now()}`}
                size="small"
                as={TextField}
                name={"de"}
                value={values.de}
                error={touched.de && errors.de}
                helperText={touched.de && errors.de}
                style={{ width:"90%" , margin:"5px 5px" }}
              />
            </DialogContent>
          </Form>
        )}
      </Formik>
      <DialogActions>
          <CustomButton
            id={`save-dialog`}
            buttonText={"_save"}
            variant="button"
            style={{
              margin: 5,
              backgroundColor: "#254a9a",
              color: "#ffffff",
            }}
            tooltip={{
              title: getIntlTranslation("tooltip.save"),
            }}
            handleClick={() => formRef.current.handleSubmit()}
          />
      </DialogActions>
    </Dialog>
  );
};

export default AutoCompleteFreeSolo;
