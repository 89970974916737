import React from "react";
import { globalRoot } from "../../utils/defaultValues";
import UserManagementHome from "./UserManagementHome";
import IntlMessages from "../../utils/IntlMessages";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const UserManagementRouteConfig = {
  routes: [
    {
      id:"user-management",
      path: `${globalRoot}/user-management/`,
      exact: true,
      showBreadCrumbs: true,
      name: <IntlMessages id={`menu.user-management`}/>,
      icon: <ManageAccountsIcon style={{ width:22, height:22, color: "#637381"}} />,
      element: <UserManagementHome />,
    },
  ],
};

export default UserManagementRouteConfig;
