import { Box, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getLabel } from '../../../../utils/helper';

const QuelleInput = ({id, node, disabled, handleQuelleChange}) => {
  const [value, setValue] = useState(node.quelle);
  const [firstChangeTriggered, setFirstChangeTriggered] = useState(false);
  useEffect(() => {
    if (node.quelle !== null) {
      setValue(node.quelle);
    } else {
      setValue(null);
    }
  }, [node.quelle]);


  
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (!firstChangeTriggered) {
      setFirstChangeTriggered(true);
      handleQuelleChange(newValue, node, true);
    }
  };


  const handleInputBlur = (e) => {
    if (firstChangeTriggered && e.target.value !== node.quelle) {
      handleQuelleChange(e.target.value, node, true);
    }
  };

  return (
    <Box className="d-flex">
    <TextField
     fullWidth
      id={`quelle-${id}`}
      label={getLabel("_quelle")}
      disabled={disabled}
      value={value}
      onChange={(e) => handleInputChange(e)}
      onBlur={(e) => handleInputBlur(e)}
      multiline
      minRows={2}
      maxRows={5}
      variant="outlined"
      size="small"
      dense="true"
      InputLabelProps={{
        shrink: true,
    }}
    />
  </Box>
  )
}

export default QuelleInput;
