import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false,
  error:{
    type: "",
   messages: [],
   data: {},
 },
 success:{
  type:"",
  messages:[],
  data:{},
  list:[],
 }
};

const contactCardsSlice = createSlice({
  name: "contactCardsSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error:{
      type: payload.type,
      messages: payload.messages,
      data: payload,
     }
    }),
    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      success:{
      type: payload.type,
      messages: payload.messages,
      data: payload.guruData,
      list:payload.guruDataList,
     }
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

export const { resetState,getFailure,getSaveSuccess } = contactCardsSlice.actions;

// A selector
export const contactCardsSliceSelector = (state) => state.contactCardsSlice;

// The reducer
export default contactCardsSlice.reducer;