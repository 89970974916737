import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  saveImages,
  onDelete,
} from '../../../../../components/imageUploader/imageProcessing';
import { resetSliceState } from '../../../../../components/imageUploader/imageProcessSlice';
import { useSnackbar } from 'notistack';

import { b64toBlob } from '../../../../../utils/helper';
import List from '@mui/material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CustomIconButton from '../../../../../components/buttons/icons-buttons/CustomIconButton';
import CustomButton from '../../../../../components/buttons/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { getImageFilesForDefect } from '../../../api/defectImageApi';
import LinearProgress from '@mui/material/LinearProgress';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getLabel, getIntlTranslation } from '../../../../../utils/helper';
import DeleteIconButton from '../../../../../components/buttons/icons-buttons/DeleteIconButton';
import SaveIconButton from '../../../../../components/buttons/icons-buttons/SaveIconButton';
import { ImageProvider } from '../../../../component-bundle/image-gallery/ImageContext';
import SortablePhotoAlbum from '../../../../component-bundle/image-gallery/SortablePhotoAlbum';

const useStyles = styled((theme) => ({
  appBar: {
    position: 'relative',
    minHeight: '40px',
    backgroundColor: '#ffffff',
    margin: 5,
    borderRadius: '5px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1500,
    color: '#fff',
  },
  large: {
    width: 300,
    height: 200,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  flex: {
    display: 'flex',
  },
}));

const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};

export const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true;
  }
  return false;
};

export default function DefectImageUploader(props) {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const { row } = props;
  const [loader, setLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const imageState = useSelector((state) => state.imageProcess);
  const { enqueueSnackbar } = useSnackbar();
  const openAsset = useSelector((state) => state.openAsset);
  const readOnlystate = useSelector((state) => state.readOnly);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    onDrop: (acceptedFiles) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>{
          let generatedId = uuidv4();
        return  Object.assign(file, {
          id: generatedId,
          key: generatedId,
            status: 'new',
            caption: row?.trade.value,
            order: '',
            parentIdent: '',
            primary: '',
            fileName: file.name,
            assetIdent: openAsset.ident,
            src: URL.createObjectURL(file),
            width: 300,
            height: 200,
            isEdited:false,
            markerState:{},
            editedFile:{},
          })
        }
        ),
      ]);
    },
  });

  useEffect(() => {
    if (!!props.row && !!props.row?.defectIdent) {
      dispatch(
        getImageFilesForDefect({
          defectIdent: row?.defectIdent,
          projectIdent: scope.projectIdent,
        })
      );
    }
  }, [dispatch, props.row, row?.defectIdent, scope.projectIdent]);

  useEffect(() => {
    if (imageState.type === 'SUCCESS') {
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      let savedFile = [];
      files.forEach((file) => {
        if (file.id === imageState.imageData.imageIdent) {
          file.status = 'saved';
        }
        savedFile.push(file);
      });
      setFiles(savedFile);
      setLoader(false);
      dispatch(resetSliceState());
      setRefreshGrid(true);
    }

    if (imageState.type === 'UPDATED') {
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      dispatch(resetSliceState());
      setLoader(false);
      setRefreshGrid(true);
    }

    if (imageState.type === 'DELETED') {
      imageState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
      dispatch(resetSliceState());
      setLoader(false);
      setRefreshGrid(true);
    }
  }, [dispatch, enqueueSnackbar, files, imageState, props]);

  useEffect(() => {
    if (
      imageState.data !== undefined &&
      imageState.data !== null &&
      imageState.data.length > 0
    ) {
      setLoader(true);
      let tempFile = [];
      let sortedArray = [...imageState.data];
      sortedArray.sort((a, b) => {
        return a.order - b.order;
      });
      sortedArray.forEach((image) => {
        tempFile.push({
          id: image.imageIdent,
          status: 'saved',
          caption: image.caption,
          capexId: image.capexID,
          order: image.order,
          fileName: image.fileName,
          parentIdent: image.parentIdent,
          primary: image.primary,
          assetIdent: openAsset.ident,
          src: b64toBlob(image.file),
          key: image.imageIdent,
          width: 300,
          height: 200,
          markerState: !!image.markerState
          ? JSON.parse(image.markerState)
          : {},
          editedFile: !!image.editedFile ? b64toBlob(image.editedFile) : {},
          isEdited: image.edited,
        });
      });
      setFiles([...files, ...tempFile]);
      dispatch(resetSliceState());
      setLoader(false);
    }
  }, [dispatch, files, imageState]);

  const handleSubmit = () => {
    files.forEach((file, index) => {
      setLoader(true);
      file.order = index + 1;
      saveImages(
        file,
        { ident: row.defectIdent, type: 'defect' },
        dispatch,
        scope.projectIdent
      );
    });
    setLoader(false);
  };

   
  const onRemove = (file) => {
    let nonDeletedfiles = [];
    files.forEach((f) => {
      if (f.key === file.key && f.fileName === file.fileName) {
        if (f.status !== 'new') {
          onDelete(
            f,
            { ident: row.defectIdent, type: 'defect' },
            dispatch,
            scope.projectIdent
          );
        }
      } else {
        nonDeletedfiles.push(f);
      }
    });
    setFiles(nonDeletedfiles);
  };

  const onImageSave = (file) => {
    setLoader(true);
    const index = files.findIndex(
      (f) => f.key === file.key && f.fileName === file.fileName
    );
    files[index].order = index + 1;
    saveImages(
      files[index],
      { ident: row.defectIdent, type: 'defect' },
      dispatch,
      scope.projectIdent
    );
    setLoader(false);
  };

  const onCaptionChange = (key, value) => {
    let tempFile = [];
    files.forEach((element) => {
      if (key === element.key) {
        element.caption = value;
      }
      tempFile.push(element);
    });
    setFiles(tempFile);
  };

  return (
    <>
      <AppBar
        style={{
          height: 40,
          backgroundColor: '#d08f8e',
          position: 'sticky',
          zIndex: 5,
        }}
      >
        <Toolbar
          className={'dialog-toolbar p-0'}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <Box
            style={{
              display: 'flex',
              width: '25%',
              justifyContent: 'end',
              alignItems: 'center',
              backgroundColor: '#d08f8e',
              height: 40,
              borderRadius: 5,

              margin: '0px 4px',
            }}
            aria-label="button group for defect grid"
          >
            <CustomButton
              id="save-all-defect-images-01"
              buttonText={'_Save-All-Images'}
              variant="button"
              tooltip={{
                title: getIntlTranslation('tooltip.Save-All'),
              }}
              style={{
                height: 30,
                backgroundColor: '#d9534f',
                color: '#ffffff',
                marginRight: 5,
                width: '70%',
              }}
              handleClick={handleSubmit}
            />
            <Divider
              orientation="vertical"
              style={{
                width: 4,
                margin: '10px 0px',
                marginLeft: 'auto',
                backgroundColor: '#ffffff',
              }}
              flexItem
            />
            <div style={{ marginLeft: 'auto' }}>
              <CustomIconButton
                id="image_drawer_close_01"
                handleClick={(e) => {
                  props.onClose(refreshGrid);
                }}
                icon={<CloseIcon style={{ color: '#ffffff' }} />}
                tooltip={{
                  title: getIntlTranslation('tooltip.Click-to-close'),
                }}
              />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Divider />
      {loader && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 45px)',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <Box
          onDragOver={(e) => e.stopPropagation()}
          style={{
            width: '20%',
            backgroundColor: '#ffffff',
            height: 'calc(100% - 10px)',
            margin: 5,
          }}
        >
          <div
            className={getClassName('image-drop', isDragActive)}
            {...getRootProps()}
            style={{
              height: '100%',
              border: '4px double #d08f8e',
              cursor: 'pointer',
              borderRadius: 4,
              padding: 4,
            }}
          >
            <input className="dropzone-input" {...getInputProps()} {...props} />
            <div style={{ textAlign: 'center' }}>
              {isDragActive ? (
                <Typography className="dropzone-content">
                  {getLabel('_drop-the-files')}
                </Typography>
              ) : (
                <>
                  <Typography className="dropzone-content">
                    {getLabel('_Drag-Images')}
                  </Typography>
                  <em>{getLabel('_Image-Allowed')}</em>
                </>
              )}
            </div>
          </div>
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            onDragOver={(e) => e.stopPropagation()}
            className="custom-scrollBar"
            style={{
              width: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              height: 'calc(100% - 5px)',
            }}
          >
            {files?.filter((f) => !!f.id).length > 0 && (
              <ImageProvider
              onRemove={(file) => onRemove(file)}
              onImageSave={(file) => onImageSave(file)}
              onCaptionChange={(key, value) =>onCaptionChange(key, value)}
              onImageEdit={(photo, editedImage, markerState) => {
                let temp = files.map((file) => {
                  if (file.key === photo.key && photo.fileName === file.fileName) {
                    if(!!markerState) {
                      file.markerState = markerState;
                      file.status = file.status === "saved" ? "edited" : file.status;
                      file.isEdited = true;
                      file.editedFile = editedImage;
                    }
                    
                  }
                  return file;
                });
                setFiles(temp);
              }}
              isDragable={true}
              onDragEnd={(photos) => setFiles(photos)}
            >
              <SortablePhotoAlbum photoSet={files} />
            </ImageProvider>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

DefectImageUploader.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  needGridRefresh: PropTypes.func,
};
