import React from "react";
import { globalRoot } from "../../utils/defaultValues";
import DataCollectionHome from "./DataCollectionHome"
import IntlMessages from "../../utils/IntlMessages";
const DataCollectionRouteConfig = {
  routes: [
    {
      path: `${globalRoot}/data-collection/*`,
      exact: true,
      name: <IntlMessages id={`menu.data-collection`}/>,
      element: <DataCollectionHome/>,
      auth: [],
      children: [],
    },
  ],
};
export default DataCollectionRouteConfig;
