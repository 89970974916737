import React from 'react'
import PropTypes from 'prop-types'
import TaskContent from "./TaskContent";
import SideBarMenu from './SideBarMenu';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

const TackManagementContainer = props => {
  return (
    <Grid style={{display:"flex", marginTop:5}}>
        <SideBarMenu/>
        <TaskContent/>
    </Grid>
  )
}

TackManagementContainer.propTypes = {}

export default TackManagementContainer