import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Grid, Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import SegmentIcon from "@mui/icons-material/Segment";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import "react-quill/dist/quill.snow.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import {
  getIntlTranslation,
  getLabel,
  normalEditorConfig,
} from "../../../../../utils/helper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import GuruEditor from 'ckeditor5-custom-build/build/ckeditor';

const modules = {
  toolbar: {
    container: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ color: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ["clean"],
    ],
  },
};

const InputBox = forwardRef((props, _ref) => {
  const { provided, node, data, currentData, remove } = props;
  const [activeIndex, setActiveIndex] = useState(true);
  const [droppedOptionData,setDroppedOptionData] = useState({
    text:"",
    editable:true,
    checked:false,
  })
  const editorRef = useRef();
  const handleChange = () => {
    setActiveIndex(!activeIndex);
  };

  const handleRemoveOption = () => {
    if (typeof remove === "function") {
      remove(node, data);
    }
  };

  useEffect(() => {
    if (!!Object.keys(currentData).length) {
      setDroppedOptionData({
        text:!!currentData.defaultText ? currentData.defaultText :"",
        editable:currentData.editable === undefined ? true : currentData.editable,
        checked:currentData.userDefined  === undefined ? false :currentData.userDefined,
      })
      setActiveIndex(false);
   }
  }, [currentData]);

  useImperativeHandle(_ref, () => ({
    getNode: () => {
      return {
        ...node,
        ...data,
        userDefined: droppedOptionData.checked,
        defaultText: droppedOptionData.text,
      };
    },
  }));

  return (
    <Card
      sx={{ width: "100%", marginTop: 1 }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      raised={true}
    >
      <ListItemButton
        onClick={(e) => handleChange()}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ListItemIcon>
          <SegmentIcon />
        </ListItemIcon>
        <ListItemText primary="Text Input" />
        {activeIndex ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={activeIndex} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            sx={{
              pl: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <ListItemText
                primary={
                  <>
                    <Box style={{ display: "flex" }}>
                      <Typography
                        variant={"body2"}
                        style={{
                          width: "60%",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {getIntlTranslation("_optional-deault-text")}
                      </Typography>

                      <FormGroup>
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                            checked={droppedOptionData.checked}
                            disabled={!droppedOptionData.editable}
                            onChange={(e) => setDroppedOptionData((prev) => ({...prev,checked:e.target.checked}))}
                          />
                          }
                          label={getLabel("_user-editable")}
                        />
                      </FormGroup>
                    </Box>
                  </>
                }
              />
               <Box
              id={`box_${Date.now()}`}
              className="g-text-editor custom-scrollBar"
              style={{
                minHeight: "200px",
                padding: 0,
                width: "100%",
                height: "90%",
              }}
              tabIndex={-1}
            >
              <CKEditor
                id={`editor-${Date.now()}`}
                editor={GuruEditor.ClassicEditor}
                config={normalEditorConfig}
                disabled={!droppedOptionData.editable}
                data={droppedOptionData.text}
                onReady={(editor) => {
                  editorRef.current = editor;
                }}
                onBlur={(event, editor) => {
                    setDroppedOptionData((prev) => ({...prev, text:editor.getData()}))
                }}
              />
            </Box>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                textAlign: "right",
                marginTop: 2,
              }}
            >
              {droppedOptionData.editable && (
                <Button variant="outlined" onClick={() => handleRemoveOption()}>
                  {getIntlTranslation("_delete")}
                </Button>
              )}
            </Grid>
          </ListItem>
        </List>
      </Collapse>
    </Card>
  );
});

export default InputBox;
