import {
  Button,
  Typography,
  Popover,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSystemLanguage } from "./systemSlice";

const languages = [
  {
    id: "en",
    title: "English",
    flag: "us",
  },
  {
    id: "de",
    title: "Deutsch",
    flag: "de",
  },
];

function LanguageSwitcherWidget() {
  const dispatch = useDispatch();
  const system = useSelector((state) => state.system);
  const [currentLanguage, setCurrentLanguage] = useState({
    id: "de",
    title: "Deutsch",
    flag: "de",
  });
  const [menu, setMenu] = useState(null);


  useEffect(() => {
  if(!!system.language){
    let found = languages.find((f) => f.id === system.language);
    if(!!found){
      setCurrentLanguage(found)
    }else{
      setCurrentLanguage(  {id: "de",
      title: "Deutsch",
      flag: "de"})
    }
    
  }
  }, [system.language])
  

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };
  const onMenuCLose = () => {
    setMenu(null);
  };


  function handleLanguageChange(language) {
    dispatch(setSystemLanguage(language.id));
    setCurrentLanguage(language);
    onMenuCLose();
  }

  return (
    <>
      {!!currentLanguage && (
        <div className="m-1">
          <button
            id="languageSwitcher"
            onClick={langMenuClick}
            className="d-flex flex-row justify-content-start align-items-center g-btn-elevated languageSwitch"
          >
            <img
              src={`/assets/flags/${currentLanguage.flag}.png`}
              alt={currentLanguage.title}
              width={20}
              height={18}
            />

            <Typography
              color="#ffffff"
              className="m-1"
            >
              {currentLanguage.id}
            </Typography>
          </button>

          <Popover
            open={Boolean(menu)}
            anchorEl={menu}
            onClose={onMenuCLose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {languages.map((l) => (
              <MenuItem
                key={l.id}
                onClick={() => handleLanguageChange(l)}
                id="switchLanguage"
              >
                <ListItemIcon className="min-w-40" id={`select-${l.title}`}>
                  <img
                    className="min-w-20"
                    src={`/assets/flags/${l.flag}.png`}
                    alt={l.title}
                  />
                </ListItemIcon>
                <ListItemText primary={l.title} />
              </MenuItem>
            ))}
          </Popover>
        </div>
      )}
    </>
  );
}

export default LanguageSwitcherWidget;
