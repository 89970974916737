import React from 'react';
import ProjectVariationsHome from "./ProjectVariationsHome"
import { pmRoot} from "../../../../utils/defaultValues";
import IntlMessages from "../../../../utils/IntlMessages";

const ProjectVariationsRouteConfig = {
  routes: [
		{
    path: `${pmRoot}/project/project-variations`,
    exact: true,
    name: <IntlMessages id={"menu.project-variations"}/>,
    element: <ProjectVariationsHome/>,
    auth: [],
  },
	]
};
export default ProjectVariationsRouteConfig;