import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../../../utils/defaultValues";

export const initialState = {
  hasErrors: false,
  error: { ...response },
  fetch: { ...response },
  save: { ...response },
  projectUsers:{ ...response },
  updatedRows:[],
};

const projectInformationTDD = createSlice({
  name: "projectInformationTDD",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      error: {
        type: payload.type,
        messages: payload.messages,
        data: payload,
      },
    }),
    getFetchSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      fetch: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getProjectUserSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      projectUsers: {
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      },
    }),
    getSaveSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      save: {
        type: payload.type,
        messages: payload.messages,
        data: payload.dataNodeList,
      },
    }),
    setUpdatedRows:(state,{payload}) => ({
      ...state,
      updatedRows:payload,
    }),
    resetState: (state) => ({
      ...initialState
    }),
  },
});

export const {
  resetState,
  getFailure,
  getFetchSuccess,
  getProjectUserSuccess,
  setUpdatedRows,
  getSaveSuccess,

} = projectInformationTDD.actions;

// A selector
export const projectInformationTDDSelector = (state) => state.projectInformationTDD;

// The reducer
export default projectInformationTDD.reducer;
