import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RestoreIcon from '@mui/icons-material/Restore';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import MenuList from '@mui/material/MenuList';
import { getLabel, getIntlTranslation } from "../../../../../utils/helper";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import {Badge,Box, Divider} from "@mui/material";

import CameraAltIcon from '@mui/icons-material/CameraAlt';

const ITEM_HEIGHT = 48;

function RowOptionsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getImageAction = (p) => {
    if (p.row.type === "defect_row" && p.row.deleted) {
      return (
        <>
          <div style={{ filter: "blur(4px)", height: "100%", width: "100%" }}>
            <CameraAltIcon
              style={{
                color: p.row.imageCount === 0 ? "#808080" : "#3f51b5",
                fontSize: 20,
              }}
            />
          </div>
        </>
      );
    } else if (p.row.type === "defect_row") {
      return (
        <CustomIconButton
          id="custom_image_upload_01"
          handleClick={(e) => props.gridRef.current.openImageDrawer(p)}
          style={{width:"100%",}}
          icon={
            <Badge badgeContent={p.row.imageCount} sx={{
              "& .MuiBadge-badge": {
                color: "#ffffff",
                backgroundColor: "#d08f8e"
              }
            }}>
              <CameraAltIcon
                style={{
                  color: p.row.imageCount === 0 ? "#808080" : "#3f51b5",
                  fontSize: 20,
                }}
              />
            </Badge>
          }
          tooltip={{
            title: getIntlTranslation("tooltip.add-Image"),
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
    <Box style={{height:"50%"}} className="d-flex w-100">
      <IconButton
        aria-label="more"
        id="menu-button-defect"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        style={{height:"100%", width:"100%"}}
        onClick={handleClick}
      >
        <MenuIcon style={{ color: "#3f51b5", fontSize: 20 }}/>
      </IconButton>
      <Menu
        id="grid-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "15ch",
            maxWidth:"20ch"
          },
        }}
      >
      <MenuList className="d-flex flex-column p-1">
         {!props.grid.row.deleted && (
        <MenuItem
          key={`${Math.random() * 10}-edit`}
          onClick={(e) => {
            props.onEditRow(props.grid)
            handleClose();
          }}
          className="p-1 grid-option"
        >
          <>
            <EditIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
              {getLabel("_edit")}
            </Typography>
          </>
        </MenuItem>
        )}
        <MenuItem
          key={`${Math.random() * 10}-copy`}
          onClick={(e) => {
            props.gridRef.current.duplicateRow(props.grid)
            handleClose();
          }}
          className="p-1 grid-option"
        >
          <>
            <FileCopyIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
              {getLabel("_Copy")}
            </Typography>
          </>
        </MenuItem>
        {!props.grid.row.deleted && (
          <MenuItem
            key={`${Math.random() * 10}-remove`}
            onClick={(e) => {
              props.gridRef.current.deleteRow(props.grid)
              handleClose();
          }}
          className="p-1 grid-option"
          >
            <>
              <RemoveCircleIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
                {getLabel("_delete")}
              </Typography>
            </>
          </MenuItem>
        )}
        {props.grid.row.deleted && (
          <MenuItem
            key={`${Math.random() * 10}-remove`}
            onClick={(e) => {
              props.gridRef.current.restoreRow(props.grid)
              handleClose();
          }}
          className="p-1 grid-option"
          >
            <>
              <RestoreIcon className="grid-option-icon m-1"/>
              <Typography className="grid-option-label">
                {getLabel("_Restore")}
              </Typography>
            </>
          </MenuItem>
        )}
          </MenuList>
      </Menu>
    </Box>
    <Divider/>
    <Box style={{height:"50%"}} className="d-flex w-100">
     {getImageAction(props.grid)}
     </Box>
     </>
  );
}

RowOptionsMenu.propTypes = {
  grid: PropTypes.object,
  gridRef: PropTypes.object,
  onEditRow:PropTypes.func,
};

export default RowOptionsMenu;
