import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
} from "@mui/material";
import {
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import { onSelectReportVersion } from "../projectDescriptionSlice";

import GuruEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { useComparisonMode } from "../context/ComparisonModeContext";
import { useProjectDescriptionContext } from "../context/ProjectDescriptionContext";

const ComparisonVersionContainer = () => {
  const { versionSelected, loading, compareDescriptionText } =
    useComparisonMode();
  const { onCopy } = useProjectDescriptionContext();
  const [comparisonEditorText, setComparisonEditorText] = useState("");
  const state = useSelector((state) => state.projectDescription);
  const monitoringState = useSelector((state) => state.projectMonitoring);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!compareDescriptionText) {
      setComparisonEditorText(compareDescriptionText);
    }
  }, [compareDescriptionText]);

  return (
    <Box
      style={{ width: monitoringState.isCompareMode ? "49%" : "0%", height: "100%" }}
      className="mx-1"
    >
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Card className="w-100 h-100 d-flex flex-column">
        <CardContent
          className="w-100 overflow-x-hidden overflow-y-auto custom-scrollBar p-1 "
          style={{ height: "calc(100% - 60px" }}
        >
          {!!comparisonEditorText?.description ? (
            <Box
              id={`box_project_description`}
              className="g-text-editor custom-scrollBar p-1"
              style={{
                minHeight: "calc(100% - 50px)",
                padding: 5,
                width: "100%",
                height: "calc(100% - 5px)",
              }}
              tabIndex={-1}
            >
              <CKEditor
                editor={GuruEditor.ClassicEditor}
                data={comparisonEditorText?.description}
                disabled={true}
              />
            </Box>
          ) : (
            <Box className="w-100 d-flex align-items-center justify-content-around h-100 g-btn-secondary rounded-3">
              {getTranslationVariantText(
                "_property not found",
                "subtitle 2",
                { fontSize: 14 },
                "p-1  mx-auto g-btn-secondary rounded-3"
              )}
            </Box>
          )}
        </CardContent>
        {!!versionSelected && state.versions.length > 0 && (
          <CardActions
            className="mt-auto w-100 p-0"
            style={{ height: 50, backgroundColor: "#c0c0c0" }}
          >
            <Autocomplete
              className="p-1 w-75"
              id="rerport_version"
              value={versionSelected.label}
              options={state.versions.filter(
                (f) => f.ident !== state.currentVersion.ident
              )}
              isOptionEqualToValue={(option, value) =>
                option.ident === value.ident
              }
              onChange={(e, newValue) => {
                dispatch(onSelectReportVersion(newValue));
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={getLabel("version")}
                  size="small"
                  style={{ color: "#ffffff", width: "100%" }}
                />
              )}
            />

            <CustomButton
              id="button-copy"
              buttonText={"_copy"}
              className={"g-btn-primary g-btn-elevated w-25"}
              style={{
                marginLeft: "auto",
                height: 40,
              }}
              variant="button"
              tooltip={{
                title: getIntlTranslation("tooltip.copy"),
              }}
              handleClick={(e) => onCopy(comparisonEditorText)}
            />
          </CardActions>
        )}
      </Card>
    </Box>
  );
};
export default ComparisonVersionContainer;
