import {
    AppBar,
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Chip,
    Collapse,
    Divider,
    FormHelperText,
    IconButton,
    List,
    ListItem,
    TextField,
    Toolbar,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import {
    getDialogBoxHeader,
    getIntlTranslation,
    getLabel,
    getTranslationVariantText,
  } from "../../../utils/helper";
  import CustomButton from "../../../components/buttons/CustomButton";
  import { useIntl } from "react-intl";
  import Rating from '@mui/material/Rating';
  import CloseIcon from "@mui/icons-material/Close";
import { useDocumentContextHook } from "../structure/DocumentBuilderContext";
import StarHalfIcon from "@mui/icons-material/StarHalf";

  const settingOptions = [
    {
      label: "_Name",
      type: "string",
      onChangeField: "title",
    },
    {
      label: "_translation",
      type: "header",
    },
    {
      label: "_German-Translation",
      type: "string",
      onChangeField: "deTranslation",
    },
    {
      label: "_English-Translation",
      type: "string",
      onChangeField: "enTranslation",
    },
    {
    label :"Maximum Rating",
    type: "number",
    onChangeField: "level",
  }
  ];

  export const TypeRatingsTreeRender = ({item}) => {
    const { selectedItem } = useDocumentContextHook();
    return ( <Box
        id={`box_${item.id}`}
        className="w-100 h-100 d-flex justify-content-start align-items-center"
      >
        <Typography variant="subtitle2" style={{
           color: selectedItem?.id === item.id ? "#ffffff" :"#254a9a",
           fontWeight: selectedItem?.id === item.id ? "bold" : "normal"
        }} className="p-1">{item?.settings?.title}</Typography>
        <Chip style={{marginLeft:5,backgroundColor:"#C1D0F0"}} label = {Number(item.settings.level)} size="small" icon={<StarHalfIcon style={{color:"#faaf00"}}/>} />
      </Box>)
  }

  export const TypeRatingsDropRender = ({item}) => {
    const { selectedItem } = useDocumentContextHook();
    return ( <Box
        id={`box_${item.id}`}
        className="w-100 h-100 d-flex justify-content-between align-items-center"
      >
        <Typography variant="subtitle2" style={{
           color: "#254a9a",
           fontWeight: "bold",
           width:"40%",
        }} className="p-1">{item?.settings?.title}</Typography>
         <Box className="d-flex align-items-center">
        <Rating name="half-rating-read" defaultValue={2.5} precision={0.5} max = {Number(item.settings.level)} readOnly />
        </Box>
      </Box>)
  }

  
  export const TypeRatingsSettings = ({item}) => {
    const intl = useIntl();
    const { editItem, setEditItem, saveSettings } = useDocumentContextHook();
    const [settings, setSettings] = useState({
      title: "",
      enTranslation: "",
      deTranslation: "",
      level:5,
    });
  
    useEffect(() => {
      if (!!editItem && !!editItem.id) {
        setSettings({
          title: editItem.settings.title,
          enTranslation: editItem.settings.translation?.en,
          deTranslation: editItem.settings.translation?.de,
          level: editItem.settings.level
        });
      }
    }, [editItem?.id]);
  
    const handleSave = () => {
      saveSettings(editItem, {
        ...editItem.settings,
        title: settings.title,
        translation: {
          en: settings.enTranslation,
          de: settings.deTranslation,
        },
        level:settings.level
      });
      setEditItem(null);
    };

    const renderInputField = (input) => {
      if (input.type === "number") {
        return (
          <TextField
            key={`name_${item.id}-${input.label}`}
            className="w-100 p-1"
            type={input.type}
            defaultValue={
              !!editItem
                ? settings[input.onChangeField]
                : item.settings.defaultTitle
            }
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                [input.onChangeField]: e.target.value,
              }))
            }
            size="small"
            helperText={!!input.hint ? input.hint : null}
          />
        );
      } else if (input.type === "checkbox") {
        return (<Typography variant="body1">
        <Checkbox
        key={`name_${item.id}-${input.label}`}
        checked={settings[input.onChangeField]}
        onChange={(e) =>
          setSettings((prev) => ({
            ...prev,
            [input.onChangeField]: e.target.checked,
          }))
        }
      />
      </Typography>)
  
      }
       else {
        return (
          <TextField
            key={`name_${item.id}-${input.label}`}
            className="w-100 p-1"
            multiline={true}
            maxRows={5}
            type={input.type || "text"}
            defaultValue={
              settings[input.onChangeField]
            }
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                [input.onChangeField]: e.target.value,
              }))
            }
            size="small"
            helperText={!!input.hint ? input.hint : null}
          />
        );
      }
    };
  
    return (
      <>
        <Collapse
        className="w-100 m-1 p-1"
        in={editItem?.id === item.id}
        timeout="auto"
        unmountOnExit
      >
        <Card className="w-100 p-1" style={{ backgroundColor: "#e6e8ed" }}>
        <AppBar
            style={{
              backgroundColor: "#d08f8e",
              position: "sticky",
              width: "98%",
              borderRadius: 4,
              marginLeft: 2,
              height: 40,
            }}
          >
            <Toolbar className={"dialog-toolbar"}>
            <Typography variant="body1">
                {editItem?.label}
              </Typography>
            <IconButton
              edge="start"
              color="inherit"
              id="create_project_close"
              onClick={() => setEditItem(null)}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
            </Toolbar>
          </AppBar>
            <CardContent
              className="custom-scrollBar m-1"
              style={{
                width: "98%",
                overflowY: "auto",
                overflowX: "hidden",
                padding: 2,
                height: "100%",
              }}
            >
              <List className="w-100 p-0">
              {settingOptions.map((o, index) => {
                if (o.type === "header") {
                  return (
                    <ListItem
                      className="w-100 p-0"
                      key={`option-${o.label}-${index}}`}
                    >
                      {getTranslationVariantText(o.label, "caption", {
                        fontWeight: "bold",
                        color: "#244a9a",
                        textAlign: "center",
                      })}
                    </ListItem>
                  );
                } else {
                  return (
                    <>
                      <ListItem
                        className="w-100 p-0 d-flex flex-row align-items-baseline m-1 flex-wrap"
                        key={`option-${o.label}-${index}}`}
                      >
                        <Box
                          style={{ textTransform: "capitalize", width: "80%" }}
                        >
                          {getLabel(o.label)} {o.optional && `(Optional)`}
                        </Box>
                        {renderInputField(o)}
                      </ListItem>
                    </>
                  );
                }
              })}
            </List>
            </CardContent>
            <CardActions style={{ marginTop: "auto" }}>
              <CustomButton
                id={`save_${item.id}`}
                buttonText={"_save"}
                variant="button"
                textVariant="body1"
                buttonColor={"inherit"}
                style={{
                  margin: 5,
                  height: 40,
                  marginLeft: "auto",
                  width: "100%",
                  padding: 5,
                }}
                className="g-btn-primary"
                tooltip={{
                  title: getIntlTranslation("tooltip.Save"),
                }}
                size="small"
                handleClick={(e) => handleSave()}
              />
            </CardActions>
            </Card>
      </Collapse>
      </>
    );
  };
  
  export const TypeRatingsPreviewRender = ({item}) => {
    return (
      <Box
      id={`box_${item.id}`}
      className="w-100 h-100 d-flex justify-content-between align-items-center"
    >
      <Typography style={{
         fontSize:11,
         color:"#254a9a",
         width:"40%",
      }} className="p-1">{item?.settings?.title}</Typography>
       <Box className="d-flex align-items-center">
      <Rating name="half-rating-read" defaultValue={2.5} precision={0.5} max = {Number(item.settings.level)} readOnly />
      </Box>
    </Box>
    )
  };