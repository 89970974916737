import React, { useEffect, useState } from "react";
import { database } from "../../../../../../index";
import { ref, set, onValue, off, get } from "firebase/database";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";

const useSaveDocumentMapFireBase = ({reportType}) => {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);

  const saveDocumentTreeToFirebase = async (tree, reportType) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const treePath = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/document-tree`;
      const treeRef = ref(database, treePath);
      try {
        await set(treeRef, tree);
      } catch (error) {
        console.error("Error adding comment: ", error);
      }
    } else {
      console.error("Error adding comment, Authentication Error");
    }
  };

  return { saveDocumentTreeToFirebase };
};

export default useSaveDocumentMapFireBase;
