import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import SegmentIcon from "@mui/icons-material/Segment";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AutoCompleteFreeSolo from "../../../common/auto-select-custom/AutoCompleteFreeSolo";
import { fetchPropertyLabelList } from "../../../api/suggestionApi";
import { decrypt, getIntlTranslation, getLabel, getLanguageCode, normalEditorConfig } from "../../../../../utils/helper";
import {resetState} from "../../../../component-bundle/suggestion-values/suggestionSlice"
import { CKEditor } from "@ckeditor/ckeditor5-react";
import GuruEditor from 'ckeditor5-custom-build/build/ckeditor';


const PropertyWithLabel = forwardRef((props, _ref) => {
  const { provided, node, data, currentData, remove } = props;
  const [activeIndex, setActiveIndex] = useState(true);
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const suggestionsState = useSelector((state) => state.suggestionSlice);
  const [droppedOptionData,setDroppedOptionData] = useState({
    text:"",
    editable:true,
    checked:false,
  })
  const [suggestionLabel, setsuggestionLabel] = useState([]);
  const [label, setlabel] = useState([]);
  const [selectedVal, setselectedVal] = useState(null);
  const editorRef = useRef();
 

  useEffect(() => {
    dispatch(fetchPropertyLabelList(scope.projectIdent));
  }, [dispatch, scope]);

  useEffect(() => {
    if (!!Object.keys(currentData).length) {
      setDroppedOptionData({
        text:!!currentData.defaultText ? currentData.defaultText :"",
        editable:currentData.editable === undefined ? true : currentData.editable,
        checked:currentData.userDefined  === undefined ? false :currentData.userDefined,
      })
      setActiveIndex(false);
      setselectedVal(
        currentData?.nameTranslation?.find(
          (f) => getLanguageCode(f.language) === scope.projectLanguageCode
        )
      );
    }
  }, [currentData, scope.projectLanguageCode]);

  useEffect(() => {
    if (
      suggestionsState.reportPropertyLabelList.type==="SUCCESS" &&
      !!suggestionsState.reportPropertyLabelList.data
    ) {
      const decrypted = decrypt(suggestionsState.reportPropertyLabelList.data);
      let data = JSON.parse(decrypted);
      if (!!selectedVal) {
        !data.some((i) => i.value === selectedVal.value) &&
          data.push(selectedVal);
      }
      setsuggestionLabel(data);
      dispatch(resetState());
    }
  }, [dispatch,selectedVal, suggestionsState.reportPropertyLabelList]);

  const handleChange = () => {
    setActiveIndex(!activeIndex);
  };

  const handleRemoveOption = () => {
    if (typeof remove === "function") {
      remove(node, data);
    }
  };


  useImperativeHandle(_ref, () => ({
    getNode: () => {
      return {
        ...node,
        ...data,
        userDefined: droppedOptionData.checked,
        defaultText: droppedOptionData.text,
        nameTranslation: label,
      };
    },
  }));

  return (
    <>
      <Card
        sx={{ width: "100%", marginTop: 1 }}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        raised={true}
      >
        <ListItem
          key={node.key}
          secondaryAction={
            <IconButton
              edge="end"
              component="span"
              onClick={(e) => handleChange()}
            >
              {activeIndex ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <ListItemIcon>
              <SegmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <AutoCompleteFreeSolo
                  editable={!droppedOptionData.editable}
                  list={suggestionLabel}
                  selectedVal={selectedVal}
                  onLabelSelect={(val) => setlabel(val)}
                />
              }
            />
          </ListItemButton>
        </ListItem>
        <Collapse in={activeIndex} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              sx={{
                pl: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid sx={{ width: "100%" }}>
                <ListItemText
                  primary={
                    <>
                      <Box style={{ display: "flex" }}>
                        <Typography
                          variant={"body2"}
                          style={{
                            width: "60%",
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginRight: "auto",
                          }}
                        >
                         {getLabel("_optional-deault-text")}
                        </Typography>

                        <FormGroup>
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={droppedOptionData.checked}
                                disabled={!droppedOptionData.editable}
                                onChange={(e) => setDroppedOptionData((prev) => ({...prev,checked:e.target.checked}))}
                              />
                            }
                            label={getLabel("_user-editable")}
                          />
                        </FormGroup>
                      </Box>
                    </>
                  }
                />
            <Box
              id={`box_${Date.now()}`}
              className="g-text-editor custom-scrollBar"
              style={{
                minHeight: "200px",
                padding: 0,
                width: "100%",
                height: "90%",
              }}
              tabIndex={-1}
            >
              <CKEditor
                id={`editor-${Date.now()}`}
                editor={GuruEditor.ClassicEditor}
                config={normalEditorConfig}
                disabled={!droppedOptionData.editable}
                data={droppedOptionData.text}
                onReady={(editor) => {
                  editorRef.current = editor;
                }}
                onBlur={(event, editor) => {
                    setDroppedOptionData((prev) => ({...prev, text:editor.getData()}))
                }}
              />
            </Box>
              </Grid>
              <Grid
                sx={{
                  width: "100%",
                  textAlign: "right",
                  marginTop: 2,
                }}
              >
                {droppedOptionData.editable && (
                  <Button
                    variant="outlined"
                    onClick={() => handleRemoveOption()}
                  >
                    {getIntlTranslation("_delete")}
                  </Button>
                )}
              </Grid>
            </ListItem>
          </List>
        </Collapse>
      </Card>
    </>
  );
});

export default PropertyWithLabel;
