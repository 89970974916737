/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavItem } from 'reactstrap';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { getRoutes } from '../../routes/appRouter';
import IntlMessages from '../../utils/IntlMessages';
import { Box, Typography } from '@mui/material';
import { addClasses, addAndRemoveClasses } from './containerActions';
import { addNewClassToContainer } from './containerActionSlice';
import { setProjectClose } from './containerSlice';
import { resetOpenAssetState } from '../../guru-suite/component-bundle/assetSwitcher/OpenAssetSlice';
import { resetState as resetReadOnly } from '../../guru-suite/component-bundle/slice/readOnlySlice';
import { decrypt } from '../../utils/helper';
import menuItems from '../nav-menu/globalNav';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const SideBar = (props) => {
  const dispatch = useDispatch();
  const actionState = useSelector((state) => state.containerActions);
  const auth = useSelector((state) => state.authentication);
  const container = useSelector((state) => state.container);
  const [foundRoutes, setFoundRoutes] = useState({});
  const [selectedParent, setSelectedParent] = useState({});
  const [selectedChild, setSelectedChild] = useState({});
  const location = useLocation();
  const [navItems, setNavItems] = useState([]);
  const currPath = location.pathname;
  const readOnlystate = useSelector((state) => state.readOnly);
  const sidebar = useSelector((state) => state.system);

  const routes = useMemo(() => getRoutes(), []);
  const [open, setOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);


  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    let timeoutId;

    const outerSidebar = document.getElementById('main-menu');
    const handleMouseEnter = () => {
      timeoutId = setTimeout(() => {
        setIsHovered(true);
      }, 500);
    };

    const handleMouseLeave = () => {
      clearTimeout(timeoutId);
      setIsHovered(false);
    };

    outerSidebar?.addEventListener('mouseenter', handleMouseEnter);
    outerSidebar?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      outerSidebar?.removeEventListener('mouseenter', handleMouseEnter);
      outerSidebar?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);


  useEffect(() => {
    let temp = [...menuItems];
    const tempRoute = routes.find((route) =>
      matchPath(
        {
          path: route.path,
          exact: route.exact,
        },
        currPath
      )
    );
    const found = temp.find((m) => {
      if (tempRoute?.isParent) {
        return m.mappedRoutes.some((p) => p.path === tempRoute?.path);
      } else if (m.children.length > 0) {
        return m.mappedRoutes.some((p) => p.path === tempRoute?.parentPath);
      } else {
        return m.mappedRoutes.some((p) => p.path === tempRoute?.path);
      }
    });
    setSelectedParent(found);
    setFoundRoutes(tempRoute);
  }, [currPath]);

  useEffect(() => {
    if (!!auth.user) {
      const decrypted = decrypt(auth.user);
      const user = JSON.parse(decrypted);
      let temp = [];
      menuItems.forEach((m) => {
        let found = m.roles.find((f) =>
          user.data?.roles.some((s) => s.toLowerCase() === f.toLowerCase())
        );
        if (!!found) temp.push(m);
      });
      setNavItems(temp);
    }
  }, [auth.user]);

  const openSelectedParentMenu = (e, node) => {
    const selectedParent = node;
    const hasChildren = node.children && node.children.length > 0;
    const { containerClassnames } = actionState;
    const currentClasses = containerClassnames;
    if (!sidebar.leaving) {
      if (container.scope.isProjectScope) {
        dispatch(setProjectClose());
        dispatch(resetOpenAssetState());
        dispatch(resetReadOnly());
      }
    }
    if (!hasChildren) {
      setSelectedParent(selectedParent);
      if (!currentClasses.includes('menu-mobile')) {
        if (currentClasses.includes('sub-show-temporary')) {
          let payload = addAndRemoveClasses(
            'menu-sub-hidden',
            containerClassnames,
            'sub-show-temporary'
          );
          dispatch(addNewClassToContainer(payload));
        }
      } else {
        if (!currentClasses.includes('menu-mobile')) {
          if (!currentClasses.includes('sub-show-temporary')) {
            let payload = addClasses('sub-show-temporary', containerClassnames);
            dispatch(addNewClassToContainer(payload));
          }
        } else {
          let payload = addClasses('sub-show-temporary', containerClassnames);
          dispatch(addNewClassToContainer(payload));
        }
        setSelectedParent(node);
      }
    }
  };

  const saveUserSelection = (item) => {
    setSelectedChild(item);
  };

  const createChildNavItems = (item, index) => {
    if (item.tag && !item.withName) {
      return (
        <NavItem
          key={`${item.id}_${index}`}
          className={'g-nav-tag-no-name w-100'}
        >
          <Typography
            variant="body2"
            className={'nav-title-no-name'}
          ></Typography>
        </NavItem>
      );
    } else if (item.tag) {
      return (
        <NavItem
          key={`${item.id}_${index}`}
          id={`${item.id}_${index}`}
          className={'g-nav-tag w-100'}
        >
          <Typography variant="body2" className={'nav-title'}>
            <IntlMessages id={item.label} />
          </Typography>
        </NavItem>
      );
    } else {
      const found = foundRoutes?.children?.find(
        (f) => f.path === location.pathname
      );
      return (
        <NavItem key={`${item.id}_${index}`} id={`${item.id}_${index}`}>
          <NavLink
            to={item.to}
            data-flag={item.id}
            onClick={(e) => saveUserSelection(item)}
            className={`${
              !!found && found.id === item.id ? 'active' : ''
            } w-100`}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: 40 }}>{item.icon}</div>
              <Typography
                variant="body2"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <IntlMessages id={item.label} />
              </Typography>
            </div>
          </NavLink>
        </NavItem>
      );
    }
  };
  function createChildNav(item, index) {
    if (!item.renderScope) {
      if (
        container.scope[item.renderScopeCondition.key] &&
        item.renderScopeCondition.serviceLine === container.scope.serviceLine
      ) {
        return createChildNavItems(item, index);
      } else {
        return null;
      }
    } else {
      return createChildNavItems(item, index);
    }
  }

  const getActiveClassName = (item) => {
    if (!!foundRoutes && foundRoutes?.isParent) {
      return item.mappedRoutes.some((s) => s.path === foundRoutes?.path)
        ? 'active w-100'
        : 'w-100';
    } else if (!!foundRoutes?.parentPath) {
      return item.mappedRoutes.some((s) => s.path === foundRoutes?.parentPath)
        ? 'active w-100'
        : 'w-100';
    } else {
      return item.mappedRoutes.some((s) => s.path === foundRoutes?.path)
        ? 'active w-100'
        : 'w-100';
    }
  };

  return (
    <>
      <div id="main-menu" className={`main-menu custom-scrollBar sidebar ${isHovered ? 'sidebar-expanded' : ''}`}>
        <div
          style={{ height: '95%' }}
          className="custom-scrollBar main-menu-nav w-100"
        >
          <Nav vertical className="list-unstyled w-100">
            {navItems &&
              navItems.map((item) => {
                return (
                  <>
                    <NavItem
                      key={item.id}
                      id={item.id}
                      className={getActiveClassName(item)}
                    >
                      <NavLink
                        to={item.to}
                        onClick={(e) => openSelectedParentMenu(e, item)}
                        data-flag={item.id}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div style={{ width: 35 }}>{item.icon}</div>
                          <Typography
                            variant="body2 menu-text"
                            style={{ marginLeft: 5, marginRight: 5 }}
                          >
                            <IntlMessages id={item.label} />
                          </Typography>
                        </div>
                      </NavLink>
                    </NavItem>
                  </>
                );
              })}
          </Nav>
        </div>
      </div>
      {!!selectedParent?.children &&
        selectedParent?.children.length > 0 &&
        selectedParent?.children?.some(
          (s) => s.parentId === selectedParent.id
        ) && (
          <>
            <Box
              className="sub-menu"
              style={{
                width: open ? '180px' : '55px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* <Box className="w-100" style={{ marginRight:5, height:20}}>
          <DoubleArrowIcon/>
            </Box> */}
              <Box
                className="sub-menu-nav w-100 custom-scrollBar"
                style={{ height: 'calc(100% - 30px)' }}
              >
                <Nav vertical className="list-unstyled w-100">
                  {readOnlystate.isReadOnly
                    ? selectedParent.children
                        .filter((item) => item.defaultAccess)
                        .map((item, index) => createChildNav(item, index))
                    : selectedParent.children.map((item, index) => createChildNav(item, index)
                      )}
                </Nav>
              </Box>
            </Box>
          </>
        )}
    </>
  );
};

export default SideBar;
