import React, { useEffect, useState } from "react";
import { ref, onValue, off } from "firebase/database";
import { database } from "../../../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CardContent,
  ClickAwayListener,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import { RenderComments } from "../report-component/comments/CommentsRenderer";
import {
  getTranslationVariantText,
  splitArray,
} from "../../../../utils/helper";
import { useDocumentTreeContext } from "../report-component/comments/DocumentTreeContext";
import EmailContainer from "../report-component/comments/EmailContainer";
import {
  markReviewChapterComplete,
  sendReviewEmail,
} from "../../api/reviewApi";
import InputSearch from "../../../../components/InputSearch";
import SearchIcon from '@mui/icons-material/Search';



const ReviewCommentsContainer = ({ reportType, selectedComment }) => {
  const [comments, setComments] = useState({
    data: [],
    open: 0,
    closed: 0,
  });
  const [checked, setChecked] = useState(false);
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const [filteredData, setFilteredData] = useState([]);
  const [filterApplied, setFilterApplied] = useState({
    type:null,
  })
  const { documentTree } = useDocumentTreeContext();

  const dispatch = useDispatch();

  function collectComments(node, comments) {
    const matchedComments = comments.filter(
      (comment) => comment.parentPath === node.path
    );
    let commentList = [
      ...matchedComments.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB - dateA;
      }),
    ];
    if (node.children) {
      node.children.forEach((child) => {
        const childComments = collectComments(child, comments);
        commentList = [...commentList, ...childComments];
      });
    }
    return commentList;
  }


  useEffect(() => {
    if (
      !!documentTree && documentTree.length > 0 &&
      documentTree.length ===
        documentTree.filter((f) => f.reviewComplete).length
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [documentTree]);

  useEffect(() => {
    if (!!documentTree && documentTree.length > 0) {
      const commentPath = `projects/${scope.projectIdent}/assets/${openAsset.ident}/reports/${reportType}/sections`;
      const commentsRef = ref(database, commentPath);
      // Listen for data updates
      onValue(commentsRef, (snapshot) => {
        const val = snapshot.val();
        let temp = [];
        for (const key in val) {
          temp.push(val[key].comments);
        }
        const orderedComments = documentTree?.map((node) =>
          collectComments(node, temp.flat(1))
        );
        const finalComments = orderedComments.flat(1).filter((f) => f.assetIdent === openAsset.ident);
        const [closed, open] = splitArray(
          finalComments.flat(1),
          (c) => c.resolved
        );
        setComments({
          data: finalComments,
          open: open?.length || 0,
          closed: closed?.length || 0,
        });
        setFilteredData({
          data: finalComments,
          open: open?.length || 0,
          closed: closed?.length || 0,
        });
      });
      return () => off(commentsRef);
    }
  }, [documentTree, openAsset, scope.projectIdent]);

  const handleSearch = (type,search) => {
    if(type === "open"){
      setFilteredData((prev) => {
        return {
          ...prev,
          data: comments.data.filter((item) => !item.resolved),
        };
      })
    }
    else if(type === "resolved"){
      setFilteredData((prev) => {
        return {
          ...prev,
          data: comments.data.filter((item) => item.resolved),
        };
      })
    } else if(type=== "reset") {
      setFilteredData(comments)
    }
    else {
      search
      ? setFilteredData((prev) => {
          return {
            ...prev,
            data: comments.data.filter((item) =>
              item.value.toLowerCase().includes(search.toLowerCase())
            ),
          };
        })
      : setFilteredData(comments);
    }
  
  };

  const sendEmail = (data) => {
    dispatch(
      sendReviewEmail({
        ...data,
        projectName: scope.projectName,
        projectIdent: scope.projectIdent,
        assetIdent: openAsset.ident,
        assetName: openAsset.assetName,
        reportType:
          reportType?.toUpperCase() === "REDFLAG" ? "RED FLAG" : "FULL REPORT",
      })
    );
  };

  const markReviewCompleted = (e) => {
    dispatch(
      markReviewChapterComplete({
        api: scope.api,
        projectIdent: scope.projectIdent,
        assetIdent: openAsset.ident,
        value: e.target.checked,
        reportType:
          reportType?.toUpperCase() === "REDFLAG" ? "RED FLAG" : "FULL REPORT",
      })
    );
  };

  const ReviewBox = () => {
    return(
      <Box
      className="d-flex flex-column p-0 g-btn-secondary rounded-3"
      style={{ position:"absolute", bottom:5, width: "25%" , overflow: "hidden", zIndex: 1501, }}
    >
      <Box className="w-100 p-1 m-0">
        <EmailContainer onSend={(data) => sendEmail(data)} />
      </Box>
      {/* <Box className="w-100 p-0 m-1">
        <FormControlLabel
         className="w-100 p-1 mt-1"
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
                markReviewCompleted(e);
              }}
              className="p-1"
            />
          }
          label={getTranslationVariantText("_markComplete", "caption", {
            fontWeight: "bold",
            fontSize:12,
          })}
          style={{height:20}}
        />
      </Box> */}
    </Box>
    )

  }
  
  return (
    <>
      <CardContent className="w-100 p-0 h-100" style={{ overflow: "hidden", backgroundColor: "#a3a3a326" }}>
        <Box className="w-100 d-flex flex-column align-items-center h-100" style={{backgroundColor: "#a3a3a326" }}>
          {comments.data.length > 0 ? (
            <>
            <Box
      style={{
        width: "98%",
        position: "sticky",
        top: 0,
        zIndex: 1500,
        height: 50,
        backgroundColor: "#ffffff",
      }}
      className="d-flex flex-column align-items-center justify-content-start p-1 m-1 rounded-3"
    >
      <Box
        style={{ height: 40}}
        className="d-flex align-items-center w-100"
      >
        <InputSearch onFilterInput={(input) => handleSearch("normal",input)} />
      </Box>
      </Box>            
            <RenderComments
              comments={filteredData}
              selectedComment = {selectedComment}
              handleSearch={handleSearch}
              filterApplied={filterApplied}
              setFilterApplied={setFilterApplied}
            />
          </>
          ) : (
            <Box className="w-100 d-flex p-1 m-1 align-items-center">
              <span className="p-1 m-1">
                {getTranslationVariantText("_no-comments-create", "caption", {
                  fontSize: 14,
                  fontWeight: "bold",
                })}
                <CommentIcon
                  style={{ fontSize: 25, color: "#d08f8e" }}
                  className="m-1"
                />
              </span>
            </Box>
          )}
        </Box>
        <ReviewBox/>
      </CardContent>
     
    </>
  );
};

ReviewCommentsContainer.propTypes = {};

export default ReviewCommentsContainer;
