import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import { useDebouncedCallback } from 'use-debounce';
import { useIntl } from "react-intl";
import CancelIcon from '@mui/icons-material/Cancel';
import { InputAdornment, IconButton } from "@mui/material";


const InputSearch = ({onFilterInput}) => {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedHandleOnChange = useDebouncedCallback(
    (value) => {
      if(typeof onFilterInput === "function"){
        onFilterInput(value);
      }
    },
    500
  );

  return (
    <Paper
      className="d-flex align-items-center w-100 h-100"
      style={{ padding: "2px 4px",backgroundColor: "#5a535317"}}
    >
    <Box  className="d-flex align-items-center w-100 h-100">
      <InputBase
        sx={{ ml: 2, flex: 1, width:"70%" }}
        placeholder={intl
          .formatMessage({ id: "_search" })
          .toLowerCase()}
        inputProps={
          { "aria-label": "search" }
        }
        value={searchQuery}
        onChange={(e) => {
          const value = e.target.value; 
          setSearchQuery(value); 
          debouncedHandleOnChange(value.toUpperCase()); //
        }}
        endAdornment={
          searchQuery ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search"
                onClick={() =>{
                  setSearchQuery("")
                  debouncedHandleOnChange("");
                } }
                edge="end"
              >
               <CancelIcon style={{fontSize:20, margin:4, color:"#254a9a"}}/>
              </IconButton>
            </InputAdornment>
          ) :  <InputAdornment position="end">
            <SearchIcon style={{margin:4, color:"#254a9a"}}/>
            </InputAdornment>
        }
      />
      
      </Box>
    </Paper>
  );
};

export default InputSearch;
