
import React, { createContext,useCallback,useContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import useReorder from "../../../../component-bundle/hooks/useReorderHook";
import { ComparisonModeProvider } from "./ComparisonModeContext";
import { useKeyFindingsContext } from "./KeyFindingsContext";
import { SelectedPropertyProvider } from "./SelectedPropertyContext";


const CategoriesDataContext = createContext();

export const useCategoriesData = () => useContext(CategoriesDataContext);

export const CategoriesDataProvider = ({ children, moduleApi }) => {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const [reorderResponse, onReorder, onResetResponse] = useReorder();
  const [reorder, setReorder] = useState({
    type:"",
    isReorder:false,
  });
  const { categories, setCategories } = useKeyFindingsContext();
  const [structuredCategories, setStructuredCategories] = useState({
    nodes: {},
  });

  const normalizeCategories = useCallback((categories,  language) => {
    const data = {
      nodes: {}
    };
  
    [...categories]
      .sort((a, b) => a.nodeOrder - b.nodeOrder)
      .filter((f) =>!!f.nodeName)
      .forEach((node) => {
        const nodeId = node.nodeIdent;
        const labelTranslation = node.labelTranslation.reduce((acc, item) => {
          acc[item.language] = { value: item.value, title: item.title };
          return acc;
        }, {});
  
        let tempProperties = {};

        [...node.properties].sort((a, b) => a.nodeOrder - b.nodeOrder)
        .forEach((property) => {
          const nodeId = property.nodeIdent;
          const labelTranslation = property.labelTranslation.reduce((acc, item) => {
            acc[item.language] = { value: item.value, title: item.title };
            return acc;
          }, {});
          let tempDataNodes = {};
          property.mapProperties.forEach((sub) => {
            tempDataNodes[sub.nodeIdent] = {
              ...sub, original: {...sub},
            };
          });
          tempProperties[nodeId] = {
            ...property,
            nodeTitle: labelTranslation[language].title,
          labelTranslation: labelTranslation,
          original: property,
          dataNodes: {...tempDataNodes},
          };
        });

        
  
        data.nodes[nodeId] = {
          ...node,
          nodeTitle: labelTranslation[language].title,
          labelTranslation: labelTranslation,
          original: node,
          properties: {...tempProperties},
        };
      });
    return data;
  },[]);


  useEffect(() => {
    if (categories.length > 0) {
      setStructuredCategories(
        normalizeCategories(
          categories,
          scope.projectLanguageCode
        )
      );
    }else{
      setStructuredCategories({
        nodes:{}
      })
    }
  }, [dispatch, normalizeCategories, categories, scope.projectLanguageCode,]);

  useEffect(() => {
    if (reorderResponse.type === "SAVED" && reorderResponse.reorderOf.type ==="category") {
      setCategories(reorderResponse.data);
      setStructuredCategories(
        normalizeCategories(
          reorderResponse.data,
          scope.projectLanguageCode
        )
      );
      setReorder({
        type:"category",
        isReorder: true,
      })
      onResetResponse();
    }
    if(reorderResponse.type === "SAVED" && reorderResponse.reorderOf.type ==="property"){
      setStructuredCategories((prev) => {
        let tempObj = {...prev}
        let tempCategory = categories.find((f) => f.nodeIdent === reorderResponse.reorderOf.parentIdent);
        if(!!tempCategory){
          const reOrderedData  = normalizeCategories(
            [{...tempCategory, properties:reorderResponse.data}],
            scope.projectLanguageCode
          )
          tempObj.nodes[tempCategory.nodeIdent] = reOrderedData.nodes[tempCategory.nodeIdent];
        }
        return tempObj;
      }
        );
        setReorder({
          type:"property",
          isReorder: true,
        })
      onResetResponse();
    }
  }, [categories, normalizeCategories, onResetResponse, reorderResponse, scope.projectLanguageCode, setCategories]);

  const handleReorder = (items, type) => {
    const reorder = items.map((item, index) => ({
      ...item.original,
      nodeOrder: index + 1,
    }));
    onReorder({
      reorderApi: `${moduleApi}/${type}`,
      data: reorder,
      reorderOf:{
        type:type
      },
    });
  };

  const handlePropertiesReorder = (data, type) => {
    const reorder = data.items.map((item, index) => ({
      ...item.original,
      nodeOrder: index + 1,
    }));
    onReorder({
      reorderApi: `${moduleApi}/${type}`,
      data: reorder,
      reorderOf:{
        parentIdent:data.parentIdent,
        type:type
      },
    });
  };

  
  return (
    <CategoriesDataContext.Provider
      value={{
        structuredCategories,
        reorder, setReorder,
        handleReorder,
        handlePropertiesReorder,
        normalizeCategories,
      }}
    >
      <SelectedPropertyProvider>
        <ComparisonModeProvider>
            {children}
        </ComparisonModeProvider>
      </SelectedPropertyProvider>
    </CategoriesDataContext.Provider>
  );
};
