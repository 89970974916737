import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Grow from "@mui/material/Grow";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { data } from "autoprefixer";
import { getInputUtilityClass } from "@mui/material";
import { getIntlTranslation } from "../../../../../utils/helper";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex, inspection) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  const newItems = result.map((m, index) => {
    const category = {
      type: "inspectionHeadersCategory",
      category: {
        type: "valueAndIdentDto",
        value: inspection.tab.value,
        ident: inspection.tab.ident,
      },
      headerOrder: index + 1,
    };
    const data = {
      ...m,
      categoryList: [category],
      updated: m.order !== index + 1,
      order: index + 1,
    };
    return data;
  });
  return newItems;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, deleted,frozen) => ({
  userSelect: "none",
  padding: grid * 1,
  margin: `0 0 ${grid}px 0`,
  background: frozen? "#244a9a": isDragging ? "lightgreen" : "#dbdbdb",
  textDecoration: deleted && "line-through",
  width: "100%",
  border: isDragging ? "2px dashed blue" : "0px",
  borderStyle: "dashed",
  color: frozen? "white" :isDragging ? "black" : "black",
  // styles we need to apply on draggables
  ...draggableStyle,
});
const numStyle = (frozen) => ({
  color: frozen? "white" : "black",

});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver && "#cedced",
  width: "100%",
  border: isDraggingOver ? "2px dashed blue" : "0px",
  borderStyle: "dashed",
});

const ReorderHeaders = ({ headerList, updateHeaders }) => {
  const inspection = useSelector((state) => state.tddInspection);

  const [items, setItems] = useState([...headerList]);
  const [newValue, setNewValue] = useState("");
  const [editeHeader, setEditeHeader] = useState({
    ident: "",
    name: "",
  });

  useEffect(() => {
    const newList = headerList
      .filter((e) =>
        e.categoryList.find((f) => f.category.ident === inspection.tab.ident)
      )
      .map((m) => {
        let obj = { ...m };
        let found = m.categoryList.find(
          (o) => o.category.ident === inspection.tab.ident
        );
        return { ...obj, order: found?.headerOrder };
      })
      .sort(function (x, y) {
        return x.frozen === y.frozen ? x.order - y.order : x.frozen ? -1 : 1;
      });
    setItems(newList);
  }, [inspection.tab.ident, headerList]);

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index <= 2) {
      return;
    }
    const reoder = reorder(
      items,
      result.source.index,
      result.destination.index,
      inspection
    );
    setItems(reoder);
    updateHeaders(reoder);
  };
  const handleChange = (value) => {
    setNewValue(value);
  };
  const cancelUpdate = (item) => {
    setNewValue(item);
    setEditeHeader({
      ident: "",
      name: "",
    });
  };
  const startUpdate = (item) => {
    setNewValue(item.name);
    setEditeHeader({
      ident: item.headerIdent,
      name: item.name,
    });
  };
  const handleSaveUpdate = (item, value) => {
    const newHeaderList = items.map((p) =>
      p.name === item.name ? { ...p, name: value, updated: true } : p
    );
    setItems(newHeaderList);
    updateHeaders(newHeaderList);
    setEditeHeader({
      ident: "",
      name: "",
    });
  };
  const handleDelete = (item) => {
    if (!!item.headerIdent) {
      const newArr = items.map((obj) => {
        if (obj.name === item.name) {
          return { ...obj, deleted: true, updated: true };
        }
        return obj;
      });
      setItems(newArr);
      updateHeaders(newArr);
    } else {
      const newArr = items.filter((obj) => {
        return obj.name !== item.name;
      });
      setItems(newArr);
      updateHeaders(newArr);
    }
  };
  return (
    <>
      <div style={{ width: "50%" }}>
        <p
          style={{
            fontWeight: 600,
            letterSpacing: "0.008em",
            fontSize: 15,
          }}
        >
          {getIntlTranslation("_Drag and drop header")}
        </p>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={(e) =>{}}
        >
          <Droppable droppableId="droppable_header">
            {(provided, snapshot) => (
              <List
                className="grid_Header"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <Grid container>
                  {items.map((item, index) => {
                    return (
                      <Draggable
                        key={item.name}
                        draggableId={item.name}
                        index={index}
                        isDragDisabled={item.frozen}
                      >
                        {(provided, snapshot) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              item.deleted,
                              item.frozen
                            )}
                          >
                            <ListItemIcon
                            style={numStyle(
                              item.frozen
                            )}
                            >{item.order}</ListItemIcon>
                            {item.headerIdent === editeHeader.ident ||
                            item.name === editeHeader.name ? (
                              <Grow in={true}>
                                <TextField
                                  style={{ width: "70%" }}
                                  label={getIntlTranslation("_Header Name")}
                                  value={newValue}
                                  onChange={(e) => handleChange(e.target.value)}
                                  InputProps={{
                                    endAdornment: (
                                      <Grid style={{ display: "flex" }}>
                                        <SaveIcon
                                          color="primary"
                                          onClick={() =>
                                            handleSaveUpdate(item, newValue)
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                        <CancelIcon
                                          color="secondary"
                                          onClick={() =>
                                            cancelUpdate(item.name)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 4,
                                          }}
                                        />
                                      </Grid>
                                    ),
                                  }}
                                />
                              </Grow>
                            ) : (
                              <ListItemText primary={item.name} />
                            )}
                            {item.custom && (
                              <Grid style={{ marginLeft: 10 }}>
                                <EditIcon
                                  onClick={() => startUpdate(item)}
                                  style={{
                                    marginRight: 5,
                                    fontSize: 25,
                                    color: "white",
                                    backgroundColor: "#005194",
                                    padding: 5,
                                    borderRadius: 50,
                                  }}
                                />
                                <CancelIcon
                                  onClick={() => handleDelete(item)}
                                  style={{
                                    marginRight: 5,
                                    fontSize: 25,
                                    color: "red",
                                    backgroundColor: "white",
                                    borderRadius: 50,
                                  }}
                                />
                              </Grid>
                            )}
                          </ListItem>
                        )}
                      </Draggable>
                    );
                  })}
                </Grid>
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default ReorderHeaders;
