import React from 'react'
import { useNavigate } from "react-router-dom";
import {
    Box,
    CardMedia,
  } from "@mui/material";
  import {
    getTranslationVariantText,
    getIntlTranslation,
  } from "../../utils/helper";
  import CustomButton from "../../components/buttons/CustomButton";
import { useSelector } from 'react-redux';
import { getServiceLine } from '../../utils/defaultValues';

function NoAssets() {
    const navigate = useNavigate();
    const scope = useSelector((state) => state.container.scope);
    
    const handleRedirectToAsset = () => {
      const found = getServiceLine(scope.serviceLine);
      if(!!found) {
        navigate(`${found.root}/project/assets-overview`)
      }
    }
  return (
        <>
          <Box
            className="d-flex align-items-center justify-content-center w-100 h-100 flex-column"
            style={{ padding: 5 }}
          >
            <>
              <Box
                style={{ width: "25%" }}
                className={"d-flex align-items-center justify-content-center"}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 250 }}
                  image="/assets/images/no_data_3.svg"
                  alt="Live from space album cover"
                />
              </Box>
              <Box style={{ width: "70%" }}>
                <div
                  className={"d-flex align-items-center justify-content-center"}
                  style={{ margin: 10 }}
                >
                  {getTranslationVariantText(
                    "no_asset_found_click_start",
                    "subtitle1",
                    {
                      color: "#d08f8e",
                      fontWeight: "bold",
                      margin: 5,
                    }
                  )}
                </div>
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <CustomButton
                    id={`create_data_properties_01`}
                    buttonText={"_redirect-to-asset-overview"}
                    className="g-btn-secondary btn"
                    style={{
                      margin: 5,
                      padding: 10,
                      width: "55%",
                      justifyContent: "center",
                      textTransform: "capitalize",
                      height: 30,
                    }}
                    tooltip={{
                      title: getIntlTranslation(
                        "tooltip.redirect-to-asset-overview"
                      ),
                    }}
                    size="small"
                    variant="button"
                    handleClick={(e) => handleRedirectToAsset()}
                  />
                </div>
              </Box>
            </>
          </Box>
        </>
  )
}

NoAssets.propTypes = {}

export default NoAssets
