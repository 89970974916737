import { createSlice } from "@reduxjs/toolkit";
import {response} from "../../../utils/defaultValues"
import { encrypt} from "../../../utils/helper";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  data:"",
  buildingType:{
    ...response
   },
   subCategory:{
    ...response
   },
   category:{
    ...response
   },
   subAssestType:{
    ...response
   },
   reportType:{
    ...response
   },
   reportPropertyLabelList:{
    ...response
   },
  error: {
    ...response
  },
};
// A slice for auth with our success or failure reducers
const suggestionSlice = createSlice({
  name: "suggestionSlice",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
    }),
    getBuildingType: (state, { payload }) => ({
      ...state,
      buildingType:{
        type: payload.type,
        messages: payload.messages,
        data: payload.guruDtoList,
      }
    }),
    getSubCategoryFetchListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      subCategory:{
        type: payload.type,
        messages: payload.messages,
        data:encrypt(payload.guruDto.suggestionvaluesList)
      }
    }),
    getCategoryFetchListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      category: {
        type: payload.type,
        messages: payload.messages,
        data: encrypt(payload.guruDto.suggestionvaluesList)
      }
    }),
    getSubAssetTypeListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      data: {
        subAsset_type_sv: encrypt(payload.guruDto.svTitles) ,
      },
    }),
    getElementTypeListSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      subAssetType:{
        type: payload.type,
        messages: payload.messages,
        data:encrypt(payload.guruDto.suggestionvaluesList)
      }
    }),
      getReportsPropertyLabel: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      reportPropertyLabelList:{
        type: payload.type,
        messages: payload.messages,
        data:encrypt(payload.guruDto.svTitles)
      }
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getBuildingType,
  resetState,
  getFailure,
  getSubCategoryFetchListSuccess,
  getCategoryFetchListSuccess,
  getSubAssetTypeListSuccess,
  getElementTypeListSuccess,
  getReportsPropertyLabel,
} = suggestionSlice.actions;

// A selector
export const suggestionSliceSelecteor = (state) =>
  state.suggestions;

// The reducer
export default suggestionSlice.reducer;
