import React, { useState } from "react";
import { Grid } from "@mui/material";

import CustomizedTreeView from "./CustomizedTreeView";



const CostOveriewContainer = (props) => {
  const [switchTree, setSwitchTree] = useState("view");

  return (
        <Grid
          style={{
            width: "100%",
            minWidth: "700px",
            backgroundColor: "white",
            minHeight: "85vh",
            height: "85vh",
          }}
        >
            <CustomizedTreeView
              switchTree={switchTree}
              setSwitchTree={setSwitchTree}
            />
        </Grid>
  );
};

CostOveriewContainer.propTypes = {};

export default CostOveriewContainer;
