import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { Card, Grid, Button, Divider, ListItemButton } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useSnackbar } from "notistack";
import { resetOpenProjectState } from "../../../component-bundle/project-dashboard/projectDashboardSlice";
import { useNavigate } from "react-router-dom";
import { setProjectClose } from "../../../../layout/app-container/containerSlice";
import {
  fetchAssetList,
  getAssetTemplate,
  updateAsset,
} from "../../../../api/assetOverviewApi";
import { getProject } from "../../../../api/openProjectApi";
import AssetDialogSelection from "./asset/AssetDialogSelection";
import { resetSubAssetState } from "../sub-asset-management/dataTreeImports";
import { setOpenAsset, resetOpenAssetState } from "./asset/OpenAssetSlice";
import { resetState } from "../../../component-bundle/asset-overview/assetOverviewSlice";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as XLSX from "xlsx";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadIcon from "@mui/icons-material/Download";
import AlertDialogSlide from "../../../../common/alertDialogConfirmation";
import CustomButton from "../../../../components/buttons/CustomButton";
import FileButton from "../../../../components/buttons/FileButton";
import FileDownloadButton from "../../../../components/buttons/FileDownloadButton";
import EditIconButton from "../../../../components/buttons/icons-buttons/EditIconButton";
import DeleteIconButton from "../../../../components/buttons/icons-buttons/DeleteIconButton";
import SwitchButton from "../../../../components/buttons/SwitchButton";
import ConstructionIcon from "@mui/icons-material/Construction";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import Box from "@mui/material/Box";
import {
  getIntlTranslation,
  getLabel,
  getTranslationVariantText,
  getVariantText,
} from "../../../../utils/helper";
import CustomIconButton from "../../../../components/buttons/icons-buttons/CustomIconButton";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ReportGenerationDialog from "../../../component-bundle/generate-report/ReportGenerationDialog";
import {
  capex,
  capexPortfolioDetail,
  capexPortfolioSummary,
  redFlag,
  fullReport,
  photoDocumentation,
} from "../../../../utils/defaultValues";

const AssetsOverview = () => {
  const prjState = useSelector((state) => state.projectDashboard.project);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scope = useSelector((state) => state.container.scope);
  const scopeLanguage = useSelector(
    (state) => state.container.scope.projectLanguageCode
  );
  const overviewState = useSelector((state) => state.assetOverview);
  const subassetsState = useSelector((state) => state.subasset);
  const [fullReportFlag, setfullReportFlag] = useState({
    isStart: false,
    asset: null,
  });
  const [assetList, setAssetList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [projectPermission, setProjectPermission] = useState({
    update: false,
    read: false,
    create: false,
    delete: false,
  });
  const [dialogScope, setDialogScope] = useState({
    openDialog: false,
    openEditAssetDialog: false,
    openEditSubAssetDialog: false,
    openPortFolioAddAssetDialog: false,
    openAddAssetDialog: false,
    openDeleteAlert: false,
    imageUpload: false,
    openAddSubAssetDialog: false,
    node: {},
    openUserManagement: false,
  });

  const getReportOptions = (asset) => {
    return [
      capex,
      capexPortfolioDetail,
      capexPortfolioSummary,
      redFlag,
      { ...fullReport, disabled: !asset.beginFullReport },
      photoDocumentation,
    ];
  };
  const getSelectedData = useCallback(
    (asset) => {
      let found = assetList?.find((a) => a.assetIdent === asset.assetIdent);
      if (!!found) {
        return {
          name: found.name,
          ident: found.assetIdent,
          isParent:true,
          parentIdent:found.assetIdent,
          users: found?.userDtos?.map((u) => {
            return {
              label: `${u.firstName} ${u.lastName}`,
              value: u.emailAddress,
            };
          }),
          projectIdent: scope.projectIdent,
        };
      }
      return null;
    },
    [assetList]
  );

  useEffect(() => {
    if (prjState?.type === "ERROR" || prjState?.type === "ACCESS_DENIED") {
      dispatch(setProjectClose());
      dispatch(resetOpenProjectState());
      navigate("/tdd/projects", {
        error: prjState.messages,
        isProjectScope: false,
      });
    }
    if (prjState?.type === "FETCHED") {
      if (prjState.data.currentUserPermissionsDto !== null) {
        setProjectPermission(prjState.data.currentUserPermissionsDto);
      }
    }
  }, [dispatch, prjState]);

  useEffect(() => {
    if (scope.projectIdent !== null && scope.projectIdent !== "") {
      const requestData = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(requestData));
      dispatch(getProject(requestData));
      dispatch(resetOpenAssetState());
    }
  }, [dispatch, scope]);

  useEffect(() => {
    if (
      scope.projectIdent !== null &&
      scope.projectIdent !== "" &&
      overviewState.create.type === "SAVED"
    ) {
      const requestData = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(requestData));
      dispatch(resetOpenAssetState());
      dispatch(resetState());
    }
  }, [dispatch, overviewState.create.type, scope.api, scope.projectIdent]);

  //assets
  useEffect(() => {
    if (overviewState.error.type === "ERROR" && overviewState.hasErrors) {
      overviewState.error.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          persist: false,
          preventDuplicate: true,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
      dispatch(resetState());
    }
    if (overviewState.deleteAsset.type === "SUCCESS") {
      overviewState.deleteAsset.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      const requestData = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(requestData));
      dispatch(resetState());
    }
    if (overviewState.create.type === "UPDATED") {
      overviewState.create.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      const requestData = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(requestData));
      dispatch(resetState());
    }
    if (overviewState.fetchAsset.type === "ERROR") {
      overviewState.fetchAsset.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          persist: false,
          preventDuplicate: true,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
      dispatch(resetState());
    }
  }, [
    dispatch,
    enqueueSnackbar,
    overviewState.create,
    overviewState.deleteAsset,
    overviewState.error,
    overviewState.fetchAsset,
    scope.api,
    scope.projectIdent,
  ]);

  // sub asset related
  useEffect(() => {
    if (subassetsState.type === "SUCCESS" && subassetsState.isUpdated) {
      subassetsState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      const requestData = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(requestData));
      dispatch(resetSubAssetState());
      dispatch(resetState());
      dispatch(resetSubAssetState());
    }
    if (subassetsState.type === "SUCCESS" && subassetsState.isDeleted) {
      subassetsState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
        });
      });
      const requestData = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(requestData));
      dispatch(resetSubAssetState());
      dispatch(resetState());
    }
    if (subassetsState.type === "ERROR") {
      subassetsState.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          persist: false,
          preventDuplicate: true,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
      dispatch(resetSubAssetState());
    }
  }, [dispatch, enqueueSnackbar, scope.projectIdent, subassetsState]);

  // asset Lists related
  useEffect(() => {
    if (overviewState.assetList.type === "ERROR") {
      overviewState.assetList.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          persist: false,
          preventDuplicate: true,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
      dispatch(resetState());
    }
    if (overviewState.assetList.type === "SUCCESS") {
      setAssetList(overviewState.assetList.data);
      dispatch(resetState());
    }
  }, [overviewState.assetList, dispatch, enqueueSnackbar, scope.projectIdent]);

  const openCreateAssetDialog = () => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: false,
      openPortFolioAddAssetDialog: false,
      openEditSubAssetDialog: false,
      openAddAssetDialog: true,
      openDeleteAlert: false,
      imageUpload: false,
      openAddSubAssetDialog: false,
      node: {
        project: prjState.data,
      },
    });
  };

  const openPortfolioCreateAssetDialog = (data) => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: false,
      openAddAssetDialog: false,
      openPortFolioAddAssetDialog: true,
      openEditSubAssetDialog: false,
      openDeleteAlert: false,
      imageUpload: false,
      openAddSubAssetDialog: false,
      node: {
        project: prjState.data,
        data: data,
      },
    });
  };

  const readFileContents = (file) => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });
        const firstSheet = workbook.SheetNames[0];
        const excelRows = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[firstSheet]
        );

        openPortfolioCreateAssetDialog(excelRows);
      } catch (e) {
        return;
      }
    };
    // Open the file in binary mode
    fileReader.readAsBinaryString(files[0]);
  };

  const resetDialogState = () => {
    setDialogScope({
      openDialog: false,
      openEditAssetDialog: false,
      openPortFolioAddAssetDialog: false,
      openEditSubAssetDialog: false,
      openAddAssetDialog: false,
      openDeleteAlert: false,
      imageUpload: false,
      openAddSubAssetDialog: false,
      node: {},
    });
  };

  const openEditAssetDialog = (selectedAsset) => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: true,
      openAddAssetDialog: false,
      openEditSubAssetDialog: false,
      openDeleteAlert: false,
      imageUpload: false,
      openAddSubAssetDialog: false,
      node: {
        asset: selectedAsset,
        project: prjState.data,
        viewMode: {
          isReadOnly: false,
          buttonText: "_save",
          headerTitle: selectedAsset.assetName,
        },
      },
    });
  };
  const openEditSubAssetDialog = (selectedAsset) => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: false,
      openEditSubAssetDialog: true,
      openAddAssetDialog: false,
      openDeleteAlert: false,
      imageUpload: false,
      openAddSubAssetDialog: false,
      node: {
        subAsset: selectedAsset,
        project: prjState.data,
        viewMode: {
          isReadOnly: false,
          buttonText: "_save",
          headerTitle: selectedAsset.assetName,
        },
      },
    });
  };

  const pushToUrl = (asset, subAsset, url) => {
    dispatch(
      setOpenAsset({
        assetName: asset.name,
        subAssetName: subAsset.name,
        ident: asset.assetIdent,
        subAssetIdent: subAsset.subAssetIdent,
      })
    );
    navigate(url, {
      asset: asset,
      subAsset: subAsset,
    });
  };

  const openCreateSubAssetDialog = (assetIdent) => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: false,
      openAddAssetDialog: false,
      openDeleteAlert: false,
      imageUpload: false,
      openAddSubAssetDialog: true,
      node: {
        assetIdent: assetIdent,
        projectIdent: scope.projectIdent,
        viewMode: {
          isReadOnly: false,
          buttonText: "_save",
          headerTitle: "Create Sub-Asset",
        },
      },
    });
  };

  const deleteAsset = (asset) => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: false,
      openAddAssetDialog: false,
      openEditSubAssetDialog: false,
      openDeleteAlert: true,
      imageUpload: false,
      openAddSubAssetDialog: false,
      node: {
        type: "asset",
        ident: asset.assetIdent,
        projectIdent: scope.projectIdent,
        title: asset.name,
      },
    });
  };
  const openUserManagement = (selectedAsset) => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: false,
      openAddAssetDialog: false,
      openEditSubAssetDialog: false,
      openDeleteAlert: false,
      imageUpload: false,
      openAddSubAssetDialog: false,
      openUserManagement: true,
      node: {
        asset: selectedAsset,
        project: prjState.data,
      },
    });
  };

  const deleteSubAsset = (subAsset) => {
    setDialogScope({
      openDialog: true,
      openEditAssetDialog: false,
      openEditSubAssetDialog: false,
      openAddAssetDialog: false,
      openDeleteAlert: true,
      imageUpload: false,
      openAddSubAssetDialog: false,
      node: {
        type: "sub-asset",
        ident: subAsset.subAssetIdent,
        projectIdent: scope.projectIdent,
        title: subAsset.name,
      },
    });
  };

  const handleFullReportChange = (e, asset) => {
    const editAsset = {
      type: "tddAssetDto",
      projectIdent: scope.projectIdent,
      name: asset.name,
      assetIdent: asset.assetIdent,
      beginFullReport: e.target.checked,
      numberofBuildings: asset.numberofBuildings,
    };
    const request = {
      api: scope.api,
      data: editAsset,
    };
    dispatch(updateAsset(request));
  };

  const createAddAnotherAssetGrid = () => {
    return (
      <Grid
        item
        md={12}
        xs={12}
        style={{
          width: "100%",
          position: "sticky",
          top: 0,
          zIndex: 1500,
          padding: 20,
          display: "flex",
          justifyContent: "space-between",
          background: "#C1D0F0",
        }}
      >
        <Grid key="AddAnotherAssetGrid">
          <CustomButton
            variant="button"
            handleClick={openCreateAssetDialog}
            style={{ width: "100%" }}
            id="Create_Asset_Button"
            buttonText="_Create Asset"
            tooltip={{
              title: getIntlTranslation("tooltip.create-asset"),
            }}
            permission={{ ...projectPermission }}
          />
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          key="uploadFile"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonGroup
            variant="contained"
            style={{ justifyContent: "flex-end" }}
            aria-label="outlined primary button group"
          >
            <FileButton
              id="Upload-Asset-File"
              variant="contained"
              className="g-btn-primary"
              permission={{ ...projectPermission }}
              style={{ marginRight: 5 }}
              endIcon={FileUploadIcon}
              onChange={(f) => readFileContents(f)}
              tooltip={{
                title: getIntlTranslation("tooltip.Upload-Asset-File"),
              }}
              buttonText="_Upload Asset File"
            />
            <FileDownloadButton
              id="Download-Asset-Template"
              endIcon={DownloadIcon}
              className="g-btn-primary"
              permission={{ ...projectPermission }}
              tooltip={{
                title: getIntlTranslation("tooltip.Download-Asset-Template"),
              }}
              handleClick={() => dispatch(getAssetTemplate({ api: scope.api }))}
              buttonText="_Asset Template"
            />
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  const createDefaultAnotherAssetGrid = () => {
    return (
      <Grid
        item
        md={12}
        xs={12}
        style={{ margin: 10 }}
        key="DefaultAnotherAssetGrid"
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <HighlightOffIcon />
            </ListItemIcon>
            <ListItemText primary={"No Asset Assigned"} />
          </ListItem>
        </List>
      </Grid>
    );
  };

  const createEditButtonIfAllowed = (asset) => {
    let permission = { ...asset.currentUserPermissionsDto };
    if (
      !projectPermission.create &&
      !projectPermission.update &&
      !projectPermission.delete
    ) {
      permission = { ...projectPermission };
    }
    return (
      <EditIconButton
        id={`edit-${asset.assetIdent}`}
        className={"g-btn-elevated"}
        handleClick={() => openEditAssetDialog(asset)}
        tooltip={{
          title: getIntlTranslation("tooltip.edit-asset"),
        }}
        dataIndex={asset.assetIdent}
        color="#ffffff"
        iconStyle={{
          fontSize: 22,
        }}
        permission={{ ...permission }}
      />
    );
  };

  const createDeleteButtonIfAllowed = (asset) => {
    let permission = { ...asset.currentUserPermissionsDto };
    if (
      !projectPermission.create &&
      !projectPermission.update &&
      !projectPermission.delete
    ) {
      permission = { ...projectPermission };
    }
    return (
      <DeleteIconButton
      id={`delete-${asset.assetIdent}`}
      className={"g-btn-elevated"}
        tooltip={{
          title: getIntlTranslation("tooltip.delete-asset"),
        }}
        iconStyle={{
          fontSize: 22,
        }}
        color="#ffffff"
        handleClick={(event) => deleteAsset(asset)}
        permission={{ ...permission }}
      />
    );
  };
  const createUserManagementButtonIfAllowed = (asset) => {
    let permission = { ...asset.currentUserPermissionsDto };
    if (
      !projectPermission.create &&
      !projectPermission.update &&
      !projectPermission.delete
    ) {
      permission = { ...projectPermission };
    }
    return (
      <CustomIconButton
      id={`user-${asset.assetIdent}`}
      className={"g-btn-elevated"}
        tooltip={{
          title: getIntlTranslation("tooltip.asset-user-management"),
        }}
        iconStyle={{
          fontSize: 22,
        }}
        handleClick={(event) => openUserManagement(asset)}
        permission={{ ...permission }}
        dataIndex={asset.assetIdent}
        style={{ color: "#ffffff" }}
        icon={<ManageAccountsIcon />}
      />
    );
  };

  const buildAssetCard = () => {
    let assetsGrid = [];
    assetsGrid.push(createAddAnotherAssetGrid());
    if (assetList.length > 0) {
      assetList.forEach((asset, index) => {
        assetsGrid.push(
          <Grid
            item
            md={12}
            xs={12}
            key={`buildAssetCard-${index}`}
            style={{ margin: 10 }}
            className="w-100"
          >
            {createAssetCards(asset)}
          </Grid>
        );
      });
    } else {
      assetsGrid.push(createDefaultAnotherAssetGrid());
    }
    return assetsGrid;
  };

  function createAssetCards(asset) {
    return (
      <>
        <Card variant="outlined">
          <Box
            className={"d-flex align-items-center"}
            style={{ width: "100%" }}
          >
            <div
              className={"d-flex flex-column"}
              style={{ margin: 10, width: "40%" }}
            >
              <div className={"d-flex align-items-center"}>
                <span style={{ margin: 5 }}>
                  {getTranslationVariantText("_Asset", "body1", {
                    fontWeight: "bold",
                    color: "#254a9a",
                  })}
                </span>
                :
                <span style={{ margin: 5 }}>
                  {getVariantText(asset.name, "body1", {
                    fontWeight: "bold",
                  })}
                </span>
              </div>
            </div>
            {!!asset.lastUpdated && asset.lastUpdatedByUser && (
              <div
                style={{
                  margin: 10,
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  color: "#254a9a",
                }}
              >
                <span style={{ margin: 5 }}>
                  {!!asset.lastUpdatedByUser && getIntlTranslation("User")}:{" "}
                  {asset.lastUpdatedByUser}
                </span>
                <span style={{ margin: 5 }}>
                  {!!asset.lastUpdated && getIntlTranslation("Last Change")}:{" "}
                  {new Date(asset.lastUpdated).toLocaleString(scopeLanguage, {
                    weekday: "short",
                  })}{" "}
                  ,
                  {new Date(asset.lastUpdated).toLocaleDateString(
                    scopeLanguage
                  )}{" "}
                  /{" "}
                  {new Date(asset.lastUpdated).toLocaleTimeString(
                    scopeLanguage
                  )}{" "}
                  {scopeLanguage === "de" ? "Uhr" : ""}
                </span>
              </div>
            )}
            <Box
              className="d-flex report-fullScreen-comments"
              style={{
                width: "30%",
                justifyContent: "space-between",
                alignItems: "center",
                height: 35,
                padding: 4,
                borderRadius: 5,
                marginRight:5,
              }}
            >
              <div
                className="d-flex w-50 h-100 flex-grow-1"
              >
                <ReportGenerationDialog
                  id={`report-generation-${asset.assetIdent}`}
                  reportOptions={getReportOptions(asset)}
                  selectedData={getSelectedData(asset)}
                  filterDataList={[
                    {
                      name: asset.name,
                      ident: asset.assetIdent,
                      projectIdent: scope.projectIdent,
                      isParent:true,
                      parentIdent: asset.assetIdent,
                      users: asset?.userDtos?.map((u) => {
                        return {
                          label: `${u.firstName} ${u.lastName}`,
                          value: u.emailAddress,
                        };
                      }),
                    },
                  ]}
                  className="text-light h-100"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "15%",
                  flexGrow: "1",
                }}
              >
                <Divider
                  orientation="vertical"
                  style={{
                    width: 4,
                    backgroundColor: "#ffffff",
                  }}
                  flexItem
                />
                {createEditButtonIfAllowed(asset)}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "15%",
                  flexGrow: "1",
                }}
              >
                <Divider
                  orientation="vertical"
                  style={{
                    width: 4,
                    backgroundColor: "#ffffff",
                  }}
                  flexItem
                />
                {createUserManagementButtonIfAllowed(asset)}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "10%",
                  flexGrow: "1",
                }}
              >
                <Divider
                  orientation="vertical"
                  style={{
                    width: 4,
                    backgroundColor: "#ffffff",
                  }}
                  flexItem
                />
                {createDeleteButtonIfAllowed(asset)}
              </div>
            </Box>
          </Box>
          <Divider component="div" style={{ width: "100%" }} />
          <Box style={{ width: "100%" }}>
            <Box
              className={"d-flex align-items-center"}
              style={{ width: "100%" }}
            >
              <Box>
                {getTranslationVariantText("List of Sub-Assets", "subtitle2", {
                  color: "#254a9a",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  padding: 10,
                })}
              </Box>
              <Box style={{ marginLeft: "auto", padding: 10 }}>
                <CustomButton
                  id="create-sub-asset-button"
                  dataIndex={asset.assetIdent}
                  permission={{ ...asset.currentUserPermissionsDto }}
                  style={{
                    float: "right",
                    color: "#fff",
                    background: "#254a9a",
                  }}
                  size="small"
                  className="g-btn-secondary"
                  variant="button"
                  handleClick={(event) =>
                    openCreateSubAssetDialog(asset.assetIdent)
                  }
                  endIcon={<AddCircleIcon />}
                  tooltip={{
                    title: getIntlTranslation("tooltip.Create Sub-Asset"),
                  }}
                  buttonText="_Create Sub-Asset"
                />
              </Box>
            </Box>
            <List style={{ width: "100%" }}>{buildSubAssetSection(asset)}</List>
          </Box>
        </Card>
      </>
    );
  }

  const buildSubAssetSection = (asset) => {
    let subAssetList = [];
    let permission = { ...asset.currentUserPermissionsDto };

    if (asset.subAssetListDto.length > 0) {
      asset.subAssetListDto.forEach((subAsset, index) => {
        const sub = {
          ...subAsset,
          assetIdent: asset.assetIdent,
        };
        subAssetList.push(
          <>
            <Divider component="div" style={{ width: "100%" }} />
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingLeft: 10,
                alignItems: "center",
              }}
            >
              <div
                style={{ width: "40%", display: "flex", flexDirection: "row" }}
              >
                <Grid
                  className={"d-flex align-items-center flex-wrap "}
                  style={{ marginRight: 5, width: "60%" }}
                >
                  {getTranslationVariantText("_Name", "body1", {
                    fontWeight: "bold",
                    color: "#254a9a",
                  })}
                  :
                  <span
                    className="d-flex align-items-baseline flex-wrap"
                    style={{ margin: 5 }}
                  >
                    {getVariantText(sub.name, "subtitile2", {
                      marginRight: 5,
                    })}
                  </span>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "20px 5px", backgroundColor: "#254a9a" }}
                />
                <Grid
                  className={"d-flex align-items-center flex-wrap"}
                  style={{ marginRight: 5, width: "40%" }}
                >
                  <AccountBalanceIcon
                    style={{ fontSize: "20", color: "#00a6db", marginRight: 5 }}
                  />
                  {getVariantText(subAsset.subAssetType, "subtitile2", {
                    wordBreak: "break-word",
                    width:"70%"
                  })}
                </Grid>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                style={{ margin: "20px 5px", backgroundColor: "#254a9a" }}
              />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  marginRight: 10,
                  width: "60%",
                }}
              >
                <ListItemButton
                  className={"d-flex align-items-center flex-wrap"}
                  
                  onClick={(event) =>
                    pushToUrl(
                      asset,
                      subAsset,
                      `/guru/tdd/project/building-profile`
                    )
                  }
                  id={`buildSubAssetSection-${index}`}
                  key={`buildSubAssetSection-${index}`}
                >
                  <RoomPreferencesIcon
                    style={{ fontSize: "20", color: "#016485", marginRight: 5 }}
                  />
                  {getTranslationVariantText("_building-profile", "subtitile2", {
                    wordBreak: "break-word",
                    width:"70%"
                  })}
                </ListItemButton>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "20px 5px", backgroundColor: "#254a9a" }}
                />
                <ListItemButton
                  className={"d-flex align-items-center flex-wrap"}
                  onClick={(event) =>
                    pushToUrl(asset, subAsset, `/guru/tdd/project/defects`)
                  }
                  id={`buildSubAssetSection-${index}`}
                  key={`buildSubAssetSection-${index}`}
                >
                  <ConstructionIcon
                    style={{ fontSize: "20", color: "#016485", marginRight: 5 }}
                  />
                  {getTranslationVariantText("_defects", "subtitile2", {
                    wordBreak: "break-word",
                    width:"70%"
                  })}
                </ListItemButton>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "20px 5px", backgroundColor: "#254a9a" }}
                />
                <ListItemButton
                  className={"d-flex align-items-center flex-wrap"}
                  onClick={(event) =>
                    pushToUrl(
                      asset,
                      subAsset,
                      `/guru/tdd/project/photo-documentation`
                    )
                  }
                  id={`buildSubAssetSection-${index}`}
                  key={`buildSubAssetSection-${index}`}
                >
                  <LinkedCameraIcon
                    style={{ fontSize: "20", color: "#016485", marginRight: 5 }}
                  />
                  {getTranslationVariantText("_photo-documentation", "subtitile2", {
                    wordBreak: "break-word",
                    width:"70%"
                  })}
                </ListItemButton>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "20px 5px", backgroundColor: "#254a9a" }}
                />
              </Box>
              <Box
              className="d-flex align-items-center"
              style={{
                width: "30%",
                justifyContent: "space-between",
                alignItems: "center",
                height: 35,
                padding: 4,
                borderRadius: 5,
                marginRight:10,
                backgroundColor: "#C1D0F0"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "40%",
                  flexGrow: "1",
                }}
              >
                <ReportGenerationDialog
                 id={`report-generation-${subAsset.ident}`}
                  reportOptions={[capex]}
                  selectedData={{
                    name: subAsset.name,
                    ident: subAsset.subAssetIdent,
                    parentIdent:asset.assetIdent,
                    projectIdent: scope.projectIdent,
                    isParent:false,
                    users: asset?.userDtos?.map((u) => {
                      return {
                        label: `${u.firstName} ${u.lastName}`,
                        value: u.emailAddress,
                      };
                    }),
                  }}
                  filterDataList={[
                    {
                      name: subAsset.name,
                      ident: subAsset.subAssetIdent,
                      parentIdent:asset.assetIdent,
                      projectIdent: scope.projectIdent,
                      isParent:false,
                      users: asset?.userDtos?.map((u) => {
                        return {
                          label: `${u.firstName} ${u.lastName}`,
                          value: u.emailAddress,
                        };
                      }),
                    },
                  ]}
                  className="g-btn-secondary"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "10%",
                  flexGrow: "1",
                }}
              >
                <Divider
                  orientation="vertical"
                  style={{
                    width: 4,
                    backgroundColor: "#ffffff",
                  }}
                  flexItem
                />
                <EditIconButton
                  id={`edit-${subAsset.ident}`}
                  className="g-btn-elevated"
                  handleClick={() => openEditSubAssetDialog(sub)}
                  tooltip={{
                    title: getIntlTranslation("tooltip.edit-asset"),
                  }}
                  iconStyle={{
                    fontSize: 22,
                  }}
                  dataIndex={subAsset.ident}
                  color="#254a9a"
                  permission={{ ...permission }}
                />
                </div>
                <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "10%",
                  flexGrow: "1",
                }}
              >
                <Divider
                  orientation="vertical"
                  style={{
                    width: 4,
                    backgroundColor: "#ffffff",
                  }}
                  flexItem
                />
                <DeleteIconButton
                   id={`delete-${subAsset.ident}`}
                   className="g-btn-elevated"
                  tooltip={{
                    title: getIntlTranslation("tooltip.Delete sub-Asset"),
                  }}
                  iconStyle={{
                    fontSize: 22,
                  }}
                  handleClick={(event) => deleteSubAsset(subAsset)}
                  style={{ margin: 5 }}
                  permission={{ ...asset.currentUserPermissionsDto }}
                />
                </div>
              </Box>
            </Box>
          </>
        );
      });
    } else {
      subAssetList.push(
        <ListItem style={{ paddingLeft: 30 }}>
          <ListItemIcon>
            <HighlightOffIcon />
          </ListItemIcon>
          <ListItemText primary={"No sub asset found"} />
        </ListItem>
      );
    }
    return subAssetList;
  };
  return (
    <Box
      style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
      className="custom-scrollBar"
      key="1"
    >
      <Box
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className="d-flex align-items-center justify-content-start flex-column"
      >
        {buildAssetCard()}
      </Box>

      {dialogScope.openDialog && (
        <AssetDialogSelection
          open={dialogScope.openDialog}
          actionScope={dialogScope}
          onClose={() => resetDialogState()}
        />
      )}
    </Box>
  );
};

export default AssetsOverview;
