import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomButton from "../../../../../components/buttons/CustomButton";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import ReportGenerationDialog from "../../../../component-bundle/generate-report/ReportGenerationDialog";
import {
  getIntlTranslation,
  getWarningText,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import {
  capex,
  capexPortfolioDetail,
  capexPortfolioSummary,
} from "../../../../../utils/defaultValues";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import { setTab, setFullMode } from "../keyFindingSlice";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import * as XLSX from "xlsx";

import {
  AppBar,
  Box,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Divider,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSnackbar } from "notistack";

import axios from "../../../../../api/customAxios";

import { renewAccessTokenIfGoingExpire } from "../../../../../api/authApi";
import { trackPromise } from "react-promise-tracker";
import {
  getFailure,
  getAssetCreateSuccess,
} from "../../../../../guru-suite/component-bundle/asset-overview/assetOverviewSlice";
import { getErrorFailure } from "../../../../../error/errorHandlingSlice";
import { getDefectTemplate } from "../../../../../api/defectOverviewApi";

import FileButton from "../../../../../components/buttons/FileButton";
import FileDownloadButton from "../../../../../components/buttons/FileDownloadButton";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DefectDialogSelection from "../defect-grid/DefectDialogSelection";

function CategoriesTab(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const state = useSelector((state) => state.tddKeyFinding);
  const openAsset = useSelector((state) => state.openAsset);
  const scope = useSelector((state) => state.container.scope);
  const readOnlystate = useSelector((state) => state.readOnly);
  const [blockChange, setBlockChange] = useState({
    block: false,
    value: null,
    action: "",
  });
  const { assetList, fetchList } = useAssetListHook();
  const { enqueueSnackbar } = useSnackbar();

  // Ellipsis Menu State
  const [anchorEl, setAnchorEl] = useState(null);
  const ellipsisMenuOpen = Boolean(anchorEl);

  const [dialogScope, setDialogScope] = useState({
    openDialog: false,
    openCreateMultipleDefectDialog: false,
    node: {},
  });

  const defect = useSelector((state) => state.defect); // Adjust based on your state structure

  useEffect(() => {
    fetchList();
  }, [scope.projectIdent, fetchList]);

  const getReportOptions = [capex, capexPortfolioDetail, capexPortfolioSummary];

  const getDataList = useMemo(
    () =>
      assetList.assets?.map((a) => ({
        name: a.name,
        ident: a.assetIdent,
        isParent: true,
        projectIdent: scope.projectIdent,
        parentIdent: a.assetIdent,
        users: a?.userDtos?.map((u) => ({
          label: `${u.firstName} ${u.lastName}`,
          value: u.emailAddress,
        })),
      })),
    [assetList, scope.projectIdent]
  );

  const getSelectedData = useMemo(() => {
    const found = assetList.assets?.find(
      (a) => a.assetIdent === openAsset.ident
    );
    if (found) {
      return {
        name: found.name,
        ident: found.assetIdent,
        isParent: true,
        parentIdent: found.assetIdent,
        users: found?.userDtos?.map((u) => ({
          label: `${u.firstName} ${u.lastName}`,
          value: u.emailAddress,
        })),
        projectIdent: scope.projectIdent,
      };
    }
    return null;
  }, [assetList, openAsset, scope.projectIdent]);

  // Initialization Effect: Sets the initial tab and value
  useEffect(() => {
    const valueNotFoundInCategories = props.categories?.options?.find(
      (cate) => cate.ident === value?.ident
    );
    if (
      props.categories?.options?.length > 0 &&
      (!value || !valueNotFoundInCategories)
    ) {
      const initialTab = props.categories.options[0];
      setValue(initialTab);
      dispatch(setTab(initialTab));
      props.onTabSelect(initialTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.categories?.options, openAsset]);

  // Synchronization Effect: Keeps 'value' in sync with 'state.tab'
  useEffect(() => {
    if (state.tab && props.categories?.options?.length > 0) {
      const matchedTab = props.categories.options.find(
        (f) => f.key === state.tab.key
      );
      if (matchedTab && (!value || matchedTab.key !== value.key)) {
        setValue(matchedTab);
        props.onTabSelect(matchedTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tab]);

  const handleChange = (event, newValue) => {
    if (state.updatedRows.length > 0) {
      setBlockChange({
        block: true,
        value: newValue,
        action: "tab-change",
      });
    } else {
      dispatch(setTab(newValue));
      setBlockChange({
        block: false,
        value: null,
        action: "",
      });
    }
  };

  const openFullModeCondition = () => {
    if (state.updatedRows.length > 0) {
      setBlockChange({
        block: true,
        action: "open-fullMode",
        value: true,
      });
    } else {
      dispatch(setFullMode(true));
    }
  };

  // Ellipsis Menu Handlers
  const handleEllipsisMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEllipsisMenuClose = () => {
    setAnchorEl(null);
  };

  // Parse Excel File (Using xlsx library)
  const openCreateMultipleDefectDialog = (data) => {
    setDialogScope({
      openDialog: true,
      openCreateMultipleDefectDialog: true,
      node: {
        project: {
          projectIdent: scope.projectIdent,
          subAssetIdent: openAsset.subAssetIdent,
          assetIdent: openAsset.ident,
        },
        data: data,
      },
    });
  };

  const resetDialogState = () => {
    setDialogScope({
      openDialog: false,
      openCreateMultipleDefectDialog: false,
      node: {},
    });
  };

  const readFileContents = (file) => {
    const { files } = file.target;
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });
        const firstSheet = workbook.SheetNames[0];
        const excelRows = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[firstSheet]
        );

        openCreateMultipleDefectDialog(excelRows);
      } catch (e) {
        return;
      }
    };

    reader.readAsBinaryString(files[0]);
  };

  return (
    <>
      <Box
        style={{ flexGrow: 1, marginBottom: 5 }}
        className="d-flex align-items-center justify-content-start"
      >
        <AppBar
          position="static"
          style={{
            width: "70%",
            height: 40,
            borderRadius: 5,
            backgroundColor: "#ffffff",
          }}
        >
          <Toolbar
            style={{ minHeight: 35, padding: 0, height: 40 }}
            className="d-flex align-items-center w-100"
          >
            <Tabs
              style={{
                height: 40,
                padding: 0,
                width: "100%",
                minHeight: 40,
              }}
              value={value}
              onChange={handleChange}
              className="g-btn-secondary d-flex align-items-center"
              indicatorColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#d08f8e",
                  height: 12,
                  borderRadius: 40,
                },
              }}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable categories"
            >
              {props.categories.options?.map((m) => (
                <Tab
                  style={{
                    color: "#254a9a",
                    padding: 10,
                    height: 40,
                    maxWidth: "fit-content",
                    marginTop: 5,
                  }}
                  label={
                    <Typography variant="button" display="block" gutterBottom>
                      {m.name}
                    </Typography>
                  }
                  key={m.ident}
                  value={m}
                />
              ))}
            </Tabs>
          </Toolbar>
        </AppBar>
        <Box
          style={{
            display: "flex",
            width: "30%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#d08f8e",
            height: 40,
            borderRadius: 5,
            margin: "0px 4px",
          }}
          aria-label="button group for defect grid"
        >
          <CustomButton
            id="save-defects-changes-rows"
            className="g-btn-elevated"
            buttonText={"_save"}
            style={{
              margin: 5,
              height: 30,
              width: "40%",
              backgroundColor: "#244a9a",
              color: "#ffffff",
            }}
            variant="button"
            tooltip={{
              title: getIntlTranslation("tooltip.save"),
            }}
            handleClick={() => {
              props.onSave();
            }}
          />
          <Divider
            orientation="vertical"
            style={{
              width: 4,
              margin: "10px 0px",
              backgroundColor: "#ffffff",
            }}
            flexItem
          />
          <div className="g-btn-elevated" style={{ margin: 2 }}>
            <ReportGenerationDialog
              reportOptions={getReportOptions}
              selectedData={getSelectedData}
              filterDataList={getDataList}
              id={`report-generation`}
              className="text-light p-1"
            />
          </div>
          {!state.fullMode && (
            <>
              <Divider
                orientation="vertical"
                style={{
                  width: 4,
                  margin: "10px 0px",
                  backgroundColor: "#ffffff",
                }}
                flexItem
              />
              <div className="g-btn-elevated" style={{ margin: 2 }}>
                <CustomIconButton
                  id="open-full-mode"
                  handleClick={() => openFullModeCondition()}
                  style={{
                    borderRadius: 4,
                    margin: 5,
                    height: 30,
                  }}
                  icon={
                    <FullscreenIcon
                      style={{ width: 22, height: 22, color: "#ffffff" }}
                    />
                  }
                  tooltip={{
                    title: getIntlTranslation("tooltip.fullscreen"),
                  }}
                />
              </div>
            </>
          )}
          {/* Ellipsis Button Start */}
          <div className="g-btn-elevated" style={{ margin: 2 }}>
            <IconButton
              aria-label="more"
              aria-controls={ellipsisMenuOpen ? "ellipsis-menu" : undefined}
              aria-haspopup="true"
              onClick={handleEllipsisMenuOpen}
              style={{ color: "#ffffff" }} // Adjust color as needed
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="ellipsis-menu"
              anchorEl={anchorEl}
              open={ellipsisMenuOpen}
              onClose={handleEllipsisMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                {/* Hidden file input */}
                <FileButton
                  id="Upload-Defect-File"
                  variant="contained"
                  className="g-btn-primary"
                  permission={{
                    create: true,
                    delete: true,
                    read: true,
                    type: "currentUserPermissionsDto",
                    update: true,
                  }}
                  style={{ marginRight: 5 }}
                  endIcon={FileUploadIcon}
                  onChange={(f) => readFileContents(f)}
                  tooltip={{
                    title: getIntlTranslation("tooltip.Upload-Defect-File"),
                  }}
                  buttonText="_Upload Defect File"
                />
                <FileDownloadButton
                  id="Download-Defect-Template"
                  endIcon={DownloadIcon}
                  className="g-btn-primary"
                  tooltip={{
                    title: getIntlTranslation(
                      "tooltip.Download-Defect-Template"
                    ),
                  }}
                  handleClick={() =>
                    dispatch(getDefectTemplate({ api: scope.api }))
                  }
                  buttonText="_Defect Template"
                />
              </MenuItem>
            </Menu>
          </div>
          {/* Ellipsis Button End */}
        </Box>
      </Box>
      {/* Dialog for Reviewing and Uploading Parsed Excel Data */}

      {dialogScope.openDialog && (
        <DefectDialogSelection
          open={dialogScope.openDialog}
          actionScope={dialogScope}
          onClose={() => resetDialogState()}
          needGridRefresh={() => props.needGridRefresh()}
        />
      )}
      {/* Existing InternalPrompt for unsaved changes */}
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={blockChange.block}
          stayText="_back"
          saveText="Save And Leave"
          leaveText="_continue"
          onLeave={() => {
            if (blockChange.action === "tab-change") {
              dispatch(setTab(blockChange.value));
            } else if (blockChange.action === "open-fullMode") {
              dispatch(setFullMode(blockChange.value));
            }
            setBlockChange({
              block: false,
              value: null,
              action: "",
            });
          }}
          onStay={() =>
            setBlockChange({
              block: false,
              value: null,
              action: "",
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText(state.updatedRows)}
        />
      )}
    </>
  );
}

CategoriesTab.propTypes = {
  onSave: PropTypes.func.isRequired,
  categories: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        ident: PropTypes.string.isRequired,
        key: PropTypes.string, // Assuming 'key' exists based on earlier code
      })
    ),
  }).isRequired,
  onTabSelect: PropTypes.func.isRequired,
  checkIfRowsUpdated: PropTypes.func, // Assuming this is optional
};

export default CategoriesTab;
