import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Grow,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  Autocomplete,
} from "@mui/material";
import CustomButton from "../../../../components/buttons/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  getLabel,
  getDialogBoxHeader,
  getIntlTranslation,
} from "../../../../utils/helper";
import { useSnackbar } from "notistack";
import { resetState } from "./monitoringReportSlice";
import { createAndDownloadBlobFile } from "../../../../utils/helper";
import { generateReport } from "../../api/monitoringGenerateReportApi";
import LinearProgress from '@mui/material/LinearProgress';
import { resetErrorState } from "../../../../error/errorHandlingSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const versionType = [
  {
    value: "Draft",
    label: "Draft",
  },
];

const lang = [
  { label: "English", value: "en" },
  { label: "Deutsch", value: "de" },
];

const ReportGenerationDialog = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.monitoringReport);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const errorState = useSelector((state) => state.errorhandling);
  const [reportSelection, setReportSelection] = useState({
    selectedVersion: null,
    selectedLanguage: null,
    selectedVersionType: {
      value: "Draft",
      label: "Draft",
    },
  });

  useEffect(() => {
    if (errorState.hasErrors && errorState.responseError) {
        setLoading(false);
        dispatch(resetErrorState());
      }
    if (state.reportSuccess.type === "REPORT_SUCCESS") {
      enqueueSnackbar(state.reportSuccess.messages, {
        variant: "success",
        persist: false,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      const arrayBuffer = base64ToArrayBuffer(state.reportSuccess.data.fileData);
      createAndDownloadBlobFile(
        arrayBuffer,
        state.reportSuccess.data.fileName,
        state.reportSuccess.data.contentType
      );
    }
    return () => {
      setLoading(false);
      dispatch(resetState());
    };
  }, [state.reportSuccess, dispatch, enqueueSnackbar,errorState.hasErrors]);

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      generateReport({
        data: { ...props.data, projectIdent: scope.projectIdent },
        api:scope.api,
        language:reportSelection.selectedLanguage?.value,
        versionType:reportSelection.selectedVersionType.value,
        versionIdent:reportSelection.selectedVersion.ident,
      })
    );
    
  };

  return (
    <>
      {!!props.data && props.data.reportVersion.length > 0 && (
        <Dialog
          open={props.open}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              props.onClose();
            }
          }}
          disableBackdropClick
          disableEscapeKeyDown
          style={{ overflow: "hidden" }}
        >
          <AppBar className={"appBar"}>
            <Toolbar className={"dialog-toolbar"}>
              {getDialogBoxHeader("_report-selection")}
              <IconButton
                edge="start"
                color="inherit"
                id="generate_report_close"
                onClick={(e) => props.onClose()}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          
          <DialogContent dividers>
            <div style={{ minHeight: 150 }}>
              <Box style={{ display: "flex" }}>
                <div style={{ width: "30%" }}>
                  <img src="/assets/images/generate.svg" alt="generate" />
                </div>
                <Box className={"d-flex flex-column w-100"}>
                  <Autocomplete
                    value={reportSelection.selectedVersion}
                    onChange={(event, newValue) => {
                      setReportSelection((prev) => ({
                        ...prev,
                        selectedVersion: newValue,
                      }));
                    }}
                    className="grid-fields"
                    id="type"
                    options={props.data.reportVersion}
                    getOptionLabel={(option) => option.versionName}
                    getOptionSelected={(option, value) =>
                      value.ident === option.ident
                    }
                    getOptionDisabled={(option) => option.disabled}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getIntlTranslation("_report-version")}
                        className="grid-fields"
                        variant="standard"
                      />
                    )}
                  />
                  <Autocomplete
                    value={reportSelection.selectedLanguage}
                    onChange={(event, newValue) => {
                      setReportSelection((prev) => ({
                        ...prev,
                        selectedLanguage: newValue,
                      }));
                    }}
                    className="grid-fields"
                    id="lang"
                    options={lang}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      value.value === option.value
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getIntlTranslation("_report-language")}
                        className="grid-fields"
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              </Box>
            </div>
          </DialogContent>
          {loading && <LinearProgress style={{backgroundColor:"#d08f8e", color:"#254a9a"}} />}
          <DialogActions>
            <CustomButton
              id={`Generate-capex`}
              buttonText={"_Generate"}
              variant="button"
              style={{
                margin: 5,
                backgroundColor: "#254a9a",
                color: "#ffffff",
              }}
              tooltip={{
                title: getIntlTranslation("tooltip.click-to-generate"),
              }}
              handleClick={(e) => handleSubmit()}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

ReportGenerationDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default ReportGenerationDialog;
