import React, { useState } from 'react'
import {Box} from '@mui/material';
import CostSuggestionContainer from '../component-bundle/cost-suggestions/CostSuggestionContainer';

const DocumentBuilderHome = () => {
  const [selectedItem, setSelectedItem] = useState({});
  return (
    <Box className={"h-100 w-100"}>
   <CostSuggestionContainer selectedItem={selectedItem} onSelect={(selected) =>
                setSelectedItem((prev) => {
                  if (prev?.ident === selected.ident) {
                    return null;
                  } else {
                    return selected;
                  }
                })
              } />
  </Box>
  );
};
export default DocumentBuilderHome;