import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  LinearProgress,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getLabel, getTranslationVariantText } from "../../../utils/helper";
import { onSelectReportVersion } from "./slice/CurrentVersionSlice";
import RenderCompareNodeValues from "./RenderCompareNodeValues";
import { useComparisonMode } from "./context/ComparisonModeContext";

const ComparisionVersionContainer = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.currentVersion);
  const { loading, versionSelected } = useComparisonMode();
  const [currentVersion, setCurrenVersion] = useState(null)
  const monitoringState = useSelector((state) => state.projectMonitoring);
  
  useEffect(() => {
    if(!!state.currentVersion.ident){
      setCurrenVersion(state.currentVersion)
    }
  }, [state.currentVersion])
  

  const renderCardContent = () => {
    if (!!versionSelected) {
      return <RenderCompareNodeValues />;
    }
    return "No Data Available";
  };

  return (
    <>
      {monitoringState.isCompareMode && (
        <Box
          style={{ width: monitoringState.isCompareMode ? "49%" : "0%", height: "100%" }}
          className="mx-1"
        >
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <Card className="w-100 h-100 d-flex flex-column g-btn-secondary">
            <CardContent
              className="w-100 overflow-x-hidden overflow-y-auto custom-scrollBar p-1 "
              style={{ height: "calc(100% - 55px" }}
            >
              {renderCardContent()}
            </CardContent>
            {!!versionSelected && state.versions.length > 0 &&(
              <CardActions
                className="mt-auto w-100 p-0"
                style={{ height: 50, backgroundColor: "#c0c0c0" }}
              >
                {getTranslationVariantText("_compare", "body1", {
                  fontWeight: "bold",
                  color: "#254a9a",
                },"w-25 p-1")}
                <Autocomplete
                  className="p-1 w-75"
                  id="rerport_version"
                  value={versionSelected.label}
                  options={state.versions.filter(
                    (f) => f.ident !== state.currentVersion.ident
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.ident === value.ident
                  }
                  onChange={(e, newValue) => {
                    dispatch(onSelectReportVersion(newValue));
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={getLabel("version")}
                      size="small"
                      style={{ color: "#ffffff", width: "100%" }}
                    />
                  )}
                />
              </CardActions>
            )}
          </Card>
        </Box>
      )}
    </>
  );
};

export default ComparisionVersionContainer;
