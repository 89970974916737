import { useSnackbar } from "notistack";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { response } from "../../../../../utils/defaultValues";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import { addProperties, deleteProperty, editProperty, fetchAllProperties, fetchPropertiesToCompare, saveProperties } from "../../../api/monitoringProjectProgressApi";
import { resetPromptState } from "../../../projectmonitoringModuleSlice";
import { resetState, setCurrentVersion, onSelectReportVersion, setVersions, setUpdatedRows, getSaveSuccess, getCustomSuccess, getCustomUpdate, getCustomDelete, getFetchSuccess } from "../projectProgressSlice";

export const ProjectProgressContext = createContext(undefined);

export const  useProjectProgressContext = () => {
  const context = useContext(ProjectProgressContext);

  if(context === undefined){
    throw new Error("context should be used")
  }
return context;
}


export const ProjectProgressProvider = ({ children, moduleApi }) => {
    const dispatch = useDispatch();
    const scope = useSelector((state) => state.container.scope);
    const openAsset = useSelector((state) => state.openAsset);
    const tempVersion = useSelector((state) => state.projectMonitoring.temp);
    const state = useSelector((state) => state.projectProgress);
    const { assetList } = useAssetListHook();
    const [assets, setAssets] = useState({
      isAssigned: false,
      data: [],
    });
    const [subAssetList, setSubAssetList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [blockAssetChange, setBlockAssetChange] = useState({
      block: false,
      newAsset: {},
    });
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        if (!!openAsset.ident){
          setProperties([])
          setSubAssetList([]);
          dispatch(resetState())
          dispatch(setCurrentVersion({}));
          setAssets({
            isAssigned: false,
            data: [],
          });
        }
      }, [dispatch, openAsset.ident])


  useEffect(() => {
    if (!!openAsset.ident && assetList.isFetchSuccess) {
      const list = assetList.assets.map((m) => {
        return {
          ...m,
          address: { ...m.addressDto },
          reportVersion: [
            ...m.reportVersionDtos.map((rv) => {
              return { ...rv, label: rv.versionName, value: rv.ident };
            }),
          ],
        };
      });

      let found = list.find(
        (f) =>
          f.assetIdent === openAsset.ident &&
          scope?.projectIdent === openAsset?.projectIdent
      );
      let currentversion = found.reportVersion.find((f) => f.current);
      if (tempVersion.isTemp && openAsset.ident === tempVersion.assetIdent) {
        currentversion = found.reportVersion.find(
          (f) => f.ident === tempVersion.reportVersionIdent
        );
      }
      let temp = [
        ...found?.subAssets.map((m) => m).sort((a, b) => b.general - a.general),
      ];
      setSubAssetList(temp);
      dispatch(setCurrentVersion(currentversion || {}));
      dispatch(onSelectReportVersion(null));
      dispatch(setVersions(found.reportVersion));
      setAssets({
        isAssigned: true,
        data: list,
      });
    }
  }, [assetList, assets.isAssigned, dispatch, openAsset.ident, openAsset?.projectIdent, scope?.projectIdent, tempVersion]);

  const fetchProps = useCallback(() => {
    if(!!state.currentVersion &&  !!state.currentVersion.ident){
    dispatch(
        fetchAllProperties({
        api: scope.api,
        projectIdent: scope.projectIdent,
        version: state.currentVersion.ident,
        moduleApi: moduleApi,
      })
    );
  }
  }, [dispatch, moduleApi, scope.api, scope.projectIdent, state.currentVersion]);

  useEffect(() => {
    if (assets.isAssigned && !!state.currentVersion) {
      fetchProps();
    }
  }, [
    assets.isAssigned,
    state.currentVersion,
    fetchProps,
  ]);

  useEffect(() => {
    if (state.fetch.type === "FETCHED" && state.fetch.data.length > 0) {
      setProperties(state.fetch.data);
      dispatch(getFetchSuccess({ ...response,guruDtoList:[] }));
    }
    if (state.save.type === "SAVED") {
        state.save.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(setUpdatedRows([]));
      dispatch(getSaveSuccess({ ...response,guruDto:{} }));
      dispatch(resetPromptState());
      fetchProps();
    }
    if (state.custom.type === "SAVED") {
        state.custom.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomSuccess({ ...response, guruDtoList:[] }));
      fetchProps();
    }
    if (state.custom.type === "UPDATED") {
      state.custom.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomUpdate({ ...response, guruDto: {} }));
      fetchProps();
    }
    if (state.customDelete.type === "DELETED") {
        state.customDelete.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
      dispatch(getCustomDelete({ ...response, guruDto: {} }));
      fetchProps();
    }
  }, [dispatch, enqueueSnackbar, fetchProps, state.custom, state.customDelete, state.fetch.data, state.fetch.type, state.save]);



  const onFetchCompareProperties = useCallback((versionIdent) => {
    dispatch(
      fetchPropertiesToCompare({
        moduleApi: moduleApi,
        api: scope.api,
        projectIdent: scope.projectIdent,
        version: versionIdent,
      })
    );
  },[dispatch, moduleApi, scope.api, scope.projectIdent]);


  const onSave = useCallback(
    (data) => {
      dispatch(
        saveProperties({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          version: state.currentVersion.ident,
          data:[{...data}],
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent, state.currentVersion]
  );

  const onEditProperty = useCallback(
    (data) => {
      dispatch(
        editProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          data: data[0],
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent]
  );

  const onDeleteProperty = useCallback(
    (data) => {
      dispatch(
        deleteProperty({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          propertyIdent: data.propertyIdent,
          version: state.currentVersion.ident,
        })
      );
    },
    [dispatch, moduleApi, scope.api, scope.projectIdent,state.currentVersion]
  );

  const convertToRequiredStructure = useCallback((data) => {
    return data.map((d) => {
      return {
        custom: d.custom,
        nodeIdent: d.nodeIdent,
        labelTranslation: d.labelTranslation,
        mapProgressDtos: [],
        nodeName: d.nodeName,
        phaseType: d.nodeType,
        nodeOrder: d.nodeOrder,
        reportVersionIdent: state.currentVersion.ident,
        required: false,
        type: 'projectProgressPhase',
      };
    });
  },[state.currentVersion.ident]);

  const onAdditionalProperty = useCallback(
    (data) => {
      dispatch(
        addProperties({
          moduleApi: moduleApi,
          api: scope.api,
          projectIdent: scope.projectIdent,
          data:convertToRequiredStructure(data),
          versionIdent:state.currentVersion.ident
        })
      );
    },
    [dispatch, moduleApi, state.currentVersion.ident,scope.api, scope.projectIdent,convertToRequiredStructure]
  );

  const isDataUpdated = useCallback(() => {
    return (
      Object.keys(state.updatedRows)?.filter(
        (f) => state.updatedRows[f].status === "new"
      ).length > 0
    );
  }, [state.updatedRows]);

    return (
        <ProjectProgressContext.Provider value={{
        assets,
        subAssetList,
        blockAssetChange, 
        properties,
        setProperties,
        setBlockAssetChange,
        isDataUpdated,
        onAdditionalProperty,
        onDeleteProperty,
        onEditProperty,
        onFetchCompareProperties,
        onSave,
        }}>
            {children}
        </ProjectProgressContext.Provider>
    )
}