import React from 'react';
import { globalRoot } from "../utils/defaultValues";
import UserDashBoardHome from "./UserDashBoardHome"
import IntlMessages from "../utils/IntlMessages";
import DashboardIcon from "@mui/icons-material/Dashboard";

const UserDashBoardRouteConfig = {
	routes: [
		{
      path: `${globalRoot}/user-dashboard/`,
      exact: true,
      showBreadCrumbs: false,
      name: <IntlMessages id={`menu.user-dashboard`}/>,
      element: <UserDashBoardHome/>,
      icon: <DashboardIcon style={{ width:22, height:22, color: "#637381"}}/>,
      auth: [],
      children: []
  },
  {
    path: `${globalRoot}/`,
    exact: true,
    showBreadCrumbs: true,
    name: <IntlMessages id={`menu.home`}/>,
    element: <UserDashBoardHome/>,
    auth: [],
    children: []
},
{
  path: `${globalRoot}`,
  exact: true,
  showBreadCrumbs: true,
  name: <IntlMessages id={`menu.home`}/>,
  element: <UserDashBoardHome/>,
  auth: [],
  children: []
},
	]
};

export default UserDashBoardRouteConfig;
