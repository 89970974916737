import { createSlice } from "@reduxjs/toolkit";
import { response } from "../../../../utils/defaultValues";

export const initialState = {
  hasErrors: false,
  type: "",
  messages: "",
  error: {},
  data:null,
  email:{...response},
};

const reportReviewSlice = createSlice({
  name: "reportReview",
  initialState,
  reducers: {
    getFailure: (state, { payload }) => ({
      ...state,
      hasErrors: true,
      type: payload.type,
      messages: payload.messages,
      error: payload,
      data:{},
      email:{...response}
    }),
    getEmailSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      email:{
        type: payload.type,
        messages: payload.messages,
        data:payload,
      },
    }),
    getMarkSuccess: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      type: payload.type,
      messages: payload.messages,
      error: null,
      data:payload
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  getFailure,
  getMarkSuccess,
  getEmailSuccess,
  resetState,
} = reportReviewSlice.actions;

// A selector
export const reportReviewSelector = (state) => state.reportReview;

// The reducer
export default reportReviewSlice.reducer;
