import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Alert,
  Card,
  InputAdornment,
  CardContent,
  Divider,
  Chip,
  CardActions,
} from "@mui/material";
import { FastField} from "formik";
import { NumberFormatForPercentage, NumericFormatCustom,calulateTotal,convertToDecimal } from "./defectHelper";
import { getUnits } from "../../../../common/dropdowns";
import PropTypes from "prop-types";
import MultiplePriority from "./MultiplePriority";
import CustomAutoComplete from "./CustomAutoComplete";
import {
  getLabel,
  getIntlTranslation,
  getVariantText,
  getTranslationVariantText,
} from "../../../../utils/helper";
import { useIntl } from "react-intl";
import TextFieldWrapper from "./TextFieldWrapper";

function CostSection(props) {
  const intl = useIntl();
  const { values, touched, errors, setFieldValue } = props;
  const [units, setUnits] = useState(getUnits());
  const [multipleCost, setMultipleCost] = useState({});
  const [rangeData, setRangeData] = useState([30, 70]);

  useEffect(() => {
    if (values.isMultiple) {
      setMultipleCost({
        short: values.percentageShortTermPriority / 100,
        medium: values.percentageMidTermPriority / 100,
        long: values.percentageLongTermPriority / 100,
      });
      if (
        !!values.percentageShortTermPriority ||
        !!values.percentageMidTermPriority ||
        !!values.percentageLongTermPriority
      ) {
        setRangeData([
          Number(values.percentageShortTermPriority),
          Number(
            values.percentageMidTermPriority +
              values.percentageShortTermPriority
          ),
        ]);
      }
    }
  }, [
    values.isMultiple,
    values.percentageLongTermPriority,
    values.percentageMidTermPriority,
    values.percentageShortTermPriority,
  ]);

  const handleTextChange = useCallback(
    (name, val) => {
      setFieldValue(name, val);
    },
    [setFieldValue]
  );



  return (
    <>
      <Box style={{ width: "50%" }}>
        <Card className="d-flex flex-column align-items-center p-0 m-1 w-100">
          <Divider style={{ margin: 5, width: "100%", padding: 2 }}>
            <Chip
              className="g-btn-secondary"
              label={getIntlTranslation("_Cost-Data")}
            />
          </Divider>
          <CardContent
            className="custom-scrollBar p-2"
            style={{
              height: 350,
              maxHeight: 350,
              overflowY: "auto",
              overFlowX: "hidden",
            }}
          >
            <Box
              className="d-flex align-items-start"
              style={{ width: "100%", padding: 2, margin: "2px 0px"}}
            >
              <FastField name="pricePerUnit">
                {() => {
                  return (
                    <TextFieldWrapper
                      label={getLabel("Price per Unit")}
                      size="small"
                      variant="outlined"
                      style={{ width: "70%" }}
                      name="pricePerUnit"
                      id="pricePerUnit-input"
                      Key="pricePerUnit-input"
                      value={!!values.pricePerUnit ? values.pricePerUnit : ""}
                      onChange={(e) =>
                        handleTextChange("pricePerUnit", e.target.value)
                      }
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={touched.pricePerUnit && errors.pricePerUnit}
                      helperText={touched.pricePerUnit && errors.pricePerUnit}
                    />
                  );
                }}
              </FastField>
              <CustomAutoComplete
                id="unit-suggestion"
                key="unit-suggestion"
                options={units}
                defaultValue={values.unit}
                onChange={(val) => handleTextChange("unit", val?.value)}
                style={{ width: "40%", margin: "0px 2px" }}
                variant="outlined"
                label={getLabel("Unit")}
                error={touched.unit && errors.unit}
                helperText={touched.unit && errors.unit}
              />
            </Box>
            <Box
              className="d-flex align-items-start"
              style={{ width: "100%", padding: 5 }}
            >
              {" "}
              <FastField name="quantity">
                {() => {
                  return (
                    <TextFieldWrapper
                      style={{ width: "25%", margin: "0px 2px" }}
                      size="small"
                      name="quantity"
                      multiline
                      maxRows={5}
                      id="quantity-input"
                      label={getLabel("_Quantity")}
                      variant="outlined"
                      key="quantity-input"
                      value={values.quantity}
                      onChange={(e) =>
                        handleTextChange("quantity", e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      error={touched.quantity && errors.quantity}
                      helperText={touched.quantity && errors.quantity}
                    />
                  );
                }}
              </FastField>
              <FastField name="elementAffected">
                {() => {
                  return (
                    <TextFieldWrapper
                      size="small"
                      name="elementAffected"
                      id="elementAffected-input"
                      style={{ width: "50%", margin: "0px 2px" }}
                      label={getLabel("_%-of-the-element-affected")}
                      variant="outlined"
                      key="elementAffected-input"
                      value={values.elementAffected}
                      onChange={(e) =>
                        handleTextChange("elementAffected", e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: NumberFormatForPercentage,
                      }}
                      placeholder={intl.formatMessage({
                        id: "min value 1 and max value 100",
                      })}
                    />
                  );
                }}
              </FastField>
              <FastField name="extraCost">
                {() => {
                  return (
                    <TextFieldWrapper
                      size="small"
                      variant="outlined"
                      name="extraCost"
                      id="extraCost-input"
                      style={{ width: "25%" }}
                      label={getLabel("_Extra-Cost-(Optional)")}
                      key="extraCost-input"
                      value={values.extraCost}
                      onChange={(e) =>
                        handleTextChange("extraCost", e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom,
                      }}
                    />
                  );
                }}
              </FastField>
            </Box>
            <Box
              className="d-flex align-items-center"
              style={{ width: "100%", padding: 2, margin: "2px 0px"}}
            >
              <FastField name="extraCostComment">
                {() => {
                  return (
                    <TextFieldWrapper
                      size="small"
                      style={{ width: "100%", margin: 2 }}
                      name="extraCostComment"
                      label={getLabel("_Extra-Cost-Comments")}
                      multiline
                      maxRows={10}
                      key="extraCostComment-input"
                      value={values.extraCostComment}
                      onChange={(e) =>
                        handleTextChange("extraCostComment", e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      error={
                        touched.extraCostComment && errors.extraCostComment
                      }
                      helperText={
                        touched.extraCostComment && errors.extraCostComment
                      }
                    />
                  );
                }}
              </FastField>
            </Box>
            {values.isMultiple && (
              <Box style={{ width: "100%", padding: 5, marginBottom: 10 }}>
                <MultiplePriority
                  data={rangeData}
                  onChange={(range) => {
                    setFieldValue(
                      "percentageShortTermPriority",
                      Number(range[0])
                    );
                    setFieldValue(
                      "percentageMidTermPriority",
                      Number(range[1] - range[0])
                    );
                    setFieldValue(
                      "percentageLongTermPriority",
                      Number(100 - range[1])
                    );
                    setFieldValue("multipleCost", {
                      short: Number(range[0]) / 100,
                      medium: Number(range[1] - range[0]) / 100,
                      long: Number(100 - range[1]) / 100,
                    });
                  }}
                />
              </Box>
            )}
            {values.isMultiple && (
              <Box
                className="d-flex flex-row"
                style={{ width: "100%", padding: 2, margin: "2px 0px"}}
              >
                <Box
                  style={{
                    margin: 2,
                    width: "33%",
                    backgroundColor: "#e85656",
                  }}
                  className="d-flex flex-row align-items-center grid-item"
                >
                  <span
                    className="grid-item"
                    id={multipleCost?.short}
                    style={{ backgroundColor: "#e85656" }}
                  >
                    {(
                      Math.round(
                        (convertToDecimal(values, false) * multipleCost?.short) /
                          1000
                      ) * 1000)
                      .toLocaleString('de-DE', {currency: 'EUR', style: 'currency'})
                    }
                  </span>
                </Box>
                <Box
                  style={{
                    margin: 2,
                    width: "40%",
                    backgroundColor: "#dfb81c",
                  }}
                  className="d-flex flex-row align-items-center grid-item"
                >
                  <span
                    className="grid-item"
                    id={multipleCost?.medium}
                    style={{ backgroundColor: "#dfb81c" }}
                  >
                    {
                      (Math.round(
                        (convertToDecimal(values, false) * multipleCost?.medium) /
                          1000
                      ) * 1000)
                      .toLocaleString('de-DE', {currency: 'EUR', style: 'currency'})
                    }
                  </span>
                </Box>
                <Box
                  style={{
                    margin: 2,
                    width: "33%",
                    backgroundColor: "#209e91",
                  }}
                  className="d-flex flex-row align-items-center grid-item"
                >
                  <span
                    className="grid-item"
                    id={multipleCost?.long}
                    style={{ backgroundColor: "#209e91" }}
                  >
                    {
                      (Math.round(
                        (convertToDecimal(values, false) * multipleCost?.long) /
                          1000
                      ) * 1000)
                      .toLocaleString('de-DE', {currency: 'EUR', style: 'currency'})
                    }
                  </span>
                </Box>
              </Box>
            )}
            <Box className="d-flex align-items-center w-100" style={{ padding: 2, margin: "2px 0px"}}>
              <Box className="d-flex" style={{ fontWeight: "bold",color: "#244a9a",margin: 5, width:"50%"}}>
              {getTranslationVariantText("_Total","body1",{fontWeight:"bold",margin:5})}
              {getVariantText(calulateTotal(values, false).toLocaleString('de-DE', {currency: 'EUR', style: 'currency'}),"body1",{fontWeight:"bold",margin:5})}
              </Box>
                
                <Box
                   style={{
                      fontWeight: "bold",
                      color: "black",
                      margin: 5,
                      width: "50%",
                    }}
                  >
                    [{getIntlTranslation("_Cost-estimate-for-CAPEX")} &nbsp;
                    {(Math.round(convertToDecimal(values,true) / 1000) * 1000).toLocaleString('de-DE', {currency: 'EUR', style: 'currency'})}]
                  </Box>
            </Box>
          </CardContent>
          <CardActions
                    style={{ marginBottom: "auto", marginLeft: "auto" }}
                    className="w-100"
                  >
{values.pricePerUnit > 0 &&
          Math.round(convertToDecimal(values, true) / 1000) * 1000 < 10000 && (
            <Alert variant="filled" severity="warning">
              {getIntlTranslation("_threshold")}
            </Alert>
          )}
          </CardActions>
        </Card>
      </Box>
    </>
  );
}

CostSection.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
};

export default CostSection;
