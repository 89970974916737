import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAutocomplete from "./useAutoComplete";
import UserMentionsPanel from "./UserMentionsPanel";
import getCaretCoordinates from "textarea-caret";
import "./CommentContainerMention.css";
import { getIntlTranslation } from "../../../utils/helper";
import CustomButton from "../../../components/buttons/CustomButton.js";
import { Box, List, Divider } from "@mui/material";
import CustomIconButton from "../../../components/buttons/icons-buttons/CustomIconButton";
import SaveIcon from "@mui/icons-material/Save";

function getActiveToken(input, cursorPosition) {
  const tokenizedQuery = input.split(/[\s\n]/).reduce((acc, word, index) => {
    const previous = acc[index - 1];
    const start = index === 0 ? index : previous.range[1] + 1;
    const end = start + word.length;

    return acc.concat([{ word, range: [start, end] }]);
  }, []);

  if (cursorPosition === undefined) {
    return undefined;
  }

  const activeToken = tokenizedQuery.find(
    ({ range }) => range[0] < cursorPosition && range[1] >= cursorPosition
  );

  return activeToken;
}

function replaceAt(str, replacement, index, length = 0) {
  const prefix = str.substr(0, index);
  const suffix = str.substr(index + length);
  return prefix + replacement + suffix;
}

export function isValidMention(username) {
  return /^@\w+(\s\w+)*$/.test(username);
}

function CommentContainerMention(props) {
  const usersState = useSelector((state) => state.users);
  const inputRef = React.useRef(null);
  const { autocomplete, state } = useAutocomplete({
    ...props,
    id: "comment-autocomplete",
    defaultActiveItemId: 0,
    insights: true,
    getSources({ query }) {
      const cursorPosition = inputRef.current?.selectionEnd || 0;
      const activeToken = getActiveToken(query, cursorPosition);

      if (activeToken?.word && isValidMention(activeToken?.word)) {
        let t = [
          {
            sourceId: "users",
            onSelect({ item, setQuery }) {
              const [index] = activeToken.range;
              const replacement = `[@${item.title}(${item.emailAddress})]\r\n`;
              const newQuery = replaceAt(
                query,
                replacement,
                index,
                activeToken.word.length
              );
              setQuery(newQuery);
              if (inputRef.current) {
                inputRef.current.selectionEnd = index + replacement.length;
              }
            },
            getItems() {
              let temp = usersState.mentions?.filter((f) =>
                f.title
                  .toLowerCase()
                  .includes(activeToken.word.slice(1).toLowerCase())
              );
              return temp;
            },
          },
        ];
        return t;
      }
      return [];
    },
  });

  const { top, height } = inputRef.current
    ? getCaretCoordinates(inputRef.current, inputRef.current?.selectionEnd)
    : { top: 0, height: 0 };

  function onInputNavigate() {
    const cursorPosition = inputRef.current?.selectionEnd || 0;
    const activeToken = getActiveToken(state.query, cursorPosition);
    const shouldOpen = isValidMention(activeToken?.word || "");
    autocomplete.setIsOpen(shouldOpen);
    autocomplete.refresh();
  }


  useEffect(() => {
    if(!!props.value){
      autocomplete.setQuery(props.value)
      if (inputRef.current) {
        inputRef.current.value = props.value;
      }
    }
  }, [props.value])
  
  const inputProps = autocomplete.getInputProps({
    inputElement: inputRef.current,
    autoFocus: true,
    contenteditable: true,
    maxLength: 10000,
    spellCheck: true,
    type: "search",
    defaultValue:props.value,
  });
  return (
    <>
      <div className="box-compose">
        <form
          {...autocomplete.getFormProps({
            inputElement: { ...inputRef.current, defaultValue: props.value },
          })}
          style={{ height: "100%" }}
        >
          <textarea
            className="box-textbox"
            ref={inputRef}
            {...inputProps}
            onChange={(e) => inputProps.onChange(e)} 
            onKeyUp={(event) => {
              if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
                onInputNavigate();
              }
            }}
            onClick={(event) => {
              inputProps.onClick(event);
              onInputNavigate();
            }}
          />
        </form>
        {state.isOpen && (
          <div
            {...autocomplete.getPanelProps({})}
            className="autocomplete-panel"
            style={{ top: `${top + height}px` }}
          >
            {state.collections.map(({ source, items }) => {
              return (
                <div
                  key={`source-${source.sourceId}`}
                  className={[
                    "autocomplete-source",
                    state.status === "stalled" && "autocomplete-source-stalled",
                  ]
                    .filter(Boolean)
                    .join(" ")}
                >
                  {items.length > 0 && (
                    <List
                      {...autocomplete.getListProps()}
                      className="autocomplete-items custom-scrollBar"
                    >
                      {items.map((item) => {
                        const itemProps = autocomplete.getItemProps({
                          item,
                          source,
                        });
                        return (
                          <>
                            <UserMentionsPanel user={item} {...itemProps} />
                            <Divider component="li" />
                          </>
                        );
                      })}
                    </List>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Box
        className={"d-flex align-items-center w-100 justify-content-end"}
        style={{
          height: "100%",
          flex: 1,
          marginTop: 10,
        }}
      >
        <CustomButton
          id="cancel_reply_comment"
          buttonText={"_cancel"}
          variant="text"
          style={{ marginLeft: 5, height: 30 }}
          className="btn p-1 m-1"
          textVariant="caption"
          tooltip={{
            title: getIntlTranslation("tooltip.cancel"),
          }}
          handleClick={(e) => props.onClose()}
        />
        <CustomIconButton
          id="save_reply_comment_01"
          icon={<SaveIcon style={{ color: "#244a9a", fontSize: 20 }} />}
          permission={null}
          handleClick={(e) => {
            props.onSave(inputRef.current.value);
          }}
          tooltip={{
            title: getIntlTranslation("tooltip.save"),
          }}
          aria-describedby={`save_reply_comment_01`}
        />
      </Box>
    </>
  );
}

export default CommentContainerMention;
