import React from "react";
import { tddRoot } from "../../utils/defaultValues";
import TddModuleHome from "./TddModuleHome";
import IntlMessages from "../../utils/IntlMessages";
import ProjectListRouteConfig from "./feature-bundle/project-list/ProjectListRouteConfig";
import ProjectDashboardRouteConfig from "./feature-bundle/project-dashboard/ProjectDashboardRouteConfig";
import AssetOverviewRouteConfig from "./feature-bundle/asset-management/AssetOverviewRouteConfig";
import BuildingProfileRouteConfig from "./feature-bundle/building-profile/BuildingProfileRouteConfig";
import KeyFindingsHomeRouteConfig from "./feature-bundle/key-findings/KeyFindingsHomeRouteConfig";
import PhotoDocumentationRouteConfig from "./feature-bundle/photodocumentation/PhotoDocumentationRouteConfig";
import CapexOverviewRouteConfig from "./feature-bundle/capex-overview/CapexOverviewRouteConfig";
import RedFlagReportRouteConfig from "./feature-bundle/redflag-report/RedFlagReportRouteConfig";
import ReportReviewRouteConfig from "./common/report-review/ReportReviewRouteConfig";
import FullReportRouteConfig from "./feature-bundle/full-report/FullReportRouteConfig";
import StatutoryComplianceRouteConfig from "./feature-bundle/statutory-compliance/StatutoryComplianceRouteConfig";
import customReportRouteConfig from "./feature-bundle/custom-report/customReportRouteConfig";
import DataAnalysisRouteConfig from "./feature-bundle/data-analysis/DataAnalysisRouteConfig";
import TddProjectScopeWrapper from "./TddProjectScopeWrapper";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ListIcon from "@mui/icons-material/List";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GridViewIcon from '@mui/icons-material/GridView';
import FlagIcon from "@mui/icons-material/Flag";
import SettingsIcon from "@mui/icons-material/Settings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import GradingIcon from "@mui/icons-material/Grading";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ConstructionIcon from "@mui/icons-material/Construction";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import PostAddIcon from "@mui/icons-material/PostAdd";

const TddModuleRouteConfig = {
  routes: [
    {
      path: `${tddRoot}/`,
      exact: true,
      name: <IntlMessages id={`menu.tdd-management`} />,
      element: <TddModuleHome />,
      icon: (
        <EngineeringIcon style={{ width: 22, height: 22, color: "#637381" }} />
      ),
      isParent: true,
      showBreadCrumbs: true,
      auth: [],
      children: [
        ...ProjectListRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-project-list",
          parentPath: `${tddRoot}/`,
          showBreadCrumbs: true,
          icon: (
            <ListIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),

        ...DataAnalysisRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-data-analysis",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
        })),

        ...ProjectDashboardRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-project-dashboard",
          showBreadCrumbs: false,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <GridViewIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...AssetOverviewRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-assets-overview",
          parentPath: `${tddRoot}/`,
          showBreadCrumbs: true,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <ApartmentIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...BuildingProfileRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-building-profile",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <RoomPreferencesIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...KeyFindingsHomeRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-project-defects",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <ConstructionIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...PhotoDocumentationRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-photo-documentation",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <LinkedCameraIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...CapexOverviewRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-capex-overview",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <RequestQuoteIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...RedFlagReportRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-red-flag",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <FlagIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...ReportReviewRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-report-review",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
        })),
        ...FullReportRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-full-report",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <AutoStoriesIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...StatutoryComplianceRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-statutory-compliance",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <GradingIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
        ...customReportRouteConfig.routes.map((u) => ({
          ...u,
          id: "tdd-custom-report",
          showBreadCrumbs: true,
          parentPath: `${tddRoot}/`,
          element: <TddProjectScopeWrapper>{u.element}</TddProjectScopeWrapper>,
          icon: (
            <PostAddIcon
              style={{ marginLeft: 5, width: 22, height: 22, color: "#244a9a" }}
            />
          ),
        })),
      ],
    },
  ],
};
export default TddModuleRouteConfig;
