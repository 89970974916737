import React from 'react'
import Box from '@mui/material/Box';
import ProjectDashboardHome from "../../../component-bundle/project-dashboard/ProjectDashboardHome"
import {tddServiceLine,tddRoot} from '../../../../utils/defaultValues'
import UserComments from './UserComments';
import useProjectUsersHook from '../../common/report-component/hooks/useProjectUsers';

const TDDProjectDashboardHome = () => {
  const { users } = useProjectUsersHook({
    isFetching: true,
  }
  );
  
  return (
    <Box style={{overflow: "hidden"}} className=" d-flex w-100 h-100">
      <Box style={{overflowY: "auto", width:"75%"}} className="custom-scrollBar g-scroll-5 h-100">
      <ProjectDashboardHome serviceLine={tddServiceLine} routePrefix={tddRoot}/>
      </Box>
      <Box style={{overflow:"hidden", width:"24%"}} className="h-100">
      <UserComments users={users}/>
      </Box>
  
  </Box>
  );
};
export default TDDProjectDashboardHome;